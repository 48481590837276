import { Injectable } from '@angular/core';

import {
  mutationConfirmLeavingDocument,
  mutationConfirmWriteOffDoc,
  mutationCreateLeavingDocument,
  mutationCreateWriteOffDoc,
  mutationDeleteLeavingDocument,
  mutationDeleteWriteOffDoc,
  mutationDuplicateLeavingDocument,
  mutationRevertWriteOffDoc,
  mutationUpdateLeavingDocument,
  mutationUpdateWriteOffDoc,
  queryAllLeavingDocumentsPageableV2,
  queryLeavingDocument,
  queryWriteOffDoc,
  queryWriteOffDocs,
  queryWriteOffProducts,
} from '@api';
import { ApiService } from '@services/core';
import {
  CoreSchema,
  Mutation,
  MutationConfirmLeavingDocumentArgs,
  MutationConfirmWriteOffDocArgs,
  MutationCreateWriteOffDocArgs,
  MutationDeleteLeavingDocumentArgs,
  MutationDeleteWriteOffDocArgs,
  MutationResult,
  MutationRevertWriteOffDocArgs,
  MutationUpdateWriteOffDocArgs,
  Query,
  QueryResult,
  QueryWriteOffDocArgs,
  QueryWriteOffDocsArgs,
  QueryWriteOffProductsArgs,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class LeavingsApi {
  constructor(private apiService: ApiService) {}

  getAllLeavingDocuments(variables: CoreSchema.QueryAllLeavingDocumentsPageableV2Args): QueryResult<'allLeavingDocumentsPageableV2'> {
    return this.apiService.query<Query<'allLeavingDocumentsPageableV2'>, CoreSchema.QueryAllLeavingDocumentsPageableV2Args>({
      query: queryAllLeavingDocumentsPageableV2,
      variables,
    });
  }

  getLeavingDocument(leavingDocumentId: string): QueryResult<'leavingDocument'> {
    return this.apiService.query<Query<'leavingDocument'>, CoreSchema.QueryLeavingDocumentArgs>({
      query: queryLeavingDocument,
      variables: {
        id: leavingDocumentId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  confirmLeavingDocument(leavingDocumentId: string, warehouseId: string): MutationResult<'confirmLeavingDocument'> {
    return this.apiService.mutate<Mutation<'confirmLeavingDocument'>, MutationConfirmLeavingDocumentArgs>({
      mutation: mutationConfirmLeavingDocument,
      variables: {
        leavingDocumentId,
        warehouseId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  deleteLeavingDocument(leavingDocumentId: string): MutationResult<'confirmLeavingDocument'> {
    return this.apiService.mutate<Mutation<'confirmLeavingDocument'>, MutationDeleteLeavingDocumentArgs>({
      mutation: mutationDeleteLeavingDocument,
      variables: {
        leavingDocumentId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  createLeavingDocument(variables: CoreSchema.MutationCreateLeavingDocumentArgs): MutationResult<'createLeavingDocument'> {
    return this.apiService.mutate<Mutation<'createLeavingDocument'>, CoreSchema.MutationCreateLeavingDocumentArgs>({
      mutation: mutationCreateLeavingDocument,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  updateLeavingDocument(variables: CoreSchema.MutationUpdateLeavingDocumentArgs): MutationResult<'updateLeavingDocument'> {
    return this.apiService.mutate<Mutation<'updateLeavingDocument'>, CoreSchema.MutationUpdateLeavingDocumentArgs>({
      mutation: mutationUpdateLeavingDocument,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  duplicateLeavingDocument(documentId: string): MutationResult<'duplicateLeavingDocument'> {
    return this.apiService.mutate<Mutation<'duplicateLeavingDocument'>, CoreSchema.MutationDuplicateLeavingDocumentArgs>({
      mutation: mutationDuplicateLeavingDocument,
      variables: {
        documentId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  writeOffProducts(variables: QueryWriteOffProductsArgs): QueryResult<'writeOffProducts'> {
    return this.apiService.query<Query<'writeOffProducts'>, QueryWriteOffProductsArgs>({
      query: queryWriteOffProducts,
      variables,
    });
  }

  writeOffDocs(variables: QueryWriteOffDocsArgs): QueryResult<'writeOffDocs'> {
    return this.apiService.query<Query<'writeOffDocs'>, QueryWriteOffDocsArgs>({
      query: queryWriteOffDocs,
      variables,
    });
  }

  writeOffDoc(variables: QueryWriteOffDocArgs): QueryResult<'writeOffDoc'> {
    return this.apiService.query<Query<'writeOffDoc'>, QueryWriteOffDocArgs>({
      query: queryWriteOffDoc,
      variables,
    });
  }

  createWriteOffDoc(variables: MutationCreateWriteOffDocArgs): MutationResult<'createWriteOffDoc'> {
    return this.apiService.mutate<Mutation<'createWriteOffDoc'>, MutationCreateWriteOffDocArgs>({
      mutation: mutationCreateWriteOffDoc,
      variables,
    });
  }

  updateWriteOffDoc(variables: MutationUpdateWriteOffDocArgs): MutationResult<'updateWriteOffDoc'> {
    return this.apiService.mutate<Mutation<'updateWriteOffDoc'>, MutationUpdateWriteOffDocArgs>({
      mutation: mutationUpdateWriteOffDoc,
      variables,
    });
  }

  confirmWriteOffDoc(variables: MutationConfirmWriteOffDocArgs): MutationResult<'confirmWriteOffDoc'> {
    return this.apiService.mutate<Mutation<'confirmWriteOffDoc'>, MutationConfirmWriteOffDocArgs>({
      mutation: mutationConfirmWriteOffDoc,
      variables,
    });
  }

  revertWriteOffDoc(variables: MutationRevertWriteOffDocArgs): MutationResult<'revertWriteOffDoc'> {
    return this.apiService.mutate<Mutation<'revertWriteOffDoc'>, MutationRevertWriteOffDocArgs>({
      mutation: mutationRevertWriteOffDoc,
      variables,
    });
  }

  deleteWriteOffDoc(variables: MutationDeleteWriteOffDocArgs): MutationResult<'deleteWriteOffDoc'> {
    return this.apiService.mutate<Mutation<'deleteWriteOffDoc'>, MutationDeleteWriteOffDocArgs>({
      mutation: mutationDeleteWriteOffDoc,
      variables,
    });
  }
}
