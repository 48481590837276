export class EditTableCellService {
  focusNextCell(rowIdx: number, colIdx: number) {
    const cells = document.querySelectorAll('nm-edit-text-table-cell');
    const nextCell = cells[rowIdx * 2 + colIdx + 1];
    if (nextCell) {
      (nextCell.querySelector('div') as HTMLElement).focus();
    }
  }

  focusPrevCell(rowIdx: number, colIdx: number) {
    const cells = document.querySelectorAll('nm-edit-text-table-cell');
    const prevCell = cells[rowIdx * 2 + colIdx - 1];
    if (prevCell) {
      (prevCell.querySelector('div') as HTMLElement).focus();
    }
  }

  focusDownCell(rowIdx: number, colIdx: number) {
    const cells = document.querySelectorAll('nm-edit-text-table-cell');
    const downCell = cells[(rowIdx + 1) * 2 + colIdx];
    if (downCell) {
      (downCell.querySelector('div') as HTMLElement).focus();
    }
  }

  focusUpCell(rowIdx: number, colIdx: number) {
    const cells = document.querySelectorAll('nm-edit-text-table-cell');
    const upCell = cells[(rowIdx - 1) * 2 + colIdx];
    if (upCell) {
      (upCell.querySelector('div') as HTMLElement).focus();
    }
  }
}
