import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormRowComponent } from './form-row.component';

@NgModule({
  declarations: [FormRowComponent],
  imports: [CommonModule],
  exports: [FormRowComponent],
})
export class FormRowModule {}
