import { Observable } from '@apollo/client/core';
import { ErrorUUIDFunc } from '@typings';

export const errorUUID: ErrorUUIDFunc = ({ storage }) => {
  storage.removeOrgId();
  storage.removeCurrencyUnit();
  storage.removeFilters();
  storage.redirectLogin('OrgId is not correct');

  return new Observable((observer) => {
    observer.complete();
  });
};
