<mat-menu
  class="nm-menu"
  #childMenu="matMenu"
  [class]="panelClass"
  [xPosition]="xPosition"
  [yPosition]="yPosition"
  [hasBackdrop]="hasBackdrop"
  (closed)="onClosed()"
>
  <nm-menu-content
    [items]="items"
    [selectedItems]="selectedItems"
    [allowMultiselect]="allowMultiselect"
    [checkboxPosition]="checkboxPosition"
    [selectOnItemClick]="selectOnItemClick"
    [closeAfterItemClick]="closeAfterItemClick"
    [header]="header"
    [xPosition]="xPosition"
    [yPosition]="yPosition"
    [showCancelButton]="showCancelButton"
    [cancelButtonLabel]="cancelButtonLabel"
    [cancelButtonVariant]="cancelButtonVariant"
    [showOkButton]="showOkButton"
    [okButtonLabel]="okButtonLabel"
    [okButtonVariant]="okButtonVariant"
    [showSearch]="showSearch"
    [hasBackdrop]="hasBackdrop"
    [searchText]="searchText"
    [maxHeight]="maxHeight"
    [minWidth]="minWidth"
    [maxWidth]="maxWidth"
    [itemsLoading]="itemsLoading"
    [panelClass]="panelClass"
    [isCloseAfterCancelClick]="isCloseAfterCancelClick"
    [isCloseAfterOkClick]="isCloseAfterOkClick"
    (selectedItemsChange)="selectedItemsChange.emit($event)"
  ></nm-menu-content>
</mat-menu>
