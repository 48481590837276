<div class="nm-input-search">
  <div>
    <nm-svg-icon [size]="24" [color]="iconsColor" [name]="'search'"></nm-svg-icon>
  </div>
  <input #input [attr.data-test-id]="testId" (keyup)="onKeyUp(input.value)" [placeholder]="placeholder" />
  <div class="remove-icon">
    <nm-svg-icon
      (click)="removeValue()"
      *ngIf="(value$ | async) ? 'close' : null"
      [size]="24"
      [color]="iconsColor"
      [name]="'close'"
    ></nm-svg-icon>
  </div>
</div>
