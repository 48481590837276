import { CoreSchema, ProductReportIndicatorType, ReportCardTitleType, ReportIndicatorType } from '@typings';

import { CURRENCY } from './currencies';

export const OVERVIEW_CARD: Record<ReportIndicatorType, ReportCardTitleType> = {
  revenue: { name: 'Выручка', averageName: 'Средняя выручка', unit: CURRENCY.RUBLE, order: 0 },
  profit: { name: 'Прибыль', averageName: 'Средняя прибыль', unit: CURRENCY.RUBLE, order: 1 },
  averageCheck: { name: 'Средний чек', unit: CURRENCY.RUBLE, order: 2 },
  guestsCount: { name: 'Посетители', averageName: 'Среднее количество посетителей', unit: '', order: 3 },
  checksCount: { name: 'Заказы', averageName: 'Среднее количество заказов', unit: '', order: 4 },
};

export const ANALYTICS_CARD: Record<ProductReportIndicatorType, ReportCardTitleType> = {
  revenue: { name: 'Выручка', unit: CURRENCY.RUBLE, order: 0 },
  profit: { name: 'Прибыль', unit: CURRENCY.RUBLE, order: 1 },
  quantity: { name: 'Количество', unit: '', order: 2 },
};

export const LOYALTY_CARD: Record<keyof CoreSchema.LoyaltyAnalyticsSummary, ReportCardTitleType> = {
  revenueWithDiscounts: { name: 'Выручка с учётом скидок', unit: CURRENCY.RUBLE, order: 0 },
  discountsUsed: { name: 'Использование скидок', unit: CURRENCY.RUBLE, order: 1 },
  loyaltyUsed: { name: 'Использование бонусных программ', unit: '', order: 2 },
};

export const LOYALTY_CARD_REVENUE_WITH_DISCOUNTS_LABELS: Record<keyof CoreSchema.DiscountsRevenue, ChartLabel> = {
  discountMarginLoss: { name: 'Потеря маржи при скидках', color: '#FF4E4E' },
  revenueWithDiscounts: { name: 'Сумма выручки со скидками', color: '#0A80FF' },
  revenueWithoutDiscounts: { name: 'Сумма выручки без скидок', color: '#FF9E83' },
};

export const LOYALTY_CARD_DISCOUNTS_USED_LABELS: Record<keyof CoreSchema.DiscountsUsed, ChartLabel> = {
  ordersWithDiscounts: { name: 'Заказы со скидками', color: '#0A80FF' },
  ordersWithoutDiscounts: { name: 'Заказы без скидок', color: '#FF9E83' },
};

export const LOYALTY_CARD_LOAYLTY_LABELS: Record<keyof CoreSchema.LoyaltyUsed, ChartLabel> = {
  ordersWithLoyalty: { name: 'Заказы с бонусными программами', color: '#0A80FF' },
  ordersWithoutLoyalty: { name: 'Заказы без бонусных программ', color: '#FF9E83' },
};

export interface ChartLabel {
  name: string;
  color: string;
}
