import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

import { ButtonModule } from '@app/shared/component/button/button.module';
import { IconButtonModule } from '@app/shared/component/icon-button/icon-button.module';
import { MenuModule } from '@app/shared/component/menu/menu.module';

import { ActionsComponent } from './actions.component';

@NgModule({
  declarations: [ActionsComponent],
  imports: [CommonModule, ButtonModule, IconButtonModule, MenuModule, MatMenuModule],
  exports: [ActionsComponent],
})
export class ActionsModule {}
