import { StrictDictionary } from '@typings';

export const isFileTypeAllowed = (file: File, allowedFileTypes: string[] = []): boolean => {
  if (allowedFileTypes.length) {
    const fileType = file.type.split('/')[1];
    if (allowedFileTypes.includes('xls')) {
      if (allowedFileTypes.findIndex((ft: string) => typeForExcel[ft] === fileType) === -1) {
        return false;
      }
    } else {
      if (allowedFileTypes.findIndex((ft) => ft === fileType) === -1) {
        return false;
      }
    }
  }

  return true;
};

export const typeForExcel: StrictDictionary<string, string> = {
  xls: 'vnd.ms-excel',
  xlsx: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
