import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import equal from 'fast-deep-equal';
import { takeUntil } from 'rxjs/operators';

import { FormGroupComponent } from '@app/shared/component/form-group/form-group.component';
import { DestroyService } from '@core/service';
import { isEmpty } from '@utils';
@Component({
  selector: 'nm-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [DestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent implements AfterContentInit, AfterViewInit {
  @Input() form: FormGroup;

  @Output() submitForm = new EventEmitter<Set<string>>();

  @ContentChildren(FormGroupComponent) formGroupComponentList: QueryList<FormGroupComponent>;
  @ContentChildren(FormGroupComponent, { read: ElementRef }) formGroupList: QueryList<ElementRef>;
  @ContentChildren('fullWidth', { read: ElementRef }) fullWidthList: QueryList<ElementRef>;

  @ViewChild('formElement') formElement: ElementRef;

  private initialFormValue: { [key: string]: unknown };
  updatedValue: Set<string> = new Set();

  constructor(@Inject(DestroyService) private readonly destroy$: DestroyService, private renderer: Renderer2) {}

  ngAfterContentInit(): void {
    this.formGroupComponentList.forEach((formGroupComponent, index) => {
      if (formGroupComponent.isOpen === false) {
        this.toggleFormGroup(index);
      }

      formGroupComponent.setIsOpen.pipe(takeUntil(this.destroy$)).subscribe(() => this.toggleFormGroup(index));
    });

    this.initialFormValue = this.form.getRawValue();
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const newValues = this.form.getRawValue();
      const controlsNames: string[] = Object.keys(this.initialFormValue).map((key) => key.toString());
      controlsNames.forEach((key: string) => {
        const initValue = this.initialFormValue[key];
        const newValue = newValues[key];
        const valuesAreEmpty = isEmpty(initValue) && isEmpty(newValue);
        const isEqual = valuesAreEmpty || equal(this.initialFormValue[key], newValues[key]);
        if (isEqual) {
          if (this.updatedValue.has(key)) {
            this.updatedValue.delete(key);
          }
        } else {
          this.updatedValue.add(key);
        }
      });
    });
  }

  ngAfterViewInit(): void {
    this.setList();

    this.fullWidthList.changes.pipe(takeUntil(this.destroy$)).subscribe(() => this.setList());
  }

  setList(): void {
    if (this.fullWidthList && this.fullWidthList.length) {
      const parent = this.renderer.parentNode(this.formElement.nativeElement);
      const style = getComputedStyle(parent);
      const width = Number(style.width.replace('px', ''));
      const paddingRight = Number(style.paddingRight.replace('px', ''));
      const paddingLeft = Number(style.paddingLeft.replace('px', ''));
      const list = this.fullWidthList.toArray();

      list.forEach((item) => this.renderer.setStyle(item.nativeElement, 'width', `${width - paddingRight - paddingLeft}px`));
    }
  }

  toggleFormGroup(index: number): void {
    this.formGroupList.toArray()[index].nativeElement.classList.toggle('collapsed');
  }

  submit() {
    this.submitForm.emit(this.updatedValue);
  }
}
