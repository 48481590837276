import { Pipe, PipeTransform } from '@angular/core';

import { MenuType, MenuTypeString } from '@typings';

@Pipe({
  name: 'menuType',
})
export class MenuTypePipe implements PipeTransform {
  transform(type: MenuType): string {
    const types: MenuTypeString = {
      DEFAULT: 'По умолчанию',
      DELIVERY: 'Доставка',
      ONLINE: 'Внутри зала',
    };

    return types[type];
  }
}
