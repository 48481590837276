import { Component, Inject } from '@angular/core';

import { CONTENT } from '../tooltip.directive';

@Component({
  selector: 'nm-tootlip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  constructor(@Inject(CONTENT) public content: string) {}
}
