import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[tableColumn]',
})
export class TableColumnDirective {
  private _colName: string;

  @Input('columnName')
  get name(): string {
    return this._colName;
  }

  set name(name: string) {
    this._colName = name;
  }
}
