import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { NotifyService } from '@services/shared';
import {
  MutationCreateSupplierV2Args,
  MutationCreateSupplierV3Args,
  MutationDeleteSupplierArgs,
  MutationDeleteSupplierV2Args,
  MutationResult,
  MutationUpdateSupplierV2Args,
  MutationUpdateSupplierV3Args,
  QueryAllSuppliersPageableArgs,
  QueryResult,
  QuerySupplierArgs,
  QuerySuppliersArgs,
  QuerySupplierV2Args,
  SupplierUnion,
} from '@typings';

import { SupplierApi } from './supplier.api';

@Injectable({
  providedIn: 'root',
})
export class SupplierStorage {
  #supplier = new BehaviorSubject<SupplierUnion | null>(null);
  supplier$ = this.#supplier.asObservable();

  setSupplier(supplier: SupplierUnion): void {
    this.#supplier.next(supplier);
  }

  getSupplier(): SupplierUnion {
    return this.#supplier.getValue()!;
  }

  constructor(private supplierApi: SupplierApi, private notifyService: NotifyService) {}

  getAllSuppliersPageable(variables: QueryAllSuppliersPageableArgs): QueryResult<'allSuppliersPageable'> {
    return this.supplierApi.getAllSuppliersPageable(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка поставщиков',
      }),
    );
  }

  getAllSuppliersPageableV2(variables: QuerySuppliersArgs): QueryResult<'suppliers'> {
    return this.supplierApi.getAllSuppliersPageableV2(variables)?.pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка поставщиков',
      }),
    );
  }

  getSuppliersShort(variables: QueryAllSuppliersPageableArgs): QueryResult<'allSuppliersPageable'> {
    return this.supplierApi.getSuppliersShort(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка поставщиков',
      }),
    );
  }

  getSupplierById(variables: QuerySupplierArgs): QueryResult<'supplier'> {
    return this.supplierApi.getSupplierById(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении поставщика',
      }),
    );
  }

  getSupplierByIdV2(variables: QuerySupplierV2Args): QueryResult<'supplierV2'> {
    return this.supplierApi.getSupplierByIdV2(variables)?.pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении поставщика',
      }),
    );
  }

  createSupplier(variables: MutationCreateSupplierV2Args): MutationResult<'createSupplierV2'> {
    return this.supplierApi.createSupplierV2(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании поставщика',
      }),
    );
  }

  updateSupplier(variables: MutationUpdateSupplierV2Args): MutationResult<'updateSupplierV2'> {
    return this.supplierApi.updateSupplierV2(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении поставщика',
      }),
    );
  }

  deleteSupplier(variables: MutationDeleteSupplierArgs): MutationResult<'deleteSupplier'> {
    return this.supplierApi.deleteSupplier(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении поставщика',
        success: 'Поставщик успешно удален',
      }),
    );
  }

  createSupplierV3(variables: MutationCreateSupplierV3Args): MutationResult<'createSupplierV3'> {
    return this.supplierApi.createSupplierV3(variables)?.pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании поставщика',
      }),
    );
  }

  updateSupplierV3(variables: MutationUpdateSupplierV3Args): MutationResult<'updateSupplierV3'> {
    return this.supplierApi.updateSupplierV3(variables)?.pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении поставщика',
      }),
    );
  }

  deleteSupplierV2(variables: MutationDeleteSupplierV2Args): MutationResult<'deleteSupplierV2'> {
    return this.supplierApi.deleteSupplierV2(variables)?.pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении поставщика',
        success: 'Поставщик успешно удален',
      }),
    );
  }
}
