import { Injectable } from '@angular/core';

import { NotifyService } from '@services/shared';
import {
  MutationCreateOrganizationSiteArgs,
  MutationResult,
  MutationUpdateOrganizationSiteArgs,
  QueryOrganizationSiteArgs,
  QueryOrganizationSitesByAccountArgs,
  QueryResult,
} from '@typings';

import { OrganizationApi } from './organization.api';

@Injectable({
  providedIn: 'root',
})
export class OrganizationStorage {
  constructor(private organizationApi: OrganizationApi, private notifyService: NotifyService) {}

  getCurrencyList() {
    return this.organizationApi.getCurrencyList().pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка валют',
      }),
    );
  }

  getOrganizationSitesByAccount(variables: QueryOrganizationSitesByAccountArgs): QueryResult<'organizationSitesByAccount'> {
    return this.organizationApi.getOrganizationSitesByAccount(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка организаций',
      }),
    );
  }

  createOrganizationSite(variables: MutationCreateOrganizationSiteArgs): MutationResult<'createOrganizationSite'> {
    return this.organizationApi.createOrganizationSite(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании организации',
      }),
    );
  }

  updateOrganizationSite(variables: MutationUpdateOrganizationSiteArgs): MutationResult<'updateOrganizationSite'> {
    return this.organizationApi.updateOrganizationSite(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении организации',
      }),
    );
  }

  organizationSite(variables: QueryOrganizationSiteArgs): QueryResult<'organizationSite'> {
    return this.organizationApi.organizationSite(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении организации',
      }),
    );
  }
}
