import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrganizationSite } from '@typings';

import { OrganizationStorage } from '../../services/organization/organization.storage';

@Injectable({
  providedIn: 'root',
})
export class OrganizationResolver implements Resolve<OrganizationSite> {
  constructor(private organizationStorage: OrganizationStorage) {
    // TODO: show preloader
  }

  resolve(route: ActivatedRouteSnapshot): Observable<OrganizationSite> {
    const id = route.params.id;

    return this.organizationStorage.organizationSite({ id }).pipe(
      map((res) => res.data.organizationSite!),
      // TODO: hide preloader
    );
  }
}
