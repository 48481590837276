import { gql } from 'apollo-angular';

export const fragmentMutationResult = gql`
  fragment MutationResult on MutationResult {
    entityId
    errorMessage
    result
  }
`;

export const fragmentPage = gql`
  fragment Page on Page {
    pageNumber
    pageSize
    total
    totalPages
  }
`;

export const fragmentRequestResult = gql`
  fragment RequestResult on RequestResult {
    result
    errorMessage
    transactionId
  }
`;
