<div class="total-row" [style.width]="tableWidth ? tableWidth + 'px' : ''">
  <div class="drag-placeholder" *cdkDragPlaceholder></div>
  <div
    *ngIf="
      config.dragAndDropOptions?.enable && (breakpointObserverService.isMobile$ | async) === false && !table.isDragging
    "
    class="drag-handler"
  ></div>
  <div class="inner" [style.gridTemplateColumns]="gridTemplateColumns">
    <div
      class="column"
      [ngClass]="{ 'column-numeric': column?.type === 'numeric' }"
      [class.grouped-row]="row?.isGroupedRow"
      *ngFor="let column of columns; let i = index"
    >
      <div class="column-content" [style.justify-content]="config?.columnsConfig[i]?.align || 'initial'">
        <div class="column-expand" *ngIf="(row?.children?.length || row?.lazyChildren) && i === expandColumn">
          <nm-svg-icon *ngIf="row?.expanded" name="keyboardArrowDown" [size]="24"></nm-svg-icon>
          <nm-svg-icon *ngIf="!row?.expanded" name="keyboardArrowRight" [size]="24"></nm-svg-icon>
        </div>

        <div
          class="column-expand"
          [ngClass]="{ 'hide-expand': row.hideExpand }"
          *ngIf="!row?.children?.length && !row?.lazyChildren && i === expandColumn && config.expandable"
        ></div>

        <ng-container *ngIf="contentIsDefined(column)">
          <div class="total-column-data">
            <div class="col-content">
              {{ column.content }}
            </div>
            <div class="sub-content">
              {{ column.subContent }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
