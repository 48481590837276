import { TokenRefreshLink } from 'apollo-link-token-refresh';

import { ApolloLink, Operation } from '@apollo/client/core';
import { REFRESH_TOKEN_OPERATION_EXCEPTIONS } from '@constants';
import { RefreshLinkProps } from '@typings';

const createRefreshLink = ({ storage }: RefreshLinkProps): ApolloLink => {
  return new TokenRefreshLink({
    isTokenValidOrUndefined: ({ operationName }: Operation) => {
      if (REFRESH_TOKEN_OPERATION_EXCEPTIONS.includes(operationName)) {
        return true;
      }

      const token = storage.getAccessToken();

      if (!token) {
        return true;
      }

      // if token has incorrect format or with corraupted data
      try {
        return storage.isAccessTokenValid();
      } catch {
        console.error('Error while check token!');
        return false;
      }
    },
    handleResponse: () => {},
    fetchAccessToken: async () => storage.fetchRefreshToken(),
    handleFetch: () => {},
  });
};

export { createRefreshLink };
