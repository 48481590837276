import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ExportReportService } from '@app/shared/service/export-report.service';
import { exportUrlByReportType } from '@constants';
import { CoreSchema, LeftoverExportFilters, LeftoverPage, QueryLeftoversArgs, WarehouseItemLeftoverPage } from '@typings';

import { LeftoversStorage } from './leftovers.storage';

@Injectable({
  providedIn: 'root',
})
export class LeftoversService {
  exportReportType = exportUrlByReportType;

  constructor(private leftoversStorage: LeftoversStorage, private exportReportService: ExportReportService) {}

  allWarehouseItemLeftovers(variables: CoreSchema.QueryWarehouseItemLeftoversArgs): Observable<WarehouseItemLeftoverPage> {
    return this.leftoversStorage.allWarehouseItemLeftoversPageble(variables).pipe(map((res) => res.data.warehouseItemLeftovers));
  }

  leftovers(variables: QueryLeftoversArgs): Observable<LeftoverPage> {
    return this.leftoversStorage.leftovers(variables).pipe(map((res) => res.data.leftovers));
  }

  exportReport(warehouseId: string): void {
    this.exportReportService.exportReportWithHandler(
      this.exportReportType.REMAINDER.url,
      {
        warehouseId: warehouseId,
      },
      this.exportReportType.REMAINDER.fileName,
    );
  }

  exportReportAll(params: LeftoverExportFilters): void {
    const { search, warehouseIds, types, sectionIds } = params;

    const exportParams: Record<string, string> = {
      zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if (!!search) {
      exportParams.search = search;
    }

    if (!!warehouseIds && !!warehouseIds.length) {
      exportParams.warehouseIds = warehouseIds.join(',');
    }

    if (!!types && !!types.length) {
      exportParams.types = types.join(',');
    }

    if (!!sectionIds && !!sectionIds.length) {
      exportParams.sectionIds = sectionIds.join(',');
    }

    this.exportReportService.exportReportWithHandler(
      this.exportReportType.REMAINDER_V2.url,
      exportParams,
      this.exportReportType.REMAINDER_V2.fileName,
    );
  }
}
