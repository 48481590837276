import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { NotifyService } from '@services/shared';
import {
  CoreSchema,
  MutationBatchResult,
  MutationCreateProductArgs,
  MutationDeleteProductArgs,
  MutationDuplicateProductArgs,
  MutationMoveProductArgs,
  MutationResult,
  MutationUpdateProductV3Args,
  Product,
  ProductType,
  QueryAllCatalogProductsPageableArgs,
  QueryGetWhereUsedPageableArgs,
  QueryProductArgs,
  QueryProductsArgs,
  QueryResult,
  QueryStockUnitsArgs,
} from '@typings';

import { ProductApi } from './product.api';

@Injectable({
  providedIn: 'root',
})
export class ProductStorage {
  protected productType: ProductType = 'PRODUCT';

  #product = new BehaviorSubject<CoreSchema.Product | null>(null);
  product$ = this.#product.asObservable();

  setProduct(product: Product | null): void {
    this.#product.next(product);
  }

  getCurrentProduct(): Product {
    return this.#product.getValue()!;
  }

  constructor(protected notifyService: NotifyService, protected productApi: ProductApi) {}

  createProduct(variables: MutationCreateProductArgs): MutationResult<'createProductV2'> {
    return this.productApi.createProductV2(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании товара',
      }),
    );
  }

  createProductWithStockUnit(variables: MutationCreateProductArgs): MutationResult<'createProductV2'> {
    return this.productApi.createProductV2WithStockUnit(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании товара',
      }),
    );
  }

  updateProduct(variables: MutationUpdateProductV3Args): MutationResult<'updateProductV3'> {
    return this.productApi.updateProduct(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при изменении товара',
      }),
    );
  }

  updateProductsBatch(variables: MutationUpdateProductV3Args[]): MutationBatchResult<'updateProductV3'> {
    const isMultiple = variables.length > 1;

    return this.productApi.updateProductBatch(variables).pipe(
      this.notifyService.$notify({
        error: isMultiple ? 'Ошибка при обновлении товаров' : 'Ошибка при обновлении товара',
      }),
    );
  }

  deleteProduct(variables: MutationDeleteProductArgs): MutationResult<'deleteProduct'> {
    return this.productApi.deleteProduct(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении товара',
        success: 'Товар успешно удален',
      }),
    );
  }

  duplicateProduct(variables: MutationDuplicateProductArgs): MutationResult<'duplicateProduct'> {
    return this.productApi.duplicateProduct(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при дублировании товара',
      }),
    );
  }

  moveProductsToCategory(variables: MutationMoveProductArgs[]): MutationBatchResult<'moveProduct'> {
    const isMultiple = variables.length > 1;
    return this.productApi.moveProductBatch(variables).pipe(
      this.notifyService.$notify({
        error: isMultiple ? 'Ошибка при перемещении товаров в категорию' : 'Ошибка при перемещении товара в категорию',
      }),
    );
  }

  getAllProductsPageable(variables: QueryAllCatalogProductsPageableArgs): QueryResult<'allCatalogProductsPageable'> {
    variables.filter.type = this.productType;
    return this.productApi.getAllProductsPageable(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка товаров',
      }),
    );
  }

  deleteProducts(variables: { id: string }[]): MutationBatchResult<'deleteProduct'> {
    const isMultiple = variables.length > 1;
    return this.productApi.deleteProducts(variables).pipe(
      this.notifyService.$notify({
        error: isMultiple ? 'Ошибка при удалении товаров' : 'Ошибка при удалении товара',
        success: isMultiple ? 'Товары успешно удалены' : 'Товар успешно удален',
      }),
    );
  }

  getProductArrivals(variables: { id: string }): QueryResult<'productArrivalsExist'> {
    return this.productApi.getProductArrivals(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении информации о поставках',
      }),
    );
  }

  getProduct(variables: QueryProductArgs): QueryResult<'product'> {
    return this.productApi.getProduct(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении товара',
      }),
    );
  }

  getProductShort(variables: QueryProductArgs): QueryResult<'product'> {
    return this.productApi.getProductShort(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении товара',
      }),
    );
  }

  getUsage(variables: QueryGetWhereUsedPageableArgs): QueryResult<'getWhereUsedPageable'> {
    return this.productApi.getWhereUsedPageable(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка товаров',
      }),
    );
  }

  getProductsFilteredByType(variables?: QueryProductsArgs): QueryResult<'products'> {
    if (!variables) {
      variables = {};
    }

    if (!variables.filter) {
      variables.filter = {};
    }

    if (!('type' in variables.filter)) {
      variables.filter.type = this.productType;
    }

    return this.getProducts(variables);
  }

  getProducts(variables?: QueryProductsArgs): QueryResult<'products'> {
    return this.productApi.products(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка товаров',
      }),
    );
  }

  getProductsShort(variables?: QueryProductsArgs): QueryResult<'products'> {
    return this.productApi.productsShort(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка товаров',
      }),
    );
  }

  getStockUnits(variables?: QueryStockUnitsArgs): QueryResult<'stockUnits'> {
    return this.productApi.stockUnits(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка товаров',
      }),
    );
  }
}
