import { gql } from 'apollo-angular';

export const querySearchBankByRequest = gql`
  query searchBankByRequest($request: String!) {
    searchBankByRequest(request: $request) {
      name
      correspondentAccount
      bic
    }
  }
`;
