import { NativeDateAdapter } from '@angular/material/core';

export const PARSE_MONTH_FORMAT = 'month';
export const RU_PARSE_FORMAT = 'DD.MM.YYYY';
export class NmDatepickerDateAdapter extends NativeDateAdapter {
  getDayOfWeekNames(): string[] {
    return ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
  }

  getFirstDayOfWeek(): number {
    return 1;
  }

  public parse(date: string, displayFormat: string): Date | null {
    let formatted = date || '';
    if (displayFormat === PARSE_MONTH_FORMAT) {
      const [month, year] = formatted.split('.');

      if (month.length === 2 && year.length === 4) {
        formatted = `${month}-01-${year}`;
      }
    }

    const parsedDate = date.split('.');
    if (parsedDate.length === 3) {
      let resultDate = new Date();
      resultDate.setDate(parseInt(parsedDate[0]));
      resultDate.setMonth(parseInt(parsedDate[1]) - 1);
      resultDate.setFullYear(parseInt(parsedDate[2]));
      return super.parse(resultDate, displayFormat);
    }

    return super.parse(formatted, displayFormat);
  }
}
