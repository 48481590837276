import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Popup } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private initialPopup: Popup = {
    title: '',
    description: '',
    acceptTitle: '',
    declineTitle: '',
    onClose(): void {},
    onAccept(): void {},
    onDecline(): void {},
  };

  private popup = new BehaviorSubject<Popup>(this.initialPopup);
  public popup$ = this.popup.asObservable();

  private loading = new BehaviorSubject<boolean>(false);
  public loading$ = this.loading.asObservable();

  constructor() {}

  public showPopup(data: Popup): void {
    this.popup.next(data);
  }

  public hidePopup(): void {
    this.popup.next(this.initialPopup);
  }

  public startLoading(): void {
    this.loading.next(true);
  }

  public stopLoading(): void {
    this.loading.next(false);
  }
}
