import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { InventoriesService } from '@app/modules/warehouse/services';
import { ROUTE_CREATE_NEW, WarehouseRouteParam } from '@constants';
import { InventoryDoc } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class InventoryResolver implements Resolve<InventoryDoc> {
  constructor(private router: Router, private inventoriesService: InventoriesService) {
    // TODO: show preloader
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InventoryDoc> {
    const id = route.params[WarehouseRouteParam.inventoryId];

    return this.inventoriesService.getInventory(id).pipe(
      // TODO: hide preloader
      catchError((err) => {
        this.router.navigateByUrl(state.url.replace(`${id}`, ROUTE_CREATE_NEW));

        return throwError(() => err);
      }),
    );
  }
}
