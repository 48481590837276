import { gql } from 'apollo-angular';

import { fragmentMutationResult } from './mutations';

export const mutationCreateLeavingDocument = gql`
  mutation createLeavingDocument($leavingDocumentCreateInput: LeavingDocumentCreateInput!) {
    createLeavingDocument(leavingDocumentCreateInput: $leavingDocumentCreateInput) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationUpdateLeavingDocument = gql`
  mutation updateLeavingDocument($leavingDocumentUpdateInput: LeavingDocumentUpdateInput!) {
    updateLeavingDocument(leavingDocumentUpdateInput: $leavingDocumentUpdateInput) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationDeleteLeavingDocument = gql`
  mutation deleteLeavingDocument($leavingDocumentId: UUID!) {
    deleteLeavingDocument(leavingDocumentId: $leavingDocumentId) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationDuplicateLeavingDocument = gql`
  mutation duplicateLeavingDocument($documentId: UUID!) {
    duplicateLeavingDocument(documentId: $documentId) {
      output {
        id
      }
      errorMessage
      result
    }
  }
`;

export const mutationConfirmLeavingDocument = gql`
  mutation confirmLeavingDocument($leavingDocumentId: UUID!, $warehouseId: UUID!) {
    confirmLeavingDocument(leavingDocumentId: $leavingDocumentId, warehouseId: $warehouseId) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationRejectLeavingDocument = gql`
  mutation rejectLeavingDocument($leavingDocumentId: UUID!) {
    rejectLeavingDocument(leavingDocumentId: $leavingDocumentId) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const queryAllLeavingDocumentsPageable = gql`
  query allLeavingDocumentsPageable($filter: DocumentFilterInput!, $pageRequest: PageRequestInput!) {
    allLeavingDocumentsPageable(filter: $filter, pageRequest: $pageRequest) {
      content {
        id
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
`;

export const queryAllLeavingDocumentsDataPageable = gql`
  query allLeavingDocumentsPageable($filter: DocumentFilterInput!, $pageRequest: PageRequestInput!) {
    allLeavingDocumentsPageable(filter: $filter, pageRequest: $pageRequest) {
      content {
        id
        organization {
          id
          name
        }
        documentItems {
          name
          factQuantity
          stockUnit {
            id
            name
            unit
            primePrice {
              amountValue
              currencyUnit
            }
          }
          amount
        }
        docNumber
        date
        creationDate
        typeDocument
        status
        description
        from {
          id
          name
          addressName
          description
          phone
          businessHours
          shippingCenter
          pickupLocation
          active
        }
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
`;

export const queryLeavingDocument = gql`
  query leavingDocument($id: UUID!) {
    leavingDocument(id: $id) {
      id
      organization {
        id
        name
      }
      documentItems {
        id
        name
        factQuantity
        convertableUnit {
          convertableUnitType
          convertableQuantity
        }
        stockUnit {
          id
          name
          unit
          primePrice {
            amountValue
            currencyUnit
          }
          unitTypesMatchList
        }
        amount
      }
      docNumber
      date
      creationDate
      typeDocument
      status
      description
      from {
        id
        name
        addressName
        description
        phone
        businessHours
        shippingCenter
        pickupLocation
        active
      }
    }
  }
`;

export const queryAllLeavingDocumentsPageableV2 = gql`
  query allLeavingDocumentsPageableV2(
    $filter: InventoryDocumentFilterInputV2!
    $pageRequest: PageRequestInput!
    $sort: [AllLeavingDocumentsPageableV2Sort]
  ) {
    allLeavingDocumentsPageableV2(filter: $filter, pageRequest: $pageRequest, sort: $sort) {
      content {
        id
        organization {
          id
          name
        }
        documentItems {
          name
          factQuantity
          stockUnit {
            id
            name
            unit
            primePrice {
              amountValue
              currencyUnit
            }
          }
          amount
        }
        docNumber
        date
        creationDate
        typeDocument
        status
        description
        from {
          id
          name
          addressName
          description
          phone
          businessHours
          shippingCenter
          pickupLocation
          active
        }
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
`;

export const fragmentWriteOffDoc = gql`
  fragment WriteOffDoc on WriteOffDoc {
    id
    date
    description
    number
    status
    sum
    revertedDate
    storageRoom {
      id
      name
      archived
    }
  }
`;

export const queryWriteOffDoc = gql`
  query writeOffDoc($id: UUID!) {
    writeOffDoc(id: $id) {
      ...WriteOffDoc
    }
  }
  ${fragmentWriteOffDoc}
`;

export const queryWriteOffDocs = gql`
  query writeOffDocs($filter: WriteOffDocFilter!, $pageRequest: PageRequestInput!, $sort: [WriteOffDocSort!]) {
    writeOffDocs(filter: $filter, pageRequest: $pageRequest, sort: $sort) {
      content {
        ...WriteOffDoc
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
  ${fragmentWriteOffDoc}
`;

export const queryWriteOffProducts = gql`
  query writeOffProducts($filter: WriteOffProductFilter!, $pageRequest: PageRequestInput!) {
    writeOffProducts(filter: $filter, pageRequest: $pageRequest) {
      content {
        id
        quantity
        unit
        sum
        product {
          id
          name
          unit
          quantity
          primePrice {
            amountValue
          }
          weightedAveragePrimePrice {
            amountValue
          }
        }
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
`;

export const mutationCreateWriteOffDoc = gql`
  mutation createWriteOffDoc($input: CreateWriteOffDocInput!) {
    createWriteOffDoc(input: $input) {
      errorMessage
      result
      output {
        ...WriteOffDoc
      }
    }
  }
  ${fragmentWriteOffDoc}
`;

export const mutationUpdateWriteOffDoc = gql`
  mutation updateWriteOffDoc($input: UpdateWriteOffDocInput!) {
    updateWriteOffDoc(input: $input) {
      errorMessage
      result
      output {
        ...WriteOffDoc
      }
    }
  }
  ${fragmentWriteOffDoc}
`;

export const mutationConfirmWriteOffDoc = gql`
  mutation confirmWriteOffDoc($id: UUID!) {
    confirmWriteOffDoc(id: $id) {
      errorMessage
      result
    }
  }
`;

export const mutationRevertWriteOffDoc = gql`
  mutation revertWriteOffDoc($id: UUID!) {
    revertWriteOffDoc(id: $id) {
      errorMessage
      result
    }
  }
`;

export const mutationDeleteWriteOffDoc = gql`
  mutation deleteWriteOffDoc($id: UUID!) {
    deleteWriteOffDoc(id: $id) {
      errorMessage
      result
    }
  }
`;
