<label
  #switchElement
  class="nm-switch"
  [ngClass]="{
    'nm-switch--active': checked,
    'nm-switch--disabled': disabled,
    'nm-switch--icon': isIcon
  }"
>
  <input
    hidden
    type="checkbox"
    [ngModel]="checked"
    [attr.data-test-id]="testId"
    [disabled]="disabled"
    (ngModelChange)="$event === true ? onAnimation(switchElement) : offAnimation(switchElement)"
  />

  <div class="nm-switch__container">
    <div class="nm-switch__toggle">
      <nm-svg-icon *ngIf="isIcon" class="nm-switch__icon nm-switch__icon--close" name="close" [size]="16"></nm-svg-icon>

      <nm-svg-icon
        *ngIf="isIcon && !disabled"
        class="nm-switch__icon nm-switch__icon--done"
        name="check"
        [size]="16"
      ></nm-svg-icon>
    </div>
  </div>
</label>

<div *ngIf="disabled" class="nm-switch__stub"></div>
