import { AllSuppliersPageableSortFieldStrict, SupplierUnion, TableHeader, TypeSupplierStrict, TypeSupplierString } from '@typings';

export const TYPE_SUPPLIER: TypeSupplierStrict = {
  LEGAL_PERSON: 'LEGAL_PERSON',
  NATURAL_PERSON: 'NATURAL_PERSON',
};

export const TYPE_SUPPLIER_STRING: TypeSupplierString = {
  LEGAL_PERSON: 'Юридическое лицо',
  NATURAL_PERSON: 'Физическое лицо',
};

export const TYPE_SUPPLIER_STRING_SHORT: TypeSupplierString = {
  LEGAL_PERSON: 'Юр. лицо',
  NATURAL_PERSON: 'Физ. лицо',
};

export const SUPPLIER_TABLE_HEADERS: TableHeader[] = [
  {
    id: 'companyName',
    title: 'Компания',
    direction: 'DESC',
    isSort: true,
    isRight: false,
  },
  {
    id: 'contactPoint',
    title: 'Контактное лицо',
    direction: 'DESC',
    isSort: true,
    isRight: false,
  },
  {
    id: 'type',
    title: 'Тип поставщика',
    direction: 'DESC',
    isSort: true,
    isRight: false,
  },
  {
    id: 'tel',
    title: 'Телефон',
    direction: 'DESC',
    isSort: false,
    isRight: false,
  },
  {
    id: 'sum',
    title: 'Сумма всех поставок',
    direction: 'DESC',
    isSort: false,
    isRight: false,
  },
];

export const SUPPLIERS_SORT_FIELD: AllSuppliersPageableSortFieldStrict = {
  COMPANY_NAME: 'COMPANY_NAME',
  CONTACT_POINT: 'CONTACT_POINT',
  TYPE: 'TYPE',
  TOTAL_COSTS: 'TOTAL_COSTS',
};

export const isLegalSuppliers = (value: SupplierUnion): boolean => '__typename' in value && value['__typename'] === 'SupplierLegal';
