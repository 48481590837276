import { Pipe, PipeTransform } from '@angular/core';

import { CHART_AVERAGE_COLOR, CHART_CURRENT_COLOR, CHART_HIGH_COLOR } from '@constants';
import { ChartData, ChartType, CoreSchema, DatasetType, DefaultDataPoint } from '@typings';
import { chartBarWideParams } from '@utils';

@Pipe({
  name: 'analyticsChartPopularData',
})
export class AnalyticsChartPopularDataPipe implements PipeTransform {
  constructor() {}

  transform(data: CoreSchema.PopularProduct[]): ChartData<ChartType, DefaultDataPoint<ChartType>, string> {
    const labels: string[] = [];
    const profit: number[] = [];
    const profitPercent: number[] = [];
    const quantity: number[] = [];
    const revenue: number[] = [];
    const cost: number[] = [];
    const price: number[] = [];

    data.forEach((item) => {
      labels.push(item.name);
      profit.push(Number(item.profit));
      profitPercent.push(Number(item.profitPercent));
      quantity.push(Number(item.quantity));
      revenue.push(Number(item.revenue));
      cost.push(Number(item.revenue) - Number(item.profit));
      price.push(Number(item.revenue) / Number(item.quantity));
    });

    const barWideParams = chartBarWideParams();

    const datasets: DatasetType[] = [
      // keep this dataset first!
      {
        label: 'Средняя цена, ₽',
        data: price,
        xAxisID: 'xPrice',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      {
        label: 'Продано, шт',
        data: quantity,
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        yAxisID: 'yUnit',
        xAxisID: 'xUnit',
      },
      {
        label: 'Маржинальность, %',
        data: profitPercent,
        order: 0,
        type: 'line',
        tension: 0,
        yAxisID: 'yPercent',
        pointBackgroundColor: '#FFE978',
        pointBorderColor: '#fff',
        backgroundColor: '#FFE978',
        borderColor: '#FFE978',
        pointRadius: 4,
        pointBorderWidth: 2,
      },
      {
        label: 'Выручка, ₽',
        data: revenue,
        ...barWideParams,
        backgroundColor: CHART_CURRENT_COLOR,
      },
      {
        label: 'Прибыль, ₽',
        data: profit,
        ...barWideParams,
        backgroundColor: CHART_HIGH_COLOR,
      },
      {
        label: 'Себестоимость, ₽',
        data: cost,
        ...barWideParams,
        backgroundColor: CHART_AVERAGE_COLOR,
      },
    ];

    const chartData: ChartData<ChartType, DefaultDataPoint<ChartType>, string> = {
      labels,
      datasets,
    };

    return chartData;
  }
}
