import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationDrawerService {
  avatarUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() {}
}
