import { Injectable } from '@angular/core';

import {
  mutationOrderAddon,
  mutationOrderRecurringBill,
  mutationOrderServicePlan,
  mutationPayOrder,
  queryAddon,
  queryAddons,
  queryBill,
  queryBills,
  queryServicePlansV2,
  queryServicePlanUsageSummary,
  queryServicePlanV2,
  querySubscriber,
} from '@api';
import { ApiService } from '@core/service';
import {
  Mutation,
  MutationOrderAddonArgs,
  MutationOrderRecurringBillArgs,
  MutationOrderServicePlanArgs,
  MutationPayOrderArgs,
  MutationResult,
  Query,
  QueryAddonArgs,
  QueryAddonsArgs,
  QueryBillArgs,
  QueryBillsArgs,
  QueryResult,
  QueryServicePlansV2Args,
  QueryServicePlanV2Args,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsApi {
  constructor(private apiService: ApiService) {}

  subscriber(): QueryResult<'subscriber'> {
    return this.apiService.query<Query<'subscriber'>, {}>({ query: querySubscriber });
  }

  servicePlanV2(variables: QueryServicePlanV2Args): QueryResult<'servicePlanV2'> {
    return this.apiService.query<Query<'servicePlanV2'>, QueryServicePlanV2Args>({
      query: queryServicePlanV2,
      variables,
    });
  }

  servicePlansV2(variables: QueryServicePlansV2Args): QueryResult<'servicePlansV2'> {
    return this.apiService.query<Query<'servicePlansV2'>, QueryServicePlansV2Args>({
      query: queryServicePlansV2,
      variables,
    });
  }

  servicePlanUsageSummary(): QueryResult<'servicePlanUsageSummary'> {
    return this.apiService.query<Query<'servicePlanUsageSummary'>, {}>({
      query: queryServicePlanUsageSummary,
    });
  }

  addon(variables: QueryAddonArgs): QueryResult<'addon'> {
    return this.apiService.query<Query<'addon'>, QueryAddonArgs>({
      query: queryAddon,
      variables,
    });
  }

  addons(variables: QueryAddonsArgs): QueryResult<'addons'> {
    return this.apiService.query<Query<'addons'>, QueryAddonsArgs>({
      query: queryAddons,
      variables,
    });
  }

  bill(variables: QueryBillArgs): QueryResult<'bill'> {
    return this.apiService.query<Query<'bill'>, QueryBillArgs>({
      query: queryBill,
      variables,
    });
  }

  bills(variables: QueryBillsArgs): QueryResult<'bills'> {
    return this.apiService.query<Query<'bills'>, QueryBillsArgs>({
      query: queryBills,
      variables,
    });
  }

  orderServicePlan(variables: MutationOrderServicePlanArgs): MutationResult<'orderServicePlan'> {
    return this.apiService.mutate<Mutation<'orderServicePlan'>, MutationOrderServicePlanArgs>({
      mutation: mutationOrderServicePlan,
      variables,
    });
  }

  orderRecurringBill(variables: MutationOrderRecurringBillArgs): MutationResult<'orderRecurringBill'> {
    return this.apiService.mutate<Mutation<'orderRecurringBill'>, MutationOrderRecurringBillArgs>({
      mutation: mutationOrderRecurringBill,
      variables,
    });
  }

  orderAddon(variables: MutationOrderAddonArgs): MutationResult<'orderAddon'> {
    return this.apiService.mutate<Mutation<'orderAddon'>, MutationOrderAddonArgs>({
      mutation: mutationOrderAddon,
      variables,
    });
  }

  payOrder(variables: MutationPayOrderArgs): MutationResult<'payOrder'> {
    return this.apiService.mutate<Mutation<'payOrder'>, MutationPayOrderArgs>({
      mutation: mutationPayOrder,
      variables,
    });
  }
}
