import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { SignerBase, SignerBaseStrict, SignerBaseString } from '@typings';

export const SINGER_BASE: SignerBaseStrict = {
  PROCURATION: 'PROCURATION',
  CHARTER: 'CHARTER',
};

export const SINGER_BASE_STRING: SignerBaseString = {
  PROCURATION: 'Доверенность',
  CHARTER: 'Устав',
};

export const SINGER_BASE_LIST: AutocompleteOption<SignerBase>[] = Object.keys(SINGER_BASE).map((singerBase) => ({
  id: singerBase,
  type: 'item',
  label: SINGER_BASE_STRING[singerBase],
}));
