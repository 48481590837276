import { Injectable } from '@angular/core';

import { UiApi } from '@app/shared/service/UI-service/ui.api';
import { MutationUpdateUiStateArgs } from '@app/typings/ui';
import { NotifyService } from '@services/shared';
import { MutationResult, QueryResult } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class UiStorage {
  constructor(private uiApi: UiApi, private notifyService: NotifyService) {}

  setColorTheme(variables: MutationUpdateUiStateArgs): MutationResult<'updateUIState'> {
    return this.uiApi.setColorTheme(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании сотрудника',
      }),
    );
  }

  getColorTheme(): QueryResult<'getUIState'> {
    return this.uiApi.getColorTheme().pipe(
      this.notifyService.$notify({
        error: 'Ошибка при отправке приглашения',
      }),
    );
  }
}
