import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { InvitationStorage } from '@services/auth';

@Injectable()
export class InvitationGuard implements CanActivate {
  constructor(private invitationStorage: InvitationStorage) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { t } = route.queryParams;
    const { url } = state;
    if (url.includes('accept-invitation')) {
      if (t) {
        this.invitationStorage.setInvitation(t);
      }
    }

    return true;
  }
}
