import { HttpErrorResponse } from '@angular/common/http';
import { GraphQLFormattedError } from 'graphql';

import { errorAuthorization, errorInvitation, errorPayment, errorUUID } from '@api';
import { ApolloLink, Observable } from '@apollo/client/core';
import { ErrorHandler, ErrorResponse, onError } from '@apollo/client/link/error';
import { ERROR_MESSAGE_PAYMENT_REQUIRED, ERROR_NETWORK_STATUS } from '@constants';
import { ErrorLinkProps, Notification, ValidationErrorWithKey } from '@typings';

export const createErrorLink = ({ storage, notifyService, validationErrorsService }: ErrorLinkProps): ApolloLink => {
  const errorHandler: ErrorHandler = (errorResponse: ErrorResponse) => {
    const { graphQLErrors, networkError, operation, response } = errorResponse;

    const httpErrorResponse = networkError as HttpErrorResponse; // assing correct type for network error

    // catch 401 NOT UNAUTORIZED request
    // try to get new refresh token and re-request again
    if (httpErrorResponse && httpErrorResponse.status) {
      if (httpErrorResponse.status === ERROR_NETWORK_STATUS.UNAUTHORIZED) {
        return errorAuthorization({ errorResponse, storage });
      } else if (httpErrorResponse && httpErrorResponse.status === ERROR_NETWORK_STATUS.UNKNOWN_ERROR) {
        notifyService.addNotification({
          type: 'alert',
          title: 'Ошибка соединения',
        });
        return new Observable((observer) => {
          if (response) {
            observer.next(response);
          }
          observer.complete();
        });
      }
    }

    const { errors } = response || {};
    if (errors) {
      const paymentRequired = errors.find((e) => e.message === ERROR_MESSAGE_PAYMENT_REQUIRED);
      if (paymentRequired) return errorPayment({ errorResponse, storage });
    }

    // catch error with Invalid UUID
    if (graphQLErrors && graphQLErrors.find(({ message }) => message.includes('Invalid UUID'))) {
      return errorUUID({ errorResponse, storage });
    }

    // catch error while getting `invitation` info
    // TODO: REMOVE AFTER ng-erp#790
    if (graphQLErrors && operation.operationName === 'getInvitation') {
      return errorInvitation({ errorResponse, storage });
    }

    if (graphQLErrors && operation.operationName === 'confirmSupportControlClaim') {
      notifyService.addNotification({
        type: 'alert',
        title: 'Введен неверный код',
      });

      return new Observable((observer) => {
        if (response) {
          observer.next(response);
        }

        observer.complete();
      });
    }

    if (graphQLErrors) {
      const validationGraphQLErrors: GraphQLFormattedError[] = [];
      const dataFetchingExceptionErrors: GraphQLFormattedError[] = [];
      const accessDeniedExceptionErrors: GraphQLFormattedError[] = [];
      const commandExecutionExceptionErrors: GraphQLFormattedError[] = [];
      const rateLimitExceptionErrors: GraphQLFormattedError[] = [];

      graphQLErrors.forEach((graphQLError) => {
        if (
          graphQLError.extensions?.type === 'GraphQLValidationException' &&
          graphQLError.extensions.classification === 'ValidationError'
        ) {
          validationGraphQLErrors.push(graphQLError);
        }

        if (
          graphQLError.extensions?.type === 'WrongCredentialExeption' &&
          graphQLError.extensions.classification === 'DataFetchingException'
        ) {
          dataFetchingExceptionErrors.push(graphQLError);
        }

        if (graphQLError.extensions?.type === 'RateLimitException' && graphQLError.extensions.classification === 'DataFetchingException') {
          rateLimitExceptionErrors.push(graphQLError);
        }

        if (graphQLError.extensions?.type === 'AccessDeniedException') {
          accessDeniedExceptionErrors.push(graphQLError);
        }

        if (
          graphQLError.extensions?.type === 'CommandExecutionException' &&
          graphQLError.message === 'This account is already employed in this organization'
        ) {
          const notify: Notification = {
            type: 'alert',
            title: 'Пользователь уже зарегистрирован в организации',
          };

          notifyService.addNotification(notify);
        }

        if (graphQLError.extensions?.type === 'CommandExecutionException') {
          commandExecutionExceptionErrors.push(graphQLError);

          throw graphQLError;
        }
      });

      if (Boolean(validationGraphQLErrors.length)) {
        const operationName = operation.operationName;
        const validationErrorsWithKeys = [...validationErrorsService.getValidationErrors()].filter((error) => error.key !== operationName);

        const validationErrors = validationGraphQLErrors.map((graphQLError) =>
          validationErrorsService.graphQLErrorToValidationErrors(graphQLError),
        );

        const error: ValidationErrorWithKey = {
          key: operationName,
          errors: validationErrors,
        };

        validationErrorsService.setValidationErrors([...validationErrorsWithKeys, error]);

        const notify: Notification = {
          type: 'information',
          title: validationGraphQLErrors[0].message,
        };

        notifyService.addNotification(notify);
      }

      if (Boolean(dataFetchingExceptionErrors.length)) {
        const notify: Notification = {
          type: 'alert',
          title: dataFetchingExceptionErrors[0].message,
        };

        notifyService.addNotification(notify);
      }

      if (Boolean(accessDeniedExceptionErrors.length)) {
        const notify: Notification = {
          type: 'alert',
          title: accessDeniedExceptionErrors[0].message,
        };

        notifyService.addNotification(notify);
      }
    }

    return new Observable((observer) => {
      if (response) {
        observer.next(response);
      }

      observer.complete();
    });
  };

  return onError(errorHandler);
};
