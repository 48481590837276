import { gql } from 'apollo-angular';

import { fragmentMutationResult } from './mutations';

export const fragmentArrivalDocumentResult = gql`
  fragment ArrivalDocumentResult on ArrivalDocumentResult {
    output {
      id
    }
    errorMessage
    result
  }
`;

export const mutationCreateArrivalDocument = gql`
  mutation createArrivalDocument($arrivalDocumentCreateInput: ArrivalDocumentCreateInput!) {
    createArrivalDocument(arrivalDocumentCreateInput: $arrivalDocumentCreateInput) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationUpdateArrivalDocument = gql`
  mutation updateArrivalDocument($arrivalDocumentUpdateInput: ArrivalDocumentUpdateInput!) {
    updateArrivalDocument(arrivalDocumentUpdateInput: $arrivalDocumentUpdateInput) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationDeleteArrivalDocument = gql`
  mutation deleteArrivalDocument($arrivalDocumentId: UUID!) {
    deleteArrivalDocument(arrivalDocumentId: $arrivalDocumentId) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationSetArrivalDocumentPaymentStatus = gql`
  mutation setArrivalDocumentPaymentStatus($arrivalDocumentId: UUID!, $paymentStatus: ArrivalDocumentPaymentStatus!) {
    setArrivalDocumentPaymentStatus(arrivalDocumentId: $arrivalDocumentId, paymentStatus: $paymentStatus) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationDuplicateArrivalDocument = gql`
  mutation duplicateArrivalDocument($documentId: UUID!) {
    duplicateArrivalDocument(documentId: $documentId) {
      ...ArrivalDocumentResult
    }
  }
  ${fragmentArrivalDocumentResult}
`;

export const mutationConfirmArrivalDocument = gql`
  mutation confirmArrivalDocument($arrivalDocumentId: UUID!, $warehouseId: UUID!) {
    confirmArrivalDocument(arrivalDocumentId: $arrivalDocumentId, warehouseId: $warehouseId) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationRejectArrivalDocument = gql`
  mutation rejectArrivalDocument($arrivalDocumentId: UUID!) {
    rejectArrivalDocument(arrivalDocumentId: $arrivalDocumentId) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const queryArrivalsPageableV2 = gql`
  query allArrivalDocumentsPageableV2(
    $filter: DocumentFilterInputV2!
    $pageRequest: PageRequestInput!
    $sort: [AllArrivalDocumentsPageableV2Sort!]
  ) {
    allArrivalDocumentsPageableV2(filter: $filter, pageRequest: $pageRequest, sort: $sort) {
      content {
        id
        status
        date
        docNumber
        paymentStatus
        documentItems {
          amount
        }
        to {
          id
          name
        }
        supplier {
          id
          companyName
          contactPoint
        }
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
`;

export const queryArrivalDocument = gql`
  query arrivalDocument($id: UUID!) {
    arrivalDocument(id: $id) {
      id
      supplier {
        id
        type
        contactPoint
        companyName
        email
        address
        inn
        kpp
        phone
        description
        creationDate
      }
      organization {
        id
        name
      }
      documentItems {
        name
        factQuantity
        stockUnit {
          id
          name
          unit
          unitTypesMatchList
          primePrice {
            amountValue
            currencyUnit
          }
        }
        amount
      }
      docNumber
      paymentStatus
      date
      creationDate
      typeDocument
      status
      description
      to {
        id
        name
        addressName
        description
        phone
        businessHours
        shippingCenter
        pickupLocation
        active
      }
    }
  }
`;

export const fragmentShortArrivalDoc = gql`
  fragment ArrivalDoc on ArrivalDoc {
    id
    date
    description
    number
    status
    sum
    revertedDate
    paymentStatus
    supplier {
      ... on SupplierLegal {
        id
        address
        contact
        description
        name
        phone
        email
        psrn
        tin
        totalCosts
      }
      ... on SupplierPhysical {
        id
        address
        description
        name
        phone
        email
        tin
        totalCosts
      }
    }
    storageRoom {
      id
      name
      archived
    }
  }
`;

export const fragmentArrivalDoc = gql`
  fragment ArrivalDoc on ArrivalDoc {
    id
    date
    description
    number
    status
    sum
    paymentStatus
    revertedDate
    supplier {
      ... on SupplierLegal {
        id
        address
        contact
        description
        name
        phone
        email
        psrn
        tin
        totalCosts
      }
      ... on SupplierPhysical {
        id
        address
        description
        name
        phone
        email
        tin
        totalCosts
      }
    }
    storageRoom {
      id
      name
    }
  }
`;

export const queryArrivalDoc = gql`
  query arrivalDoc($id: UUID!) {
    arrivalDoc(id: $id) {
      ...ArrivalDoc
    }
  }
  ${fragmentArrivalDoc}
`;

export const queryArrivalProducts = gql`
  query arrivalProducts($filter: ArrivalProductFilter!, $pageRequest: PageRequestInput!) {
    arrivalProducts(filter: $filter, pageRequest: $pageRequest) {
      content {
        id
        sum
        product {
          id
          name
          unit
        }
        quantity
        unit
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
`;

export const queryArrivalDocs = gql`
  query arrivalDocs($filter: ArrivalDocFilter, $pageRequest: PageRequestInput!, $sort: [ArrivalDocSort!]) {
    arrivalDocs(filter: $filter, pageRequest: $pageRequest, sort: $sort) {
      content {
        ...ArrivalDoc
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
  ${fragmentShortArrivalDoc}
`;

export const mutationCreateArrivalDoc = gql`
  mutation createArrivalDoc($input: CreateArrivalDocInput!) {
    createArrivalDoc(input: $input) {
      errorMessage
      result
      output {
        ...ArrivalDoc
      }
    }
  }
  ${fragmentShortArrivalDoc}
`;

export const mutationUpdateArrivalDoc = gql`
  mutation updateArrivalDoc($input: UpdateArrivalDocInput!) {
    updateArrivalDoc(input: $input) {
      errorMessage
      result
      output {
        ...ArrivalDoc
      }
    }
  }
  ${fragmentShortArrivalDoc}
`;

export const mutationDeleteArrivalDoc = gql`
  mutation deleteArrivalDoc($id: UUID!) {
    deleteArrivalDoc(id: $id) {
      errorMessage
      result
    }
  }
`;

export const mutationConfirmArrivalDoc = gql`
  mutation confirmArrivalDoc($id: UUID!) {
    confirmArrivalDoc(id: $id) {
      errorMessage
      result
    }
  }
`;

export const mutationRevertArrivalDoc = gql`
  mutation revertArrivalDoc($id: UUID!) {
    revertArrivalDoc(id: $id) {
      errorMessage
      result
    }
  }
`;
