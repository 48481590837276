import { WarehouseRoute } from '@constants/routes-warehouse';
import { MovementReason, StockUnitMovementsSortFieldStrict } from '@typings';

export const WAREHOUSE_ROUTE_BY_MOVEMENT_REASON: Partial<Record<MovementReason, string>> = {
  ARRIVAL_DOCUMENT: WarehouseRoute.arrivals,
  INVENTORY_DOCUMENT: WarehouseRoute.inventories,
  WRITE_OFF_DOCUMENT: WarehouseRoute.leavings,
  MOVE_DOCUMENT: WarehouseRoute.movings,
  SOLD: WarehouseRoute.leavingsByOrder,
};

export const MOVEMENT_SORT_FIELD: StockUnitMovementsSortFieldStrict = {
  PRODUCT_NAME: 'PRODUCT_NAME',
  STORAGE_ROOM: 'STORAGE_ROOM',
  SECTION: 'SECTION',
  PRODUCT_TYPE: 'PRODUCT_TYPE',
  ARRIVAL: 'ARRIVAL',
  WRITTEN_OFF: 'WRITTEN_OFF',
  PRIME_PRICE: 'PRIME_PRICE',
};
