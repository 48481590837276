import { ElementRef, Pipe, PipeTransform } from '@angular/core';

import {
  ChartData,
  ChartDataset,
  ChartGradientType,
  ChartType,
  DefaultDataPoint,
  OverviewDatasetItemType,
  OverviewDatasetsType,
  ProductReportIndicatorType,
  ReportIndicatorType,
} from '@typings';
import { chartLineGradientParams, dayjs } from '@utils';

@Pipe({
  name: 'chartLineData',
})
export class ChartLineDataPipe implements PipeTransform {
  constructor() {}

  transform(
    data: OverviewDatasetsType,
    indicator: ReportIndicatorType | ProductReportIndicatorType | 'stockUnitQuantity',
    canvas: ElementRef<HTMLCanvasElement>,
  ): ChartData<ChartType, DefaultDataPoint<ChartType>, string> {
    const [current, previous] = data || [[], []];

    const labels: string[] = [];
    const datasets: ChartDataset<ChartType, DefaultDataPoint<ChartType>>[] = [];

    const currentData: DefaultDataPoint<ChartType> = [];
    const previousData: DefaultDataPoint<ChartType> = [];

    current.forEach((item: OverviewDatasetItemType) => {
      const { date } = item;
      const value = item[indicator];

      if (indicator === 'quantity') {
        indicator = 'stockUnitQuantity';
      }

      const isMidnight = dayjs(date).format('HH:mm') === '00:00';
      const label = dayjs(date).format(isMidnight ? 'D MMM HH:mm' : 'HH:mm');

      labels.push(label);
      currentData.push(Number(value));
    });

    previous.forEach((item: OverviewDatasetItemType) => {
      const value = item[indicator];

      previousData.push(Number(value));
    });

    const optionsPrimary = canvas ? chartLineGradientParams(canvas.nativeElement, ChartGradientType.PRIMARY) : {};
    const optionsSecondary = canvas ? chartLineGradientParams(canvas.nativeElement, ChartGradientType.SECONDARY) : {};

    datasets.push({ ...optionsPrimary, data: currentData });
    datasets.push({ ...optionsSecondary, data: previousData });

    const chartData: ChartData<ChartType, DefaultDataPoint<ChartType>, string> = {
      labels,
      datasets,
    };

    return chartData;
  }
}
