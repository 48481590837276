<nm-modal-base
  (cancel)="menuService.onCancelDelete()"
  (ok)="menuService.onConfirmDelete()"
  [okTitle]="'Удалить'"
  okVariant="filled"
  [cancelTitle]="'Отменить'"
  testId="menu::modal-delete"
>
  <div modal-base-title>Вы уверены?</div>
  <div modal-base-content-container>Данное действие будет выполнено безвозвратно</div>
</nm-modal-base>
