import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CatalogStorage } from '@services/catalog';

@Injectable()
export class SetCatalogGuard implements CanActivateChild, CanActivate {
  constructor(private catalogStorage: CatalogStorage) {}

  canActivateChild(): Observable<boolean> {
    return this.checkCanActivate();
  }

  canActivate(): Observable<boolean> {
    return this.checkCanActivate();
  }

  private checkCanActivate(): Observable<boolean> {
    return new Observable((subscriber) => {
      const catalog = this.catalogStorage.getCatalog();

      if (catalog) {
        subscriber.next(true);
      } else {
        this.catalogStorage
          .getCatalogByOrganization()
          .pipe(map((res) => res.data.catalogByOrganization))
          .subscribe((catalog) => {
            this.catalogStorage.setCatalog(catalog!);
            subscriber.next(true);
          });
      }
    });
  }
}
