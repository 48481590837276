import { Injectable, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, zip } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CatalogStorage } from '@app/modules/catalog/services/catalog.storage';
import { ModifierGroupApi } from '@app/modules/catalog/services/modifierGroup.api';
import { ModifierGroupStorage } from '@app/modules/catalog/services/modifierGroup.storage';
import { ProductStorage } from '@app/modules/catalog/services/product.storage';
import { ProductModifierStorage } from '@app/modules/catalog/services/productModifier.storage';
import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { CreateProductDialogComponent } from '@app/shared/component/create-product-dialog/create-product-dialog.component';
import { ModalRef, ModalService } from '@app/shared/component/dialog/abstract';
import { ModalBaseComponent } from '@app/shared/component/dialog/modal-base/modal-base.component';
import { TableSidebarLayoutService } from '@app/shared/component/table-sidenav-layout/service/table-sidebar-layout.service';
import { CatalogRoute, MAX_CHARACTERS, MODIFIER_TYPE, PRODUCT_TYPE, RootNavigationRoute, ROUTE_CREATE_NEW, UNIT_TYPE } from '@constants';
import { FormConfirmSaveService, ValidationErrorsService } from '@core/service';
import { SessionStorage } from '@services/api';
import { NotifyService, SidenavService } from '@services/shared';
import {
  CreateModifierGroupInput,
  CreateModifierInput,
  ModifierForm,
  ModifierGroupFilterInput,
  ModifierGroupInfoForm,
  ModifierGroupOutput,
  ModifierGroupOutputPage,
  PageRequestInput,
  Product,
  ProductCreateInput,
  ProductDialogForm,
  QueryResult,
  StockUnitInput,
  StockUnitModifier,
  UpdateModifierGroupInput,
} from '@typings';
import { notEmpty } from '@utils';

export type Tab = {
  id: string;
  label: string;
  disabled: boolean;
};
@Injectable({
  providedIn: 'root',
})
export class ModifierGroupService {
  form: FormGroup<ModifierGroupInfoForm>;
  createModifierForm: FormGroup<ProductDialogForm>;
  controls: ModifierGroupInfoForm;
  modifierGroup: ModifierGroupOutput | null | undefined;
  modalRef: ModalRef<unknown>;
  modifierCreateIndex: number | undefined;

  #refresh = new BehaviorSubject(true);
  refresh$ = this.#refresh.asObservable();
  isEditing$ = new BehaviorSubject(false);
  isSubmitDisabled$ = new BehaviorSubject(false);
  updateModifierGroupInTable = new Subject<ModifierGroupOutput>();
  removeModifierGroupFromTable = new Subject<string>();
  tabs: Tab[] = [
    {
      id: 'info',
      label: 'Основное',
      disabled: false,
    },
    {
      id: 'usage',
      label: 'Где используется',
      disabled: false,
    },
  ];
  currentTab = new BehaviorSubject<number>(0);

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private modifierGroupApi: ModifierGroupApi,
    private sidenavService: SidenavService,
    private sessionStorage: SessionStorage,
    private modifierGroupStorage: ModifierGroupStorage,
    private formConfirmSaveService: FormConfirmSaveService,
    private modalService: ModalService,
    private validationErrorsService: ValidationErrorsService,
    private catalogStorage: CatalogStorage,
    private modifierStorage: ProductModifierStorage,
    private productStorage: ProductStorage,
    private tableSidebarService: TableSidebarLayoutService,
    private notifyService: NotifyService,
  ) {}

  getModifierGroups(
    filterInput?: ModifierGroupFilterInput,
    pageRequest?: PageRequestInput,
    modifiersPageRequest?: PageRequestInput,
  ): Observable<ModifierGroupOutputPage> {
    return this.modifierGroupApi
      .getModifierGroups({
        filterInput,
        pageRequest,
        modifiersPageRequest,
      })
      .pipe(map((res) => res.data.modifierGroups));
  }

  getAllModifierGroupsShort(): Observable<ModifierGroupOutput[]> {
    return this.modifierGroupApi.getModifierGroupsShort({}).pipe(map((res) => res.data.modifierGroups.content));
  }

  openModifierGroupPage(id?: string): Promise<boolean> {
    return this.router.navigate([
      this.sessionStorage.getOrgId(),
      RootNavigationRoute.catalog,
      CatalogRoute.modifiersGroups,
      id || ROUTE_CREATE_NEW,
      this.tabs[this.currentTab.getValue()]?.id,
    ]);
  }

  initState(modifierGroup: ModifierGroupOutput | null | undefined, tab?: number): void {
    if (!modifierGroup && this.tableSidebarService.isRedirected()) {
      return;
    }

    if (tab || tab === 0) {
      this.setCurrentTab(tab);
    } else {
      this.setCurrentTab(0);
    }

    this.modifierGroup = modifierGroup;

    if (modifierGroup) {
      this.modifierGroupStorage.setActiveModifierGroup(modifierGroup);
    }
  }

  initForm(shouldRegister: boolean = true): void {
    this.form = this.fb.group<ModifierGroupInfoForm>({
      name: this.fb.nonNullable.control('', [Validators.required, Validators.maxLength(MAX_CHARACTERS.MODIFIER_GROUP_NAME)]),
      type: this.fb.nonNullable.control(MODIFIER_TYPE.STOCK_UNIT_MODIFIER),
      modifiers: this.fb.nonNullable.array(new Array<FormGroup<ModifierForm>>()),
      products: this.fb.array(new Array<FormControl<AutocompleteOption<Product> | null>>()),
      required: this.fb.nonNullable.control(false),
      minValue: this.fb.nonNullable.control(null),
      maxValue: this.fb.nonNullable.control(null),
    });
    this.controls = this.form.controls;

    if (this.modifierGroup) {
      const { name, type, modifiers, products, required, minValue, maxValue } = this.modifierGroup;

      if (name) {
        this.controls.name.setValue(name);
      }

      if (type) {
        this.controls.type.setValue(type);
      }

      if (required) {
        this.controls.required.setValue(required);
      }

      if (minValue) {
        this.controls.minValue.setValue(this.numberToAutocompleteOption(minValue));
      }

      if (maxValue) {
        this.controls.maxValue.setValue(this.numberToAutocompleteOption(maxValue));
      }

      if (modifiers && modifiers.content) {
        modifiers.content.forEach((modifier) => this.controls.modifiers.push(this.addModifierItem(modifier as StockUnitModifier)));
      }

      if (products && products.content) {
        products.content.forEach((product) => {
          this.controls.products.push(this.fb.nonNullable.control({ type: 'item', label: product.name, id: product.id, data: product }));
        });
      }
    }

    this.isSubmitDisabled$.next(false);
    this.isEditing$.next(!!this.modifierGroup);

    if (shouldRegister) {
      this.formConfirmSaveService.setForm(this.form, undefined, () => this.resetForm());
    }
  }

  resetForm() {
    this.initState(undefined);
    this.initForm(false);
  }

  addModifierItem(modifier: StockUnitModifier): FormGroup<ModifierForm> {
    const stockUnit = modifier.stockUnit;

    return this.fb.group<ModifierForm>({
      id: this.fb.control(modifier.id),
      name: this.fb.nonNullable.control(modifier.name),
      pinned: this.fb.nonNullable.control(modifier.pinned),
      stockUnit: this.fb.control({ type: 'item', label: stockUnit?.name, id: stockUnit?.id, data: stockUnit }),
    });
  }

  initCreateModifierForm(name: string): void {
    this.createModifierForm = this.fb.group<ProductDialogForm>({
      name: this.fb.nonNullable.control(name, [Validators.required, Validators.maxLength(MAX_CHARACTERS.PRODUCT_NAME)]),
      section: this.fb.control(null, [Validators.required]),
      type: this.fb.control(PRODUCT_TYPE.MODIFIER, [Validators.required]),
      weighable: this.fb.nonNullable.control(false),
      unit: this.fb.nonNullable.control(UNIT_TYPE.PIECE),
      quantity: this.fb.nonNullable.control('1', [Validators.required, Validators.min(0)]),
      primePrice: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
      salePrice: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
      surcharge: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
    });
  }

  addModifier(): FormGroup<ModifierForm> {
    return this.fb.nonNullable.group<ModifierForm>({
      id: this.fb.control(null),
      stockUnit: this.fb.control(null),
      name: this.fb.nonNullable.control(''),
      pinned: this.fb.nonNullable.control(false),
    });
  }

  removeModifier(index: number): void {
    this.controls.modifiers.removeAt(index);
  }

  addProduct(): FormControl<AutocompleteOption<Product> | null> {
    return this.fb.nonNullable.control(null);
  }

  removeProduct(index: number): void {
    this.controls.products.removeAt(index);
  }

  submitForm(sidenavMode: boolean): void {
    this.#refresh.next(true);

    if (this.form.invalid) {
      this.validationErrorsService.markFormControls(this.form);

      this.notifyService.addNotification({
        type: 'alert',
        title: 'Необходимо заполнить обязательные поля',
      });

      return;
    }

    this.disableForm();

    this.modifierGroup ? this.updateModifierGroup(sidenavMode) : this.createModifierGroup();
  }

  disableForm(): void {
    this.form.disable();
    this.isSubmitDisabled$.next(true);
  }

  enableForm(): void {
    this.form.enable();
    this.isSubmitDisabled$.next(false);
  }

  createModifierGroup(): void {
    const modifierGroup = this.createModifierGroupCreateInput();

    this.modifierGroupStorage.createModifierGroup({ modifierGroup }).subscribe(
      () => this.toList(),
      () => this.enableForm(),
    );
  }

  createModifierGroupCreateInput(addModifierId: boolean = false): CreateModifierGroupInput {
    const { name, type, modifiers, products, required, minValue, maxValue } = this.controls;

    const modifiersInput: CreateModifierInput[] = modifiers.controls
      .map((modifier) => {
        const { stockUnit, name, pinned, id } = modifier.controls;

        if (type.value === MODIFIER_TYPE.STOCK_UNIT_MODIFIER) {
          if (!stockUnit.value) {
            return null;
          }

          return {
            stockUnitModifier: {
              stockUnitId: stockUnit.value.id,
              name: stockUnit.value.label,
              // TODO now we don't use 'sort' field at all, probably, need to make in optional in request input
              sort: 100,
              pinned: pinned.value,
            },
            ...(addModifierId ? { id: id.value } : {}),
          };
        }

        if (type.value === MODIFIER_TYPE.TEXT_MODIFIER) {
          return {
            textModifier: {
              name: name.value,
              sort: 100,
              pinned: pinned.value,
            },
            ...(addModifierId ? { id: id.value } : {}),
          };
        }

        return null;
      })
      .filter(notEmpty);

    const productsInput = products.controls
      .map((product) => {
        if (!product.value || !product.value.id) {
          return null;
        }

        return product.value.id;
      })
      .filter(notEmpty);

    return {
      name: name.value,
      type: type.value,
      modifiers: modifiersInput,
      productIds: productsInput,
      required: required.value,
      minValue: minValue.value?.data || null,
      maxValue: maxValue.value?.data || null,
    };
  }

  updateModifierGroup(sidenavMode: boolean): void {
    if (!this.modifierGroup) {
      return;
    }

    const createInput = this.createModifierGroupCreateInput(true);

    const updateModifierGroupInput: UpdateModifierGroupInput = {
      id: this.modifierGroup.id,
      name: createInput.name,
      type: createInput.type,
      required: createInput.required,
      minValue: createInput.minValue,
      maxValue: createInput.maxValue,
    };

    const modifierGroupUpdateResult = this.modifierGroupStorage.updateModifierGroup({ modifierGroup: updateModifierGroupInput });
    let requests: Observable<unknown>[] = [modifierGroupUpdateResult];

    const previousProducts = this.modifierGroup.products.content.map((p) => p.id);
    const previousProductsSet = new Set(previousProducts);

    const currentProducts = createInput.productIds || [];
    const currentProductsSet = new Set(currentProducts);

    const productsToAttach = currentProducts.filter((p) => !previousProductsSet.has(p));
    const productsToDetach = previousProducts.filter((p) => !currentProductsSet.has(p));

    if (productsToAttach.length) {
      requests.push(
        this.modifierGroupStorage.attachProductsToModifierGroup({
          modifierGroupId: this.modifierGroup.id,
          productIds: productsToAttach,
        }),
      );
    }

    if (productsToDetach.length) {
      requests.push(
        this.modifierGroupStorage.detachProductsFromModifierGroup({
          modifierGroupId: this.modifierGroup.id,
          productIds: productsToDetach,
        }),
      );
    }

    if (createInput.modifiers) {
      requests = requests.concat(this.createModifiersUpdates(this.modifierGroup, createInput.modifiers));
    }

    modifierGroupUpdateResult.subscribe((res) => {
      if (sidenavMode) {
        this.tableSidebarService.closeTableSidenav();
        this.updateModifierGroupInTable.next(res?.data?.modifierGroupUpdate.output as ModifierGroupOutput);
      }
    });

    zip(...requests).subscribe(
      () => {
        if (!sidenavMode) {
          this.toList();
        }
        this.enableForm();
      },
      () => this.enableForm(),
    );
  }

  createModifiersUpdates(
    modifierGroup: ModifierGroupOutput,
    currentModifiers: (CreateModifierInput & { id?: string | null })[],
  ): Observable<unknown>[] {
    let updates: Observable<unknown>[] = [];

    const previousModifiers = modifierGroup.modifiers.content;

    const currentModifiersWithIdById = new Map(
      currentModifiers.filter((m): m is CreateModifierInput & { id: string } => !!m.id).map((m) => [m.id, m]),
    );
    const currentModifiersIds = new Set(currentModifiers.map((m) => m.id).filter(notEmpty));

    let modifiersToCreate = currentModifiers.filter((m) => !m.id);
    let modifierIdsToDelete = previousModifiers.filter((m) => !currentModifiersIds.has(m.id)).map((m) => m.id);
    let modifiersToUpdate = previousModifiers
      .filter((m) => currentModifiersIds.has(m.id))
      .map((m) => currentModifiersWithIdById.get(m.id)!);

    const modifiersWithChangedStockUnit = modifiersToUpdate.filter((m) => {
      if (!m.stockUnitModifier) {
        return false;
      }

      const updatedModifier = currentModifiersWithIdById.get(m.id);

      if (!updatedModifier || !updatedModifier.stockUnitModifier?.stockUnitId) {
        return false;
      }

      return m.stockUnitModifier.stockUnitId !== updatedModifier.stockUnitModifier.stockUnitId;
    });

    if (modifiersWithChangedStockUnit.length) {
      const toRecreateIds = modifiersWithChangedStockUnit.map((m) => m.id);
      const toRecreateIdsSet = new Set(toRecreateIds);

      modifiersToUpdate = modifiersToUpdate.filter((m) => !toRecreateIdsSet.has(m.id));
      modifierIdsToDelete = modifierIdsToDelete.concat(toRecreateIds);
      modifiersToCreate = modifiersToCreate.concat(
        modifiersWithChangedStockUnit.map((m) => currentModifiersWithIdById.get(m.id)).filter(notEmpty),
      );
    }

    if (modifiersToCreate.length) {
      modifiersToCreate.forEach((m) => delete m.id);

      updates.push(
        this.modifierGroupStorage.addModifiers({
          modifierGroupId: modifierGroup.id,
          modifiers: modifiersToCreate,
        }),
      );
    }

    if (modifierIdsToDelete.length) {
      updates.push(
        this.modifierGroupStorage.removeModifiers({
          modifierGroupId: modifierGroup.id,
          modifierIds: modifierIdsToDelete,
        }),
      );
    }

    if (modifiersToUpdate.length) {
      const updateModifierRequests = modifiersToUpdate.map((m) => {
        const modifier = m.stockUnitModifier || m.textModifier!;

        return this.modifierGroupStorage.updateModifier({
          modifierGroupId: modifierGroup.id,
          modifierId: m.id,
          name: modifier.name,
          pinned: modifier.pinned,
          sort: modifier.sort,
        });
      });

      updates = updates.concat(updateModifierRequests);
    }

    return updates;
  }

  deleteModifierGroup(sidenavMode: boolean): void {
    if (!this.modifierGroup?.id) {
      throw new Error('Modifier group id is missing');
    }

    this.modifierGroupStorage.deleteModifierGroup({ modifierGroupId: this.modifierGroup.id }).subscribe(
      () => {
        this.modalRef.close();
        if (!sidenavMode) {
          this.toList();
        }
        if (sidenavMode) {
          this.removeModifierGroupFromTable.next(this.modifierGroup?.id!);
          this.tableSidebarService.closeTableSidenav();
        }
      },
      () => this.enableForm(),
    );
  }

  createModifierStockUnit(): void {
    const { name, type, unit, weighable, quantity, section, primePrice, salePrice } = this.createModifierForm.controls;

    const stockUnitInput: StockUnitInput = {
      name: name.value,
      weighable: !weighable.value,
      unit: unit.value || 'NONE',
      quantity: quantity.value,
      primePrice: { amountValue: primePrice.value, currencyUnit: this.getCurrencyUnit() },
      salePrice: { amountValue: salePrice.value, currencyUnit: this.getCurrencyUnit() },
      barcodes: [],
      nutritionFact: { protein: 0, fat: 0, calories: 0, carbohydrates: 0 },
      techCard: { id: null, items: [], processOfCooking: '' },
    };

    const productCreateInput: ProductCreateInput = {
      catalogId: this.catalogStorage.getCatalog().id,
      name: name.value,
      sectionId: section.value?.id,
      stockUnits: [stockUnitInput],
      type: type.value,
    };

    this.isSubmitDisabled$.next(true);

    this.modifierStorage.createProduct({ product: productCreateInput }).subscribe((res) => {
      this.#refresh.next(true);

      if (!res.data?.createProductV2?.output?.id) {
        return;
      }

      this.modifierStorage.getProduct({ id: res.data.createProductV2.output.id }).subscribe((r) => {
        const stockUnits = r.data.product.stockUnits;

        if (this.modifierCreateIndex !== undefined && stockUnits) {
          const stockUnit = r.data.product.stockUnits[0];
          this.controls.modifiers
            .at(this.modifierCreateIndex)
            .controls.stockUnit.patchValue({ type: 'item', label: stockUnit.name, id: stockUnit.id, data: stockUnit });
        }

        this.isSubmitDisabled$.next(false);
      });
    });
  }

  toList(shouldConfirm: boolean = false): void {
    this.formConfirmSaveService
      .closeForm(shouldConfirm)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.router.navigateByUrl(`${this.router.url.split(`${CatalogRoute.modifiersGroups}/`)[0]}${CatalogRoute.modifiersGroups}`);
        }
      });
  }

  back(): void {
    this.toList(true);
  }

  showModal(modalTemplate: TemplateRef<ModalBaseComponent>): void {
    this.modalRef = this.modalService.openDialog(modalTemplate);
  }

  hideModal(): void {
    if (!this.modalRef) {
      return;
    }

    this.modalRef.close();
  }

  openCreateModifierDialog(name: string, index: number): void {
    this.initCreateModifierForm(name);
    this.modifierCreateIndex = index;
    this.modalRef = this.modalService.openDialog(CreateProductDialogComponent, {
      data: {
        form: this.createModifierForm,
        title: 'Новый модификатор',
        name,
        close: (confirm: boolean) => this.closeCreateModifierDialog(confirm),
      },
    });
  }

  closeCreateModifierDialog(confirm: boolean): void {
    if (confirm) {
      if (this.createModifierForm.valid) {
        this.createModifierStockUnit();
        this.hideModal();
      } else {
        this.validationErrorsService.markFormControls(this.createModifierForm);
      }
    } else {
      this.hideModal();
    }
  }

  getProducts(pageRequest: PageRequestInput, searchText: string, excludeIds: string[]): QueryResult<'products'> {
    return this.productStorage.getProducts({
      pageRequest,
      filter: {
        search: searchText,
        excludeIds,
      },
    });
  }

  getModifiers(pageRequest: PageRequestInput, searchText: string, excludeStockUnitIds: string[]): QueryResult<'products'> {
    return this.productStorage.getProducts({
      pageRequest,
      filter: {
        type: PRODUCT_TYPE.MODIFIER,
        search: searchText,
        excludeStockUnitIds,
      },
    });
  }

  numberToAutocompleteOption(num: number): AutocompleteOption<number> {
    return { id: String(num), label: String(num), type: 'item', data: num };
  }

  getCurrencyUnit() {
    return this.sessionStorage.getCurrencyUnit();
  }

  showExitModal() {
    this.formConfirmSaveService
      .closeForm(true)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.tableSidebarService.closeTableSidenav();
        }
      });
    this.setCurrentTab(0);
  }
  setCurrentTab(index: number) {
    this.currentTab.next(index);
  }
}
