import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';

import { ActionsModule } from '@app/shared/component/actions/actions.module';
import { AlertBannerModule } from '@app/shared/component/alert-banner/alert-banner.module';
import { LayoutLoaderModule } from '@app/shared/component/layout-loader/layout-loader.module';
import { MenuModule } from '@app/shared/component/menu/menu.module';
import { NavigationDrawerModule } from '@app/shared/component/navigation-drawer/navagation-drawer.module';
import { TopBarModule } from '@app/shared/component/top-bar/top-bar.module';
import { SidenavLayoutComponent } from '@layout';
import { LetModule } from '@ngrx/component';

import { TableSidebarLayoutModule } from '../../shared/component/table-sidenav-layout/table-sidebar-layout.module';

import { ClickOutsideDirective } from './click-outside.directive';
import { TopBarActionsDirective } from './top-bar-actions.directive';

@NgModule({
  declarations: [SidenavLayoutComponent, TopBarActionsDirective, ClickOutsideDirective],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    TopBarModule,
    NavigationDrawerModule,
    MatMenuModule,
    MenuModule,
    ActionsModule,
    LayoutLoaderModule,
    AlertBannerModule,
    TableSidebarLayoutModule,
    LetModule,
  ],
  exports: [SidenavLayoutComponent],
})
export class SidenavLayoutModule {}
