import { gql } from 'apollo-angular';

export const queryPaySystemReportV2 = gql`
  query paySystemReportV2($input: PaySystemReportInput!, $sort: [PaySystemReportSort!]) {
    paySystemReportV2(input: $input) {
      total {
        guestsCount
        ordersQuantity
        revenue
        averageCheck
      }
      rows(sort: $sort) {
        content {
          paySystemId
          paySystemName
          guestsCount
          ordersQuantity
          revenue
          averageCheck
        }
        pageNumber
        pageSize
        total
        totalPages
      }
    }
  }
`;

export const queryPaymentReportTimeSeries = gql`
  query paymentReportTimeSeries(
    $input: PaySystemReportInput!
    $timeSeriesInput: TimeSeriesInput!
    $guestsCount: Boolean!
    $revenue: Boolean!
    $ordersCount: Boolean!
  ) {
    paymentReportTimeSeries(input: $input, timeSeriesInput: $timeSeriesInput) {
      elements {
        guestsCount @include(if: $guestsCount)
        ordersCount @include(if: $ordersCount)
        revenue @include(if: $revenue)
        timestamp
      }
    }
  }
`;
