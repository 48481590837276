import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { ModalBaseModule } from '@app/shared/component/dialog/modal-base/modal-base.module';

import { DeleteEmployeeDialogComponent } from './delete-employee-dialog.component';

@NgModule({
  declarations: [DeleteEmployeeDialogComponent],
  imports: [CommonModule, MatDialogModule, ModalBaseModule],
  exports: [DeleteEmployeeDialogComponent],
})
export class DeleteEmployeeDialogModule {}
