<nm-modal-base
  (ok)="devicesService.removeDevice(data.device, data.callbackFn)"
  (cancel)="devicesService.hideModal()"
  okTitle="Удалить"
  cancelTitle="Отменить"
  okVariant="filled"
>
  <div modal-base-title>Удалить устройство</div>
  <div>
    Вы уверены, что хотите удалить устройство {{ data.device.name }}? Вход в приложение Absolut POS на этом устройстве
    будет заблокирован?
  </div>
</nm-modal-base>
