import { gql } from 'apollo-angular';

import { fragmentPage } from './mutations';

export const queryAllEmployees = gql`
  query allEmployees($page: Int!, $size: Int!, $sortField: String, $sortOrder: String, $nameFilter: String, $activeFilter: Boolean) {
    allEmployees(
      page: $page
      size: $size
      sortField: $sortField
      sortOrder: $sortOrder
      nameFilter: $nameFilter
      activeFilter: $activeFilter
    ) {
      id
      active
      firstName
      lastName
      middleName
      vatin
      snils
      medicalBookExpiresAt
      dateOfBirth
      dateOfEmployment
      workPhone
      address
      workEmail
      pin
      accountId
      userGroups {
        id
        name
        code
        userGroupId
        storeId
      }
      organizationId
      organizationName
      employeeInvitation {
        id
        login
        confirmationToken
        url
      }
    }
  }
`;

export const queryAllEmployeesPageable = gql`
  query allEmployeesPageable($filter: EmployeeFilterInput!, $pageRequest: PageRequestInput!) {
    allEmployeesPageable(filter: $filter, pageRequest: $pageRequest) {
      content {
        id
        active
        firstName
        lastName
        middleName
        userGroups {
          code
          storeId
        }
      }
      ...Page
    }
  }
  ${fragmentPage}
`;

export const queryCountEmployees = gql`
  query countEmployees($nameFilter: String, $activeFilter: Boolean) {
    countEmployees(nameFilter: $nameFilter, activeFilter: $activeFilter)
  }
`;

export const queryEmployee = gql`
  query employee($id: UUID!) {
    employee(id: $id) {
      id
      active
      firstName
      lastName
      middleName
      workEmail
      vatin
      snils
      medicalBookExpiresAt
      dateOfBirth
      dateOfEmployment
      workPhone
      address
      userGroups {
        id
        name
        code
      }
      organization {
        name
        id
      }
      employeeInvitation {
        id
        login
        confirmationToken
        url
      }
    }
  }
`;

export const queryEmployeeV2 = gql`
  query employee($id: UUID!) {
    employee(id: $id) {
      id
      lastName
      firstName
      middleName
      workEmail
      workPhone
      vatin
      userGroups {
        id
        userGroupId
        name
        code
        storeId
      }
      active
      pin
      snils
      dateOfBirth
      medicalBookExpiresAt
      dateOfEmployment
      address
    }
  }
`;

export const queryUserGroups = gql`
  query userGroups {
    userGroups {
      id
      name
      code
    }
  }
`;

export const mutationCreateEmployee = gql`
  mutation createEmployee($createEmployeeInput: CreateEmployeeInput!) {
    createEmployee(createEmployeeInput: $createEmployeeInput)
  }
`;

export const mutationRemoveEmployee = gql`
  mutation removeEmployee($employeeId: UUID!) {
    removeEmployee(employeeId: $employeeId)
  }
`;

export const mutationUpdateEmployee = gql`
  mutation updateEmployee($updateEmployeeInput: UpdateEmployeeInput!) {
    updateEmployee(updateEmployeeInput: $updateEmployeeInput)
  }
`;

export const mutationUpdateEmployeePinCode = gql`
  mutation updateEmployeePinCode($employeeId: UUID!) {
    updateEmployeePinCode(employeeId: $employeeId) {
      pin
    }
  }
`;
