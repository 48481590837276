import { Injectable } from '@angular/core';

import {
  mutationCreateCatalogSectionV2,
  mutationDeleteCatalogSection,
  mutationDeleteCatalogSectionV2,
  mutationMoveSection,
  mutationUpdateCatalogSectionV2,
  queryAllCatalogSectionsPageableV2,
  queryCatalogSectionV2,
  querySections,
  querySectionsByIds,
} from '@api';
import { ApiService } from '@core/service';
import {
  Mutation,
  MutationBatchResult,
  MutationCreateCatalogSectionV2Args,
  MutationDeleteCatalogSectionArgs,
  MutationMoveSectionArgs,
  MutationResult,
  MutationUpdateCatalogSectionV2Args,
  Query,
  QueryAllCatalogSectionsPageableArgs,
  QueryCatalogSectionArgs,
  QueryResult,
  QuerySectionsArgs,
  QuerySectionsByIdsArgs,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class CategoriesApi {
  constructor(private apiService: ApiService) {}

  createCatalogSectionV2(variables: MutationCreateCatalogSectionV2Args): MutationResult<'createCatalogSectionV2'> {
    return this.apiService.mutate<Mutation<'createCatalogSectionV2'>, MutationCreateCatalogSectionV2Args>({
      mutation: mutationCreateCatalogSectionV2,
      variables,
    });
  }

  updateCatalogSectionV2(variables: MutationUpdateCatalogSectionV2Args): MutationResult<'updateCatalogSectionV2'> {
    return this.apiService.mutate<Mutation<'updateCatalogSectionV2'>, MutationUpdateCatalogSectionV2Args>({
      mutation: mutationUpdateCatalogSectionV2,
      variables,
    });
  }

  deleteCatalogSection(variables: MutationDeleteCatalogSectionArgs): MutationResult<'deleteCatalogSection'> {
    return this.apiService.mutate<Mutation<'deleteCatalogSection'>, MutationDeleteCatalogSectionArgs>({
      mutation: mutationDeleteCatalogSectionV2,
      variables,
    });
  }

  deleteCatalogSectionAll(variables: { id: string }[]): MutationBatchResult<'deleteCatalogSection'> {
    return this.apiService.mutateBatch<Mutation<'deleteCatalogSection'>, { id: string }[]>({
      mutation: mutationDeleteCatalogSection,
      variables,
    });
  }

  moveSection(variables: MutationMoveSectionArgs): MutationResult<'moveSection'> {
    return this.apiService.mutate<Mutation<'moveSection'>, MutationMoveSectionArgs>({
      mutation: mutationMoveSection,
      variables,
    });
  }

  moveSectionBatch(variables: MutationMoveSectionArgs[]): MutationBatchResult<'moveSection'> {
    return this.apiService.mutateBatch<Mutation<'moveSection'>, { targetSectionId: string; newSectionId: string }[]>({
      mutation: mutationMoveSection,
      variables,
    });
  }

  allCatalogSectionsPageable(variables: QueryAllCatalogSectionsPageableArgs): QueryResult<'allCatalogSectionsPageable'> {
    return this.apiService.query<Query<'allCatalogSectionsPageable'>, QueryAllCatalogSectionsPageableArgs>({
      query: queryAllCatalogSectionsPageableV2,
      variables,
    });
  }

  sections(variables?: QuerySectionsArgs): QueryResult<'sections'> {
    return this.apiService.query<Query<'sections'>, QuerySectionsArgs>({
      query: querySections,
      variables,
    });
  }

  sectionsByIds(variables?: QuerySectionsByIdsArgs): QueryResult<'sectionsByIds'> {
    return this.apiService.query<Query<'sectionsByIds'>, QuerySectionsByIdsArgs>({
      query: querySectionsByIds,
      variables,
    });
  }

  catalogSection(variables: QueryCatalogSectionArgs): QueryResult<'catalogSection'> {
    return this.apiService.query<Query<'catalogSection'>, QueryCatalogSectionArgs>({
      query: queryCatalogSectionV2,
      variables,
    });
  }
}
