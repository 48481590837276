<div class="organization-item" [attr.data-test-id]="testId">
  <div class="name">
    <nm-avatar class="item-icon" type="square" [title]="organization?.name"></nm-avatar>
    <div class="label">
      <div class="account-name" [title]="name">{{ name }}</div>
      <div *ngIf="organization?.name && organization?.shortId" class="description">ID: {{ organization?.shortId }}</div>
    </div>
  </div>
  <div class="item-arrow">
    <nm-svg-icon [size]="24" name="keyboardArrowRight"></nm-svg-icon>
  </div>
</div>
