import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, take } from 'rxjs';

import { SelectInTableService } from '@app/shared/service/select-in-table.service';
import { SessionStorage } from '@services/api';
import { BreakpointObserverService } from '@services/core';
import { RedirectService } from '@services/shared';

import { Row } from '../table/table.types';
import { TableSidebarLayoutService } from '../table-sidenav-layout/service/table-sidebar-layout.service';

import { SidebarMultiselectLayoutComponent } from './sidebar-multiselect-layout.component';

export type SelectedOptions = {
  rows: Row[];
  reset: boolean;
};

export type sidebarMultiselectConfig = {
  options: Observable<Row[]>;
  searchFn: (searchText: string) => void;
  title: string;
  selectedOptions: string[];
  excludedIds?: string[];
};

@Injectable({
  providedIn: 'root',
})
export class SidebarMultiselectLayoutService {
  getSelected = new BehaviorSubject<SelectedOptions>({ rows: [], reset: false });
  #redirected = new BehaviorSubject<boolean>(false);
  excludedIds$ = new BehaviorSubject<string[]>([]);
  tableInited = new Subject<void>();
  options: Observable<Row[]>;
  title: string;
  searchFn: (searchText: string) => void;
  constructor(
    private tableSidebarService: TableSidebarLayoutService,
    private sessionStorage: SessionStorage,
    private location: Location,
    private selectService: SelectInTableService,
    private route: ActivatedRoute,
    private router: Router,
    private bos: BreakpointObserverService,
    private redirectService: RedirectService,
  ) {}
  openSidebarMultiselect(config: sidebarMultiselectConfig) {
    this.selectService.setSelected([]);
    this.getSelected.next({ rows: [], reset: false });
    this.sessionStorage.setSelectedItems(config.selectedOptions as string[]);
    this.options = config.options;
    this.searchFn = config.searchFn;
    this.excludedIds$.next(config.excludedIds ?? []);
    this.title = config.title || 'Добавление позиций';
    this.bos.isMaxWidth900$.pipe(take(1)).subscribe((res: boolean) => {
      if (res) {
        let route = this.router.routerState.root;
        while (route.firstChild) {
          route = route.firstChild;
        }
        route.snapshot;
        this.redirectService.redirectToAddPositions(route);
      } else {
        this.tableSidebarService.openTableSidenav(
          {
            component: SidebarMultiselectLayoutComponent,
            idForRedirect: '',
            title: config.title,
            addPositionsComponent: true,
          },
          [{ name: 'id', value: '' }],
        );
      }
    });
  }

  resetSelected() {
    this.getSelected.next({ rows: [], reset: false });
  }
  close(rows: Row[], reset = false) {
    this.getSelected.next({ rows: rows, reset: reset });

    this.location.back();

    this.#redirected.next(true);
    setTimeout(() => {
      this.#redirected.next(false);
      this.getSelected.next({ rows: [], reset: false });
    }, 1000);
  }

  redirected() {
    const redirected = this.#redirected.getValue();
    this.#redirected.next(false);
    return redirected;
  }
}
