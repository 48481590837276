import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SVG_ICONS_TYPE } from '@constants';

@Component({
  selector: 'nm-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarComponent {
  @Input() title: string;
  @Input() testId: string;
  @Input() icon: SVG_ICONS_TYPE;
  @Input() iconLeft: SVG_ICONS_TYPE;
  @Input() transparent: boolean = false;
  @Input() count: string = '';
  @Input() onLeftClick = () => {};

  @Output() btnClick = new EventEmitter();

  get componentClasses(): string[] {
    const classes = ['nm-top-bar'];

    if (this.transparent) {
      classes.push('transparent');
    }
    if (this.icon) {
      classes.push('with-icon');
    }

    return classes;
  }

  constructor() {}

  onBtnClick() {
    this.btnClick.emit();
  }
}
