import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SVG_ICONS_TYPE } from '@constants';

export type HintVariant = 'helper' | 'error' | 'validation';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nm-control-hint',
  templateUrl: './control-hint.component.html',
})
export class ControlHintComponent {
  @Input() testId: string;
  @Input() type: HintVariant = 'helper';
  @Input() label: string;
  @Input() icon: SVG_ICONS_TYPE = 'info';

  get hintClasses(): string[] {
    return ['nm-control-hint', this.type];
  }
}
