import { Pipe, PipeTransform } from '@angular/core';

import { ChartData, ChartType, CoreSchema, DefaultDataPoint } from '@typings';
import { chartDonutParams } from '@utils';

@Pipe({
  name: 'analyticsChartDonutData',
})
export class AnalyticsChartDonutDataPipe implements PipeTransform {
  constructor() {}

  transform(data: CoreSchema.PaySystemAnalytics[]): ChartData<ChartType, DefaultDataPoint<ChartType>, string> {
    const labels: string[] = [];
    const currentData: DefaultDataPoint<ChartType> = [];
    const donutData = data || [];

    donutData.forEach((item: CoreSchema.PaySystemAnalytics) => {
      const { amount, paySystemName } = item;

      currentData.push(Number(amount));
      labels.push(paySystemName);
    });

    const params = chartDonutParams();

    const chartData: ChartData<ChartType, DefaultDataPoint<ChartType>, string> = {
      labels,
      datasets: [
        {
          data: currentData,
          ...params,
        },
      ],
    };

    return chartData;
  }
}
