import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Inject, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { MatCalendarView, MatDatepicker } from '@angular/material/datepicker';

import { FunctionType } from '@typings';

import { HintVariant } from '../control-hint/control-hint.component';

import { NmDatepickerDateAdapter } from './datepicker-adapter';

export type DatepickerMode = 'day' | 'year' | 'month';
export type DatepickerRange = {
  start: Date | null;
  end: Date | null;
};

@Component({
  selector: 'nm-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    { provide: DateAdapter, useClass: NmDatepickerDateAdapter, deps: [MAT_DATE_LOCALE] },
  ],
})
export class DatepickerComponent implements ControlValueAccessor {
  value: Date | null;

  @Input() rangeStart: Date | null;
  @Input() rangeEnd: Date | null;
  @Input() isRange: boolean = false;
  @Input() showActionButtons: boolean = false;
  @Input() showRangeControls: boolean = false;
  @Input() testId: string;
  @Input() mode: DatepickerMode = 'day';
  @Input() placeholder: string = '';
  @Input() placeholderStart: string = '';
  @Input() placeholderEnd: string = '';
  @Input() compareRange: DatepickerRange;
  @Input() label: string;
  @Input() hint: string;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() error: boolean;
  @Input() isSelectOnly: boolean;

  @ViewChild('input', { static: false }) inputElem: ElementRef;
  @ViewChild('picker', { static: false }) datepicker: MatDatepicker<Date>;

  opened: boolean = false;
  private isYearView: boolean;

  onTouched: FunctionType = () => {};
  onChange: FunctionType = () => {};

  get startView(): 'month' | 'year' | 'multi-year' {
    switch (this.mode) {
      case 'day':
        return 'month';
      case 'month':
        return 'year';
      case 'year':
        return 'multi-year';
      default:
        return 'month';
    }
  }

  get panelClasses(): string[] {
    const classes = ['nm-datepicker-calendar'];
    if (this.isYearView || this.isMonthPicker) {
      classes.push('nm-datepicker-year-view');
    }
    return classes;
  }

  get componentClasses(): string[] {
    const classes = ['nm-datepicker'];
    if (this.opened) {
      classes.push('opened');
    }
    return classes;
  }

  get hintType(): HintVariant {
    return this.error ? 'error' : 'helper';
  }

  get isMonthPicker() {
    return this.mode === 'month';
  }

  get isYearPicker() {
    return this.mode === 'year';
  }

  constructor(@Inject(MAT_DATE_FORMATS) private matDateFormats: MatDateFormats) {}

  monthSelected(value: Date): void {
    if (this.isMonthPicker) {
      this.datepicker.close();
      this.value = value;
      this.onChange(value);
    }
  }

  yearSelected(value: Date): void {
    if (this.isYearPicker) {
      this.datepicker.close();
      this.value = value;
      this.onChange(value);
    }
  }

  onViewChanged(view: MatCalendarView): void {
    this.isYearView = view === 'year';
  }

  writeValue(value: Date | DateRange): void {
    if (!value) {
      return;
    }

    if (this.isRange && 'start' in value) {
      this.rangeStart = value?.start;
      this.rangeEnd = value?.end;
    } else if (typeof value === 'string') {
      this.value = new Date(value);
    }
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  fieldClick(): void {
    this.inputElem.nativeElement.focus();
  }

  openCalendar(): void {
    if (this.disabled) {
      return;
    }

    this.datepicker.open();
  }

  onCalendarToggle(isOpened: boolean): void {
    this.opened = isOpened;
  }

  onRangeChanged(): void {
    this.onChange({
      start: this.rangeStart,
      end: this.rangeEnd,
    });
  }
}

export interface DateRange {
  start: Date;
  end: Date;
}
