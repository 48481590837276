import { Injectable, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, lastValueFrom, Observable, Subject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { INVENTORY_TYPE } from '@app/constants/inventory';
import { RevertDialogComponent } from '@app/modules/warehouse/components/revert-dialog/revert-dialog.component';
import { WarehouseStorage } from '@app/modules/warehouse/services';
import { CreateProductDialogComponent } from '@app/shared/component/create-product-dialog/create-product-dialog.component';
import { ModalRef, ModalService } from '@app/shared/component/dialog/abstract';
import { DateService } from '@app/shared/service/date.service';
import { ExportReportService } from '@app/shared/service/export-report.service';
import {
  DOC_STATUS,
  exportUrlByReportType,
  MAX_CHARACTERS,
  MAX_FRACTIONAL,
  MAX_INTEGER,
  RootNavigationRoute,
  ROUTE_CREATE_NEW,
  STATUS_DOCUMENT,
  UNIT_TYPE,
  WarehouseRoute,
} from '@constants';
import { FormConfirmSaveService, ValidationErrorsService } from '@core/service';
import { NumberFormatService } from '@core/service/number-format.service';
import { SessionStorage } from '@services/api';
import { CatalogStorage, ProductStorage } from '@services/catalog';
import { NotifyService, SidenavService } from '@services/shared';
import {
  AddInventoryProductsInput,
  CreateInventoryDocInput,
  DocStatus,
  InventoryDoc,
  InventoryDocPage,
  InventoryExportFilters,
  InventoryForm,
  InventoryItemForm,
  InventoryProduct,
  InventoryProductData,
  InventoryProductInput,
  InventoryProductPage,
  InventoryType,
  PageRequestInput,
  ProductCreateInput,
  ProductDialogForm,
  ProductInventoryData,
  QueryInventoryDocsArgs,
  QueryInventoryProductsArgs,
  QueryResult,
  RemoveInventoryProductsInput,
  StockUnit,
  StockUnitInput,
  UpdateInventoryDocInput,
  UpdateInventoryProductInput,
  UpdateInventoryProductsInput,
} from '@typings';
import { notEmpty } from '@utils';

import { InventoriesStorage } from './inventories.storage';

@Injectable({
  providedIn: 'root',
})
export class InventoriesService {
  form: FormGroup<InventoryForm>;
  modalRef: ModalRef<unknown>;
  exportReportType = exportUrlByReportType;
  inventory: InventoryDoc;
  inventoryProducts: InventoryProduct[];

  isEditing$: Observable<boolean>;
  isConfirmed$ = new BehaviorSubject<boolean>(false);
  isSubmitDisabled$ = new BehaviorSubject(false);
  isLoadingConfirmed$ = new BehaviorSubject<boolean>(false);
  inventoryId$: Observable<string | null>;

  productIndex: number | undefined;
  #productDialogRef: ModalRef<unknown>;
  productForm: FormGroup<ProductDialogForm>;
  isLoading$ = new BehaviorSubject<boolean>(false);

  updateInventoryInTable = new Subject<InventoryDoc>();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private inventoriesStorage: InventoriesStorage,
    private exportReportService: ExportReportService,
    private formConfirmSaveService: FormConfirmSaveService,
    private sessionStorage: SessionStorage,
    private sidenavService: SidenavService,
    private modalService: ModalService,
    private warehouseStorage: WarehouseStorage,
    private dateService: DateService,
    private notifyService: NotifyService,
    private productStorage: ProductStorage,
    private validationErrorsService: ValidationErrorsService,
    private catalogStorage: CatalogStorage,
    private numberFormatService: NumberFormatService,
  ) {}

  isPending(): boolean {
    return this.inventory?.status === STATUS_DOCUMENT.PENDING;
  }

  isConfirmed(): boolean {
    return this.inventory?.status === STATUS_DOCUMENT.CONFIRMED;
  }

  initForm(inventory: InventoryDoc, products: InventoryProduct[], shouldConfirm = true): void {
    this.inventory = inventory;
    this.inventoryProducts = products;

    this.form = this.fb.group<InventoryForm>({
      fromWarehouseId: this.fb.control(null, [Validators.required]),
      type: this.fb.control(INVENTORY_TYPE.PARTIAL),
      date: this.fb.control(new Date().toISOString(), [Validators.required]),
      time: this.fb.nonNullable.control(this.dateService.getTime(new Date()), [Validators.required]),
      description: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.DESCRIPTION)]),
      documentItems: this.fb.array(new Array<FormGroup<InventoryItemForm>>()),
      docNumber: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.DOC_NUMBER)]),
    });

    if (this.inventory) {
      const { controls } = this.form;
      const { storageRoom, number, type, date, description, status } = this.inventory;

      controls.fromWarehouseId.setValue(storageRoom && storageRoom?.id && !storageRoom.archived ? storageRoom.id : null);
      controls.type.setValue(type ? type : null);
      controls.date.setValue(date ? date : null);
      controls.time.setValue(date ? this.dateService.getTime(new Date(date)) : null);
      controls.description.setValue(description ? description : null);
      controls.docNumber.setValue(number || number == '0' ? number : null);

      if (this.inventoryProducts && this.inventoryProducts.length) {
        this.inventoryProducts.forEach((documentItem) => controls.documentItems.push(this.addInventoryItemV2(documentItem)));
      }

      if (status !== 'CONFIRMED' && !this.isConfirmed()) {
        controls.documentItems.push(this.addInventoryItem());
      }

      if (status && this.isConfirmed()) {
        this.form.disable();
      }
    }

    this.isEditing$ = new BehaviorSubject(!!this.inventory);
    this.isLoadingConfirmed$.next(false);
    this.isConfirmed$.next(this.inventory?.status === 'CONFIRMED');
    this.inventoryId$ = new BehaviorSubject(this.inventory?.id || null);
    this.isSubmitDisabled$.next(this.inventory?.status === 'CONFIRMED');

    if (shouldConfirm) {
      setTimeout(() => {
        this.formConfirmSaveService.setForm(this.form);
      }, 500);
    }
  }

  addInventoryItem(item?: InventoryProductData): FormGroup<InventoryItemForm> {
    const document: FormGroup<InventoryItemForm> = this.fb.group<InventoryItemForm>({
      stockUnit: this.fb.nonNullable.control(null),
      lastInventoryDocumentId: this.fb.control(null),
      lastInventoryDate: this.fb.control(null),
      leftoverOnLastInventory: this.fb.control(null),
      arrivalFromLastInventory: this.fb.control(null),
      soldFromLastInventory: this.fb.control(null),
      writtenOffFromLastInventory: this.fb.control(null),
      planQuantity: this.fb.control('0'),
      factQuantity: this.fb.control(null),
      quantityDelta: this.fb.control('0'),
      amountDelta: this.fb.control('0'),
      primePrice: this.fb.nonNullable.control('0'),
    });

    if (item) {
      const {
        product,
        lastInventoryDocumentId,
        lastInventoryDate,
        leftoverOnLastInventory,
        arrivalFromLastInventory,
        soldFromLastInventory,
        writtenOffFromLastInventory,
        planQuantity,
        factQuantity,
        delta,
        primePrice,
      } = item;

      document.setValue({
        stockUnit: notEmpty(product) ? { type: 'item', label: product.name, id: product.id, data: product } : null,
        lastInventoryDocumentId: notEmpty(lastInventoryDocumentId) ? lastInventoryDocumentId : null,
        lastInventoryDate: notEmpty(lastInventoryDate) ? lastInventoryDate : null,
        leftoverOnLastInventory: notEmpty(leftoverOnLastInventory) ? String(leftoverOnLastInventory) : null,
        arrivalFromLastInventory: notEmpty(arrivalFromLastInventory) ? String(arrivalFromLastInventory) : null,
        soldFromLastInventory: notEmpty(soldFromLastInventory) ? String(soldFromLastInventory) : null,
        writtenOffFromLastInventory: notEmpty(writtenOffFromLastInventory) ? String(writtenOffFromLastInventory) : null,
        planQuantity: notEmpty(planQuantity) ? String(planQuantity) : '0',
        factQuantity: notEmpty(factQuantity) ? Number(factQuantity) : null,
        quantityDelta: String(Number(factQuantity) - Number(planQuantity)),
        amountDelta: notEmpty(delta) ? String(delta) : '0',
        primePrice: String(primePrice) || '0',
      });
    }

    return document;
  }

  addInventoryItemV2(item?: InventoryProduct): FormGroup<InventoryItemForm> {
    const document: FormGroup<InventoryItemForm> = this.fb.group<InventoryItemForm>({
      stockUnit: this.fb.nonNullable.control(null),
      lastInventoryDocumentId: this.fb.control(null),
      lastInventoryDate: this.fb.control(null),
      leftoverOnLastInventory: this.fb.control(null),
      arrivalFromLastInventory: this.fb.control(null),
      soldFromLastInventory: this.fb.control(null),
      writtenOffFromLastInventory: this.fb.control(null),
      planQuantity: this.fb.control('0'),
      factQuantity: this.fb.control(null),
      quantityDelta: this.fb.control('0'),
      amountDelta: this.fb.control('0'),
      primePrice: this.fb.nonNullable.control('0'),
    });

    if (item) {
      const { product, planQuantity, factQuantity, delta, primePrice, deltaSum } = item;
      const amountDelta = this.isConfirmed() ? deltaSum : '0';

      document.setValue({
        stockUnit: notEmpty(product) ? { type: 'item', label: product.name, id: product.id, data: product } : null,
        lastInventoryDocumentId: null,
        lastInventoryDate: null,
        leftoverOnLastInventory: null,
        arrivalFromLastInventory: null,
        soldFromLastInventory: null,
        writtenOffFromLastInventory: null,
        planQuantity: notEmpty(planQuantity) ? String(planQuantity) : '0',
        factQuantity: notEmpty(factQuantity) ? Number(factQuantity) : null,
        quantityDelta: String(delta),
        amountDelta: notEmpty(amountDelta) ? String(amountDelta) : '0',
        primePrice: String(primePrice) || '0',
      });
    }

    return document;
  }

  getAmountDelta(price: string, delta: string | null): string {
    let amountDelta = 0;

    if (price) {
      const priceNum = Number(price) || 0;
      const deltaNum = Number(delta);

      amountDelta = priceNum * deltaNum;
    }

    return this.validateNumber(String(amountDelta), {
      maxInteger: MAX_INTEGER.PRIME_PRICE,
      maxFractional: MAX_FRACTIONAL.PRIME_PRICE,
    });
  }

  validateNumber(
    value: string | number | null,
    options: { unit?: string; maxInteger?: number; maxFractional?: number; stub?: '—' | '0' } = {},
  ): string {
    const { unit, maxInteger, maxFractional, stub } = options;

    const valueStr = this.numberFormatService.formatNumber(value, {
      isDecimal: true,
      maxFractionalDigits: maxFractional || MAX_FRACTIONAL.QUANTITY,
      maxIntegerDigits: maxInteger || MAX_INTEGER.QUANTITY,
    });

    const unitSuffix = unit ? ` ${unit}` : '';

    if (!valueStr) {
      if (stub === '0') {
        return stub + unitSuffix;
      }

      return stub || '—';
    }

    return valueStr + unitSuffix;
  }

  getProducts(pageRequest: PageRequestInput, searchText: string, excludeStockUnitIds: string[]): QueryResult<'products'> {
    return this.productStorage.getProducts({
      pageRequest,
      filter: {
        search: searchText,
        excludeStockUnitIds,
      },
    });
  }

  getStockUnits(ids?: string[], pageRequest?: PageRequestInput): Promise<StockUnit[]> {
    return lastValueFrom(
      this.productStorage
        .getStockUnits({
          filter: { ids },
          pageRequest,
        })
        .pipe(map((res) => res.data.stockUnits.content)),
    );
  }

  getStockUnitInventoryData(storageRoomId: string, productIds: string[]): Promise<ProductInventoryData[]> {
    return lastValueFrom(
      this.inventoriesStorage
        .productsInventoryData({
          storageRoomId,
          productIds,
        })
        .pipe(map((res) => res.data.productsInventoryData)),
    );
  }

  getPossibleDocumentCreationTypes(warehouseId: string): Promise<InventoryType[]> {
    return lastValueFrom(
      this.inventoriesStorage
        .getPossibleDocumentCreationTypes({ warehouseId })
        .pipe(map((res) => res.data.getPossibleDocumentCreationTypes)),
    );
  }

  openInventoryPage(id?: string, extras?: NavigationExtras): Promise<boolean> {
    this.formConfirmSaveService.closeForm(false);

    return this.router.navigate(
      [this.sessionStorage.getOrgId(), RootNavigationRoute.warehouse, WarehouseRoute.inventories, id || ROUTE_CREATE_NEW],
      extras,
    );
  }

  back(shouldConfirm: boolean = true): void {
    this.formConfirmSaveService.closeForm(false);
    this.sidenavService.back(shouldConfirm);
  }

  submitForm(): void {
    if (!this.haveInventoryItemsInput(this.form.controls.documentItems)) {
      this.notifyService.addNotification({
        type: 'alert',
        title: 'В инвентаризации должны быть указаны позиции',
      });

      return;
    }

    if (this.form.invalid) {
      this.validationErrorsService.markFormControls(this.form);
      this.notifyService.addNotification({
        type: 'alert',
        title: 'Необходимо заполнить обязательные поля',
      });

      return;
    }

    this.disableForm();

    this.inventory ? this.updateInventory() : this.createInventory();
  }

  haveInventoryItemsInput(documentItems: FormArray<FormGroup<InventoryItemForm>>) {
    return documentItems.length > 1;
  }

  disableForm(): void {
    this.form.disable();
    this.isSubmitDisabled$.next(true);
  }

  enableForm(): void {
    this.form.enable();
    this.form.controls.documentItems.controls.forEach((d) => {
      if (!d.controls.stockUnit.value) {
        d.controls.factQuantity.disable();
      }
    });
    this.isSubmitDisabled$.next(false);
  }

  createInventory(): void {
    this.inventoriesStorage.createInventoryDoc({ input: this.createInventoryDocumentCreateInput() }).subscribe(
      (res) => {
        if (res.data && res.data.createInventoryDoc.output) {
          const documentItems = this.getInventoryItemsInput(this.form.controls.documentItems);
          const input: AddInventoryProductsInput = {
            docId: res.data.createInventoryDoc.output.id,
            inventoryProducts: documentItems,
          };

          this.inventoriesStorage.addInventoryProducts({ input }).subscribe(() => this.back(false));
        } else {
          this.enableForm();
        }
      },
      () => this.enableForm(),
    );
  }

  createInventoryDocumentCreateInput(): CreateInventoryDocInput {
    const { fromWarehouseId, type, date, time, description, docNumber } = this.form.controls;

    return {
      storageRoomId: fromWarehouseId.value!,
      type: type.value,
      date: this.dateService.getDateTime(date.value, time.value) || '',
      description: description.value,
      number: docNumber.value || null,
    };
  }

  createInventoryDocumentUpdateInput(): UpdateInventoryDocInput {
    const { date, time, description, docNumber } = this.form.controls;

    return {
      id: this.inventory.id!,
      date: this.dateService.getDateTime(date.value, time.value) || '',
      description: description.value,
      number: docNumber.value || null,
    };
  }

  getInventoryItemsInput(documentItems: FormArray<FormGroup<InventoryItemForm>>): InventoryProductInput[] {
    return documentItems.controls
      .filter((item) => item.controls.stockUnit.value)
      .map((documentItem) => {
        const inventoryItem = documentItem.controls;
        const { planQuantity, factQuantity } = inventoryItem;
        const { id, unit } = inventoryItem.stockUnit.value!.data!;

        const item: InventoryProductInput = {
          productId: id,
          unit: unit,
          planQuantity: String(planQuantity.value),
          factQuantity: String(factQuantity.value || 0),
        };

        return item;
      });
  }

  updateInventory(): void {
    this.inventoriesStorage.updateInventoryDoc({ input: this.createInventoryDocumentUpdateInput() }).subscribe(
      (res) => {
        if (res.data && res.data.updateInventoryDoc.output) {
          const productsToAdd: InventoryProductInput[] = [];
          const productIdsToRemove: string[] = [];
          const updateProducts: UpdateInventoryProductInput[] = [];
          const documentItems = this.getInventoryItemsInput(this.form.controls.documentItems);

          documentItems.forEach((d) => {
            if (!this.inventoryProducts.some((prod) => prod.product?.id === d.productId)) {
              productsToAdd.push(d);
            }
          });

          this.inventoryProducts.forEach((prod) => {
            if (!documentItems.some((d) => d.productId === prod.product?.id)) {
              productIdsToRemove.push(prod.id);
            }
          });

          this.inventoryProducts.forEach((prod) => {
            const docItem = documentItems.find((d) => d.productId === prod.product?.id)!;
            if (docItem && (docItem.factQuantity !== String(prod.factQuantity) || docItem.planQuantity !== String(prod.planQuantity))) {
              const input = {
                id: prod.id,
                factQuantity: docItem.factQuantity,
                planQuantity: docItem.planQuantity,
              };
              updateProducts.push(input);
            }
          });

          if (productsToAdd.length > 0) {
            const input: AddInventoryProductsInput = {
              docId: res.data.updateInventoryDoc.output.id,
              inventoryProducts: productsToAdd,
            };
            this.inventoriesStorage.addInventoryProducts({ input }).subscribe();
          }

          if (productIdsToRemove.length > 0) {
            const input: RemoveInventoryProductsInput = {
              inventoryProductIds: productIdsToRemove,
            };
            this.inventoriesStorage.removeInventoryProducts({ input }).subscribe();
          }

          if (updateProducts.length > 0) {
            const input: UpdateInventoryProductsInput = {
              products: updateProducts,
            };
            this.inventoriesStorage.updateInventoryProducts({ input }).subscribe();
          }
        }

        this.back(false);
      },
      () => this.enableForm(),
    );
  }

  showModal(modalTemplate: TemplateRef<unknown>): void {
    this.modalRef = this.modalService.openDialog(modalTemplate);
  }

  showRevertModal(inventory: InventoryDoc, callbackFn: () => void): void {
    this.modalRef = this.modalService.openDialog(RevertDialogComponent, {
      data: {
        title: ` инвентаризации №${inventory.number}`,
        callbackFn,
        hideFn: () => {
          this.modalRef.close();
        },
      },
    });
  }

  revertInventory(inventory: InventoryDoc): Observable<string | undefined> {
    return this.inventoriesStorage
      .revertInventoryDoc({
        id: inventory.id,
      })
      .pipe(
        tap(() => {
          this.formConfirmSaveService.closeForm(false);
        }),
        map((_) => inventory.id),
      );
  }

  hideModal(): void {
    this.modalRef.close();
  }

  cancelModal() {
    this.modalRef.close(false);
  }

  okModal() {
    this.modalRef.close(true);
  }

  deleteInventory(id: string): Observable<string> {
    return this.inventoriesStorage
      .deleteInventoryDoc({ id })
      .pipe(map((res) => (res.data?.deleteInventoryDoc.result === 'SUCCESS' ? id : '')));
  }

  confirmInventory(inventoryId: string): Observable<string | undefined> {
    return this.inventoriesStorage
      .confirmInventoryDoc({
        id: inventoryId,
      })
      .pipe(
        tap(() => {
          this.formConfirmSaveService.closeForm(false);
        }),
        map((res) => (res.data?.confirmInventoryDoc.result === 'SUCCESS' ? inventoryId : '')),
      );
  }

  duplicateInventory(documentId: string): void {
    this.inventoriesStorage
      .duplicateInventory({ documentId })
      .pipe(map((res) => res.data?.duplicateInventoryDocument?.output?.id))
      .subscribe((id) => {
        if (id) {
          this.openInventoryPage(id);
        }
      });
  }

  getInventoriesList(variables: QueryInventoryDocsArgs): Observable<InventoryDocPage> {
    return this.inventoriesStorage.inventoryDocs(variables).pipe(map((res) => res.data.inventoryDocs!));
  }

  getInventory(id: string): Observable<InventoryDoc> {
    return this.inventoriesStorage.inventoryDoc({ id }).pipe(map((res) => res.data.inventoryDoc!));
  }

  exportInventoryListReport(id: string): void {
    this.exportReportService.exportReportWithHandler(
      this.exportReportType.INVENTORY_LIST.url,
      {
        documentId: id,
        zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      this.exportReportType.INVENTORY_LIST.fileName,
    );
  }

  exportReport(id: string, warehouseId: string): void {
    this.exportReportService.exportReportWithHandler(
      this.exportReportType.INVENTORY.url,
      {
        warehouseId,
        documentId: id,
        zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      this.exportReportType.INVENTORY.fileName,
    );
  }

  exportReportAll(params: InventoryExportFilters): void {
    const { warehouseIds, statuses, search, dateFrom, dateTo } = params;

    const exportParams: Record<string, string> = {
      zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if (!!search) {
      exportParams.search = search;
    }

    if (dateFrom) {
      exportParams.dateFrom = this.exportReportService.setModifiedDate(dateFrom);
    }

    if (dateTo) {
      exportParams.dateTo = this.exportReportService.setModifiedDate(dateTo);
    }

    if (!!warehouseIds && !!warehouseIds.length) {
      exportParams.warehouseIds = warehouseIds.join(',');
    }

    if (!!statuses && !!statuses.length) {
      exportParams.statuses = statuses.join(',');
    }

    this.exportReportService.exportReportWithHandler(
      this.exportReportType.ALL_INVENTORY_V2.url,
      exportParams,
      this.exportReportType.ALL_INVENTORY_V2.fileName,
    );
  }

  stockUnitInventoryDataToDocumentItem(stockUnitInventoryItem: ProductInventoryData, stockUnits: StockUnit[]): InventoryProductData {
    const {
      productId,
      lastInventoryDocumentId,
      lastInventoryDate,
      leftoverOnLastInventory,
      arrivalFromLastInventory,
      soldFromLastInventory,
      writtenOffFromLastInventory,
      planQuantity,
    } = stockUnitInventoryItem;

    return {
      id: productId,
      productId: productId,
      product: stockUnits.find((stockUnit) => stockUnit.id === productId),
      lastInventoryDocumentId: notEmpty(lastInventoryDocumentId) ? lastInventoryDocumentId : null,
      lastInventoryDate: notEmpty(lastInventoryDate) ? lastInventoryDate : null,
      leftoverOnLastInventory: notEmpty(leftoverOnLastInventory) ? String(leftoverOnLastInventory) : null,
      arrivalFromLastInventory: notEmpty(arrivalFromLastInventory) ? String(arrivalFromLastInventory) : null,
      soldFromLastInventory: notEmpty(soldFromLastInventory) ? String(soldFromLastInventory) : null,
      writtenOffFromLastInventory: notEmpty(writtenOffFromLastInventory) ? String(writtenOffFromLastInventory) : null,
      planQuantity: notEmpty(planQuantity) ? String(planQuantity) : '0',
      factQuantity: notEmpty(planQuantity) ? String(planQuantity) : '0',
      delta: '0',
      unit: stockUnits.find((stockUnit) => stockUnit.id === productId)!.unit,
    };
  }

  initProductForm(name: string) {
    this.productForm = this.fb.group<ProductDialogForm>({
      name: this.fb.nonNullable.control(name, [Validators.required, Validators.maxLength(MAX_CHARACTERS.PRODUCT_NAME)]),
      section: this.fb.nonNullable.control(null, [Validators.required]),
      type: this.fb.control(null, [Validators.required]),
      weighable: this.fb.nonNullable.control(false),
      unit: this.fb.nonNullable.control(UNIT_TYPE.PIECE),
      quantity: this.fb.nonNullable.control('1', [Validators.required, Validators.min(0)]),
      primePrice: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
      salePrice: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
      surcharge: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
    });
  }

  openProductDialog(name: string, index: number): void {
    this.initProductForm(name);
    this.productIndex = index;
    this.#productDialogRef = this.modalService.openDialog(CreateProductDialogComponent, {
      data: {
        form: this.productForm,
        title: 'Новая позиция',
        name,
        close: (confirm: boolean) => this.closeCreateProductDialog(confirm),
        filterType: true,
      },
      disableClose: true,
    });
  }

  closeCreateProductDialog(confirm: boolean): void {
    if (confirm) {
      if (this.productForm.valid) {
        this.createProduct();
        this.hideModalCreateProduct();
      } else {
        this.validationErrorsService.markFormControls(this.productForm);
      }
    } else {
      this.hideModalCreateProduct();
    }
  }

  hideModalCreateProduct(): void {
    if (!this.#productDialogRef) {
      return;
    }
    this.#productDialogRef.close();
  }

  private createProduct(): void {
    const { name, type, weighable, unit, quantity, section, primePrice, salePrice } = this.productForm.controls;

    const stockUnitInput: StockUnitInput = {
      name: name.value,
      weighable: !weighable.value,
      unit: unit.value || 'NONE',
      quantity: quantity.value,
      primePrice: { amountValue: primePrice.value, currencyUnit: this.sessionStorage.getCurrencyUnit() },
      salePrice: { amountValue: salePrice.value, currencyUnit: this.sessionStorage.getCurrencyUnit() },
    };

    const productCreateInput: ProductCreateInput = {
      catalogId: this.catalogStorage.getCatalog().id,
      name: name.value,
      type: type.value,
      sectionId: section.value?.id,
      stockUnits: [stockUnitInput],
    };

    this.productStorage.createProduct({ product: productCreateInput }).subscribe((res) => {
      const stockUnits = res.data?.createProductV2?.output?.stockUnits;

      if (this.productIndex !== undefined && stockUnits) {
        const stockUnit = stockUnits[0];
        this.form.controls.documentItems
          .at(this.productIndex)
          .controls.stockUnit.patchValue({ type: 'item', label: stockUnit.name, id: stockUnit.id, data: stockUnit });
      }
    });
  }

  getInventoryProductsPromise(input: QueryInventoryProductsArgs): Promise<InventoryProductPage> {
    return new Promise<InventoryProductPage>((resolve) => {
      this.getInventoryProducts(input)
        .pipe(take(1))
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  getInventoryProducts(input: QueryInventoryProductsArgs): Observable<InventoryProductPage> {
    return this.inventoriesStorage.inventoryProducts(input).pipe(map((res) => res.data.inventoryProducts!));
  }

  isOpenInventory(status: DocStatus) {
    return status === DOC_STATUS.OPEN || status === DOC_STATUS.FAILURE || status === DOC_STATUS.PENDING_CONFIRMATION;
  }

  getWarehouses(pageRequest: PageRequestInput, searchText: string): QueryResult<'storageRooms'> {
    return this.warehouseStorage.storageRooms({
      pageRequest,
      filter: {
        search: searchText,
        states: ['NOT_ARCHIVED'],
      },
    });
  }
}
