import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RouterModule } from '@angular/router';
import { IMaskModule } from 'angular-imask';

import {
  BackButtonModule,
  ButtonModule,
  CalendarModule,
  CalendarWithInputModule,
  CardModule,
  CustomPopupModule,
  IconModule,
  MyButtonModule,
  NotificationModule,
} from '@nomia/nomia-components-lib';
import {
  NomiaUiButtonModule,
  NomiaUiDropDownModule,
  NomiaUiFormModule,
  NomiaUiIconSpriteModule,
  NomiaUiLibModule,
} from '@nomia/nomia-ui-lib';

import { components } from '@app/shared/component';
import { OrganizationComponent } from '@app/shared/component/organization/organization.component';
import { DeclensionPipe } from '@app/shared/pipe/declension.pipe';

import { FakePopupComponent } from './component/fake-popup/fake-popup.component';
import { FakeSidebarComponent } from './component/fake-sidebar/fake-sidebar.component';
import { InputModule } from './component/input/input.module';
import { PopupComponent } from './component/popup/popup.component';
import { AutofocusModule } from './directive/autofocus.module';
import { SharedPipesModule } from './pipe/pipes.module';

@NgModule({
  declarations: [...components, PopupComponent, OrganizationComponent, FakeSidebarComponent, FakePopupComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule, // think about it
    NomiaUiLibModule,
    NomiaUiFormModule,
    NomiaUiDropDownModule,
    NomiaUiButtonModule,
    IMaskModule,
    NomiaUiIconSpriteModule.forRoot({ path: 'assets/icons/sprite.svg' }),
    ButtonModule,
    CardModule,
    IconModule,
    InputModule,
    CustomPopupModule,
    BackButtonModule,
    NotificationModule,
    PortalModule,
    MyButtonModule,
    CalendarModule,
    CalendarWithInputModule,
    AutofocusModule,
    SharedPipesModule,
    MatAutocompleteModule,
  ],
  exports: [
    NomiaUiLibModule,
    NomiaUiIconSpriteModule,
    IMaskModule,
    NomiaUiDropDownModule,
    ...components,
    PopupComponent,
    OrganizationComponent,
    FakeSidebarComponent,
    MyButtonModule,
    AutofocusModule,
  ],
  providers: [DeclensionPipe],
})
export class SharedModule {}
