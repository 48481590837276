import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

export type MoveDirection = 'up' | 'down';
export type ScrollVisibilityEvent = { direction: MoveDirection; element: HTMLElement | null; intersectionRatio: number };
@Directive({
  selector: '[nmScrollVisibility]',
})
export class ScrollVisibilityDirective implements OnDestroy, OnInit, AfterViewInit {
  @Input() threshold = 1;
  @Input() rootMargin = '50px';

  @Output() visible = new EventEmitter<ScrollVisibilityEvent>();
  @Output() hidden = new EventEmitter<ScrollVisibilityEvent>();
  @Output() heightInited = new EventEmitter<number>();

  private observer: IntersectionObserver | undefined;

  private prevY = 0;

  constructor(private element: ElementRef) {}

  ngOnInit() {
    this.createObserver();
  }

  ngAfterViewInit() {
    this.observer?.observe(this.element.nativeElement);
    this.heightInited.emit((this.element.nativeElement as HTMLElement).getBoundingClientRect().height);
  }

  ngOnDestroy() {
    this.observer?.disconnect();
  }

  private createObserver() {
    this.observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        const { isIntersecting, boundingClientRect, target, intersectionRatio } = entry;
        const currentY = boundingClientRect.y;
        const direction: MoveDirection = currentY > this.prevY ? 'down' : 'up';

        if (isIntersecting) {
          this.visible.emit({ direction, intersectionRatio, element: target as HTMLElement });
        } else {
          this.hidden.emit({ direction, intersectionRatio, element: target as HTMLElement });
        }

        this.prevY = currentY;
      },
      {
        rootMargin: this.rootMargin,
        threshold: this.threshold,
      },
    );
  }
}
