<mat-chip
  #matChip
  [attr.data-test-id]="testId"
  [ngClass]="chipClasses"
  [disableRipple]="true"
  [disabled]="disabled"
  [selected]="selected"
  (mouseup)="blur(matChip)"
>
  <nm-avatar
    *ngIf="showAvatar"
    [title]="selected ? '' : avatarTitle"
    [imageUrl]="selected ? '' : avatarImageUrl"
    [icon]="selected ? 'check' : 'profile'"
    [color]="selected ? '#131c2c' : ''"
    [type]="'round'"
    size="24"
    [disabled]="disabled"
  ></nm-avatar>
  <nm-svg-icon *ngIf="iconLeft" class="left-icon" [size]="18" [name]="iconLeft"></nm-svg-icon>
  <img *ngIf="image" [src]="image" class="left-image" />
  <span class="chip-text">
    <ng-content></ng-content>
  </span>
  <nm-svg-icon
    *ngIf="iconRight"
    class="right-icon"
    [size]="18"
    [name]="iconRight"
    testId="icon-right"
    (click)="onRightIconClick($event)"
  ></nm-svg-icon>
</mat-chip>
