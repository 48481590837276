import { ErrorHandler, Injectable } from '@angular/core';
import { GraphQLError } from 'graphql';

import { ConfigService } from '@app/config.service';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  #sentry: typeof Sentry;

  constructor(private configService: ConfigService) {
    this.configService.config$.then((config) => {
      const { sentryDns, sentryEnabled, sentryRelease, sentryEnvironment } = config;
      this.#sentry = Sentry;

      this.#sentry.init({
        dsn: sentryDns,
        integrations: [
          new BrowserTracing({
            tracingOrigins: ['develop.erp.n.nomia.tech', 'stage.erp.n.nomia.tech', 'erp.nomia.app'],
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],
        tracesSampleRate: 1.0,
        enabled: sentryEnabled,
        release: sentryRelease,
        environment: sentryEnvironment,
      });
    });
  }

  handleError(error: Error | GraphQLError): void {
    if (!environment.production) {
      console.error(error);
      return;
    }

    if (error) {
      try {
        this.#sentry.captureException('originalError' in error ? error.originalError : error);
      } catch {}
    }
  }
}
