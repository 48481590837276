import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { ButtonModule } from '@app/shared/component/button/button.module';
import { InputModule } from '@app/shared/component/input/input.module';
import { InputSearchModule } from '@app/shared/component/input-search/input-search.module';
import { MenuHeaderModule } from '@app/shared/component/menu-header/menu-header.module';
import { MenuItemModule } from '@app/shared/component/menu-item/menu-item.module';
import { ProgressSpinnerModule } from '@app/shared/component/progress-spinner/progress-spinner.module';
import { ScrollVisibilityModule } from '@app/shared/directive/scroll-visibility/scroll-visibility.module';

import { MenuContentComponent } from './menu-content/menu-content.component';
import { MenuComponent } from './menu.component';

@NgModule({
  declarations: [MenuComponent, MenuContentComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MenuItemModule,
    MenuHeaderModule,
    ButtonModule,
    InputModule,
    ProgressSpinnerModule,
    InputSearchModule,
    OverlayModule,
    ScrollVisibilityModule,
    VirtualScrollerModule,
  ],
  exports: [MenuComponent, MenuContentComponent],
})
export class MenuModule {}
