export const sortObjectByKeys = <T extends Object>(unsorted: T): T => {
  const empty = {} as T;

  if (!unsorted) {
    return empty;
  }

  return Object.keys<T>(unsorted)
    .sort()
    .reduce((obj, key: keyof T) => {
      obj[key] = unsorted[key];
      return obj;
    }, empty);
};
