import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AfterViewInit, Directive, ElementRef, InjectionToken, Injector, Input, OnDestroy } from '@angular/core';

import { TooltipComponent } from './tooltip-component/tootlip.component';

@Directive({
  selector: '[nmTooltip]',
})
export class TooltipDirective implements AfterViewInit, OnDestroy {
  @Input() position: ConnectedPosition = {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
  };

  @Input() TooltipContent = '';

  overlayRef: OverlayRef | null;

  constructor(private element: ElementRef, private overlay: Overlay, private injector: Injector) {}

  ngAfterViewInit(): void {
    this.element.nativeElement.addEventListener('mouseenter', () => {
      this.showTooltip();
    });
    this.element.nativeElement.addEventListener('mouseleave', () => {
      this.hideTooltip();
    });
  }

  ngOnDestroy(): void {
    this.hideTooltip();
  }

  showTooltip() {
    if (!this.TooltipContent || this.overlayRef) {
      return;
    }
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position().flexibleConnectedTo(this.element).withPositions([this.position]),
    });
    const userProfilePortal = new ComponentPortal(
      TooltipComponent,
      null,
      Injector.create([{ provide: CONTENT, useValue: this.TooltipContent }], this.injector),
    );
    this.overlayRef.attach(userProfilePortal);
  }

  hideTooltip() {
    this.overlayRef?.detach();
    this.overlayRef = null;
  }
}

export const CONTENT = new InjectionToken<string>('');
