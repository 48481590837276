import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { SubscriptionsService } from '@services/settings';
import { ServicePlanUsageSummary } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class ServicePlanUsageSummaryResolver implements Resolve<ServicePlanUsageSummary> {
  constructor(private subscriptionsService: SubscriptionsService) {}

  resolve(): Observable<ServicePlanUsageSummary> {
    return this.subscriptionsService.getServicePlanUsageSummary();
  }
}
