import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { RoleService } from '@app/modules/employees/services/roles/role.service';
import { Role } from '@app/typings/role';
import { EmployeeRouteParam, ROUTE_CREATE_NEW } from '@constants';

@Injectable({
  providedIn: 'root',
})
export class RoleResolver implements Resolve<Role> {
  constructor(private router: Router, private roleService: RoleService) {
    // TODO: show preloader
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Role> {
    const id = route.params[EmployeeRouteParam.roleId];

    return this.roleService.getRole(id).pipe(
      // TODO: hide preloader
      catchError((err) => {
        this.router.navigateByUrl(state.url.replace(String(id), ROUTE_CREATE_NEW));

        return throwError(() => err);
      }),
    );
  }
}
