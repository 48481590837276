import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitArrayLength',
  pure: false,
})
export class LimitArrayLengthPipe<T> implements PipeTransform {
  transform(items: T[], limit: number | undefined): T[] {
    if (typeof limit === 'number' && limit > 0) {
      return items.slice(0, limit);
    }

    return items;
  }
}
