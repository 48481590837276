import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

import { SVG_ICONS_TYPE } from '@constants';

@Component({
  selector: 'nm-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuHeaderComponent {
  @Input() label: string = '';
  @Input() description: string = '';
  @Input() iconLeft: SVG_ICONS_TYPE | null = null;
  @Input() iconRight: SVG_ICONS_TYPE | null = null;
  @Input() testId: string;
  @Input() imageUrl: string | SafeUrl = '';
  @Input() type: 'round' | 'square' = 'square';
  @Input() imageTitle = '';
  @Input() multiline: boolean = false;

  @Output() headerClick = new EventEmitter<void>();
  @Output() leftIconClick = new EventEmitter<void>();
  @Output() rightIconClick = new EventEmitter<void>();
  @Output() imageLoad = new EventEmitter<void>();
  @Output() imageError = new EventEmitter<void>();

  handleHeaderClick(e: MouseEvent) {
    e.stopPropagation();

    this.headerClick.emit();
  }

  handleLeftIconClick(e: MouseEvent) {
    e.stopPropagation();

    this.leftIconClick.emit();
  }

  handleRightIconClick(e: MouseEvent) {
    e.stopPropagation();

    this.rightIconClick.emit();
  }

  handleImageLoaded() {
    this.imageLoad.emit();
  }

  handleImageLoadError() {
    this.imageError.emit();
  }
}
