import { Injectable } from '@angular/core';

import { mutationCreateRole, mutationDeleteRole, mutationUpdateRole, queryRole, queryRoles, queryRolesByUser } from '@app/api/queries/role';
import {
  MutationCreateRoleArgs,
  MutationDeleteRoleArgs,
  MutationUpdateRoleArgs,
  QueryRoleArgs,
  QueryRolesArgs,
  QueryRolesByUserArgs,
} from '@app/typings/role';
import { ApiService } from '@core/service/api.service';
import { Mutation, MutationResult, Query, QueryResult } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class RoleApi {
  constructor(private api: ApiService) {}

  roles(variables: QueryRolesArgs): QueryResult<'roles'> {
    return this.api.query<Query<'roles'>, QueryRolesArgs>({
      query: queryRoles,
      variables,
    });
  }

  rolesByUser(variables: QueryRolesByUserArgs): QueryResult<'rolesByUser'> {
    return this.api.query<Query<'rolesByUser'>, QueryRolesByUserArgs>({
      query: queryRolesByUser,
      variables,
    });
  }

  role(variables: QueryRoleArgs): QueryResult<'role'> {
    return this.api.query<Query<'role'>, QueryRoleArgs>({
      query: queryRole,
      variables,
    });
  }

  createRole(variables: MutationCreateRoleArgs): MutationResult<'createRole'> {
    return this.api.mutate<Mutation<'createRole'>, MutationCreateRoleArgs>({
      mutation: mutationCreateRole,
      variables,
    });
  }

  updateRole(variables: MutationUpdateRoleArgs): MutationResult<'updateRole'> {
    return this.api.mutate<Mutation<'updateRole'>, MutationUpdateRoleArgs>({
      mutation: mutationUpdateRole,
      variables,
    });
  }

  deleteRole(variables: MutationDeleteRoleArgs): MutationResult<'deleteRole'> {
    return this.api.mutate<Mutation<'deleteRole'>, MutationDeleteRoleArgs>({
      mutation: mutationDeleteRole,
      variables,
    });
  }
}
