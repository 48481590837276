import { gql } from 'apollo-angular';

export const queryAllInCatalogPageable = gql`
  query allInCatalogPageable($filter: InCatalogFilterInput!, $pageRequest: PageRequestInput) {
    allInCatalogPageable(filter: $filter, pageRequest: $pageRequest) {
      total
      pageNumber
      pageSize
      totalPages
      content {
        ... on CatalogSection {
          id
          name
          isParent
          isDefault
          depthLevel
          leftMargin
          rightMargin
          colorSection
          productsCount
          workshop {
            id
            name
          }
          parent {
            id
            name
            isParent
            depthLevel
            leftMargin
            rightMargin
            colorSection
            image {
              originalFileName
              imageSizes {
                width
                height
                url
              }
            }
          }
          colorSection
          image {
            originalFileName
            imageSizes {
              width
              height
              url
            }
          }
        }
        ... on Product {
          id
          name
          section {
            id
            name
            isParent
            depthLevel
            leftMargin
            rightMargin
            parent {
              id
              name
              isParent
              depthLevel
              leftMargin
              rightMargin
              colorSection
            }
            colorSection
          }
          stockUnits {
            id
            name
            unit
            unitTypesMatchList
            primePrice {
              amountValue
              currencyUnit
            }
            salePrice {
              amountValue
              currencyUnit
            }
            techCard {
              id
              techCardItems {
                stockUnit {
                  id
                  name
                  unit
                  primePrice {
                    amountValue
                    currencyUnit
                  }
                }
                brutto
                netto
                bruttoUnitType
              }
              processOfCooking
            }
            images {
              id
              originalFileName
              extension
              imageSizes {
                width
                height
                url
              }
            }
            quantity
          }
        }
      }
    }
  }
`;

export const queryGetWhereUsedPageable = gql`
  query getWhereUsedPageable($pageRequest: PageRequestInput!, $stockUnitId: UUID!) {
    getWhereUsedPageable(pageRequest: $pageRequest, stockUnitId: $stockUnitId) {
      content {
        id
        name
        section {
          id
          name
        }
        stockUnits {
          id
          unit
          primePrice {
            amountValue
            currencyUnit
          }
          salePrice {
            amountValue
            currencyUnit
          }
          images {
            id
            imageSizes {
              url
            }
          }
        }
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
`;

export const queryAllCatalogProductsPageable = gql`
  query allCatalogProductsPageable($filter: ProductFilterInput!, $pageRequest: PageRequestInput!) {
    allCatalogProductsPageable(filter: $filter, pageRequest: $pageRequest) {
      total
      pageNumber
      pageSize
      totalPages
      content {
        id
        name
        type
        catalog {
          id
          name
        }
        modifierGroupWhereUsedAsModifier {
          content {
            id
            name
          }
        }
        section {
          id
          name
          catalog {
            id
            name
          }
          isParent
          labels
          depthLevel
          parent {
            id
            name
            catalog {
              id
              name
            }
            isParent
            labels
            depthLevel
            colorSection
          }
          colorSection
          snoValue {
            entityId
            entityType
            sno
          }
          vatValue {
            entityId
            entityType
            vat
          }
        }
        stockUnits {
          id
          name
          unit
          quantity
          unitTypesMatchList
          positionType {
            code
          }
          primePrice {
            amountValue
            currencyUnit
          }
          weightedAveragePrimePrice {
            amountValue
            currencyUnit
          }
          salePrice {
            amountValue
            currencyUnit
          }
          techCard {
            id
            techCardItems {
              stockUnit {
                id
                name
                unit
                primePrice {
                  amountValue
                  currencyUnit
                }
              }
              brutto
              netto
              bruttoUnitType
              id
            }
            processOfCooking
          }
          images {
            id
            originalFileName
            extension
            imageSizes {
              width
              height
              url
            }
          }
          snoValue {
            entityId
            entityType
            sno
          }
          vatValue {
            entityId
            entityType
            vat
          }
          barcodes {
            code
          }
        }
      }
    }
  }
`;
