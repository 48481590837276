import { Injectable, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SupplierStorage } from '@app/modules/warehouse/services';
import { ModalRef, ModalService } from '@app/shared/component/dialog/abstract';
import { ModalBaseComponent } from '@app/shared/component/dialog/modal-base/modal-base.component';
import {
  isLegalSuppliers,
  MAX_CHARACTERS,
  MIN_CHARACTERS,
  RootNavigationRoute,
  ROUTE_CREATE_NEW,
  TYPE_SUPPLIER,
  WarehouseRoute,
} from '@constants';
import { FormConfirmSaveService, UtilsService, ValidationErrorsService } from '@core/service';
import { SessionStorage } from '@services/api';
import { NotifyService, SidenavService } from '@services/shared';
import {
  CreateSupplierInput,
  IndividualSupplierInfoForm,
  LegalSupplierInfoForm,
  SupplierInfoForm,
  SupplierLegal,
  SupplierPhysical,
  SupplierUnion,
  UpdateSupplierInput,
} from '@typings';
import { createPhoneNumberValidator } from '@utils';

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  form: FormGroup<SupplierInfoForm>;
  legalForm: FormGroup<LegalSupplierInfoForm>;
  individualForm: FormGroup<IndividualSupplierInfoForm>;
  controls: SupplierInfoForm;
  legalControls: LegalSupplierInfoForm;
  individualControls: IndividualSupplierInfoForm;
  supplier: SupplierUnion | undefined;
  modalRef: ModalRef<unknown>;

  isEditing$: Observable<boolean>;
  isSubmitDisabled$ = new BehaviorSubject(false);

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private sessionStorage: SessionStorage,
    private supplierStorage: SupplierStorage,
    private sidenavService: SidenavService,
    private formConfirmSaveService: FormConfirmSaveService,
    private modalService: ModalService,
    private utilsService: UtilsService,
    private validationErrorsService: ValidationErrorsService,
    private notifyService: NotifyService,
  ) {}

  initState(supplier: SupplierUnion | undefined): void {
    this.supplier = supplier;

    if (supplier) {
      this.supplierStorage.setSupplier(supplier);
    }
  }

  setNavSupplier(): void {
    let title: string = 'Новый поставщик';

    if (this.supplier) {
      title = isLegalSuppliers(this.supplier) ? this.supplier.name || '' : this.supplier.name || '';
    }

    this.sidenavService.setTopBarItem({
      title,
      icon: 'arrowLeft',
      route: WarehouseRoute.warehouses,
    });
  }

  initForm(): void {
    this.form = this.fb.group<SupplierInfoForm>({
      type: this.fb.nonNullable.control(TYPE_SUPPLIER.LEGAL_PERSON),
      legal: this.fb.group<LegalSupplierInfoForm>({
        companyName: this.fb.nonNullable.control('', {
          validators: [
            Validators.required,
            Validators.minLength(MIN_CHARACTERS.ORGANIZATION),
            Validators.maxLength(MAX_CHARACTERS.ORGANIZATION),
          ],
        }),
        inn: this.fb.nonNullable.control('', [
          Validators.minLength(MIN_CHARACTERS.INN_LEGAL),
          Validators.maxLength(MAX_CHARACTERS.INN_LEGAL),
        ]),
        kpp: this.fb.nonNullable.control('', [Validators.minLength(MIN_CHARACTERS.KPP), Validators.maxLength(MAX_CHARACTERS.KPP)]),
        address: this.fb.nonNullable.control('', [
          Validators.minLength(MIN_CHARACTERS.ADDRESS),
          Validators.maxLength(MAX_CHARACTERS.ADDRESS),
        ]),
        contactPoint: this.fb.nonNullable.control('', [
          Validators.minLength(MIN_CHARACTERS.NAME),
          Validators.maxLength(MAX_CHARACTERS.SUPPLIER_CONTACT_POINT),
        ]),
        phone: this.fb.nonNullable.control('', [
          Validators.minLength(MIN_CHARACTERS.PHONE),
          Validators.maxLength(MAX_CHARACTERS.PHONE),
          createPhoneNumberValidator(),
        ]),
        email: this.fb.nonNullable.control('', [
          Validators.email,
          Validators.minLength(MIN_CHARACTERS.EMAIL),
          Validators.maxLength(MAX_CHARACTERS.EMAIL),
        ]),
        description: this.fb.nonNullable.control('', [
          Validators.minLength(MIN_CHARACTERS.DESCRIPTION),
          Validators.maxLength(MAX_CHARACTERS.DESCRIPTION),
        ]),
      }),
      individual: this.fb.group<IndividualSupplierInfoForm>({
        contactPoint: this.fb.nonNullable.control('', [
          Validators.required,
          Validators.minLength(MIN_CHARACTERS.NAME),
          Validators.maxLength(MAX_CHARACTERS.SUPPLIER_CONTACT_POINT),
        ]),
        inn: this.fb.nonNullable.control('', [
          Validators.minLength(MIN_CHARACTERS.INN_INDIVIDUAL),
          Validators.maxLength(MAX_CHARACTERS.INN_INDIVIDUAL),
        ]),
        address: this.fb.nonNullable.control('', [
          Validators.minLength(MIN_CHARACTERS.ADDRESS),
          Validators.maxLength(MAX_CHARACTERS.ADDRESS),
        ]),
        phone: this.fb.nonNullable.control('', [
          Validators.minLength(MIN_CHARACTERS.PHONE),
          Validators.maxLength(MAX_CHARACTERS.PHONE),
          createPhoneNumberValidator(),
        ]),
        email: this.fb.nonNullable.control('', [
          Validators.email,
          Validators.minLength(MIN_CHARACTERS.EMAIL),
          Validators.maxLength(MAX_CHARACTERS.EMAIL),
        ]),
        description: this.fb.nonNullable.control('', [
          Validators.minLength(MIN_CHARACTERS.DESCRIPTION),
          Validators.maxLength(MAX_CHARACTERS.DESCRIPTION),
        ]),
      }),
    });
    this.controls = this.form.controls;

    this.legalForm = this.form.controls.legal;
    this.legalControls = this.legalForm.controls;

    this.individualForm = this.form.controls.individual;
    this.individualControls = this.individualForm.controls;

    if (this.supplier) {
      const type = isLegalSuppliers(this.supplier) ? TYPE_SUPPLIER.LEGAL_PERSON : TYPE_SUPPLIER.NATURAL_PERSON;

      if (type) {
        this.controls.type.setValue(type);
      }

      if (this.controls.type.value === TYPE_SUPPLIER.LEGAL_PERSON) {
        const { name, tin, psrn, address, contact, phone, email, description } = this.supplier as SupplierLegal;

        if (name) {
          this.legalControls.companyName.setValue(name);
        }

        if (tin) {
          this.legalControls.inn.setValue(tin);
        }

        if (psrn) {
          this.legalControls.kpp.setValue(psrn);
        }

        if (address) {
          this.legalControls.address.setValue(address);
        }

        if (contact) {
          this.legalControls.contactPoint.setValue(contact);
        }

        if (phone) {
          this.legalControls.phone.setValue(phone);
        }

        if (email) {
          this.legalControls.email.setValue(email);
        }

        if (description) {
          this.legalControls.description.setValue(description);
        }
      }

      if (this.controls.type.value === TYPE_SUPPLIER.NATURAL_PERSON) {
        const { tin, address, name, phone, email, description } = this.supplier as SupplierPhysical;

        if (name) {
          this.individualControls.contactPoint.setValue(name);
        }

        if (tin) {
          this.individualControls.inn.setValue(tin);
        }

        if (address) {
          this.individualControls.address.setValue(address);
        }

        if (phone) {
          this.individualControls.phone.setValue(phone);
        }

        if (email) {
          this.individualControls.email.setValue(email);
        }

        if (description) {
          this.individualControls.description.setValue(description);
        }
      }
    }

    this.setActiveForm();

    this.isEditing$ = new BehaviorSubject(!!this.supplier);
    this.isSubmitDisabled$.next(false);

    this.formConfirmSaveService.setForm(this.form);
  }

  setActiveForm(): void {
    if (this.controls.type.value === TYPE_SUPPLIER.LEGAL_PERSON) {
      this.legalForm.enable();
      this.individualForm.disable();
    }

    if (this.controls.type.value === TYPE_SUPPLIER.NATURAL_PERSON) {
      this.individualForm.enable();
      this.legalForm.disable();
    }
  }

  disableForm(): void {
    this.form.disable();
    this.isSubmitDisabled$.next(true);
  }

  enableForm(): void {
    this.form.enable();
    this.isSubmitDisabled$.next(false);

    if (this.controls.type.value === TYPE_SUPPLIER.LEGAL_PERSON) {
      this.individualForm.disable();
    }

    if (this.controls.type.value === TYPE_SUPPLIER.NATURAL_PERSON) {
      this.legalForm.disable();
    }
  }

  openSupplierPage(supplierId?: string) {
    this.formConfirmSaveService.closeForm(false);

    this.router.navigate([
      this.sessionStorage.getOrgId(),
      RootNavigationRoute.warehouse,
      WarehouseRoute.suppliers,
      supplierId || ROUTE_CREATE_NEW,
    ]);
  }

  submitForm(): void {
    if (this.form.invalid) {
      this.validationErrorsService.markFormControls(this.form);
      this.notifyService.addNotification({
        type: 'alert',
        title: 'Необходимо заполнить обязательные поля',
      });

      return;
    }

    this.disableForm();

    this.supplier ? this.updateSupplier() : this.createSupplier();
  }

  createSupplier(): void {
    const supplier = this.createSupplierCreateInput();

    this.supplierStorage.createSupplierV3({ input: supplier }).subscribe(
      () => this.back(false),
      () => this.enableForm(),
    );
  }

  createSupplierCreateInput(): CreateSupplierInput {
    const { type } = this.controls;

    if (type.value === TYPE_SUPPLIER.LEGAL_PERSON) {
      const { companyName, inn, kpp, address, contactPoint, phone, email, description } = this.legalControls;

      return {
        legal: {
          address: address.value,
          description: description.value,
          email: email.value,
          name: companyName.value,
          contact: contactPoint.value,
          phone: phone.value ? this.utilsService.setParsedPhone(phone.value) : '',
          tin: inn.value,
          psrn: kpp.value,
        },
      };
    }

    if (type.value === TYPE_SUPPLIER.NATURAL_PERSON) {
      const { inn, address, contactPoint, phone, email, description } = this.individualControls;

      return {
        physical: {
          address: address.value,
          description: description.value,
          email: email.value,
          name: contactPoint.value,
          phone: phone.value ? this.utilsService.setParsedPhone(phone.value) : '',
          tin: inn.value,
        },
      };
    }

    throw new Error('Unable to get suppliers type');
  }

  updateSupplier(): void {
    const supplier = this.createSupplierUpdateInput();

    this.supplierStorage.updateSupplierV3({ input: supplier }).subscribe(
      () => this.back(false),
      () => this.enableForm(),
    );
  }

  createSupplierUpdateInput(): UpdateSupplierInput {
    if (!this.supplier?.id) {
      throw new Error('Supplier id is missing');
    }
    const { type } = this.controls;

    if (type.value === TYPE_SUPPLIER.LEGAL_PERSON) {
      const { companyName, inn, kpp, address, contactPoint, phone, email, description } = this.legalControls;

      return {
        legal: {
          id: this.supplier?.id,
          address: address.value,
          description: description.value,
          email: email.value,
          name: companyName.value,
          contact: contactPoint.value,
          phone: phone.value ? this.utilsService.setParsedPhone(phone.value) : '',
          tin: inn.value,
          psrn: kpp.value,
        },
      };
    }

    if (type.value === TYPE_SUPPLIER.NATURAL_PERSON) {
      const { inn, address, contactPoint, phone, email, description } = this.individualControls;

      return {
        physical: {
          id: this.supplier?.id,
          address: address.value,
          description: description.value,
          email: email.value,
          name: contactPoint.value,
          phone: phone.value ? this.utilsService.setParsedPhone(phone.value) : '',
          tin: inn.value,
        },
      };
    }

    throw new Error('Unable to get suppliers type');
  }

  deleteSupplier(): void {
    if (!this.supplier?.id) {
      throw new Error('Supplier id is missing');
    }

    this.modalRef.close();
    this.supplierStorage.deleteSupplierV2({ id: this.supplier.id }).subscribe(() => {
      this.back(false);
    });
  }

  back(shouldConfirm: boolean = true): void {
    this.sidenavService.back(shouldConfirm);
  }

  showModal(modalTemplate: TemplateRef<ModalBaseComponent>): void {
    this.modalRef = this.modalService.openDialog(modalTemplate);
  }

  hideModal(): void {
    if (!this.modalRef) {
      return;
    }

    this.modalRef.close();
  }

  getAllSuppliers(): Observable<SupplierUnion[]> {
    return this.supplierStorage
      .getAllSuppliersPageableV2({
        pageRequest: {
          page: 0,
          size: 1000,
        },
      })
      .pipe(map((res) => res.data.suppliers.content));
  }
}
