<nm-modal-base
  (ok)="data.callbackFn()"
  (cancel)="hideFn()"
  okTitle="Распровести"
  cancelTitle="Отменить"
  okVariant="filled"
>
  <div modal-base-title>Вы действительно хотите распровести документ {{ title }}?</div>
  <div>
    При отмене проведения документа, все ранее внесенные данные будут удалены из системы и документ вернется в статус "В
    ожидании".
  </div>
</nm-modal-base>
