import { Observable } from '@apollo/client/core';
import { ErrorInvitationFunc } from '@typings';

export const errorInvitation: ErrorInvitationFunc = ({ errorResponse }) => {
  return new Observable((observer) => {
    if (errorResponse.response) {
      observer.next(errorResponse.response);
    }

    observer.complete();
  });
};
