import { WarehouseItemLeftoverSortFieldsStrict } from '@typings';

export const LEFTOVERS_SORT_FIELD: WarehouseItemLeftoverSortFieldsStrict = {
  LAST_ARRIVAL_DATE: 'LAST_ARRIVAL_DATE',
  NAME: 'NAME',
  PRIME_PRICE_AMOUNT_VALUE: 'PRIME_PRICE_AMOUNT_VALUE',
  QUANTITY: 'QUANTITY',
  SECTION_NAME: 'SECTION_NAME',
  TYPE: 'TYPE',
  UNIT_TYPE: 'UNIT_TYPE',
  WAREHOUSE_NAME: 'WAREHOUSE_NAME',
  TOTAL_AMOUNT: 'TOTAL_AMOUNT',
};
