import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { RoleService } from '@app/modules/employees/services/roles/role.service';
import { Role } from '@app/typings/role';

@Component({
  selector: 'nm-delete-role-dialog',
  templateUrl: './delete-role-dialog.component.html',
  styleUrls: ['./delete-role-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteRoleDialogComponent {
  constructor(
    public roleService: RoleService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      role: Role;
      callbackFn?: () => void;
    },
  ) {}
}
