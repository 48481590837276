import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { SubscriptionsService } from '@services/settings';
import { MonetizationOrder } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class LicenceOrderResolver implements Resolve<MonetizationOrder | boolean> {
  constructor(private router: Router, private subscriptionsService: SubscriptionsService) {
    // TODO: show preloader
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MonetizationOrder> | Promise<boolean> {
    const order = this.subscriptionsService.licenceOrder;

    if (!order) {
      return this.router.navigateByUrl(state.url.replace(`/order`, ''));
    }

    return of(order);
  }
}
