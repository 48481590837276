import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AvatarModule } from '@app/shared/component/avatar/avatar.module';
import { IconButtonModule } from '@app/shared/component/icon-button/icon-button.module';

import { MenuHeaderComponent } from './menu-header.component';

@NgModule({
  declarations: [MenuHeaderComponent],
  imports: [CommonModule, IconButtonModule, AvatarModule],
  exports: [MenuHeaderComponent],
})
export class MenuHeaderModule {}
