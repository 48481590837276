import { Component, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'nm-base-component',
  template: '<div></div>',
})
export class BaseComponent implements OnDestroy {
  onDestroy = new Subject<void>();
  subs: Subscription[] = [];

  constructor() {}

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();

    if (this.subs.length) {
      this.subs.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
