import { Injectable } from '@angular/core';

import { ConfigService } from '@app/config.service';
import { Domain } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class DomainService {
  domain: Domain;

  constructor(public configService: ConfigService) {
    const config = this.configService.config;

    if (config?.domains) {
      this.#applyDomain(new Map(Object.entries(config.domains)));
    }
  }

  #applyDomain(domains: Map<string, Domain>): void {
    if (!domains.size) {
      return;
    }

    for (const [_, value] of domains) {
      if (value.isDefault) {
        this.domain = value;
      }
    }

    if (!this.domain) {
      this.domain = domains.get(Array.from(domains.keys())[0])!;
    }
  }
}
