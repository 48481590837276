import { gql } from 'apollo-angular';

export const querySearchOrganizationByRequest = gql`
  query searchOrganizationByRequest($request: String!) {
    searchOrganizationByRequest(request: $request) {
      organizationSearchDTOList {
        shortName
        inn
        kpp
        owner
        physicalAddress
        ogrn
        okpo
      }
    }
  }
`;

export const querySearchOrganizationLegalSupplier = gql`
  query searchOrganizationByRequest($request: String!) {
    searchOrganizationByRequest(request: $request) {
      organizationSearchDTOList {
        shortName
        inn
        kpp
        owner
        physicalAddress
      }
    }
  }
`;

export const querySearchAddress = gql`
  query searchAddressByRequest($request: String!) {
    searchAddressByRequest(request: $request) {
      addressSearchDTOList {
        address
        timezone
      }
    }
  }
`;

export const querySearchAddressGeoLocation = gql`
  query searchAddressByRequest($request: String!) {
    searchAddressByRequest(request: $request) {
      addressSearchDTOList {
        address
        latitude
        longitude
      }
    }
  }
`;

export const querySearchAddressByRequest = gql`
  query searchAddressByRequest($request: String!) {
    searchAddressByRequest(request: $request) {
      addressSearchDTOList {
        address
        postalCode
      }
    }
  }
`;

export const querySearchOrganizationByRequestRequisites = gql`
  query searchOrganizationByRequest($request: String!) {
    searchOrganizationByRequest(request: $request) {
      organizationSearchDTOList {
        shortName
        inn
        kpp
        owner
        physicalAddress
        ogrn
        okpo
        registrationDate
      }
    }
  }
`;

export const querySearchAddressRequisites = gql`
  query searchAddressByRequest($request: String!) {
    searchAddressByRequest(request: $request) {
      addressSearchDTOList {
        address
        postalCode
      }
    }
  }
`;

export const querySearchBankByRequestRequisites = gql`
  query searchBankByRequest($request: String!) {
    searchBankByRequest(request: $request) {
      name
      bic
      correspondentAccount
    }
  }
`;
