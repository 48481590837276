import { Injectable } from '@angular/core';

import { NotifyService } from '@services/shared';
import { MutationRemoveDeviceArgs, MutationResult, QueryDevicesArgs, QueryResult } from '@typings';

import { DevicesApi } from './devices.api';

@Injectable({
  providedIn: 'root',
})
export class DevicesStorage {
  constructor(private devicesApi: DevicesApi, private notifyService: NotifyService) {}

  getSubscriber(): QueryResult<'subscriber'> {
    return this.devicesApi.subscriber().pipe(
      this.notifyService.$notify({
        error: 'Ошибка',
      }),
    );
  }

  getDeviceList(variables: QueryDevicesArgs): QueryResult<'devices'> {
    return this.devicesApi.devices(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка',
      }),
    );
  }

  removeDevice(variables: MutationRemoveDeviceArgs): MutationResult<'removeDevice'> {
    return this.devicesApi.removeDevice(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка',
        success: 'Успешно',
      }),
    );
  }
}
