import { Injectable, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Maybe } from 'graphql/jsutils/Maybe';
import { BehaviorSubject, map, Observable, of, Subject, switchMap } from 'rxjs';

import { WarehouseArchiveDialogComponent } from '@app/modules/warehouse/components';
import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { ModalRef, ModalService } from '@app/shared/component/dialog/abstract';
import { ModalBaseComponent } from '@app/shared/component/dialog/modal-base/modal-base.component';
import { MenuItemType } from '@app/shared/component/menu-item/menu-item.model';
import { InfiniteLoaderAutocompleteFactory } from '@app/shared/service/autocomplete/infinite-loader-autocomplete.service';
import { InfiniteLoadResult } from '@app/shared/service/infinite-loader/infinite-loader.service';
import { phoneValidator } from '@app/shared/validator/phoneValidator';
import { DEFAULT_TIMEZONE, MAX_CHARACTERS, RootNavigationRoute, WarehouseRoute } from '@constants';
import { SessionStorage } from '@services/api';
import { AccountStorage, FormConfirmSaveService, UtilsService, ValidationErrorsService } from '@services/core';
import { StoresService } from '@services/settings';
import { NotifyService, RedirectService, SidenavService } from '@services/shared';
import {
  ArchiveWarehouseForm,
  CreateStorageRoomInput,
  FunctionTypeVoid,
  PageableInput,
  SortOrderInput,
  StorageRoom,
  StorageRoomFilterInput,
  StorageRoomPage,
  TimeZone,
  UpdateStorageRoomInput,
  WarehouseFormGroup,
  WarehouseItemWrittenOffReport,
} from '@typings';
import { getUpdatedControls } from '@utils';

import { WarehouseStorage } from './warehouse.storage';

@Injectable({
  providedIn: 'root',
})
export class WarehouseService {
  readonly defaultTimezoneAutocomplete: AutocompleteOption<TimeZone> = {
    id: DEFAULT_TIMEZONE,
    label: DEFAULT_TIMEZONE,
    type: 'item',
  };
  form: FormGroup<WarehouseFormGroup>;
  archiveForm: FormGroup<ArchiveWarehouseForm>;

  warehouse: StorageRoom;
  modalRef: ModalRef<ModalBaseComponent | WarehouseArchiveDialogComponent>;

  refresh$ = new BehaviorSubject(true);

  isEditing$: Observable<boolean>;
  isSubmitDisabled$ = new BehaviorSubject(false);
  storesCount$ = new BehaviorSubject(0);
  #allSelectedStores = new BehaviorSubject(true);

  updateWarehouseInTable = new Subject<StorageRoom>();

  #searchWarehousesFn = (
    pageRequest: PageableInput,
    searchText?: string,
  ): Observable<InfiniteLoadResult<AutocompleteOption<StorageRoom>>> => {
    return this.warehouseStorage
      .storageRooms({
        pageRequest,
        filter: {
          search: searchText,
          states: ['NOT_ARCHIVED'],
        },
      })
      .pipe(
        map((res) => {
          const { content, total, totalPages } = res.data.storageRooms;
          const items: AutocompleteOption<StorageRoom>[] = content
            .filter((s): s is StorageRoom & { id: string; name: string } => !!s.id && !!s.name)
            .map((item) => ({
              id: item.id,
              type: 'item',
              label: item.name,
              data: item,
            }));
          return { items, total, totalPages };
        }),
      );
  };
  searchWarehousesService = InfiniteLoaderAutocompleteFactory.getService<StorageRoom>(this.#searchWarehousesFn);

  constructor(
    private warehouseStorage: WarehouseStorage,
    private router: Router,
    private sessionStorage: SessionStorage,
    private fb: FormBuilder,
    private formConfirmSaveService: FormConfirmSaveService,
    private modalService: ModalService,
    private storesService: StoresService,
    private utilsService: UtilsService,
    private notifyService: NotifyService,
    private validationErrorsService: ValidationErrorsService,
    private sidenavService: SidenavService,
    private redirectService: RedirectService,
    private accountStorage: AccountStorage,
  ) {
    if (this.accountStorage.getOrganization()) {
      this.defaultTimezoneAutocomplete = {
        id: this.accountStorage.getOrganization()!.timezone,
        label: this.accountStorage.getOrganization()!.timezone,
        type: 'item',
      };
    }
  }

  initState(warehouse: StorageRoom): void {
    this.warehouse = warehouse;
  }

  initForm(): void {
    this.form = this.fb.group<WarehouseFormGroup>({
      name: this.fb.nonNullable.control('', [Validators.required, Validators.maxLength(MAX_CHARACTERS.NAME)]),
      addressName: this.fb.control('', [Validators.maxLength(MAX_CHARACTERS.ADDRESS)]),
      zone: this.fb.nonNullable.control(this.defaultTimezoneAutocomplete, [Validators.required]),
      phone: this.fb.control('', [Validators.maxLength(MAX_CHARACTERS.PHONE), phoneValidator()]),
      description: this.fb.control('', [Validators.maxLength(MAX_CHARACTERS.DESCRIPTION)]),
      stores: this.fb.nonNullable.control([]),
    });

    const { controls } = this.form;

    this.storesService
      .getStoresTotalNumber()
      .pipe(
        switchMap((res) => {
          this.storesCount$.next(res);

          if (res === 1) {
            return this.storesService.getStores({
              pageRequest: { size: 1, page: 0 },
            });
          }

          return of(null);
        }),
      )
      .subscribe((res) => {
        if (res) {
          const store = res.data.stores.content[0];

          controls.stores.setValue([
            {
              id: store.id,
              label: store.name,
              type: 'item',
              data: store,
            },
          ]);
        }
      });

    if (this.warehouse) {
      const { name, phone, description, zoneId, address, stores } = this.warehouse;
      const autocompleteStores = (stores?.content || []).map((store) => {
        return {
          id: store.id,
          label: store.name,
          type: 'item' as MenuItemType,
          data: store,
        };
      });
      controls.name.setValue(name || '');
      controls.phone.setValue(phone || null);
      controls.description.setValue(description || null);
      controls.addressName.setValue(address || '');
      controls.stores.setValue(autocompleteStores);

      if (zoneId) {
        controls.zone.setValue({
          id: zoneId,
          label: zoneId,
          type: 'item' as MenuItemType,
        });
      }
    }

    this.isEditing$ = new BehaviorSubject(!!this.warehouse);
    this.isSubmitDisabled$.next(false);

    this.formConfirmSaveService.setForm(this.form);
  }

  initArchiveForm() {
    this.archiveForm = this.fb.group<ArchiveWarehouseForm>({
      newWarehouse: this.fb.nonNullable.control(null, [Validators.required]),
    });
  }

  submitForm(updatedControls: Set<string>): void {
    if (this.form.invalid) {
      this.validationErrorsService.markFormControls(this.form);
      this.notifyService.addNotification({
        type: 'alert',
        title: 'Необходимо заполнить обязательные поля',
      });

      return;
    }

    this.disableForm();

    this.warehouse ? this.updateWarehouse(updatedControls) : this.createWarehouse();
  }

  showModal(modalTemplate: TemplateRef<ModalBaseComponent>): void {
    this.modalRef = this.modalService.openDialog(modalTemplate);
  }

  hideModal(): void {
    if (!this.modalRef) {
      return;
    }

    this.modalRef.close();
  }

  openWarehousePage(id?: string) {
    this.formConfirmSaveService.closeForm(false);

    this.redirectService.openWarehousePage(this.sessionStorage.getOrgId(), id);
  }

  back(shouldConfirm: boolean = true): void {
    this.sidenavService.back(shouldConfirm);
  }

  openArchiveDialog(warehouse: StorageRoom, fromListCallback?: FunctionTypeVoid): void {
    this.modalRef = this.modalService.openDialog(WarehouseArchiveDialogComponent, { data: { warehouse, fromListCallback } });
  }

  showArchiveError(): void {
    this.notifyService.addNotification({
      type: 'alert',
      title: 'Нельзя удалить последний склад',
    });
  }

  hasStores(warehouse: StorageRoom): boolean {
    return !!warehouse?.stores?.content.length;
  }

  archiveWarehouse(warehouse: StorageRoom, fromListCallback?: FunctionTypeVoid): void {
    if (this.hasStores(warehouse)) {
      if (this.archiveForm.invalid || !this.archiveForm.controls.newWarehouse.value) {
        return;
      }

      const storeIds = warehouse.stores ? warehouse.stores.content.map((s) => s.id) : [];

      this.updateStores(this.archiveForm.controls.newWarehouse.value?.id || '', storeIds).subscribe(() => {
        this.archive(warehouse, fromListCallback);
      });
    } else {
      this.archive(warehouse, fromListCallback);
    }
  }

  private updateStores(warehouseId: string, storesIds: string[]): Observable<StorageRoom | undefined> {
    return this.warehouseStorage
      .updateStorageRoom({
        id: warehouseId,
        updateInput: {
          addStoreIds: storesIds,
        },
      })
      .pipe(map((res) => res.data?.updateStorageRoom.result?.output || undefined));
  }

  private archive(warehouse: StorageRoom, fromListCallback?: FunctionTypeVoid): void {
    if (!warehouse.id) {
      throw Error('Warehouse id is undefined');
    }
    this.warehouseStorage
      .archiveStorageRoom({
        archiveInput: {
          id: warehouse.id,
        },
      })
      .pipe(map((res) => res.data?.archiveStorageRoom))
      .subscribe(() => {
        this.hideModal();
        if (fromListCallback) {
          fromListCallback();
        } else {
          this.openArchiveList();
        }
      });
  }

  unarchiveWarehouse(id: string): Observable<string | undefined> {
    if (!id) {
      throw Error('Warehouse id is undefined');
    }

    return this.warehouseStorage
      .unarchiveStorageRoom({ unarchiveInput: { id } })
      .pipe(map((res) => (res.data?.unarchiveStorageRoom?.status.result === 'SUCCESS' ? id : undefined)));
  }

  openArchiveList(): void {
    this.router.navigate([
      this.sessionStorage.getOrgId(),
      RootNavigationRoute.warehouse,
      WarehouseRoute.warehouses,
      WarehouseRoute.warehousesArchive,
    ]);
  }

  getWarehouse(id: string): Observable<StorageRoom> {
    return this.warehouseStorage.storageRoom({ id }).pipe(map((res) => res.data.storageRoom!));
  }

  private getWarehouseCreateInput(): CreateStorageRoomInput {
    const { controls } = this.form;
    return {
      address: controls?.addressName?.value || '',
      description: controls.description.value,
      name: controls.name.value,
      phone: controls.phone.value,
      zoneId: controls?.zone?.value?.id || '',
    };
  }

  private getWarehouseUpdateInput(updatedValues: Set<string>): UpdateStorageRoomInput | undefined {
    if (!this.warehouse.id) {
      return undefined;
    }
    const { controls } = this.form;

    const updatedControls: Partial<WarehouseFormGroup> = getUpdatedControls(controls, updatedValues);
    const input: UpdateStorageRoomInput = {};

    if (updatedControls.addressName) {
      input.address = controls.addressName.value || '';
    }

    if (updatedControls.description) {
      input.description = controls.description.value;
    }

    if (updatedControls.name) {
      input.name = controls.name.value;
    }

    if (updatedControls.phone) {
      input.phone = controls.phone.value;
    }

    if (updatedControls.zone) {
      input.zoneId = controls.zone?.value?.id || '';
    }

    return input;
  }

  createWarehouse(): void {
    const createInput: CreateStorageRoomInput = this.getWarehouseCreateInput();
    this.warehouseStorage.createStorageRoom({ createInput }).subscribe(
      () => this.back(false),
      () => this.enableForm(),
    );
  }

  updateWarehouse(updatedControls: Set<string>): void {
    const updateInput = this.getWarehouseUpdateInput(updatedControls);

    if (updateInput) {
      this.warehouseStorage.updateStorageRoom({ id: this.warehouse.id, updateInput }).subscribe(
        () => this.back(false),
        () => this.enableForm(),
      );
    }
  }

  disableForm(): void {
    this.form.disable();
    this.isSubmitDisabled$.next(true);
  }

  enableForm(): void {
    this.form.enable();
    this.isSubmitDisabled$.next(false);
  }

  getWarehousesPageableAll(): Observable<StorageRoom[]> {
    return this.warehouseStorage
      .storageRooms({
        pageRequest: { page: 0, size: 1000 },
      })
      .pipe(map((res) => res?.data?.storageRooms?.content));
  }

  getWarehousesPageableShort(): Observable<StorageRoom[]> {
    return this.warehouseStorage
      .storageRooms({
        pageRequest: { page: 0, size: 1000 },
        filter: { states: ['NOT_ARCHIVED'] },
      })
      .pipe(map((res) => res?.data?.storageRooms?.content));
  }

  getWarehousesTotalNumber(): Observable<number> {
    return this.warehouseStorage.storageRooms({ pageRequest: { page: 0, size: 1 } }).pipe(map((res) => res.data.storageRooms.total));
  }

  getWarehousesPageable(pageRequest: PageableInput, filter: StorageRoomFilterInput, sort?: SortOrderInput[]): Observable<StorageRoomPage> {
    return this.warehouseStorage
      .storageRooms({ pageRequest: { page: pageRequest.page, size: pageRequest.size, sort }, filter })
      .pipe(map((res) => res.data.storageRooms!));
  }

  getWarehouseItemsWrittenOffReportByWarehouse(
    page: number,
    size: number,
    warehouseId: string,
    dateFrom?: string,
    dateTo?: string,
    sortOrder?: string,
    search?: string,
  ): Observable<Maybe<WarehouseItemWrittenOffReport>[]> {
    return this.warehouseStorage.getWarehouseItemsWrittenOffReportByWarehouse({
      page,
      size,
      warehouseId,
      dateFrom: dateFrom?.slice(0, 10),
      dateTo: dateTo?.slice(0, 10),
      sortOrder,
      search,
    });
  }

  setAllSelectedStores(selected: boolean): void {
    this.#allSelectedStores.next(selected);
  }

  getAllSelectedStores(): boolean {
    return this.#allSelectedStores.getValue();
  }
}
