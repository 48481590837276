import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ProgressSpinnerModule } from '@app/shared/component/progress-spinner/progress-spinner.module';
import { SvgIconModule } from '@app/shared/component/svg-icon/svg-icon.module';

import { InputImageComponent } from './input-image.component';

@NgModule({
  declarations: [InputImageComponent],
  imports: [CommonModule, SvgIconModule, ProgressSpinnerModule],
  exports: [InputImageComponent],
})
export class InputImageModule {}
