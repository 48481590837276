import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Body } from 'apollo-angular/http/types';
import { from, lastValueFrom, Observable } from 'rxjs';

import { environment } from '@env/environment';
import { SessionStorage } from '@services/api';

@Injectable()
export class ExportInterceptor implements HttpInterceptor {
  constructor(private sessionStorage: SessionStorage) {}

  intercept(request: HttpRequest<Body>, next: HttpHandler): Observable<HttpEvent<Body>> {
    if (request.url.includes(environment.baseExportReportUrl)) {
      return from(this.#request(request, next)!);
    }

    return next.handle(request);
  }

  async #request(request: HttpRequest<Body>, next: HttpHandler) {
    const isValid = this.sessionStorage.isAccessTokenValid();

    if (!isValid) {
      await this.sessionStorage.updateTokens();
    }

    const authorization = this.sessionStorage.getAuthHeader();

    const updatedRequest = request.clone({
      setHeaders: {
        authorization,
      },
      responseType: 'blob',
    });

    return lastValueFrom(next.handle(updatedRequest));
  }
}
