<div class="check-layout">
  <header class="header">
    <nm-icon-button variant="text" icon="close" (clickButton)="onClickButton()"></nm-icon-button>

    <span *ngIf="title" class="title">{{ title }}</span>
  </header>

  <router-outlet></router-outlet>

  <ng-content></ng-content>
</div>
