import { SortDirection } from '@typings';

import { TemplateColData } from './table.component';
import { ColumnConfig, Row, SortEnum, TableConfig } from './table.types';

export const ID_SPLITTER = '_';
export const PARENT_SCROLL_WRAPPER_ATTR = 'scrollWrapperAttr';

export function createRowId(parentId: string, rowId: number | string): string {
  return parentId + ID_SPLITTER + rowId;
}

export function setTemplatesForRowColumns(rows: Row[], columnsDataTemplates: TemplateColData[]): Row[] {
  return rows.map((row) => {
    if (row?.children?.length) {
      row.children = setTemplatesForRowColumns(row.children, columnsDataTemplates);
    }
    row.columnsData = row.columnsData.map((col) => {
      const curColumn = columnsDataTemplates.find((c) => c.columnName === col.column);
      if (curColumn?.template) {
        col.template = curColumn.template;
      }

      return col;
    });

    return row;
  });
}

export function sortColumn(column: ColumnConfig): ColumnConfig {
  if (column.sorting === SortEnum.ASC) {
    column.sorting = SortEnum.DESC;
  } else if (column.sorting === SortEnum.DESC) {
    column.sorting = null;
  } else {
    column.sorting = SortEnum.ASC;
  }

  return column;
}

function removeSorting(colCfg: ColumnConfig): void {
  if ('sorting' in colCfg) {
    colCfg.sorting = null;
  }
}

export function addSortToConfig(tableConfig: TableConfig, sort?: { field?: string | null; direction?: SortDirection | null }[]) {
  if (!tableConfig?.columnsConfig?.length) {
    return;
  }

  const filteredSort = (sort || []).filter((s) => !!s.field);

  if (!filteredSort.length) {
    tableConfig.columnsConfig.forEach(removeSorting);
    return;
  }

  tableConfig?.columnsConfig.forEach((columnCfg) => {
    if (!columnCfg.sortable) {
      removeSorting(columnCfg);
      return;
    }

    const sorting = filteredSort.find((s) => s.field === columnCfg.name);

    if (sorting?.direction === 'ASC') {
      columnCfg.sorting = SortEnum.ASC;
    } else if (sorting?.direction === 'DESC') {
      columnCfg.sorting = SortEnum.DESC;
    } else {
      removeSorting(columnCfg);
      columnCfg.sorting = null;
    }
  });
}
