import { createMask } from '@ngneat/input-mask';

type Options = {
  placeholder?: string;
  groupSeparator?: string;
  digitsOptional?: boolean;
  digits?: number;
  radixPoint?: string;
  allowMinus?: boolean;
  prefix?: string;
  suffix?: string;
  min?: number;
  max?: number;
  SetMaxOnOverflow?: boolean;
};

export const setNumberMask = ({
  placeholder = '',
  groupSeparator = ' ',
  digitsOptional = true,
  digits = 0,
  radixPoint = '.',
  allowMinus = true,
  prefix = '',
  suffix = '',
  min,
  max,
  SetMaxOnOverflow = false,
}: Options = {}) => {
  return createMask({
    alias: 'numeric',
    autoUnmask: true,
    unmaskAsNumber: true,
    groupSeparator,
    digitsOptional,
    digits,
    radixPoint,
    allowMinus,
    prefix,
    suffix,
    min,
    max,
    SetMaxOnOverflow,
    placeholder,
  });
};
