import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { User } from '@app/typings/user';
import { EmployeesService } from '@services/employee';

@Component({
  selector: 'nm-delete-employee-dialog',
  templateUrl: './delete-employee-dialog.component.html',
})
export class DeleteEmployeeDialogComponent {
  constructor(
    public employeesService: EmployeesService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      employee: User;
      callbackFn?: () => void;
    },
  ) {}
}
