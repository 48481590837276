import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';

import { ModalService } from '@nomia/nomia-ui-lib';

import { BaseComponent } from '@app/shared/component/base-component/base.component';
import { InvitationModalComponent } from '@components/auth';
import { SessionStorage } from '@services/api';
import { InvitationStorage } from '@services/auth';

@Component({
  selector: 'nm-invitation',
  template: '',
})
export class InvitationComponent extends BaseComponent implements OnInit {
  hasInvitation: boolean = false;
  isRedirect: boolean = true;
  isError: string | null = null;
  isModal: boolean = false;

  constructor(private invitationStorage: InvitationStorage, public sessionStorage: SessionStorage, private modalService: ModalService) {
    super();

    this.invitationStorage.invitation.pipe(filter((result) => !!result)).subscribe((invitation) => {
      if (invitation?.id) {
        this.hasInvitation = true;
      }
    });
  }

  ngOnInit(): void {
    const id = this.invitationStorage.getInvitationId();

    if (id) {
      this.invitationStorage.getInvitationInfo(id);
    }
  }

  showModal(id: string) {
    this.isModal = true;

    this.modalService.showModal(InvitationModalComponent, {
      data: {
        id,
      },
      allowOverlayClick: false,
      whenClosed: () => {
        this.isModal = false;
      },
    });
  }
}
