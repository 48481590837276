import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { BannerType } from '@typings';

@Component({
  selector: 'nm-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertBannerComponent {
  @Input() type: BannerType = 'information';
  @Input() title: string = '';
  @Input() link: string = '';
  @Input() redirectPage: boolean = false;
  @Input() linkText: string = '';
  @Input() height: string;

  @Output() clickRedirect = new EventEmitter<void>();

  onClickRedirect(): void {
    this.clickRedirect.emit();
  }
}
