import { gql } from 'apollo-angular';

import { fragmentPage, fragmentRequestResult } from './mutations';

export const mutationCreatePaymentSystem = gql`
  mutation createPaymentSystem($paymentSystemCreateInput: PaymentSystemCreateInput!) {
    createPaymentSystem(paymentSystemCreateInput: $paymentSystemCreateInput) {
      ...RequestResult
      output {
        id
      }
    }
  }
  ${fragmentRequestResult}
`;

export const mutationUpdatePaymentSystem = gql`
  mutation updatePaymentSystem($input: PaymentSystemUpdateInput!) {
    updatePaymentSystem(input: $input) {
      ...RequestResult
      output {
        id
      }
    }
  }
  ${fragmentRequestResult}
`;

export const mutationDeletePaymentSystem = gql`
  mutation deletePaymentSystem($id: UUID!) {
    deletePaymentSystem(id: $id) {
      ...RequestResult
      output {
        id
      }
    }
  }
  ${fragmentRequestResult}
`;

export const queryGetPaySystemReport = gql`
  query getPaySystemReport($dateFrom: LocalDate!, $dateTo: LocalDate!, $storeIds: [UUID!]) {
    getPaySystemReport(dateFrom: $dateFrom, dateTo: $dateTo, storeIds: $storeIds) {
      amount
      paySystemName
    }
  }
`;

export const queryPaymentSystems = gql`
  query paymentSystems($filter: PaymentSystemsFilterInput, $pageRequest: PageRequestInput!) {
    paymentSystems(filter: $filter, pageRequest: $pageRequest) {
      ...Page
      content {
        id
        name
        active
        hasStores
        icon
        type
        stores {
          id
          name
        }
      }
    }
  }
  ${fragmentPage}
`;

export const queryPaymentSystem = gql`
  query paymentSystem($paymentSystemId: UUID!) {
    paymentSystem(paymentSystemId: $paymentSystemId) {
      id
      name
      type
      icon
      stores {
        id
      }
    }
  }
`;
