import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class SessionApiService {
  constructor(private http: HttpClient) {}

  async fetchRefreshToken<T>(refreshToken: string): Promise<T> {
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: 'Basic ' + btoa('erp:kjsfh8au3pjNSdp9ubfjbPF8wUb0iajsd09GBHuyt56dtfC45G'),
    });

    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', refreshToken)
      .set('redirect_uri', `${window.location.origin}/auth/login`);

    return lastValueFrom(this.http.post<T>(`${environment.authUrl}/oauth2/token`, body, { headers: headers }));
  }
}
