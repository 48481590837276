import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from '@angular/core';

import { InputComponent } from '@app/shared/component/input/input.component';

@Component({
  selector: 'nm-file-url-dialog',
  templateUrl: './file-url-dialog.component.html',
  styleUrls: ['./file-url-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUrlDialogComponent implements AfterViewInit {
  url: string = '';

  @Output() urlSelect = new EventEmitter<string>();
  @Output() urlCancel = new EventEmitter<void>();

  @ViewChild('input') input: InputComponent;

  ngAfterViewInit(): void {
    this.input.registerOnChange((val: string) => {
      this.url = val;
    });
  }

  handleOk() {
    if (!this.url) {
      this.urlCancel.emit();
      return;
    }

    this.urlSelect.emit(this.url);
  }

  handleCancel() {
    this.urlCancel.emit();
  }
}
