import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { dateOffsetFix } from '@utils';

@Pipe({
  name: 'noOffsetDate',
})
export class NoOffsetDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(date: string, format: string): string | null {
    return this.datePipe.transform(dateOffsetFix(date).toDate(), format);
  }
}
