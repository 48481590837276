import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ArrivalsService } from '@app/modules/warehouse/services';
import { MAX_CHARACTERS, TYPE_SUPPLIER, TYPE_SUPPLIER_STRING } from '@constants';
import { TypeSupplier } from '@typings';

@Component({
  selector: 'nm-create-supplier-dialog',
  templateUrl: './create-supplier-dialog.component.html',
  styleUrls: ['./create-supplier-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateSupplierDialogComponent {
  maxCharacters = MAX_CHARACTERS;
  supplierTypeOptions: { label: string; value: string }[];
  type: FormControl<TypeSupplier>;
  name: FormControl<string>;

  constructor(public arrivalsService: ArrivalsService, @Inject(MAT_DIALOG_DATA) public data: { name: string }) {
    this.supplierTypeOptions = Object.entries(TYPE_SUPPLIER_STRING).map(([value, label]) => ({ value, label }));
    this.type = new FormControl<TypeSupplier>(TYPE_SUPPLIER.LEGAL_PERSON, { nonNullable: true });
    this.name = new FormControl<string>(data.name, { nonNullable: true, validators: [Validators.required] });
  }

  okClick(): void {
    this.arrivalsService.createSupplier(this.name.value, this.type.value);
  }
}
