import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { Config } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  #config: Promise<Config>;
  #value: Config;

  constructor(private http: HttpClient) {
    this.config;
  }

  async loadConfig(): Promise<Config> {
    this.#config = firstValueFrom(this.http.get<Config>('./assets/config.json', { headers: {} }));

    return this.#config.then<Config>((config) => {
      this.#value = config;

      return config;
    });
  }

  get config(): Config {
    return this.#value;
  }

  get config$(): Promise<Config> {
    return this.#config || this.loadConfig();
  }
}
