import { Injectable } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  public getTime(date: Date): string {
    return ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
  }

  public getDate(date: Date): string {
    return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
  }

  public getDateTime(date: string | null, time: string | null): string | null {
    if (!date || !time) return null;
    const dateTime = new Date(date);
    const [hours, minutes] = time.split(':');
    if (!hours || !minutes) return null;

    dateTime.setHours(parseInt(hours));
    dateTime.setMinutes(parseInt(minutes));
    dateTime.setSeconds(0);

    return dateTime.toISOString();
  }

  public getLastNDaysRange(n: number): DateRange<Date> {
    const now = new Date();
    const lastNDays = new Date();

    lastNDays.setDate(now.getDate() - (n - 1));

    return new DateRange<Date>(lastNDays, now);
  }
}
