import { BehaviorSubject, Observable, take } from 'rxjs';

import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';

export class SearchAutocompleteService<T = unknown> {
  readonly #initialOptions: AutocompleteOption<T>[] = [];
  #searchFn: (search: string) => Observable<AutocompleteOption<T>[]>;
  #options: BehaviorSubject<AutocompleteOption<T>[]> = new BehaviorSubject(this.#initialOptions);
  options$ = this.#options.asObservable();

  searchFn: (searchText: string) => void;

  constructor() {}

  init(searchFn: (search: string) => Observable<AutocompleteOption<T>[]>) {
    this.#searchFn = searchFn;

    this.searchFn = (searchText: string): void => {
      this.#searchFn(searchText)
        .pipe(take(1))
        .subscribe((options: AutocompleteOption<T>[]) => {
          this.#options.next(options);
        });
    };
  }

  getOptionsValue(): AutocompleteOption<T>[] {
    return this.#options.getValue();
  }
}

export class SearchAutocompleteServiceFactory {
  static getService<T = unknown>(searchFn: (search: string) => Observable<AutocompleteOption<T>[]>): SearchAutocompleteService<T> {
    const service = new SearchAutocompleteService<T>();
    service.init(searchFn);
    return service;
  }
}
