import { Injectable } from '@angular/core';
import { Apollo, ApolloBase } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { MutationOptions, OperationVariables, QueryOptions, SubscriptionOptions, WatchQueryOptions } from '@apollo/client/core';
import { MutationBaseOptions } from '@apollo/client/core/watchQueryOptions';
import { SubscriptionClientService } from '@services/api';
import {
  Mutation,
  MutationBatchObservableResult,
  MutationKeys,
  MutationObservableResult,
  OperationBatchVariables,
  Query,
  QueryBatchObservableResult,
  QueryKeys,
  QueryObservableResult,
  Subscription,
  SubscriptionKeys,
  SubscriptionObservableResult,
  WatchObservableResult,
} from '@typings';
import { getBatchOperation } from '@utils';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly apolloInstance: ApolloBase;
  private readonly apolloTestInstance: ApolloBase;

  #getDefaultQueryOptions<T, K>(): Omit<QueryOptions<K, T>, 'query'> {
    return {
      fetchPolicy: 'no-cache',
    };
  }

  #getDefaultWatchQueryOptions<T, K extends OperationVariables>(): Omit<WatchQueryOptions<K, T>, 'query'> {
    return {
      fetchPolicy: 'no-cache',
    };
  }

  #getDefaultMutationOptions<T, K>(): Omit<MutationOptions<T, K>, 'mutation'> {
    return {
      fetchPolicy: 'no-cache',
    };
  }

  constructor(private apollo: Apollo, private subscriptionClient: SubscriptionClientService) {
    this.apolloInstance = this.apollo.use('default');
    this.apolloTestInstance = this.apollo.use('test');
  }

  query<T extends Query<Extract<T, QueryKeys>>, K extends OperationVariables>(
    options: QueryOptions<K, T>,
    use: string = 'default',
  ): QueryObservableResult<T> {
    const apolloInstance = use === 'default' ? this.apolloInstance : this.apolloTestInstance || this.apolloInstance;
    return apolloInstance.query<T, K>({ ...this.#getDefaultQueryOptions<T, K>(), ...options });
  }

  watch<T extends Query<Extract<T, QueryKeys>>, K extends OperationVariables>(
    options: WatchQueryOptions<K, T>,
    use: string = 'default',
  ): WatchObservableResult<T, K> {
    const apolloInstance = use === 'default' ? this.apolloInstance : this.apolloTestInstance || this.apolloInstance;
    return apolloInstance.watchQuery<T, K>({ ...this.#getDefaultWatchQueryOptions<T, K>(), ...options });
  }

  mutate<T extends Mutation<Extract<T, MutationKeys>>, K extends OperationVariables>(
    options: MutationOptions<T, K>,
    use: string = 'default',
  ): MutationObservableResult<T> {
    const apolloInstance = use === 'default' ? this.apolloInstance : this.apolloTestInstance;
    return apolloInstance.mutate<T, K>({ ...this.#getDefaultMutationOptions<T, K>(), ...options });
  }

  mutateBatch<T extends Mutation<Extract<T, MutationKeys>>, K extends OperationBatchVariables<K[0]>>(
    options: Omit<MutationOptions<T, K>, 'variables'> & NonNullable<MutationBaseOptions['variables']>,
  ): MutationBatchObservableResult<T> {
    const { variables, mutation } = options;

    // no need to do smth if we have only one variable set
    if (variables && variables.length === 1) {
      return this.mutate<T, K>({ variables: variables[0], mutation });
    }

    const batchMutation = getBatchOperation<T, K>(mutation, variables);

    return this.apollo.mutate<T, K>({ ...this.#getDefaultMutationOptions<T, K>(), ...batchMutation });
  }

  queryBatch<T extends Query<Extract<T, QueryKeys>>, K extends OperationBatchVariables<K[0]>>(
    options: NonNullable<QueryOptions['variables']>,
  ): QueryBatchObservableResult<T[]> {
    const { variables, query } = options;

    if (variables && variables.length === 1) {
      return this.query<T, K>({ variables: variables[0], query }).pipe(map((result) => ({ ...result, data: [result.data] })));
    }

    const batchQuery = getBatchOperation<T, K>(query, variables);

    return this.apollo
      .query<T, K>({ ...this.#getDefaultQueryOptions<T, K>(), ...batchQuery })
      .pipe(map((result) => ({ ...result, data: Object.values(result.data) })));
  }

  subscribe<T extends Subscription<Extract<T, SubscriptionKeys>>, K extends OperationVariables>(
    options: SubscriptionOptions<K, T>,
  ): SubscriptionObservableResult<T> {
    return this.apollo.subscribe<T, K>({ ...this.#getDefaultQueryOptions<T, K>(), ...options });
  }

  closeSocket(): void {
    this.subscriptionClient.close(true);
  }
}
