import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { InventoryDocSortFieldStrict, InventoryType, InventoryTypeStrict, InventoryTypeString } from '@typings';

export const INVENTORY_TYPE: InventoryTypeStrict = {
  FULL: 'FULL',
  PARTIAL: 'PARTIAL',
};

export const INVENTORY_TYPE_STRING: InventoryTypeString = {
  FULL: 'Полная',
  PARTIAL: 'Частичная',
};

export const INVENTORY_TYPE_LIST: AutocompleteOption<InventoryType>[] = Object.keys(INVENTORY_TYPE).map((type) => ({
  id: type,
  label: INVENTORY_TYPE_STRING[type],
  type: 'item',
  data: type,
}));

export const INVENTORY_SORT_FIELD: InventoryDocSortFieldStrict = {
  CREATION_DATE: 'CREATION_DATE',
  DATE: 'DATE',
  DOC_NUMBER: 'DOC_NUMBER',
  STATUS: 'STATUS',
  TOTAL_AMOUNT_DELTA: 'TOTAL_AMOUNT_DELTA',
};
