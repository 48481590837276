import { Injectable } from '@angular/core';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { CONNECTION_INACTIVITY_TIMEOUT, CONNECTION_MIN_TIMEOUT, CONNECTION_TIMEOUT, RECONNECTION_ATTEMPTS } from '@constants';
import { environment } from '@env/environment';
import { SessionStorage } from '@services/api';
import { ConnectionParams } from '@typings';

import { SubsClient } from './subsClient';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionClientService {
  #client: SubsClient;

  constructor(private sessionStorage: SessionStorage) {
    this.#client = new SubsClient(environment.baseUrlWs, {
      reconnect: true,
      lazy: true,
      connectionParams: () => this.getConnectionParams(),
      timeout: CONNECTION_TIMEOUT,
      minTimeout: CONNECTION_MIN_TIMEOUT,
      inactivityTimeout: CONNECTION_INACTIVITY_TIMEOUT,
      reconnectionAttempts: RECONNECTION_ATTEMPTS,
    });
  }

  getConnectionParams(): Promise<ConnectionParams> {
    const getParams = (): ConnectionParams => {
      const Authorization = this.sessionStorage.getAuthHeader();

      return {
        Authorization,
      };
    };

    return new Promise<ConnectionParams>((resolve) => {
      if (this.sessionStorage.isAccessTokenValid()) {
        return resolve(getParams());
      } else {
        this.sessionStorage.updateTokens().then(() => {
          return resolve(getParams());
        });
      }
    });
  }

  getClient(): SubscriptionClient {
    return this.#client as unknown as SubscriptionClient;
  }

  close(noReconnect: boolean = true): void {
    this.#client.close(noReconnect, true);
  }
}
