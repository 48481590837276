import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FormConfirmOptions } from '../abstract';

@Component({
  selector: 'nm-form-confirm-save',
  templateUrl: './form-confirm-save.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormConfirmSaveComponent {
  title: string;
  content: string;
  okTitle: string;
  cancelTitle: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Partial<FormConfirmOptions>,
    private dialogRef: MatDialogRef<FormConfirmSaveComponent>,
  ) {
    this.title = data?.modalTitle || 'Закрыть без сохранения';
    this.content = data?.modalText || 'Вы уверены, что хотите покинуть страницу без сохранения? Все внесенные изменения будут утеряны';
    this.okTitle = data?.modalOkText || 'Закрыть';
    this.cancelTitle = data?.modalCancelText || 'Отменить';
  }

  closeDialog(data: boolean) {
    this.dialogRef.close(data);
  }

  onOk() {
    this.closeDialog(true);
  }

  onCancel() {
    this.closeDialog(false);
  }
}
