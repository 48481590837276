import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs/esm';
import duration from 'dayjs/esm/plugin/duration';
import relativeTime from 'dayjs/esm/plugin/relativeTime';

import 'dayjs/esm/locale/ru';

dayjs.extend(duration);
dayjs.extend(relativeTime);

@Pipe({
  name: 'ISO8601DurationToString',
})
export class ISO8601DurationToStringPipe implements PipeTransform {
  transform(ISO8601Duration: string): string {
    return dayjs.duration(ISO8601Duration).locale('ru').humanize();
  }
}
