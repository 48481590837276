import { ComponentType } from '@angular/cdk/portal';
import { Injector, TemplateRef } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

export abstract class ModalService {
  abstract openDialog<T, R = unknown>(
    template: ComponentType<T> | TemplateRef<T>,
    settings?: DialogConfig,
    injector?: Injector,
  ): ModalRef<T, R>;
}

export interface DialogConfig extends MatDialogConfig {
  closeOnBackdropClick?: boolean;
}

export interface ModalRef<T, R = unknown> {
  componentRef: MatDialogRef<T, R>;
  afterOpened(): Observable<void>;
  afterClosed(): Observable<R | undefined>;
  beforeClosed(): Observable<R | undefined>;
  backdropClick(): Observable<MouseEvent>;
  keydownEvents(): Observable<KeyboardEvent>;
  close(result?: R): void;
}
