import { ActionsComponent } from '@app/shared/component/actions/actions.component';
import { ROUTE_CREATE_NEW } from '@constants/navigation';
import { NavDrawerItem } from '@typings';

export enum EmployeeRoute {
  employees = 'employees',
  invitation = 'invitation',
  roles = 'roles',
}

export enum EmployeeRouteName {
  employees = 'Список сотрудников',
  invitation = 'Приглашение',
  roles = 'Доступы и роли',
}

export enum EmployeeRouteParam {
  employeeId = 'idEmployee',
  roleId = 'roleId',
  invitationId = 'idInvitation',
}

export const EMPLOYEE_ITEM_ROLES = {
  title: EmployeeRouteName.roles,
  route: EmployeeRoute.roles,
  routes: [
    {
      title: 'Новая роль',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Роль',
      route: EmployeeRouteParam.roleId,
    },
  ],
};

export const EMPLOYEE_ITEM_EMPLOYEES = {
  title: EmployeeRouteName.employees,
  route: EmployeeRoute.employees,
  actionsComponent: ActionsComponent,
  routes: [
    {
      title: 'Новый сотрудник',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Сотрудник',
      route: EmployeeRouteParam.employeeId,
    },
  ],
};

export const EMPLOYEE_CHILD_ITEMS: NavDrawerItem[] = [EMPLOYEE_ITEM_EMPLOYEES, EMPLOYEE_ITEM_ROLES];
