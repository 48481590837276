import { Injectable } from '@angular/core';

import {
  mutationCreateOrganizationSite,
  mutationUpdateOrganizationSite,
  queryCurrencyList,
  queryOrganizationSite,
  queryOrganizationSitesByAccount,
} from '@api';
import { ApiService } from '@services/core';
import {
  Mutation,
  MutationCreateOrganizationSiteArgs,
  MutationResult,
  MutationUpdateOrganizationSiteArgs,
  Query,
  QueryOrganizationSiteArgs,
  QueryOrganizationSitesByAccountArgs,
  QueryResult,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class OrganizationApi {
  constructor(private apiService: ApiService) {}

  getCurrencyList() {
    return this.apiService.query({
      query: queryCurrencyList,
    });
  }

  getOrganizationSitesByAccount(variables: QueryOrganizationSitesByAccountArgs): QueryResult<'organizationSitesByAccount'> {
    return this.apiService.query<Query<'organizationSitesByAccount'>, QueryOrganizationSitesByAccountArgs>({
      query: queryOrganizationSitesByAccount,
      variables,
    });
  }

  organizationSite(variables: QueryOrganizationSiteArgs): QueryResult<'organizationSite'> {
    return this.apiService.query<Query<'organizationSite'>, QueryOrganizationSiteArgs>({
      query: queryOrganizationSite,
      variables,
    });
  }

  createOrganizationSite(variables: MutationCreateOrganizationSiteArgs): MutationResult<'createOrganizationSite'> {
    return this.apiService.mutate<Mutation<'createOrganizationSite'>, MutationCreateOrganizationSiteArgs>({
      mutation: mutationCreateOrganizationSite,
      variables,
    });
  }

  updateOrganizationSite(variables: MutationUpdateOrganizationSiteArgs): MutationResult<'updateOrganizationSite'> {
    return this.apiService.mutate<Mutation<'updateOrganizationSite'>, MutationUpdateOrganizationSiteArgs>({
      mutation: mutationUpdateOrganizationSite,
      variables,
    });
  }
}
