import { Injectable } from '@angular/core';

import { ReceiptsApi } from '@app/modules/settings/services/receipts/receipts.api';
import { NotifyService } from '@services/shared';
import { MutationResult, MutationSaveStoreReceiptSettingsArgs, QueryResult, QueryStoreReceiptSettingsArgs } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class ReceiptsStorage {
  constructor(private notifyService: NotifyService, private receiptsApi: ReceiptsApi) {}

  getStoreReceipts(variables: QueryStoreReceiptSettingsArgs): QueryResult<'storeReceiptSettings'> {
    return this.receiptsApi.getStoreReceipts(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении информации о чеках',
      }),
    );
  }

  updateStoreReceipts(variables: MutationSaveStoreReceiptSettingsArgs): MutationResult<'saveStoreReceiptSettings'> {
    return this.receiptsApi.updateStoreReceipts(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при сохранении информации о чеках',
      }),
    );
  }
}
