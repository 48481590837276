import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';
import { ProductModifiersReportMetric, ProductReportMetricNames } from '@typings';

const REPORT_METRICS: ProductReportMetricNames = {
  revenue: 'Выручка',
  prime: 'Себестоимость',
  profit: 'Прибыль',
  discount: 'Скидка',
};

export const getProductReportMetricsItems = (): MenuItem[] => {
  return Object.keys(REPORT_METRICS).map((metric) => ({
    id: metric,
    label: REPORT_METRICS[metric],
    type: 'item',
  }));
};

export const getProductMetricName = (metric: ProductModifiersReportMetric): string => {
  return REPORT_METRICS[metric];
};

export const getProductMetricsList = (): ProductModifiersReportMetric[] => {
  return Object.keys(REPORT_METRICS);
};
