import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'nm-revert-dialog',
  templateUrl: './revert-dialog.component.html',
})
export class RevertDialogComponent {
  get title(): string {
    return this.data.title;
  }

  get callbackFn(): () => void {
    return this.data.callbackFn;
  }

  get hideFn(): () => void {
    return this.data.hideFn;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      callbackFn: () => void;
      hideFn: () => void;
    },
  ) {}
}
