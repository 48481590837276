import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { HintVariant } from '@app/shared/component/control-hint/control-hint.component';
import { ModalRef, ModalService } from '@app/shared/component/dialog/abstract';
import { FileUrlDialogComponent } from '@app/shared/component/file-url-dialog/file-url-dialog.component';
import { InputImageComponent } from '@app/shared/component/input-image/input-image.component';
import { isFileTypeAllowed } from '@app/utils/file.util';

type FileSizeError = {
  reason: 'fileLimitExceeded';
  limit: number;
  current: number;
};

type FileTypeError = {
  reason: 'fileTypeNotAllowed';
  allowed: string[];
  current: string;
};

export type FileError = FileSizeError | FileTypeError;

@Component({
  selector: 'nm-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFileComponent implements OnChanges {
  @Input() file: File | [SafeUrl | string, string] | null = null;
  @Input() disabled: boolean = false;
  @Input() error: boolean = false;
  @Input() required: boolean = false;
  @Input() label: string = '';
  @Input() selectBtnLabel: string = 'Выбрать файл';
  @Input() description: string = '';
  @Input() hint: string = '';
  @Input() previewLabel: string = '';
  @Input() testId: string;
  @Input() allowedFileTypes: string[] = []; // without dots before type
  @Input() maxSize: number | null = null; // in kilobytes
  @Input() loadByLink = true;
  @Input() showIcon = true;

  @Output() fileSelect = new EventEmitter<File | null>();
  @Output() fileLoadError = new EventEmitter<HttpErrorResponse | undefined>();
  @Output() previewLoad = new EventEmitter<void>();
  @Output() previewError = new EventEmitter<ErrorEvent>();
  @Output() fileValidationSuccess = new EventEmitter<void>();
  @Output() fileValidationError = new EventEmitter<FileError>();

  @ViewChild('inputImage') inputImage: ElementRef<InputImageComponent>;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<FileUrlDialogComponent>;

  isDraggedOver: boolean = false;
  dragCount: number = 0;

  modalRef: ModalRef<FileUrlDialogComponent> | null = null;
  fileDownloadSub: Subscription | null = null;

  fileUrl: SafeUrl | string | null = null;
  fileName: string = '';

  constructor(
    private http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: ModalService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('file' in changes) {
      const file = changes.file.currentValue;

      if (file) {
        if (file instanceof File) {
          this.setFile(file);
        }

        if (Array.isArray(file) && file.length === 2) {
          this.fileUrl = file[0];
          this.fileName = file[1];
        }
      } else {
        this.fileUrl = null;
        this.fileName = '';
      }
    }
  }

  get hintType(): HintVariant {
    return this.error ? 'error' : 'helper';
  }

  get accept(): string {
    return this.allowedFileTypes.map((t) => `.${t}`).join(',');
  }

  setFile(file: File) {
    this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
    this.fileName = file.name;
  }

  handleLoadFromURL() {
    if (this.disabled) {
      return;
    }

    this.modalRef = this.modalService.openDialog(this.dialogTemplate);
  }

  handleDialogClose() {
    this.modalRef?.close();
  }

  handleUrlSelect(url: string) {
    this.handleDialogClose();

    if (!url) {
      return;
    }

    this.removeSelectedFile();

    this.fileDownloadSub = this.http.get(url, { responseType: 'blob' }).subscribe(
      (blob) => {
        this.setSelectedFile(new File([blob], 'name', { type: blob.type }));
      },
      (error?: HttpErrorResponse) => {
        this.fileLoadError.emit(error);
        this.cancelFileLoading();
      },
    );
    this.changeDetectorRef.markForCheck();
  }

  handleFileInput() {
    if (this.disabled) {
      return;
    }

    const files = this.fileInput.nativeElement.files;

    if (files && files.length > 0) {
      this.setSelectedFile(files[0]);
    }
  }

  handleDrop(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (this.disabled) {
      return;
    }

    this.isDraggedOver = false;
    this.dragCount = 0;

    if (e.dataTransfer?.files.length) {
      this.setSelectedFile(e.dataTransfer.files[0]);
    }
  }

  setSelectedFile(file: File) {
    this.cancelFileLoading();

    if (!file || !this.validateFile(file)) {
      return;
    }

    this.file = file;
    this.setFile(file);
    this.fileSelect.emit(file);
  }

  removeSelectedFile() {
    this.cancelFileLoading();

    this.fileInput.nativeElement.value = '';

    if (this.file) {
      this.file = null;
      this.fileUrl = null;
      this.fileName = '';
      this.fileSelect.emit(null);
    }
  }

  cancelFileLoading() {
    this.fileDownloadSub?.unsubscribe();
    this.fileDownloadSub = null;
    this.changeDetectorRef.markForCheck();
  }

  handleLoadFromLocal() {
    if (this.disabled) {
      return;
    }

    this.fileInput.nativeElement.click();
  }

  handlePreviewClick() {
    if (this.disabled || this.file) {
      return;
    }

    this.fileInput.nativeElement.click();
  }

  handleImagePreviewLoad() {
    this.previewLoad.emit();
  }

  handleImagePreviewError(e: ErrorEvent) {
    this.previewError.emit(e);
  }

  handleDragEnter(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (this.disabled || !this.isDraggedOver) {
      return;
    }

    this.dragCount++;
  }

  handleDragOver(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (this.disabled || this.isDraggedOver) {
      return;
    }

    this.isDraggedOver = true;
  }

  handleDragLeave(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (this.disabled || !this.isDraggedOver) {
      return;
    }

    if (this.dragCount) {
      this.dragCount--;
    } else {
      this.isDraggedOver = false;
    }
  }

  validateFile(file: File): boolean {
    if (!isFileTypeAllowed(file, this.allowedFileTypes)) {
      this.fileValidationError.emit({
        reason: 'fileTypeNotAllowed',
        allowed: this.allowedFileTypes,
        current: file.type.split('/')[1],
      });

      return false;
    }

    const limit = this.maxSize && this.maxSize * 1024;

    if (limit && file.size > limit) {
      this.fileValidationError.emit({
        reason: 'fileLimitExceeded',
        limit,
        current: file.size,
      });

      return false;
    }

    this.fileValidationSuccess.emit();

    return true;
  }

  get inputFileDropzoneClasses(): string[] {
    const classes = ['nm-input-file-dropzone'];

    if (this.disabled) {
      classes.push('nm-input-file-dropzone-disabled');
    }

    if (this.isDraggedOver) {
      classes.push('nm-input-file-dropzone-dragover');
    }

    return classes;
  }
}
