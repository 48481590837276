import { Pipe, PipeTransform } from '@angular/core';

import { MenuItem } from '../component/menu-item/menu-item.model';

@Pipe({
  name: 'filterMenuItems',
})
export class FilterMenuItemsPipe implements PipeTransform {
  transform(items: MenuItem[], search?: string): MenuItem[] {
    if (!search) {
      return items;
    }

    return items.filter((item) => {
      const label = item.label.toLowerCase();
      const value = search.toLowerCase();

      return label.includes(value);
    });
  }
}
