import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreakpointObserverService {
  breakpoints = ['(max-width: 767px), (min-width: 768px), (min-width: 1024px)', '(max-width: 900px)'];
  breakpoints$ = this.breakpointObserver.observe(this.breakpoints);
  minWidth768 = false;
  minWidth1024 = false;

  private isMinWidth768 = new BehaviorSubject<boolean>(false);
  isMinWidth768$ = this.isMinWidth768.asObservable();

  private isMinWidth1024 = new BehaviorSubject<boolean>(false);
  isMinWidth1024$ = this.isMinWidth1024.asObservable();

  private isMaxWidth900 = new BehaviorSubject<boolean>(false);
  isMaxWidth900$ = this.isMaxWidth900.asObservable();

  #isMobile = new BehaviorSubject<boolean>(false);
  isMobile$ = this.#isMobile.asObservable();

  #isTablet = new BehaviorSubject<boolean>(false);
  isTablet$ = this.#isTablet.asObservable();

  #isDesktop = new BehaviorSubject<boolean>(false);
  isDesktop$ = this.#isDesktop.asObservable();

  constructor(private breakpointObserver: BreakpointObserver) {}

  getIsMobile(): boolean {
    return this.#isMobile.getValue();
  }

  getIsTablet(): boolean {
    return this.#isTablet.getValue();
  }

  getIsDesktop(): boolean {
    return this.#isDesktop.getValue();
  }

  observe(): void {
    this.breakpoints$.subscribe((state: BreakpointState) => {
      this.minWidth768 = state.breakpoints['(min-width: 768px)'];
      this.minWidth1024 = state.breakpoints['(min-width: 1024px)'];

      this.isMinWidth768.next(state.breakpoints['(min-width: 768px)']);
      this.isMinWidth1024.next(state.breakpoints['(min-width: 1024px)']);
      this.isMaxWidth900.next(state.breakpoints['(max-width: 900px)']);

      this.#isMobile.next(state.breakpoints['(max-width: 767px)']);
      this.#isTablet.next(state.breakpoints['(min-width: 768px)']);
      this.#isDesktop.next(state.breakpoints['(min-width: 1024px)']);
    });
  }
}
