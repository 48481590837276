import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ScrollVisibilityDirective } from './scroll-visibility.directive';

@NgModule({
  declarations: [ScrollVisibilityDirective],
  imports: [CommonModule],
  exports: [ScrollVisibilityDirective],
})
export class ScrollVisibilityModule {}
