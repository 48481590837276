import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize, takeUntil, takeWhile } from 'rxjs/operators';

import { CountdownService } from '@app/modules/auth/service/countdown.service';
import { countdown, dayjs } from '@utils';

import { BaseComponent } from '../base-component/base.component';

@Component({
  selector: 'nm-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() datetime: string;

  #diff: number = 10000000;
  #timer: Subscription;

  counter = new BehaviorSubject<string>('');

  constructor(private countdownService: CountdownService) {
    super();
  }

  ngOnInit(): void {
    this.#updateDiff(this.datetime);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.datetime) {
      this.#updateDiff(changes.datetime.currentValue);
    }
  }

  #updateDiff(datetime: string) {
    this.#diff = dayjs(datetime).diff(Date.now());

    this.#startTimer();
  }

  #startTimer() {
    if (this.#timer) {
      this.#timer.unsubscribe();
    }

    this.#timer = this.countdownService.countdownTimer
      .pipe(
        takeUntil(this.onDestroy),
        takeWhile((val) => this.#diff > val),
        finalize(() => this.#timer.unsubscribe()),
      )
      .subscribe((res) => {
        this.counter.next(countdown(this.#diff - res * 1000, 'Истёк'));
      });
  }
}
