import { FormControl } from '@angular/forms';
import { BehaviorSubject, map, Observable, Subject, takeUntil } from 'rxjs';

import { notEmpty } from '@utils';

import { AutocompleteOption, AutocompleteSelectAllEvent, UpdateSelected } from '../../component/autocomplete/autocomplete.model';
import { TreeDataService } from '../tree-data.service';

export class TreeDataAutocompleteService<T = unknown> {
  #treeDataService: TreeDataService<AutocompleteOption<T>>;
  #formControl: FormControl | null;
  #returnObjects: boolean;
  #unsubscribe: Subject<void>;
  options$: Observable<AutocompleteOption[]>;
  selectedOptionIds$: Observable<Set<string>>;
  updateSelected = new Subject<UpdateSelected>();

  allOptionsList: AutocompleteOption[];
  allSelected = new BehaviorSubject<boolean>(false);
  topParentSelected$ = new BehaviorSubject<AutocompleteOption<T>[]>([]);

  getSelectedOptionFn: (selectedId: string) => AutocompleteOption | null;
  searchFn: (searchText: string) => void;

  constructor() {}

  init(
    treeDataService: TreeDataService<AutocompleteOption<T>>,
    formControl: FormControl | null = null,
    returnObjects: boolean = false,
    allOptionsList?: AutocompleteOption[],
    allSelected?: boolean,
  ) {
    if (this.#unsubscribe) {
      this.destroy();
    }
    this.#unsubscribe = new Subject<void>();
    this.#treeDataService = treeDataService;
    this.#formControl = formControl;
    this.#returnObjects = returnObjects;
    this.allOptionsList = allOptionsList || [];
    if (allSelected) {
      this.allSelected.next(allSelected);
    }
    if (allSelected && this.#formControl) {
      this.#formControl.setValue(this.allOptionsList.map((opt) => opt));
    }

    this.getSelectedOptionFn = this.#getSelectedOptionFn;
    this.searchFn = this.#searchFn;

    this.options$ = this.#treeDataService.shownNodesList$.pipe(
      map((options) =>
        options.reduce((acc: AutocompleteOption[], current) => {
          if (current.isLoading) {
            acc.push({ label: 'loading', type: 'loading', id: '' });
          } else {
            const opt = {
              ...current,
              ...current.data,
              expandable: current.isParent,
              expanded: current.isExpanded,
              onExpandClick: ($event: boolean) => this.#setExpandState($event, opt),
              onCheckboxChange: ($event: boolean) => this.#setSelectedState($event, opt),
              onClick: ($event: boolean) => this.#setSelectedState($event, opt),
            };
            acc.push(opt);
          }
          return acc;
        }, []),
      ),
      takeUntil(this.#unsubscribe),
    );

    this.selectedOptionIds$ = treeDataService.selectedNodesIds$.pipe(
      map((options) => {
        return options;
      }),
      takeUntil(this.#unsubscribe),
    );
    this.updateSelected = treeDataService.updateSelected;
    if (this.#formControl) {
      this.#treeDataService.selectedNodesIds$.pipe(takeUntil(this.#unsubscribe)).subscribe((res) => {
        if (!this.#returnObjects) {
          this.#formControl?.setValue([...res]);
        } else {
          this.#formControl?.setValue([...res].map((id) => this.#getSelectedOptionFn(id)));
        }

        this.topParentSelected$.next(
          [...res]
            .filter((id) => this.#filterTopSelectedFn(id))
            .map((id) => this.#getSelectedOptionFn(id))
            .filter(notEmpty),
        );
      });
    }
  }

  loadRootNodes() {
    if (!this.#treeDataService) {
      throw Error('Service is not initialized. Call init() at first');
    }
    this.#treeDataService.loadRootNodes();
  }

  loadAllNodes() {
    if (!this.#treeDataService) {
      throw Error('Service is not initialized. Call init() at first');
    }
    this.#treeDataService.loadAllNodes();
  }

  reloadAllNodes() {
    if (!this.#treeDataService) {
      throw Error('Service is not initialized. Call init() at first');
    }
    this.#treeDataService.reloadAllNodes();
  }

  isAllSelected(): boolean {
    return this.allSelected.getValue();
  }
  updateTreeService(treeDataService: TreeDataService<AutocompleteOption<T>>) {
    this.#treeDataService = treeDataService;
  }
  deselectAll(): void {
    if (!this.#treeDataService) {
      throw Error('Service is not initialized. Call init() at first');
    }
    this.#treeDataService.deselectAll();
  }

  selectAllHandler(value: AutocompleteSelectAllEvent) {
    this.allSelected.next(value.selected);
    if (value.updateSelectedItems) {
      if (value.selected) {
        this.#treeDataService.selectAll();
      } else {
        this.deselectAll();
      }
    }
  }

  #searchFn = (searchText: string): void => {
    this.#treeDataService.filter(searchText);
  };

  #getSelectedOptionFn = (selectedId: string): AutocompleteOption<T> | null => {
    let node;
    try {
      node = this.#treeDataService.getTreeNodeById(selectedId);
    } catch {
      return null;
    }

    if (!node) return null;
    return {
      ...node,
      ...node.data,
      expandable: node.isParent,
      expanded: node.isExpanded,
    } as AutocompleteOption<T>;
  };

  #filterTopSelectedFn = (selectedId: string): boolean => {
    let node;
    try {
      node = this.#treeDataService.getTreeNodeById(selectedId);
    } catch {
      return false;
    }

    if (!node) return false;
    if (!node.parent) return true;

    let parentNode;
    try {
      parentNode = this.#treeDataService.getTreeNodeById(node.parent);
    } catch {
      return true;
    }
    return !parentNode || !parentNode.isSelected;
  };

  #setExpandState(isExpanded: boolean, option: AutocompleteOption): void {
    if (isExpanded) {
      this.#treeDataService.expand(option.id);
    } else {
      this.#treeDataService.collapse(option.id);
    }
  }

  #setSelectedState(isSelected: boolean, option: AutocompleteOption): void {
    if (isSelected) {
      this.#treeDataService.select(option.id);
    } else {
      this.#treeDataService.deselect(option.id);
    }
  }

  destroy() {
    this.#unsubscribe.next();
    this.#unsubscribe.complete();
  }
}
export class TreeDataAutocompleteServiceFactory {
  static getService(
    treeDataService: TreeDataService<AutocompleteOption>,
    formControl: FormControl | null = null,
    returnObjects: boolean = false,
    allOptionsList?: AutocompleteOption[],
    allSelected?: boolean,
  ): TreeDataAutocompleteService {
    const service = new TreeDataAutocompleteService();
    service.init(treeDataService, formControl, returnObjects, allOptionsList, allSelected);
    return service;
  }
}
