import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ClientDeviceType, DeviceWidthPoints } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class DetectDeviceService {
  deviceWidthPoints = DeviceWidthPoints;
  deviceType = ClientDeviceType;

  device: ClientDeviceType = ClientDeviceType.MOBILE;
  deviceDetection$ = new BehaviorSubject<ClientDeviceType>(this.getDeviceType());

  constructor() {}

  detectDeviceWidth(): void {
    this.device = this.getDeviceType();
    this.deviceDetection$.next(this.device);
  }

  deviceDetection(): Observable<ClientDeviceType> {
    return this.deviceDetection$.asObservable();
  }

  private getDeviceType(): ClientDeviceType {
    return window.innerWidth >= this.deviceWidthPoints.desktop
      ? this.deviceType.DESKTOP
      : window.innerWidth >= this.deviceWidthPoints.tablet
      ? this.deviceType.TABLET
      : this.deviceType.MOBILE;
  }
}
