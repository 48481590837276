import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ListingContentModule } from '@app/shared/component/listing-content/listing-content.module';
import { ListingFooterModule } from '@app/shared/component/listing-footer/listing-footer.module';
import { ListingHeaderModule } from '@app/shared/component/listing-header/listing-header.module';

import { ListingComponent } from './listing.component';

@NgModule({
  declarations: [ListingComponent],
  imports: [CommonModule, ListingHeaderModule, ListingContentModule, ListingFooterModule],
  exports: [ListingComponent],
})
export class ListingModule {}
