import { Pipe, PipeTransform } from '@angular/core';

import { CoreSchema } from '@typings';
import { notEmpty } from '@utils';

@Pipe({
  name: 'sumInventoryAmount',
})
export class SumInventoryAmountPipe implements PipeTransform {
  transform(documentItems: CoreSchema.DocumentItem[] = []): number {
    const filteredDocumentItems = (documentItems || []).filter((item) => item.planQuantity !== item.factQuantity);

    return filteredDocumentItems.reduce((acc, val) => {
      const factQuantity = val?.factQuantity;
      const planQuantity = val?.planQuantity;
      const amountValue = val?.stockUnit?.primePrice?.amountValue;

      if (notEmpty(factQuantity) && notEmpty(planQuantity) && notEmpty(amountValue)) {
        const quantity = val?.stockUnit?.quantity;

        return notEmpty(quantity)
          ? acc + (factQuantity - planQuantity) * (Number(amountValue) / Number(quantity))
          : acc + (factQuantity - planQuantity) * Number(amountValue);
      }

      return acc;
    }, 0);
  }
}
