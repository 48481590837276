import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, timer } from 'rxjs';

@Component({
  selector: 'nm-layout-loader',
  templateUrl: './layout-loader.component.html',
  styleUrls: ['./layout-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutLoaderComponent {
  isNavigate$ = new BehaviorSubject<boolean>(true);

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (this.isNavigate$.getValue() === false) {
          this.isNavigate$.next(true);
        }
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        if (this.isNavigate$.getValue() === true) {
          timer(1000).subscribe(() => this.isNavigate$.next(false));
        }
      }
    });
  }
}
