import { SectionRc } from '@typings';

export const rootMenuSection: SectionRc = {
  id: 'root',
  name: 'Корневая категория',
  depthLevel: 0,
  leftMargin: 0,
  rightMargin: 0,
  menuId: '',
  color: 'COLOR_V1',
};
