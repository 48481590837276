import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateFractional',
})
export class TruncateFractionalPipe implements PipeTransform {
  transform(value: number, digits: number): number {
    if (digits < 0) {
      throw new Error(`Fractional digits number must be equal or greater than zero, actual: ${digits}`);
    }

    if (digits === 0) {
      return Math.floor(value);
    }

    const a = Math.pow(10, Math.floor(digits));

    return Math.floor(value * a) / a;
  }
}
