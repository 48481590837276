import { CatalogEntityStrict, ValueType } from '@typings';

import { UNIT_TYPE } from './unit-type';

export const WEIGHT_TYPES: ValueType[] = [
  {
    name: 'Поштучный',
    value: false,
    type: 'weight',
  },
  {
    name: 'Весовой',
    value: true,
    type: 'weight',
  },
];

export const UNIT_TYPES: ValueType[] = [
  {
    name: 'Штука',
    value: UNIT_TYPE.PIECE,
    type: 'unitType',
  },
  {
    name: 'Литр',
    value: UNIT_TYPE.LITER,
    type: 'unitType',
  },
  {
    name: 'Грамм',
    value: UNIT_TYPE.GRAM,
    type: 'unitType',
  },
  {
    name: 'Килограмм',
    value: UNIT_TYPE.KILOGRAM,
    type: 'unitType',
  },
  {
    name: 'Миллилитр',
    value: UNIT_TYPE.MILLILITER,
    type: 'unitType',
  },
];

export const CATALOG_ENTITY_TYPE: CatalogEntityStrict = {
  CATALOG: 'CATALOG',
  PRODUCT: 'PRODUCT',
  SECTION: 'SECTION',
  STOCK_UNIT: 'STOCK_UNIT',
  STORE: 'STORE',
};
