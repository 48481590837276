import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';

import { querySearchAddress } from '@api';
import { ApolloQueryResult, DocumentNode } from '@apollo/client/core';
import { CoreSchema } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private apollo: Apollo) {}

  public searchAddress(
    request: string,
    query: DocumentNode = querySearchAddress,
  ): Observable<
    ApolloQueryResult<{
      searchAddressByRequest: CoreSchema.AddressSearchResponse;
    }>
  > {
    return this.apollo.watchQuery<{
      searchAddressByRequest: CoreSchema.AddressSearchResponse;
    }>({
      query,
      variables: {
        request,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }
}
