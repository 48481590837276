import { Injectable } from '@angular/core';

import {
  mutationCreateEmployee,
  mutationInviteEmployee,
  mutationRemoveEmployee,
  mutationUpdateEmployee,
  mutationUpdateEmployeePinCode,
  queryAllEmployeesPageable,
  queryEmployeeV2,
  queryUserGroups,
} from '@api';
import { ApiService } from '@core/service';
import {
  CoreSchema,
  Mutation,
  MutationBatchResult,
  MutationCreateEmployeeArgs,
  MutationRemoveEmployeeArgs,
  MutationResult,
  MutationUpdateEmployeeArgs,
  MutationUpdateEmployeePinCodeArgs,
  Query,
  QueryAllEmployeesPageableArgs,
  QueryEmployeeArgs,
  QueryResult,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class EmployeesApi {
  constructor(private apiService: ApiService) {}

  createEmployee(variables: MutationCreateEmployeeArgs): MutationResult<'createEmployee'> {
    return this.apiService.mutate<Mutation<'createEmployee'>, MutationCreateEmployeeArgs>({
      mutation: mutationCreateEmployee,
      variables,
    });
  }

  createEmployeeInvitation(variables: CoreSchema.MutationInviteEmployeeArgs): MutationResult<'createEmployeeInvitation'> {
    return this.apiService.mutate<Mutation<'createEmployeeInvitation'>, CoreSchema.MutationInviteEmployeeArgs>({
      mutation: mutationInviteEmployee,
      variables,
    });
  }

  updateEmployee(variables: MutationUpdateEmployeeArgs): MutationResult<'updateEmployee'> {
    return this.apiService.mutate<Mutation<'updateEmployee'>, MutationUpdateEmployeeArgs>({
      mutation: mutationUpdateEmployee,
      variables,
    });
  }

  removeEmployee(variables: MutationRemoveEmployeeArgs): MutationResult<'removeEmployee'> {
    return this.apiService.mutate<Mutation<'removeEmployee'>, MutationRemoveEmployeeArgs>({
      mutation: mutationRemoveEmployee,
      variables,
    });
  }

  updateEmployeePinCodeBatch(variables: MutationUpdateEmployeePinCodeArgs[]): MutationBatchResult<'updateEmployeePinCode'> {
    return this.apiService.mutateBatch<Mutation<'updateEmployeePinCode'>, MutationUpdateEmployeePinCodeArgs[]>({
      mutation: mutationUpdateEmployeePinCode,
      variables,
    });
  }

  updateEmployeePinCode(variables: MutationUpdateEmployeePinCodeArgs): MutationResult<'updateEmployeePinCode'> {
    return this.apiService.mutate<Mutation<'updateEmployeePinCode'>, MutationUpdateEmployeePinCodeArgs>({
      mutation: mutationUpdateEmployeePinCode,
      variables,
    });
  }

  getEmployeesList(variables: QueryAllEmployeesPageableArgs): QueryResult<'allEmployeesPageable'> {
    return this.apiService.query<Query<'allEmployeesPageable'>, QueryAllEmployeesPageableArgs>({
      query: queryAllEmployeesPageable,
      variables,
    });
  }

  getEmployee(variables: QueryEmployeeArgs): QueryResult<'employee'> {
    return this.apiService.query<Query<'employee'>, QueryEmployeeArgs>({
      query: queryEmployeeV2,
      variables,
    });
  }

  getUserGroupsByOrganization(): QueryResult<'userGroups'> {
    return this.apiService.query<Query<'userGroups'>, {}>({
      query: queryUserGroups,
    });
  }
}
