import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';

import { DialogModule } from '@app/shared/component/dialog/dialog.module';
import { ModalBaseModule } from '@app/shared/component/dialog/modal-base/modal-base.module';
import { InputModule } from '@app/shared/component/input/input.module';
import { RadioModule } from '@app/shared/component/radio/radio.module';

import { CreateSupplierDialogComponent } from './create-supplier-dialog.component';

@NgModule({
  declarations: [CreateSupplierDialogComponent],
  imports: [CommonModule, MatDialogModule, ModalBaseModule, DialogModule, MatRadioModule, RadioModule, InputModule, ReactiveFormsModule],
  exports: [CreateSupplierDialogComponent],
})
export class CreateSupplierDialogModule {}
