export const CHART_DONUT_COLORS = [
  '#40DA9D',
  '#39A5FF',
  '#FFA73F',
  'rgb(255, 205, 86)',
  '#292961',
  '#EDF1F8',
  '#f95454',
  '#3a0a0a',
  '#36383f',
  '#f936bb',
  '#cb36f9',
  '#280434',
];

export const CHART_CURRENT_COLOR = '#409DEF';
export const CHART_PREVIOUS_COLOR = '#C3CEE0';

export const CHART_HIGH_COLOR = '#40DA9D';
export const CHART_AVERAGE_COLOR = '#FF981E';
export const CHART_LOW_COLOR = '#FF5B5B';
