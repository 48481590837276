import { ComponentRef, Directive, EventEmitter, Input, Output, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TableService } from '@app/shared/service/table.service';

import { TableRowComponent } from './table-row/table-row.component';
import { TemplateColData } from './table.component';
import { Row, TableConfig } from './table.types';

@Directive({
  selector: '[rowChild]',
})
export class RowChildDirective {
  constructor(private viewContainerRef: ViewContainerRef, public tableService: TableService) {}

  private destroyed$ = new Subject<void>();
  private _config: TableConfig;
  private _row: Row;
  rowComponent: ComponentRef<TableRowComponent>;

  @Output() selectRow = new EventEmitter<Row>();
  @Output() expandRow = new EventEmitter<Row>();
  @Output() dragStart = new EventEmitter<Row>();
  @Output() dragEnd = new EventEmitter<Row>();

  @Input() rowPadding = 0;
  @Input() columnsDataTemplates: TemplateColData[] = [];
  @Input() gridTemplateColumns: string = 'auto';
  @Input() set config(conf: TableConfig) {
    this._config = conf;
    if (this.row) {
      this.updateRow();
    }
  }
  get config(): TableConfig {
    return this._config;
  }

  @Input()
  set row(row: Row) {
    this._row = row;
    this.updateRow();
  }

  @Input()
  set update(res: { isMouseOver: boolean; isMouseOverIndex: number }) {
    this.updatePathOfRow(res.isMouseOver, res.isMouseOverIndex);
  }

  get row(): Row {
    return this._row;
  }

  updatePathOfRow(over: boolean, index: number) {
    this.rowComponent.instance.isMouseOver = over || false;
    this.rowComponent.instance.isMouseOverIndex = index || 0;
  }

  updateRow() {
    this.viewContainerRef.clear();
    const rowComponent = this.viewContainerRef.createComponent(TableRowComponent);
    this.rowComponent = rowComponent;
    rowComponent.instance.row = this.row;
    rowComponent.instance.columns = this.row.columnsData;
    rowComponent.instance.rowPadding = this.rowPadding;
    rowComponent.instance.isChild = true;
    rowComponent.instance.config = this.config;
    rowComponent.instance.columnsDataTemplates = this.columnsDataTemplates;
    rowComponent.instance.gridTemplateColumns = this.gridTemplateColumns;
    rowComponent.instance.selectRow.pipe(takeUntil(this.destroyed$)).subscribe((row) => {
      this.selectRow.emit(row || this.row);
    });
    rowComponent.instance.expandRow.pipe(takeUntil(this.destroyed$)).subscribe((row) => {
      this.expandRow.emit(row || this.row);
    });
    rowComponent.instance.dragStart.pipe(takeUntil(this.destroyed$)).subscribe((row) => {
      this.dragStart.emit(row);
    });
    rowComponent.instance.dragEnd.pipe(takeUntil(this.destroyed$)).subscribe((_) => {
      this.dragEnd.emit();
    });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy() {
    this.destroyed$.next();
    this.viewContainerRef.clear();
  }
}
