import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';

export function phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;
    if (!value) return null;

    const phoneIsInvalid = !isPossiblePhoneNumber(value, 'RU') && !isValidPhoneNumber(value, 'RU');

    return phoneIsInvalid ? { invalidPhoneNumber: true } : null;
  };
}
