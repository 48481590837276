import { ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject, takeUntil } from 'rxjs';

import { CachedCatalogTreeService } from '@app/modules/catalog/services/ccached-catalog/cached-catalog-tree.service';
import { MenuSectionTreeService } from '@app/modules/catalog/services/menu-categories-tree.service';
import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { InfiniteLoaderAutocompleteService } from '@app/shared/service/autocomplete/infinite-loader-autocomplete.service';
import { TreeDataService } from '@app/shared/service/tree-data.service';
import { MAX_CHARACTERS } from '@constants';
import { ValidationErrorsService } from '@core/service';
import { MenuService, MenuStorage } from '@services/catalog';
import { TreeDataAutocompleteService } from '@services/shared';
import { CoreSchema, InCatalogResponse, MenuItemsForm, MenuSection } from '@typings';

@Component({
  selector: 'nm-menu-items-dialog',
  templateUrl: './menu-items-dialog.component.html',
})
export class MenuItemsDialogComponent implements OnDestroy {
  maxCharacters = MAX_CHARACTERS;
  sections$: Observable<AutocompleteOption<CoreSchema.SectionRc>[]>;
  treeDataService: TreeDataService<AutocompleteOption>;
  destroy$ = new Subject<void>();

  autocompleteTreeService = new TreeDataAutocompleteService();
  menuSectionsAutocompleteTreeService = new TreeDataAutocompleteService();

  get btnTitle(): string {
    return 'Добавить';
  }

  get title() {
    return 'Добавление позиций';
  }

  get menuId() {
    return this.data.menuId;
  }

  get sectionId() {
    return this.data?.section?.id;
  }

  get form(): FormGroup<MenuItemsForm> {
    return this.menuService.menuItemsForm;
  }

  get controls() {
    return this.form.controls;
  }

  get subsectionControl() {
    return this.controls.section;
  }

  get isSubsectionError() {
    return this.subsectionControl.dirty && this.subsectionControl.invalid;
  }

  get structure() {
    return this.controls.structure;
  }
  get useStructure() {
    return this.controls.useStructure;
  }

  isControlError(control: AbstractControl): boolean {
    return this.validationErrorsService.isControlDirtyAndInvalid(control);
  }

  controlErrorHint(control: AbstractControl, errorCode: string): string {
    return this.validationErrorsService.validateField(control) || control.getError(errorCode);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      menuId: string;
      section: MenuSection | null;
    },
    public menuService: MenuService,
    public validationErrorsService: ValidationErrorsService,
    private menuStorage: MenuStorage,
    private cachedCatalogTreeService: CachedCatalogTreeService,
    private menuSectionTreeService: MenuSectionTreeService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.treeDataService = this.cachedCatalogTreeService.initSections();
    this.autocompleteTreeService.init(this.treeDataService);
    this.menuService.initMenuItemsForm(this.data.section);

    this.treeDataService = this.menuSectionTreeService.initSections({ preloadAllNodes: false });
    this.menuSectionsAutocompleteTreeService.init(this.treeDataService);
  }

  closeForm(confirm: boolean): void {
    this.menuService.closeMenuItemsDialog(confirm, this.form);
  }

  createProduct(name: string): void {
    this.menuService.openCreateProductDialog(name);
  }

  change(event: MatCheckboxChange) {
    this.useStructure.setValue(event.checked);
  }

  onOpenProductsAutocomplete(
    control: FormControl<AutocompleteOption<InCatalogResponse>[]>,
    searchService: InfiniteLoaderAutocompleteService<InCatalogResponse>,
    searchText: string,
  ): void {
    searchService.search(control.value && control.value.length ? '' : searchText, true);
  }
  createSection(event: string) {
    this.menuService.openMenuSectionDialog(undefined, event);
    this.menuService.sectionCreated.pipe(takeUntil(this.destroy$)).subscribe((id) => {
      this.menuSectionsAutocompleteTreeService.reloadAllNodes();
      this.subsectionControl.setValue(id);
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
