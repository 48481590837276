export function calculateDistanceFromTop(event: DragEvent, dropzoneElement: HTMLElement): number {
  const eventY = event.clientY;
  const dropzoneRect = dropzoneElement.getBoundingClientRect();

  const dropzoneOffsetTop = dropzoneRect.top + window.pageYOffset;
  const dropzoneScrollTop = dropzoneElement.scrollTop;

  const distanceFromTop = eventY - dropzoneOffsetTop + dropzoneScrollTop;
  return distanceFromTop;
}

export function calculateDistanceFromLeft(event: DragEvent, dropzoneElement: HTMLElement): number {
  const dropzoneRect = dropzoneElement.getBoundingClientRect();
  const dropzoneOffsetLeft = dropzoneRect.left + window.pageXOffset;
  const dropzoneScrollLeft = dropzoneElement.scrollLeft;
  return event.clientX - dropzoneOffsetLeft + dropzoneScrollLeft;
}
