import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalStateService {
  modalIsOpen = false;
  constructor() {}
  setModalState(state: boolean) {
    this.modalIsOpen = state;
  }
  getModalState() {
    return this.modalIsOpen;
  }
}
