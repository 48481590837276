import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OrganizationsComponent } from '@app/modules/auth/page/organizations/organizations.component';
import { AuthRoute } from '@constants';
import { AuthGuard, SigninGuard } from '@guards';

const routes: Routes = [
  {
    path: '',
    redirectTo: AuthRoute.login,
    pathMatch: 'full',
  },
  {
    path: AuthRoute.login,
    canActivate: [SigninGuard],
    loadChildren: () => import('./page/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: AuthRoute.choose,
    component: OrganizationsComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
