<div class="actions">
  <ng-container *ngIf="isTablet | async">
    <nm-button
      *ngIf="(items$ | async)?.length"
      [disabled]="isMenuDisabled$ | async"
      variant="outlined"
      [iconRight]="'arrowDropDown'"
      [matMenuTriggerFor]="(isMenuDisabled$ | async) ? null : menu.childMenu"
      data-test-id="topbar::actions-list-button"
    >
      Действия
    </nm-button>

    <nm-button
      *ngIf="settings?.buttonAction"
      data-test-id="topbar::right-action-button"
      [variant]="settings.buttonAction.variant || 'filled'"
      (click)="settings?.buttonAction.onClick()"
      >{{ settings.buttonAction.label }}</nm-button
    >
  </ng-container>

  <nm-icon-button
    *ngIf="(isMobile | async) && (items$ | async)?.length"
    [disabled]="isMenuDisabled$ | async"
    variant="text"
    icon="moreHoriz"
    [matMenuTriggerFor]="(isMenuDisabled$ | async) ? null : menu.childMenu"
    data-test-id="topbar::mobile-dots-button"
  ></nm-icon-button>

  <nm-menu #menu class="menu" [selectOnItemClick]="false" [items]="items$ | async"></nm-menu>
</div>
