import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Breadcrumb } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  breadcrumbs = new BehaviorSubject<Breadcrumb[]>([]);

  constructor() {}

  set(value: Breadcrumb[]): void {
    this.breadcrumbs.next(value);
  }

  clear(): void {
    this.breadcrumbs.next([]);
  }
}
