import { gql } from 'apollo-angular';

import { fragmentPage } from './mutations';

export const querySubscriber = gql`
  query subscriber {
    subscriber {
      organizationId
      deviceRestrictions {
        allowed
        deviceType
      }
      addonsSubscriptions {
        addon {
          id
          name
          price {
            amountValue
            currencyUnit
          }
        }
      }
      deviceUsages {
        deviceType
        used
      }
      servicePlan {
        id
        name
        servicesDescription
        price {
          amountValue
          currencyUnit
        }
      }
      validUntil
    }
  }
`;

export const queryServicePlanV2 = gql`
  query servicePlanV2($servicePlanId: UUID!) {
    servicePlanV2(servicePlanId: $servicePlanId) {
      id
    }
  }
`;

export const queryServicePlansV2 = gql`
  query servicePlansV2($pageRequest: PageRequestInput) {
    servicePlansV2(pageRequest: $pageRequest) {
      ...Page
      content {
        id
        description
        isPersonal
        name
        servicesDescription
        price {
          amountValue
          currencyUnit
        }
        terms {
          deviceType
          limit
        }
        trialPeriod
      }
    }
  }
  ${fragmentPage}
`;

export const queryServicePlanUsageSummary = gql`
  query servicePlanUsageSummary {
    servicePlanUsageSummary {
      organizationId
      showPaywall
      servicePlan {
        description
        id
        name
        servicesDescription
        terms {
          deviceType
          limit
        }
        price {
          amountValue
          currencyUnit
        }
      }
      remainingDays
      isFreeAccount
    }
  }
`;

export const queryAddon = gql`
  query addon($addonId: UUID!) {
    addon(addonId: $addonId) {
      id
    }
  }
`;

export const queryAddons = gql`
  query addons($pageRequest: PageRequestInput) {
    addons(pageRequest: $pageRequest) {
      ...Page
      content {
        id
        description
        name
        price {
          amountValue
          currencyUnit
        }
        terms {
          deviceType
          limit
        }
      }
    }
  }
  ${fragmentPage}
`;

export const queryDevices = gql`
  query devices($pageRequest: PageRequestInput) {
    devices(pageRequest: $pageRequest) {
      ...Page
      content {
        id
        name
        deviceType
        model
        deviceUniqueKey
        createdDate
      }
    }
  }
  ${fragmentPage}
`;

export const queryBill = gql`
  query bill($billId: UUID!) {
    bill(billId: $billId) {
      id
      billNumber
      paymentDate
      items {
        id
        name
        quantity
        amount {
          amountValue
          currencyUnit
        }
      }
      totalAmount {
        amountValue
        currencyUnit
      }
      status
      accountingPeriod {
        from
        to
      }
    }
  }
`;

export const queryBills = gql`
  query bills($pageRequest: PageRequestInput) {
    bills(pageRequest: $pageRequest) {
      ...Page
      content {
        id
        billNumber
        paymentDate
        items {
          id
          name
          amount {
            amountValue
            currencyUnit
          }
          quantity
        }
        totalAmount {
          amountValue
          currencyUnit
        }
        status
        accountingPeriod {
          from
          to
        }
      }
    }
  }
  ${fragmentPage}
`;

export const fragmentMonetizationOrderResult = gql`
  fragment MonetizationOrderResult on MonetizationOrderResult {
    transactionId
    result
    errorMessage
    output {
      id
      accountingPeriod {
        from
        to
      }
      amount {
        amountValue
        currencyUnit
      }
      discount {
        amountValue
        currencyUnit
      }
      items {
        amount {
          amountValue
          currencyUnit
        }
        description
        id
        name
        price {
          amountValue
          currencyUnit
        }
        quantity
      }
      organizationId
      totalAmount {
        amountValue
        currencyUnit
      }
    }
  }
`;

export const mutationOrderServicePlan = gql`
  mutation orderServicePlan($servicePlanId: UUID!) {
    orderServicePlan(servicePlanId: $servicePlanId) {
      ...MonetizationOrderResult
    }
  }
  ${fragmentMonetizationOrderResult}
`;

export const mutationOrderRecurringBill = gql`
  mutation orderRecurringBill($billId: UUID!) {
    orderRecurringBill(billId: $billId) {
      ...MonetizationOrderResult
    }
  }
  ${fragmentMonetizationOrderResult}
`;

export const mutationOrderAddon = gql`
  mutation orderAddon($addonRequests: [OrderAddonInput!]!) {
    orderAddon(addonRequests: $addonRequests) {
      ...MonetizationOrderResult
    }
  }
  ${fragmentMonetizationOrderResult}
`;

export const fragmentPaymentInfoResult = gql`
  fragment PaymentInfoResult on PaymentInfoResult {
    transactionId
    result
    errorMessage
    output {
      details
      errorCode
      message
      paymentId
      paymentUrl
      status
      success
    }
  }
`;

export const mutationPayOrder = gql`
  mutation payOrder($orderId: UUID!) {
    payOrder(orderId: $orderId) {
      ...PaymentInfoResult
    }
  }
  ${fragmentPaymentInfoResult}
`;

export const mutationRemoveDevice = gql`
  mutation removeDevice($deviceId: UUID!) {
    removeDevice(deviceId: $deviceId)
  }
`;
