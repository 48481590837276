import {
  ColumnConfig,
  MAX_COLUMN_WIDTH,
  MIN_COLUMN_WIDTH,
  SELECT_COLUMN_WIDTH,
  TableConfig,
} from '@app/shared/component/table/table.types';
import { SVG_ICONS_TYPE } from '@constants';

export type CompareCellInfo = {
  percent: string;
  color: string;
  icon?: SVG_ICONS_TYPE | null;
};

export const tableGridTemplateColumns = (config: TableConfig): string => {
  const selectable: boolean = config.selectable;
  const enumerable: boolean = !!config.enumerable;
  const template = selectable || enumerable ? `${String(SELECT_COLUMN_WIDTH)}px` : '';

  const getWidthText = (w: string | [string, string] = `${String(MAX_COLUMN_WIDTH)}px`, i: number, col: ColumnConfig): string => {
    if (col.name === 'tools') {
      return `${String(MIN_COLUMN_WIDTH)}px`;
    }

    if (col.hidden) {
      return '';
    }

    const value = (v: string) => (selectable && i === 0 ? `calc(${v} - ${template})` : v);

    return ` minmax(${
      Array.isArray(w) ? w.join(',') : `min(${String(MIN_COLUMN_WIDTH)}px, ${value(w)}), max(${value(w)}, ${String(MAX_COLUMN_WIDTH)}px)`
    })`;
  };

  return config?.columnsConfig?.reduce((template, col, i) => template + getWidthText(col.width, i, col), template) || 'auto';
};
