import { gql } from 'apollo-angular';

export const fragmentChartProductReportData = gql`
  fragment ChartProductReportData on AnalyticsChartReportSummary {
    revenue
    profit
    quantity
    unitType
  }
`;

export const queryChartReportForSingleStockUnitSummary = gql`
  query getChartReportForSingleStockUnitSummary(
    $dateFrom: LocalDate!
    $dateTo: LocalDate!
    $dateFromPrev: LocalDate!
    $dateToPrev: LocalDate!
    $stockUnitId: UUID!
    $storeIds: [UUID!]
    $terminalIds: [UUID!]
  ) {
    current: getChartReportForSingleStockUnitSummary(
      dateFrom: $dateFrom
      dateTo: $dateTo
      stockUnitId: $stockUnitId
      storeIds: $storeIds
      terminalIds: $terminalIds
    ) {
      ...ChartProductReportData
    }
    previous: getChartReportForSingleStockUnitSummary(
      dateFrom: $dateFromPrev
      dateTo: $dateToPrev
      stockUnitId: $stockUnitId
      storeIds: $storeIds
      terminalIds: $terminalIds
    ) {
      ...ChartProductReportData
    }
  }
  ${fragmentChartProductReportData}
`;

export const fragmentChartProductReportDaily = gql`
  fragment ChartProductReport on AnalyticsChartReport {
    revenue
    profit
    stockUnitQuantity
    date
  }
`;

export const queryGetChartReportForSingleStockUnitDaily = gql`
  query getChartReportForSingleStockUnit(
    $dateFrom: LocalDate!
    $dateTo: LocalDate!
    $dateFromPrev: LocalDate!
    $dateToPrev: LocalDate!
    $stockUnitId: UUID!
    $storeIds: [UUID!]
    $terminalIds: [UUID!]
  ) {
    current: getChartReportForSingleStockUnit(
      dateFrom: $dateFrom
      dateTo: $dateTo
      stockUnitId: $stockUnitId
      storeIds: $storeIds
      terminalIds: $terminalIds
    ) {
      ...ChartProductReport
    }
    previous: getChartReportForSingleStockUnit(
      dateFrom: $dateFromPrev
      dateTo: $dateToPrev
      stockUnitId: $stockUnitId
      storeIds: $storeIds
      terminalIds: $terminalIds
    ) {
      ...ChartProductReport
    }
  }
  ${fragmentChartProductReportDaily}
`;

export const fragmentChartProductReportHourly = gql`
  fragment ChartProductReportHourly on AnalyticsChartReportHourly {
    revenue
    profit
    date
    stockUnitQuantity
  }
`;

export const queryGetChartReportForSingleStockUnitHourly = gql`
  query getChartReportForSingleStockUnitHourly(
    $dateFrom: LocalDate!
    $dateTo: LocalDate!
    $dateFromPrev: LocalDate!
    $dateToPrev: LocalDate!
    $stockUnitId: UUID!
    $storeIds: [UUID!]
    $terminalIds: [UUID!]
  ) {
    current: getChartReportForSingleStockUnitHourly(
      dateFrom: $dateFrom
      dateTo: $dateTo
      stockUnitId: $stockUnitId
      storeIds: $storeIds
      terminalIds: $terminalIds
    ) {
      ...ChartProductReportHourly
    }
    previous: getChartReportForSingleStockUnitHourly(
      dateFrom: $dateFromPrev
      dateTo: $dateToPrev
      stockUnitId: $stockUnitId
      storeIds: $storeIds
      terminalIds: $terminalIds
    ) {
      ...ChartProductReportHourly
    }
  }
  ${fragmentChartProductReportHourly}
`;

export const fragmentChartProductReportMonthly = gql`
  fragment ChartProductReportMonthly on AnalyticsChartReport {
    revenue
    profit
    stockUnitQuantity
    date
  }
`;

export const queryGetChartReportForSingleStockUnitMonthly = gql`
  query getChartReportForSingleStockUnitMonthly(
    $dateFrom: LocalDate!
    $dateTo: LocalDate!
    $dateFromPrev: LocalDate!
    $dateToPrev: LocalDate!
    $stockUnitId: UUID!
    $storeIds: [UUID!]
    $terminalIds: [UUID!]
  ) {
    current: getChartReportForSingleStockUnitMonthly(
      dateFrom: $dateFrom
      dateTo: $dateTo
      stockUnitId: $stockUnitId
      storeIds: $storeIds
      terminalIds: $terminalIds
    ) {
      ...ChartProductReportMonthly
    }
    previous: getChartReportForSingleStockUnitMonthly(
      dateFrom: $dateFromPrev
      dateTo: $dateToPrev
      stockUnitId: $stockUnitId
      storeIds: $storeIds
      terminalIds: $terminalIds
    ) {
      ...ChartProductReportMonthly
    }
  }
  ${fragmentChartProductReportMonthly}
`;

export const queryFrequentlyBoughtTogetherProducts = gql`
  query frequentlyBoughtTogetherProducts(
    $dateFrom: LocalDate!
    $dateTo: LocalDate!
    $stockUnitId: UUID!
    $storeIds: [UUID!]
    $terminalIds: [UUID!]
  ) {
    frequentlyBoughtTogetherProducts(
      dateFrom: $dateFrom
      dateTo: $dateTo
      stockUnitId: $stockUnitId
      storeIds: $storeIds
      terminalIds: $terminalIds
    ) {
      crossingPercent
      stockUnitName
    }
  }
`;

export const queryAnalyticsWarehouseItemMoveHistoryByStockUnit = gql`
  query getAnalyticsWarehouseItemMoveHistoryByStockUnit(
    $filter: AnalyticsWarehouseItemMoveHistoryFilterInput!
    $pageRequest: PageRequestInput!
  ) {
    getAnalyticsWarehouseItemMoveHistoryByStockUnit(filter: $filter, pageRequest: $pageRequest) {
      content {
        coreItemId
        coreItemName
        date
        docNumber
        documentId
        event
        initialQuantity
        name
        quantity
        reason
        remainsQuantity
        stockUnitId
        storeId
        unit
        warehouseId
        warehouseName
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
`;

export const queryLeavingByOrderDocument = gql`
  query leavingByOrderDocument($id: UUID!) {
    leavingByOrderDocument(id: $id) {
      creationDate
      date
      description
      docNumber
      documentItems {
        name
        factQuantity
        stockUnit {
          id
          name
          unit
          primePrice {
            amountValue
            currencyUnit
          }
        }
        resultLeavingItem {
          id
          name
          unit
          quantity
          coreItem
          parentStockUnitId
        }
        amount
      }
      id
      order {
        id
        orderNumber
        store {
          name
        }
      }
      warehouse {
        name
      }
      status
      typeDocument
    }
  }
`;

export const queryOrderWriteOffProducts = gql`
  query orderWriteOffProducts($filter: OrderWriteOffProductFilter!) {
    orderWriteOffProducts(filter: $filter) {
      content {
        productName
        quantity
        unit
        products {
          id
          isRoot
          productName
          primePrice
          quantity
          unit
        }
      }
    }
  }
`;

export const queryOrderWriteOffProductsByDocId = gql`
  query orderWriteOffProductsByDocId($documentId: UUID!) {
    orderWriteOffProductsByDocId(documentId: $documentId) {
      content {
        productName
        quantity
        unit
        products {
          id
          isRoot
          productName
          primePrice
          quantity
          unit
        }
      }
    }
  }
`;

export const queryOrderWriteOffDoc = gql`
  query orderWriteOffDocById($documentId: UUID!) {
    orderWriteOffDocById(documentId: $documentId) {
      date
      description
      id
      number
      orderId
      orderNumber
      storeName
      status
      storageRoom {
        id
        name
      }
    }
  }
`;
