import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;
    if (!value) return null;

    const specialChars = /[!@#$%^&*,.?":{}|<>]/g;
    const cleanedPhoneNumber = value.replace(/[\s\-()+-]/g, '');

    const phoneIsInvalid = !specialChars.test(value) && cleanedPhoneNumber.length > 15;

    return phoneIsInvalid ? { invalidPhoneNumberV2: true } : null;
  };
}
