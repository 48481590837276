import { Pipe, PipeTransform } from '@angular/core';

import { ISO8601DurationToStringPipe } from '@app/shared/pipe/ISO8601-duration-to-string.pipe';
import { DAYS_IN_MONTH, DAYS_IN_YEAR } from '@constants';

@Pipe({
  name: 'nextPaymentDate',
})
export class NextPaymentDatePipe implements PipeTransform {
  constructor(private iso8601DurationToStringPipe: ISO8601DurationToStringPipe) {}

  transform(ISO8601Duration: string, isTrial: boolean, trialPeriodDuration: string): string {
    const nextPaymentDate = new Date();

    const stringDates = ['дня', 'дней', 'месяца', 'месяцев', 'года', 'лет'];
    const durationString = this.iso8601DurationToStringPipe.transform(ISO8601Duration);

    if (isTrial) {
      const trialPeriodDurationString = this.iso8601DurationToStringPipe.transform(trialPeriodDuration);

      if (trialPeriodDurationString.includes('день')) {
        nextPaymentDate.setDate(nextPaymentDate.getDate() + 1);

        return nextPaymentDate.toString();
      }

      nextPaymentDate.setDate(nextPaymentDate.getDate() + parseInt(trialPeriodDurationString, 10));

      return nextPaymentDate.toString();
    }

    if (durationString.includes('день')) {
      nextPaymentDate.setDate(nextPaymentDate.getDate() + 1);
    }

    if (durationString.includes('месяц')) {
      nextPaymentDate.setDate(nextPaymentDate.getDate() + DAYS_IN_MONTH);
    }

    if (durationString.includes('год')) {
      nextPaymentDate.setDate(nextPaymentDate.getDate() + DAYS_IN_YEAR);
    }

    if (stringDates.some((stringDate) => durationString.includes(stringDate))) {
      nextPaymentDate.setDate(nextPaymentDate.getDate() + parseInt(durationString, 10));
    }

    return nextPaymentDate.toString();
  }
}
