import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CSSObject } from 'highcharts';

export enum SKELETON_TYPE {
  CIRCLE = 'circle',
  RECT = 'rect',
}

export enum SKELETON_COLOR_TYPE {
  SURFACE = 'surface',
  BACKGROUND = 'background',
}

export const SKELETON_FULL_FILLING_VALUE = '100%';
export const SKELETON_FULL_FILLING_HEIGHT = '50px';

@Component({
  selector: 'nm-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonComponent {
  @Input() width: string = SKELETON_FULL_FILLING_VALUE;
  @Input() height: string = SKELETON_FULL_FILLING_VALUE;
  @Input() type: SKELETON_TYPE = SKELETON_TYPE.RECT;
  @Input() colorType: SKELETON_COLOR_TYPE = SKELETON_COLOR_TYPE.SURFACE;

  styles(): CSSObject {
    if (this.type === SKELETON_TYPE.CIRCLE) {
      if (this.height === SKELETON_FULL_FILLING_VALUE) {
        return {
          maxHeight: SKELETON_FULL_FILLING_HEIGHT,
          maxWidth: SKELETON_FULL_FILLING_HEIGHT,
        };
      }

      return {
        minWidth: this.height,
        maxWidth: this.height,
        paddingTop: this.height,
      };
    }

    return {
      maxWidth: this.width,
      maxHeight: this.height,
    };
  }

  get classes(): string {
    let classes: string[] = ['skeleton'];

    if (this.type) {
      classes.push(this.type.toLowerCase());
    }

    if (this.colorType) {
      classes.push(this.colorType.toLowerCase());
    }

    return classes.join(' ');
  }
}
