import { Observable } from '@apollo/client/core';
import { ErrorAuthorizationFunc } from '@typings';

export const errorAuthorization: ErrorAuthorizationFunc = ({ errorResponse: { operation, forward, response }, storage }) => {
  const refreshToken = storage.getRefreshToken();
  // We assume we have both tokens needed to run the async request
  if (refreshToken) {
    // Let's refresh token through async request
    return new Observable((observer) => {
      storage
        .updateTokens()
        .then(() => {
          const authorization = storage.getAuthHeader();

          operation.setContext(({ headers = {} }) => ({
            headers: {
              ...headers,
              authorization,
            },
          }));
        })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          // Retry last failed request
          forward(operation).subscribe(subscriber);
        })
        .catch((error) => {
          // No refresh or client token available, we force user to login
          observer.error(error);
          console.error('Error with re-request', error);
        });
    });
  } else {
    storage.clear();
    storage.redirectLogin('No refresh token');

    return new Observable((observer) => {
      if (response) {
        observer.next(response);
      }

      observer.complete();
    });
  }
};
