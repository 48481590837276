<nm-listing
  class="sidebar-multiselect"
  [isSelect]="(table?.selectedRows$ | async)?.length !== 0 || !!initiallySelected.length"
>
  <ng-container header>
    <nm-filters-table header [showGroupedFilters]="false" testId="categories::filters-panel" (search)="search($event)">
    </nm-filters-table>
    <nm-control-hint [label]="hint"></nm-control-hint
  ></ng-container>

  <ng-container main>
    <div *ngIf="rows?.length">
      <nm-custom-table
        #table
        testId="multiselect:table"
        [config]="tableConfig"
        [rows]="rows"
        (headerClick)="onSort($event)"
      >
        <ng-template tableColumn columnName="name" let-data="data">
          <nm-table-title [name]="data?.text" testId="title" [description]="data.description"></nm-table-title>
        </ng-template>

        <ng-template tableColumn columnName="tools" let-data="data">
          <nm-item-actions *ngIf="!data.leaveEmpty" [items]="data.items" testId="actions"></nm-item-actions>
        </ng-template>
      </nm-custom-table>
    </div>
  </ng-container>

  <nm-skeleton-template-table main [isLoading]="isLoading" [tableConfig]="tableConfig"></nm-skeleton-template-table>

  <ng-container footer *ngIf="selectService.getSelected()?.length || initiallySelected.length">
    <nm-footer-select [selectedRows]="table?.selectedRows$ | async" [selectedLabel]="getSelectedLabel()">
      <ng-container cancelSelect>
        <nm-icon-button
          variant="text"
          [disabled]="(table?.isAnyRowsSelected$ | async) === false"
          [icon]="cancelIcon"
          (clickButton)="cancelSelect()"
          nmTooltip
          TooltipContent="Отменить выбор"
          testId="products::cancel-select"
        ></nm-icon-button>
      </ng-container>
      <ng-container header-right>
        <nm-button (click)="addItems()" [loading]="buttonLoading" [disabled]="buttonLoading || !selectionChanged()">
          Применить</nm-button
        >
      </ng-container>
    </nm-footer-select>
  </ng-container>
</nm-listing>
