import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { SVG_ICONS_TYPE } from '@app/constants/svg-icons';
import { InputmaskOptions } from '@ngneat/input-mask';
import { FunctionType } from '@typings';

import { HintVariant } from '../control-hint/control-hint.component';

import { getCounterText } from './input.util';

@Component({
  selector: 'nm-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('input', { static: false }) inputElem: ElementRef;
  @ViewChild('plainText', { static: false }) plainTextElem: ElementRef;

  @Input() value: string | number | null;
  @Input() type: 'text' | 'number' | 'password' | 'tel' | 'email' = 'text';
  @Input() testId: string;
  @Input() placeholder: string = '';
  @Input() maxLength: number;
  @Input() label: string;
  @Input() showLabelIcon: boolean = false;
  @Input() hint: string;
  @Input() hintIcon: SVG_ICONS_TYPE;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() required: boolean = false;
  @Input() error: boolean;
  @Input() iconLeft: SVG_ICONS_TYPE;
  @Input() iconRight: SVG_ICONS_TYPE;
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() nativeName: string;
  @Input() inputMask: InputmaskOptions<unknown>;
  @Input() selectOnFocus: boolean = false;
  @Input() focus: boolean = false;

  @Output() inputFocus = new EventEmitter();
  @Output() inputBlur = new EventEmitter();
  @Output() rightIconClick = new EventEmitter<MouseEvent>();

  onTouched: FunctionType = () => {};
  onChange: FunctionType = () => {};

  get hintType(): HintVariant {
    return this.error ? 'error' : 'helper';
  }

  get counterText(): string {
    return getCounterText(this.value, this.maxLength);
  }

  constructor(private cdr: ChangeDetectorRef, public elRef: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.nativeName) {
      this.inputElem.nativeElement.setAttribute('name', this.nativeName);
    }
    if (this.focus) {
      this.inputElem.nativeElement.focus();
    }
  }

  writeValue(value: string | number | null): void {
    this.value = value;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: FunctionType): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: FunctionType): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  onClick() {
    if (this.readonly) {
      this.selectReadonlyText();
    } else {
      this.inputElem.nativeElement.focus();
    }
  }

  selectReadonlyText(): void {
    const range = document.createRange();
    range.selectNode(this.plainTextElem.nativeElement);

    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
  }

  selectInputText(): void {
    this.inputElem.nativeElement.select();
  }

  onRightIconClick(e: MouseEvent) {
    this.rightIconClick.emit(e);
  }

  onFocus(evt: Event): void {
    this.inputFocus.emit(evt);

    if (this.selectOnFocus) {
      this.selectInputText();
    }
  }

  onBlur(evt: Event): void {
    this.inputBlur.emit(evt);
  }

  changeVal($event: string | number): void {
    this.onChange($event.toString().trim());
  }
}
