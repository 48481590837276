import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { CustomerGroupsSortFieldStrict, GenderType, GenderTypeStrict, GenderTypeString } from '@typings';

export const CLIENT_GROUPS_SORT_FIELD: CustomerGroupsSortFieldStrict = {
  NAME: 'NAME',
  CUSTOMERS_COUNT: 'CUSTOMERS_COUNT',
};

export const GENDER_TYPE: GenderTypeStrict = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};

export const GENDER_TYPE_STRING: GenderTypeString = {
  MALE: 'Мужской',
  FEMALE: 'Женский',
};

export const GENDER_TYPE_AUTOCOMPLETE_OPTIONS: AutocompleteOption<GenderType>[] = Object.keys(GENDER_TYPE).map((genderType) => ({
  id: genderType,
  label: GENDER_TYPE_STRING[genderType],
  type: 'item',
  data: genderType,
}));

export const getGenderType = (genderType: string) => Object.keys(GENDER_TYPE).find((key) => key === genderType);
