import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { NotifyService } from '@services/shared';
import {
  ModifierGroupOutput,
  MutationAddModifiersArgs,
  MutationDeleteModifierGroupArgs,
  MutationModifierGroupAttachProductsArgs,
  MutationModifierGroupCreateArgs,
  MutationModifierGroupDetachProductsArgs,
  MutationModifierGroupUpdateArgs,
  MutationRemoveModifiersArgs,
  MutationResult,
  MutationUpdateModifierArgs,
  QueryModifierGroupByIdArgs,
  QueryModifierGroupsArgs,
  QueryModifierGroupsArgsWithModifiers,
  QueryModifierGroupWhereUsedAsModifierArgs,
  QueryResult,
} from '@typings';

import { ModifierGroupApi } from './modifierGroup.api';

@Injectable({
  providedIn: 'root',
})
export class ModifierGroupStorage {
  #activeModifierGroup = new BehaviorSubject<ModifierGroupOutput | null>(null);
  activeModifierGroup$ = this.#activeModifierGroup.asObservable();

  constructor(private modifierGroupApi: ModifierGroupApi, private notifyService: NotifyService) {}

  setActiveModifierGroup(modifierGroup: ModifierGroupOutput): void {
    this.#activeModifierGroup.next(modifierGroup);
  }

  getActiveModifierGroup(): ModifierGroupOutput {
    return this.#activeModifierGroup.getValue()!;
  }

  getModifierGroups(variables?: QueryModifierGroupsArgsWithModifiers): QueryResult<'modifierGroups'> {
    return this.modifierGroupApi.getModifierGroups(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении групп модификаторов',
      }),
    );
  }

  getModifierGroupsShort(variables: QueryModifierGroupsArgs): QueryResult<'modifierGroups'> {
    return this.modifierGroupApi.getModifierGroupsShort(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении групп модификаторов',
      }),
    );
  }

  getModifierGroupById(variables: QueryModifierGroupByIdArgs): QueryResult<'modifierGroupById'> {
    return this.modifierGroupApi.getModifierGroupById(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении группы модификаторов',
      }),
    );
  }

  getWhereUsedAsModifier(variables: QueryModifierGroupWhereUsedAsModifierArgs): QueryResult<'modifierGroupWhereUsedAsModifier'> {
    return this.modifierGroupApi.getWhereUsedAsModifier(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении групп модификаторов',
      }),
    );
  }

  createModifierGroup(variables: MutationModifierGroupCreateArgs): MutationResult<'modifierGroupCreate'> {
    return this.modifierGroupApi.createModifierGroup(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании группы модификаторов',
      }),
    );
  }

  updateModifierGroup(variables: MutationModifierGroupUpdateArgs): MutationResult<'modifierGroupUpdate'> {
    return this.modifierGroupApi.updateModifierGroup(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении группы модификаторов',
      }),
    );
  }

  deleteModifierGroup(variables: MutationDeleteModifierGroupArgs): MutationResult<'deleteModifierGroup'> {
    return this.modifierGroupApi.deleteModifierGroup(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении группы модификаторов',
        success: 'Группа модификаторов успешно удалена',
      }),
    );
  }

  attachProductsToModifierGroup(variables: MutationModifierGroupAttachProductsArgs): MutationResult<'modifierGroupAttachProducts'> {
    return this.modifierGroupApi.attachProductsToModifierGroup(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при добавлении продуктов к группе модификаторов',
      }),
    );
  }

  detachProductsFromModifierGroup(variables: MutationModifierGroupDetachProductsArgs): MutationResult<'modifierGroupDetachProducts'> {
    return this.modifierGroupApi.detachProductsFromModifierGroup(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении продуктов из группы модификаторов',
        success: 'Продукты из группы модификаторов успешно удалены',
      }),
    );
  }

  addModifiers(variables: MutationAddModifiersArgs): MutationResult<'addModifiers'> {
    return this.modifierGroupApi.addModifiers(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при добавлении модификатора в группу',
      }),
    );
  }

  updateModifier(variables: MutationUpdateModifierArgs): MutationResult<'updateModifier'> {
    return this.modifierGroupApi.updateModifier(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении модификатора',
      }),
    );
  }

  removeModifiers(variables: MutationRemoveModifiersArgs): MutationResult<'removeModifiers'> {
    return this.modifierGroupApi.removeModifiers(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении модификаторов из группы',
        success: 'Модификатор успешно удален из группы',
      }),
    );
  }
}
