import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FunctionType } from '@typings';

@Component({
  selector: 'nm-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent implements ControlValueAccessor {
  @Input() disabled: boolean = false;
  @Input() isIcon: boolean = false;
  @Input() testId: string;
  @Input()
  set isChecked(value: boolean) {
    this.checked = value;
  }
  checked: boolean = false;

  onTouched: FunctionType = () => '';
  onChange: FunctionType = () => '';

  constructor(private cdr: ChangeDetectorRef) {}

  writeValue(checked: boolean): void {
    this.checked = checked;
  }

  registerOnChange(fn: FunctionType): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: FunctionType): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;

    this.cdr.detectChanges();
  }

  onAnimation(switchElement: HTMLLabelElement): void {
    const rootClassName = 'nm-switch';

    switchElement.classList.remove(`${rootClassName}--off`);
    switchElement.classList.add(`${rootClassName}--on`);

    this.onChange(true);

    setTimeout(() => {
      switchElement.classList.remove(`${rootClassName}--on`);

      this.checked = true;

      this.cdr.detectChanges();
    }, 1000);
  }

  offAnimation(switchElement: HTMLLabelElement): void {
    const rootClassName = 'nm-switch';

    switchElement.classList.remove(`${rootClassName}--on`);
    switchElement.classList.add(`${rootClassName}--off`);

    this.checked = false;
    this.onChange(this.checked);

    setTimeout(() => {
      switchElement.classList.remove(`${rootClassName}--off`);

      this.cdr.detectChanges();
    }, 1000);
  }
}
