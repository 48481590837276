import { ConnectedPosition, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { BreakpointObserverService } from '@services/core';

@Component({
  selector: 'nm-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent implements AfterViewInit, OnDestroy {
  @ViewChild('overlayContent') overlayContent: TemplateRef<unknown>;
  @ViewChild('labelEl') labelEl: ElementRef;
  @ViewChild('container', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;
  resizeDropdown$ = new BehaviorSubject([] as ResizeObserverEntry[]);
  dropdownElObserver: MutationObserver;
  dropdownElement: Element | null;

  @Input() initView: TemplateRef<unknown>;
  @Input() isOpen = false;
  @Input() disabled = false;
  @Input() positions: ConnectedPosition[] = [
    { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
    { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
    { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
    { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' },
  ];

  @Output() menuClosed = new EventEmitter<boolean>();
  @Output() menuOpened = new EventEmitter<boolean>();

  destroy$ = new Subject<void>();
  currentView$ = new BehaviorSubject<TemplateRef<unknown> | null>(null);
  close$ = new Subject<boolean>();

  overlayRef: OverlayRef;
  isMobile = this.bos.getIsMobile();

  constructor(private overlay: Overlay, private bos: BreakpointObserverService) {}

  ngAfterViewInit(): void {
    if (this.initView) {
      this.setView(this.initView);
    }
  }

  openOverlay(event?: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
    if (this.disabled) return;
    const config: OverlayConfig = new OverlayConfig({
      positionStrategy: this.overlay.position().flexibleConnectedTo(this.labelEl).withPositions(this.positions).withGrowAfterOpen(true),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });

    this.overlayRef = this.overlay.create(config);
    this.overlayRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => {
        this.close();
      });

    this.overlayRef
      .attachments()
      .pipe(take(1))
      .subscribe(() => {
        setTimeout(() => {
          this.dropdownElement = this.overlayRef.overlayElement.querySelector('.dropdown-menu-panel');
          this.adjustOverlayPosition(this.dropdownElement);
          this.observeOverlayElement(this.dropdownElement);
        });
      });

    setTimeout(() => {
      this.overlayRef.attach(new TemplatePortal(this.overlayContent, this.viewContainerRef));
      this.menuOpened.emit(true);
    });
  }

  adjustOverlayPosition(dropdownElement: Element | null): void {
    if (this.overlayRef.hasAttached()) {
      const isElementHaveHorizontalScrollbar = (dropdownElement?.scrollWidth || 0) > (dropdownElement?.clientWidth || 0);
      const isElementHaveVerticalScrollbar = (dropdownElement?.scrollHeight || 0) > (dropdownElement?.clientHeight || 0);
      if (isElementHaveHorizontalScrollbar || isElementHaveVerticalScrollbar) {
        this.overlayRef.addPanelClass('overflow-panel');
      }
    }
  }

  observeOverlayElement(targetNode: Element | null) {
    if (!targetNode) {
      return;
    }
    const config = { childList: true, subtree: true };
    const callback = () => {
      this.adjustOverlayPosition(this.dropdownElement);
    };

    this.dropdownElObserver = new MutationObserver(callback);
    this.dropdownElObserver.observe(targetNode, config);
  }

  close(): void {
    this.overlayRef?.dispose();
    this.menuClosed.emit(true);
    this.close$.next(true);
    this.dropdownElObserver?.disconnect();
  }

  setView(view: TemplateRef<unknown> | null) {
    this.currentView$.next(view);
  }

  getView() {
    return this.currentView$.getValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
