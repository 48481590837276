import { Injectable } from '@angular/core';

import { NotifyService } from '@services/shared';

import { ProductApi } from './product.api';
import { ProductStorage } from './product.storage';

@Injectable({
  providedIn: 'root',
})
export class SemimanufacturesStorage extends ProductStorage {
  constructor(protected notifyService: NotifyService, protected productApi: ProductApi) {
    super(notifyService, productApi);
    this.productType = 'SEMIPRODUCT';
  }
}
