import { ApolloLink, GraphQLRequest } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AUTH_TOKEN_OPERATION_EXCEPTIONS, HTTP_HEADER } from '@constants';
import { AuthLinkProps, Context } from '@typings';

export const createOrganizationLink = ({ storage }: AuthLinkProps): ApolloLink => {
  return setContext(async ({ operationName }: GraphQLRequest, defaultContext) => {
    const organizationId = storage.getOrgId();
    const context = defaultContext as Context;

    if ((operationName && AUTH_TOKEN_OPERATION_EXCEPTIONS.includes(operationName)) || !organizationId) {
      return context;
    }

    const { headers } = context;

    return {
      headers: {
        ...headers,
        [HTTP_HEADER.ORGANIZATION]: organizationId,
      },
    };
  });
};
