import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';
import { SVG_ICONS_TYPE } from '@constants';
import { ColumnBaseComponent } from '@typings';

@Component({
  selector: 'nm-item-actions',
  templateUrl: './item-actions.component.html',
  styleUrls: ['./item-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemActionsComponent extends ColumnBaseComponent {
  @Input() items: MenuItem[];
  @Input() testId: string;

  constructor() {
    super();
  }

  menuIcon: SVG_ICONS_TYPE = 'moreHoriz';
}
