import { gql } from 'apollo-angular';

export const queryImageById = gql`
  query imageById($id: UUID!) {
    imageById(id: $id) {
      id
      originalFileName
      extension
      imageSizes {
        width
        height
        url
      }
    }
  }
`;

export const fragmentAccount = gql`
  fragment AccountMain on Account {
    id
    active
    userId
    firstName
    lastName
    middleName
    onboarded
    pin
    userId
    imageId
    roles
    hasPassword
    supportAssistant
    employees {
      active
      id
      userGroups {
        id
        name
        code
        storeId
        userGroupId
      }
      organization {
        id
        active
        name
        shortOrgId
        settings {
          currency
          currencyUnit
        }
      }
    }
    identities {
      id
      value
      identityType
    }
  }
`;

export const queryAccount = gql`
  query Account($id: UUID!) {
    account(id: $id) {
      ...AccountMain
    }
  }
  ${fragmentAccount}
`;

export const queryRole = gql`
  query Account($id: UUID!) {
    account(id: $id) {
      roles
    }
  }
`;

export const mutationUpdateAccount = gql`
  mutation updateAccount($accountInput: AccountInput!) {
    updateAccount(accountInput: $accountInput)
  }
`;

export const mutationChangeAccountPassword = gql`
  mutation changeAccountPassword($changePasswordInput: ChangePasswordInput!) {
    changeAccountPassword(changePasswordInput: $changePasswordInput)
  }
`;

export const mutationClaimIdentity = gql`
  mutation claimIdentity($value: String!) {
    claimIdentity(value: $value)
  }
`;

export const mutationClaimSendConfirmation = gql`
  mutation identityClaimSendConfirmation($identityClaimId: UUID!) {
    identityClaimSendConfirmation(identityClaimId: $identityClaimId)
  }
`;

export const mutationIdentityClaimConfirm = gql`
  mutation identityClaimConfirm($temporaryTokenId: UUID!, $code: String!) {
    identityClaimConfirm(temporaryTokenId: $temporaryTokenId, code: $code)
  }
`;

export const mutationIdentityRemove = gql`
  mutation identityRemove($id: UUID!) {
    identityRemove(id: $id) {
      id
    }
  }
`;
