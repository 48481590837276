import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

import { AvatarModule } from '@app/shared/component/avatar/avatar.module';
import { CheckboxModule } from '@app/shared/component/checkbox/checkbox.module';
import { RadioModule } from '@app/shared/component/radio/radio.module';
import { SvgIconModule } from '@app/shared/component/svg-icon/svg-icon.module';

import { MenuItemComponent } from './menu-item.component';

@NgModule({
  declarations: [MenuItemComponent],
  imports: [CommonModule, MatMenuModule, SvgIconModule, CheckboxModule, AvatarModule, RadioModule],
  exports: [MenuItemComponent],
})
export class MenuItemModule {}
