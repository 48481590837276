import { gql } from 'apollo-angular';

export const queryProductForReport = gql`
  query product($id: UUID!) {
    product(id: $id) {
      id
      name
      type
      stockUnits {
        id
      }
    }
  }
`;

export const queryProductsReportLinkedProducts = gql`
  query productsReportLinkedProducts($input: ProductsReportLinkedProductsInput!, $pageRequestInput: PageRequestInput) {
    productsReportLinkedProducts(input: $input) {
      total {
        revenue
        prime
        discount
        profit
      }
      rows(pageRequest: $pageRequestInput) {
        content {
          productName
          productType
          categories {
            id
            name
          }
          sales
          revenue
          prime
          discount
          profit
          unitType
        }
        pageNumber
        pageSize
        total
        totalPages
      }
    }
  }
`;

export const queryProductsReportLinkedProductsTimeSeries = gql`
  query productsReportLinkedProductsTimeSeries(
    $input: ProductsReportLinkedProductsInput!
    $timeSeriesInput: TimeSeriesInput!
    $revenue: Boolean!
    $prime: Boolean!
    $profit: Boolean!
    $discount: Boolean!
  ) {
    productsReportLinkedProductsTimeSeries(input: $input, timeSeriesInput: $timeSeriesInput) {
      elements {
        prime @include(if: $prime)
        profit @include(if: $profit)
        revenue @include(if: $revenue)
        discount @include(if: $discount)
        timestamp
      }
    }
  }
`;
