export enum ClientDeviceType {
  DESKTOP = 'DESKTOP',
  TABLET = 'TABLET',
  MOBILE = 'MOBILE',
}

export const DeviceWidthPoints = {
  desktop: 1025,
  tablet: 768,
  mobile: 320,
};
