import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DragAndDropHandlerDirective } from './drag-and-drop-handler.directive';
import { DragAndDropItemDirective } from './drag-and-drop-item.directive';
import { DragAndDropListDirective } from './drag-and-drop-list.directive';
import { DragAndDropListService } from './drag-and-drop-list.service';

@NgModule({
  declarations: [DragAndDropListDirective, DragAndDropItemDirective, DragAndDropHandlerDirective],
  imports: [CommonModule],
  providers: [DragAndDropListService],
  exports: [DragAndDropListDirective, DragAndDropItemDirective, DragAndDropHandlerDirective],
})
export class DragAndDropModule {}
