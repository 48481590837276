import { Injectable } from '@angular/core';

import {
  mutationConfirmMoveDoc,
  mutationConfirmMoveDocument,
  mutationCreateMoveDoc,
  mutationCreateMoveDocument,
  mutationDeleteMoveDoc,
  mutationDeleteMoveDocument,
  mutationDuplicateMoveDocument,
  mutationRejectMoveDocument,
  mutationRevertMoveDoc,
  mutationUpdateMoveDoc,
  mutationUpdateMoveDocument,
  queryAllMoveDocumentsPageable,
  queryMoveDoc,
  queryMoveDocs,
  queryMoveDocument,
  queryMoveProducts,
} from '@api';
import { ApiService } from '@core/service';
import {
  Mutation,
  MutationConfirmMoveDocArgs,
  MutationConfirmMoveDocumentV2Args,
  MutationCreateMoveDocArgs,
  MutationCreateMoveDocumentArgs,
  MutationDeleteMoveDocArgs,
  MutationDeleteMoveDocumentArgs,
  MutationDuplicateMoveDocumentArgs,
  MutationRejectMoveDocumentArgs,
  MutationResult,
  MutationRevertMoveDocArgs,
  MutationUpdateMoveDocArgs,
  MutationUpdateMoveDocumentArgs,
  Query,
  QueryAllMoveDocumentsPageableV2Args,
  QueryMoveDocArgs,
  QueryMoveDocsArgs,
  QueryMoveDocumentArgs,
  QueryMoveProductsArgs,
  QueryResult,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class MovingsApi {
  constructor(private apiService: ApiService) {}

  allMovingsPageable(variables: QueryAllMoveDocumentsPageableV2Args): QueryResult<'allMoveDocumentsPageableV2'> {
    return this.apiService.query<Query<'allMoveDocumentsPageableV2'>, QueryAllMoveDocumentsPageableV2Args>({
      query: queryAllMoveDocumentsPageable,
      variables,
    });
  }

  moving(variables: QueryMoveDocumentArgs): QueryResult<'moveDocument'> {
    return this.apiService.query<Query<'moveDocument'>, QueryMoveDocumentArgs>({
      query: queryMoveDocument,
      variables,
    });
  }

  createMoving(variables: MutationCreateMoveDocumentArgs): MutationResult<'createMoveDocument'> {
    return this.apiService.mutate<Mutation<'createMoveDocument'>, MutationCreateMoveDocumentArgs>({
      mutation: mutationCreateMoveDocument,
      variables,
    });
  }

  updateMoving(variables: MutationUpdateMoveDocumentArgs): MutationResult<'updateMoveDocument'> {
    return this.apiService.mutate<Mutation<'updateMoveDocument'>, MutationUpdateMoveDocumentArgs>({
      mutation: mutationUpdateMoveDocument,
      variables,
    });
  }

  deleteMoving(variables: MutationDeleteMoveDocumentArgs): MutationResult<'deleteMoveDocument'> {
    return this.apiService.mutate<Mutation<'deleteMoveDocument'>, MutationDeleteMoveDocumentArgs>({
      mutation: mutationDeleteMoveDocument,
      variables,
    });
  }

  confirmMoving(variables: MutationConfirmMoveDocumentV2Args): MutationResult<'confirmMoveDocumentV2'> {
    return this.apiService.mutate<Mutation<'confirmMoveDocumentV2'>, MutationConfirmMoveDocumentV2Args>({
      mutation: mutationConfirmMoveDocument,
      variables,
    });
  }

  rejectMoving(variables: MutationRejectMoveDocumentArgs): MutationResult<'rejectMoveDocument'> {
    return this.apiService.mutate<Mutation<'rejectMoveDocument'>, MutationRejectMoveDocumentArgs>({
      mutation: mutationRejectMoveDocument,
      variables,
    });
  }

  duplicateMoving(variables: MutationDuplicateMoveDocumentArgs): MutationResult<'duplicateMoveDocument'> {
    return this.apiService.mutate<Mutation<'duplicateMoveDocument'>, MutationDuplicateMoveDocumentArgs>({
      mutation: mutationDuplicateMoveDocument,
      variables,
    });
  }

  moveDocProducts(variables: QueryMoveProductsArgs): QueryResult<'moveProducts'> {
    return this.apiService.query<Query<'moveProducts'>, QueryMoveProductsArgs>({
      query: queryMoveProducts,
      variables,
    });
  }

  moveDocs(variables: QueryMoveDocsArgs): QueryResult<'moveDocs'> {
    return this.apiService.query<Query<'moveDocs'>, QueryMoveDocsArgs>({
      query: queryMoveDocs,
      variables,
    });
  }

  moveDoc(variables: QueryMoveDocArgs): QueryResult<'moveDoc'> {
    return this.apiService.query<Query<'moveDoc'>, QueryMoveDocArgs>({
      query: queryMoveDoc,
      variables,
    });
  }

  createMoveDoc(variables: MutationCreateMoveDocArgs): MutationResult<'createMoveDoc'> {
    return this.apiService.mutate<Mutation<'createMoveDoc'>, MutationCreateMoveDocArgs>({
      mutation: mutationCreateMoveDoc,
      variables,
    });
  }

  updateMoveDoc(variables: MutationUpdateMoveDocArgs): MutationResult<'updateMoveDoc'> {
    return this.apiService.mutate<Mutation<'updateMoveDoc'>, MutationUpdateMoveDocArgs>({
      mutation: mutationUpdateMoveDoc,
      variables,
    });
  }

  confirmMoveDoc(variables: MutationConfirmMoveDocArgs): MutationResult<'confirmMoveDoc'> {
    return this.apiService.mutate<Mutation<'confirmMoveDoc'>, MutationConfirmMoveDocArgs>({
      mutation: mutationConfirmMoveDoc,
      variables,
    });
  }

  revertMoveDoc(variables: MutationRevertMoveDocArgs): MutationResult<'revertMoveDoc'> {
    return this.apiService.mutate<Mutation<'revertMoveDoc'>, MutationRevertMoveDocArgs>({
      mutation: mutationRevertMoveDoc,
      variables,
    });
  }

  deleteMoveDoc(variables: MutationDeleteMoveDocArgs): MutationResult<'deleteMoveDoc'> {
    return this.apiService.mutate<Mutation<'deleteMoveDoc'>, MutationDeleteMoveDocArgs>({
      mutation: mutationDeleteMoveDoc,
      variables,
    });
  }
}
