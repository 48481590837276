<nm-modal-base
  okVariant="filled"
  [cancelTitle]="'Отменить'"
  [okTitle]="'Применить'"
  (cancel)="storesService.cancelModal()"
  (ok)="storesService.okModal()"
>
  <div modal-base-title>Редактирование группы столов</div>
  <div modal-base-content-container>
    <div class="table-groups-modal">
      <nm-input
        [formControl]="tablesEditGroupFormControls?.name"
        [required]="true"
        [maxLength]="150"
        [error]="validationErrorsService.isControlDirtyAndInvalid(tablesEditGroupFormControls?.name)"
        [hint]="validationErrorsService.validateField(tablesEditGroupFormControls?.name)"
        label="Название группы"
      ></nm-input>
      <div class="tables-group-switcher">
        <div class="description">
          <div class="title">Отображение группы в POS</div>
          <div>Настройка видимости группы столов в POS-терминале</div>
        </div>
        <div>
          <nm-switch [formControl]="tablesEditGroupFormControls?.showTables"></nm-switch>
        </div>
      </div>
    </div>
  </div>
</nm-modal-base>
