import { Pipe, PipeTransform } from '@angular/core';

import { DocumentItem } from '@typings';

@Pipe({
  name: 'sumDocumentItemsAmount',
})
export class SumDocumentItemsAmountPipe implements PipeTransform {
  transform(documentItems: DocumentItem[] = []): number {
    return (documentItems || []).reduce((total, item) => {
      return total + (item.amount || 0);
    }, 0);
  }
}
