import { Injectable } from '@angular/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotifyService } from '@services/shared';
import {
  MutationArchiveStorageRoomArgs,
  MutationCreateStorageRoomArgs,
  MutationCreateWarehouseV2Args,
  MutationResult,
  MutationUnarchiveStorageRoomArgs,
  MutationUpdateStorageRoomArgs,
  MutationUpdateWarehouseV2Args,
  QueryAllWarehouseItemsPageableArgs,
  QueryAllWarehousesPageableArgs,
  QueryCountWarehouseItemsWrittenOffReportByWarehouseArgs,
  QueryGetWarehouseItemsWrittenOffReportByWarehouseArgs,
  QueryResult,
  QueryStorageRoomArgs,
  QueryStorageRoomsArgs,
  QueryWarehouseArgs,
  QueryWarehouseStockUnitsIdsArgs,
  WarehouseItemWrittenOffReport,
} from '@typings';

import { WarehouseApi } from './warehouse.api';

@Injectable({
  providedIn: 'root',
})
export class WarehouseStorage {
  constructor(private notifyService: NotifyService, private warehouseApi: WarehouseApi) {}

  getWarehouse(variables: QueryWarehouseArgs): QueryResult<'warehouse'> {
    return this.warehouseApi.warehouse(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении склада',
      }),
    );
  }

  createWarehouse(variables: MutationCreateWarehouseV2Args): MutationResult<'createWarehouseV2'> {
    return this.warehouseApi.createWarehouseV2(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании склада',
      }),
    );
  }

  updateWarehouse(variables: MutationUpdateWarehouseV2Args): MutationResult<'updateWarehouseV2'> {
    return this.warehouseApi.updateWarehouseV2(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении склада',
      }),
    );
  }

  allWarehousesPageable(variables: QueryAllWarehousesPageableArgs): QueryResult<'allWarehousesPageable'> {
    return this.warehouseApi.allWarehousesPageable(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка складов',
      }),
    );
  }

  allWarehousesPageableShort(variables: QueryAllWarehousesPageableArgs): QueryResult<'allWarehousesPageable'> {
    return this.warehouseApi.allWarehousesPageableShort(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка складов',
      }),
    );
  }

  getWarehousesTotalNumber(variables: QueryAllWarehousesPageableArgs): QueryResult<'allWarehousesPageable'> {
    return this.warehouseApi.getWarehousesTotalNumber(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка складов',
      }),
    );
  }

  getWarehouseItems(variables: QueryAllWarehouseItemsPageableArgs): QueryResult<'allWarehouseItemsPageable'> {
    return this.warehouseApi.allWarehouseItemsPageable(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка товаров на складе',
      }),
    );
  }

  getWarehouseStockUnitsIds(variables: QueryWarehouseStockUnitsIdsArgs): QueryResult<'warehouseStockUnitsIds'> {
    return this.warehouseApi.warehouseStockUnitsIds(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка товаров на складе',
      }),
    );
  }

  wareHouseItemsWrittenOffReportExist(): Observable<boolean> {
    return this.warehouseApi.wareHouseItemsWrittenOffReportExist().pipe(map((res) => res.data.warehouseItemsWrittenOffReportsExist));
  }

  getWarehouseItemsWrittenOffReportByWarehouse(
    variables: QueryGetWarehouseItemsWrittenOffReportByWarehouseArgs,
  ): Observable<Maybe<WarehouseItemWrittenOffReport>[]> {
    return this.warehouseApi
      .getWarehouseItemsWrittenOffReportByWarehouse(variables)
      .pipe(map((res) => res.data.getWarehouseItemsWrittenOffReportByWarehouse));
  }

  getCountWarehouseItemsWrittenOffReportByWarehouse(
    variables: QueryCountWarehouseItemsWrittenOffReportByWarehouseArgs,
  ): Observable<Maybe<string> | undefined> {
    return this.warehouseApi
      .getCountWarehouseItemsWrittenOffReportByWarehouse(variables)
      .pipe(map((res) => res.data.countWarehouseItemsWrittenOffReportByWarehouse));
  }

  storageRoom(variables: QueryStorageRoomArgs): QueryResult<'storageRoom'> {
    return this.warehouseApi.storageRoom(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении склада',
      }),
    );
  }

  createStorageRoom(variables: MutationCreateStorageRoomArgs): MutationResult<'createStorageRoom'> {
    return this.warehouseApi.createStorageRoom(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании склада',
      }),
    );
  }

  updateStorageRoom(variables: MutationUpdateStorageRoomArgs): MutationResult<'updateStorageRoom'> {
    return this.warehouseApi.updateStorageRoom(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении склада',
      }),
    );
  }

  archiveStorageRoom(variables: MutationArchiveStorageRoomArgs): MutationResult<'archiveStorageRoom'> {
    return this.warehouseApi.archiveStorageRoom(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении склада',
      }),
    );
  }

  unarchiveStorageRoom(variables: MutationUnarchiveStorageRoomArgs): MutationResult<'unarchiveStorageRoom'> {
    return this.warehouseApi.unarchiveStorageRoom(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении склада',
      }),
    );
  }

  storageRooms(variables: QueryStorageRoomsArgs): QueryResult<'storageRooms'> {
    return this.warehouseApi.storageRooms(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка складов',
      }),
    );
  }
}
