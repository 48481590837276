import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DialogModule } from '@app/shared/component/dialog/dialog.module';
import { ModalBaseModule } from '@app/shared/component/dialog/modal-base/modal-base.module';
import { FormConfirmSaveService } from '@core/service';

import { FormConfirmSaveComponent } from './component/form-confirm-save.component';
import { FormConfirmSaveAbstract } from './abstract';

@NgModule({
  imports: [CommonModule, DialogModule, ModalBaseModule],
  declarations: [FormConfirmSaveComponent],
  providers: [
    {
      provide: FormConfirmSaveAbstract,
      useClass: FormConfirmSaveService,
    },
  ],
})
export class FormConfirmSaveModule {}
