import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';

import { AccountStorage } from '@services/core';
import { NotifyService } from '@services/shared';

@Injectable()
export class AccessGuard implements CanActivate {
  constructor(private accountStorage: AccountStorage, private notifyService: NotifyService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const module = route.data.module;

    return this.accountStorage.roles.pipe(
      skipWhile((result) => !result.length),
      take(1),
      map((roles: string[]) => {
        if (roles.includes('owner')) {
          return true;
        }

        const access = roles.includes(module) || roles.includes(module + '.read');
        if (!access) {
          this.notifyService.addNotification({
            type: 'alert',
            title: 'Нет доступа',
          });
        }

        return access;
      }),
    );
  }
}
