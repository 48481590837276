<nm-modal-base
  (cancel)="closeForm(false)"
  (ok)="closeForm(true)"
  [okTitle]="btnTitle"
  okVariant="filled"
  [cancelTitle]="'Отменить'"
  [okDisabled]="form.invalid"
  testId="menu::edit-item-modal"
>
  <div modal-base-title>{{ title }}</div>

  <ng-container *ngIf="form">
    <nm-form [form]="form" class="form nm-edit-menu-form">
      <div class="nm-input-image">
        <img *ngIf="itemImage; else itemIcon" [src]="itemImage" class="image" alt="photo." />

        <ng-template #itemIcon>
          <nm-svg-icon [size]="24" name="image"></nm-svg-icon>
        </ng-template>
      </div>
      <nm-autocomplete
        ngDefaultControl
        label="Родительская категория"
        placeholder="Выберите родительскую категорию"
        [search]="true"
        [formControl]="subsection"
        [error]="validationErrorsService.isControlDirtyAndInvalid(subsection)"
        [hint]="validationErrorsService.validateField(subsection) || subsection.getError('subsection')"
        [searchFn]="autocompleteTreeService.searchFn"
        [getSelectedOptionFn]="autocompleteTreeService.getSelectedOptionFn"
        [options]="autocompleteTreeService.options$"
        [autocompleteTreeService]="autocompleteTreeService"
        collapseChipsLabel="PRODUCTS_AND_CATEGORIES"
      ></nm-autocomplete>

      <nm-autocomplete
        ngDefaultControl
        label="Товар в каталоге"
        placeholder="Выберите товар"
        [required]="true"
        [formControl]="product"
        [error]="isProductError"
        [hint]="isProductError ? 'Обязательное поле' : ''"
        [options]="productOptions$"
        [returnObjects]="true"
        testId="product"
      ></nm-autocomplete>

      <nm-input
        label="Название позиции в меню"
        placeholder="Введите название"
        ngDefaultControl
        [required]="true"
        [formControl]="name"
        [maxLength]="MAX_CHARACTERS.PRODUCT_NAME"
        [error]="validationErrorsService.isControlDirtyAndInvalid(name)"
        [hint]="validationErrorsService.validateField(name) || name.getError('name')"
        testId="name"
      ></nm-input>
    </nm-form>
  </ng-container>
</nm-modal-base>
