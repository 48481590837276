import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { BaseModalComponent } from '@nomia/nomia-ui-lib';

import { UserRoleEnum } from '@constants';
import { InvitationStorage } from '@services/auth';
import { AccountStorage } from '@services/core';
import { RedirectService } from '@services/shared';
import { CoreSchema, InvitationAcceptModalData } from '@typings';

@Component({
  selector: 'nm-invitation-modal',
  templateUrl: './invitation-modal.component.html',
  styleUrls: ['./invitation-modal.component.scss'],
})
export class InvitationModalComponent extends BaseModalComponent implements OnDestroy {
  @ViewChild('wrapper')
  wrapper: ElementRef<HTMLDivElement>;

  hasError: boolean = false;
  isRedirect: boolean = true;

  isOnboarded: boolean | null = null;

  totalOrganizations: number;

  _data: InvitationAcceptModalData;
  invitation: CoreSchema.Invitation | null = null;

  organization: CoreSchema.InvitationOrganizationInfo | null = null;
  employeeRoles: CoreSchema.EmployeeInvitationRole[] = [];
  isInviteAdminRole: boolean = false;

  onDestroy = new Subject<void>();

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  constructor(
    private redirectService: RedirectService,
    private invitationStorage: InvitationStorage,
    private accountStorage: AccountStorage,
  ) {
    super();

    this.accountStorage.organizations.pipe(takeUntil(this.onDestroy)).subscribe((organizations) => {
      this.totalOrganizations = organizations?.length || 0;
    });

    this.accountStorage.account.pipe(takeUntil(this.onDestroy)).subscribe((_) => {
      // const { onboarded } = account || {};
      //
      // if (onboarded !== undefined) {
      //   this.isOnboarded = onboarded;
      //
      //   if (onboarded === true && !this.invitationStorage.getInvitation() && this.isRedirect && this.organization?.id) {
      //     this.redirectService.redirectToAnalytics(this.organization?.id);
      //   }
      // }
    });

    this.invitationStorage.invitation
      .pipe(
        filter((result) => !!result),
        takeUntil(this.onDestroy),
      )
      .subscribe((invitation) => {
        const { employeeRoles, organizationInfo } = invitation || {};

        if (employeeRoles) {
          this.employeeRoles = employeeRoles;

          if (employeeRoles.find((role) => role.roleCode === UserRoleEnum.ADMIN)) {
            this.isInviteAdminRole = true;
          }
        }

        if (organizationInfo) {
          this.organization = organizationInfo;

          // remove active organization to avoid bug with subscriptions
          this.accountStorage.removeOrganization();
        }
      });

    this.invitationStorage.hasError.pipe(takeUntil(this.onDestroy)).subscribe((hasError) => {
      if (hasError === true || hasError === false) {
        this.hasError = hasError;
      }
    });
  }

  acceptInvitation(): void {
    this.invitationStorage
      .acceptInvitation()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((res) => {
        const { errors } = res;

        if (errors) {
          this.hasError = true;
          this.invitationStorage.removeInvitation();
        } else {
          if (this.isOnboarded === false) {
            this.accountStorage
              .updateAccount({ onboarded: true })
              .pipe(takeUntil(this.onDestroy))
              .subscribe(() => {
                this.invitationStorage.removeInvitation();
              });
          } else {
            this.accountStorage.fetchAccount();
            this.invitationStorage.removeInvitation();
          }

          this.close();
        }
      });
  }

  declineInvitation(): void {
    this.invitationStorage.removeInvitation();
    this.close();
  }

  checkRedirectOnAccept(): void {
    this.isRedirect = !this.isRedirect;
  }
}
