import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { map, Observable, take } from 'rxjs';

import { SubscriptionsService } from '@services/settings';
import { Bill } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class RecurrentBillResolver implements Resolve<Bill> {
  constructor(private subscriptionsService: SubscriptionsService) {}

  resolve(): Observable<Bill> {
    return this.subscriptionsService.getBillList({ page: 0, size: 1 }).pipe(
      take(1),
      map((bills) => bills.content[0]),
    );
  }
}
