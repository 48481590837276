import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { SubscriptionsService } from '@services/settings';
import { Subscriber } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class SubscriberResolver implements Resolve<Subscriber> {
  constructor(private subscriptionsService: SubscriptionsService) {}

  resolve(): Observable<Subscriber> {
    return this.subscriptionsService.getSubscriber();
  }
}
