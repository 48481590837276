import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';

import { ControlLabelModule } from '@app/shared/component/control-label/control-label.module';
import { CreateProductDialogModule } from '@app/shared/component/create-product-dialog/create-product-dialog.module';
import { DatepickerModule } from '@app/shared/component/datepicker/datepicker.module';
import { InputFileModule } from '@app/shared/component/input-file/input-file.module';
import { MenuModule } from '@app/shared/component/menu/menu.module';
import { DragAndDropModule } from '@app/shared/directive/drag-and-drop/drag-and-drop.module';
import { TooltipModule } from '@app/shared/directive/tooltip/tooltip.module';
import { FormConfirmSaveModule } from '@core/service/form-confirm-save/form-confirm-save.module';

import { IconButtonModule } from '../icon-button/icon-button.module';
import { ProgressSpinnerModule } from '../progress-spinner/progress-spinner.module';
import { SidebarMultiselectModule } from '../sidebar-multiselect-layout/sidebar-multiselect.module';

import { TableSidebarLayoutComponent } from './table-sidebar-layout.component';

@NgModule({
  declarations: [TableSidebarLayoutComponent],
  imports: [
    CommonModule,
    DatepickerModule,
    ControlLabelModule,
    InputFileModule,
    MatTabsModule,
    DragAndDropModule,
    ProgressSpinnerModule,
    IconButtonModule,
    TooltipModule,
    MenuModule,
    MatMenuModule,
    FormConfirmSaveModule,
    CreateProductDialogModule,
    SidebarMultiselectModule,
  ],
  exports: [TableSidebarLayoutComponent],
})
export class TableSidebarLayoutModule {}
