import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { Row } from '@app/shared/component/table/table.types';

@Injectable({
  providedIn: 'root',
})
export class SelectInTableService {
  tableInited = new Subject<void>();
  constructor() {}

  selectedRow = new BehaviorSubject<Row[]>([]);

  getSelected() {
    return this.selectedRow.getValue();
  }

  setSelected(rows: Row[]) {
    return this.selectedRow.next(rows);
  }
}
