import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { DragAndDropItemDirective } from './drag-and-drop-item.directive';
import { DragAndDropListDirective } from './drag-and-drop-list.directive';

@Injectable()
export class DragAndDropListService {
  list$: BehaviorSubject<DragAndDropListDirective | null> = new BehaviorSubject<DragAndDropListDirective | null>(null);
  items$: BehaviorSubject<DragAndDropItemDirective[]> = new BehaviorSubject<DragAndDropItemDirective[]>([]);
  drop: Subject<void> = new Subject<void>();
}
