<div [ngClass]="layoutClasses">
  <nm-layout-loader></nm-layout-loader>

  <nm-alert-banner
    *ngIf="showSubscriptionAlert"
    [type]="subscriptionAlertType"
    [title]="subscriptionAlertTitle"
    [redirectPage]="true"
    (clickRedirect)="subscriptionAlertsService.clickToSubscription()"
    linkText="Продлить подписку"
  ></nm-alert-banner>

  <mat-sidenav-container autosize [hasBackdrop]="false">
    <mat-sidenav
      [style.width]="(sidenavService.sidenavState$ | async).isMobile ? '100%' : ''"
      [style.border-radius]="(sidenavService.sidenavState$ | async).isMobile ? '0' : '0 16px 16px 0'"
      [mode]="(sidenavService.sidenavState$ | async).mode"
      [opened]="(sidenavService.sidenavState$ | async).isOpen"
      (click)="clickOnSidenav()"
      (mouseleave)="setExpandedState(false)"
      position="start"
      [disableClose]="true"
    >
      <nm-navigation-drawer
        id="navigation-drawer"
        [items]="navItems$ | async"
        [isCollapsed]="(sidenavService.sidenavState$ | async).isCollapsed"
        [isCompactView]="isCompactView()"
        [isMobile]="(sidenavService.sidenavState$ | async).isMobile"
        [account]="accountStorage.account | async"
        [activeItem]="sidenavService.currentSidebarNavItem$ | async"
        [clickItem]="sidenavService.currentSidebarNavItem$ | async"
        (toggle)="toggleCompactView($event)"
        (closeSidenav)="closeSidenav()"
        (dropdownState)="getDropdownState($event)"
        (activateItem)="setActiveItem($event)"
      ></nm-navigation-drawer>
    </mat-sidenav>
    <mat-sidenav
      class="table-sidebar"
      [opened]="tableSidebarService.tableSidenavOpen | async"
      position="end"
      nmClickOutside
      (clickOutside)="closeTableSidenav()"
      [autoFocus]="false"
      [disableClose]="true"
    >
      <nm-table-sidebar-layout></nm-table-sidebar-layout
    ></mat-sidenav>

    <mat-sidenav-content
      [ngClass]="{
        'open-sidenav': getOpenSidenav(sidenavService.sidenavState$ | async),
        'is-mobile': (sidenavService.sidenavState$ | async).isMobile,
        'expanded-mobile':
          (sidenavService.sidenavState$ | async).isCollapsed && !(sidenavService.sidenavState$ | async).isMobile
      }"
      (click)="closeMobileSidenav()"
    >
      <nm-top-bar
        testId="topBar"
        [title]="(sidenavService.topBarItem$ | async).title"
        [transparent]="(sidenavService.transparentTopBar$ | async) !== false"
        [icon]="(sidenavService.sidenavState$ | async).topBarIcon"
        [iconLeft]="(sidenavService.topBarItem$ | async).iconLeft"
        [count]="sidenavService.topBarCountItem$ | async"
        [onLeftClick]="(sidenavService.topBarItem$ | async).onLeftClick"
        (btnClick)="sidenavService.clickTopBarButton()"
        class="layout-header"
      >
        <div class="controls">
          <ng-template actionsHost></ng-template>
        </div>
      </nm-top-bar>

      <div
        class="content-wrapper layout-content"
        (click)="closeMobileSidenav()"
        [class.without-border-radius]="(sidenavService.transparentTopBar$ | async) === false"
      >
        <router-outlet></router-outlet>
      </div>

      <div class="content-wrapper layout-details" (click)="closeMobileSidenav()">
        <router-outlet name="detials"></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
