import { Component } from '@angular/core';

import { MenuService } from '@services/catalog';

@Component({
  selector: 'nm-menu-delete-dialog',
  templateUrl: './menu-delete-dialog.component.html',
})
export class MenuDeleteDialogComponent {
  constructor(public menuService: MenuService) {}
}
