import { FragmentDefinitionNode, OperationDefinitionNode } from 'graphql';

import { createWebSocketLink } from '@api';
import { split } from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';
import { environment } from '@env/environment';
import { HttpLinkProps } from '@typings';

export const createRequestLink = ({ httpLink, subscriptionClient }: HttpLinkProps, url?: string) => {
  const requestLink = httpLink.create({
    uri: !!url ? url : environment.baseUrl,
  });

  return split(
    ({ query }) => {
      const def: OperationDefinitionNode | FragmentDefinitionNode = getMainDefinition(query);

      if ('operation' in def) {
        const { kind, operation } = def;

        return kind === 'OperationDefinition' && operation === 'subscription';
      }

      return false;
    },
    createWebSocketLink({ subscriptionClient }),
    requestLink,
  );
};
