<div class="footer-select">
  <div class="select">
    <div class="cancel">
      <ng-content select="[cancelSelect]"></ng-content>
    </div>

    <span *ngIf="!selectedLabel" class="text">Выбрано: {{ selectedRows?.length }}</span>
    <span *ngIf="selectedLabel" class="text">{{ selectedLabel }}</span>

    <div class="actions">
      <ng-content select="[selectedActions]"></ng-content>
    </div>
  </div>

  <div class="buttons-select">
    <ng-content select="[header-right]"></ng-content>
  </div>
</div>
