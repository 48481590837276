import { Injectable } from '@angular/core';

import { MovementsApi } from '@app/modules/warehouse/services';
import { NotifyService } from '@services/shared';
import {
  QueryGetAnalyticsWarehouseItemMoveHistoryDetailsByStockUnitArgs,
  QueryLeavingByOrderDocumentArgs,
  QueryOrderByIdArgs,
  QueryOrderWriteOffDocByIdArgs,
  QueryOrderWriteOffProductsArgs,
  QueryOrderWriteOffProductsByDocIdArgs,
  QueryResult,
  QueryStockUnitMovementsArgs,
  QueryStockUnitMovementsByProductArgs,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class MovementsStorage {
  constructor(private notifyService: NotifyService, private movementsApi: MovementsApi) {}

  movementDetailsPageable(
    variables: QueryGetAnalyticsWarehouseItemMoveHistoryDetailsByStockUnitArgs,
  ): QueryResult<'getAnalyticsWarehouseItemMoveHistoryDetailsByStockUnit'> {
    return this.movementsApi.movementDetailsPageable(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка передвижений',
      }),
    );
  }

  leavingByOrderDocument(variables: QueryLeavingByOrderDocumentArgs): QueryResult<'leavingByOrderDocument'> {
    return this.movementsApi.leavingByOrderDocument(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении документа списания по заказу',
      }),
    );
  }

  orderById(variables: QueryOrderByIdArgs): QueryResult<'orderById'> {
    return this.movementsApi.orderById(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении заказа',
      }),
    );
  }

  stockUnitMovements(variables: QueryStockUnitMovementsArgs): QueryResult<'stockUnitMovements'> {
    return this.movementsApi.stockUnitMovements(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка передвижений',
      }),
    );
  }

  stockUnitMovementsByProduct(variables: QueryStockUnitMovementsByProductArgs): QueryResult<'stockUnitMovementsByProduct'> {
    return this.movementsApi.stockUnitMovementsByProduct(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка передвижений',
      }),
    );
  }

  orderWriteOffProducts(variables: QueryOrderWriteOffProductsArgs): QueryResult<'orderWriteOffProducts'> {
    return this.movementsApi.orderWriteOffProducts(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении документа списания по заказу',
      }),
    );
  }

  orderWriteOffProductByDocId(variables: QueryOrderWriteOffProductsByDocIdArgs): QueryResult<'orderWriteOffProductsByDocId'> {
    return this.movementsApi.orderWriteOffProductsByDocId(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении документа списания по заказу',
      }),
    );
  }

  orderWriteOffDoc(variables: QueryOrderWriteOffDocByIdArgs): QueryResult<'orderWriteOffDocById'> {
    return this.movementsApi.orderWriteOffDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении документа списания по заказу',
      }),
    );
  }
}
