<button
  #input
  mat-icon-button
  class="nm-icon-button"
  [ngClass]="buttonClasses"
  color="primary"
  [type]="type"
  [disableRipple]="true"
  [disabled]="disabled"
  [attr.data-test-id]="testId"
  (click)="onClickButton($event, input)"
>
  <div class="content">
    <nm-svg-icon [name]="icon" [size]="size" [color]="disabled ? '' : iconColor"></nm-svg-icon>
  </div>

  <mat-spinner *ngIf="loading" color="primary" diameter="18"></mat-spinner>
</button>
