import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';
import { Row } from '@app/shared/component/table/table.types';
import { CoreSchema } from '@typings';

export type TablesGroup = {
  id: string;
  name: string;
  quantity: number;
  defaultQuantity: number;
  tables$: BehaviorSubject<Row<Table, TablesColumns>[]>;
  showTables: boolean;
  expanded?: boolean;
  groupMenus?: MenuItem[];
};

export type TablesGroupForm = {
  name: FormControl<string | null>;
  quantity: FormControl<number | null>;
  defaultQuantity: FormControl<number | null>;
  showTables: FormControl<boolean | null>;
};

export type Table = {
  id: string;
  name: string;
  quantity: number;
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  radius: number | null;
};

export type TablesColumns = {
  formControl?: FormControl<string | number | null>;
  tableName?: string;
  tableId?: string;
  table?: CoreSchema.Table;
};

export enum TablesColumnsNames {
  tableName = 'tableName',
  places = 'places',
}
