import { Pipe, PipeTransform } from '@angular/core';

import { SessionStorage } from '@services/api';
import { CURRENCY_SHORT } from '@typings';

@Pipe({ name: 'currency' })
export class CurrencyPipe implements PipeTransform {
  constructor(private sessionStorage: SessionStorage) {}
  transform(value: number | string): string {
    return value + ` ${CURRENCY_SHORT[this.sessionStorage.getCurrencyUnit()]}`;
  }
}
