import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SvgIconModule } from '@app/shared/component/svg-icon/svg-icon.module';

import { SwitchComponent } from './switch.component';

@NgModule({
  declarations: [SwitchComponent],
  imports: [CommonModule, FormsModule, SvgIconModule],
  exports: [SwitchComponent],
})
export class SwitchModule {}
