import { Pipe, PipeTransform } from '@angular/core';

import { IdentityType, IdentityTypeString } from '@typings';

@Pipe({
  name: 'identityType',
})
export class IdentityTypePipe implements PipeTransform {
  transform(type: IdentityType): string {
    const types: IdentityTypeString = {
      EMAIL: 'Электронная почта',
      PHONE: 'Номер телефона',
    };

    return types[type];
  }
}
