import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { ModalBaseComponent } from './modal-base/modal-base.component';
import { ModalBaseModule } from './modal-base/modal-base.module';
import { ModalService } from './abstract';
import { ModalServiceImpl } from './modal.service';

@NgModule({
  imports: [CommonModule, MatDialogModule, ModalBaseModule],
  exports: [MatDialogModule, ModalBaseComponent],
  providers: [
    {
      provide: ModalService,
      useClass: ModalServiceImpl,
    },
  ],
})
export class DialogModule {}
