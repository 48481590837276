import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkDate',
})
export class CheckDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(date: string): string {
    const currentDate = this.datePipe.transform(date, 'dd.MM.yyyy')!;
    const today = this.datePipe.transform(new Date(), 'dd.MM.yyyy');
    const yesterday = this.datePipe.transform(new Date().setDate(new Date().getDate() - 1), 'dd.MM.yyyy');

    if (currentDate === today) {
      return 'Сегодня';
    }

    if (currentDate === yesterday) {
      return 'Вчера';
    }

    return currentDate;
  }
}
