<nm-modal-base
  (ok)="okClick()"
  (cancel)="arrivalsService.hideModal()"
  [okDisabled]="!name.valid"
  okTitle="Создать"
  cancelTitle="Отменить"
  okVariant="filled"
  class="nm-create-supplier-dialog"
>
  <div modal-base-title>Новый поставщик</div>
  <div class="container">
    <mat-radio-group class="supplier-type-group" [formControl]="type">
      <div *ngFor="let option of supplierTypeOptions" class="supplier-type">
        <nm-radio [value]="option.value"></nm-radio>
        <span class="supplier-type-label"> {{ option.label }} </span>
      </div>
    </mat-radio-group>

    <nm-input
      ngDefaultControl
      label="Название"
      placeholder="Введите название"
      [required]="true"
      [formControl]="name"
      [maxLength]="maxCharacters.NAME"
    ></nm-input>
  </div>
</nm-modal-base>
