import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, take } from 'rxjs';

import { ActionMenuItem } from '@app/shared/component/actions/actions.model';
import { Param, ParamsService } from '@core/service/params.service';

import { RedirectService } from '../../../service/redirect.service';

export type TableSidenavConfig = {
  component: unknown;
  title: string;
  idForRedirect: string;
  sidebarComponentData?: unknown;
  addPositionsComponent?: boolean;
};
@Injectable({
  providedIn: 'root',
})
export class TableSidebarLayoutService {
  tableSidenavOpen = new BehaviorSubject<boolean>(false);
  currentComponent = new BehaviorSubject<unknown>(null);
  title = new BehaviorSubject<string>('');
  idForRedirect = new BehaviorSubject<string | null>('');
  sidebarComponentData = new BehaviorSubject<unknown>(null);
  tableSidenavLoading = new BehaviorSubject<boolean>(false);
  items$ = new BehaviorSubject<ActionMenuItem[]>([]);
  currentParams: Param[] = [];
  sidebarConfig$ = new BehaviorSubject<TableSidenavConfig | null>(null);

  redirectToInfoPage = new Subject<string>();
  #redirected = new BehaviorSubject<boolean>(false);

  openWithRedirect = this.breakpointObserver.observe('(max-width: 900px)');

  exitConfirm = new Subject<void>();
  constructor(
    private breakpointObserver: BreakpointObserver,
    private paramsService: ParamsService,
    private redirectService: RedirectService,
  ) {
    this.redirectService.urlChange$.subscribe(() => {
      const sidebar = this.paramsService.getParamByName('sidebar', false, true);
      if (!sidebar && this.tableSidenavOpen.getValue()) {
        this.closeTableSidenav();
      }
    });
  }
  openTableSidenav(config: TableSidenavConfig, params: Param[] = []) {
    this.tableSidenavOpen.next(true);
    this.sidebarConfig$.next(config);
    this.items$.next([]);

    const addParams = [{ name: 'sidebar', value: true }, ...params];
    this.paramsService.setParams([...addParams]);
    this.currentParams.concat(...addParams);
    this.tableSidenavLoading.next(false);
    if (config.sidebarComponentData) {
      this.sidebarComponentData.next(config.sidebarComponentData);
    }
    this.idForRedirect.next(config.idForRedirect);
    this.openWithRedirect.pipe(take(1)).subscribe((data) => {
      if (data.matches) {
        this.redirectToInfoPage.next(config.idForRedirect);

        return;
      }

      this.title.next(config.title);

      this.currentComponent.next(config.component);

      this.paramsService.setParams([...addParams]);
      this.currentParams.concat(...addParams);
    });
  }
  closeTableSidenav(expanded?: boolean) {
    this.currentComponent.next(null);
    this.tableSidenavOpen.next(false);
    this.tableSidenavLoading.next(false);
    this.items$.next([]);
    if (!expanded) {
      this.#redirected.next(false);
      this.paramsService.setParams([
        ...defaultParamsToRemove,
        ...this.currentParams.map((param) => {
          return { ...param, value: '' };
        }),
      ]);
      this.currentParams = [];
    }
    if (this.sidebarComponentData.getValue()) {
      this.sidebarComponentData.next(null);
    }
    setTimeout(() => {
      this.#redirected.next(false);
    });
  }
  expandPage() {
    this.#redirected.next(true);
    this.closeTableSidenav(true);
    this.redirectToInfoPage.next(this.idForRedirect.getValue()!);
    setTimeout(() => {
      this.#redirected.next(false);
    });
  }
  setLoadingState(state?: boolean) {
    this.openWithRedirect.pipe(take(1)).subscribe((data) => {
      if (data.matches) {
        return;
      }
      this.tableSidenavLoading.next(state ?? true);
      this.tableSidenavOpen.next(true);
    });
  }

  confirmExit() {
    this.exitConfirm.next();
  }
  isRedirected(): boolean {
    return this.#redirected.getValue();
  }
  getSidebarConfig() {
    return this.sidebarConfig$.getValue();
  }
}
const defaultParamsToRemove = [
  { name: 'sidebar', value: '' },
  { name: 'tab', value: '' },
  { name: 'modifierGroup', value: '' },
  { name: 'modifierGroupTab', value: '' },
  { name: 'client', value: '' },
  { name: 'clientTab', value: '' },
  { name: 'id', value: '' },
];
