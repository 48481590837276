import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';

import { AvatarModule } from '@app/shared/component/avatar/avatar.module';

import { SvgIconModule } from '../svg-icon/svg-icon.module';

import { ChipComponent } from './chip.component';

@NgModule({
  declarations: [ChipComponent],
  imports: [CommonModule, SvgIconModule, MatChipsModule, AvatarModule],
  exports: [ChipComponent],
})
export class ChipModule {}
