import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenu, MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { BehaviorSubject } from 'rxjs';

import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';
import { ButtonVariant } from '@app/utils/constants/button-variants';

@Component({
  selector: 'nm-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
  @Input() items: MenuItem[] = [];
  @Input() selectedItems: BehaviorSubject<Set<string>> | Set<string> = new Set<string>();
  @Input() allowMultiselect: boolean = false;
  @Input() checkboxPosition: 'left' | 'right' | 'none' = 'none';
  @Input() selectOnItemClick: boolean = true;
  @Input() closeAfterItemClick: boolean = true;
  @Input() header: MenuItem | null = null;
  @Input() xPosition: MenuPositionX = 'after';
  @Input() yPosition: MenuPositionY = 'below';
  @Input() showCancelButton: boolean = false;
  @Input() cancelButtonLabel: string = 'Отменить';
  @Input() cancelButtonVariant: ButtonVariant = 'outlined';
  @Input() showOkButton: boolean = false;
  @Input() okButtonLabel: string = 'Добавить';
  @Input() okButtonVariant: ButtonVariant = 'filled';
  @Input() showSearch: boolean = false;
  @Input() hasBackdrop: boolean = true;
  @Input() searchText: string = '';
  @Input() maxHeight: number | null = null;
  @Input() minWidth: number | null = 220;
  @Input() maxWidth: number | null = null;
  @Input() itemsLoading: boolean = false;
  @Input() panelClass: string = '';
  @Input() isCloseAfterCancelClick: boolean = false;
  @Input() isCloseAfterOkClick: boolean = false;

  @Output() selectedItemsChange = new EventEmitter<Set<string>>();
  @Output() cancelClick = new EventEmitter<void>();
  @Output() okClick = new EventEmitter<Set<string> | BehaviorSubject<Set<string>>>();
  @Output() searchTextChange = new EventEmitter<string>();
  @Output() closed = new EventEmitter<void>();

  @ViewChild('childMenu', { static: true }) childMenu: MatMenu;

  isOpen = false;

  onClosed(): void {
    this.closed.emit();
  }

  close() {
    this.isOpen = false;
  }
}
