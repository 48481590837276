import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs/esm';
import { Observable } from 'rxjs';

import { HTTP_HEADER } from '@constants';
import { environment } from '@env/environment';
import { SessionStorage } from '@services/api';
import { getFileNameFromHeader, saveFile } from '@utils';

@Injectable({
  providedIn: 'root',
})
export class ExportReportService {
  orgId = this.sessionStorage.getOrgId();

  constructor(private http: HttpClient, private sessionStorage: SessionStorage) {}

  exportReport(
    reportType: string,
    params: Record<string, string | string[] | null | undefined> = {},
  ): Observable<HttpResponse<Blob | MediaSource>> {
    const authorization = this.sessionStorage.getAuthHeader();
    const orgId = this.sessionStorage.getOrgId();
    const paramsStr = this.#stringifyRequestParams(params);

    const url = `${environment.baseExportReportUrl}/${reportType}?organizationId=${this.orgId}${paramsStr}`;

    return this.http.post(
      url,
      {},
      {
        observe: 'response',
        responseType: 'blob',
        headers: {
          authorization,
          [HTTP_HEADER.ORGANIZATION]: `${orgId}`,
        },
      },
    );
  }

  exportReportWithHandler(
    reportType: string,
    params: Record<string, string | string[] | null | undefined> = {},
    defaultFilename: string = '',
    onComplete?: Function,
  ): void {
    const exportSubscribe = this.exportReport(reportType, params).subscribe(
      ({ body, headers }) => {
        const fileName = getFileNameFromHeader(headers) || defaultFilename;
        saveFile(body, fileName);

        exportSubscribe.unsubscribe();
        if (onComplete) {
          onComplete();
        }
      },
      () => {
        exportSubscribe.unsubscribe();
        if (onComplete) {
          onComplete();
        }
      },
    );
  }

  setModifiedDate(currentDate: string): string {
    const date = dayjs(currentDate);
    const dateYear = date.get('year');
    let dateMonth: number | string = date.get('month') + 1;
    if (dateMonth.toString().length === 1) {
      dateMonth = '0' + dateMonth.toString();
    }
    let dateDay: number | string = date.get('date');
    if (dateDay.toString().length === 1) {
      dateDay = '0' + dateDay.toString();
    }

    return `${dateYear}-${dateMonth}-${dateDay}`;
  }

  #stringifyRequestParams(params: Record<string, string | string[] | null | undefined>): string {
    return Object.entries(params).reduce((acc, [key, value]) => (value ? `${acc}&${key}=${value}` : acc), '');
  }

  exportReportFromUrl(url: string, defaultFilename: string = ''): void {
    const authorization = this.sessionStorage.getAuthHeader();
    const organizationId = this.sessionStorage.getOrgId()!;

    this.http
      .get(url, {
        observe: 'response',
        responseType: 'blob',
        headers: {
          authorization,
          organizationId,
        },
      })
      .subscribe(({ body, headers }) => {
        const fileName = getFileNameFromHeader(headers) || defaultFilename;

        saveFile(body, fileName);
      });
  }

  exportReportFromUrlWithDefaultName(url: string, defaultFilename: string): void {
    const authorization = this.sessionStorage.getAuthHeader();
    const organizationId = this.sessionStorage.getOrgId()!;

    this.http
      .get(url, {
        observe: 'response',
        responseType: 'blob',
        headers: {
          authorization,
          organizationId,
        },
      })
      .subscribe(({ body }) => {
        saveFile(body, defaultFilename);
      });
  }
}
