import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { RoleApi } from '@app/modules/employees/services/roles/role.api';
import {
  MutationCreateRoleArgs,
  MutationDeleteRoleArgs,
  MutationUpdateRoleArgs,
  QueryRoleArgs,
  QueryRolesArgs,
  QueryRolesByUserArgs,
} from '@app/typings/role';
import { NotifyService } from '@services/shared';
import { QueryResult } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class RoleStorage {
  constructor(private notifyService: NotifyService, private roleApi: RoleApi) {}

  roles(variables: QueryRolesArgs): QueryResult<'roles'> {
    return this.roleApi.roles(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка ролей',
      }),
    );
  }

  role(variables: QueryRoleArgs): QueryResult<'role'> {
    return this.roleApi.role(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении роли',
      }),
    );
  }

  rolesByUser(variables: QueryRolesByUserArgs): QueryResult<'rolesByUser'> {
    return this.roleApi.rolesByUser(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка ролей',
      }),
    );
  }

  createRole(variables: MutationCreateRoleArgs) {
    return this.roleApi.createRole(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании роли',
      }),
    );
  }

  updateRole(variables: MutationUpdateRoleArgs) {
    return this.roleApi.updateRole(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при изменении роли',
      }),
    );
  }

  deleteRole(variables: MutationDeleteRoleArgs) {
    return this.roleApi.deleteRole(variables).pipe(
      catchError((err) => {
        this.notifyService.addNotification({
          type: 'alert',
          title: 'Ошибка при удалении роли',
        });

        return throwError(() => err);
      }),
      tap(() =>
        this.notifyService.addNotification({
          type: 'success',
          title: 'Роль успешно удалена',
        }),
      ),
    );
  }
}
