import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SkeletonModule } from '@app/shared/component/skeleton/skeleton.module';

import { SkeletonTemplateTableComponent } from './skeleton-template-table.component';

@NgModule({
  declarations: [SkeletonTemplateTableComponent],
  imports: [CommonModule, SkeletonModule],
  exports: [SkeletonTemplateTableComponent],
})
export class SkeletonTemplateTableModule {}
