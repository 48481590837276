import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { ConfirmedForm } from '@typings';

export abstract class FormConfirmSaveAbstract {
  abstract setForm(form: UntypedFormGroup, options?: Partial<FormConfirmOptions>): void;
  abstract closeForm(): void;
  abstract onConfirm(form: ConfirmedForm): Observable<boolean>;
}

export interface FormConfirmOptions {
  modalTitle: string;
  modalText: string;
  modalOkText: string;
  modalCancelText: string;
  checkIfTouched: boolean;
  // TODO: implement in ERPF-923s
  showModalOnCloseWindow: boolean;
}
