<div class="popup" (click)="popup.onDecline()">
  <div class="popup__content" (click)="$event.stopPropagation()">
    <div class="popup__container" [ngStyle]="{ 'margin-bottom': popup.description ? '16px' : '32px' }">
      <span class="popup__title">
        {{ popup.title }}
      </span>

      <button type="button" class="popup__close" (click)="popup.onClose()">
        <svg
          class="popup__close-icon"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.14643 3.85375C2.95119 3.65847 2.95119 3.34186 3.14643 3.14657C3.34167 2.95129 3.65822 2.95129 3.85347 3.14657L9.99703 9.2914L16.1435 3.14362C16.3388 2.94834 16.6553 2.94834 16.8506 3.14362C17.0458 3.33891 17.0458 3.65552 16.8506 3.8508L10.7041 9.99858L16.8506 16.1464C17.0458 16.3416 17.0458 16.6583 16.8506 16.8535C16.6553 17.0488 16.3388 17.0488 16.1435 16.8535L9.99703 10.7058L3.85347 16.8506C3.65822 17.0459 3.34167 17.0459 3.14643 16.8506C2.95119 16.6553 2.95119 16.3387 3.14643 16.1434L9.29 9.99858L3.14643 3.85375Z"
          />
        </svg>
      </button>
    </div>

    <span *ngIf="popup.description" class="popup__description">
      {{ popup.description }}
    </span>

    <ng-template #loading>
      <div class="popup__buttons">
        <lib-my-button
          class="popup__button popup__button--accept"
          buttonSize="L"
          [buttonTitle]="popup.acceptTitle"
          [isFullWidth]="true"
          (clickButton)="popup.onAccept()"
        ></lib-my-button>

        <lib-my-button
          class="popup__button popup__button--decline"
          buttonTheme="outline"
          buttonSize="L"
          [buttonTitle]="popup.declineTitle"
          [isFullWidth]="true"
          (clickButton)="popup.onDecline()"
        ></lib-my-button>
      </div>
    </ng-template>

    <nm-preloader *ngIf="loading$ | async; else loading" class="popup__preloader"></nm-preloader>
  </div>
</div>
