import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { SVG_ICONS_TYPE } from '@constants';

@Injectable({
  providedIn: 'root',
})
export class BaseLayoutService {
  leftIcon: SVG_ICONS_TYPE | null;
  iconClick$ = new Subject<boolean>();
  settings$: BehaviorSubject<LayoutSettings | null> = new BehaviorSubject<LayoutSettings | null>(null);

  constructor() {}

  setHeaderLeftIcon(icon: SVG_ICONS_TYPE | null) {
    this.leftIcon = icon;
  }

  setLayoutSettings(settings: LayoutSettings | null) {
    this.settings$.next(settings);
  }
}

export interface LayoutSettings {
  showLogo: boolean;
  showHeader: boolean;
  showFooterInfo: boolean;
  title: string;
}
