import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';

import { UtilsService } from '@core/service/utils.service';
import { Alert, Notification, notificationToAlert } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  #notifications = new BehaviorSubject<Alert[]>([]);
  $notifications = this.#notifications.asObservable();

  constructor(private utilsService: UtilsService) {}

  #updateNotifications(notifications: Alert[]): void {
    this.#notifications.next(notifications);
  }

  getNotifications(): Alert[] {
    return this.#notifications.getValue();
  }

  closeNotification(id: string): void {
    this.#updateNotifications(this.getNotifications().filter((currentNotification) => currentNotification.id !== id));
  }

  addNotification(notification: Notification): void {
    const modifiedNotification: Alert = {
      ...notificationToAlert(notification),
      id: this.utilsService.generateUUID4(),
    };

    this.#updateNotifications([modifiedNotification, ...this.getNotifications()]);
  }

  $notify(notifications: { error?: string; success?: string; addErrorMessage?: boolean }) {
    return <T>(source: Observable<T>) =>
      source.pipe(
        catchError((err) => {
          if (notifications.error) {
            let msg = notifications.error;
            if (notifications.addErrorMessage) {
              msg += ` ${err.message}`;
            }
            this.addNotification({
              type: 'alert',
              title: msg,
            });
          }
          return throwError(() => err);
        }),
        tap((res) => {
          if (!res && notifications.error) {
            this.addNotification({
              type: 'alert',
              title: notifications.error,
            });
          } else if (notifications.success) {
            this.addNotification({
              type: 'success',
              title: notifications.success,
            });
          }
        }),
      );
  }
}
