import { gql } from 'apollo-angular';

export const mutationCreateOrganizationInvitation = gql`
  mutation createOrganizationInvitation($createOrganizationInvitationInput: CreateOrganizationInvitationInput!) {
    createOrganizationInvitation(createOrganizationInvitationInput: $createOrganizationInvitationInput)
  }
`;

export const mutationAcceptEmployeeInvite = gql`
  mutation acceptEmployeeInvite($token: String!) {
    acceptEmployeeInvite(token: $token) {
      employeeId
    }
  }
`;

export const mutationAcceptOrganizationInvitation = gql`
  mutation acceptOrganizationInvitation($organizationInvitationId: UUID!, $confirmationToken: String!) {
    acceptOrganizationInvitation(organizationInvitationId: $organizationInvitationId, confirmationToken: $confirmationToken)
  }
`;

export const mutationCreateEmployeeInvitation = gql`
  mutation createEmployeeInvitation($createEmployeeInvitationInput: CreateEmployeeInvitationInput!) {
    createEmployeeInvitation(createEmployeeInvitationInput: $createEmployeeInvitationInput)
  }
`;

export const mutationRemoveOrganizationInvitation = gql`
  mutation removeOrganizationInvitation($organizationInvitationId: UUID!) {
    removeOrganizationInvitation(organizationInvitationId: $organizationInvitationId)
  }
`;

export const mutationInviteEmployee = gql`
  mutation inviteEmployee($employeeId: UUID!, $sendTo: String!) {
    inviteEmployee(employeeId: $employeeId, sendTo: $sendTo) {
      employeeId
      token
    }
  }
`;

export const mutationUpdateOrganizationInvitation = gql`
  mutation updateOrganizationInvitation($updateOrganizationInvitationInput: UpdateOrganizationInvitationInput!) {
    updateOrganizationInvitation(updateOrganizationInvitationInput: $updateOrganizationInvitationInput)
  }
`;

export const fragmentOrganizationInvitation = gql`
  fragment OrganizationInvitation on OrganizationInvitation {
    id
    name
    confirmationToken
  }
`;

export const queryOrganizationInvitation = gql`
  query organizationInvitation($organizationInvitationId: UUID!) {
    organizationInvitation(organizationInvitationId: $organizationInvitationId) {
      ...OrganizationInvitation
    }
  }
  ${fragmentOrganizationInvitation}
`;

export const queryAllOrganizationInvitations = gql`
  query allOrganizationInvitations {
    allOrganizationInvitations {
      ...OrganizationInvitation
      active
      organization {
        id
        name
      }
    }
  }
  ${fragmentOrganizationInvitation}
`;

export const queryInvitationInfo = gql`
  query getInvitation($inviteId: UUID!) {
    getInvitation(inviteId: $inviteId) {
      id
      organizationInfo {
        shortId
        id
        name
      }
      employeeRoles {
        storeName
        role
        roleCode
      }
    }
  }
`;
