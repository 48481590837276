import { gql } from 'apollo-angular';

export const queryAllInCatalogPageableSidebar = gql`
  query allInCatalogPageable($filter: InCatalogFilterInput!, $pageRequest: PageRequestInput) {
    allInCatalogPageable(filter: $filter, pageRequest: $pageRequest) {
      total
      pageNumber
      pageSize
      totalPages
      content {
        ... on CatalogSection {
          id
          name
          depthLevel
          isParent
          parent {
            id
            name
          }
          productsCount
          colorSection
          positionType {
            entityId
            entityType
            code
          }
          snoValue {
            entityId
            entityType
            sno
          }
          vatValue {
            entityId
            entityType
            vat
          }
        }
        ... on Product {
          id
          name
          section {
            id
            name
          }
          stockUnits {
            id
            unit
            primePrice {
              amountValue
              currencyUnit
            }
            salePrice {
              amountValue
              currencyUnit
            }
            images {
              id
              imageSizes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
