<div
  *ngrxLet="{ value: value$, tooltip: tooltip$ } as obs"
  class="table-title"
  [class.font-bold]="isBold"
  [class.font-thin]="isThin"
  [style.justify-content]="justifyContent"
>
  <nm-avatar
    *ngIf="isAvatar && imageUrl"
    type="square"
    size="32"
    cursor="default"
    [color]="color"
    [iconColor]="iconColor"
    [imageUrl]="imageUrl"
  ></nm-avatar>
  <nm-avatar
    *ngIf="isAvatar && !imageUrl"
    [color]="color"
    type="square"
    size="32"
    cursor="default"
    [iconColor]="iconColor"
    icon="image"
  ></nm-avatar>

  <nm-svg-icon *ngIf="icon" [color]="iconColor" [size]="24" [name]="icon"></nm-svg-icon>

  <div class="name-container" *ngIf="!isClickedName && description">
    <span class="name" nmTooltip [TooltipContent]="obs.tooltip">{{ obs.value }}</span>
    <span class="description">{{ description }}</span>
  </div>

  <span *ngIf="!isClickedName && !description" class="name" nmTooltip [TooltipContent]="obs.tooltip">{{
    obs.value
  }}</span>

  <button
    *ngIf="isClickedName && !description"
    class="button"
    type="button"
    nmTooltip
    [TooltipContent]="obs.tooltip"
    [attr.data-test-id]="testId"
    (click)="onClickButton()"
  >
    {{ obs.value }}
  </button>

  <div *ngIf="isClickedName && description" class="name-container">
    <button
      class="button"
      type="button"
      nmTooltip
      [TooltipContent]="obs.tooltip"
      [attr.data-test-id]="testId"
      (click)="onClickButton()"
    >
      {{ obs.value }}
    </button>
    <span class="description">{{ description }}</span>
  </div>
</div>
