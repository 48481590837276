import { HttpHeaders } from '@angular/common/http';

import { HTTP_HEADER } from '@constants';

export const saveFile = (blob: Blob | MediaSource | null, fileName: string): void => {
  const link: HTMLAnchorElement = document.createElement('a');
  if (blob) {
    link.href = URL.createObjectURL(blob);
  }
  link.setAttribute('download', fileName);

  document.getElementsByTagName('body')[0].appendChild(link);

  // Firefox
  if (document.createEvent) {
    const event: MouseEvent = document.createEvent('MouseEvents');
    event.initEvent('click', true, true);
    link.dispatchEvent(event);
  } else if (link.click) {
    // IE
    link.click();
  }

  link.parentNode?.removeChild(link);
};

export const getFileNameFromHeader = (headers: HttpHeaders): string | null => {
  if (!headers) return null;

  const fileName: string | null = headers.get(HTTP_HEADER.CONTENT_DISPOSITION);

  if (!fileName) return null;

  return decodeURIComponent(fileName.substring(fileName.lastIndexOf("'") + 1));
};

export const base64toBlob = (b64Data: string, contentType: string = '', sliceSize: number = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};
