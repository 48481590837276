import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotifyService } from '@services/shared';
import {
  CoreSchema,
  LeavingDocument,
  MutationConfirmWriteOffDocArgs,
  MutationCreateWriteOffDocArgs,
  MutationDeleteWriteOffDocArgs,
  MutationResult,
  MutationRevertWriteOffDocArgs,
  MutationUpdateWriteOffDocArgs,
  QueryResult,
  QueryWriteOffDocArgs,
  QueryWriteOffDocsArgs,
  QueryWriteOffProductsArgs,
  WriteOffDoc,
} from '@typings';

import { LeavingsApi } from './leavings.api';

@Injectable({
  providedIn: 'root',
})
export class LeavingsStorage {
  constructor(private notifyService: NotifyService, private leavingsApi: LeavingsApi) {}

  allLeavingsPageable(variables: CoreSchema.QueryAllLeavingDocumentsPageableV2Args): QueryResult<'allLeavingDocumentsPageableV2'> {
    return this.leavingsApi.getAllLeavingDocuments(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка поставок',
      }),
    );
  }

  confirmLeavingDocument(leavingDocumentId: string, warehouseId: string) {
    return this.leavingsApi.confirmLeavingDocument(leavingDocumentId, warehouseId).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при проведении документа списания',
      }),
    );
  }

  getLeavingDocument(leavingDocumentId: string): Observable<LeavingDocument> {
    return this.leavingsApi.getLeavingDocument(leavingDocumentId).pipe(
      map((r) => {
        return r.data.leavingDocument;
      }),
    );
  }

  deleteLeavingDocument(leavingDocumentId: string) {
    return this.leavingsApi.deleteLeavingDocument(leavingDocumentId).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении списания',
        success: 'Списание успешно удалено',
      }),
    );
  }

  createLeavingDocument(variables: CoreSchema.MutationCreateLeavingDocumentArgs): MutationResult<'createLeavingDocument'> {
    return this.leavingsApi.createLeavingDocument(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании списания',
      }),
    );
  }

  updateLeavingDocument(variables: CoreSchema.MutationUpdateLeavingDocumentArgs): MutationResult<'updateLeavingDocument'> {
    return this.leavingsApi.updateLeavingDocument(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при сохранении списания',
      }),
    );
  }

  duplicateLeavingDocument(documentId: string) {
    return this.leavingsApi.duplicateLeavingDocument(documentId).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при дублировании списания',
      }),
    );
  }

  writeOffDocs(variables: QueryWriteOffDocsArgs): QueryResult<'writeOffDocs'> {
    return this.leavingsApi.writeOffDocs(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка поставок',
      }),
    );
  }

  writeOffProducts(variables: QueryWriteOffProductsArgs): QueryResult<'writeOffProducts'> {
    return this.leavingsApi.writeOffProducts(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка продуктов поставки',
      }),
    );
  }

  writeOffDoc(variables: QueryWriteOffDocArgs): Observable<WriteOffDoc> {
    return this.leavingsApi.writeOffDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении поставки',
      }),
      map((r) => {
        return r.data.writeOffDoc!;
      }),
    );
  }

  createWriteOffDoc(variables: MutationCreateWriteOffDocArgs): MutationResult<'createWriteOffDoc'> {
    return this.leavingsApi.createWriteOffDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании списания',
      }),
    );
  }

  updateWriteOffDoc(variables: MutationUpdateWriteOffDocArgs): MutationResult<'updateWriteOffDoc'> {
    return this.leavingsApi.updateWriteOffDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при изменении списания',
      }),
    );
  }

  confirmWriteOffDoc(variables: MutationConfirmWriteOffDocArgs) {
    return this.leavingsApi.confirmWriteOffDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при проведении документа списания',
      }),
    );
  }

  revertWriteOffDoc(variables: MutationRevertWriteOffDocArgs) {
    return this.leavingsApi.revertWriteOffDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при отмене списания',
      }),
    );
  }

  deleteWriteOffDoc(variables: MutationDeleteWriteOffDocArgs) {
    return this.leavingsApi.deleteWriteOffDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении списания',
        success: 'Списание успешно удалено',
      }),
    );
  }
}
