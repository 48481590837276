import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

import { ModalStateService } from '@app/shared/component/dialog/modal-state.service';
import { TableSidebarLayoutService } from '@app/shared/component/table-sidenav-layout/service/table-sidebar-layout.service';

@Directive({
  selector: '[nmClickOutside]',
})
export class ClickOutsideDirective {
  @Output() public clickOutside = new EventEmitter();

  constructor(
    private _elementRef: ElementRef,
    private modalStateService: ModalStateService,
    private tableSidebarService: TableSidebarLayoutService,
  ) {}

  @HostListener('document:mouseup', ['$event'])
  public onClick(event: MouseEvent) {
    const rect = this._elementRef.nativeElement.getBoundingClientRect();
    const isInElement =
      rect.top <= event.clientY && event.clientY <= rect.bottom && rect.left <= event.clientX && event.clientX <= rect.right;

    const isButton = (event.target as HTMLElement).tagName.toLowerCase() === 'button';

    const isModal = this.modalStateService.getModalState();
    if (!isInElement && !isButton && !isModal && event.clientY !== 0 && event.clientX !== 0) {
      this.clickOutside.emit();
    }
  }
}
