import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { first, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { CreateProductDialogComponent } from '@app/shared/component/create-product-dialog/create-product-dialog.component';
import { ModalRef, ModalService } from '@app/shared/component/dialog/abstract';
import { MenuItemType } from '@app/shared/component/menu-item/menu-item.model';
import { Row } from '@app/shared/component/table/table.types';
import { ImagesService } from '@app/shared/service/images.service';
import { TableTreeService } from '@app/shared/service/table-tree.service';
import { CatalogRoute, MAX_CHARACTERS, ONLINE_MENU_LINK, RootNavigationRoute, UNIT_TYPE } from '@constants';
import { SessionStorage } from '@services/api';
import { ValidationErrorsService } from '@services/core';
import { StoresStorage } from '@services/settings';
import { NotifyService } from '@services/shared';
import {
  CoreSchema,
  CURRENCY_SHORT,
  EditItemForm,
  itemRc,
  Menu,
  MenuDuplicateForm,
  MenuElementFilterInput,
  MenuElementRc,
  MenuFilterInput,
  MenuForm,
  MenuItem,
  MenuItemsForm,
  MenuOnlineForm,
  MenuPage,
  MenuRc,
  MenuRcCreateInput,
  MenuRcFilterInput,
  MenuRcStructureInput,
  MenuSection,
  MenuSectionForm,
  MenusRc,
  MenuSubectionForm,
  ModifierGroupOutput,
  OnlineMenu,
  PageRequestInput,
  Product,
  ProductCreateInput,
  ProductDialogForm,
  QueryResult,
  SectionRc,
  SectionUpdateInput,
  StockUnit,
  StockUnitInput,
  Store,
  UpdateItemInput,
  UpdateMenuInput,
} from '@typings';
import { notEmpty } from '@utils';

import { EditItemDialogComponent } from '../pages/menus/menu/edit-item-dialog/edit-item-dialog.component';
import { MenuDuplicateDialogComponent } from '../pages/menus/menu/menu-duplicate-dialog/menu-duplicate-dialog.component';
import { MenuItemsDialogComponent } from '../pages/menus/menu/menu-items-dialog/menu-items-dialog.component';
import { MenuSectionDialogComponent } from '../pages/menus/menu/menu-section-dialog/menu-section-dialog.component';
import { MenuDeleteDialogComponent } from '../pages/menus/menu-delete-dialog/menu-delete-dialog.component';
import { MenuFormComponent } from '../pages/menus/menu-form-dialog/menu-form-dialog.component';

import { CatalogStorage } from './catalog.storage';
import { MenuStorage } from './menu.storage';
import { ModifierGroupStorage } from './modifierGroup.storage';
import { ProductStorage } from './product.storage';

export type UpdateMenuItem = {
  element: MenuElementRc;
  parentId?: string;
  placeOnTop?: boolean;
  delete?: boolean;
  updateChildren?: boolean;
};
@Injectable({
  providedIn: 'root',
})
export class MenuService implements OnDestroy {
  form: FormGroup<MenuForm>;
  sectionForm: FormGroup<MenuSectionForm>;
  duplicateForm: FormGroup<MenuDuplicateForm>;
  subsectionForm: FormGroup<MenuSubectionForm>;
  menuItemsForm: FormGroup<MenuItemsForm>;
  editItemForm: FormGroup<EditItemForm>;
  onlineForm: FormGroup<MenuOnlineForm>;
  productForm: FormGroup<ProductDialogForm>;

  rows$: Record<string, Observable<Row<MenuSection | MenuItem>[]>> = {};

  subsections: Record<string, Observable<MenuSection[]>> = {};

  refreshSubsections$: Record<string, ReplaySubject<boolean>> = {};
  expandSubsection$: ReplaySubject<string | null> = new ReplaySubject();

  #deleteDialogRef: ModalRef<MenuDeleteDialogComponent>;

  #formDialogRef: ModalRef<unknown>;
  #productDialogRef: ModalRef<unknown>;
  sectionDialogRef: ModalRef<unknown>;

  #menu = new BehaviorSubject<MenuRc | Menu | null>(null);
  menu$ = this.#menu.asObservable();

  refreshMenu$: ReplaySubject<boolean> = new ReplaySubject();
  #refreshSub: Subscription | null = null;

  newProduct$: ReplaySubject<Product | null> = new ReplaySubject();

  tableTreeService = new TableTreeService<MenuSection | MenuItem>();

  #allCatalogSelected = new BehaviorSubject<boolean>(false);
  allCatalogSelected$ = this.#allCatalogSelected.asObservable();

  #allSelectedStores = new BehaviorSubject(true);
  allSelectedStores$ = this.#allSelectedStores.asObservable();

  updateMenu = new Subject<void>();
  updateMenuItem = new Subject<UpdateMenuItem>();
  sectionCreated = new Subject<string>();
  updateMenuInTable = new Subject<MenuRc>();

  isLoading$ = new BehaviorSubject(false);
  allStores: BehaviorSubject<Store[]> = new BehaviorSubject([] as Store[]);

  constructor(
    private fb: FormBuilder,
    private sessionStorage: SessionStorage,
    private router: Router,
    private menuStorage: MenuStorage,
    private modal: ModalService,
    private notifyService: NotifyService,
    private productStorage: ProductStorage,
    private validationErrorsService: ValidationErrorsService,
    private catalogStorage: CatalogStorage,
    private modifierGroupStorage: ModifierGroupStorage,
    private imagesService: ImagesService,
    private storesStorage: StoresStorage,
  ) {
    this.refreshMenu();
    this.expandSubsection$.next(null);
  }

  ngOnDestroy() {
    this.#refreshSub?.unsubscribe();
  }
  getMenuId(): string {
    return this.#menu.getValue()?.id || '';
  }
  setAllCatalogSelected(status: boolean) {
    this.#allCatalogSelected.next(status);
  }

  setAllSelectedStores(selected: boolean): void {
    this.#allSelectedStores.next(selected);
  }

  getAllSelectedStores(): boolean {
    return this.#allSelectedStores.getValue();
  }

  async initForm(menu: MenuRc | undefined): Promise<void> {
    this.#allCatalogSelected.next(false);
    this.form = this.fb.group<MenuForm>({
      name: this.fb.nonNullable.control(menu ? menu.name : '', [Validators.required, Validators.maxLength(MAX_CHARACTERS.PRODUCT_NAME)]),
      structure: this.fb.nonNullable.control([]),
      useStructure: this.fb.nonNullable.control(false),
      stores: this.fb.nonNullable.control([]),
    });
    this.setAllSelectedStores(true);

    await this.getStoresPromise().then((stores) => {
      this.allStores.next(stores);
      if (menu && menu.storeIds && menu.storeIds.length) {
        this.setAllSelectedStores(menu.storeIds?.length === stores.length);
        const selectedStores: AutocompleteOption<Store>[] = menu?.storeIds
          .map((storeId) => {
            const store = stores.find((store) => store.id === storeId);

            if (!store) {
              return null;
            }

            return {
              id: store.id,
              label: store.name,
              type: 'item' as MenuItemType,
              data: store,
            };
          })
          .filter(notEmpty);

        this.form.controls.stores.setValue(selectedStores);
      } else {
        const selectedStores: AutocompleteOption<Store>[] = stores
          .map((res) => {
            const store = stores.find((store) => store.id === res.id);

            if (!store) {
              return null;
            }

            return {
              id: store.id,
              label: store.name,
              type: 'item' as MenuItemType,
              data: store,
            };
          })
          .filter(notEmpty);

        this.form.controls.stores.setValue(selectedStores);
      }
    });
  }

  async initUpdateMenuForm(menu: MenuRc): Promise<void> {
    this.form = this.fb.group<MenuForm>({
      name: this.fb.nonNullable.control(menu ? menu.name : '', [Validators.required, Validators.maxLength(MAX_CHARACTERS.PRODUCT_NAME)]),
      stores: this.fb.nonNullable.control([]),
    });

    if (menu.storeIds && menu.storeIds.length) {
      await this.getStoresPromise().then((stores) => {
        this.allStores.next(stores);
        this.setAllSelectedStores(menu.storeIds?.length === stores.length);
        const selectedStores: AutocompleteOption<Store>[] = (menu.storeIds || [])
          .map((storeId) => {
            const store = stores.find((store) => store.id === storeId);

            if (!store) {
              return null;
            }

            return {
              id: store.id,
              label: store.name,
              type: 'item' as MenuItemType,
              data: store,
            };
          })
          .filter(notEmpty);

        this.form.controls.stores.setValue(selectedStores);
      });
    }
  }

  initSectionForm(section: CoreSchema.SectionRc | undefined): void {
    this.sectionForm = this.fb.group<MenuSectionForm>({
      name: this.fb.nonNullable.control(section ? section.name : '', [
        Validators.required,
        Validators.maxLength(MAX_CHARACTERS.PRODUCT_NAME),
      ]),
      image: this.fb.control(section?.image?.imageSizes.length ? section.image : null),
      parentSection: this.fb.nonNullable.control(section?.parentId || undefined),
      colorSection: this.fb.control(section?.color || null, section ? [Validators.required] : []),
    });
  }

  initDuplicateForm(): void {
    this.duplicateForm = this.fb.group<MenuDuplicateForm>({
      storeIds: this.fb.control<string[]>([], { validators: [Validators.required], nonNullable: true }),
    });
  }

  initSubsectionForm(parentSectionId: string, subsection: MenuSection | undefined): void {
    this.subsectionForm = this.fb.group<MenuSubectionForm>({
      name: this.fb.nonNullable.control(subsection ? subsection.name : '', [
        Validators.required,
        Validators.maxLength(MAX_CHARACTERS.PRODUCT_NAME),
      ]),
      parentSection: this.fb.nonNullable.control(parentSectionId, []),
    });
  }

  initMenuItemsForm(section: MenuSection | null) {
    this.newProduct$.next(null);
    this.#allCatalogSelected.next(false);
    this.menuItemsForm = this.fb.group<MenuItemsForm>({
      section: this.fb.nonNullable.control(section?.id),
      structure: this.fb.nonNullable.control([], Validators.required),
      useStructure: this.fb.nonNullable.control(false),
    });
  }

  initEditItemForm(menuItem: itemRc, stockUnit: StockUnit) {
    this.editItemForm = this.fb.group<EditItemForm>({
      name: this.fb.nonNullable.control(menuItem.name, [Validators.required, Validators.maxLength(MAX_CHARACTERS.PRODUCT_NAME)]),
      subsection: this.fb.nonNullable.control(menuItem.sectionId || undefined),
      product: this.fb.nonNullable.control(null, [Validators.required]),
    });
    this.getProductByStockUnitId(stockUnit?.id)
      .pipe(first())
      .subscribe((product) => {
        if (product) {
          this.editItemForm.patchValue({
            product: {
              id: product.id,
              type: 'item',
              label: product.name,
              data: product,
            },
          });
        } else {
          this.editItemForm.patchValue({ product: null });
        }
        this.editItemForm.markAsPristine();
      });
  }

  initOnlineForm(menu: Menu | undefined): void {
    const active = menu?.onlineMenu?.active || false;
    const link = active ? this.getOnlineMenuLink(menu!.onlineMenu!) : '';

    this.onlineForm = this.fb.group<MenuOnlineForm>({
      active: this.fb.nonNullable.control(active),
      link: this.fb.nonNullable.control(link),
    });
  }

  initProductForm(name: string) {
    this.productForm = this.fb.group<ProductDialogForm>({
      name: this.fb.nonNullable.control(name, [Validators.required, Validators.maxLength(MAX_CHARACTERS.PRODUCT_NAME)]),
      section: this.fb.nonNullable.control(null, [Validators.required]),
      type: this.fb.control(null, [Validators.required]),
      weighable: this.fb.nonNullable.control(false),
      unit: this.fb.nonNullable.control(UNIT_TYPE.PIECE),
      quantity: this.fb.nonNullable.control('1', [Validators.required, Validators.min(0)]),
      primePrice: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
      salePrice: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
      surcharge: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
    });
  }

  initState(menuId: string) {
    this.#refreshSub?.unsubscribe();

    this.#refreshSub = this.refreshMenu$.pipe(switchMap(() => this.menuStorage.getMenuRc({ id: menuId }))).subscribe((res) => {
      const newMenu = res.data.menuRc;

      this.#menu.next(newMenu);
      this.menuStorage.setMenu(newMenu);
    });
  }

  private showSuccessNotification(message: string): void {
    this.notifyService.addNotification({
      type: 'success',
      title: message,
    });
  }

  refreshSubsectionsBySectionId(sectionId: string | null): void {
    if (!sectionId || !this.refreshSubsections$[sectionId]) {
      return;
    }
    this.refreshSubsections$[sectionId].next(true);
  }

  private duplicateMenu(menuId: string): Observable<boolean> {
    if (this.duplicateForm.invalid) {
      return of(false);
    }

    const { storeIds } = this.duplicateForm.controls;

    const menuDuplicateInput = {
      menuIdToDuplicate: menuId,
      storeIds: storeIds.value,
    };

    return this.menuStorage.duplicateMenuRc({ input: menuDuplicateInput }).pipe(
      first(),
      map((resp) => !!resp),
    );
  }

  private createProduct(): void {
    const { name, weighable, unit, quantity, section, primePrice, salePrice } = this.productForm.controls;

    const stockUnitInput: StockUnitInput = {
      name: name.value,
      weighable: !weighable.value,
      unit: unit.value || 'NONE',
      quantity: quantity.value,
      primePrice: { amountValue: primePrice.value, currencyUnit: this.getCurrencyUnit() },
      salePrice: { amountValue: salePrice.value, currencyUnit: this.getCurrencyUnit() },
    };

    const productCreateInput: ProductCreateInput = {
      catalogId: this.catalogStorage.getCatalog().id,
      name: name.value,
      sectionId: section.value?.id,
      stockUnits: [stockUnitInput],
    };

    this.productStorage.createProduct({ product: productCreateInput }).subscribe((res) => {
      if (!this.menuItemsForm) {
        return;
      }
      this.newProduct$.next(res.data?.createProductV2?.output || null);
    });
  }

  getStoresPromise(): Promise<Store[]> {
    return firstValueFrom(this.storesStorage.getAllStoresShort().pipe(map((res) => res.data.allStores || [])));
  }

  private async createMenuSection(menuId: string): Promise<void> {
    if (!this.sectionForm) {
      return;
    }

    const { name, parentSection, colorSection } = this.sectionForm.controls;
    const newParentSection = parentSection.value;
    const image = this.sectionForm.controls.image.value;

    if (this.sectionForm.invalid) {
      return;
    }

    const menuSectionCreateInput: CoreSchema.SectionCreateInput = {
      name: name.value,
      active: true,
      parentId: newParentSection,
      color: colorSection.value!,
      menuId,
    };
    if (image && 'extension' in image) {
      menuSectionCreateInput.imageId = await this.imagesService.uploadImages([image]);
    }

    this.menuStorage
      .createMenuSectionRc({ input: menuSectionCreateInput })
      .pipe(
        first(),
        map((res) => res.data?.createSectionRc),
      )
      .subscribe((res) => {
        if (res?.section?.id !== undefined) {
          this.sectionCreated.next(res.section.id);
        }

        if (!newParentSection) {
          this.updateMenu.next();
        } else {
          this.menuStorage
            .getMenuElement({ elementId: newParentSection })
            .pipe(take(1))
            .subscribe((res) => {
              this.updateMenuItem.next({ element: res.data.menuRcElement.element, updateChildren: true });
            });
        }
      });
  }

  private async editMenuSection(section: SectionRc): Promise<void> {
    const { name, colorSection, parentSection } = this.sectionForm.controls;

    const newParentSection = parentSection.value;
    const image = this.sectionForm.controls.image.value;
    if (this.sectionForm.invalid) {
      return;
    }

    const menuSectionUpdateInput: SectionUpdateInput = {
      id: section.id,
      name: name.value,
      color: colorSection.value,
    };

    if (image && 'extension' in image) {
      menuSectionUpdateInput.imageId = await this.imagesService.uploadImages([image]);
    } else if (!image && section.image) {
      menuSectionUpdateInput.imageId = null;
    }

    this.menuStorage
      .updateMenuSectionRc({ input: menuSectionUpdateInput })
      .pipe(
        first(),
        map((res) => res.data?.updateSectionRc),
      )
      .subscribe((res) => {
        if (newParentSection === section.parentId && res?.section) {
          this.updateMenuItem.next({ element: res.section });
        } else if (newParentSection !== section.parentId) {
          if (section.parentId && !parentSection.value) {
            this.menuStorage
              .moveMenuElements({
                input: {
                  elementIds: [section.id],
                  newLeftPosition: 1,
                },
              })
              .pipe(first())
              .subscribe(
                (_) => res && res.section && this.updateMenuItem.next({ element: { ...res.section, parentId: null }, placeOnTop: true }),
              );
          } else {
            this.menuStorage
              .getMenuElementShort({ elementId: newParentSection! })
              .pipe(
                mergeMap((res) => {
                  return this.menuStorage.moveMenuElements({
                    input: {
                      elementIds: [section.id],
                      newLeftPosition: res.data.menuRcElement.element.leftMargin + 1,
                    },
                  });
                }),
                first(),
              )
              .subscribe(() => {
                if (res?.section) {
                  this.updateMenuItem.next({ element: res.section, parentId: newParentSection });
                }
              });
          }
        }
      });
  }

  createMenu(): void {
    const { name, stores, structure, useStructure } = this.form.controls;

    let menuStructure: MenuRcStructureInput = {
      allCatalog: this.#allCatalogSelected.getValue(),
      categorySelection: { selected: [], excluded: [] },
      itemSelection: { selected: [], excluded: [] },
      useStruct: useStructure?.value!,
    };
    if (this.#allCatalogSelected.getValue() === false) {
      structure?.value.map((value) => {
        if ('skuId' in value?.data!) {
          menuStructure.itemSelection.selected.push(value.data?.skuId);
        } else {
          menuStructure.categorySelection.selected.push(value.data?.id!);
        }
      });
    }

    if (!stores.value.length && !this.getAllSelectedStores()) {
      this.isLoading$.next(false);
      this.notifyService.addNotification({
        type: 'alert',
        title: 'Необходимо выбрать хотя бы одно заведение',
      });
      return;
    }

    if (this.form.invalid) {
      this.isLoading$.next(false);
      return;
    }

    const menuCreateInput: MenuRcCreateInput = {
      name: name.value,
      type: 'DEFAULT',
      allStores: false,
      storeIds: stores.value.map((s) => s.id),
    };

    this.menuStorage
      .createRcMenu({
        menu: menuCreateInput,
        struct: menuStructure,
      })
      .pipe(
        first(),
        map((res) => {
          return res?.data?.createMenuRc;
        }),
      )
      .subscribe(
        (res) => {
          if (res) {
            this.closeMenuForm(true);
            this.toMenuPage(res?.menu?.id!);
          } else {
            this.notifyService.$notify({ error: 'Ошибка при создании меню' });
          }
          this.isLoading$.next(false);
        },
        () => this.isLoading$.next(false),
      );
  }

  editMenu(menu: MenuRc | Menu): void {
    const { name, stores } = this.form.controls;

    if (!stores.value.length && !this.getAllSelectedStores()) {
      this.isLoading$.next(false);
      this.notifyService.addNotification({
        type: 'alert',
        title: 'Необходимо выбрать хотя бы одно заведение',
      });
      return;
    }

    if (this.form.invalid) {
      this.isLoading$.next(false);
      return;
    }

    const menuUpdateInput: UpdateMenuInput = {
      id: menu.id,
      storeIds: this.getAllSelectedStores() ? this.allStores.getValue().map((s) => s.id) : stores.value.map((s) => s.id),
      name: name.value,
    };

    this.menuStorage
      .updateMenuRc({ input: menuUpdateInput })
      .pipe(
        first(),
        map((res) => res.data?.updateMenuRc),
      )
      .subscribe(
        () => {
          this.closeMenuForm(true);
          this.refreshMenu$.next(true);
          this.isLoading$.next(false);
        },
        () => this.isLoading$.next(false),
      );
  }

  private addMenuItems(menuId: string): void {
    const form = this.menuItemsForm;
    const { section, structure, useStructure } = form.controls;
    const sectionId = section?.value;
    let menuStructure: MenuRcStructureInput = {
      allCatalog: this.#allCatalogSelected.getValue(),
      categorySelection: { selected: [], excluded: [] },
      itemSelection: { selected: [], excluded: [] },
      useStruct: useStructure.value,
    };
    if (this.#allCatalogSelected.getValue() === false) {
      structure.value.map((value) => {
        if ('skuId' in value?.data!) {
          menuStructure.itemSelection.selected.push(value.data?.skuId);
        } else {
          menuStructure.categorySelection.selected.push(value.data?.id!);
        }
      });
    }

    if (form.invalid) {
      return;
    }
    this.menuStorage
      .addMenuRcStructure({ menuId, struct: menuStructure, categoryId: sectionId })
      .pipe(
        first(),
        map((res) => res?.data?.addMenuRc),
      )
      .subscribe(() => {
        if (!sectionId) {
          this.updateMenu.next();
        } else {
          this.menuStorage
            .getMenuElement({ elementId: sectionId })
            .pipe(take(1))
            .subscribe((res) => {
              this.updateMenuItem.next({ element: res.data.menuRcElement.element, updateChildren: true });
            });
        }
      });
  }

  private editMenuItem(menuItem: CoreSchema.ItemRc): void {
    const form = this.editItemForm;
    const { name, subsection, product } = form.controls;
    const newSubsectionId = subsection.value;
    const productId = product?.value?.data?.stockUnits[0].id;

    if (form.invalid || !productId) {
      return;
    }

    const menuItemInput: UpdateItemInput = {
      id: menuItem.id,
      menuId: this.getMenuId(),
      name: name.value,
      stockUnitId: productId,
    };

    this.menuStorage
      .updateMenuItemRc({
        item: menuItemInput,
      })
      .pipe(
        first(),
        map((res) => res.data?.updateItemRc.item),
      )
      .subscribe((res) => {
        if (newSubsectionId === menuItem.sectionId && res) {
          this.updateMenuItem.next({ element: res });
        } else if (menuItem.sectionId !== newSubsectionId) {
          if (menuItem.sectionId && !newSubsectionId) {
            this.menuStorage
              .moveMenuElements({
                input: {
                  elementIds: [menuItem.id],
                  newLeftPosition: 1,
                },
              })
              .pipe(first())
              .subscribe((_) => res && this.updateMenuItem.next({ element: { ...res, sectionId: null }, placeOnTop: true }));
          } else {
            this.menuStorage
              .getMenuElementShort({ elementId: newSubsectionId as string })
              .pipe(
                mergeMap((res) => {
                  return this.menuStorage.moveMenuElements({
                    input: {
                      elementIds: [menuItem.id],
                      newLeftPosition: res.data.menuRcElement.element.leftMargin + 1 || 1,
                    },
                  });
                }),
                first(),
              )
              .subscribe(() => {
                res && this.updateMenuItem.next({ element: res, parentId: newSubsectionId });
              });
          }
        }
      });
  }
  modifyMenuItem(menuItem: UpdateItemInput): void {
    this.menuStorage
      .updateMenuItemRc({ item: menuItem })
      .pipe(
        first(),
        map((res) => res.data?.updateItemRc),
      )
      .subscribe();
  }

  openMenuDialog(menu?: MenuRc | Menu) {
    this.#formDialogRef = this.modal.openDialog(MenuFormComponent, {
      disableClose: true,
      data: {
        menu,
      },
    });
  }

  createMenuForApplication(name: string, menu?: MenuRc | Menu) {
    this.#formDialogRef = this.modal.openDialog(MenuFormComponent, {
      disableClose: true,
      data: {
        menu,
        menuName: name,
      },
    });
  }

  openMenuSectionDialog(section?: CoreSchema.SectionRc, sectionName?: string) {
    const menuId = this.getMenuId();
    this.sectionDialogRef = this.modal.openDialog(MenuSectionDialogComponent, {
      data: {
        section,
        menuId: menuId,
        sectionName,
      },
      disableClose: true,
    });
    this.sectionDialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        if (section) {
          this.editMenuSection(section);
        } else {
          this.createMenuSection(menuId);
        }
      }
    });
  }

  openMenuElementDialog(menuId: string, section?: CoreSchema.SectionRc) {
    this.#formDialogRef = this.modal.openDialog(MenuItemsDialogComponent, {
      data: {
        section,
        menuId,
      },
      disableClose: true,
    });
    this.#formDialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.addMenuItems(menuId);
      }
    });
  }

  openMenuItemsDialog(menuId: string, section: CoreSchema.SectionRc | null, subsection?: CoreSchema.SectionRc) {
    this.#formDialogRef = this.modal.openDialog(MenuItemsDialogComponent, {
      data: {
        subsection,
        section,
      },
      disableClose: true,
    });
    this.#formDialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.addMenuItems(menuId);
      }
    });
  }

  openEditItemDialog(menuItem: CoreSchema.ItemRc, stockUnit: StockUnit) {
    this.#formDialogRef = this.modal.openDialog(EditItemDialogComponent, {
      data: {
        menuItem,
        menuId: this.getMenuId(),
        stockUnit,
      },
      disableClose: true,
    });
    this.#formDialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.editMenuItem(menuItem);
      }
    });
  }

  openCreateProductDialog(name: string): void {
    this.initProductForm(name);
    this.#productDialogRef = this.modal.openDialog(CreateProductDialogComponent, {
      data: {
        form: this.productForm,
        title: 'Новая позиция',
        name,
        close: (confirm: boolean) => this.closeCreateProductDialog(confirm),
      },
      disableClose: true,
    });

    this.#productDialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.createProduct();
      }
    });
  }

  private closeDialogForm(confirm: boolean, form: FormGroup, dialogRef = this.#formDialogRef): void {
    if (confirm) {
      if (form.valid) {
        dialogRef.close(confirm);
      } else {
        this.validationErrorsService.markFormControls(form);
      }
    } else {
      dialogRef.close(confirm);
    }
  }

  closeMenuItemsDialog(confirm: boolean, form: FormGroup<MenuItemsForm>): void {
    this.closeDialogForm(confirm, form);
  }

  openDuplicateMenuDialog(menu: Menu | MenuRc): Observable<boolean> {
    this.#formDialogRef = this.modal.openDialog(MenuDuplicateDialogComponent, {
      data: {
        menu,
      },
      disableClose: true,
    });
    return this.#formDialogRef.afterClosed().pipe(
      first(),
      switchMap((confirm) => {
        if (confirm) {
          return this.duplicateMenu(menu.id);
        }
        return of(false);
      }),
    );
  }

  closeDuplicateForm(confirm: boolean): void {
    this.closeDialogForm(confirm, this.duplicateForm);
  }

  closeEditItemDialog(confirm: boolean): void {
    this.closeDialogForm(confirm, this.editItemForm);
  }

  closeSubsectionForm(confirm: boolean): void {
    this.closeDialogForm(confirm, this.subsectionForm);
  }

  closeSectionForm(confirm: boolean): void {
    this.closeDialogForm(confirm, this.sectionForm, this.sectionDialogRef);
  }

  closeMenuForm(confirm: boolean): void {
    this.closeDialogForm(confirm, this.form);
  }

  closeOnlineDialog(): void {
    this.#formDialogRef.close(false);
  }

  updateOnlineMenuActiveState(menuId: string, active: boolean): void {
    if (!this.onlineForm) {
      return;
    }

    this.onlineForm.controls.active.disable({ emitEvent: false });

    this.menuStorage
      .onlineMenuSetActiveState({ input: { active, menuId } })
      .pipe(
        first(),
        map((res) => res.data!.onlineMenuSetActiveState.onlineMenu),
      )
      .subscribe(
        (onlineMenu) => {
          const link = onlineMenu.active ? this.getOnlineMenuLink(onlineMenu) : '';
          this.onlineForm.controls.link.patchValue(link);

          this.refreshMenu();

          this.onlineForm.controls.active.enable({ emitEvent: false });
        },
        () => {
          this.onlineForm.controls.active.enable({ emitEvent: false });
        },
      );
  }

  copyLinkToClipboard(link: string): void {
    navigator.clipboard.writeText(link).then(() => {
      this.showSuccessNotification('Ссылка успешно скопирована');
    });
  }

  downloadQR(): void {
    this.showSuccessNotification('QR-код успешно загружен');
  }
  createPageRequestInput(page: number, size: number): PageRequestInput {
    return {
      page: page,
      size,
    };
  }
  createInCatalogFilterInput(search?: string, parentSectionId?: string, maxDepth?: string): MenuElementFilterInput {
    let filter: MenuElementFilterInput = {};

    if (parentSectionId) {
      filter = { ...filter, menuIds: [parentSectionId] };
    }

    if (maxDepth) {
      filter = { ...filter, depthLevel: [+maxDepth] };
    }

    if (search) {
      filter = { ...filter, name: search, depthLevel: undefined };
    }

    return filter;
  }

  closeCreateProductDialog(confirm: boolean): void {
    if (confirm) {
      if (this.productForm.valid) {
        this.#productDialogRef.close(confirm);
      } else {
        this.validationErrorsService.markFormControls(this.productForm);
      }
    } else {
      this.#productDialogRef.close(confirm);
    }
  }

  deleteMenu(id: string): Observable<boolean> {
    this.#deleteDialogRef = this.modal.openDialog(MenuDeleteDialogComponent);
    return this.#deleteDialogRef.afterClosed().pipe(
      first(),
      switchMap((confirm) => {
        if (confirm) {
          return this.menuStorage.deleteMenuRc({ id }).pipe(
            first(),
            tap(() => this.showSuccessNotification('Меню успешно удалено')),
            map(() => true),
          );
        } else {
          return of(false);
        }
      }),
    );
  }

  onCancelDelete() {
    this.#deleteDialogRef.close(false);
  }

  onConfirmDelete() {
    this.#deleteDialogRef.close(true);
  }

  deleteSection(section: CoreSchema.SectionRc): void {
    this.#deleteDialogRef = this.modal.openDialog(MenuDeleteDialogComponent);
    this.#deleteDialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.menuStorage
          .deleteMenuSectionRc({ id: section.id })
          .pipe(first())
          .subscribe(() => {
            this.showSuccessNotification('Категория меню успешно удалена');
            this.updateMenuItem.next({ element: section, delete: true });
          });
      }
    });
  }

  deleteMenuItem(menuItem: CoreSchema.ItemRc): void {
    this.#deleteDialogRef = this.modal.openDialog(MenuDeleteDialogComponent);
    this.#deleteDialogRef
      .afterClosed()
      .pipe(
        switchMap((confirm) => {
          if (confirm) {
            return this.menuStorage.deleteMenuItemRc({ id: menuItem.id }).pipe(
              tap(() => {
                this.showSuccessNotification('Позиция меню успешно удалена');
                this.updateMenuItem.next({ element: menuItem, delete: true });
              }),
              first(),
              map(() => true),
            );
          }
          return of(false);
        }),
      )
      .subscribe();
  }

  private refreshMenu(): void {
    this.refreshMenu$.next(true);
  }

  refreshSectionItems(subsectionId: string | undefined): void {
    if (!subsectionId) {
      return;
    }
    this.tableTreeService.refreshRowItems(subsectionId);
  }

  getAllProducts(): Observable<Product[]> {
    return this.catalogStorage
      .getCatalogProductsShort(this.catalogStorage.getCatalog()?.id)
      .pipe(map((res) => res.data.allInCatalogPageable.content.map((item) => item as Product)));
  }

  getProducts(pageRequest: PageRequestInput, searchText: string | undefined): QueryResult<'products'> {
    return this.productStorage.getProducts({
      pageRequest,
      filter: {
        search: searchText || '',
      },
    });
  }

  getMenuItemsBySection(sectionId: string | undefined | null): Observable<MenuItem[]> {
    if (!sectionId) {
      return of([]);
    }
    return this.menuStorage.getMenuItemsBySection({ sectionId }).pipe(map((res) => res.data.allMenuItemsBySection));
  }

  private getProductByStockUnitId(stockUnitId: string | undefined): Observable<Product | null> {
    if (!stockUnitId) {
      return of(null);
    }
    return this.menuStorage
      .getAllProductsPageable({
        filter: {
          catalogId: this.catalogStorage.getCatalog()?.id,
          stockUnitIds: [stockUnitId],
        },
        pageRequest: { page: 0, size: 20 },
      })
      .pipe(map((res) => res.data.allCatalogProductsPageable.content[0] as Product));
  }

  getMenuSection(sectionId: string): Observable<MenuSection> {
    return this.menuStorage.getMenuSection({ id: sectionId }).pipe(map((res) => res.data.menuSection));
  }

  getAllMenusRc(pageRequest: PageRequestInput, filter: MenuRcFilterInput, storeIds: string[]): Observable<MenusRc | null> {
    return this.menuStorage
      .getAllMenusRc({
        pageRequest,
        filter,
        storeIds,
      })
      .pipe(map((res) => res.data.menusRc || null));
  }
  getAllMenus(pageRequest: PageRequestInput, filter: MenuFilterInput, storeIds: string[]): Observable<MenuPage | null> {
    return this.menuStorage
      .getAllMenus({
        pageRequest,
        filter,
        storeIds,
      })
      .pipe(map((res) => res.data.allMenusByStoreIdsPageable || null));
  }

  getModifierGroups(product?: Product | null): Observable<ModifierGroupOutput[]> {
    if (!product) {
      return of([]);
    }
    return this.modifierGroupStorage
      .getModifierGroupsShort({ filterInput: { productIds: [product.id] } })
      .pipe(map((res) => res.data.modifierGroups.content));
  }

  toMenuPage(menuId: string) {
    const orgId = this.sessionStorage.getOrgId();
    return this.router.navigate([orgId, RootNavigationRoute.catalog, CatalogRoute.menus, menuId], {
      queryParams: {
        page: '1',
      },
    });
  }

  toMenuList() {
    const orgId = this.sessionStorage.getOrgId();
    return this.router.navigate([orgId, RootNavigationRoute.catalog, CatalogRoute.menus]);
  }

  private getOnlineMenuLink(onlineMenu: OnlineMenu): string {
    return `${ONLINE_MENU_LINK}${onlineMenu.shortId}`;
  }

  moveMenuElement(element: MenuElementRc, onPositionOf: Row<MenuElementRc>, insideCategory?: boolean, firstInList?: boolean): void {
    let menuElementType: 'section' | 'item';
    let newParentId: string | null;
    if ('__typename' in element && element['__typename'] === 'SectionRc') {
      menuElementType = 'section';
    } else {
      menuElementType = 'item';
    }
    if (firstInList) {
      this.menuStorage
        .moveMenuElements({
          input: { elementIds: [element.id], newLeftPosition: 1 },
        })
        .pipe(first())
        .subscribe((_) => {
          if (menuElementType === 'item') {
            const menuItem = element as itemRc;
            this.updateMenuItem.next({ element: { ...menuItem, sectionId: null } });
          } else {
            const menuSection = element as SectionRc;
            this.updateMenuItem.next({ element: { ...menuSection, parentId: null } });
          }
        });
    } else {
      const newPositionElementId = onPositionOf.data?.id!;
      this.menuStorage
        .getMenuElementShort({ elementId: newPositionElementId })
        .pipe(
          mergeMap((res) => {
            const onPositionOf = res.data.menuRcElement.element;
            if ('__typename' in onPositionOf && onPositionOf['__typename'] === 'SectionRc') {
              newParentId = (onPositionOf as itemRc).sectionId || null;
            } else {
              newParentId = (onPositionOf as SectionRc).parentId || null;
            }
            let newLeftMargin = onPositionOf.rightMargin + 1;

            if (insideCategory) {
              newParentId = onPositionOf.id;
              newLeftMargin = onPositionOf.leftMargin + 1;
            }

            return this.menuStorage.moveMenuElements({
              input: {
                elementIds: [element.id],
                newLeftPosition: newLeftMargin,
              },
            });
          }),
          first(),
        )
        .subscribe(() => {
          if (menuElementType === 'item') {
            const menuItem = element as itemRc;
            this.updateMenuItem.next({ element: { ...menuItem, sectionId: newParentId } });
          } else {
            const menuSection = element as SectionRc;
            this.updateMenuItem.next({ element: { ...menuSection, parentId: newParentId } });
          }
        });
    }
  }

  getCurrencyUnit() {
    return CURRENCY_SHORT[this.sessionStorage.getCurrencyUnit()];
  }
}
