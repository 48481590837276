import { gql } from 'apollo-angular';

export const mutationHideAllNotifications = gql`
  mutation hideAllNotifications {
    hideAllNotifications
  }
`;

export const mutationHideNotification = gql`
  mutation hideNotification($notificationId: UUID!) {
    hideNotification(notificationId: $notificationId)
  }
`;

export const mutationMarkNotificationAsShown = gql`
  mutation markNotificationAsShown($notificationId: UUID!) {
    markNotificationAsShown(notificationId: $notificationId)
  }
`;

export const mutationReadAllNotifications = gql`
  mutation readAllNotifications {
    readAllNotifications
  }
`;

export const mutationReadNotification = gql`
  mutation readNotification($notificationId: UUID!) {
    readNotification(notificationId: $notificationId)
  }
`;

export const mutationUnreadNotification = gql`
  mutation unreadNotification($notificationId: UUID!) {
    unreadNotification(notificationId: $notificationId)
  }
`;

export const queryActiveAccountNotifications = gql`
  query activeAccountNotifications {
    activeAccountNotifications {
      isRead
      notification {
        ... on PaymentCardNotBoundNotification {
          buttons {
            action {
              ... on GraphQLNotificationAction {
                methodName
                methodType
                parameters {
                  path
                  value
                }
              }
            }
            isHighlighted
            label
          }
          createdDate
          id
          message
          type
        }

        ... on PaymentInvoiceCreatedNotification {
          createdDate
          id
          message
          type
        }

        ... on SubscriptionsCancellationNotification {
          createdDate
          id
          message
          type
        }

        ... on SubscriptionsDueToExpireNotification {
          createdDate
          id
          message
          type
        }

        ... on SuccessfulPaymentNotification {
          createdDate
          id
          message
          type
        }

        ... on UnsuccessfulPaymentNotification {
          createdDate
          id
          message
          type
        }

        ... on XlsReportCreationStartedNotification {
          createdDate
          id
          message
          type
        }

        ... on XlsReportGeneratedNotification {
          buttons {
            action {
              ... on URLNotificationAction {
                url
              }
            }
            isHighlighted
            label
          }
          createdDate
          id
          message
          type
        }
      }
    }
  }
`;

export const subscriptionSubscribeOnNotifications = gql`
  subscription subscribeOnNotifications {
    subscribeOnNotifications {
      isRead
      notification {
        ... on PaymentCardNotBoundNotification {
          buttons {
            action {
              ... on GraphQLNotificationAction {
                methodName
                methodType
                parameters {
                  path
                  value
                }
              }
            }
            isHighlighted
            label
          }
          id
          message
          type
        }

        ... on PaymentInvoiceCreatedNotification {
          id
          message
          type
        }

        ... on SubscriptionsCancellationNotification {
          id
          message
          type
        }

        ... on SubscriptionsDueToExpireNotification {
          id
          message
          type
        }

        ... on SuccessfulPaymentNotification {
          id
          message
          type
        }

        ... on UnsuccessfulPaymentNotification {
          id
          message
          type
        }

        ... on XlsReportCreationStartedNotification {
          id
          message
          type
        }

        ... on XlsReportGeneratedNotification {
          buttons {
            action {
              ... on URLNotificationAction {
                url
              }
            }
            isHighlighted
            label
          }
          id
          message
          type
        }
      }
    }
  }
`;
