import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { ButtonVariant } from '@app/utils/constants/button-variants';
import { SVG_ICONS_TYPE } from '@constants';

@Component({
  selector: 'nm-modal-base',
  templateUrl: './modal-base.component.html',
  styleUrls: ['./modal-base.component.scss'],
})
export class ModalBaseComponent {
  @Input()
  cancelVariant: ButtonVariant = 'text';

  @Input()
  okVariant: ButtonVariant = 'text';

  @Input()
  showToolbar = true;

  @Input()
  showOk = true;

  @Input()
  okDisabled = false;

  @Input()
  okLoading = false;

  @Input()
  showCancel = true;

  @Input()
  cancelTitle = 'cancel';

  @Input()
  okTitle = 'ok';

  @Input()
  keydownEsc = true;

  @Input()
  keydownEnter = true;

  @Input()
  actionButtons: ActionButton[] = [];

  @Input()
  icon: SVG_ICONS_TYPE;

  @Input()
  headerPosition: 'left' | 'center' = 'left';

  @Input()
  testId: string;

  @Output()
  ok = new EventEmitter();

  @Output()
  closeDialog = new EventEmitter();

  @Output()
  cancel = new EventEmitter();

  @HostListener('document:keydown.escape')
  closeEmit() {
    if (!this.keydownEsc) {
      return;
    }

    this.closeDialog.emit();
  }

  constructor(private elRef: ElementRef) {}

  isContentHaveScroll(): boolean {
    const contentBlock = this.elRef.nativeElement.querySelector('.modal-base-content-container');
    return contentBlock.scrollHeight > contentBlock.clientHeight;
  }

  @HostListener('document:keydown', ['$event'])
  okEmit($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      $event.stopPropagation();
      if (!this.keydownEnter) {
        return;
      }
      this.ok.emit();
    }
  }
}

export interface ActionButton {
  label: string;
  onClick: (event: MouseEvent) => void;
  disabled: boolean;
  variant?: ButtonVariant;
  type: 'button' | 'submit';
}
