<nm-modal-base
  [cancelTitle]="cancelTitle"
  [okTitle]="okTitle"
  (ok)="onOk()"
  (cancel)="onCancel()"
  okTitle="Закрыть"
  cancelTitle="Отменить"
  okVariant="filled"
  testId="form-confirm-save-modal"
>
  <div modal-base-title>{{ title }}</div>
  <div>{{ content }}</div>
</nm-modal-base>
