import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { RootNavigationRoute } from '@constants';
import { SessionStorage } from '@services/api';
import { AccountStorage } from '@services/core';
import { FeatureEnableService, RedirectService } from '@services/shared';

@Injectable()
export class FeatureProfileGuard implements CanActivate {
  constructor(
    private features: FeatureEnableService,
    private accountStorage: AccountStorage,
    private sessionStorage: SessionStorage,
    private redirectService: RedirectService,
  ) {}

  canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const orgId = this.sessionStorage.getOrgId();

    if (state.url.endsWith(RootNavigationRoute.bonuses)) {
      const isCashier = this.accountStorage.getIsCacher();

      if (!isCashier) {
        return this.redirectService.redirectToStart(orgId);
      }
    }

    if (state.url.includes('master/new')) {
      return this.redirectService.redirectToSetup(this.sessionStorage.getOrgId());
    }

    // if (state.url.includes(AuthRoute.acceptInvitation)) {
    //   this.router.navigate([AuthRoute.auth, AuthRoute.invitation]);
    // }

    return true;
  }
}
