import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { NotifyService } from '@services/shared';
import {
  CatalogSection,
  MutationBatchResult,
  MutationCreateCatalogSectionV2Args,
  MutationDeleteCatalogSectionArgs,
  MutationMoveSectionArgs,
  MutationResult,
  MutationUpdateCatalogSectionV2Args,
  QueryAllCatalogSectionsPageableArgs,
  QueryCatalogSectionArgs,
  QueryResult,
  QuerySectionsArgs,
  QuerySectionsByIdsArgs,
} from '@typings';

import { CategoriesApi } from './categories.api';

@Injectable({
  providedIn: 'root',
})
export class CategoriesStorage {
  #categories = new BehaviorSubject<CatalogSection[]>([]);
  categories$ = this.#categories.asObservable();

  #activeCategory = new BehaviorSubject<CatalogSection | null>(null);
  activeCategory$ = this.#activeCategory.asObservable();

  setCategories(categories: CatalogSection[]): void {
    this.#categories.next(categories);
  }

  getCategories(): CatalogSection[] {
    return this.#categories.getValue();
  }

  setActiveCategory(category: CatalogSection): void {
    this.#activeCategory.next(category);
  }

  getActiveCategory(): CatalogSection {
    return this.#activeCategory.getValue()!;
  }

  constructor(private categoriesApi: CategoriesApi, private notifyService: NotifyService) {}

  createCategory(variables: MutationCreateCatalogSectionV2Args): MutationResult<'createCatalogSectionV2'> {
    return this.categoriesApi.createCatalogSectionV2(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании категории',
      }),
    );
  }

  updateCategory(variables: MutationUpdateCatalogSectionV2Args): MutationResult<'updateCatalogSectionV2'> {
    return this.categoriesApi.updateCatalogSectionV2(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении категории',
      }),
    );
  }

  deleteCategory(variables: MutationDeleteCatalogSectionArgs): MutationResult<'deleteCatalogSection'> {
    return this.categoriesApi.deleteCatalogSection(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении категории',
        success: 'Категория успешно удалена',
      }),
    );
  }

  deleteCatalogSectionAll(variables: { id: string }[]): MutationBatchResult<'deleteCatalogSection'> {
    return this.categoriesApi.deleteCatalogSectionAll(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении категорий',
        success: 'Категории успешно удалены',
      }),
    );
  }

  moveSection(variables: MutationMoveSectionArgs): MutationResult<'moveSection'> {
    return this.categoriesApi.moveSection(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при перемещении категории',
      }),
    );
  }

  moveSectionBatch(variables: MutationMoveSectionArgs[]): MutationBatchResult<'moveSection'> {
    return this.categoriesApi.moveSectionBatch(variables).pipe(
      this.notifyService.$notify({
        error: `Ошибка при перемещении ${variables.length > 1 ? 'категорий' : 'категоии'}`,
      }),
    );
  }

  getCategoriesList(variables: QueryAllCatalogSectionsPageableArgs): QueryResult<'allCatalogSectionsPageable'> {
    return this.categoriesApi.allCatalogSectionsPageable(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении категорий',
      }),
    );
  }

  getSectionsList(variables?: QuerySectionsArgs): QueryResult<'sections'> {
    return this.categoriesApi.sections(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении категорий',
      }),
    );
  }

  getSectionsByIds(variables?: QuerySectionsByIdsArgs): QueryResult<'sectionsByIds'> {
    return this.categoriesApi.sectionsByIds(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении категорий',
      }),
    );
  }

  getCategory(variables: QueryCatalogSectionArgs): QueryResult<'catalogSection'> {
    return this.categoriesApi.catalogSection(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении категории',
      }),
    );
  }
}
