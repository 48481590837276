import { Pipe, PipeTransform } from '@angular/core';

import { UNIT_TYPE } from '@constants';

@Pipe({
  name: 'unitType',
})
export class UnitTypePipe implements PipeTransform {
  transform(type?: string): string {
    switch (type) {
      case UNIT_TYPE.PIECE:
        return 'шт';

      case UNIT_TYPE.GRAM:
        return 'г';

      case UNIT_TYPE.MILLILITER:
        return 'мл';

      case UNIT_TYPE.KILOGRAM:
        return 'кг';

      case UNIT_TYPE.LITER:
        return 'л';

      default:
        return '';
    }
  }
}
