<div [ngClass]="componentClasses" [attr.data-test-id]="testId">
  <nm-control-label *ngIf="label" [label]="label" [required]="required"></nm-control-label>

  <nm-form-field [disabled]="disabled" [error]="error" [focused]="opened" (click)="fieldClick()">
    <div *ngIf="!isRange; else range">
      <input
        #input
        matInput
        [placeholder]="placeholder"
        [matDatepicker]="picker"
        [disabled]="disabled"
        [readonly]="isSelectOnly"
        [(ngModel)]="value"
        (ngModelChange)="onChange($event)"
      />

      <mat-datepicker
        #picker
        (opened)="onCalendarToggle(true)"
        (closed)="onCalendarToggle(false)"
        (viewChanged)="onViewChanged($event)"
        (monthSelected)="monthSelected($event)"
        (yearSelected)="yearSelected($event)"
        [startView]="startView"
        [panelClass]="panelClasses"
      >
        <div *ngIf="showActionButtons">
          <mat-datepicker-actions>
            <div class="controls">
              <nm-button [fullWidth]="true" variant="outlined" matDatepickerCancel>Отменить</nm-button>
              <nm-button [fullWidth]="true" matDatepickerApply>Применить</nm-button>
            </div>
          </mat-datepicker-actions>
        </div>
      </mat-datepicker>
    </div>

    <ng-template #range>
      <mat-date-range-input
        class="date-range-input"
        #rangeInput
        [rangePicker]="picker"
        [comparisonStart]="compareRange?.start"
        [comparisonEnd]="compareRange?.end"
      >
        <input
          #input
          matStartDate
          [disabled]="disabled"
          [placeholder]="placeholderStart || placeholder"
          [(ngModel)]="rangeStart"
          (ngModelChange)="onRangeChanged()"
        />
        <input
          matEndDate
          [disabled]="disabled"
          [placeholder]="placeholderEnd || placeholder"
          [(ngModel)]="rangeEnd"
          (ngModelChange)="onRangeChanged()"
        />
      </mat-date-range-input>

      <mat-date-range-picker
        #picker
        (opened)="onCalendarToggle(true)"
        (closed)="onCalendarToggle(false)"
        (viewChanged)="onViewChanged($event)"
        [panelClass]="panelClasses"
      >
        <div *ngIf="showActionButtons || showRangeControls">
          <mat-datepicker-actions>
            <div class="range-controls" *ngIf="showRangeControls">
              <ng-content select="[controls]"></ng-content>
            </div>

            <div class="controls">
              <nm-button [fullWidth]="true" variant="outlined" matDatepickerCancel> Отменить </nm-button>

              <nm-button [fullWidth]="true" matDatepickerApply> Применить </nm-button>
            </div>
          </mat-datepicker-actions>
        </div>
      </mat-date-range-picker>
    </ng-template>

    <button icons class="icon-wrapper icon-wrapper-color" type="button" (click)="openCalendar()">
      <nm-svg-icon name="calendar" [size]="24" class="calendar-icon"></nm-svg-icon>
    </button>
  </nm-form-field>

  <nm-control-hint *ngIf="hint" [label]="hint" [type]="hintType"> </nm-control-hint>
</div>
