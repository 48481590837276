import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SVG_ICONS_TYPE } from '@constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nm-filter-label',
  templateUrl: './filter-label.component.html',
  styleUrls: ['./filter-label.component.scss'],
})
export class FilterLabelComponent {
  @Input() testId: string;
  @Input() iconLeft: SVG_ICONS_TYPE;

  @Output() filterClick = new EventEmitter<MouseEvent>();

  isOpened = false;
}
