<nm-modal-base
  (ok)="arrivalsService.deleteArrival(data.arrival, data.callbackFn)"
  (cancel)="arrivalsService.hideModal()"
  okTitle="Удалить"
  cancelTitle="Отменить"
  okVariant="filled"
>
  <div modal-base-title>Удалить поставку</div>
  <div>Вы уверены, что хотите удалить поставку?</div>
</nm-modal-base>
