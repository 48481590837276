import { Injectable } from '@angular/core';

import { NotifyService } from '@services/shared';
import { CoreSchema, MutationResult, QueryResult } from '@typings';

import { TablesApi } from './tables.api';

@Injectable({
  providedIn: 'root',
})
export class TablesStorage {
  constructor(private notifyService: NotifyService, private tablesApi: TablesApi) {}

  updateTablesGroups(variables: CoreSchema.MutationSaveStoreTableGroupsArgs): MutationResult<'saveStoreTableGroups'> {
    return this.tablesApi.updateTablesGroups(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при сохранении групп столов',
      }),
    );
  }

  tableGroups(variables: CoreSchema.QueryTableGroupsArgs): QueryResult<'tableGroups'> {
    return this.tablesApi.tableGroups(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении групп столов',
      }),
    );
  }

  tableGroup(variables: CoreSchema.QueryTableGroupArgs): QueryResult<'tableGroup'> {
    return this.tablesApi.tableGroup(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении групп столов',
      }),
    );
  }
}
