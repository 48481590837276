import { Pipe, PipeTransform } from '@angular/core';

import { pluralize } from '@utils';

@Pipe({
  name: 'pluralize',
})
export class PluralizePipe implements PipeTransform {
  transform(noun: string, suffix: string, count: number): string {
    return pluralize(count, noun, suffix);
  }
}
