<ng-template [ngIf]="checked === undefined" [ngIfElse]="withChecked">
  <mat-radio-button
    (change)="changeRadio.emit($event)"
    [value]="value"
    [disabled]="disabled"
    [disableRipple]="true"
    [attr.data-test-id]="testId"
  ></mat-radio-button>
</ng-template>

<ng-template #withChecked>
  <mat-radio-button
    (change)="changeRadio.emit($event)"
    [value]="value"
    [checked]="checked"
    [disabled]="disabled"
    [disableRipple]="true"
    [attr.data-test-id]="testId"
  ></mat-radio-button>
</ng-template>
