import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DateMaskDirective } from '@app/shared/directive/date-mask/date-mask.directive';

@NgModule({
  declarations: [DateMaskDirective],
  imports: [CommonModule],
  exports: [DateMaskDirective],
})
export class DateMaskModule {}
