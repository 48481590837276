import { gql } from 'apollo-angular';

import { fragmentPage } from './mutations';

export const queryGetAnalyticsWarehouseItemMoveHistoriesDetails = gql`
  query getAnalyticsWarehouseItemMoveHistoriesDetails(
    $filter: AnalyticsWarehouseItemMoveHistoriesDetailsFilterInput
    $pageRequest: PageRequestInput
    $sort: [GetAnalyticsWarehouseItemMoveHistoriesDetailsSort!]
  ) {
    getAnalyticsWarehouseItemMoveHistoriesDetails(filter: $filter, pageRequest: $pageRequest, sort: $sort) {
      content {
        arrivalDuringPeriod
        primePriceAtEndOfPeriod {
          amountValue
          currencyUnit
        }
        primePriceAtStartOfPeriod {
          amountValue
          currencyUnit
        }
        productName
        productType
        quantityAtEndOfPeriod
        quantityAtStartOfPeriod
        sectionName
        stockUnitId
        unitType
        warehouseName
        writtenOffDuringPeriod
      }
      ...Page
    }
  }
  ${fragmentPage}
`;

export const queryGetAnalyticsWarehouseItemMoveHistoriesDetailsTotal = gql`
  query getAnalyticsWarehouseItemMoveHistoriesDetails(
    $filter: AnalyticsWarehouseItemMoveHistoriesDetailsFilterInput
    $pageRequest: PageRequestInput
  ) {
    getAnalyticsWarehouseItemMoveHistoriesDetails(filter: $filter, pageRequest: $pageRequest) {
      total
    }
  }
`;

export const queryGetAnalyticsWarehouseItemMoveHistoryDetailsByStockUnit = gql`
  query getAnalyticsWarehouseItemMoveHistoryDetailsByStockUnit(
    $filter: AnalyticsWarehouseItemMoveHistoryDetailsFilterInput!
    $pageRequest: PageRequestInput
  ) {
    getAnalyticsWarehouseItemMoveHistoryDetailsByStockUnit(filter: $filter, pageRequest: $pageRequest) {
      content {
        documentDate
        documentId
        docNumber
        newPrimePrice {
          amountValue
          currencyUnit
        }
        newQuantity
        previousPrimePrice {
          amountValue
          currencyUnit
        }
        previousQuantity
        quantity
        previousUnit
        unit
        arrival
        writtenOff
        stockUnitName
        reason
        warehouseName
      }
      ...Page
    }
  }
  ${fragmentPage}
`;

export const queryStockUnitMovements = gql`
  query stockUnitMovements($filter: StockUnitMovementsFilterInput!, $pageRequest: PageRequestInput) {
    stockUnitMovements(filter: $filter, pageRequest: $pageRequest) {
      pageNumber
      pageSize
      total
      totalPages
      content {
        arrivalDuringPeriod
        primePrice
        product {
          id
          name
        }
        productType
        section {
          id
          name
        }
        quantityAtEndOfPeriod
        quantityAtStartOfPeriod
        storageRoom {
          id
          name
        }
        unit
        writtenOffDuringPeriod
      }
    }
  }
`;

export const queryStockUnitMovementsByProduct = gql`
  query stockUnitMovementsByProduct($filter: StockUnitMovementsByProductFilterInput!, $pageRequest: PageRequestInput) {
    stockUnitMovementsByProduct(filter: $filter, pageRequest: $pageRequest) {
      pageNumber
      pageSize
      total
      totalPages
      content {
        arrival
        docDate
        docId
        reason
        primePrice
        newQuantity
        number
        previousQuantity
        revertedDate
        skuName
        storageRoomName
        unit
        writtenOff
      }
    }
  }
`;
