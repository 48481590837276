import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { COLOR_VALUE, ColorSection, ColorSectionExtended, ColorSectionString, ColorSectionStringExtended } from '@typings';

export enum _OLD_COLORS {
  'Carrot' = '#FD6C36', // Оранжевый
  'DeepPink' = '#ED368C', // Розовый
  'Vivid orange' = '#FFB515', // Мандариновый
  'Eggplant' = '#9C0D64', // Баклажановый
  'PurpleHeart' = '#73349F', // Пурпурный
  'ModerateBlue' = '#345B7E', // Умеренный синий
  'Aqua' = '#0092FF', // Голубой
  'Aspid' = '##5151C2', // Лавандовый
  'BlueDust' = '#002894', // Темно-синий
  'MountainMeadow' = '#32B69B', // Зеленый

  // 'Blue' = '#3AD7FF',
  // 'Peach' = '#FFAF8E',
  // 'LightSandy' = '#FBED9A',
  // 'GlossyPink' = '#FD98BC',
  // 'DeepCoral' = '#FC4242',
  // 'Aquamarine' = '#5EFAD2',
  // 'GlossyPurple' = '#E9BEF8',
  // 'ModeratelyGreen' = '#CDDDCA',
}

// export enum COLORS {
//   COLOR_V0 = '#000000', // 'ErrorBlack' = '#000000', // Чёрный default on error!!!

//   COLOR_V1 = '#FFC9AB', // 'Orange' = '#FFC9AB', // Оранжевый
//   COLOR_V2 = '#FFB0F7', // 'Pink' = '#FFB0F7', // Розовый
//   COLOR_V3 = '#FBED9A', // 'Yellow' = '#FBED9A', // Желтый
//   COLOR_V4 = '#FF9E9E', // 'Red' = '#FF9E9E', // Красный
//   COLOR_V5 = '#DFAFFF', // 'Purple' = '#DFAFFF', // Пурпурный
//   COLOR_V6 = '#C0D9FF', // 'Blue' = '#C0D9FF', // Синий
//   COLOR_V7 = '#ABECFD', // 'LightBlue' = '#ABECFD', // Голубой
//   COLOR_V8 = '#A8A8FF', // 'Lavender' = '##A8A8FF', // Лавандовый
//   COLOR_V9 = '#B7EEE3', // 'Mint' = '#B7EEE3', // Мятный
//   COLOR_V10 = '#CDDDCA', // 'Green' = '#CDDDCA', // Зеленый
// }

export const COLOR_ERROR = '#000000';

export const COLORS_BASE: ColorSectionString = {
  COLOR_V1: '#FFC9AB', // 'Orange' = '#FFC9AB', // Оранжевый
  COLOR_V2: '#FFB0F7', // 'Pink' = '#FFB0F7', // Розовый
  COLOR_V3: '#FBED9A', // 'Yellow' = '#FBED9A', // Желтый
  COLOR_V4: '#FF9E9E', // 'Red' = '#FF9E9E', // Красный
  COLOR_V5: '#DFAFFF', // 'Purple' = '#DFAFFF', // Пурпурный
  COLOR_V6: '#C0D9FF', // 'Blue' = '#C0D9FF', // Синий
  COLOR_V7: '#ABECFD', // 'LightBlue' = '#ABECFD', // Голубой
  COLOR_V8: '#A8A8FF', // 'Lavender' = '##A8A8FF', // Лавандовый
  COLOR_V9: '#B7EEE3', // 'Mint' = '#B7EEE3', // Мятный
  COLOR_V10: '#CDDDCA', // 'Green' = '#CDDDCA', // Зеленый
};

export const COLORS: ColorSectionStringExtended = {
  COLOR_V0: COLOR_ERROR,
  ...COLORS_BASE,
};

export const COLORS_SET: Record<ColorSectionExtended, COLOR_VALUE> = {
  COLOR_V0: {
    name: 'Ошибка',
    key: 'Error',
  },
  COLOR_V1: {
    name: 'Оранжевый',
    key: 'Orange',
  },
  COLOR_V2: {
    name: 'Розовый',
    key: 'Pink',
  },
  COLOR_V3: {
    name: 'Жёлтый',
    key: 'Yellow',
  },
  COLOR_V4: {
    name: 'Красный',
    key: 'Red',
  },
  COLOR_V5: {
    name: 'Пурпурный',
    key: 'Purple',
  },
  COLOR_V6: {
    name: 'Синий',
    key: 'Blue',
  },
  COLOR_V7: {
    name: 'Голубой',
    key: 'LightBlue',
  },
  COLOR_V8: {
    name: 'Лавандовый',
    key: 'Lavender',
  },
  COLOR_V9: {
    name: 'Мятный',
    key: 'Mint',
  },
  COLOR_V10: {
    name: 'Зеленый',
    key: 'Green',
  },
};

export enum COLORS_LOYALTY {
  COLOR_V1 = '#FD6C36',
  COLOR_V2 = '#ED368C',
  COLOR_V3 = '#FFB515',
  COLOR_V4 = '#9C0D64',
  COLOR_V5 = '#73349F',
  COLOR_V6 = '#345B7E',
  COLOR_V7 = '#0092FF',
  COLOR_V8 = '#5151C2',
}

export const COLORS_BASE_LIST: AutocompleteOption<ColorSection>[] = Object.keys(COLORS_BASE).map((color) => ({
  id: color,
  label: COLORS_SET[color].name,
  type: 'item',
  imageColor: COLORS[color],
  data: color,
}));
