import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FunctionTypeVoid, WriteOffDoc } from '@typings';

import { LeavingsService } from '../../services/leavings/leavings.service';

@Component({
  selector: 'nm-delete-leaving-dialog',
  templateUrl: './delete-leaving-dialog.component.html',
})
export class DeleteLeavingDialogComponent {
  constructor(
    public leavingsService: LeavingsService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      leaving: WriteOffDoc;
      callbackFn?: FunctionTypeVoid;
    },
  ) {}
}
