import { Component, Input } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'nm-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
})
export class ProgressSpinnerComponent {
  @Input() testId: string;
  @Input() mode: ProgressSpinnerMode = 'determinate';
  @Input() value = 0;
  @Input() diameter: number = 18;

  constructor() {}
}
