import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconButtonModule } from '@app/shared/component/icon-button/icon-button.module';

import { MicroBannerComponent } from './micro-banner.component';

@NgModule({
  declarations: [MicroBannerComponent],
  imports: [CommonModule, IconButtonModule],
  exports: [MicroBannerComponent],
})
export class MicroBannerModule {}
