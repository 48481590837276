<div
  #rowElement
  class="row"
  [class.highlighted]="row?.highlighted"
  [class.drag-hovered]="dragHovering"
  [class.selected]="row.selected"
  [class.drag-mode]="dragModeStart || table.isDragging"
  [style.width]="tableWidth ? tableWidth + 'px' : ''"
  [class.color]="row?.rowColor"
  [style.background-color]="row?.rowColor ? row.rowColorBackground : 'none'"
  [class.mobile-row]="bos.getIsMobile()"
  [class.table-dragging]="isDragging"
  [ghostTemplate]="ghostTemplate"
  (nmDragEnd)="onDragEnd()"
  (nmDrop)="onDropRow($event)"
  (nmDragStart)="onDragStart()"
  [data]="row"
  (click)="rowClick(row.rowDataId)"
  [rowId]="row.rowDataId"
  nmDragAndDropItem
>
  <div class="pointer up"></div>
  <div class="pointer down"></div>
  <div
    class="drop-inside"
    (dragover)="onDragOver()"
    (dragleave)="onDragLeave()"
    *ngIf="row?.isCategoryRow && table.isDragging"
  ></div>
  <div class="drag-placeholder" *cdkDragPlaceholder></div>
  <div
    *ngIf="
      config.dragAndDropOptions?.enable && (breakpointObserverService.isMobile$ | async) === false && !table.isDragging
    "
    (mouseDown)="onMouseDown()"
    class="drag-handler"
  >
    <nm-drag-handler (mousedown)="onMouseDown()"></nm-drag-handler>
  </div>

  <ng-template #ghostTemplate>
    <div class="drag-preview-container">
      <div class="drag-preview">
        <nm-svg-icon *ngIf="row.children?.length" [size]="24" [name]="dragPreviewIcon"></nm-svg-icon
        ><nm-svg-icon *ngIf="!row.children?.length" [size]="24" [name]="'draft'"></nm-svg-icon>Объектов:
        {{ (this.row.children?.length || 0) + 1 }}
      </div>
    </div>
  </ng-template>

  <div class="inner" id="row" [style.grid-template-columns]="gridTemplateColumns">
    <div
      class="column select-column"
      [style.width]="selectColWidth + 'px'"
      *ngIf="config.selectable"
      [class.pinned]="true"
    >
      <div
        class="number-wrapper"
        *ngIf="(config?.enumerable?.enabled && row?.enumerator !== undefined) || row.skipEnumerator"
      >
        {{ row.enumerator + 1 }}
      </div>
      <div class="checkbox-wrapper" *ngIf="row.rowDataId !== 'empty' && config.selectable">
        <nm-checkbox
          [checked]="!!row?.selected"
          (checkboxChange)="onSelectRowChange($event)"
          [indeterminate]="row?.selected === 'indeterminate'"
          (click)="checkboxClick()"
        ></nm-checkbox>
      </div>
    </div>

    <div
      class="column number-column"
      *ngIf="config?.enumerable?.enabled && !config.selectable"
      [class.grouped-row]="row?.isGroupedRow"
      [class.pinned]="true"
    >
      <div class="number-wrapper" *ngIf="row?.enumerator !== undefined">
        {{ row.enumerator + 1 }}
      </div>
    </div>

    <div
      *ngFor="let column of columns; let i = index"
      class="column"
      [class.hidden-column]="config?.columnsConfig[i]?.hidden"
      [class.pinned]="config?.columnsConfig[i]?.pinned"
      [ngStyle]="{ left: config?.columnsConfig[i]?.pinned ? this.getLeftForPinned(config?.columnsConfig, i) : '0' }"
      [class.expand-column]="i === expandColumn"
      [class.grouped-row]="row?.isGroupedRow"
      [class.transparent-border]="column.isTransparentBorder"
      [class.top-border]="column.isTopBorder"
      [ngClass]="[
        'column-numeric-' + (config?.columnsConfig[i]?.align || 'initial'),
        column?.type === 'numeric' ? 'column-numeric' : ''
      ]"
      [style.padding-left]="i === expandColumn ? rowPadding + 'px' : ''"
      [class.color]="config?.columnsConfig[i]?.columnColor || row.columnsData[i].color"
      [class.border-left]="checkBorder(i)"
      [class.border-right]="checkBorder(i, false)"
      [class.edit-column]="config?.columnsConfig[i]?.isEdit"
      [class.hover]="isMouseOver && isMouseOverIndex === i"
    >
      <div class="column-content" [style.text-align]="config?.columnsConfig[i]?.align || 'initial'">
        <div
          class="column-expand"
          (click)="onExpandClick()"
          [ngClass]="{ 'hide-expand': row.hideExpand }"
          *ngIf="(row?.children ? row?.children?.length : row?.lazyChildren) && i === expandColumn"
        >
          <nm-svg-icon *ngIf="row?.expanded" name="keyboardArrowDown" [size]="24"></nm-svg-icon>
          <nm-svg-icon *ngIf="!row?.expanded" name="keyboardArrowRight" [size]="24"></nm-svg-icon>
        </div>
        <div
          class="column-expand"
          [ngClass]="{ 'hide-expand': row.hideExpand }"
          *ngIf="
            (row?.children ? !row?.children?.length : !row?.lazyChildren) && i === expandColumn && config.expandable
          "
        ></div>

        <ng-container
          *ngIf="!contentIsDefined(column)"
          [ngTemplateOutlet]="column.template"
          [ngTemplateOutletContext]="{ data: column.data }"
        ></ng-container>
        <ng-container *ngIf="contentIsDefined(column)">
          <div class="col-content" [style.text-align]="config?.columnsConfig[i]?.align || 'initial'">
            {{ column.content }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="row?.expanded">
  <div class="rows">
    <ng-container *ngFor="let child of row?.children">
      <ng-container
        rowChild
        [rowPadding]="rowPadding"
        [columnsDataTemplates]="columnsDataTemplates"
        [gridTemplateColumns]="gridTemplateColumns"
        [config]="config"
        [row]="child"
        [update]="{ isMouseOver, isMouseOverIndex }"
        (selectRow)="selectRow.emit($event)"
        (expandRow)="expandRow.emit($event)"
        (dragStart)="dragStart.emit($event)"
        (dragEnd)="dragEnd.emit($event)"
        (click)="rowClick(child.rowDataId)"
      ></ng-container>
    </ng-container>
  </div>
</ng-container>
