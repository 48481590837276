import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ROUTE_CREATE_NEW, SettingsRouteParam } from '@constants';
import { PaySystemsService } from '@services/settings';
import { PaymentSystem } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class PaymentSystemResolver implements Resolve<PaymentSystem> {
  constructor(private router: Router, private paySystemsService: PaySystemsService) {
    // TODO: show preloader
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaymentSystem> {
    const id = route.params[SettingsRouteParam.paymentSystemId];

    return this.paySystemsService.getPaymentSystem(id).pipe(
      // TODO: hide preloader
      catchError((err) => {
        this.router.navigateByUrl(state.url.replace(`${id}`, ROUTE_CREATE_NEW));

        return throwError(() => err);
      }),
    );
  }
}
