import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { VatType, VatTypeStrict, VatTypeString } from '@typings';

export const VAT_TYPE: Omit<VatTypeStrict, 'DEFAULT' | 'VAT_110' | 'VAT_120'> = {
  NONE: 'NONE',
  VAT_0: 'VAT_0',
  VAT_10: 'VAT_10',
  VAT_20: 'VAT_20',
};

export const VAT_TYPE_STRING: Omit<VatTypeString, 'DEFAULT' | 'VAT_110' | 'VAT_120'> = {
  NONE: 'Не облагается',
  VAT_0: '0%',
  VAT_10: '10%',
  VAT_20: '20%',
};

export const VAT_TYPE_LIST: AutocompleteOption<Omit<VatType, 'DEFAULT' | 'VAT_110' | 'VAT_120'>>[] = Object.keys(VAT_TYPE).map((vat) => ({
  id: vat,
  label: VAT_TYPE_STRING[vat],
  type: 'item',
  data: vat,
}));
