import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AvatarModule } from '@app/shared/component/avatar/avatar.module';
import { SvgIconModule } from '@app/shared/component/svg-icon/svg-icon.module';
import { TooltipModule } from '@app/shared/directive/tooltip/tooltip.module';
import { LetModule } from '@ngrx/component';

import { TableTitleComponent } from './table-title.component';

@NgModule({
  declarations: [TableTitleComponent],
  imports: [CommonModule, SvgIconModule, AvatarModule, TooltipModule, LetModule, SvgIconModule],
  exports: [TableTitleComponent],
})
export class TableTitleModule {}
