import { gql } from 'apollo-angular';

import { fragmentMutationResult, fragmentPage, fragmentRequestResult } from './mutations';

export const fragmentCatalogSectionResult = gql`
  fragment CatalogSectionResult on CatalogSectionResult {
    ...RequestResult
    output {
      id
      name
    }
  }
  ${fragmentRequestResult}
`;

export const fragmentCatalogSectionTax = gql`
  fragment CatalogSectionTax on CatalogSection {
    positionType {
      entityId
      entityType
      code
    }
    snoValue {
      entityId
      entityType
      sno
    }
    vatValue {
      entityId
      entityType
      vat
    }
  }
`;

export const mutationCreateCatalogSectionV2 = gql`
  mutation createCatalogSectionV2($catalogSection: CatalogSectionCreateInputV2!) {
    createCatalogSectionV2(catalogSection: $catalogSection) {
      ...CatalogSectionResult
    }
  }
  ${fragmentCatalogSectionResult}
`;

export const mutationUpdateCatalogSectionV2 = gql`
  mutation updateCatalogSectionV2($catalogSection: CatalogSectionUpdateInputV2!) {
    updateCatalogSectionV2(catalogSection: $catalogSection) {
      output {
        id
        name
        depthLevel
        isParent
        workshop {
          id
          name
        }
        image {
          id
          imageSizes {
            url
          }
        }
        colorSection
        ...CatalogSectionTax
      }
    }
  }
  ${fragmentCatalogSectionTax}
`;

export const mutationDeleteCatalogSectionV2 = gql`
  mutation deleteCatalogSection($id: UUID!) {
    deleteCatalogSection(id: $id) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationMoveSection = gql`
  mutation moveSection($newSectionId: UUID, $targetSectionId: UUID!) {
    moveSection(newSectionId: $newSectionId, targetSectionId: $targetSectionId) {
      ...CatalogSectionResult
    }
  }
  ${fragmentCatalogSectionResult}
`;

export const queryAllCatalogSectionsPageableV2 = gql`
  query allCatalogSectionsPageable($filter: CatalogSectionFilterInput!, $pageRequest: PageRequestInput!) {
    allCatalogSectionsPageable(filter: $filter, pageRequest: $pageRequest) {
      total
      pageNumber
      pageSize
      totalPages
      content {
        id
        name
        depthLevel
        isParent
      }
    }
  }
`;

export const querySections = gql`
  query sections($filter: SectionsFilterInput, $pageRequest: PageRequestInput) {
    sections(filter: $filter, pageRequest: $pageRequest) {
      ...Page
      content {
        id
        name
        depthLevel
        isParent
        isDefault
        workshop {
          id
          name
        }
        parent {
          id
          name
          colorSection
          ...CatalogSectionTax
        }
        colorSection
        ...CatalogSectionTax
      }
    }
  }
  ${fragmentPage}
  ${fragmentCatalogSectionTax}
`;

export const querySectionsByIds = gql`
  query sectionsByIds($pageRequest: PageRequestInput, $sectionIds: [UUID!]!) {
    sectionsByIds(pageRequest: $pageRequest, sectionIds: $sectionIds) {
      ...Page
      content {
        id
        name
      }
    }
  }
  ${fragmentPage}
`;

export const queryCatalogSectionV2 = gql`
  query catalogSection($id: UUID!) {
    catalogSection(id: $id) {
      id
      name
      isParent
      parent {
        id
        name
        colorSection
        ...CatalogSectionTax
      }
      workshop {
        id
        name
      }
      image {
        id
        imageSizes {
          url
        }
      }
      colorSection
      ...CatalogSectionTax
    }
  }
  ${fragmentCatalogSectionTax}
`;
