import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

import { FormFieldModule } from '../form-field/form-field.module';
import { MenuModule } from '../menu/menu.module';

import { DropdownMenuComponent } from './dropdown-menu.component';

@NgModule({
  declarations: [DropdownMenuComponent],
  imports: [CommonModule, FormFieldModule, MenuModule, MatMenuModule, OverlayModule],
  exports: [DropdownMenuComponent],
})
export class DropdownMenuModule {}
