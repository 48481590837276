import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { BannerType } from '@typings';

@Component({
  selector: 'nm-micro-banner',
  templateUrl: './micro-banner.component.html',
  styleUrls: ['./micro-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MicroBannerComponent {
  @Input() type: BannerType = 'information';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() link: string = '';
  @Input() linkText: string = '';
  @Input() isClose: boolean = false;
  @Input() redirectPage: boolean = false;

  @Output() closeBanner = new EventEmitter<void>();
  @Output() clickRedirect = new EventEmitter<void>();

  onCloseBanner(): void {
    this.closeBanner.emit();
  }

  onClickRedirect(): void {
    this.clickRedirect.emit();
  }
}
