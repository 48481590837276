export const MULTIPLE_SPACES = /  +/g;

export const NOT_VALID_NAME_CHARS = /[^ a-zA-Zа-яА-ЯёЁ-]+/g;

export const NOT_PHONE_NUMBER_INPUT_CHARS = /[^0-9 ()+-]+/g;

export const NOT_PHONE_NUMBER_SPECIFIC_CHARS = /[^0-9 !“№%:,.;()-=+/<>#$%]+/g;
export const NOT_PHONE_NUMBER_CHARS = /[^0-9 +]+/g;

export const NOT_DATE_CHARS = /[^0-9 /.]+/g;
const firstNIntegerDigitsBegin = '^(-?\\d{0,';
const firstNIntegerDigitsEnd = '})([^.,]*)(\\..*)?';

const firstNFractionalDigitsBegin = '^(.*\\.)(\\d{0,';
const firstNFractionalDigitsEnd = '})(.*)';

// matches the first n integers into the 1st capturing group ($1), remaining integers into $2,
// and delimiter with fractional digits into $3
// expects string in the format: { 0 or more digits }{ '.' or ',' }{ 0 or more digits }
export const getFirstNIntegerDigitsRegexp = (n: number): RegExp => {
  return new RegExp(firstNIntegerDigitsBegin + n + firstNIntegerDigitsEnd);
};

// matches all integer digits with delimiter into the 1st capturing group ($1), first n fractional digits into $2,
// and remaining fractional digits into $3
// expects string in the format: { 0 or more digits }{ '.' or ',' }{ 0 or more digits }
export const getFirstNFractionalDigitsRegexp = (n: number): RegExp => {
  return new RegExp(firstNFractionalDigitsBegin + n + firstNFractionalDigitsEnd);
};
