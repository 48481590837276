import { ModifierEntityTypeStrict, ModifierType, ModifierTypeStrict, ModifierTypeString } from '@typings';

export const MODIFIER_TYPE: ModifierTypeStrict = {
  STOCK_UNIT_MODIFIER: 'STOCK_UNIT_MODIFIER',
  TEXT_MODIFIER: 'TEXT_MODIFIER',
};

export const MODIFIER_TYPE_TEXT: ModifierTypeString = {
  STOCK_UNIT_MODIFIER: 'Ценовой',
  TEXT_MODIFIER: 'Неценовой',
};

export const MODIFIER_ENTITY_TYPE: ModifierEntityTypeStrict = {
  PRODUCT: 'PRODUCT',
  SECTION: 'SECTION',
};

export const MODIFIER_TYPE_LIST_WITH_DESCRIPTION: Array<{
  value: ModifierType;
  label: string;
  description: string;
}> = [
  {
    value: MODIFIER_TYPE.STOCK_UNIT_MODIFIER,
    label: 'Ценовой',
    description: 'Влияет на стоимость позиции, например «топинги для кофе»',
  },
  {
    value: MODIFIER_TYPE.TEXT_MODIFIER,
    label: 'Неценовой',
    description: 'Не влияет на стоимость позиции, например «степень прожарки»',
  },
];
