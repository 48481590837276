import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'nm-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlPanelComponent {
  @Input() isContent: boolean = false;
}
