import { BillStatusStrict, BillStatusString, DeviceTypeStrict, DeviceTypeString } from '@typings';

export const BILL_STATUS: BillStatusStrict = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  EXPIRED: 'EXPIRED',
};

export const BILL_STATUS_STRING: BillStatusString = {
  OPEN: 'Оплатить',
  CLOSED: 'Оплачено',
  EXPIRED: 'Не оплачено',
};

export const DEVICE_TYPE: DeviceTypeStrict = {
  TABLET: 'TABLET',
  PHONE: 'PHONE',
};

export const DEVICE_TYPE_STRING: DeviceTypeString = {
  TABLET: 'Планшет',
  PHONE: 'Смартфон',
};
