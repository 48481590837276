import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { FiscalStatusStrict, FiscalStatusString } from '@typings';

export const FISCAL_STATUS: FiscalStatusStrict = {
  NotFiscal: 'NotFiscal',
  SuccessfullyFiscalized: 'SuccessfullyFiscalized',
  FiscalizationError: 'FiscalizationError',
};

export const FISCAL_STATUS_STRING: FiscalStatusString = {
  NotFiscal: 'Не фискальный',
  SuccessfullyFiscalized: 'Успешно фискализирован',
  FiscalizationError: 'Ошибка фискализации',
};

export const FISCAL_STATUS_LIST: AutocompleteOption[] = Object.keys(FISCAL_STATUS).map((status) => ({
  id: status,
  type: 'item',
  label: FISCAL_STATUS_STRING[status],
}));
