import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ModalRef, ModalService } from '@app/shared/component/dialog/abstract';
import { Device, DevicePage, PageRequestInput, Subscriber } from '@typings';

import { DeleteDeviceDialogComponent } from '../../pages/devices/delete-device-dialog/delete-device-dialog.component';

import { DevicesStorage } from './devices.storage';

@Injectable({
  providedIn: 'root',
})
export class DevicesService {
  modalRef: ModalRef<unknown>;

  constructor(private devicesStorage: DevicesStorage, private modalService: ModalService) {}

  getSubscriber(): Observable<Subscriber> {
    return this.devicesStorage.getSubscriber().pipe(map((res) => res.data.subscriber));
  }

  getDeviceList(pageRequest: PageRequestInput): Observable<DevicePage> {
    return this.devicesStorage.getDeviceList({ pageRequest }).pipe(map((res) => res.data.devices));
  }

  removeDevice(device: Device, callbackFn: () => void): void {
    if (!device || !device.id) {
      return;
    }

    this.modalRef.close();
    this.devicesStorage.removeDevice({ deviceId: device.id }).subscribe(callbackFn);
  }

  showDeleteModal(device: Device, callbackFn: () => void): void {
    this.modalRef = this.modalService.openDialog(DeleteDeviceDialogComponent, { data: { device, callbackFn } });
  }

  hideModal(): void {
    if (!this.modalRef) {
      return;
    }

    this.modalRef.close();
  }
}
