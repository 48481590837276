import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'nm-form-row',
  templateUrl: './form-row.component.html',
  styleUrls: ['./form-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormRowComponent {
  @Input() numberOfColumns: number = 1;
  @Input() isAction: boolean = false;
  @Input() isLabel: boolean = false;

  get gridTemplateColumns(): string {
    return `repeat(${this.numberOfColumns}, minmax(0, 1fr))`;
  }
}
