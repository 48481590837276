import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AvatarModule } from '@app/shared/component/avatar/avatar.module';
import { SvgIconModule } from '@app/shared/component/svg-icon/svg-icon.module';

import { OrganizationItemComponent } from './organization-item.component';

@NgModule({
  declarations: [OrganizationItemComponent],
  exports: [OrganizationItemComponent],
  imports: [CommonModule, SvgIconModule, AvatarModule],
})
export class OrganizationItemModule {}
