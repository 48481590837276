import { SVG_ICONS_TYPE } from '@constants';
import { CoreSchema } from '@typings';

export type NotificationAction = CoreSchema.NotificationAction;
export type URLNotificationAction = CoreSchema.UrlNotificationAction;
export type GraphQLNotificationAction = CoreSchema.GraphQlNotificationAction;
export type AccountNotification = CoreSchema.AccountNotification;

export enum ComponentNotificationType {
  ALERT = 'alert',
  SUCCESS = 'success',
  DEFAULT = 'default',
  INFORMATION = 'information',
}

export type NotificationType = 'default' | 'success' | 'information' | 'alert';
export type Notification = {
  type: NotificationType;
  title: string;
  createdDate?: string;
  id?: string;
  isButtons?: boolean;
  isRead?: boolean;
  isStub?: boolean;
  buttonDeclineTitle?: string;
  buttonAcceptTitle?: string;
  acceptAction?: object;
};

export type AlertType = 'information' | 'success' | 'warning' | 'error';
export type Alert = {
  id?: string;
  type: AlertType;
  icon?: SVG_ICONS_TYPE;
  title?: string;
  description?: string;
  link?: string;
  linkText?: string;
  isIcon?: boolean;
  isTimer?: boolean;
  timer?: number;
  isClose?: boolean;
};

// TODO: move to utils
export const notificationToAlert = (notification: Notification): Alert => {
  const type: Record<NotificationType, AlertType> = {
    default: 'information',
    success: 'success',
    information: 'warning',
    alert: 'error',
  };

  return { type: type[notification.type], description: notification.title };
};
