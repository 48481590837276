import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { Drop } from './drag-and-drop.types';
import { DragAndDropItemDirective } from './drag-and-drop-item.directive';
import { DragAndDropListService } from './drag-and-drop-list.service';

@Directive({
  selector: '[nmDragAndDropList]',
})
export class DragAndDropListDirective implements AfterViewInit, OnDestroy {
  @Input() placeholder: TemplateRef<unknown> | undefined;
  @Input() nmDragAndDropHorizontal = false;
  @Input() dropListDisabled = false;

  @Output() nmDragEnd: EventEmitter<Drop<unknown>> | null = new EventEmitter<Drop<unknown>>();
  @Output() nmDrag = new EventEmitter<Drop<unknown>>();

  lastDragEnterItem: DragAndDropItemDirective;
  itemsUpdated$ = new Subject<void>();
  isDragged = false;
  @HostListener('drop')
  onDrop() {
    this.isDragged = false;
  }

  @HostListener('dragstart')
  onDragStart() {
    this.isDragged = true;
  }

  @HostListener('dragend')
  onDragEnd() {
    this.isDragged = false;
  }

  constructor(public dropZone: ElementRef, public dragAndDropListService: DragAndDropListService) {
    this.dragAndDropListService.list$.next(this);
  }

  ngAfterViewInit() {
    this.dragAndDropListService.items$.subscribe((items) => {
      this.itemsUpdated$.next();
      this.itemsUpdated$.complete();
      this.itemsUpdated$ = new Subject<void>();

      items.forEach((i: DragAndDropItemDirective, index: number) => {
        this.updateDragItems(i, index, this.itemsUpdated$);
      });
    });
  }

  updateDragItems(i: DragAndDropItemDirective, index: number, itemsUpdated$: Subject<void>) {
    i.id = index;
    i.placeholder = this.placeholder;
    i.nmDragAndDropHorizontal = this.nmDragAndDropHorizontal;
    i.dropzoneElement = this.dropZone.nativeElement;
    if (this.dropListDisabled) {
      i.isDraggable = false;
    }
    i.nmDragEnd.pipe(takeUntil(itemsUpdated$)).subscribe((dragEnd) => {
      let newPosition = this.lastDragEnterItem?.id;

      let placeOnTop = this.lastDragEnterItem?.placeholderOnTop;
      const droppedInside = document.elementFromPoint(dragEnd.event.clientX, dragEnd.event.clientY)?.classList.contains('drop-inside');
      this.nmDragEnd!.emit({
        ...dragEnd,
        newPosition,
        placeOnTop,
        dropInside: droppedInside,
      });

      this.refreshItemsIndexes(dragEnd.prevPosition || 0, newPosition || 0);
      this.dragAndDropListService.drop.next();
    });

    i.nmDrag.pipe(takeUntil(itemsUpdated$)).subscribe((dragEnd) => {
      this.nmDrag.emit(dragEnd);
    });

    i.lastDragEnterItem.pipe(takeUntil(itemsUpdated$)).subscribe((lastDragOverItem) => {
      this.lastDragEnterItem = lastDragOverItem;
    });
  }

  refreshItemsIndexes(prevPosition: number, newPosition: number) {
    let items = this.dragAndDropListService.items$.getValue();
    moveItemInArray(items, prevPosition, newPosition);

    items = items.map((i, index) => {
      i.id = index;
      return i;
    });

    this.dragAndDropListService.items$.next(items);
  }
  ngOnDestroy(): void {
    this.dragAndDropListService.items$.next([]);
    this.dragAndDropListService.list$.next(null);
    this.nmDragEnd = null;
  }
  @HostBinding('class') classes = 'dropzone';
}
