<div *ngIf="title" class="alert-banner {{ type }}" [style.height]="height">
  <span>
    <span class="title">{{ title }}</span>

    <ng-container *ngIf="link && linkText"
      ><a class="link" target="_blank" rel="noopener noreferrer" [href]="link">{{ linkText }}</a></ng-container
    >

    <ng-container *ngIf="redirectPage && linkText">
      <span class="link" (click)="onClickRedirect()">{{ linkText }}</span></ng-container
    >
  </span>
</div>
