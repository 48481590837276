import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'nm-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent {
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() indeterminate: boolean = false;
  @Input() testId: string;

  @Output() checkboxChange = new EventEmitter<MatCheckboxChange>();
  @Output() indeterminateChange = new EventEmitter<boolean>();

  onCheckboxChange(evt: MatCheckboxChange) {
    this.checkboxChange.emit(evt);
  }

  onIndeterminateChange(evt: boolean) {
    this.indeterminateChange.emit(evt);
  }
}
