import { map, Observable, tap } from 'rxjs';

import { AutocompleteLoadingState, AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';

import { InfiniteLoaderService, InfiniteLoadFn } from '../infinite-loader/infinite-loader.service';

export class InfiniteLoaderAutocompleteService<T = unknown> {
  #infiniteLoaderService: InfiniteLoaderService<AutocompleteOption<T>, string>;

  options$: Observable<AutocompleteOption<T>[]>;
  loadingState$: AutocompleteLoadingState;

  #cachedOptions: Record<string, AutocompleteOption<T>> = {};

  searchFn: (searchText: string) => void;

  constructor() {}

  init(loadItemsFn: InfiniteLoadFn<AutocompleteOption<T>, string>, pageSize?: number) {
    this.#infiniteLoaderService = new InfiniteLoaderService<AutocompleteOption<T>, string>();
    this.#infiniteLoaderService.init({
      loadItemsFn,
      pageSize: pageSize || 10,
      itemIdFn: (option: AutocompleteOption<T>) => option.id,
    });

    this.searchFn = (searchText: string): void => {
      this.#infiniteLoaderService.filter(searchText);
    };

    this.options$ = this.#infiniteLoaderService.visibleItems$;

    this.loadingState$ = this.#infiniteLoaderService.state$.pipe(
      tap((state) => {
        this.cacheItems(this.#infiniteLoaderService.getAllItems(state));
      }),
      map((state) => ({
        reloading: state.reloading,
        canLoadMore: state.canLoad,
      })),
    );
  }

  cacheItems(items: AutocompleteOption<T>[]): void {
    items.forEach((item) => {
      this.cacheItem(item);
    });
  }

  cacheItem(item: AutocompleteOption<T>): void {
    this.#cachedOptions[item.id] = item;
  }

  search(text: string, force?: boolean) {
    this.#infiniteLoaderService.filter(text, force);
  }

  getSelectedOption(id: string): AutocompleteOption<T> | null {
    return this.#cachedOptions[id] || null;
  }

  loadItems() {
    this.#infiniteLoaderService.loadItems();
  }
}

export class InfiniteLoaderAutocompleteFactory {
  static getService<T = unknown>(
    loadItemsFn: InfiniteLoadFn<AutocompleteOption<T>, string>,
    initValue?: AutocompleteOption<T> | AutocompleteOption<T>[],
    pageSize?: number,
  ): InfiniteLoaderAutocompleteService<T> {
    const service = new InfiniteLoaderAutocompleteService<T>();
    service.init(loadItemsFn, pageSize);
    if (initValue) {
      if (Array.isArray(initValue)) {
        service.cacheItems(initValue);
      } else {
        service.cacheItem(initValue);
      }
    }
    return service;
  }
}
