<ng-container>
  <div *ngIf="title || description" class="micro-banner {{ type }}">
    <div class="content">
      <span *ngIf="title" class="title">{{ title }}</span>

      <span>
        <div *ngIf="description" class="description">{{ description }}</div>

        <ng-container *ngIf="link && linkText"
          ><a class="link" target="_blank" rel="noopener noreferrer" [href]="link">{{ linkText }}</a></ng-container
        >

        <ng-container *ngIf="redirectPage && linkText">
          <span class="link" (click)="onClickRedirect()">{{ linkText }}</span></ng-container
        >
      </span>
    </div>

    <nm-icon-button
      *ngIf="isClose"
      class="close"
      variant="text"
      icon="close"
      (clickButton)="onCloseBanner()"
    ></nm-icon-button>
  </div>
</ng-container>
