import { gql } from 'apollo-angular';

export const queryTimezones = gql`
  query timezones {
    timezones {
      timeZones {
        zoneId
      }
    }
  }
`;
