import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { PopupService } from '@core/service/popup.service';
import { Popup } from '@typings';

@Component({
  selector: 'nm-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent {
  @Input() popup: Popup;

  public loading$: Observable<boolean>;

  constructor(private popupService: PopupService) {
    this.loading$ = this.popupService.loading$;
  }
}
