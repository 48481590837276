import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_EXPANSION_PANEL_DEFAULT_OPTIONS, MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';

import { LetModule } from '@ngrx/component';

import { AvatarModule } from '../avatar/avatar.module';
import { DividerModule } from '../divider/divider.module';
import { DropdownMenuModule } from '../dropdown-menu/dropdown-menu.module';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { MenuModule } from '../menu/menu.module';
import { MicroBannerModule } from '../micro-banner/micro-banner.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';

import { NavigationDrawerComponent } from './navigation-drawer.component';

@NgModule({
  declarations: [NavigationDrawerComponent],
  imports: [
    CommonModule,
    IconButtonModule,
    SvgIconModule,
    MatListModule,
    DividerModule,
    MatExpansionModule,
    AvatarModule,
    DropdownMenuModule,
    MenuModule,
    MicroBannerModule,
    LetModule,
  ],
  exports: [NavigationDrawerComponent],
  providers: [
    {
      provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
      useValue: {
        collapsedHeight: '48px',
        expandedHeight: '48px',
        hideToggle: true,
        displayMode: 'flat',
      },
    },
  ],
})
export class NavigationDrawerModule {}
