import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RedirectService } from '@app/shared/service/redirect.service';
import { RootNavigationRoute, SettingsRoute, SettingsSubscriptionsRoute } from '@constants';
import { SessionStorage } from '@services/api';
import { SubscriptionsStorage } from '@services/settings';
import { BannerType } from '@typings';

import { FeatureEnableService } from './featureEnable.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionAlertsService {
  public remainSubscriptionDays: number;
  public readonly subscriptionsLink: string = `/${this.sessionStorage.getOrgId()}/${RootNavigationRoute.settings}/${
    SettingsRoute.subscriptions
  }/${SettingsSubscriptionsRoute.info}`;

  constructor(
    private featureEnableService: FeatureEnableService,
    private sessionStorage: SessionStorage,
    private subscriptionsStorage: SubscriptionsStorage,
    private redirectService: RedirectService,
  ) {}

  setRemainSubscriptionDays(): void {
    this.subscriptionsStorage
      .getServicePlanUsageSummary()
      .pipe(
        tap((res) => {
          if (res.data.servicePlanUsageSummary.showPaywall) {
            this.redirectService.redirectToPaywall(this.sessionStorage.getOrgId()!);
          }
        }),
        map((res) => res.data.servicePlanUsageSummary.remainingDays),
      )

      .subscribe((remainingDays) => (this.remainSubscriptionDays = remainingDays!));
  }

  private getDaysLabel(daysNumber: number): string {
    if (daysNumber === 1) return 'день';
    if (daysNumber <= 4) return 'дня';
    return 'дней';
  }

  public geSubscriptionAlertTitle(): string {
    if (this.remainSubscriptionDays <= 0) return 'Ваша подписка закончилась. ';
    return `Подписка закончится через ${this.remainSubscriptionDays} ${this.getDaysLabel(this.remainSubscriptionDays)}.`;
  }

  public getSubscriptionAlertType(): BannerType {
    return this.remainSubscriptionDays <= 3 ? 'error' : 'warning';
  }

  public showSubscriptionAlert(): boolean {
    return this.featureEnableService.monetization && this.remainSubscriptionDays <= 10;
  }

  public showSubscriptionBanner(): boolean {
    return this.showSubscriptionAlert() && !this.sessionStorage.getHideSubscriptionBanner();
  }

  public hideSubscriptionBanner(): void {
    this.sessionStorage.setHideSubscriptionBanner(true);
  }

  public getBannerTitle(): string {
    if (this.remainSubscriptionDays <= 0) {
      return 'Подписка закончилась';
    } else if (this.remainSubscriptionDays >= 4) {
      return 'Подписка скоро закончится';
    } else if (this.remainSubscriptionDays > 1) {
      return `Осталось ${this.remainSubscriptionDays} дня до окончания подписки`;
    } else {
      return `Остался 1 день до окончания подписки`;
    }
  }

  public getBannerDescription(): string {
    if (this.remainSubscriptionDays >= 1) {
      return 'Оплатите подписку, чтобы использовать все возможности системы';
    } else {
      return 'Вы можете продлить подписку, чтобы продолжить использовать все возможности системы';
    }
  }

  clickToSubscription() {
    this.redirectService.redirectToSubscription(this.sessionStorage.getOrgId());
  }
}
