import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IconsLib } from '@nomia/nomia-components-lib';

import { UtilsService } from '@core/service/utils.service';
import { OrganizationSite } from '@typings';

@Component({
  selector: 'nm-organization-card',
  templateUrl: './organization-card.component.html',
  styleUrls: ['./organization-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationCardComponent {
  public iconsLib = IconsLib;

  isHover: boolean;

  @Input() organization: OrganizationSite;
  @Input() isActive: boolean;
  @Input() isSettings: boolean;
  @Input() isLoading: boolean;

  @Output() clickCard = new EventEmitter();
  @Output() clickSettings = new EventEmitter();

  constructor(private utilsService: UtilsService) {
    this.isHover = this.utilsService.isTouch();
  }

  public onClickCard(): void {
    this.clickCard.emit();
  }

  public onClickSettings(): void {
    this.clickSettings.emit();
  }
}
