import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { queryCatalogByOrganization } from '@api';
import { ApiService } from '@core/service';
import { NotifyService } from '@services/shared';
import {
  Catalog,
  MutationCreateCatalogV2Args,
  MutationResult,
  MutationUpdateCatalogV2Args,
  PageRequestInput,
  Query,
  QueryAllInCatalogPageableArgs,
  QueryResult,
} from '@typings';

import { CatalogApi } from './catalog.api';

@Injectable({
  providedIn: 'root',
})
export class CatalogStorage {
  #catalog = new BehaviorSubject<Catalog | null>(null);
  catalog$ = this.#catalog.asObservable();

  setCatalog(catalog: Catalog | null): void {
    this.#catalog.next(catalog);
  }

  getCatalog(): Catalog {
    return this.#catalog.getValue()!;
  }

  constructor(private catalogApi: CatalogApi, private notifyService: NotifyService, private api: ApiService) {}

  createCatalog(variables: MutationCreateCatalogV2Args): MutationResult<'createCatalogV2'> {
    return this.catalogApi.createCatalogV2(variables);
  }

  updateCatalog(variables: MutationUpdateCatalogV2Args): MutationResult<'updateCatalogV2'> {
    return this.catalogApi.updateCatalogV2(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении каталога',
      }),
    );
  }

  getCatalogByOrganization(): QueryResult<'catalogByOrganization'> {
    return this.catalogApi.getCatalogByOrganization().pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении каталога',
      }),
    );
  }

  getAllInCatalogPageable(variables: QueryAllInCatalogPageableArgs): QueryResult<'allInCatalogPageable'> {
    return this.catalogApi.getAllInCatalogPageable(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка товаров',
      }),
    );
  }

  getCatalogProductsShort(catalogId: string): QueryResult<'allInCatalogPageable'> {
    return this.catalogApi
      .getCatalogProductsShort({
        filter: {
          catalogId,
          types: ['PRODUCT'],
        },
      })
      .pipe(
        this.notifyService.$notify({
          error: 'Ошибка при получении списка товаров',
        }),
      );
  }

  getProductsShort(catalogId: string, pageRequest: PageRequestInput): QueryResult<'allInCatalogPageable'> {
    return this.catalogApi
      .getCatalogProductsShort({
        filter: {
          catalogId,
          types: ['PRODUCT'],
        },
        pageRequest,
      })
      .pipe(
        this.notifyService.$notify({
          error: 'Ошибка при получении списка товаров',
        }),
      );
  }

  getCachedCatalog(): QueryResult<'fullCatalog'> {
    return this.catalogApi.getCachedCatalog({ request: { catalogId: this.#catalog.getValue()?.id || '' } }).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении каталога',
      }),
    );
  }

  getCachedCatalogForDocument(): QueryResult<'fullCatalog'> {
    return this.catalogApi.getCachedCatalog({ request: { catalogId: this.#catalog.getValue()?.id || '', hasTechCard: false } }).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении каталога',
      }),
    );
  }

  fetchCatalogByOrganization(): QueryResult<'catalogByOrganization'> {
    return this.api.query<Query<'catalogByOrganization'>, {}>({
      query: queryCatalogByOrganization,
      fetchPolicy: 'no-cache',
    });
  }
}
