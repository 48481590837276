import { gql } from 'apollo-angular';

export const subscriptionGoals = gql`
  subscription goalUpdates {
    goalUpdates {
      id
      name
      isComplete
    }
  }
`;

export const queryAllGoals = gql`
  query allGoals {
    allGoals {
      id
      name
      goals {
        id
        name
        isComplete
      }
    }
  }
`;

export const mutationFinalizeGoalsByGroupId = gql`
  mutation finalizeGoalsByGroupId($groupId: UUID!, $feedbackText: String) {
    finalizeGoalsByGroupId(groupId: $groupId, feedbackText: $feedbackText)
  }
`;
