import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EmployeesApi } from '@services/employee';
import { StoresStorage } from '@services/settings';
import { NotifyService } from '@services/shared';
import {
  CoreSchema,
  MutationBatchResult,
  MutationCreateEmployeeArgs,
  MutationRemoveEmployeeArgs,
  MutationResult,
  MutationUpdateEmployeeArgs,
  MutationUpdateEmployeePinCodeArgs,
  QueryAllEmployeesPageableArgs,
  QueryEmployeeArgs,
  QueryResult,
  Store,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class EmployeesStorage {
  constructor(private employeesApi: EmployeesApi, private storesStorage: StoresStorage, private notifyService: NotifyService) {}

  createEmployee(variables: MutationCreateEmployeeArgs): MutationResult<'createEmployee'> {
    return this.employeesApi.createEmployee(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании сотрудника',
      }),
    );
  }

  createEmployeeInvitation(variables: CoreSchema.MutationInviteEmployeeArgs): MutationResult<'createEmployeeInvitation'> {
    return this.employeesApi.createEmployeeInvitation(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при отправке приглашения',
      }),
    );
  }

  updateEmployee(variables: MutationUpdateEmployeeArgs): MutationResult<'updateEmployee'> {
    return this.employeesApi.updateEmployee(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении сотрудника',
      }),
    );
  }

  removeEmployee(variables: MutationRemoveEmployeeArgs): MutationResult<'removeEmployee'> {
    return this.employeesApi.removeEmployee(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении сотрудника',
        success: 'Сотрудник успешно удалён',
      }),
    );
  }

  updateEmployeePinCodeBatch(variables: MutationUpdateEmployeePinCodeArgs[]): MutationBatchResult<'updateEmployeePinCode'> {
    return this.employeesApi.updateEmployeePinCodeBatch(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении пин-кода',
      }),
    );
  }

  updateEmployeePinCode(variables: MutationUpdateEmployeePinCodeArgs): MutationResult<'updateEmployeePinCode'> {
    return this.employeesApi.updateEmployeePinCode(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении пин-кода',
      }),
    );
  }

  getEmployeesList(variables: QueryAllEmployeesPageableArgs): QueryResult<'allEmployeesPageable'> {
    return this.employeesApi.getEmployeesList(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка сотрудников',
      }),
    );
  }

  getEmployee(variables: QueryEmployeeArgs): QueryResult<'employee'> {
    return this.employeesApi.getEmployee(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении клиента',
      }),
    );
  }

  getUserGroupsByOrganization(): QueryResult<'userGroups'> {
    return this.employeesApi.getUserGroupsByOrganization().pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка групп пользователей',
      }),
    );
  }

  getStores(): Observable<Store[]> {
    return this.storesStorage.getStores({ pageRequest: { page: 0, size: 1000 } }).pipe(map((res) => res.data.stores.content));
  }
}
