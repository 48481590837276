import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SVG_ICONS_TYPE } from '@constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nm-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent {
  @Input() testId: string;
  @Input() disabled: boolean;
  @Input() error: boolean;
  @Input() focused: boolean;
  @Input() iconLeft: SVG_ICONS_TYPE;
  @Input() iconRight: SVG_ICONS_TYPE;
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() isAllFieldAction: boolean = false;

  @Output() rightIconClick = new EventEmitter<MouseEvent>();

  constructor() {}

  onRightIconClick(e: MouseEvent) {
    this.rightIconClick.emit(e);

    if (!this.isAllFieldAction) {
      e.stopPropagation();
    }
  }
}
