import { Injectable } from '@angular/core';

import {
  mutationCreateCatalogV2,
  mutationUpdateCatalogV2,
  queryAllInCatalogPageable,
  queryCatalogByOrganization,
  queryFullCatalog,
  queryGetCatalogProductsShort,
} from '@api';
import { ApiService } from '@core/service';
import {
  CoreSchema,
  Mutation,
  MutationCreateCatalogArgs,
  MutationCreateCatalogV2Args,
  MutationResult,
  MutationUpdateCatalogArgs,
  MutationUpdateCatalogV2Args,
  Query,
  QueryFullCatalogArgs,
  QueryResult,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class CatalogApi {
  constructor(private apiService: ApiService) {}

  createCatalogV2(variables: MutationCreateCatalogV2Args): MutationResult<'createCatalogV2'> {
    return this.apiService.mutate<Mutation<'createCatalogV2'>, MutationCreateCatalogArgs>({
      mutation: mutationCreateCatalogV2,
      variables,
    });
  }

  updateCatalogV2(variables: MutationUpdateCatalogV2Args): MutationResult<'updateCatalogV2'> {
    return this.apiService.mutate<Mutation<'updateCatalogV2'>, MutationUpdateCatalogArgs>({
      mutation: mutationUpdateCatalogV2,
      variables,
    });
  }

  getAllInCatalogPageable(variables: CoreSchema.QueryAllInCatalogPageableArgs) {
    return this.apiService.query<Query<'allInCatalogPageable'>, CoreSchema.QueryAllInCatalogPageableArgs>({
      query: queryAllInCatalogPageable,
      variables,
    });
  }

  getCatalogProductsShort(variables: CoreSchema.QueryAllInCatalogPageableArgs) {
    return this.apiService.query<Query<'allInCatalogPageable'>, CoreSchema.QueryAllInCatalogPageableArgs>({
      query: queryGetCatalogProductsShort,
      variables,
    });
  }

  getCatalogByOrganization(): QueryResult<'catalogByOrganization'> {
    return this.apiService.query<Query<'catalogByOrganization'>, {}>({
      query: queryCatalogByOrganization,
    });
  }
  getCachedCatalog(variables: QueryFullCatalogArgs) {
    return this.apiService.query<Query<'fullCatalog'>, QueryFullCatalogArgs>({
      query: queryFullCatalog,
      variables,
    });
  }
}
