import { Injectable } from '@angular/core';

import { queryTimezones } from '@api';
import { ApiService } from '@services/core';
import { Query, QueryResult } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class TimezonesApi {
  constructor(private apiService: ApiService) {}

  getTimezones(): QueryResult<'timezones'> {
    return this.apiService.query<Query<'timezones'>, {}>({
      query: queryTimezones,
    });
  }
}
