import { gql } from 'apollo-angular';

export const fragmentChartReportHourly = gql`
  fragment ChartReportHourly on AnalyticsChartReportHourly {
    date
    averageCheck
    checksCount
    guestsCount
    profit
    revenue
  }
`;

export const queryGetChartReportHourly = gql`
  query getChartReportHourly(
    $dateFrom: LocalDate!
    $dateTo: LocalDate!
    $dateFromPrev: LocalDate!
    $dateToPrev: LocalDate!
    $storeIds: [UUID!]
    $terminalIds: [UUID!]
  ) {
    current: getChartReportHourly(dateFrom: $dateFrom, dateTo: $dateTo, storeIds: $storeIds, terminalIds: $terminalIds) {
      ...ChartReportHourly
    }

    previous: getChartReportHourly(dateFrom: $dateFromPrev, dateTo: $dateToPrev, storeIds: $storeIds, terminalIds: $terminalIds) {
      ...ChartReportHourly
    }
  }
  ${fragmentChartReportHourly}
`;

export const fragmentChartReportDaily = gql`
  fragment ChartReport on AnalyticsChartReport {
    averageCheck
    checksCount
    date
    guestsCount
    profit
    revenue
  }
`;

export const queryGetChartReportDaily = gql`
  query getChartReport(
    $dateFrom: LocalDate!
    $dateTo: LocalDate!
    $dateFromPrev: LocalDate!
    $dateToPrev: LocalDate!
    $storeIds: [UUID!]
    $terminalIds: [UUID!]
  ) {
    current: getChartReport(dateFrom: $dateFrom, dateTo: $dateTo, storeIds: $storeIds, terminalIds: $terminalIds) {
      ...ChartReport
    }

    previous: getChartReport(dateFrom: $dateFromPrev, dateTo: $dateToPrev, storeIds: $storeIds, terminalIds: $terminalIds) {
      ...ChartReport
    }
  }
  ${fragmentChartReportDaily}
`;

export const fragmentChartReportMonthly = gql`
  fragment ChartReportMonthly on AnalyticsChartReport {
    averageCheck
    checksCount
    date
    guestsCount
    profit
    revenue
  }
`;

export const queryGetChartReportMonthly = gql`
  query getChartReportMonthly(
    $dateFrom: LocalDate!
    $dateTo: LocalDate!
    $dateFromPrev: LocalDate!
    $dateToPrev: LocalDate!
    $storeIds: [UUID!]
    $terminalIds: [UUID!]
  ) {
    current: getChartReportMonthly(dateFrom: $dateFrom, dateTo: $dateTo, storeIds: $storeIds, terminalIds: $terminalIds) {
      ...ChartReportMonthly
    }

    previous: getChartReportMonthly(dateFrom: $dateFromPrev, dateTo: $dateToPrev, storeIds: $storeIds, terminalIds: $terminalIds) {
      ...ChartReportMonthly
    }
  }
  ${fragmentChartReportMonthly}
`;

export const fragmentChartReportData = gql`
  fragment ChartReportData on AnalyticsChartReportSummary {
    averageCheck
    checksCount
    guestsCount
    profit
    revenue
  }
`;

export const queryChartReportSummary = gql`
  query getChartReportSummary(
    $dateFrom: LocalDate!
    $dateTo: LocalDate!
    $dateFromPrev: LocalDate!
    $dateToPrev: LocalDate!
    $storeIds: [UUID!]
    $terminalIds: [UUID!]
  ) {
    current: getChartReportSummary(dateFrom: $dateFrom, dateTo: $dateTo, storeIds: $storeIds, terminalIds: $terminalIds) {
      ...ChartReportData
    }
    previous: getChartReportSummary(dateFrom: $dateFromPrev, dateTo: $dateToPrev, storeIds: $storeIds, terminalIds: $terminalIds) {
      ...ChartReportData
    }
  }
  ${fragmentChartReportData}
`;

export const querySalesByHour = gql`
  query getSalesByHour($dateFrom: LocalDate!, $dateTo: LocalDate!, $storeIds: [UUID!], $terminalIds: [UUID!]) {
    getSalesByHour(dateFrom: $dateFrom, dateTo: $dateTo, storeIds: $storeIds, terminalIds: $terminalIds) {
      hour
      averageHourlyProfit
      averageHourlyRevenue
      averageHourlyCheck
      totalHourlyGuestsCount
      totalHourlyChecksCount
    }
  }
`;

export const querySalesByWeekDay = gql`
  query getSalesByWeekDay($dateFrom: LocalDate!, $dateTo: LocalDate!, $storeIds: [UUID!], $terminalIds: [UUID!]) {
    getSalesByWeekDay(dateFrom: $dateFrom, dateTo: $dateTo, storeIds: $storeIds, terminalIds: $terminalIds) {
      dayOfWeek
      averageWeeklyProfit
      averageWeeklyRevenue
      averageWeeklyCheck
      totalWeeklyGuestsCount
      totalWeeklyChecksCount
    }
  }
`;

export const queryPaySystemReport = gql`
  query getPaySystemReport($dateFrom: LocalDate!, $dateTo: LocalDate!, $storeIds: [UUID!]) {
    getPaySystemReport(dateFrom: $dateFrom, dateTo: $dateTo, storeIds: $storeIds) {
      amount
      paySystemName
    }
  }
`;

export const queryPopularProducts = gql`
  query getPopularProducts($dateFrom: LocalDate!, $dateTo: LocalDate!, $storeIds: [UUID!]) {
    getPopularProducts(dateFrom: $dateFrom, dateTo: $dateTo, storeIds: $storeIds) {
      name
      profit
      revenue
      profitPercent
      quantity
    }
  }
`;

export const queryLoyaltyAnalyticsSummary = gql`
  query getLoyaltyAnalyticsSummary($input: ChartInput!, $inputPrev: ChartInput!) {
    current: loyaltyAnalyticsSummary(input: $input) {
      revenueWithDiscounts
      discountsUsed
      loyaltyUsed
    }
    previous: loyaltyAnalyticsSummary(input: $inputPrev) {
      revenueWithDiscounts
      discountsUsed
      loyaltyUsed
    }
  }
`;

export const fragmentQueryLoyalty = gql`
  fragment QueryLoyalty on LoyaltyAnalytics {
    discountsUsed {
      ordersWithDiscounts
      ordersWithoutDiscounts
    }
    loyaltyUsed {
      ordersWithLoyalty
      ordersWithoutLoyalty
    }
    revenueWithDiscounts {
      discountMarginLoss
      revenueWithDiscounts
      revenueWithoutDiscounts
    }
  }
`;

export const queryMonthlyLoyaltyAnalytics = gql`
  query getMonthlyLoyaltyAnalytics($input: ChartInput!) {
    monthlyLoyaltyAnalytics(input: $input) {
      date
      loyaltyAnalytics {
        ...QueryLoyalty
      }
    }
  }
  ${fragmentQueryLoyalty}
`;

export const queryHourlyLoyaltyAnalytics = gql`
  query getHourlyLoyaltyAnalytics($input: ChartInput!) {
    hourlyLoyaltyAnalytics(input: $input) {
      hour
      loyaltyAnalytics {
        ...QueryLoyalty
      }
    }
  }
  ${fragmentQueryLoyalty}
`;

export const queryDailyLoyaltyAnalytics = gql`
  query getDailyLoyaltyAnalytics($input: ChartInput!) {
    dailyLoyaltyAnalytics(input: $input) {
      date
      loyaltyAnalytics {
        ...QueryLoyalty
      }
    }
  }
  ${fragmentQueryLoyalty}
`;
