import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { iif, Observable, of, Subscriber } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';

import { CatalogService, CatalogStorage } from '@services/catalog';
import { SidenavService } from '@services/shared';
import { Catalog } from '@typings';

@Injectable()
export class CatalogSettingsGuard implements CanActivateChild {
  constructor(private catalogStorage: CatalogStorage, private catalogService: CatalogService, private sidenavService: SidenavService) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable((subscriber) => {
      const catalog = this.catalogStorage.getCatalog();

      if (catalog) {
        this.action(this.isAccess(catalog, state), subscriber);
      }

      if (!catalog) {
        this.catalogStorage
          .getCatalogByOrganization()
          .pipe(
            map((res) => res.data.catalogByOrganization),
            tap((currentCatalog) => this.catalogStorage.setCatalog(currentCatalog!)),
            concatMap((currentCatalog) => iif(() => this.isAccess(currentCatalog!, state), of(true), of(false))),
          )
          .subscribe((isAccess) => this.action(isAccess, subscriber));
      }
    });
  }

  isAccess(catalog: Catalog, state: RouterStateSnapshot): boolean {
    return this.catalogService.isAccess(catalog) || state.url.includes('settings');
  }

  action(isAccess: boolean, observable: Subscriber<boolean | UrlTree>): void {
    isAccess ? observable.next(true) : this.sidenavService.openCatalogSettingsWithSection();
  }
}
