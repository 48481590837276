import { ComponentType } from '@angular/cdk/portal';
import { ApplicationRef, Injectable, RendererFactory2, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, take } from 'rxjs';

import { DialogConfig, ModalRef, ModalService } from './abstract';
import { ModalStateService } from './modal-state.service';

@Injectable()
export class ModalServiceImpl implements ModalService {
  dialogOpen = new BehaviorSubject(false);
  constructor(
    readonly dialog: MatDialog,
    readonly rendererFactory: RendererFactory2,
    readonly appRef: ApplicationRef,
    private modalStateService: ModalStateService,
  ) {}

  openDialog<T, R = unknown>(template: ComponentType<T> | TemplateRef<T>, settings?: DialogConfig): ModalRef<T, R> {
    let dialogRef: MatDialogRef<T, R>;
    settings = settings || {};
    dialogRef = this.dialog.open(template, { ...settings, height: 'auto', maxHeight: '90vh' });
    this.modalStateService.setModalState(true);
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.modalStateService.setModalState(false);
      });
    return {
      componentRef: dialogRef,
      afterOpened: () => dialogRef.afterOpened(),
      afterClosed: () => dialogRef.afterClosed(),
      beforeClosed: () => dialogRef.beforeClosed(),
      backdropClick: () => dialogRef.backdropClick(),
      keydownEvents: () => dialogRef.keydownEvents(),
      close: (result?: R) => {
        dialogRef.close(result);
        this.appRef.tick();
      },
    };
  }
}
