import { Injectable } from '@angular/core';

import {
  mutationConfirmArrivalDoc,
  mutationConfirmArrivalDocument,
  mutationCreateArrivalDoc,
  mutationCreateArrivalDocument,
  mutationDeleteArrivalDoc,
  mutationDeleteArrivalDocument,
  mutationDuplicateArrivalDocument,
  mutationRejectArrivalDocument,
  mutationRevertArrivalDoc,
  mutationSetArrivalDocumentPaymentStatus,
  mutationUpdateArrivalDoc,
  mutationUpdateArrivalDocument,
  queryArrivalDoc,
  queryArrivalDocs,
  queryArrivalDocument,
  queryArrivalProducts,
  queryArrivalsPageableV2,
} from '@api';
import { ApiService } from '@services/core';
import {
  Mutation,
  MutationConfirmArrivalDocArgs,
  MutationConfirmArrivalDocumentArgs,
  MutationCreateArrivalDocArgs,
  MutationCreateArrivalDocumentArgs,
  MutationDeleteArrivalDocArgs,
  MutationDeleteArrivalDocumentArgs,
  MutationDuplicateArrivalDocumentArgs,
  MutationRejectArrivalDocumentArgs,
  MutationResult,
  MutationRevertArrivalDocArgs,
  MutationSetArrivalDocumentPaymentStatusArgs,
  MutationUpdateArrivalDocArgs,
  MutationUpdateArrivalDocumentArgs,
  Query,
  QueryAllArrivalDocumentsPageableV2Args,
  QueryArrivalDocArgs,
  QueryArrivalDocsArgs,
  QueryArrivalDocumentArgs,
  QueryArrivalProductsArgs,
  QueryResult,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class ArrivalsApi {
  constructor(private apiService: ApiService) {}

  allArrivalsPageable(variables: QueryAllArrivalDocumentsPageableV2Args): QueryResult<'allArrivalDocumentsPageableV2'> {
    return this.apiService.query<Query<'allArrivalDocumentsPageableV2'>, QueryAllArrivalDocumentsPageableV2Args>({
      query: queryArrivalsPageableV2,
      variables,
    });
  }

  arrival(variables: QueryArrivalDocumentArgs): QueryResult<'arrivalDocument'> {
    return this.apiService.query<Query<'arrivalDocument'>, QueryArrivalDocumentArgs>({
      query: queryArrivalDocument,
      variables,
    });
  }

  createArrival(variables: MutationCreateArrivalDocumentArgs): MutationResult<'createArrivalDocument'> {
    return this.apiService.mutate<Mutation<'createArrivalDocument'>, MutationCreateArrivalDocumentArgs>({
      mutation: mutationCreateArrivalDocument,
      variables,
    });
  }

  updateArrival(variables: MutationUpdateArrivalDocumentArgs): MutationResult<'updateArrivalDocument'> {
    return this.apiService.mutate<Mutation<'updateArrivalDocument'>, MutationUpdateArrivalDocumentArgs>({
      mutation: mutationUpdateArrivalDocument,
      variables,
    });
  }

  deleteArrival(variables: MutationDeleteArrivalDocumentArgs): MutationResult<'deleteArrivalDocument'> {
    return this.apiService.mutate<Mutation<'deleteArrivalDocument'>, MutationDeleteArrivalDocumentArgs>({
      mutation: mutationDeleteArrivalDocument,
      variables,
    });
  }

  setArrivalDocumentPaymentStatus(
    variables: MutationSetArrivalDocumentPaymentStatusArgs,
  ): MutationResult<'setArrivalDocumentPaymentStatus'> {
    return this.apiService.mutate<Mutation<'setArrivalDocumentPaymentStatus'>, MutationSetArrivalDocumentPaymentStatusArgs>({
      mutation: mutationSetArrivalDocumentPaymentStatus,
      variables,
    });
  }

  duplicateArrival(variables: MutationDuplicateArrivalDocumentArgs): MutationResult<'duplicateArrivalDocument'> {
    return this.apiService.mutate<Mutation<'duplicateArrivalDocument'>, MutationDuplicateArrivalDocumentArgs>({
      mutation: mutationDuplicateArrivalDocument,
      variables,
    });
  }

  confirmArrival(variables: MutationConfirmArrivalDocumentArgs): MutationResult<'confirmArrivalDocument'> {
    return this.apiService.mutate<Mutation<'confirmArrivalDocument'>, MutationConfirmArrivalDocumentArgs>({
      mutation: mutationConfirmArrivalDocument,
      variables,
    });
  }

  rejectArrival(variables: MutationRejectArrivalDocumentArgs): MutationResult<'rejectArrivalDocument'> {
    return this.apiService.mutate<Mutation<'rejectArrivalDocument'>, MutationRejectArrivalDocumentArgs>({
      mutation: mutationRejectArrivalDocument,
      variables,
    });
  }

  arrivalDoc(variables: QueryArrivalDocArgs): QueryResult<'arrivalDoc'> {
    return this.apiService.query<Query<'arrivalDoc'>, QueryArrivalDocArgs>({
      query: queryArrivalDoc,
      variables,
    });
  }

  arrivalDocs(variables: QueryArrivalDocsArgs): QueryResult<'arrivalDocs'> {
    return this.apiService.query<Query<'arrivalDocs'>, QueryArrivalDocsArgs>({
      query: queryArrivalDocs,
      variables,
    });
  }

  arrivalProducts(variables: QueryArrivalProductsArgs): QueryResult<'arrivalProducts'> {
    return this.apiService.query<Query<'arrivalProducts'>, QueryArrivalProductsArgs>({
      query: queryArrivalProducts,
      variables,
    });
  }

  createArrivalDoc(variables: MutationCreateArrivalDocArgs): MutationResult<'createArrivalDoc'> {
    return this.apiService.mutate<Mutation<'createArrivalDoc'>, MutationCreateArrivalDocArgs>({
      mutation: mutationCreateArrivalDoc,
      variables,
    });
  }

  updateArrivalDoc(variables: MutationUpdateArrivalDocArgs): MutationResult<'updateArrivalDoc'> {
    return this.apiService.mutate<Mutation<'updateArrivalDoc'>, MutationUpdateArrivalDocArgs>({
      mutation: mutationUpdateArrivalDoc,
      variables,
    });
  }

  confirmArrivalDoc(variables: MutationConfirmArrivalDocArgs): MutationResult<'confirmArrivalDoc'> {
    return this.apiService.mutate<Mutation<'confirmArrivalDoc'>, MutationConfirmArrivalDocArgs>({
      mutation: mutationConfirmArrivalDoc,
      variables,
    });
  }

  revertArrivalDoc(variables: MutationRevertArrivalDocArgs): MutationResult<'revertArrivalDoc'> {
    return this.apiService.mutate<Mutation<'revertArrivalDoc'>, MutationRevertArrivalDocArgs>({
      mutation: mutationRevertArrivalDoc,
      variables,
    });
  }

  deleteArrivalDoc(variables: MutationDeleteArrivalDocArgs): MutationResult<'deleteArrivalDoc'> {
    return this.apiService.mutate<Mutation<'deleteArrivalDoc'>, MutationDeleteArrivalDocArgs>({
      mutation: mutationDeleteArrivalDoc,
      variables,
    });
  }
}
