import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';
import { CategoryReportMetric, CategoryReportMetricNames, CategoryReportSortFieldStrict } from '@typings';

export const CATEGORY_REPORT_METRICS: CategoryReportMetricNames = {
  revenue: 'Выручка',
  profit: 'Прибыль',
  foodCost: 'Себестоимость',
};

export const getCategoryReportMetricsItems = (): MenuItem[] => {
  return Object.keys(CATEGORY_REPORT_METRICS).map((metric) => ({
    id: metric,
    label: CATEGORY_REPORT_METRICS[metric],
    type: 'item',
  }));
};

export const getCategoryMetricName = (metric: CategoryReportMetric): string => {
  return CATEGORY_REPORT_METRICS[metric];
};

export const getCategoryMetricsList = (): CategoryReportMetric[] => {
  return Object.keys(CATEGORY_REPORT_METRICS);
};

export const CATEGORY_REPORT_SORT_FIELD: CategoryReportSortFieldStrict = {
  CATEGORY_NAME: 'CATEGORY_NAME',
  FOOD_COST: 'FOOD_COST',
  KILOGRAM_QUANTITY: 'KILOGRAM_QUANTITY',
  LITER_QUANTITY: 'LITER_QUANTITY',
  ORDERS_QUANTITY: 'ORDERS_QUANTITY',
  PIECE_QUANTITY: 'PIECE_QUANTITY',
  PRIME_PRICE: 'PRIME_PRICE',
  PROFIT: 'PROFIT',
  REVENUE: 'REVENUE',
  TAX: 'TAX',
};
