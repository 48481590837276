import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DocumentStatusComponent } from './document-status.component';

@NgModule({
  declarations: [DocumentStatusComponent],
  imports: [CommonModule],
  exports: [DocumentStatusComponent],
})
export class DocumentStatusModule {}
