import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ValidationErrorsService } from '@core/service';
import { StoresService } from '@services/settings';

@Component({
  selector: 'nm-table-group-edit-dialog',
  templateUrl: './table-group-edit-dialog.component.html',
  styleUrls: ['./table-group-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableGroupEditDialogComponent {
  get tablesEditGroupForm() {
    return this.storesService?.tablesEditGroupForm;
  }

  get tablesEditGroupFormControls() {
    return this.tablesEditGroupForm?.controls;
  }

  constructor(public storesService: StoresService, public validationErrorsService: ValidationErrorsService) {}
}
