<div class="control-panel">
  <div class="header">
    <div class="left-side" [class.empty-filters]="">
      <ng-content select="[header-left]"></ng-content>
    </div>

    <div class="right-side">
      <ng-content select="[header-right]"></ng-content>
    </div>
  </div>

  <div *ngIf="isContent" class="content">
    <div class="left-side">
      <ng-content select="[content-left]"></ng-content>
    </div>

    <div class="right-side">
      <ng-content select="[content-right]"></ng-content>
    </div>
  </div>
</div>
