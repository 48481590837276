import { Directive, ElementRef, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[nmDndHandler]',
})
export class DragAndDropHandlerDirective {
  hovered$ = new BehaviorSubject(false);

  constructor(public elementRef: ElementRef) {}

  @HostListener('mouseover')
  onMouseOver() {
    this.hovered$.next(true);
  }

  @HostListener('mouseout')
  onMouseOut() {
    this.hovered$.next(false);
  }
}
