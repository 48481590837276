import { Pipe, PipeTransform } from '@angular/core';

import { ORDER_STATUS_STRING } from '@constants';
import { OrderState } from '@typings';

@Pipe({
  name: 'orderStatus',
})
export class OrderStatusPipe implements PipeTransform {
  transform(status: OrderState): string {
    return ORDER_STATUS_STRING[status];
  }
}
