import { COLORS, COLORS_SET } from '@constants';
import { COLOR_VALUE, ColorSectionExtended } from '@typings';

// TODO: add tests:
// const color = getColorNamesByHexCode('#FD6C36');
// const colorNo = getColorNamesByHexCode('#FFF');
// const hex = getHexCodeByColor(COLORS.COLOR_V1);
// const hexString = getHexCodeByColor('COLOR_V1');
// const name = getColorNamesByColor(COLORS.COLOR_V1);
// const nameString = getColorNamesByColor('COLOR_V1');

export const getColorNamesByHexCode = (hexCode: string): COLOR_VALUE => {
  const found = Object.entries(COLORS_SET).find(([hex]) => {
    return hex === hexCode;
  });

  if (!found) {
    console.warn(`No color name exists for ${hexCode}, return defaults`);
    return COLORS_SET.COLOR_V0;
  }

  return found[1];
};

export const getHexCodeByColor = (color: ColorSectionExtended): string => {
  const value = color.toString();

  if (color.startsWith('#')) {
    return value;
  }

  return COLORS[color];
};

export const getColorNamesByColor = (color: ColorSectionExtended): COLOR_VALUE => {
  const value = COLORS_SET[color];

  if (!value) {
    const hex = COLORS[color];

    return getColorNamesByHexCode(hex);
  }

  return value;
};

export function stringToHslColor(str: string, saturation: number = 50, lightness: number = 50): string {
  let code = 0;

  for (let i = 0; i < str.length; i += 1) {
    code = str.charCodeAt(i) + ((code << 5) - code);
  }

  const hue = code % 360;

  return `hsl(${hue}, ${saturation % 100}%, ${lightness % 100}%)`;
}
