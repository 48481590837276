import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import {
  getFirstNFractionalDigitsRegexp,
  getFirstNIntegerDigitsRegexp,
  GRAM,
  KILOGRAM,
  LITER,
  LITER_OR_KILOGRAM,
  MAX_FRACTIONAL,
  MAX_INTEGER,
  MILLILITER,
  MILLILITER_OR_GRAM,
  PIECE,
  UNIT_TYPE,
  UNIT_TYPE_LIST,
} from '@constants';
import { TechCardItemForm, UnitType, ValidationNumberConfigType } from '@typings';

export const MULTIPLIER = 1000;
export const PERCENT = 100;

const truncateInteger = (str: string, integer: number): string => {
  return str.replace(getFirstNIntegerDigitsRegexp(integer), '$1$3');
};

const checkStringNumbers = (str: string, config: ValidationNumberConfigType = {}): string => {
  const { decimal } = config;

  if (decimal) {
    const fractional = validateFractional(config.fractional);

    const value = str
      .replace(/[^0-9,.-]/g, '') // remove all except numbers and delimiters
      .replace(/,/g, '.') // replace comma to period
      .replace(/(.+)-/g, '$1') // remove minus if it not first symbol
      .replace(/^(-?)0+(\d)/, '$1$2') // remove zero from start, if another letter comes after
      .replace(/^(-?)(\.)/, '$10$2') // set zero before delimiter, if delimiter comes first
      .replace(/^([^.]*\.)(.*)$/, (a, b, c) => b + c.replace(/\./g, '')); // remove any delimiter if they come after existing one

    if (fractional === undefined) {
      return value;
    }

    return value.replace(getFirstNFractionalDigitsRegexp(fractional), '$1$2'); // leave only `fractional` digits after delimiter
  }

  return str.replace(/[^0-9]+/g, '');
};

const validateFractional = (fractional?: number): number | undefined => {
  if (fractional === undefined || !Number.isFinite(fractional)) {
    return undefined;
  }

  if (fractional <= 0) {
    return 0;
  }

  return Math.floor(fractional);
};

export const validateNumber = (value: string, maxInteger: number, fractional?: number): string => {
  return truncateInteger(checkStringNumbers(value, { decimal: true, fractional }), maxInteger);
};

export const setUnitTypeList = (unitType: UnitType): AutocompleteOption<UnitType>[] => {
  let unitList: AutocompleteOption<UnitType>[] = [];

  if (unitType === LITER || unitType === MILLILITER) {
    unitList = UNIT_TYPE_LIST.filter((unit) => unit.data === LITER || unit.data === MILLILITER);
  }

  if (unitType === KILOGRAM || unitType === GRAM) {
    unitList = UNIT_TYPE_LIST.filter((unit) => unit.data === KILOGRAM || unit.data === GRAM);
  }

  const unit = UNIT_TYPE_LIST.find((unit) => unit.data === PIECE);

  if (unit) {
    unitList = [...unitList, unit];
  }

  return unitList;
};

export const setBruttoUnit = (bruttoUnit: UnitType, item: TechCardItemForm): void => {
  const baseBruttoUnit = item.stockUnit.value?.data?.unit;
  const prevBruttoUnit = item.prevBruttoUnit.value;
  const baseQuantity = Number(item.stockUnit.value?.data?.quantity);
  const quantity = Number(item.quantity.value);
  const brutto = Number(item.brutto.value);

  if (!prevBruttoUnit) {
    return;
  }

  if (prevBruttoUnit === bruttoUnit) {
    return;
  }

  if (prevBruttoUnit === PIECE) {
    if (bruttoUnit === baseBruttoUnit) {
      item.quantity.setValue(String(baseQuantity));
      item.brutto.setValue(validateNumber(String(brutto * baseQuantity), MAX_INTEGER.BRUTTO, MAX_FRACTIONAL.BRUTTO));
    } else {
      if (LITER_OR_KILOGRAM(bruttoUnit)) {
        item.quantity.setValue(String(baseQuantity / MULTIPLIER));
        item.brutto.setValue(validateNumber(String((brutto * baseQuantity) / MULTIPLIER), MAX_INTEGER.BRUTTO, MAX_FRACTIONAL.BRUTTO));
      }

      if (MILLILITER_OR_GRAM(bruttoUnit)) {
        item.quantity.setValue(String(baseQuantity * MULTIPLIER));
        item.brutto.setValue(validateNumber(String(brutto * baseQuantity * MULTIPLIER), MAX_INTEGER.BRUTTO, MAX_FRACTIONAL.BRUTTO));
      }
    }
  } else {
    if (LITER_OR_KILOGRAM(bruttoUnit)) {
      item.quantity.setValue(String(quantity / MULTIPLIER));
      item.brutto.setValue(validateNumber(String(brutto / MULTIPLIER), MAX_INTEGER.BRUTTO, MAX_FRACTIONAL.BRUTTO));
    }

    if (MILLILITER_OR_GRAM(bruttoUnit)) {
      item.quantity.setValue(String(quantity * MULTIPLIER));
      item.brutto.setValue(validateNumber(String(brutto * MULTIPLIER), MAX_INTEGER.BRUTTO, MAX_FRACTIONAL.BRUTTO));
    }

    if (bruttoUnit === PIECE) {
      if (baseBruttoUnit === prevBruttoUnit) {
        item.quantity.setValue(String(1));
        item.brutto.setValue(validateNumber(String(brutto / baseQuantity), MAX_INTEGER.BRUTTO, MAX_FRACTIONAL.BRUTTO));
      } else {
        if (LITER_OR_KILOGRAM(prevBruttoUnit)) {
          item.quantity.setValue(String(1));
          item.brutto.setValue(validateNumber(String((brutto / baseQuantity) * MULTIPLIER), MAX_INTEGER.BRUTTO, MAX_FRACTIONAL.BRUTTO));
        }

        if (MILLILITER_OR_GRAM(prevBruttoUnit)) {
          item.quantity.setValue(String(1));
          item.brutto.setValue(validateNumber(String(brutto / baseQuantity / MULTIPLIER), MAX_INTEGER.BRUTTO, MAX_FRACTIONAL.BRUTTO));
        }
      }
    }
  }

  item.prevBruttoUnit.setValue(bruttoUnit);
};

export const setPrimePriceItem = (item: TechCardItemForm): void => {
  const basePrimePrice = Number(
    item.stockUnit.value?.data?.weightedAveragePrimePrice?.amountValue || item.stockUnit?.value?.data?.primePrice?.amountValue,
  );
  const quantity = Number(item.quantity.value);
  const brutto = Number(item.brutto.value);

  item.primePrice.setValue(String(setPrimePrice(basePrimePrice, quantity, brutto)));
};

export const setPrimePrice = (basePrimePrice: number, quantity: number, brutto: number): number =>
  Number(validateNumber(String((brutto * basePrimePrice) / quantity), MAX_INTEGER.PRIME_PRICE, MAX_FRACTIONAL.PRIME_PRICE));

export const setNetto = (item: TechCardItemForm): void => {
  let brutto = Number(item.brutto.value);

  if (isNaN(brutto)) {
    return;
  }

  const losses = Number(item.losses.value);
  const baseQuantity = Number(item.stockUnit.value?.data?.quantity);
  const baseBruttoUnit = item.stockUnit?.value?.data?.unit;
  const prevBruttoUnit = item.prevBruttoUnit.value;
  const bruttoUnit = item.bruttoUnit.value;
  let netto = 0;

  if (!baseBruttoUnit || !prevBruttoUnit || !bruttoUnit) {
    return;
  }

  if (prevBruttoUnit === bruttoUnit) {
    if (bruttoUnit === baseBruttoUnit) {
      if (baseBruttoUnit === UNIT_TYPE.PIECE) {
        netto = brutto - (brutto * losses) / PERCENT;
      }

      if (LITER_OR_KILOGRAM(baseBruttoUnit)) {
        brutto = brutto * MULTIPLIER;
        netto = brutto - (brutto * losses) / PERCENT;
      }

      if (MILLILITER_OR_GRAM(baseBruttoUnit)) {
        netto = brutto - (brutto * losses) / PERCENT;
      }

      if (MILLILITER_OR_GRAM(bruttoUnit)) {
        netto = brutto - (brutto * losses) / PERCENT;
      }
    } else {
      if (LITER_OR_KILOGRAM(bruttoUnit)) {
        brutto = brutto * MULTIPLIER;
        netto = brutto - (brutto * losses) / PERCENT;
      }

      if (MILLILITER_OR_GRAM(bruttoUnit)) {
        netto = brutto - (brutto * losses) / PERCENT;
      }

      if (bruttoUnit === UNIT_TYPE.PIECE) {
        if (LITER_OR_KILOGRAM(baseBruttoUnit)) {
          brutto = brutto * MULTIPLIER;
          netto = (brutto - (brutto * losses) / PERCENT) * baseQuantity;
        }

        if (MILLILITER_OR_GRAM(baseBruttoUnit)) {
          netto = (brutto - (brutto * losses) / PERCENT) * baseQuantity;
        }
      }
    }

    if (netto <= 0) {
      netto = 0;
    }

    netto = Number(validateNumber(String(netto), MAX_INTEGER.NETTO, MAX_FRACTIONAL.NETTO));

    item.netto.setValue(String(netto));

    return;
  }

  if (prevBruttoUnit === UNIT_TYPE.PIECE) {
    if (bruttoUnit === baseBruttoUnit) {
      netto = brutto - (brutto * losses) / PERCENT;

      if (LITER_OR_KILOGRAM(bruttoUnit)) {
        netto = netto * MULTIPLIER;
      }
    } else {
      if (LITER_OR_KILOGRAM(bruttoUnit)) {
        brutto = brutto * MULTIPLIER;
        netto = brutto - (brutto * losses) / PERCENT;
      }

      if (MILLILITER_OR_GRAM(bruttoUnit)) {
        netto = brutto - (brutto * losses) / PERCENT;
      }
    }
  } else {
    if (baseBruttoUnit === bruttoUnit) {
      netto = brutto - (brutto * losses) / PERCENT;

      if (LITER_OR_KILOGRAM(baseBruttoUnit)) {
        netto = netto * MULTIPLIER;
      }
    } else {
      if (LITER_OR_KILOGRAM(bruttoUnit)) {
        brutto = brutto * MULTIPLIER;
        netto = brutto - (brutto * losses) / PERCENT;
      }

      if (MILLILITER_OR_GRAM(bruttoUnit)) {
        netto = brutto - (brutto * losses) / PERCENT;
      }

      if (bruttoUnit === UNIT_TYPE.PIECE) {
        if (baseBruttoUnit === prevBruttoUnit) {
          netto = brutto - (brutto * baseQuantity * losses) / PERCENT;
        } else {
          if (LITER_OR_KILOGRAM(prevBruttoUnit)) {
            if (LITER_OR_KILOGRAM(baseBruttoUnit)) {
              brutto = brutto / MULTIPLIER;
              netto = brutto - (brutto * losses) / PERCENT;
            }

            if (MILLILITER_OR_GRAM(baseBruttoUnit)) {
              netto = (brutto - (brutto * losses) / PERCENT) * baseQuantity;
            }
          }

          if (MILLILITER_OR_GRAM(prevBruttoUnit)) {
            if (LITER_OR_KILOGRAM(baseBruttoUnit)) {
              brutto = brutto * MULTIPLIER;
              netto = (brutto - (brutto * losses) / PERCENT) * baseQuantity;
            }

            if (MILLILITER_OR_GRAM(baseBruttoUnit)) {
              brutto = brutto / MULTIPLIER;
              netto = brutto - (brutto * losses) / PERCENT;
            }
          }
        }
      }
    }
  }

  if (netto <= 0) {
    netto = 0;
  }

  netto = Number(validateNumber(String(netto), MAX_INTEGER.NETTO, MAX_FRACTIONAL.NETTO));

  item.netto.setValue(String(netto));
};

export const setLosses = (item: TechCardItemForm): void => {
  let brutto = Number(item.brutto.value);
  const netto = Number(item.netto.value);

  if (isNaN(brutto) || isNaN(netto)) {
    return;
  }

  const baseQuantity = Number(item.stockUnit.value?.data?.quantity);
  const baseBruttoUnit = item.stockUnit?.value?.data?.unit;
  const prevBruttoUnit = item.prevBruttoUnit.value;
  const bruttoUnit = item.bruttoUnit.value;
  let losses = 0;

  if (!baseBruttoUnit || !prevBruttoUnit || !bruttoUnit) {
    return;
  }

  if (prevBruttoUnit === bruttoUnit) {
    losses = setLossesShort(brutto, netto, bruttoUnit, baseBruttoUnit, baseQuantity);

    item.losses.setValue(String(validateLosses(losses)));

    return;
  }

  if (prevBruttoUnit === UNIT_TYPE.PIECE) {
    if (bruttoUnit === baseBruttoUnit) {
      losses = ((brutto - netto) / brutto) * PERCENT;

      if (LITER_OR_KILOGRAM(bruttoUnit)) {
        losses = losses / MULTIPLIER;
      }
    } else {
      if (LITER_OR_KILOGRAM(bruttoUnit)) {
        brutto = brutto * MULTIPLIER;
        losses = ((brutto - netto) / brutto) * PERCENT;
      }

      if (MILLILITER_OR_GRAM(bruttoUnit)) {
        losses = ((brutto - netto) / brutto) * PERCENT;
      }
    }
  } else {
    if (baseBruttoUnit === bruttoUnit) {
      losses = ((brutto - netto) / brutto) * PERCENT;

      if (LITER_OR_KILOGRAM(bruttoUnit)) {
        losses = losses / MULTIPLIER;
      }
    } else {
      if (LITER_OR_KILOGRAM(bruttoUnit)) {
        brutto = brutto * MULTIPLIER;
        losses = ((brutto - netto) / brutto) * PERCENT;
      }

      if (MILLILITER_OR_GRAM(bruttoUnit)) {
        losses = ((brutto - netto) / brutto) * PERCENT;
      }

      if (bruttoUnit === UNIT_TYPE.PIECE) {
        if (baseBruttoUnit === prevBruttoUnit) {
          losses = ((brutto - netto) / brutto) * baseQuantity * PERCENT;
        } else {
          if (LITER_OR_KILOGRAM(prevBruttoUnit)) {
            if (LITER_OR_KILOGRAM(baseBruttoUnit)) {
              brutto = brutto * MULTIPLIER;
              losses = ((brutto - netto) / brutto) * PERCENT;
            }

            if (MILLILITER_OR_GRAM(baseBruttoUnit)) {
              losses = ((brutto - netto) / (brutto * baseQuantity)) * PERCENT;
            }
          }

          if (MILLILITER_OR_GRAM(prevBruttoUnit)) {
            if (LITER_OR_KILOGRAM(baseBruttoUnit)) {
              brutto = brutto * MULTIPLIER;
              losses = ((brutto - netto) / brutto) * PERCENT;
            }

            if (MILLILITER_OR_GRAM(baseBruttoUnit)) {
              brutto = brutto / MULTIPLIER;
              losses = ((brutto - netto) / brutto) * PERCENT;
            }
          }
        }
      }
    }
  }

  losses = Number(validateNumber(String(validateLosses(losses)), MAX_INTEGER.LOSSES, MAX_FRACTIONAL.LOSSES));

  item.losses.setValue(String(losses));
};

export const setLossesShort = (
  brutto: number,
  netto: number,
  bruttoUnit: UnitType,
  baseBruttoUnit: UnitType,
  baseQuantity: number,
): number => {
  let losses = 0;

  if (bruttoUnit === baseBruttoUnit) {
    if (baseBruttoUnit === UNIT_TYPE.PIECE) {
      losses = ((brutto - netto) / brutto) * PERCENT;
    }

    if (LITER_OR_KILOGRAM(baseBruttoUnit)) {
      brutto = brutto * MULTIPLIER;
      losses = ((brutto - netto) / brutto) * PERCENT;
    }

    if (MILLILITER_OR_GRAM(baseBruttoUnit)) {
      losses = ((brutto - netto) / brutto) * PERCENT;
    }

    if (MILLILITER_OR_GRAM(bruttoUnit)) {
      losses = ((brutto - netto) / brutto) * PERCENT;
    }
  } else {
    if (LITER_OR_KILOGRAM(bruttoUnit)) {
      brutto = brutto * MULTIPLIER;
      losses = ((brutto - netto) / brutto) * PERCENT;
    }

    if (MILLILITER_OR_GRAM(bruttoUnit)) {
      losses = ((brutto - netto) / brutto) * PERCENT;
    }

    if (bruttoUnit === UNIT_TYPE.PIECE) {
      if (LITER_OR_KILOGRAM(baseBruttoUnit)) {
        brutto = brutto * baseQuantity * MULTIPLIER;
        losses = ((brutto - netto) / brutto) * PERCENT;
      }

      if (MILLILITER_OR_GRAM(baseBruttoUnit)) {
        brutto = brutto * baseQuantity;
        losses = ((brutto - netto) / brutto) * PERCENT;
      }
    }
  }

  return Number(validateNumber(String(losses), MAX_INTEGER.LOSSES, MAX_FRACTIONAL.LOSSES));
};

export const nettoGreaterThanBrutto = (item: TechCardItemForm, netto: string): string => {
  const baseQuantity = Number(item.stockUnit?.value?.data?.quantity);
  const baseBruttoUnit = item.stockUnit?.value?.data?.unit;
  const bruttoUnit = item.bruttoUnit.value;
  let brutto = Number(item.brutto.value);

  if (!baseBruttoUnit || !bruttoUnit) {
    return netto;
  }

  if (bruttoUnit === baseBruttoUnit) {
    if (LITER_OR_KILOGRAM(baseBruttoUnit)) {
      brutto = brutto * MULTIPLIER;
    }
  } else {
    if (LITER_OR_KILOGRAM(bruttoUnit)) {
      brutto = brutto * MULTIPLIER;
    }

    if (bruttoUnit === UNIT_TYPE.PIECE) {
      if (LITER_OR_KILOGRAM(baseBruttoUnit)) {
        brutto = brutto * baseQuantity * MULTIPLIER;
      }

      if (MILLILITER_OR_GRAM(baseBruttoUnit)) {
        brutto = brutto * baseQuantity;
      }
    }
  }

  if (Number(netto) >= brutto) {
    netto = String(brutto);
  }

  return netto;
};

export const validateLosses = (losses: number): number => {
  if (losses <= 0) {
    losses = 0;
  }

  if (losses >= 100) {
    losses = 100;
  }

  return losses;
};
