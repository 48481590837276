import { Injectable } from '@angular/core';

import {
  mutationCreateStoreV2,
  mutationUpdateStore,
  queryAllStores,
  queryAllStoresForPaymentSystem,
  queryAllStoresShort,
  queryStoreById,
  queryStores,
  queryStoresShort,
  queryStoresTotal,
} from '@api';
import { ApiService } from '@services/core';
import {
  Mutation,
  MutationCreateStoreV2Args,
  MutationResult,
  MutationUpdateStoreArgs,
  Query,
  QueryResult,
  QueryStoreArgs,
  QueryStoresArgs,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class StoresApi {
  constructor(private apiService: ApiService) {}

  getAllStores(): QueryResult<'allStores'> {
    return this.apiService.watch<Query<'allStores'>, {}>({
      query: queryAllStores,
    }).valueChanges;
  }

  getAllStoresShort(): QueryResult<'allStores'> {
    return this.apiService.watch<Query<'allStores'>, {}>({
      query: queryAllStoresShort,
    }).valueChanges;
  }

  getStores(variables: QueryStoresArgs): QueryResult<'stores'> {
    return this.apiService.watch<Query<'stores'>, QueryStoresArgs>({
      query: queryStores,
      variables,
    }).valueChanges;
  }

  getStoresShort(variables: QueryStoresArgs): QueryResult<'stores'> {
    return this.apiService.watch<Query<'stores'>, QueryStoresArgs>({
      query: queryStoresShort,
      variables,
    }).valueChanges;
  }

  allStoresForPaymentSystem(): QueryResult<'allStores'> {
    return this.apiService.query<Query<'allStores'>, {}>({
      query: queryAllStoresForPaymentSystem,
    });
  }

  getStoresTotalNumber(variables: QueryStoresArgs): QueryResult<'stores'> {
    return this.apiService.query<Query<'stores'>, QueryStoresArgs>({
      query: queryStoresTotal,
      variables,
    });
  }

  getStore(variables: QueryStoreArgs): QueryResult<'store'> {
    return this.apiService.query<Query<'store'>, QueryStoreArgs>({
      query: queryStoreById,
      variables,
    });
  }

  createStore(variables: MutationCreateStoreV2Args): MutationResult<'createStoreV2'> {
    return this.apiService.mutate<Mutation<'createStoreV2'>, MutationCreateStoreV2Args>({
      mutation: mutationCreateStoreV2,
      variables,
    });
  }

  updateStore(variables: MutationUpdateStoreArgs): MutationResult<'updateStore'> {
    return this.apiService.mutate<Mutation<'updateStore'>, MutationUpdateStoreArgs>({
      mutation: mutationUpdateStore,
      variables,
    });
  }
}
