import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { WeightUnitType, WeightUnitTypeStrict, WeightUnitTypeString } from '@typings';

export const WEIGHT_UNIT_TYPE: WeightUnitTypeStrict = {
  GRAM: 'GRAM',
  MILLILITER: 'MILLILITER',
};

export const WEIGHT_UNIT_TYPE_STRING: WeightUnitTypeString = {
  GRAM: 'Грамм',
  MILLILITER: 'Миллилитр',
};

export const WEIGHT_UNIT_TYPE_STRING_SHORT: WeightUnitTypeString = {
  GRAM: 'г',
  MILLILITER: 'мл',
};

const WEIGHT_UNIT_TYPE_KEYS = Object.keys(WEIGHT_UNIT_TYPE);

export const WEIGHT_UNIT_TYPE_LIST: AutocompleteOption<WeightUnitType>[] = WEIGHT_UNIT_TYPE_KEYS.map((type) => ({
  id: type,
  label: WEIGHT_UNIT_TYPE_STRING[type],
  type: 'item',
  data: type,
}));
