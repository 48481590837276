import { ChartDataset, ChartType, DefaultDataPoint } from 'chart.js';

import {
  CoreSchema,
  QueryGetChartReportDailyArgs,
  QueryGetChartReportForSingleStockUnitDailyArgs,
  QueryGetChartReportForSingleStockUnitHourlyArgs,
  QueryGetChartReportForSingleStockUnitMonthlyArgs,
  QueryGetChartReportForSingleStockUnitSummaryArgs,
  QueryGetChartReportHourlyArgs,
  QueryGetChartReportMonthlyArgs,
  QueryGetChartReportSummaryArgs,
} from '@typings';

export type {
  ActiveElement,
  BarControllerDatasetOptions,
  ChartArea,
  ChartData,
  ChartDataset,
  ChartEvent,
  ChartOptions,
  ChartType,
  CommonElementOptions,
  CoreChartOptions,
  DefaultDataPoint,
  DoughnutControllerDatasetOptions,
  FillerControllerDatasetOptions,
  LineOptions,
  PointPrefixedOptions,
  TooltipItem,
} from 'chart.js';

export type DatasetType = ChartDataset<ChartType, DefaultDataPoint<ChartType>>;

export type QueryGetChartDatesArgs =
  | QueryGetChartReportHourlyArgs
  | QueryGetChartReportDailyArgs
  | QueryGetChartReportMonthlyArgs
  | QueryGetChartReportSummaryArgs
  | QueryGetChartReportForSingleStockUnitHourlyArgs
  | QueryGetChartReportForSingleStockUnitDailyArgs
  | QueryGetChartReportForSingleStockUnitMonthlyArgs
  | QueryGetChartReportForSingleStockUnitSummaryArgs;

export enum ChartGradientType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export interface LegendType {
  label: string;
  color?: string;
  data?: string;
}

export type DatasetPaymentPercentage = CoreSchema.PaySystemAnalytics & {
  percentage: string;
};
