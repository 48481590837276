import { gql } from 'apollo-angular';

export const mutationSingIn = gql`
  mutation signIn($login: String!) {
    signIn(login: $login) {
      requestId
      authType
      hasPassword
    }
  }
`;

export const mutationAuth = gql`
  mutation auth($login: LoginInput!) {
    auth(login: $login) {
      accessToken
      refreshToken
    }
  }
`;

export const mutationResetPassword = gql`
  mutation resetPassword($requestId: UUID!, $code: String!) {
    resetPassword(requestId: $requestId, code: $code) {
      transactionId
      result
      errorMessage
    }
  }
`;

export const mutationSingInWithPassword = gql`
  mutation signInWithPassword($login: LoginInput!) {
    auth(login: $login) {
      accessToken
      refreshToken
    }
  }
`;

export const mutationChangeLostPassword = gql`
  mutation changeLostPassword($requestId: UUID!, $code: String!, $password: String!) {
    changeLostPassword(requestId: $requestId, code: $code, password: $password)
  }
`;

export const mutationDemandResetPassword = gql`
  mutation demandResetPassword($login: String!) {
    demandResetPassword(login: $login) {
      transactionId
      output {
        requestId
        identityType
      }
      result
      errorMessage
    }
  }
`;
