import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AuthUserAgreementComponent } from './auth-user-agreement.component';

@NgModule({
  declarations: [AuthUserAgreementComponent],
  exports: [AuthUserAgreementComponent],
  imports: [CommonModule],
})
export class AuthUserAgreementModule {}
