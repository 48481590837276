import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import {
  ArrivalDocumentPaymentStatus,
  DocStatusStrict,
  DocStatusString,
  PaymentStatusDocumentStrict,
  PaymentStatusDocumentString,
  StatusDocumentStrict,
  StatusDocumentString,
} from '@typings';

export const STATUS_DOCUMENT: Omit<StatusDocumentStrict, 'REJECTED'> = {
  CONFIRMED: 'CONFIRMED',
  PENDING: 'PENDING',
};

export const STATUS_DOCUMENT_STRING: Omit<StatusDocumentString, 'REJECTED'> = {
  CONFIRMED: 'Проведен',
  PENDING: 'В ожидании',
};

export const GET_STATUS_DOCUMENT = (status: string) => Object.keys(STATUS_DOCUMENT).find((key) => key === status)!;

export const DOC_STATUS: Omit<DocStatusStrict, 'REJECTED'> = {
  CONFIRMED: 'CONFIRMED',
  OPEN: 'OPEN',
  CANCELED: 'CANCELED',
  FAILURE: 'FAILURE',
  PENDING_CONFIRMATION: 'PENDING_CONFIRMATION',
};

export const DOC_STATUS_STRING: Omit<DocStatusString, 'REJECTED'> = {
  CONFIRMED: 'Проведен',
  OPEN: 'В ожидании',
  CANCELED: 'Закрыт',
  FAILURE: 'В ожидании',
  PENDING_CONFIRMATION: 'В ожидании',
};

export const GET_DOC_STATUS = (status: string) => Object.keys(DOC_STATUS).find((key) => key === status)!;

export const PAYMENT_STATUS_DOCUMENT: PaymentStatusDocumentStrict = {
  PAID: 'PAID',
  PARTIALLY_PAID: 'PARTIALLY_PAID',
  UNPAID: 'UNPAID',
};

export const PAYMENT_STATUS_DOCUMENT_STRING: PaymentStatusDocumentString = {
  PAID: 'Оплачен',
  PARTIALLY_PAID: 'Частично оплачен',
  UNPAID: 'Не оплачен',
};

export const GET_PAYMENT_STATUS_DOCUMENT = (status: string) => Object.keys(PAYMENT_STATUS_DOCUMENT).find((key) => key === status)!;

export const PAYMENT_STATUS_DOCUMENT_OPTIONS: AutocompleteOption<ArrivalDocumentPaymentStatus>[] = Object.keys(PAYMENT_STATUS_DOCUMENT).map(
  (status) => ({
    id: status,
    label: PAYMENT_STATUS_DOCUMENT_STRING[status],
    type: 'item',
    data: status,
  }),
);
