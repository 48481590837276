import { ArrivalDocSortFieldStrict } from '@typings';

export const ARRIVAL_SORT_FIELD: ArrivalDocSortFieldStrict = {
  CREATION_DATE: 'CREATION_DATE',
  DATE: 'DATE',
  DOC_NUMBER: 'DOC_NUMBER',
  STATUS: 'STATUS',
  TOTAL_AMOUNT: 'TOTAL_AMOUNT',
  PAYMENT_STATUS: 'PAYMENT_STATUS',
};
