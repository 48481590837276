import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Row } from '@app/shared/component/table/table.types';

@Component({
  selector: 'nm-footer-select',
  templateUrl: './footer-select.component.html',
  styleUrls: ['./footer-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterSelectComponent {
  @Input() selectedRows: Row[];
  @Input() selectedLabel: string;

  constructor() {}
}
