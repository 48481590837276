import { Injectable } from '@angular/core';

import {
  mutationCreateSupplierV2,
  mutationCreateSupplierV3,
  mutationDeleteSupplier,
  mutationDeleteSupplierV2,
  mutationUpdateSupplierV2,
  mutationUpdateSupplierV3,
  queryAllSuppliersPageable,
  querySupplier,
  querySuppliers,
  querySuppliersShort,
  querySupplierV2,
} from '@api';
import { ApiService } from '@core/service';
import {
  Mutation,
  MutationCreateSupplierV2Args,
  MutationCreateSupplierV3Args,
  MutationDeleteSupplierArgs,
  MutationDeleteSupplierV2Args,
  MutationResult,
  MutationUpdateSupplierV2Args,
  MutationUpdateSupplierV3Args,
  Query,
  QueryAllSuppliersPageableArgs,
  QueryResult,
  QuerySupplierArgs,
  QuerySuppliersArgs,
  QuerySupplierV2Args,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class SupplierApi {
  constructor(private apiService: ApiService) {}

  getAllSuppliersPageable(variables: QueryAllSuppliersPageableArgs): QueryResult<'allSuppliersPageable'> {
    return this.apiService.query<Query<'allSuppliersPageable'>, QueryAllSuppliersPageableArgs>({
      query: queryAllSuppliersPageable,
      variables,
    });
  }

  getAllSuppliersPageableV2(variables: QuerySuppliersArgs): QueryResult<'suppliers'> {
    return this.apiService.query<Query<'suppliers'>, QuerySuppliersArgs>({
      query: querySuppliers,
      variables,
    });
  }

  getSuppliersShort(variables: QueryAllSuppliersPageableArgs): QueryResult<'allSuppliersPageable'> {
    return this.apiService.query<Query<'allSuppliersPageable'>, QueryAllSuppliersPageableArgs>({
      query: querySuppliersShort,
      variables,
    });
  }

  getSupplierById(variables: QuerySupplierArgs): QueryResult<'supplier'> {
    return this.apiService.query<Query<'supplier'>, QuerySupplierArgs>({
      query: querySupplier,
      variables,
    });
  }

  getSupplierByIdV2(variables: QuerySupplierV2Args): QueryResult<'supplierV2'> {
    return this.apiService.query<Query<'supplierV2'>, QuerySupplierV2Args>({
      query: querySupplierV2,
      variables,
    });
  }

  createSupplierV2(variables: MutationCreateSupplierV2Args) {
    return this.apiService.mutate<Mutation<'createSupplierV2'>, MutationCreateSupplierV2Args>({
      mutation: mutationCreateSupplierV2,
      variables,
    });
  }

  createSupplierV3(variables: MutationCreateSupplierV3Args) {
    return this.apiService.mutate<Mutation<'createSupplierV3'>, MutationCreateSupplierV3Args>({
      mutation: mutationCreateSupplierV3,
      variables,
    });
  }

  updateSupplierV2(variables: MutationUpdateSupplierV2Args) {
    return this.apiService.mutate<Mutation<'updateSupplierV2'>, MutationUpdateSupplierV2Args>({
      mutation: mutationUpdateSupplierV2,
      variables,
    });
  }

  updateSupplierV3(variables: MutationUpdateSupplierV3Args) {
    return this.apiService.mutate<Mutation<'updateSupplierV3'>, MutationUpdateSupplierV3Args>({
      mutation: mutationUpdateSupplierV3,
      variables,
    });
  }

  deleteSupplier(variables: MutationDeleteSupplierArgs): MutationResult<'deleteSupplier'> {
    return this.apiService.mutate<Mutation<'deleteSupplier'>, MutationDeleteSupplierArgs>({
      mutation: mutationDeleteSupplier,
      variables,
    });
  }

  deleteSupplierV2(variables: MutationDeleteSupplierV2Args): MutationResult<'deleteSupplierV2'> {
    return this.apiService.mutate<Mutation<'deleteSupplierV2'>, MutationDeleteSupplierV2Args>({
      mutation: mutationDeleteSupplierV2,
      variables,
    });
  }
}
