import { OrderStatusStrictExtended, OrderStatusStringExtended } from '@typings';

export const ORDER_STATUS: OrderStatusStrictExtended = {
  CLOSED: 'CLOSED',
  NEW: 'CLOSED',
  CANCELED: 'CANCELED',
};

export const ORDER_STATUS_STRING: OrderStatusStringExtended = {
  CLOSED: 'Закрыт',
  NEW: 'Открыт',
  CANCELED: 'Заказ отменён',
  REFUNDED: 'Возвращен',
};
