import { Injectable } from '@angular/core';

import { mutationUpdateUIState, queryGetUIStateTheme } from '@app/api/queries/ui';
import { MutationUpdateUiStateArgs, QueryGetUiStateArgs } from '@app/typings/ui';
import { ApiService } from '@core/service';
import { Mutation, Query, QueryResult } from '@typings';
@Injectable({
  providedIn: 'root',
})
export class UiApi {
  constructor(private apiService: ApiService) {}

  setColorTheme(variables: MutationUpdateUiStateArgs) {
    return this.apiService.mutate<Mutation<'updateUIState'>, MutationUpdateUiStateArgs>({
      mutation: mutationUpdateUIState,
      variables,
    });
  }

  getColorTheme(): QueryResult<'getUIState'> {
    return this.apiService.query<Query<'getUIState'>, QueryGetUiStateArgs>({
      query: queryGetUIStateTheme,
    });
  }
}
