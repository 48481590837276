import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModalBaseModule } from '@app/shared/component/dialog/modal-base/modal-base.module';
import { InputModule } from '@app/shared/component/input/input.module';

import { FileUrlDialogComponent } from './file-url-dialog.component';

@NgModule({
  declarations: [FileUrlDialogComponent],
  imports: [CommonModule, InputModule, ModalBaseModule],
  exports: [FileUrlDialogComponent],
})
export class FileUrlDialogModule {}
