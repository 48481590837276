import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ROUTE_CREATE_NEW, SettingsRouteParam } from '@constants';
import { StoresService } from '@services/settings';
import { Store } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class StoreResolver implements Resolve<Store> {
  constructor(private router: Router, private storesService: StoresService) {
    // TODO: show preloader
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Store> {
    const id = route.params[SettingsRouteParam.storeId];

    return this.storesService.getStore(id).pipe(
      // TODO: hide preloader
      catchError((err) => {
        this.router.navigateByUrl(state.url.replace(String(id), ROUTE_CREATE_NEW));

        return throwError(() => err);
      }),
    );
  }
}
