import { Observable } from '@apollo/client/core';
import { ErrorPaymentFunc } from '@typings';

export const errorPayment: ErrorPaymentFunc = ({ storage }) => {
  const orgId = storage.getOrgId();
  storage.redirectPaymentRequired(orgId || '', `Payment required for organizationId ${orgId}.`);

  return new Observable((observer) => {
    observer.complete();
  });
};
