import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SessionStorage } from '@services/api';

@Component({
  selector: 'nm-check-layout',
  templateUrl: './check-layout.component.html',
  styleUrls: ['./check-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckLayoutComponent {
  @Input() title: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private sessionStorage: SessionStorage) {
    this.title = this.activatedRoute.snapshot.data.title;
  }

  onClickButton(): void {
    this.router.navigateByUrl(`${this.sessionStorage.getOrgId()}/${this.activatedRoute.snapshot.data.param}`);
  }
}
