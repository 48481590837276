import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';
import { OrdersReportMetric, OrdersReportMetricNames, OrdersReportSortFieldStrict } from '@typings';

export const ORDERS_REPORT_METRICS: OrdersReportMetricNames = {
  revenue: 'Выручка',
  profit: 'Прибыль',
  itemsCount: 'Количество позиций',
  guestsCount: 'Количество гостей',
  ordersCount: 'Количество заказов',
};

export const getOrdersReportMetricsItems = (): MenuItem[] => {
  return Object.keys(ORDERS_REPORT_METRICS).map((metric) => ({
    id: metric,
    label: ORDERS_REPORT_METRICS[metric],
    type: 'item',
  }));
};

export const getOrdersMetricName = (metric: OrdersReportMetric): string => {
  return ORDERS_REPORT_METRICS[metric];
};

export const getOrdersMetricsList = (): OrdersReportMetric[] => {
  return Object.keys(ORDERS_REPORT_METRICS);
};

export const ORDERS_REPORT_SORT_FIELD: OrdersReportSortFieldStrict = {
  CURRENCY: 'CURRENCY',
  DATE: 'DATE',
  FISCAL_STATUS: 'FISCAL_STATUS',
  GUESTS_COUNT: 'GUESTS_COUNT',
  INCOME_AMOUNT: 'INCOME_AMOUNT',
  ITEMS_COUNT: 'ITEMS_COUNT',
  ORDER_AMOUNT: 'ORDER_AMOUNT',
  ORDER_NUMBER: 'ORDER_NUMBER',
  PAYMENTS_AMOUNT: 'PAYMENTS_AMOUNT',
  PAY_SYSTEM_NAMES: 'PAY_SYSTEM_NAMES',
  SHIFT: 'SHIFT',
  STATUS: 'STATUS',
  TAX_AMOUNT: 'TAX_AMOUNT',
};
