import { gql } from 'apollo-angular';

export const queryDarkTaskById = gql`
  query darkTaskById($id: UUID!) {
    darkTaskById(id: $id) {
      id
      status
      createdAt
      doneAt
    }
  }
`;

export const mutationCreateDarkTask = gql`
  mutation createDarkTask($darkTaskInput: DarkTaskInput!) {
    createDarkTask(darkTaskInput: $darkTaskInput) {
      entityId
      errorMessage
      result
    }
  }
`;

export const mutationCreateKitchenWorkshop = gql`
  mutation createKitchenWorkshop($kitchenWorkshopCreateInput: KitchenWorkshopCreateInput) {
    createKitchenWorkshop(kitchenWorkshopCreateInput: $kitchenWorkshopCreateInput)
  }
`;

export const mutationDeleteKitchenWorkshop = gql`
  mutation deleteKitchenWorkshop($id: UUID!) {
    deleteKitchenWorkshop(id: $id)
  }
`;

export const mutationSetTechCardsToKitchenWorkshop = gql`
  mutation setTechCardsToKitchenWorkshop($kitchenWorkshopId: UUID!, $techCardList: [UUID!]!) {
    setTechCardsToKitchenWorkshop(kitchenWorkshopId: $kitchenWorkshopId, techCardList: $techCardList)
  }
`;

export const mutationUpdateDarkTaskStatus = gql`
  mutation updateDarkTaskStatus($darkTaskId: UUID!, $status: Status!) {
    updateDarkTaskStatus(darkTaskId: $darkTaskId, status: $status) {
      entityId
      result
      errorMessage
    }
  }
`;

export const mutationUpdateKitchenWorkshop = gql`
  mutation updateKitchenWorkshop($kitchenWorkshopUpdateInput: KitchenWorkshopUpdateInput) {
    updateKitchenWorkshop(kitchenWorkshopUpdateInput: $kitchenWorkshopUpdateInput)
  }
`;
