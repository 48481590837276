import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

import {
  ChartLabel,
  LOYALTY_CARD_DISCOUNTS_USED_LABELS,
  LOYALTY_CARD_LOAYLTY_LABELS,
  LOYALTY_CARD_REVENUE_WITH_DISCOUNTS_LABELS,
  MONTH_DATE_FORMAT,
} from '@constants';
import { ChartData, ChartDataset, CoreSchema, DefaultDataPoint, UnpackByKeys } from '@typings';

@Pipe({
  name: 'chartStackedBarData',
})
export class ChartStackedBarDataPipe implements PipeTransform {
  constructor() {}

  barWidth = 0.3;

  transform(
    data: CoreSchema.HourlyLoyaltyAnalytics[] | CoreSchema.DailyLoyaltyAnalytics[] = [],
    indicator: keyof CoreSchema.LoyaltyAnalytics,
  ): ChartData<'bar', DefaultDataPoint<'bar'>, string> {
    const datasets: ChartDataset<'bar', DefaultDataPoint<'bar'>>[] = [];

    const values: [Record<string, ChartLabel>, UnpackByKeys<CoreSchema.LoyaltyAnalytics>, boolean][] = [];

    const secondColumnAdditionals = {
      borderRadius: {
        topRight: 2,
        topLeft: 2,
      },
    };

    if (indicator === 'discountsUsed') {
      values.push(
        [LOYALTY_CARD_DISCOUNTS_USED_LABELS, 'ordersWithoutDiscounts', false],
        [LOYALTY_CARD_DISCOUNTS_USED_LABELS, 'ordersWithDiscounts', true],
      );
    } else if (indicator === 'loyaltyUsed') {
      values.push([LOYALTY_CARD_LOAYLTY_LABELS, 'ordersWithoutLoyalty', false], [LOYALTY_CARD_LOAYLTY_LABELS, 'ordersWithLoyalty', true]);
    } else if (indicator === 'revenueWithDiscounts') {
      values.push(
        [LOYALTY_CARD_REVENUE_WITH_DISCOUNTS_LABELS, 'revenueWithDiscounts', false],
        [LOYALTY_CARD_REVENUE_WITH_DISCOUNTS_LABELS, 'revenueWithoutDiscounts', false],
        [LOYALTY_CARD_REVENUE_WITH_DISCOUNTS_LABELS, 'discountMarginLoss', true],
      );
    }

    values.forEach(([list, value, extend]) => {
      datasets.push({
        label: '  ' + list[value].name,
        backgroundColor: list[value].color,
        data: this.prepareDatasetData(data, indicator, value),
        barPercentage: this.barWidth,
        ...(extend && secondColumnAdditionals),
      });
    });

    const chartData: ChartData<'bar', DefaultDataPoint<'bar'>, string> = {
      labels: data.map((d) => ('date' in d ? this.formatDate(d.date) : this.formatHour(d.hour))),
      datasets: datasets,
    };

    return chartData;
  }

  formatDate(date: string): string {
    return dayjs(date).format(MONTH_DATE_FORMAT);
  }

  formatHour(hour = 0): string {
    let stringHour = hour + '';
    if (hour < 10) {
      stringHour = '0' + stringHour;
    }
    return stringHour + ':00';
  }

  prepareDatasetData(
    data: CoreSchema.HourlyLoyaltyAnalytics[] | CoreSchema.DailyLoyaltyAnalytics[],
    indicator: keyof CoreSchema.LoyaltyAnalytics,
    value: UnpackByKeys<CoreSchema.LoyaltyAnalytics>,
  ): DefaultDataPoint<'bar'> {
    return data.map((el) => {
      const item = el.loyaltyAnalytics[indicator] as Record<UnpackByKeys<CoreSchema.LoyaltyAnalytics>, number>;

      return item.hasOwnProperty(value) ? item[value] : 0;
    });
  }
}
