import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { SessionStorage } from '@services/api';
import { AuthService } from '@services/auth';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private sessionStorage: SessionStorage, private authService: AuthService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const refreshToken = this.sessionStorage.getRefreshToken();

    if (!refreshToken) {
      this.authService.login();
      return false;
    }

    return true;
  }
}
