<div class="nm-form-group" [style.row-gap]="gridRowGap" [attr.data-test-id]="testId">
  <div *ngIf="title || description" class="nm-form-group-header">
    <div class="nm-form-group__container">
      <div class="title-row">
        <div *ngIf="title" class="nm-form-group__title" [title]="title">
          <span class="title-content">{{ title }}</span>
        </div>
        <ng-content select="[controls]"></ng-content>
      </div>

      <ng-content select="[description]"></ng-content>

      <span
        *ngIf="description && isOpen"
        class="nm-form-group__description"
        [title]="description"
        [style.width]="title && description && isAction ? 'calc(100% + 56px)' : '100%'"
      >
        {{ description }}
      </span>
    </div>

    <ng-content select="[add-button]"></ng-content>

    <nm-icon-button
      *ngIf="isAction"
      class="nm-form-group__action"
      variant="outlined"
      [icon]="isOpen ? 'keyboardArrowUp' : 'keyboardArrowDown'"
      testId="button-toggle-expand"
      (click)="handleToggleOpen()"
    ></nm-icon-button>
  </div>

  <div *ngIf="isOpen" class="nm-form-group__content">
    <ng-content></ng-content>
  </div>
</div>
