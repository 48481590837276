import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { TreeDataService, TreeNode, TreeNodeFetchResult } from '@app/shared/service/tree-data.service';
import { getProductTypeString, rootCatalogSection } from '@constants';
import { CachedProductsAndCategories, CatalogSection, CategoryLine, InCatalogResponse, ProductLine } from '@typings';

import { CatalogStorage } from '../catalog.storage';

export type CatalogTreeServiceOptions = {
  excludedSectionIds: string[];
  isRootSection: boolean;
  categories: boolean;
  selectedNodeIds$?: Observable<string[]>;
  filteredId?: string;
};

const defaultOptions: CatalogTreeServiceOptions = {
  excludedSectionIds: [],
  isRootSection: false,
  categories: false,
  selectedNodeIds$: undefined,
  filteredId: '',
};

@Injectable({
  providedIn: 'root',
})
export class CachedCatalogTreeService {
  cachedParentSectionIds = new Set<string>();
  cachedCatalogResponse = new BehaviorSubject<CachedProductsAndCategories[]>([]);
  constructor(private catalogStorage: CatalogStorage) {}

  initSections(options: Partial<CatalogTreeServiceOptions> = {}): TreeDataService<AutocompleteOption> {
    this.cachedParentSectionIds = new Set<string>();
    const mapper = (catalogResponse: InCatalogResponse | CachedProductsAndCategories) => {
      const { id, name } = catalogResponse as InCatalogResponse;
      const { skuId } = catalogResponse as ProductLine;

      const option: TreeNodeFetchResult<AutocompleteOption> = {
        id: id || skuId,
        isParent: false,
        data: {
          id: id || skuId,
          type: 'item',
          label: name,
          description: (catalogResponse as ProductLine)?.type
            ? this.getDescriptionForProduct(catalogResponse as ProductLine)
            : this.getDescriptionForSection(catalogResponse as CategoryLine),
          data: catalogResponse,
          catalogType: 'depthLvl' in catalogResponse ? 'CATEGORY' : 'PRODUCT',
        },
      };

      option.isParent = this.cachedParentSectionIds.has((catalogResponse as CategoryLine).id);

      return option;
    };

    return this.#initSections(mapper, options);
  }

  #initSections<T>(
    mapper: (section: CachedProductsAndCategories | CatalogSection) => TreeNodeFetchResult<T>,
    options: Partial<CatalogTreeServiceOptions> = {},
  ): TreeDataService<T> {
    const mergedOptions: CatalogTreeServiceOptions = {
      ...defaultOptions,
      ...options,
    };
    const cachedCatalogResponse = new ReplaySubject<CachedProductsAndCategories[]>();
    const sectionsTreeService = new TreeDataService<T>();
    sectionsTreeService.categoriesTree = !!options.categories;
    this.catalogStorage
      .getCachedCatalog()
      .pipe(first())
      .subscribe((res) => {
        const catalog = res.data.fullCatalog?.catalog!;
        const productsAndCategories = [...(catalog?.categories as CategoryLine[]), ...(catalog?.products as ProductLine[])];
        if (mergedOptions.categories) {
          catalog.categories?.forEach((item) => item.parentCategoryId && this.cachedParentSectionIds.add(item.parentCategoryId));
        } else {
          productsAndCategories.forEach((item) => item.parentCategoryId && this.cachedParentSectionIds.add(item?.parentCategoryId));
        }

        if (mergedOptions.categories && catalog?.categories) {
          cachedCatalogResponse.next(catalog?.categories);
        } else {
          cachedCatalogResponse.next(productsAndCategories);
        }

        this.cachedCatalogResponse.next(productsAndCategories);
      });
    sectionsTreeService.fetchChildren = (parentSectionId, maxDepth): Observable<TreeNodeFetchResult<T>[]> => {
      let fetchedCatalogResponses$: Observable<CachedProductsAndCategories[] | (CachedProductsAndCategories | CatalogSection)[]> =
        cachedCatalogResponse.pipe(
          map((cachedCatalogResponse) => {
            if (maxDepth === 0 && !parentSectionId) {
              return cachedCatalogResponse.filter((section) => !section?.parentCategoryId);
            }

            return cachedCatalogResponse.filter((section) => section.parentCategoryId === parentSectionId);
          }),
        );

      return fetchedCatalogResponses$.pipe(
        map((catalogResponses) => {
          if (!parentSectionId && mergedOptions.isRootSection) {
            catalogResponses = [rootCatalogSection, ...catalogResponses];
          }

          return catalogResponses
            .filter((catalogResponse) => {
              const sectionNotInList = !mergedOptions.excludedSectionIds.includes(
                'id' in catalogResponse ? catalogResponse.id : catalogResponse.skuId,
              );

              return sectionNotInList;
            })
            .map((res) => mapper(res));
        }),
      );
    };
    sectionsTreeService.fromLoadedNodes = true;
    sectionsTreeService.filterNodeFromStorage = (node: TreeNode<T>, searchText: string) => {
      const nodeNameLowerCase = (node as TreeNode<AutocompleteOption<CachedProductsAndCategories>>).data?.data?.name.toLowerCase()!;
      const searchTextLowerCase = searchText.toLowerCase();

      return nodeNameLowerCase.includes(searchTextLowerCase);
    };
    const loadedPromise = sectionsTreeService.loadAllNodes();

    loadedPromise.then(() => {
      if (mergedOptions.selectedNodeIds$) {
        mergedOptions.selectedNodeIds$.subscribe((selectedIds) => {
          sectionsTreeService.setSelectedNodeIds(selectedIds);
        });
      }
    });

    return sectionsTreeService;
  }
  getDescriptionForProduct(product: ProductLine) {
    const type = product.type ? getProductTypeString(product.type) : '';
    const section = product?.parentCategoryId
      ? this.cachedCatalogResponse.getValue().find((item) => (item as CategoryLine).id === product?.parentCategoryId)?.name
      : '';
    if (!section) {
      return `${type}`;
    }
    return `${section} • ${type}`;
  }

  getDescriptionForSection(section: CategoryLine) {
    if (section?.parentCategoryId) {
      return `${
        this.cachedCatalogResponse.getValue().find((item) => (item as CategoryLine).id === section?.parentCategoryId)?.name
      } • Категория`;
    }

    return 'Категория';
  }
}
