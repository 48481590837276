import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilterPanelService {
  currentView$ = new BehaviorSubject<TemplateRef<unknown> | null>(null);
  isPanelOpened$ = new BehaviorSubject<boolean>(false);
  previousMenu$ = new BehaviorSubject<TemplateRef<unknown> | null>(null);

  setView(view: TemplateRef<unknown> | null, previousMenu: TemplateRef<unknown> | null = null) {
    this.previousMenu$.next(previousMenu);
    this.currentView$.next(view);
  }

  getView() {
    return this.currentView$.getValue();
  }

  closePanel() {
    this.isPanelOpened$.next(false);
  }
}
