import { Pipe, PipeTransform } from '@angular/core';

import { SessionStorage } from '@services/api';
import { CURRENCY_SHORT } from '@typings';

@Pipe({
  name: 'documentItemsCurrency',
})
export class DocumentItemsCurrencyPipe implements PipeTransform {
  constructor(private sessionStorage: SessionStorage) {}
  transform(value: string): string {
    return value + ' ' + CURRENCY_SHORT[this.sessionStorage.getCurrencyUnit()];
  }
}
