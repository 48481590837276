<nm-modal-base
  (cancel)="closeForm(false)"
  (ok)="closeForm(true)"
  okTitle="Создать"
  okVariant="filled"
  [cancelTitle]="'Отменить'"
>
  <div modal-base-title>{{ data.title }}</div>

  <div style="width: 512px">
    <ng-container *ngIf="data.form as form">
      <nm-form class="form" [form]="form">
        <nm-input
          label="Название"
          placeholder="Введите название"
          ngDefaultControl
          [required]="true"
          [maxLength]="maxCharacters.PRODUCT_NAME"
          [formControl]="name"
          [error]="validationErrorsService.isControlDirtyAndInvalid(name)"
          [hint]="validationErrorsService.validateField(name) || name.getError('name')"
        ></nm-input>

        <nm-form-row [numberOfColumns]="2">
          <nm-autocomplete
            ngDefaultControl
            label="Раздел"
            placeholder="Выберите раздел"
            [required]="true"
            [options]="productTypeList$"
            [formControl]="type"
            [error]="validationErrorsService.isControlDirtyAndInvalid(type)"
            [hint]="validationErrorsService.validateField(type) || type.getError('type')"
            testId="product::type"
            (ngModelChange)="productTypeChange()"
          ></nm-autocomplete>

          <nm-autocomplete
            ngDefaultControl
            label="Категория"
            placeholder="Выберите категорию"
            [required]="true"
            [formControl]="section"
            [createOption]="false"
            [options]="treeService.options$"
            [searchFn]="treeService.searchFn"
            [getSelectedOptionFn]="treeService.getSelectedOptionFn"
            [returnObjects]="true"
            [search]="true"
            [error]="isSectionError"
            [hint]="isSectionError ? 'Обязательное поле' : ''"
          ></nm-autocomplete>
        </nm-form-row>

        <nm-autocomplete
          (ngModelChange)="unitTypeChange($event)"
          [error]="validationErrorsService.isControlDirtyAndInvalid(unit)"
          [formControl]="unit"
          [hint]="unitControlHint(unit, 'unit')"
          [options]="unitTypeList$"
          [required]="true"
          hinticon="info"
          label="Единица измерения"
          ngDefaultControl
          placeholder="Выберите единицу измерения"
          testId="product::unit-type"
        ></nm-autocomplete>

        <label class="product-info-switch">
          <span class="container">
            <span class="title"> Порционный товар </span>

            <span class="description">
              Активируя этот режим, вы сможете указать размер порции. При добавлении состава, списание будет происходить
              согласно технологической карте.
            </span>
          </span>

          <nm-switch ngDefaultControl [formControl]="weighable"></nm-switch>
        </label>

        <nm-form-row *ngIf="weighable.value" [numberOfColumns]="2">
          <nm-input
            #quantityInput
            ngDefaultControl
            label="Размер порции к списанию"
            placeholder="Введите значение"
            [suffix]="getSuffixForQuantity()"
            [formControl]="quantity"
            [error]="!isQuantityDisabled && validationErrorsService.isControlDirtyAndInvalid(quantity)"
            [hint]="!isQuantityDisabled && getErrorDescription(quantity)"
            testId="product::quantity"
            (input)="quantityChange(quantityInput.inputElem.nativeElement.value)"
          ></nm-input>
        </nm-form-row>

        <nm-form-row [numberOfColumns]="3">
          <nm-input
            #primePriceInput
            ngDefaultControl
            label="Себестоимость"
            placeholder="Введите значение"
            [required]="true"
            [suffix]="currency"
            [formControl]="primePrice"
            [error]="isPrimePriceError"
            [hint]="getErrorDescription(primePrice)"
            hintIcon="info"
            (input)="primePriceChange(primePriceInput.inputElem.nativeElement.value)"
          ></nm-input>

          <nm-input
            #surchargeInput
            ngDefaultControl
            label="Наценка"
            placeholder="Введите значение"
            suffix="%"
            [formControl]="surcharge"
            [error]="isSurchargeError"
            [hint]="getErrorDescription(surcharge)"
            hintIcon="info"
            (input)="surchargeChange(surchargeInput.inputElem.nativeElement.value)"
          ></nm-input>

          <nm-input
            #salePriceInput
            ngDefaultControl
            label="Цена"
            placeholder="Введите значение"
            [required]="true"
            [suffix]="currency"
            [formControl]="salePrice"
            [error]="isSalePriceError"
            [hint]="getErrorDescription(salePrice)"
            hintIcon="info"
            (input)="salePriceChange(salePriceInput.inputElem.nativeElement.value)"
          ></nm-input>
        </nm-form-row>
      </nm-form>
    </ng-container>
  </div>
</nm-modal-base>
