import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconButtonModule } from '@app/shared/component/icon-button/icon-button.module';

import { FormGroupComponent } from './form-group.component';

@NgModule({
  declarations: [FormGroupComponent],
  imports: [CommonModule, IconButtonModule],
  exports: [FormGroupComponent],
})
export class FormGroupModule {}
