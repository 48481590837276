import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '@app/shared/component/button/button.module';
import { IconButtonModule } from '@app/shared/component/icon-button/icon-button.module';
import { SvgIconModule } from '@app/shared/component/svg-icon/svg-icon.module';

import { BannerComponent } from './banner.component';

@NgModule({
  declarations: [BannerComponent],
  imports: [CommonModule, SvgIconModule, ButtonModule, IconButtonModule],
  exports: [BannerComponent],
})
export class BannerModule {}
