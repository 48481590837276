<nm-input
  [formControl]="customControl"
  [inputMask]="timeMask"
  [label]="label"
  [hint]="hint"
  [disabled]="disabled"
  [required]="required"
  [error]="error"
  placeholder="ЧЧ:ММ"
>
</nm-input>
