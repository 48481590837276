<nm-control-panel
  *ngrxLet="bos.isMobile$ as isMobile"
  class="filters-table"
  [isContent]="isContent"
  [attr.data-test-id]="testId"
>
  <ng-container header-left>
    <div #headerLeftWrapper class="filters-list">
      <ng-content select="[header-left]"></ng-content>

      <div *ngIf="showGroupedFilters" #settingsElement class="settings" [class.empty-filters]="emptyFilters">
        <nm-filter-panel
          #filterPanel
          (click)="setTemplate(menuTemplate)"
          [initView]="menuTemplate"
          [positions]="positions"
        >
          <nm-filter-label *ngIf="!isMobile" #labelElement (click)="setTemplate(menuTemplate)">Фильтры</nm-filter-label>

          <nm-icon-button
            *ngIf="isMobile"
            class="icon"
            variant="text"
            icon="filterAlt"
            (click)="setTemplate(menuTemplate)"
            [size]="24"
          ></nm-icon-button>
        </nm-filter-panel>
      </div>

      <nm-input-search
        *ngIf="showSearch"
        placeholder="Поиск"
        [initialValue]="initialSearch"
        [lettersThreshold]="searchCharsCount"
        testId="input-search"
        (searchKeyup)="onSearch($event)"
      ></nm-input-search>
    </div>
  </ng-container>

  <ng-container header-right>
    <div *ngIf="isGraph">
      <nm-filter-panel #filterPanel (click)="setTemplate(graphMenuTemplate)" [initView]="graphMenuTemplate">
        <nm-icon-button
          class="icon"
          variant="text"
          icon="miniBurger"
          (click)="setTemplate(graphMenuTemplate)"
          [size]="24"
        ></nm-icon-button>
      </nm-filter-panel>
    </div>
  </ng-container>

  <ng-container content-left> </ng-container>

  <ng-container content-right> </ng-container>
</nm-control-panel>

<ng-template #menuTemplate>
  <nm-menu-content
    (itemClick)="onMenuItemClick($event, menuTemplate)"
    [items]="items"
    testId="menu-settings"
  ></nm-menu-content>
</ng-template>

<ng-template #graphMenuTemplate>
  <nm-menu-content
    (itemClick)="onMenuItemClick($event, graphMenuTemplate)"
    [items]="graphItems"
    testId="menu-settings"
  ></nm-menu-content>
</ng-template>
