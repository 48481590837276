import { ActionsComponent } from '@app/shared/component/actions/actions.component';
import { ROUTE_CREATE_NEW, ROUTE_IMPORT } from '@constants/navigation';
import { NavDrawerItem } from '@typings';

export enum WarehouseRoute {
  leftovers = 'leftovers',
  arrivals = 'arrivals',
  leavings = 'leavings',
  movings = 'movings',
  inventories = 'inventories',
  movements = 'movements',
  leavingsByOrder = 'leavingsByOrder',
  orders = 'orders',
  suppliers = 'suppliers',
  warehouses = 'warehouses',
  warehousesArchive = 'archive',
}

export enum WarehouseRouteName {
  leftovers = 'Остатки',
  arrivals = 'Поставки',
  leavings = 'Списания',
  movings = 'Перемещения',
  inventories = 'Инвентаризации',
  movements = 'Движение товаров',
  leavingsByOrder = 'Списание на основе заказа',
  orders = 'Заказ',
  suppliers = 'Поставщики',
  warehouses = 'Склады',
  warehousesArchive = 'Архив складов',
}

export enum WarehouseRouteParam {
  arrivalId = 'idArrival',
  movingId = 'idMoving',
  leavingId = 'idLeaving',
  inventoryId = 'idInventory',
  movementId = 'idMovement',
  leavingByOrderId = 'idLeavingByOrder',
  orderId = 'idOrder',
  supplierId = 'idSupplier',
  warehouseId = 'idWarehouse',
}

export const WAREHOUSE_ITEM_LEFTOVERS = {
  title: WarehouseRouteName.leftovers,
  route: WarehouseRoute.leftovers,
  actionsComponent: ActionsComponent,
  routes: [
    {
      title: 'Импорт',
      route: ROUTE_IMPORT,
    },
  ],
};

export const WAREHOUSE_ITEM_ARRIVALS = {
  title: WarehouseRouteName.arrivals,
  route: WarehouseRoute.arrivals,
  actionsComponent: ActionsComponent,
  routes: [
    {
      title: 'Новая поставка',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Поставка',
      route: WarehouseRouteParam.arrivalId,
    },
    {
      title: 'Импорт',
      route: ROUTE_IMPORT,
    },
  ],
};

export const WAREHOUSE_ITEM_LEAVINGS = {
  title: WarehouseRouteName.leavings,
  route: WarehouseRoute.leavings,
  actionsComponent: ActionsComponent,
  routes: [
    {
      title: 'Новое списание',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Списание',
      route: WarehouseRouteParam.leavingId,
    },
  ],
};

export const WAREHOUSE_ITEM_MOVINGS = {
  title: WarehouseRouteName.movings,
  route: WarehouseRoute.movings,
  actionsComponent: ActionsComponent,
  routes: [
    {
      title: 'Новое перемещение',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Перемещение',
      route: WarehouseRouteParam.movingId,
    },
  ],
};

export const WAREHOUSE_ITEM_INVENTORIES = {
  title: WarehouseRouteName.inventories,
  route: WarehouseRoute.inventories,
  actionsComponent: ActionsComponent,
  routes: [
    {
      title: 'Новая инвентаризация',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Инвентаризация',
      route: WarehouseRouteParam.inventoryId,
    },
  ],
};

export const WAREHOUSE_ITEM_MOVEMENTS = {
  title: WarehouseRouteName.movements,
  route: WarehouseRoute.movements,
  routes: [
    {
      title: 'Движение товара',
      route: WarehouseRouteParam.movementId,
      routes: [
        {
          title: 'Поставка',
          route: `${WarehouseRoute.arrivals}/${WarehouseRouteParam.arrivalId}`,
        },
        {
          title: 'Перемещение',
          route: `${WarehouseRoute.movings}/${WarehouseRouteParam.movingId}`,
        },
        {
          title: 'Списание',
          route: `${WarehouseRoute.leavings}/${WarehouseRouteParam.leavingId}`,
        },
        {
          title: 'Инвентаризация',
          route: `${WarehouseRoute.inventories}/${WarehouseRouteParam.inventoryId}`,
        },
        {
          title: WarehouseRouteName.leavingsByOrder,
          route: `${WarehouseRoute.leavingsByOrder}/${WarehouseRouteParam.leavingByOrderId}`,
          routes: [
            {
              title: WarehouseRouteName.orders,
              route: `${WarehouseRoute.orders}/${WarehouseRouteParam.orderId}`,
            },
          ],
        },
      ],
    },
  ],
};

export const WAREHOUSE_ITEM_SUPPLIERS = {
  title: WarehouseRouteName.suppliers,
  route: WarehouseRoute.suppliers,
  actionsComponent: ActionsComponent,
  routes: [
    {
      title: 'Новый поставщик',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Поставщик',
      route: WarehouseRouteParam.supplierId,
    },
    {
      title: 'Импорт',
      route: ROUTE_IMPORT,
    },
  ],
};

export const WAREHOUSE_ITEM_WAREHOUSES = {
  title: WarehouseRouteName.warehouses,
  route: WarehouseRoute.warehouses,
  actionsComponent: ActionsComponent,
  routes: [
    {
      title: 'Новый склад',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: WarehouseRouteName.warehousesArchive,
      route: WarehouseRoute.warehousesArchive,
    },
    {
      title: 'Склад',
      route: WarehouseRouteParam.warehouseId,
    },
  ],
};

export const WAREHOUSE_CHILD_ITEMS: NavDrawerItem[] = [
  WAREHOUSE_ITEM_LEFTOVERS,
  WAREHOUSE_ITEM_ARRIVALS,
  WAREHOUSE_ITEM_LEAVINGS,
  WAREHOUSE_ITEM_MOVINGS,
  WAREHOUSE_ITEM_INVENTORIES,
  WAREHOUSE_ITEM_MOVEMENTS,
  WAREHOUSE_ITEM_SUPPLIERS,
  WAREHOUSE_ITEM_WAREHOUSES,
];
