<nm-modal-base
  (ok)="roleService.deleteRole(data.role, data.callbackFn)"
  (cancel)="roleService.hideModal()"
  okTitle="Удалить"
  cancelTitle="Отменить"
  okVariant="filled"
>
  <div modal-base-title>Удалить роль</div>
  <div>Вы уверены, что хотите удалить роль?</div>
</nm-modal-base>
