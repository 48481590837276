import { Pipe, PipeTransform } from '@angular/core';

import { PAY_SYSTEM_TYPE_STRING } from '@constants';
import { PaySystemType } from '@typings';

@Pipe({
  name: 'paySystem',
})
export class PaySystemTypePipe implements PipeTransform {
  transform(type: PaySystemType): string {
    return PAY_SYSTEM_TYPE_STRING[type];
  }
}
