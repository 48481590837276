import { gql } from 'apollo-angular';

import { fragmentMutationResult } from './mutations';

export const mutationCreateInventoryDocument = gql`
  mutation createInventoryDocument($inventoryDocumentCreateInput: InventoryDocumentCreateInput!) {
    createInventoryDocument(inventoryDocumentCreateInput: $inventoryDocumentCreateInput) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationUpdateInventoryDocument = gql`
  mutation updateInventoryDocument($inventoryDocumentUpdateInput: InventoryDocumentUpdateInput!) {
    updateInventoryDocument(inventoryDocumentUpdateInput: $inventoryDocumentUpdateInput) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationDeleteInventoryDocument = gql`
  mutation deleteInventoryDocument($inventoryDocumentId: UUID!) {
    deleteInventoryDocument(inventoryDocumentId: $inventoryDocumentId) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationConfirmInventoryDocument = gql`
  mutation confirmInventoryDocument($inventoryDocumentId: UUID!, $warehouseId: UUID!) {
    confirmInventoryDocument(inventoryDocumentId: $inventoryDocumentId, warehouseId: $warehouseId) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationRejectInventoryDocument = gql`
  mutation rejectInventoryDocument($inventoryDocumentId: UUID!) {
    rejectInventoryDocument(inventoryDocumentId: $inventoryDocumentId) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const fragmentInventoryDoc = gql`
  fragment InventoryDoc on InventoryDoc {
    id
    date
    description
    number
    status
    revertedDate
    deltaSum
    storageRoom {
      id
      name
      archived
    }
    type
  }
`;

export const mutationDuplicateInventoryDocument = gql`
  mutation duplicateInventoryDocument($documentId: UUID!) {
    duplicateInventoryDocument(documentId: $documentId) {
      transactionId
      errorMessage
      result
      output {
        id
      }
    }
  }
`;

export const queryAllInventoryDocumentsPageable = gql`
  query allInventoryDocumentsPageable($filter: DocumentFilterInput!, $pageRequest: PageRequestInput!) {
    allInventoryDocumentsPageable(filter: $filter, pageRequest: $pageRequest) {
      content {
        id
        docNumber
        type
        date
        from {
          id
          name
        }
        status
        documentItems {
          planQuantity
          factQuantity
          stockUnit {
            id
            primePrice {
              amountValue
              currencyUnit
            }
          }
        }
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
`;

export const queryAllInventoryDocumentsPageableV2 = gql`
  query allInventoryDocumentsPageableV2(
    $filter: InventoryDocumentFilterInputV2
    $pageRequest: PageRequestInput
    $sort: [AllInventoryDocumentsPageableV2Sort]
  ) {
    allInventoryDocumentsPageableV2(filter: $filter, pageRequest: $pageRequest, sort: $sort) {
      content {
        id
        docNumber
        type
        date
        from {
          id
          name
        }
        status
        documentItems {
          planQuantity
          factQuantity
          stockUnit {
            id
            primePrice {
              amountValue
              currencyUnit
            }
            quantity
          }
        }
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
`;

export const queryInventoryDocument = gql`
  query inventoryDocument($id: UUID!) {
    inventoryDocument(id: $id) {
      id
      docNumber
      type
      date
      creationDate
      typeDocument
      status
      description
      from {
        id
        name
      }
      documentItems {
        name
        stockUnit {
          id
          name
          unit
          primePrice {
            amountValue
            currencyUnit
          }
          quantity
        }
        currency
        amount
        comment
        lastInventoryDocumentId
        lastInventoryDate
        leftoverOnLastInventory
        arrivalFromLastInventory
        soldFromLastInventory
        writtenOffFromLastInventory
        planQuantity
        factQuantity
        quantityDelta
        amountDelta
      }
    }
  }
`;

export const queryStockUnitInventoryData = gql`
  query stockUnitInventoryData($stockUnitIds: [UUID!]!, $warehouseId: UUID!) {
    stockUnitInventoryData(stockUnitIds: $stockUnitIds, warehouseId: $warehouseId) {
      stockUnitId
      lastInventoryDocumentId
      lastInventoryDate
      leftoverOnLastInventory
      arrivalFromLastInventory
      soldFromLastInventory
      writtenOffFromLastInventory
      planQuantity
    }
  }
`;

export const queryGetPossibleDocumentCreationTypes = gql`
  query getPossibleDocumentCreationTypes($warehouseId: UUID!) {
    getPossibleDocumentCreationTypes(warehouseId: $warehouseId)
  }
`;

export const queryInventoryDoc = gql`
  query inventoryDoc($id: UUID!) {
    inventoryDoc(id: $id) {
      ...InventoryDoc
    }
  }
  ${fragmentInventoryDoc}
`;

export const queryInventoryDocs = gql`
  query inventoryDocs($filter: InventoryDocFilter!, $pageRequest: PageRequestInput!, $sort: [InventoryDocSort!]) {
    inventoryDocs(filter: $filter, pageRequest: $pageRequest, sort: $sort) {
      content {
        ...InventoryDoc
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
  ${fragmentInventoryDoc}
`;

export const queryInventoryProducts = gql`
  query inventorProducts($filter: InventoryProductFilter!, $pageRequest: PageRequestInput!) {
    inventoryProducts(filter: $filter, pageRequest: $pageRequest) {
      content {
        id
        delta
        factQuantity
        planQuantity
        unit
        deltaSum
        primePrice
        product {
          id
          name
          unit
          quantity
        }
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
`;

export const queryProductsInventoryData = gql`
  query productsInventoryData($storageRoomId: UUID!, $productIds: [UUID!]!) {
    productsInventoryData(storageRoomId: $storageRoomId, productIds: $productIds) {
      arrivalFromLastInventory
      lastInventoryDate
      lastInventoryDocumentId
      leftoverOnLastInventory
      planQuantity
      productId
      soldFromLastInventory
      writtenOffFromLastInventory
      primePrice
    }
  }
`;

export const mutationCreateInventoryDoc = gql`
  mutation createInventoryDoc($input: CreateInventoryDocInput!) {
    createInventoryDoc(input: $input) {
      errorMessage
      result
      output {
        ...InventoryDoc
      }
    }
  }
  ${fragmentInventoryDoc}
`;

export const mutationUpdateInventoryDoc = gql`
  mutation updateInventoryDoc($input: UpdateInventoryDocInput!) {
    updateInventoryDoc(input: $input) {
      errorMessage
      result
      output {
        ...InventoryDoc
      }
    }
  }
  ${fragmentInventoryDoc}
`;

export const mutationConfirmInventoryDoc = gql`
  mutation confirmInventoryDoc($id: UUID!) {
    confirmInventoryDoc(id: $id) {
      errorMessage
      result
    }
  }
`;

export const mutationRevertInventoryDoc = gql`
  mutation revertInventoryDoc($id: UUID!) {
    revertInventoryDoc(id: $id) {
      errorMessage
      result
    }
  }
`;

export const mutationDeleteInventoryDoc = gql`
  mutation deleteInventoryDoc($id: UUID!) {
    deleteInventoryDoc(id: $id) {
      errorMessage
      result
    }
  }
`;

export const mutationAddInventoryProducts = gql`
  mutation addInventoryProducts($input: AddInventoryProductsInput!) {
    addInventoryProducts(input: $input) {
      errorMessage
      result
    }
  }
`;

export const mutationUpdateInventoryProducts = gql`
  mutation updateInventoryProducts($input: UpdateInventoryProductsInput!) {
    updateInventoryProducts(input: $input) {
      statuses {
        id
        errorMessage
        result
        output {
          delta
          factQuantity
          id
          planQuantity
          unit
          product {
            id
            name
            unit
          }
        }
      }
    }
  }
`;

export const mutationRemoveInventoryProducts = gql`
  mutation removeInventoryProducts($input: RemoveInventoryProductsInput!) {
    removeInventoryProducts(input: $input) {
      errorMessage
      result
    }
  }
`;
