import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, Observable, of } from 'rxjs';

import { MenuSectionTreeService } from '@app/modules/catalog/services/menu-categories-tree.service';
import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { MenuItemType } from '@app/shared/component/menu-item/menu-item.model';
import { ImagesService } from '@app/shared/service/images.service';
import { TreeDataService } from '@app/shared/service/tree-data.service';
import { COLORS_BASE_LIST, MAX_CHARACTERS } from '@constants';
import { ValidationErrorsService } from '@core/service';
import { MenuService, MenuStorage } from '@services/catalog';
import { TreeDataAutocompleteService } from '@services/shared';
import { CoreSchema, SectionRc } from '@typings';
import { getHexCodeByColor } from '@utils';

@Component({
  selector: 'nm-menu-section-dialog',
  templateUrl: './menu-section-dialog.component.html',
  styleUrls: ['./menu-section-dialog.component.scss'],
})
export class MenuSectionDialogComponent {
  colors = of(COLORS_BASE_LIST);
  MAX_CHARACTERS = MAX_CHARACTERS;
  sections$: Observable<AutocompleteOption<CoreSchema.SectionRc>[]>;
  treeDataService: TreeDataService<AutocompleteOption>;
  loading = false;

  autocompleteTreeService = new TreeDataAutocompleteService();

  get section(): SectionRc | undefined {
    return this.data.section;
  }

  get isNew(): boolean {
    return !this.section;
  }

  get btnTitle(): string {
    return this.isNew ? 'Добавить' : 'Сохранить';
  }

  get title() {
    return this.isNew ? 'Новая категория' : 'Редактирование категории';
  }

  get image() {
    return this.controls.image;
  }

  get colorSection() {
    return this.controls.colorSection;
  }

  get controls() {
    return this.menuService.sectionForm.controls;
  }

  get name() {
    return this.controls.name;
  }
  get menuId() {
    return this.data.menuId;
  }
  get newSectionName() {
    return this.data.sectionName;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { menuId: string; section: SectionRc | undefined; sectionName: string },
    public menuService: MenuService,
    public imagesService: ImagesService,
    public validationErrorsService: ValidationErrorsService,
    private menuStorage: MenuStorage,
    private menuSectionTreeService: MenuSectionTreeService,
  ) {
    this.menuService.initSectionForm(this.section);
    this.treeDataService = this.menuSectionTreeService.initSections({ excludedSectionIds: [this.section?.id || ''] });
    this.autocompleteTreeService.init(this.treeDataService);

    const image = this.image.value;

    if (image && 'imageSizes' in image && image.imageSizes.length && image.imageSizes[0].url) {
      this.imagesService.setFile([image.imageSizes[0].url, image.originalFileName]);
    } else {
      this.imagesService.setFile(null);
    }
    this.sections$ = this.menuStorage
      .getAllInMenuPageable({ filter: { menuIds: [this.menuId], types: ['SECTION'] }, pageable: { page: 0, size: 1000 } })
      .pipe(
        map((res) => {
          const sections = (res.data.menuRcElements.elements as CoreSchema.SectionRc[]).filter((section) =>
            this.section ? section.id !== this.section.id : true,
          );
          return sections.map((section) => {
            return {
              id: section.id,
              label: section.name,
              type: 'item' as MenuItemType,
              imageColor: section.color ? getHexCodeByColor(section.color) : undefined,
              data: section,
            };
          });
        }),
      );
    if (this.newSectionName) {
      this.name.setValue(this.newSectionName);
    }
  }

  closeSectionForm(confirm: boolean) {
    this.menuService.closeSectionForm(confirm);
  }
}
