import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AutocompleteModule } from '@app/shared/component/autocomplete/autocomplete.module';
import { ModalBaseModule } from '@app/shared/component/dialog/modal-base/modal-base.module';
import { FormModule } from '@app/shared/component/form/form.module';
import { FormRowModule } from '@app/shared/component/form-row/form-row.module';
import { InputModule } from '@app/shared/component/input/input.module';
import { SwitchModule } from '@app/shared/component/switch/switch.module';

import { CreateProductDialogComponent } from './create-product-dialog.component';

@NgModule({
  declarations: [CreateProductDialogComponent],
  imports: [CommonModule, ModalBaseModule, FormModule, InputModule, ReactiveFormsModule, FormRowModule, AutocompleteModule, SwitchModule],
  exports: [CreateProductDialogComponent],
})
export class CreateProductDialogModule {}
