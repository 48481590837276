import {
  CHART_AVERAGE_COLOR,
  CHART_CURRENT_COLOR,
  CHART_DONUT_COLORS,
  CHART_HIGH_COLOR,
  CHART_LOW_COLOR,
  CHART_PREVIOUS_COLOR,
} from '@constants';
import { ChartArea, ChartGradientType, OverviewBarOptionsType, OverviewDonutOptionsType, OverviewLineGradientOptionsType } from '@typings';

export const getOverviewCardTitlePostfix = (isDay: boolean = false, isPrev: boolean = false): string => {
  if (!isPrev) {
    return `за ${isDay ? 'день' : 'период'}`;
  } else {
    return `за пред. ${isDay ? 'день' : 'период'}`;
  }
};

export const getDateLabel = (fullDate: string, hasYear = true): string => {
  const months = ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'];
  const date = new Date(fullDate);
  let month = months[date.getMonth()];

  if (month.length === 3) {
    month = 'мая';
  } else {
    month = month.slice(0, 3) + '.';
  }

  return date.getDate() + ' ' + month + ' ' + (hasYear ? date.getFullYear() : '');
};

export const getDateLabelWithTime = (fullDate: string, withTime = ''): string => {
  const date = new Date(fullDate.replace(/-/g, '/'));

  return date.getDate() + '.' + converNumberWithZero(date.getMonth() + 1) + '-' + (withTime ? withTime : '');
};

export const convertTimeFromNumber = (num: number): string => {
  return (num <= 9 ? '0' + num : num) + ':00';
};

export const converNumberWithZero = (num: number): string => {
  return (num <= 9 ? '0' + num : num).toString();
};

export const generateExportDate = (): string => {
  return new Date().toISOString().slice(0, 10);
};

export const canvasChartGradient = (canvas: HTMLCanvasElement): CanvasGradient | string => {
  const gradient = canvas?.getContext('2d')?.createLinearGradient(0, 0, 0, 800);

  gradient?.addColorStop(0, 'rgba(64, 155, 239, 0.2)');
  gradient?.addColorStop(0.4, 'rgba(4, 155, 239, 0)');

  return gradient || 'transparent';
};

export const chartLineGradientParams = (canvas: HTMLCanvasElement, type: ChartGradientType, options?: OverviewLineGradientOptionsType) => {
  const paramsPrimary: OverviewLineGradientOptionsType = {
    pointBackgroundColor: CHART_CURRENT_COLOR,
    pointBorderColor: '#ffffff',
    pointRadius: 4,
    pointBorderWidth: 2,
    fill: true,
    tension: 0,
    backgroundColor: canvasChartGradient(canvas),
    borderColor: CHART_CURRENT_COLOR,
  };

  const paramsSecondary: OverviewLineGradientOptionsType = {
    fill: false,
    tension: 0,
    pointRadius: 4,
    pointBackgroundColor: CHART_PREVIOUS_COLOR,
    pointBorderColor: '#ffffff',
    pointBorderWidth: 2,
    backgroundColor: 'transparent',
    borderColor: CHART_PREVIOUS_COLOR,
  };

  const params: OverviewLineGradientOptionsType = {
    ...(type === ChartGradientType.PRIMARY && paramsPrimary),
    ...(type === ChartGradientType.SECONDARY && paramsSecondary),
    ...options,
  };

  return params;
};

export const chartBarParams = (options?: OverviewBarOptionsType) => {
  const params: OverviewBarOptionsType = {
    barPercentage: 0.5,
    barThickness: 6,
    maxBarThickness: 8,
    minBarLength: 2,
    backgroundColor: CHART_CURRENT_COLOR,
    ...options,
  };

  return params;
};

export const chartBarWideParams = (options?: OverviewBarOptionsType) => {
  const params: OverviewBarOptionsType = {
    barPercentage: 0.5,
    barThickness: 16,
    maxBarThickness: 8,
    minBarLength: 2,
    backgroundColor: CHART_CURRENT_COLOR,
    ...options,
  };

  return params;
};

export const chartDonutParams = (options?: OverviewDonutOptionsType) => {
  const params: OverviewDonutOptionsType = {
    backgroundColor: CHART_DONUT_COLORS,
    ...options,
  };

  return params;
};

export const getChartProfitGradient = (context: CanvasRenderingContext2D, chartArea: ChartArea) => {
  const gradient: CanvasGradient = context.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);

  gradient.addColorStop(0, CHART_LOW_COLOR);
  gradient.addColorStop(0.5, CHART_AVERAGE_COLOR);
  gradient.addColorStop(1, CHART_HIGH_COLOR);

  return gradient;
};

export function toIsoString(date: Date): string {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  const pad = (num: number) => {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? '0' : '') + norm;
  };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  );
}
