import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'myDate',
})
export class MyDatePipe implements PipeTransform {
  transform(value: string | number | Date): string {
    if (!value) {
      return '';
    }
    const date = new Date(value);
    const month = date.toLocaleString('ru', { month: 'short' });

    return date.getDate() + ' ' + month + ' ' + date.getFullYear();
  }
}
