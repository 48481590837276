import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { LoggerService } from '@app/shared/service/logger.service';
import { SessionStorage } from '@services/api';
import { InvitationStorage } from '@services/auth';
import { CatalogService, CatalogStorage } from '@services/catalog';
import { AccountStorage } from '@services/core';
import { RedirectService } from '@services/shared';
import { Catalog } from '@typings';

@Injectable()
export class OrganizationGuard implements CanActivate {
  constructor(
    private accountStorage: AccountStorage,
    private sessionStorage: SessionStorage,
    private invitationStorage: InvitationStorage,
    private redirectService: RedirectService,
    private catalogStorage: CatalogStorage,
    private catalogService: CatalogService,
    private loggerService: LoggerService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.loggerService.logDebugInfo('[OrganizationGuard] canActivate()');

    return new Observable((observable) => {
      const {
        params: { id },
      } = route;
      const orgId = this.sessionStorage.getOrgId();

      if (!orgId) {
        this.redirectService.redirectToChooseOrganization();
        return observable.next(false);
      }

      combineLatest([
        this.accountStorage.account.pipe(
          filter((result) => !!result),
          take(1),
        ),
        iif(
          () => !orgId,
          of(null),
          this.catalogStorage.getCatalogByOrganization().pipe(
            take(1),
            tap((res) => this.catalogStorage.setCatalog(res.data.catalogByOrganization!)),
            map((res) => res.data.catalogByOrganization),
          ),
        ),
      ]).subscribe(([_, catalog]) => {
        const invitation = this.invitationStorage.getInvitation();

        if (orgId && !invitation) {
          if (id && id !== orgId) {
            if (catalog && this.catalogService.isAccess(catalog as Catalog)) {
              this.redirectService.redirectToStart(orgId);
            } else {
              this.redirectService.redirectToCatalogSettings(orgId);
            }

            return observable.next(false);
          }
        }

        return observable.next(true);
      });
    });
  }
}
