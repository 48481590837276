import { Injectable } from '@angular/core';

import { mutationRemoveDevice, queryDevices, querySubscriber } from '@api';
import { ApiService } from '@core/service';
import { Mutation, MutationRemoveDeviceArgs, MutationResult, Query, QueryDevicesArgs, QueryResult } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class DevicesApi {
  constructor(private apiService: ApiService) {}

  subscriber(): QueryResult<'subscriber'> {
    return this.apiService.query<Query<'subscriber'>, {}>({ query: querySubscriber });
  }

  devices(variables: QueryDevicesArgs): QueryResult<'devices'> {
    return this.apiService.query<Query<'devices'>, QueryDevicesArgs>({
      query: queryDevices,
      variables,
    });
  }

  removeDevice(variables: MutationRemoveDeviceArgs): MutationResult<'removeDevice'> {
    return this.apiService.mutate<Mutation<'removeDevice'>, MutationRemoveDeviceArgs>({
      mutation: mutationRemoveDevice,
      variables,
    });
  }
}
