import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { iif, Observable, of } from 'rxjs';
import { concatMap, first, map, tap } from 'rxjs/operators';

import { MenuService, MenuStorage } from '@services/catalog';
import { StoresService } from '@services/settings';
import { QueryResponse, Store } from '@typings';

@Injectable()
export class MenusGuard implements CanActivate {
  constructor(private storesService: StoresService, private menuStorage: MenuStorage, private menuService: MenuService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable((subscriber) => {
      return this.storesService
        .getAllStores()
        .pipe(
          first(),
          tap((stores) => this.menuStorage.setStores(stores)),
          map((stores: Store[]) => stores.map((s) => s.id)),
          concatMap((storeIds: string[]) =>
            iif(
              () => storeIds.length > 1,
              of(null),
              this.menuStorage.getAllMenus({ storeIds, pageRequest: { page: 0, size: 1 } }).pipe(first()),
            ),
          ),
        )
        .subscribe((menusResponse: QueryResponse<'allMenusByStoreIdsPageable'> | null) => {
          // in case if there are more than 1 stores
          if (menusResponse === null) {
            return subscriber.next(true);
          }
          const menus = menusResponse?.data?.allMenusByStoreIdsPageable?.content! || [];
          if (menus && menus.length === 1 && menus[0]) {
            return this.menuService.toMenuPage(menus[0].id);
          }
          return subscriber.next(true);
        });
    });
  }
}
