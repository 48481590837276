<div class="nm-dialog-container" [attr.data-test-id]="testId">
  <div *ngIf="showToolbar" [ngClass]="{ 'header-center': headerPosition === 'center' }">
    <div *ngIf="icon" class="modal-base-icon-container">
      <nm-svg-icon [name]="icon" [size]="24"></nm-svg-icon>
    </div>
    <div class="modal-base-title-container">
      <ng-content select="[modal-base-title]"></ng-content>
    </div>
  </div>

  <div class="modal-base-content-container">
    <ng-content></ng-content>
  </div>

  <div class="btn-container modal-base-footer" [class.content-scroll]="isContentHaveScroll()">
    <ng-content select="[modal-base-before-cancel]"></ng-content>
    <nm-button *ngIf="showCancel" (clickButton)="cancel.emit()" [variant]="cancelVariant" testId="button-cancel">
      {{ cancelTitle }}
      <ng-content select="[modal-base-cancel-content]"></ng-content>
    </nm-button>
    <ng-content select="[modal-base-before-ok]"></ng-content>
    <nm-button
      *ngIf="showOk"
      (clickButton)="ok.emit()"
      [disabled]="okDisabled"
      [variant]="okVariant"
      [loading]="okLoading"
      testId="button-ok"
    >
      {{ okTitle }}
      <ng-content select="[modal-base-ok-content]"></ng-content>
    </nm-button>

    <div *ngIf="actionButtons.length" class="modal-base-actions">
      <nm-button
        *ngFor="let btn of actionButtons"
        (clickButton)="btn.onClick($event)"
        [disabled]="btn.disabled"
        [variant]="btn.variant || 'text'"
        [type]="btn.type"
        [testId]="btn.label"
        >{{ btn.label }}</nm-button
      >
    </div>
  </div>
  <ng-content select="[modal-base-after-ok]"></ng-content>
</div>
