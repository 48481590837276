import { SubscriptionClient } from "subscriptions-transport-ws";


// FIXME: delete this after graphql-ws client migrate to graphql-transport-ws
// We need this workaround as we have a 5 second reconnection on websocket with Spring on BE
// If don't use this, subscriptions will lose auth on reconnections
export class SubsClient extends SubscriptionClient {
  constructor(url, options) {
    super(url, options);
  }

  tryReconnect = function () {
    var self = this;
    if (!this.reconnect || this.backoff.attempts >= this.reconnectionAttempts) {
        return;
    }

    if (!this.reconnecting) {
      self.connectionParams().then((params) => {
        Object.keys(this.operations).forEach((key) => {
          const options = self.operations[key].options;
          options.variables = { ...options.variables, ...params };
          self.unsentMessagesQueue.push(self.buildMessage(key, 'start', options));
        })
      });

      this.reconnecting = true;
    }

    this.clearTryReconnectTimeout();

    const delay = this.backoff.duration();

    this.tryReconnectTimeoutId = setTimeout(function () {
      self.connect();
    }, delay);
  }
}
