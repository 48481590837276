import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ArrivalsService } from '@app/modules/warehouse/services';
import { ArrivalDoc } from '@typings';

@Component({
  selector: 'nm-delete-arrival-dialog',
  templateUrl: './delete-arrival-dialog.component.html',
})
export class DeleteArrivalDialogComponent {
  constructor(
    public arrivalsService: ArrivalsService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      arrival: ArrivalDoc;
      callbackFn?: () => void;
    },
  ) {}
}
