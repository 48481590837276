import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { PositionTypeCode, PositionTypeCodeIcon, PositionTypeCodeStrict, PositionTypeCodeString } from '@typings';

export const POSITION_TYPE_CODE: PositionTypeCodeStrict = {
  PRODUCT: 'PRODUCT',
  SERVICE: 'SERVICE',
  EXCISABLE_PRODUCT: 'EXCISABLE_PRODUCT',
  PRODUCT_MARKED: 'PRODUCT_MARKED',
};

export const POSITION_TYPE_CODE_STRING: PositionTypeCodeString = {
  PRODUCT: 'Товар',
  SERVICE: 'Услуга',
  EXCISABLE_PRODUCT: 'Подакцизный товар',
  PRODUCT_MARKED: 'Маркируемый товар',
};

export const POSITION_TYPE_CODE_TABLE_STRING: PositionTypeCodeString = {
  PRODUCT: 'Товар',
  SERVICE: 'Услуга',
  EXCISABLE_PRODUCT: 'Честный знак',
  PRODUCT_MARKED: 'Честный знак',
};

export const POSITION_TYPE_CODE_ICON: PositionTypeCodeIcon = {
  PRODUCT: 'storage',
  SERVICE: 'service',
  EXCISABLE_PRODUCT: 'trueMark',
  PRODUCT_MARKED: 'trueMark',
};

export const POSITION_TYPE_CODE_LIST: AutocompleteOption<PositionTypeCode>[] = Object.keys(POSITION_TYPE_CODE).map((code) => ({
  id: code,
  label: POSITION_TYPE_CODE_STRING[code],
  type: 'item',
  data: code,
  iconLeft: POSITION_TYPE_CODE_ICON[code],
}));
