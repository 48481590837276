import { Injectable } from '@angular/core';

import { NotifyService } from '@services/shared';
import {
  MutationOrderAddonArgs,
  MutationOrderRecurringBillArgs,
  MutationOrderServicePlanArgs,
  MutationPayOrderArgs,
  MutationResult,
  QueryAddonArgs,
  QueryAddonsArgs,
  QueryBillArgs,
  QueryBillsArgs,
  QueryResult,
  QueryServicePlanV2Args,
} from '@typings';

import { SubscriptionsApi } from './subscriptions.api';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsStorage {
  // TODO: update notify messages
  constructor(private subscriptionsApi: SubscriptionsApi, private notifyService: NotifyService) {}

  getSubscriber(): QueryResult<'subscriber'> {
    return this.subscriptionsApi.subscriber().pipe(
      this.notifyService.$notify({
        error: 'Ошибка',
      }),
    );
  }

  getServicePlan(variables: QueryServicePlanV2Args): QueryResult<'servicePlanV2'> {
    return this.subscriptionsApi.servicePlanV2(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка',
      }),
    );
  }

  getServicePlanList(): QueryResult<'servicePlansV2'> {
    return this.subscriptionsApi.servicePlansV2({ pageRequest: { page: 0, size: 1000 } }).pipe(
      this.notifyService.$notify({
        error: 'Ошибка',
      }),
    );
  }

  getServicePlanUsageSummary(): QueryResult<'servicePlanUsageSummary'> {
    return this.subscriptionsApi.servicePlanUsageSummary().pipe(
      this.notifyService.$notify({
        error: 'Ошибка',
      }),
    );
  }

  getAddon(variables: QueryAddonArgs): QueryResult<'addon'> {
    return this.subscriptionsApi.addon(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка',
      }),
    );
  }

  getAddonList(variables: QueryAddonsArgs): QueryResult<'addons'> {
    return this.subscriptionsApi.addons(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка',
      }),
    );
  }

  getBill(variables: QueryBillArgs): QueryResult<'bill'> {
    return this.subscriptionsApi.bill(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка',
      }),
    );
  }

  getBillList(variables: QueryBillsArgs): QueryResult<'bills'> {
    return this.subscriptionsApi.bills(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка',
      }),
    );
  }

  orderServicePlan(variables: MutationOrderServicePlanArgs): MutationResult<'orderServicePlan'> {
    return this.subscriptionsApi.orderServicePlan(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при формировании заказа',
        addErrorMessage: true,
      }),
    );
  }

  orderRecurringBill(variables: MutationOrderRecurringBillArgs): MutationResult<'orderRecurringBill'> {
    return this.subscriptionsApi.orderRecurringBill(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при формировании заказа',
        addErrorMessage: true,
      }),
    );
  }

  orderAddon(variables: MutationOrderAddonArgs): MutationResult<'orderAddon'> {
    return this.subscriptionsApi.orderAddon(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при формировании заказа',
        addErrorMessage: true,
      }),
    );
  }

  payOrder(variables: MutationPayOrderArgs): MutationResult<'payOrder'> {
    return this.subscriptionsApi.payOrder(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при оплате',
      }),
    );
  }
}
