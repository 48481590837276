import { gql } from 'apollo-angular';

export const mutationUpdateUIState = gql`
  mutation UpdateUIState($key: String!, $uiState: String) {
    updateUIState(key: $key, uiState: $uiState) {
      transactionId
      output
      result
      errorMessage
    }
  }
`;

export const queryGetUIStateTheme = gql`
  query GetUIState {
    getUIState(key: "theme")
  }
`;
