import { gql } from 'apollo-angular';

import { fragmentRequestResult } from './mutations';

export const fragmentStoreData = gql`
  fragment StoreData on Store {
    id
    name
    active
    catalogId
    ceo
    storeDomain {
      permanentSubdomain
      subdomain
    }
    organizationName
    shippingWarehouse {
      id
      name
    }
    currency
    zoneId
    actualAddress
    paySystems {
      id
      type
      name
      active
    }
    type
    bankAccount
    ogrn
    kpp
    vatin
    okpo
    postalCode
    legalAddress
    terminals {
      id
      name
      active
      position
      menuId
      serialNumber
    }
    position
    serialNumber
    schedules {
      fromTime
      toTime
      daysOfWeek
      scheduleType
    }
    bankName
    bic
    correspondentAccount
    phone
    signer
    signerBase
    registrationDate
    postAddressCoincidence
    tablesFeatureEnabled
    tablesBookingFeatureEnabled
    respectWorkingHoursForBooking
    workingScheduleEnabled
    geoLocation {
      latitude
      longitude
    }
  }
`;

export const queryStoreById = gql`
  query store($storeId: UUID!) {
    store(storeId: $storeId) {
      ...StoreData
    }
  }
  ${fragmentStoreData}
`;

export const queryStores = gql`
  query stores($filter: StoreFilterInputV2, $pageRequest: PageRequestInput!) {
    stores(filter: $filter, pageRequest: $pageRequest) {
      total
      pageNumber
      totalPages
      content {
        id
        name
        active
        catalogId
        organizationId
        shippingWarehouseId
        currency
        zoneId
        actualAddress
        shippingWarehouse {
          zoneId
          id
          name
        }
      }
    }
  }
`;

export const queryStoresShort = gql`
  query stores($filter: StoreFilterInputV2, $pageRequest: PageRequestInput!) {
    stores(filter: $filter, pageRequest: $pageRequest) {
      total
      totalPages
      content {
        id
        name
      }
    }
  }
`;

export const queryAllStoresForPaymentSystem = gql`
  query allStores {
    allStores {
      id
      name
      active
    }
  }
`;

export const queryAllStores = gql`
  query allStores {
    allStores {
      id
      name
      active
      catalogId
      organizationId
      shippingWarehouseId
      currency
      zoneId
      actualAddress
    }
  }
`;

export const queryAllStoresShort = gql`
  query allStores {
    allStores {
      id
      name
    }
  }
`;

export const queryStoresTotal = gql`
  query storesTotal($filter: StoreFilterInputV2, $pageRequest: PageRequestInput!) {
    stores(filter: $filter, pageRequest: $pageRequest) {
      total
    }
  }
`;

export const mutationUpdateStore = gql`
  mutation updateStore($input: StoreUpdateInput!) {
    updateStore(input: $input) {
      errorMessage
      output {
        ...StoreData
      }
      result
    }
  }
  ${fragmentStoreData}
`;

export const mutationCreateStoreV2 = gql`
  mutation createStoreV2($storeCreateInput: StoreCreateInput!) {
    createStoreV2(storeCreateInput: $storeCreateInput) {
      ...RequestResult
      output {
        ...StoreData
      }
    }
  }
  ${fragmentRequestResult}
  ${fragmentStoreData}
`;

export const mutationSaveStoreTableGroups = gql`
  mutation saveStoreTableGroups($storeTableGroupsInput: StoreTableGroupsInput!) {
    saveStoreTableGroups(storeTableGroupsInput: $storeTableGroupsInput) {
      transactionId
      output {
        tableGroups {
          id
          storeId
          name
          archived
          tables {
            id
            name
            seatsCount
            position
            x
            y
            height
            width
            radius
          }
        }
      }
      result
      errorMessage
    }
  }
`;

export const queryStoreTableGroup = gql`
  query tableGroup($id: UUID!) {
    tableGroup(id: $id) {
      id
      storeId
      name
      archived
      tables {
        id
        name
        seatsCount
        position
        x
        y
        height
        width
        radius
      }
    }
  }
`;

export const queryStoreTableGroups = gql`
  query tableGroups($storeId: UUID!, $pageRequest: PageRequestInput!) {
    tableGroups(storeId: $storeId, pageRequest: $pageRequest) {
      content {
        id
        storeId
        name
        archived
        tables {
          id
          name
          seatsCount
          position
          x
          y
          height
          width
          radius
        }
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
`;

export const queryStoreReceiptSettings = gql`
  query storeReceiptSettings($storeId: UUID!) {
    storeReceiptSettings(storeId: $storeId) {
      freeComment
      printFreeComment
      printOrderComment
      printStoreLogo
      printStoreName
      printWishes
      storeLogoImageId
      storeName
      wishes {
        text
      }
    }
  }
`;

export const mutationSaveStoreReceiptSettings = gql`
  mutation saveStoreReceiptSettings($settings: SaveStoreReceiptSettingsInput!) {
    saveStoreReceiptSettings(settings: $settings) {
      errorMessage
      result
      transactionId
      output {
        freeComment
        printFreeComment
        printOrderComment
        printStoreLogo
        printStoreName
        printWishes
        storeLogoImageId
        storeName
        wishes {
          text
        }
      }
    }
  }
`;
