import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OrganizationSite } from '@typings';

@Component({
  selector: 'nm-organization-item',
  templateUrl: './organization-item.component.html',
  styleUrls: ['./organization-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationItemComponent {
  @Input() organization: OrganizationSite;
  @Input() testId: string;

  get name() {
    if (this.organization?.name) {
      return this.organization?.name;
    }

    if (this.organization?.shortId) {
      return 'ID: ' + this.organization?.shortId;
    }

    return '';
  }
}
