// TODO: remove after Marketing release
// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { StoresApi } from '@services/settings';
import { CoreSchema } from '@typings';
@Injectable({
  providedIn: 'root',
})
export class StoreControlsStorage {
  #store = new BehaviorSubject<CoreSchema.Store | null>(null);
  store$ = this.#store.asObservable();

  #stores = new BehaviorSubject<CoreSchema.Store[]>([]);
  stores$ = this.#stores.asObservable();

  updateStores(value: CoreSchema.Store[]): void {
    this.#stores.next(value);
  }

  getStores(): CoreSchema.Store[] {
    return this.#stores.getValue();
  }

  updateStore(value: CoreSchema.Store): void {
    this.#store.next(value);
  }

  clearCurrentStore(): void {
    this.#store.next(null);
  }

  #activeStores = new BehaviorSubject<string[] | null>(null);
  activeStores = this.#activeStores.asObservable();

  #storeByRoute = new BehaviorSubject<string | null>(null);

  setActiveStore(value: string[]): void {
    this.#activeStores.next(value);
  }

  getActiveStoreId(): string[] {
    return this.#activeStores.getValue();
  }

  setStoreByRoute(value: string): void {
    this.setActiveStore([value]);
    this.#storeByRoute.next(value);
  }

  toggleActiveStoreIds(value: string[]): Observable<string[]> {
    let active: string[] = this.#activeStores.getValue();

    value.forEach((id) => {
      if (active.includes(id)) {
        active = [...active.filter((uid) => id !== uid)];
      } else {
        active.push(id);
      }
    });

    this.setActiveStore(active);
    return this.activeStores;
  }

  setAllStoresAsActive(): void {
    if (this.#stores.value) {
      this.setActiveStore(this.#stores.value.map(({ id }) => id));
    }
  }

  constructor(private storesApiService: StoresApi) {}

  async initAllStores() {
    return new Promise((resolve) => {
      this.storesApiService
        .getAllStores()
        .pipe(take(1))
        .subscribe((stores) => {
          this.updateStores(stores.data.allStores);
          resolve(stores);
        });
    });
  }
}
