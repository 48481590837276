import { Injectable } from '@angular/core';

import { mutationSaveStoreReceiptSettings, queryStoreReceiptSettings } from '@api';
import { ApiService } from '@core/service';
import {
  Mutation,
  MutationResult,
  MutationSaveStoreReceiptSettingsArgs,
  Query,
  QueryResult,
  QueryStoreReceiptSettingsArgs,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class ReceiptsApi {
  constructor(private apiService: ApiService) {}

  getStoreReceipts(variables: QueryStoreReceiptSettingsArgs): QueryResult<'storeReceiptSettings'> {
    return this.apiService.query<Query<'storeReceiptSettings'>, QueryStoreReceiptSettingsArgs>({
      query: queryStoreReceiptSettings,
      variables,
    });
  }

  updateStoreReceipts(variables: MutationSaveStoreReceiptSettingsArgs): MutationResult<'saveStoreReceiptSettings'> {
    return this.apiService.mutate<Mutation<'saveStoreReceiptSettings'>, MutationSaveStoreReceiptSettingsArgs>({
      mutation: mutationSaveStoreReceiptSettings,
      variables,
    });
  }
}
