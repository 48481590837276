import { Pipe, PipeTransform } from '@angular/core';

import { SelectLib } from '@nomia/nomia-components-lib';

import { CustomerGroup } from '@typings';

@Pipe({
  name: 'toCustomerGroups',
})
export class ToCustomerGroupsPipe implements PipeTransform {
  transform(customerGroups: CustomerGroup[]): SelectLib[] {
    return customerGroups?.map((customerGroup) => {
      const { id, name } = customerGroup;

      return {
        id,
        title: name,
      };
    });
  }
}
