import { Pipe, PipeTransform } from '@angular/core';

import { MenuSectionLib } from '@nomia/nomia-components-lib';

import { COLORS } from '@constants';
import { CatalogSection, CoreSchema } from '@typings';

@Pipe({
  name: 'toBusinessMenuSectionLib',
})
export class toBusinessMenuSectionLib implements PipeTransform {
  transform(section: CoreSchema.MenuSection | CatalogSection): MenuSectionLib {
    let menuSectionLib: MenuSectionLib | undefined = undefined;

    if ('name' in section) {
      menuSectionLib = {
        title: section.name,
      };

      if ('colorSection' in section) {
        menuSectionLib.color = COLORS[section.colorSection!];
      }
    }

    if (menuSectionLib) {
      return menuSectionLib;
    }

    throw new Error(`Unable to convert ${section} into MenuSectionLib`);
  }
}
