import { BehaviorSubject } from 'rxjs';

export class TableService {
  gridTemplateColumns$ = new BehaviorSubject<string>('auto');
  isResizing = new BehaviorSubject<boolean>(false);

  isMouseOver = new BehaviorSubject(false);
  isMouseOverIndex = new BehaviorSubject(0);

  constructor() {}
}
