import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { DropdownMenuModule } from '@app/shared/component/dropdown-menu/dropdown-menu.module';
import { InputSearchModule } from '@app/shared/component/input-search/input-search.module';
import { MenuModule } from '@app/shared/component/menu/menu.module';
import { MenuItemModule } from '@app/shared/component/menu-item/menu-item.module';
import { SkeletonTemplateTableModule } from '@app/shared/component/skeleton-templates/skeleton-template-table/skeleton-template-table.module';
import { EditTableCellService } from '@app/shared/component/table/edit-table-cell/edit-table-cell.service';
import { DateMaskModule } from '@app/shared/directive/date-mask/date-mask.module';
import { DragAndDropModule } from '@app/shared/directive/drag-and-drop/drag-and-drop.module';
import { InputNumberOnlyModule } from '@app/shared/directive/inputNumberOnly/inputNumberOnly.module';
import { ScrollVisibilityModule } from '@app/shared/directive/scroll-visibility/scroll-visibility.module';
import { TooltipModule } from '@app/shared/directive/tooltip/tooltip.module';
import { SharedPipesModule } from '@app/shared/pipe/pipes.module';
import { TableService } from '@app/shared/service/table.service';
import { LetModule } from '@ngrx/component';

import { CheckboxModule } from '../checkbox/checkbox.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';

import { DragHandlerComponent } from './drag-handler/drag-handler.component';
import { EditDateTableCellComponent } from './edit-table-cell/edit-date-table-cell/edit-date-table-cell.component';
import { EditSelectTableCellComponent } from './edit-table-cell/edit-select-table-cell/edit-select-table-cell.component';
import { EditTextTableCellComponent } from './edit-table-cell/edit-text-table-cell/edit-text-table-cell.component';
import { TableBodyComponent } from './table-body/table-body.component';
import { TableHeaderComponent } from './table-header/table-header.component';
import { TableRowComponent } from './table-row/table-row.component';
import { TableTextColumnComponent } from './table-text-column/table-text-column.component';
import { TableTotalRowsComponent } from './table-total-rows/table-total-rows.component';
import { ColumnDirective } from './column.directive';
import { TableColumnDirective } from './column-name.directive';
import { RowChildDirective } from './row-child.directive';
import { TableComponent } from './table.component';

@NgModule({
  declarations: [
    TableComponent,
    TableRowComponent,
    TableBodyComponent,
    TableHeaderComponent,
    ColumnDirective,
    TableTextColumnComponent,
    RowChildDirective,
    TableColumnDirective,
    DragHandlerComponent,
    TableTotalRowsComponent,
    EditTextTableCellComponent,
    EditSelectTableCellComponent,
    EditDateTableCellComponent,
  ],
  providers: [TableComponent, TableService, EditTableCellService],
  imports: [
    CommonModule,
    CheckboxModule,
    SvgIconModule,
    DragDropModule,
    ScrollVisibilityModule,
    SkeletonTemplateTableModule,
    TooltipModule,
    DragAndDropModule,
    FormsModule,
    InputNumberOnlyModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    DateMaskModule,
    DropdownMenuModule,
    MenuItemModule,
    MenuModule,
    SharedPipesModule,
    LetModule,
    InputSearchModule,
  ],
  exports: [
    TableComponent,
    TableRowComponent,
    TableBodyComponent,
    TableHeaderComponent,
    TableColumnDirective,
    EditTextTableCellComponent,
    EditDateTableCellComponent,
    EditSelectTableCellComponent,
  ],
})
export class TableModule {}
