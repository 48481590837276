import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BannerModule } from '@app/shared/component/banner/banner.module';
import { SvgIconModule } from '@app/shared/component/svg-icon/svg-icon.module';

import { BannerEmptySearchComponent } from './banner-empty-search.component';

@NgModule({
  declarations: [BannerEmptySearchComponent],
  imports: [CommonModule, SvgIconModule, BannerModule],
  exports: [BannerEmptySearchComponent],
})
export class BannerEmptySearchModule {}
