import { Injectable } from '@angular/core';

import { NotifyService } from '@services/shared';
import { CoreSchema, QueryLeftoversArgs, QueryResult } from '@typings';

import { LeftoversApi } from './leftovers.api';

@Injectable({
  providedIn: 'root',
})
export class LeftoversStorage {
  constructor(private notifyService: NotifyService, private leftoversApi: LeftoversApi) {}

  allWarehouseItemLeftoversPageble(variables: CoreSchema.QueryWarehouseItemLeftoversArgs): QueryResult<'warehouseItemLeftovers'> {
    return this.leftoversApi.warehouseItemLeftovers(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка остатков',
      }),
    );
  }

  leftovers(variables: QueryLeftoversArgs): QueryResult<'leftovers'> {
    return this.leftoversApi.leftovers(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка остатков',
      }),
    );
  }
}
