import { gql } from 'apollo-angular';

export const queryProductsReport = gql`
  query productsReport($input: ProductsReportInput!, $pageRequestInput: PageRequestInput, $sort: [ProductReportSort!]) {
    productsReport(input: $input) {
      total {
        revenue
        prime
        discount
        profit
        sales
      }
      rows(pageRequest: $pageRequestInput, sort: $sort) {
        content {
          productName
          productType
          productId
          categories {
            id
            name
          }
          sales
          revenue
          prime
          discount
          profit
          unitType
        }
        pageNumber
        pageSize
        total
        totalPages
      }
    }
  }
`;

export const queryProductsReportV2 = gql`
  query productsReportV2($input: ReportV2Input!, $pageRequestInput: PageRequestInput, $sort: [ProductReportSort!]) {
    productsReportV2(input: $input) {
      total {
        revenue
        prime
        discount
        profit
        sales
      }
      rows(pageRequest: $pageRequestInput, sort: $sort) {
        content {
          productName
          productType
          productId
          categories {
            id
            name
          }
          sales
          revenue
          prime
          discount
          profit
          unitType
        }
        pageNumber
        pageSize
        total
        totalPages
      }
    }
  }
`;

export const queryReportFilters = gql`
  query reportFilters($report: ReportName) {
    reportFilters(report: $report) {
      filters {
        field
        searchOperations
      }
    }
  }
`;

export const queryProductsReportTimeSeries = gql`
  query productsReportTimeSeries(
    $input: ProductsReportInput!
    $timeSeriesInput: TimeSeriesInput!
    $revenue: Boolean!
    $prime: Boolean!
    $profit: Boolean!
    $discount: Boolean!
  ) {
    productsReportTimeSeries(input: $input, timeSeriesInput: $timeSeriesInput) {
      elements {
        prime @include(if: $prime)
        profit @include(if: $profit)
        revenue @include(if: $revenue)
        discount @include(if: $discount)
        timestamp
      }
    }
  }
`;
