import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isEqual } from 'lodash';
import { BehaviorSubject, debounceTime, delay, distinctUntilChanged, shareReplay, skip, take, takeUntil, tap } from 'rxjs';

import { SelectInTableService } from '@app/shared/service/select-in-table.service';
import { TreeDataService } from '@app/shared/service/tree-data.service';
import { NavDrawerItem } from '@app/typings/navigation';
import { SVG_ICONS_TYPE } from '@constants/svg-icons';
import { DestroyService } from '@core/service/destroy.service';
import { SessionStorage } from '@services/api';
import { SidenavService } from '@services/shared';

import { BaseComponent } from '../base-component/base.component';
import { TableComponent } from '../table/table.component';
import { ColumnConfig, Row, TableConfig } from '../table/table.types';
import { TableRowService } from '../table/table-row/table-row.service';
import { TableSidebarLayoutService } from '../table-sidenav-layout/service/table-sidebar-layout.service';

import { SidebarMultiselectLayoutService } from './sidebar-multiselect-layout.service';

@Component({
  selector: 'nm-sidebar-multiselect-layout',
  templateUrl: './sidebar-multiselect-layout.component.html',
  styleUrls: ['./sidebar-multiselect-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class SidebarMultiselectLayoutComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('table', { read: TableComponent }) table: TableComponent;
  selectedRows: Row[] = [];
  isLoading = false;
  treeDataService: TreeDataService<Row>;
  rows: Row[];
  buttonLoading = false;

  tableConfig: TableConfig = {
    columnsConfig: [
      {
        name: 'name',
        title: 'Название',
        align: 'left',
        sortable: true,
        width: '90%',
      },

      {
        name: 'tools',
        title: '',
        sortable: false,
        width: '10%',
      },
    ],
    selectable: true,
    expandable: true,
  };

  cancelIcon: SVG_ICONS_TYPE = 'cancelFilter';
  createNew = false;
  searchText = '';
  rowsWithoutSort: Row[];
  initiallySelected: string[] = [];
  hint = 'При выборе категорий, в документ будут добавлены все позиции, относящиеся к ней';
  searchText$: BehaviorSubject<string> = new BehaviorSubject('');
  searchObservable$ = this.searchText$.pipe(debounceTime(300), distinctUntilChanged(), shareReplay(), takeUntil(this.destroy$));

  constructor(
    @Inject(DestroyService) private readonly destroy$: DestroyService,
    private cdRef: ChangeDetectorRef,
    private tableRowService: TableRowService,
    private sidebarMultiselectService: SidebarMultiselectLayoutService,
    private tableSidebarService: TableSidebarLayoutService,
    private sidenavService: SidenavService,
    private sessionStorage: SessionStorage,
    public selectService: SelectInTableService,
    private router: Router,
  ) {
    super();
    this.isLoading = true;
    setTimeout(() => {
      if (!this.tableSidebarService.tableSidenavOpen.getValue()) {
        this.sidenavService.setTransparentTopBar(false);
      }
    });
  }

  ngOnInit(): void {
    if (this.sidebarMultiselectService.options) {
      this.sidebarMultiselectService.options.pipe(skip(1), takeUntil(this.destroy$), debounceTime(100)).subscribe((options) => {
        const rows = this.filterRows(options);

        this.rows = [...rows];
        this.rowsWithoutSort = [...rows];

        if (!this.rows.length) {
          this.addEmptyState();
        }
        this.isLoading = false;
        this.cdRef.detectChanges();
      });
    } else if (!this.sidebarMultiselectService.options && !this.tableSidebarService.tableSidenavOpen.getValue()) {
      const currentUrl = this.router.url; // Get the current URL
      const baseUrl = currentUrl.split('/').slice(0, -1);

      this.router.navigate(baseUrl);
    }

    const barItem: NavDrawerItem = {
      title: this.sidebarMultiselectService.title,
      icon: this.cancelIcon,
    };

    if (!this.tableSidebarService.tableSidenavOpen.getValue()) {
      this.sidenavService.setTopBarItem(barItem);
    }

    this.selectService.tableInited
      .pipe(
        take(1),
        delay(100),
        tap(() => {
          const selectedItems = this.sessionStorage.getSelectedItems();
          this.initiallySelected = selectedItems;
          if (selectedItems.length > 0 && this.table) {
            this.table.selectRows(selectedItems);
          }
        }),
      )
      .subscribe();

    this.cdRef.detectChanges();

    this.selectService.selectedRow.pipe(takeUntil(this.destroy$)).subscribe((selectedRows) => {
      this.selectedRows = selectedRows;
    });

    this.sidenavService.backToPreviousRoute.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.sidebarMultiselectService.close([]);
    });
    this.tableRowService.rowClicked.pipe(takeUntil(this.destroy$)).subscribe((id) => {
      if (id !== 'empty') {
        this.table.selectRows([id]);
      }
    });
    if (!!this.sidebarMultiselectService.searchFn) {
      this.searchObservable$.subscribe((searchText) => {
        this.sidebarMultiselectService.searchFn(searchText);
      });
    }
    this.destroy$.subscribe(() => {
      this.selectService.setSelected([]);
    });
  }

  search(text: string) {
    this.searchText$.next(text);
  }

  cancelSelect() {
    this.table.onChangeSelection(false);
  }

  onSort(column: ColumnConfig) {
    if (column.sorting === 'ASC') {
      this.rows = [...this.rows.sort((a, b) => (a.data?.name as string).localeCompare(b.data?.name as string))];
    } else if (column.sorting === 'DESC') {
      this.rows = [...this.rows.sort((a, b) => (b.data?.name as string).localeCompare(a.data?.name as string))];
    } else {
      this.rowsWithoutSort;

      if (!this.rows.length) {
        this.addEmptyState();
      }
    }
  }

  addItems() {
    this.buttonLoading = true;
    let selected = this.selectService.getSelected();

    if (!selected.length) {
      this.sidebarMultiselectService.close(selected, true);
      return;
    }

    this.sidebarMultiselectService.close(selected);
  }

  selectionChanged() {
    const selectedItems = this.selectService
      .getSelected()
      .filter((row) => !row.isCategoryRow)
      .map((row) => row.rowDataId)
      .sort();

    return !isEqual(selectedItems, this.initiallySelected.sort());
  }

  addEmptyState() {
    this.tableConfig = {
      columnsConfig: [
        {
          name: 'name',
          title: 'Название',
          sortable: true,
          width: '100%',
        },
      ],
      selectable: true,
      expandable: true,
    };
    const row: Row = {
      rowDataId: 'empty',
      columnsData: [
        {
          column: 'name',
          data: {
            text: 'По запросу ничего не найдено',
            description: `Убедитесь, что все слова написаны правильно${this.createNew ? ', или создайте новый объект.' : ''}`,
          },
        },
      ],
    };
    this.rows = [row];
  }

  getSelectedLabel(): string {
    let products = 0;

    this.selectedRows.forEach((row) => {
      if (!row?.isCategoryRow) {
        products++;
      }
    });

    if (products === 1) {
      return `Выбрано: ${products} продукт`;
    } else if (products > 0 && products < 5) {
      return `Выбрано: ${products} продукта`;
    }

    return `Выбрано: ${products} продуктов`;
  }

  filterRows(rows: Row[]) {
    const filteredRows = rows.filter((row) => {
      return (
        !this.sidebarMultiselectService.excludedIds$.getValue().includes(row.rowDataId as string) &&
        (row.data?.name as string)?.toLocaleLowerCase().includes(this.searchText$.getValue().toLowerCase())
      );
    });

    return this.updateSelectedRows(filteredRows, this.selectedRows);
  }

  updateSelectedRows(rows: Row[], selectedRows: Row[]): Row[] {
    return rows.map((row) => {
      if (selectedRows.some((r) => r.rowDataId === row.rowDataId)) {
        row.selected = true;
      } else if (!selectedRows.some((r) => r.rowDataId === row.rowDataId) && row.selected === true) {
        row.selected = false;
      }

      if (row.children) {
        this.updateSelectedRows(row.children, selectedRows);
      }
      return row;
    });
  }
  ngOnDestroy(): void {
    this.sidenavService.setTransparentTopBar(true);
  }
}
