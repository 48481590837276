import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BaseLayoutModule } from '@app/layout/base-layout/base-layout.module';
import { ItemActionsModule } from '@app/modules/catalog/components/item-actions/item-actions.module';
import { TooltipModule } from '@app/shared/directive/tooltip/tooltip.module';
import { SharedModule } from '@app/shared/shared.module';

import { BannerEmptySearchModule } from '../banner-empty-search/banner-empty-search.module';
import { ButtonModule } from '../button/button.module';
import { ControlHintModule } from '../control-hint/control-hint.module';
import { FiltersTableModule } from '../filters-table/filters-table.module';
import { FooterSelectModule } from '../footer-select/footer-select.module';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { ListingModule } from '../listing/listing.module';
import { SectionModule } from '../section/section.module';
import { SkeletonTemplateTableModule } from '../skeleton-templates/skeleton-template-table/skeleton-template-table.module';
import { TableModule } from '../table/table.module';
import { TableTitleModule } from '../table-title/table-title.module';

import { SidebarMultiselectLayoutComponent } from './sidebar-multiselect-layout.component';
const routes: Routes = [{ path: '', component: SidebarMultiselectLayoutComponent, runGuardsAndResolvers: 'always' }];
@NgModule({
  declarations: [SidebarMultiselectLayoutComponent],
  imports: [
    CommonModule,
    FiltersTableModule,
    TableModule,
    IconButtonModule,
    ItemActionsModule,
    FooterSelectModule,
    SkeletonTemplateTableModule,
    BannerEmptySearchModule,
    TableTitleModule,
    SharedModule,
    BaseLayoutModule,
    SectionModule,
    ListingModule,
    ButtonModule,
    ControlHintModule,
    RouterModule.forChild(routes),
    TooltipModule,
  ],
})
export class SidebarMultiselectModule {}
