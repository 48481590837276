<nm-modal-base
  class="nm-menu-duplicate-dialog"
  okTitle="Дублировать"
  okVariant="filled"
  cancelTitle="Отменить"
  (cancel)="cancel()"
  (ok)="confirm()"
  testId="menu::menu-duplicate-modal"
>
  <div modal-base-title>{{ 'Дублирование меню' }}</div>
  <div class="content" modal-base-content-container>
    <ng-container *ngIf="menuService.duplicateForm as form">
      <span>Выберите магазин, в который хотите дублировать</span>
      <nm-form class="form" [form]="form">
        <nm-autocomplete
          #storeAutocomplete
          ngDefaultControl
          label="Магазин"
          placeholder="Выберите магазин"
          [searchFn]="searchStoresService.searchFn"
          [options]="searchStoresService.options$"
          [formControl]="storeIdsControl"
          [isMulti]="true"
          [search]="true"
          [required]="true"
          [error]="isStoreIdsError"
          [hint]="isStoreIdsError ? 'Обязательное поле' : ''"
          testId="stores"
          (menuOpen)="openStoreAutocomplete(storeAutocomplete)"
        ></nm-autocomplete>
      </nm-form>
    </ng-container>
  </div>
</nm-modal-base>
