import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DOC_STATUS, DOC_STATUS_STRING } from '@constants';
import { DocStatus } from '@typings';

@Component({
  selector: 'nm-document-status',
  templateUrl: './document-status.component.html',
  styleUrls: ['./document-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentStatusComponent {
  DOC_STATUS = DOC_STATUS;
  DOC_STATUS_STRING = DOC_STATUS_STRING;

  @Input() status: DocStatus;
}
