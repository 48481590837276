import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '@app/shared/component/button/button.module';
import { ControlHintModule } from '@app/shared/component/control-hint/control-hint.module';
import { ControlLabelModule } from '@app/shared/component/control-label/control-label.module';
import { DialogModule } from '@app/shared/component/dialog/dialog.module';
import { FileUrlDialogModule } from '@app/shared/component/file-url-dialog/file-url-dialog.module';
import { InputImageModule } from '@app/shared/component/input-image/input-image.module';
import { ProgressSpinnerModule } from '@app/shared/component/progress-spinner/progress-spinner.module';

import { InputFileComponent } from './input-file.component';

@NgModule({
  declarations: [InputFileComponent],
  imports: [
    CommonModule,
    ProgressSpinnerModule,
    DialogModule,
    InputImageModule,
    ButtonModule,
    ControlHintModule,
    ControlLabelModule,
    FileUrlDialogModule,
  ],
  exports: [InputFileComponent],
})
export class InputFileModule {}
