import { Injectable, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { uuid } from 'minifaker';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay, take } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { TableGroupEditDialogComponent } from '@app/modules/settings/pages/stores/store/tables/table-group-edit-dialog/table-group-edit-dialog.component';
import { PaySystemsStorage } from '@app/modules/settings/services';
import { ReceiptsStorage } from '@app/modules/settings/services/receipts/receipts.storage';
import { WarehouseStorage } from '@app/modules/warehouse/services';
import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { ModalRef, ModalService } from '@app/shared/component/dialog/abstract';
import { ModalBaseComponent } from '@app/shared/component/dialog/modal-base/modal-base.component';
import { Row } from '@app/shared/component/table/table.types';
import { InfiniteLoaderAutocompleteFactory } from '@app/shared/service/autocomplete/infinite-loader-autocomplete.service';
import { ImagesService } from '@app/shared/service/images.service';
import { InfiniteLoadResult } from '@app/shared/service/infinite-loader/infinite-loader.service';
import { phoneNumberValidator } from '@app/shared/validator/phoneNumberValidator';
import { DAYS, DEFAULT_TIMEZONE, EXACT_CHARACTERS, MAX_CHARACTERS, RootNavigationRoute, ROUTE_CREATE_NEW, SettingsRoute } from '@constants';
import { FormConfirmSaveService, ValidationErrorsService } from '@core/service';
import { SessionStorage } from '@services/api';
import { CatalogStorage } from '@services/catalog';
import { NotifyService, SearchStorage, SidenavService, TimezonesService } from '@services/shared';
import {
  AddressSearchDto,
  BankSearchResponse,
  CoreSchema,
  DayOfWeek,
  Image,
  MutationResult,
  MutationSaveStoreReceiptSettingsArgs,
  OrganizationSearchDto,
  PageRequestInput,
  PaymentSystemPage,
  QueryResult,
  QueryStoresArgs,
  ReceiptsForm,
  RequisiteForm,
  Schedule,
  ScheduleInput,
  StorageRoom,
  Store,
  StoreCreateInput,
  StoreForm,
  StorePage,
  StoreReceiptSettings,
  StoreUpdateInput,
  WeekDayNew,
  WeekDayPeriod,
} from '@typings';
import { createExactLengthValidator, getControlsWithErrorsOfType } from '@utils';

import { Table, TablesColumns, TablesColumnsNames, TablesGroup, TablesGroupForm } from '../../pages/stores/store/tables/tables.types';
import { TablesStorage } from '../tables/tables.storage';

import { StoresStorage } from './stores.storage';

@Injectable({
  providedIn: 'root',
})
export class StoresService {
  modalRef: ModalRef<unknown>;
  store: Store | null | undefined;
  form: FormGroup<StoreForm>;
  days: WeekDayNew[] = [];

  isEditing$ = new BehaviorSubject(false);
  isSubmitDisabled$ = new BehaviorSubject(false);

  requisitesForm: FormGroup<RequisiteForm>;
  tablesGroupForm: FormGroup<TablesGroupForm>;
  receiptsForm: FormGroup<ReceiptsForm>;
  tablesEditGroupForm: FormGroup<Partial<TablesGroupForm>>;
  tablesGroups$ = new BehaviorSubject<TablesGroup[]>([]);
  #refresh = new BehaviorSubject<boolean>(true);
  refresh$ = this.#refresh.asObservable();

  timeZones$: Observable<AutocompleteOption[]> = this.timezonesService.getTimezones().pipe(
    shareReplay(),
    map((timezones) =>
      timezones.map((tz) => ({
        id: tz.zoneId,
        label: tz.zoneId,
        type: 'item',
      })),
    ),
  );

  #searchWarehousesFn = (
    pageRequest: PageRequestInput,
    searchText?: string,
  ): Observable<InfiniteLoadResult<AutocompleteOption<StorageRoom>>> => {
    return this.warehouseStorage
      .storageRooms({
        pageRequest,
        filter: {
          search: searchText,
          states: ['NOT_ARCHIVED'],
        },
      })
      .pipe(
        map((res) => {
          const { content, total, totalPages } = res.data.storageRooms;
          const items: AutocompleteOption<StorageRoom>[] = content
            .filter((s): s is StorageRoom & { id: string; name: string } => !!s.id && !!s.name)
            .map((item) => ({
              id: item.id,
              type: 'item',
              label: item.name,
              data: item,
            }));
          return { items, total, totalPages };
        }),
      );
  };
  public searchWarehousesService = InfiniteLoaderAutocompleteFactory.getService<StorageRoom>(this.#searchWarehousesFn);

  constructor(
    private storesStorage: StoresStorage,
    private router: Router,
    private formConfirmSaveService: FormConfirmSaveService,
    private sessionStorage: SessionStorage,
    private modalService: ModalService,
    private fb: FormBuilder,
    private notifyService: NotifyService,
    private validationErrorsService: ValidationErrorsService,
    private catalogStorage: CatalogStorage,
    private sidenavService: SidenavService,
    private imagesService: ImagesService,
    private searchStorage: SearchStorage,
    private paySystemsStorage: PaySystemsStorage,
    private timezonesService: TimezonesService,
    private warehouseStorage: WarehouseStorage,
    private tablesStorage: TablesStorage,
    public receiptsStorage: ReceiptsStorage,
  ) {}

  refresh(): void {
    this.#refresh.next(true);
  }

  openStorePage(id?: string): Promise<boolean> {
    this.formConfirmSaveService.closeForm(false);
    const isStoreTable = this.router.url.split('/').includes(SettingsRoute.tablesAndBooking);
    const isStoreReceipt = this.router.url.split('/').includes(SettingsRoute.receipts);
    if (id) {
      if (isStoreReceipt) {
        return this.router.navigate([
          this.sessionStorage.getOrgId(),
          RootNavigationRoute.settings,
          SettingsRoute.receipts,
          id || ROUTE_CREATE_NEW,
          'receipts',
        ]);
      }

      if (isStoreTable) {
        return this.router.navigate([
          this.sessionStorage.getOrgId(),
          RootNavigationRoute.settings,
          SettingsRoute.tablesAndBooking,
          id || ROUTE_CREATE_NEW,
          'tables',
        ]);
      }
    }

    return this.router.navigate([
      this.sessionStorage.getOrgId(),
      RootNavigationRoute.settings,
      SettingsRoute.stores,
      id || ROUTE_CREATE_NEW,
    ]);
  }

  back(shouldConfirm: boolean = true): void {
    this.enableForm();
    this.sidenavService.back(shouldConfirm);
  }

  getTableGroupsRequest(): CoreSchema.QueryTableGroupsArgs {
    return {
      pageRequest: {
        page: 0,
        size: 1000,
      },
      storeId: this.store?.id || '',
    };
  }

  initForm(store: Store | null | undefined, shouldRegister: boolean = true): void {
    if (this.form) {
      this.form.reset();
    }
    this.store = store;
    this.days = cloneDeep(DAYS);
    this.tablesGroups$.next([]);

    this.initReceiptsForm();

    this.tablesGroupForm = this.fb.group<TablesGroupForm>({
      name: this.fb.nonNullable.control(null, [Validators.required, Validators.maxLength(150)]),
      quantity: this.fb.nonNullable.control(null, [Validators.required, Validators.max(100)]),
      defaultQuantity: this.fb.nonNullable.control(2, [Validators.required, Validators.max(100)]),
      showTables: this.fb.nonNullable.control(true, [Validators.required]),
    });

    this.tablesEditGroupForm = this.fb.group<Partial<TablesGroupForm>>({
      name: this.fb.nonNullable.control(null, [Validators.required, Validators.maxLength(150)]),
      showTables: this.fb.nonNullable.control(false, [Validators.required]),
    });

    this.form = this.fb.group<StoreForm>({
      name: this.fb.nonNullable.control(null, [Validators.required, Validators.maxLength(MAX_CHARACTERS.STORE_NAME)]),
      domain: this.fb.nonNullable.control(null, [Validators.maxLength(30), Validators.minLength(3)]),
      address: this.fb.nonNullable.control(null, [Validators.required, Validators.maxLength(MAX_CHARACTERS.ADDRESS)]),
      isPeriod: this.fb.nonNullable.control(false),
      warehouse: this.fb.nonNullable.control(null, [Validators.required]),
      timezone: this.fb.nonNullable.control(null, [Validators.required]),
      tablesFeatureEnabled: this.fb.control(this.store?.tablesFeatureEnabled || false),
      tablesBookingFeatureEnabled: this.fb.control(this.store?.tablesBookingFeatureEnabled || false),
      respectWorkingHoursForBooking: this.fb.control(this.store?.respectWorkingHoursForBooking || false),
    });

    const { controls } = this.form;

    if (this.store) {
      const { name, storeDomain, actualAddress, shippingWarehouse, zoneId, schedules } = this.store;

      let warehouseOption: AutocompleteOption<StorageRoom> | null = null;

      if (shippingWarehouse) {
        const { id: warehouseId, name: warehouseName } = shippingWarehouse as StorageRoom;

        if (warehouseId && warehouseName) {
          warehouseOption = {
            id: warehouseId,
            label: warehouseName,
            type: 'item',
            data: shippingWarehouse as StorageRoom,
          };
        }
      }

      const address: AutocompleteOption<AddressSearchDto> | null = actualAddress
        ? {
            id: actualAddress,
            label: actualAddress,
            type: 'item',
            data: {
              address: actualAddress,
              timezone: zoneId,
            },
          }
        : null;

      const zone: AutocompleteOption<string> | null = zoneId
        ? {
            id: zoneId,
            label: zoneId,
            type: 'item',
            data: zoneId,
          }
        : null;

      controls.name.setValue(name);
      controls.address.setValue(address);
      controls.isPeriod.setValue(Boolean(schedules?.length));
      controls.warehouse.setValue(warehouseOption);
      controls.timezone.setValue(zone);
      controls.domain.setValue(storeDomain.subdomain || storeDomain.permanentSubdomain);

      if (schedules && schedules.length) {
        this.days = this.days.map((day) => {
          let currentDay: WeekDayNew = day;
          const currentDailyPeriods: Schedule[] = schedules.filter((currentDailyPeriod) =>
            currentDailyPeriod.daysOfWeek ? currentDailyPeriod.daysOfWeek[0] === day.dayOfWeek : true,
          );

          if (!Boolean(currentDailyPeriods.length)) {
            currentDay = { ...day, isActive: false };
          }

          if (Boolean(currentDailyPeriods.length)) {
            const periods: WeekDayPeriod[] = currentDailyPeriods.map((currentDailyPeriod) => {
              const { fromTime, toTime } = currentDailyPeriod;

              return {
                minTime: fromTime ? fromTime : '00:00',
                isMinTimeError: false,
                minTimeError: '',
                maxTime: toTime ? toTime : '23:59',
                isMaxTimeError: false,
                maxTimeError: '',
                isDuplicateTime: false,
                duplicateTimeError: '',
              };
            });

            currentDay = { ...day, periods };
            this.isDuplicateTime(currentDay);
          }

          return currentDay;
        });
      }

      this.getStoreReceipts();
      this.getTableGroup();
    } else {
      const defaultTimezone: AutocompleteOption<string> = {
        id: DEFAULT_TIMEZONE,
        label: DEFAULT_TIMEZONE,
        type: 'item',
        data: DEFAULT_TIMEZONE,
      };

      controls.timezone.setValue(defaultTimezone);
    }

    this.initRequisitesForm();

    this.isEditing$.next(!!this.store);
    this.isSubmitDisabled$.next(false);

    if (shouldRegister) {
      this.formConfirmSaveService.setForm(this.form, undefined, () => this.resetForm());
    }
  }

  getStoreReceipts() {
    const variables = { storeId: this.store?.id || '' };
    this.receiptsStorage
      .getStoreReceipts(variables)
      .pipe(
        first(),
        tap((res) => {
          const imageId = res.data.storeReceiptSettings?.storeLogoImageId;
          if (imageId) {
            this.imagesService.imageById({ id: imageId }).subscribe((image) => {
              this.setStoreReceiptsSetting(res.data.storeReceiptSettings!, image);

              if (image && 'imageSizes' in image && image.imageSizes.length && image.imageSizes[0].url) {
                this.imagesService.setFile([image.imageSizes[0].url, image.originalFileName]);
              }
            });
          } else {
            this.setStoreReceiptsSetting(res.data.storeReceiptSettings!);
          }
        }),
      )
      .subscribe();
  }

  getTableGroup() {
    this.tablesGroups$.next([]);
    if (this.store?.id) {
      this.tablesStorage.tableGroups(this.getTableGroupsRequest()).subscribe((res) => {
        const currentTablesGroup = (res.data?.tableGroups?.content || []).map((g) => {
          const curGroup = this.newTableGroup(g);
          curGroup.name = g.name;
          curGroup.tables$ = new BehaviorSubject(
            (g.tables || [])
              ?.sort((a, b) => a.position - b.position)
              .map((t) => {
                return this.newTable(curGroup, t);
              }),
          );

          return curGroup;
        });
        this.tablesGroups$.next(currentTablesGroup);
      });
    }
  }

  newTableGroup(group?: CoreSchema.TableGroup): TablesGroup {
    const currentGroupId = group?.id || uuid.v4();
    const showTables = group ? !group?.archived : this.tablesGroupForm.controls?.showTables?.value || false;
    return {
      id: currentGroupId,
      name: group?.name || this.tablesGroupForm.controls?.name.value || '',
      quantity: this.tablesGroupForm.controls?.quantity.value || 0,
      defaultQuantity: this.tablesGroupForm.controls?.defaultQuantity.value || 0,
      tables$: new BehaviorSubject([] as Row<Table, TablesColumns>[]),
      showTables,
      expanded: true,
      groupMenus: [
        {
          iconLeft: 'edit',
          label: 'Редактировать',
          type: 'item',
          onClick: () => this.openEditModal(currentGroupId),
        },
        {
          iconLeft: 'delete',
          label: 'Удалить',
          type: 'item',
          onClick: () => this.removeGroup(currentGroupId),
        },
      ],
    };
  }

  removeGroup(groupId: string) {
    this.sidenavService.showConfirmDialogForcedOnNavigate = true;
    this.tablesGroups$.next((this.tablesGroups$.getValue() || []).filter((g) => g.id !== groupId));
  }

  openEditModal(groupId: string) {
    const currentGroup = this.tablesGroups$.getValue().find((g) => g.id === groupId);
    if (currentGroup) {
      this.tablesEditGroupForm.controls?.name?.setValue(currentGroup?.name || '');
      this.tablesEditGroupForm.controls?.showTables?.setValue(currentGroup?.showTables || false);
      this.modalRef = this.modalService.openDialog(TableGroupEditDialogComponent);
      this.modalRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((res) => {
          if (res) {
            this.changeGroup(
              groupId,
              this.tablesEditGroupForm.controls?.name?.value || '',
              this.tablesEditGroupForm.controls?.showTables?.value || false,
            );
          }
          this.tablesEditGroupForm.reset();
        });
    }
  }

  changeGroup(groupId: string, name: string, showTables: boolean) {
    this.tablesGroups$.next(
      this.tablesGroups$.getValue().map((g) => {
        if (g.id === groupId) {
          g.name = name;
          g.showTables = showTables;
        }
        return g;
      }),
    );
  }

  newTable(group: TablesGroup, table?: CoreSchema.Table): Row<Table, TablesColumns> {
    let newRowId = table?.id || uuid.v4();
    let tableName = table?.name || `${group.name}`;
    const tableNameControl = new FormControl(tableName);
    const placesControl = new FormControl(table?.seatsCount || group.defaultQuantity);

    return {
      rowDataId: newRowId,
      columnsData: [
        {
          column: TablesColumnsNames.tableName,
          data: {
            formControl: tableNameControl,
            tableName: group.name,
            table: table,
          },
        },
        {
          column: TablesColumnsNames.places,
          data: {
            formControl: placesControl,
          },
        },
        {
          column: 'actions',
          data: {
            tableId: String(newRowId),
          },
        },
      ],
    };
  }

  initReceiptsForm() {
    this.receiptsForm = this.fb.group<ReceiptsForm>({
      freeComment: this.fb.control(null),
      printFreeComment: this.fb.nonNullable.control(false),
      printOrderComment: this.fb.nonNullable.control(false),
      printStoreLogo: this.fb.nonNullable.control(false),
      printStoreName: this.fb.nonNullable.control(false),
      printWishes: this.fb.nonNullable.control(false),
      storeLogoImageId: this.fb.control(null),
      storeName: this.fb.control(this.store?.name || null, [Validators.maxLength(150)]),
      wishes: this.fb.nonNullable.array([this.fb.nonNullable.group({ text: '' })]),
      image: this.fb.control(null),
    });
  }

  setStoreReceiptsSetting(settings: StoreReceiptSettings, image?: Image) {
    this.receiptsForm.reset();
    const wishesFormArray = this.receiptsForm.controls.wishes;
    if (settings.wishes && settings.wishes.length) {
      wishesFormArray.removeAt(0);
      settings.wishes?.forEach((wish) => wishesFormArray.push(this.fb.nonNullable.group(wish)));
    }

    this.receiptsForm = this.fb.group<ReceiptsForm>({
      freeComment: this.fb.control(settings.freeComment || null),
      printFreeComment: this.fb.nonNullable.control(settings.printFreeComment),
      printOrderComment: this.fb.nonNullable.control(settings.printOrderComment),
      printStoreLogo: this.fb.nonNullable.control(settings.printStoreLogo),
      printStoreName: this.fb.nonNullable.control(settings.printStoreName),
      printWishes: this.fb.nonNullable.control(settings.printWishes),
      storeLogoImageId: this.fb.control(settings.storeLogoImageId || null),
      storeName: this.fb.control(settings.storeName || this.store?.name || null, [Validators.maxLength(150)]),
      wishes: wishesFormArray,
      image: this.fb.control(image || null),
    });
  }

  resetForm() {
    this.initForm(undefined, false);
  }

  hasRequiredErrors(): boolean {
    return (
      [this.form, this.requisitesForm]
        .map((form) => getControlsWithErrorsOfType(form, 'required').length)
        .reduce((acc, len) => acc + len, 0) > 0
    );
  }

  getStoreTableGroupsInput(storeId?: string): CoreSchema.MutationSaveStoreTableGroupsArgs {
    return {
      storeTableGroupsInput: {
        storeId: storeId || this.store?.id || '',
        tableGroups: (this.tablesGroups$.getValue() || []).map((g) => {
          return {
            archived: !g.showTables,
            id: g.id,
            name: g.name,
            tables: (g.tables$.getValue() || []).map((t, i) => {
              const nameColumn = t.columnsData.find((c) => c.column === TablesColumnsNames.tableName)?.data?.formControl;
              const placesColumn = t.columnsData.find((c) => c.column === TablesColumnsNames.places)?.data?.formControl;
              const table = t.columnsData.find((c) => c.column === TablesColumnsNames.tableName)?.data?.table;
              return {
                id: t.rowDataId,
                name: String(nameColumn ? nameColumn.value : ''),
                position: i,
                seatsCount: Number(placesColumn ? placesColumn.value : ''),
                x: table?.x || null,
                y: table?.y || null,
                height: table?.height || null,
                width: table?.width || null,
                radius: table?.radius || null,
              };
            }),
          } as CoreSchema.TableGroupInput;
        }),
      },
    };
  }

  async submitForm() {
    if (!this.isDaysCorrect()) {
      this.notifyService.addNotification({
        type: 'alert',
        title: 'Необходимо корректно заполнить расписание по определенным дням',
      });

      return;
    }
    if (this.form.invalid || this.requisitesForm.invalid) {
      this.validationErrorsService.markFormControls(this.form);
      this.validationErrorsService.markFormControls(this.requisitesForm);

      if (this.hasRequiredErrors()) {
        this.notifyService.addNotification({
          type: 'alert',
          title: 'Необходимо заполнить обязательные поля',
        });
      } else {
        this.notifyService.addNotification({
          type: 'alert',
          title: 'Некорректный ввод данных',
        });
      }

      return;
    }

    this.disableForm();

    const image = this.receiptsForm.controls.image;

    let imageId: string | null;
    if (image) {
      const imageValue = image.value;

      if (!imageValue) {
        imageId = null;
      }

      if (imageValue && 'imageSizes' in imageValue) {
        imageId = imageValue.id;
      }

      if (imageValue && 'body' in imageValue) {
        imageId = await this.imagesService.uploadImages([image.value]);
      }
    }

    const storeUpdateInput = this.getStoreUpdateInput();

    if (storeUpdateInput) {
      this.storesStorage.updateStore({ input: storeUpdateInput }).subscribe(
        () => {
          this.sidenavService.showConfirmDialogForcedOnNavigate = false;

          combineLatest([
            this.tablesStorage.updateTablesGroups(this.getStoreTableGroupsInput(storeUpdateInput.id)),
            this.receiptsStorage.updateStoreReceipts(this.getStoreReceiptSettings(imageId)),
          ]).subscribe((_) => this.back(false));
        },
        (error) => {
          if (!!error.message && error.message.includes('Store domain is not unique')) {
            this.notifyService.addNotification({
              type: 'alert',
              title: 'Введённый домен уже используется другим заведением. Попробуйте ввести другой',
            });
          }

          if (!!error.message && error.message.includes('The store domain cannot be in the format')) {
            this.notifyService.addNotification({
              type: 'alert',
              title: 'Название домена не должно содержать префикс "id", если числовые значения не соответствуют вашему постоянному домену.',
            });
          }

          if (this.requisitesForm && this.validationErrorsService.getValidationErrors()[0]) {
            this.validationErrorsService.setErrorsForFormControls(
              this.validationErrorsService.getValidationErrors()[0].errors,
              this.requisitesForm,
            );
          }

          this.enableForm();
          this.refresh();
        },
      );
    } else {
      const input = this.getStoreCreateInput();

      if (input) {
        this.createStore(input);
      }
    }
  }

  disableForm(): void {
    this.form.disable();
    this.isSubmitDisabled$.next(true);
  }

  enableForm(): void {
    this.form.enable();
    this.isSubmitDisabled$.next(false);
  }

  getStoreCreateInput(): StoreCreateInput | null {
    const { name, domain, address, warehouse, timezone, isPeriod } = this.form.controls;
    const orgId = this.sessionStorage.getOrgId();
    const catalogId = this.catalogStorage.getCatalog().id;

    if (!name.value || !address.value || !warehouse.value?.id || !timezone.value || !orgId || !catalogId) {
      return null;
    }

    const addressValue = typeof address.value === 'object' ? address.value.id : address.value;

    let storeCreateInput: StoreCreateInput = {
      organizationId: orgId,
      catalogId: catalogId,
      currency: this.sessionStorage.getCurrencyUnit(), // TODO which value we should use ?
      type: 'KAFE', // TODO which value we should use ?
      name: name.value,
      subdomain: domain.value || null,
      address: addressValue,
      shippingWarehouseId: warehouse.value.id,
      zoneId: timezone.value.id,
      schedules: [],
    };

    if (isPeriod.value) {
      const activeDays = this.days.filter((day) => day.isActive);
      const days: ScheduleInput[] = [];

      activeDays.forEach((day) => {
        const { dayOfWeek, periods } = day;

        periods.forEach((period) => {
          const { minTime, maxTime } = period;

          days.push({
            daysOfWeek: [dayOfWeek as DayOfWeek],
            fromTime: minTime,
            toTime: maxTime,
            scheduleType: 'WORKING_TIME',
          });
        });
      });

      storeCreateInput.schedules = days;
    }

    if (this.requisitesForm) {
      const {
        vatin,
        kpp,
        ogrn,
        okpo,
        legalAddress,
        postAddress,
        postalCode,
        phone,
        ceo,
        signerBase,
        bankAccount,
        bankName,
        bic,
        correspondentAccount,
        postAddressCoincidence,
        signer,
        registrationDate,
      } = this.requisitesForm.controls;

      storeCreateInput = {
        ...storeCreateInput,
        vatin: vatin.value,
        kpp: kpp.value,
        ogrn: ogrn.value,
        okpo: okpo.value,
        legalAddress: legalAddress.value,
        postAddress: postAddress.value,
        postalCode: postalCode.value,
        phone: phone.value,
        ceo: ceo.value,
        signerBase: signerBase.value,
        bankAccount: bankAccount.value,
        bankName: bankName.value,
        bic: bic.value,
        correspondentAccount: correspondentAccount.value,
        postAddressCoincidence: postAddressCoincidence.value,
        signer: signer.value,
        registrationDate: registrationDate.value,
      };
    }

    return storeCreateInput;
  }

  getStoreUpdateInput(): StoreUpdateInput | null {
    const { name, domain, address, warehouse, timezone, isPeriod } = this.form.controls;

    if (!this.store?.id) {
      return null;
    }

    const addressValue = typeof address.value === 'object' ? address.value?.id : address.value;

    const infoUpdate = {
      name: name.value,
      subdomain: domain.value || null,
      address: addressValue,
      shippingWarehouseId: warehouse.value?.id,
      zoneId: timezone.value?.id,
      id: this.store.id,
    };

    let schedules: ScheduleInput[] = [];

    if (isPeriod.value) {
      const activeDays = this.days.filter((day) => day.isActive);
      const days: ScheduleInput[] = [];

      activeDays.forEach((day, index) => {
        const { dayOfWeek, periods } = day;

        periods.forEach((period, periodIndex) => {
          const { minTime, maxTime } = period;
          const useNextDay = this.getUseNextDay(minTime, maxTime);

          if (useNextDay && periodIndex === periods.length - 1) {
            days.push({
              daysOfWeek: [dayOfWeek as DayOfWeek],
              fromTime: minTime,
              toTime: '23:59',
              scheduleType: 'WORKING_TIME',
            });

            days.push({
              daysOfWeek: [activeDays[index === 6 ? 0 : index + 1].dayOfWeek as DayOfWeek],
              fromTime: '00:00',
              toTime: maxTime,
              scheduleType: 'WORKING_TIME',
            });
          } else {
            days.push({
              daysOfWeek: [dayOfWeek as DayOfWeek],
              fromTime: minTime,
              toTime: maxTime,
              scheduleType: 'WORKING_TIME',
            });
          }
        });
      });

      schedules = days;
    }

    if (this.requisitesForm) {
      const {
        vatin,
        kpp,
        ogrn,
        okpo,
        legalAddress,
        postAddress,
        postalCode,
        phone,
        ceo,
        signerBase,
        bankAccount,
        bankName,
        bic,
        correspondentAccount,
        postAddressCoincidence,
        signer,
        registrationDate,
      } = this.requisitesForm.controls;

      return {
        ...infoUpdate,
        schedules,
        vatin: vatin.value,
        kpp: kpp.value,
        ogrn: ogrn.value,
        okpo: okpo.value,
        legalAddress: legalAddress.value,
        postAddress: postAddress.value,
        postalCode: postalCode.value,
        phone: phone.value,
        ceo: ceo.value,
        signerBase: signerBase.value,
        bankAccount: bankAccount.value,
        bankName: bankName.value,
        bic: bic.value,
        correspondentAccount: correspondentAccount.value,
        postAddressCoincidence: postAddressCoincidence.value,
        signer: signer.value,
        registrationDate: registrationDate.value,
        tablesFeatureEnabled: this.form.controls.tablesFeatureEnabled.value,
        tablesBookingFeatureEnabled: this.form.controls.tablesBookingFeatureEnabled.value,
        respectWorkingHoursForBooking: this.form.controls.respectWorkingHoursForBooking.value,
      };
    }

    return infoUpdate;
  }

  getUseNextDay(min: string, max: string) {
    const [minHour, minMinute] = min.split(':');
    const [maxHour, maxMinute] = max.split(':');
    return minHour > maxHour || (minHour === maxHour && minMinute > maxMinute);
  }

  showModal(modalTemplate: TemplateRef<ModalBaseComponent>): void {
    this.modalRef = this.modalService.openDialog(modalTemplate);
  }

  hideModal(): void {
    if (!this.modalRef) {
      return;
    }

    this.modalRef.close();
  }

  getAllStores(): Observable<Store[]> {
    return this.storesStorage.getAllStores().pipe(map((res) => res.data.allStores));
  }

  getAllStoresShort(): Observable<Store[]> {
    return this.storesStorage.getAllStoresShort().pipe(map((res) => res.data.allStores));
  }

  getStores(variables: QueryStoresArgs): QueryResult<'stores'> {
    return this.storesStorage.getStores(variables);
  }

  getStoresForSearch(searchText: string): Observable<Store[]> {
    return this.storesStorage
      .getStoresShort({
        pageRequest: {
          page: 0,
          size: 1000,
        },
        filter: {
          name: searchText,
        },
      })
      .pipe(map((res) => res.data.stores.content));
  }

  getStoresForInfinity(pageRequest: PageRequestInput, search?: string): Observable<StorePage> {
    return this.storesStorage.getStoresShort({ pageRequest, filter: { name: search } }).pipe(map((res) => res.data.stores));
  }

  getStoresTotalNumber(): Observable<number> {
    return this.storesStorage.getStoresTotalNumber({ pageRequest: { page: 0, size: 1 } }).pipe(map((res) => res.data.stores.total));
  }

  getStore(storeId: string): Observable<Store> {
    return this.storesStorage.getStore({ storeId }).pipe(map((res) => res.data.store));
  }

  async createStore(storeCreateInput: StoreCreateInput): Promise<void> {
    this.sidenavService.showConfirmDialogForcedOnNavigate = false;

    const image = this.receiptsForm.controls.image;

    let imageId: string | null;
    if (image) {
      const imageValue = image.value;

      if (!imageValue) {
        imageId = null;
      }

      if (imageValue && 'imageSizes' in imageValue) {
        imageId = imageValue.id;
      }

      if (imageValue && 'extension' in imageValue) {
        imageId = await this.imagesService.uploadImages([image.value]);
      }
    }

    // let imageId: string;
    // if (image && 'extension' in image) {
    //   imageId = await this.imagesService.uploadImages([image]);
    // }

    this.storesStorage.createStore({ storeCreateInput }).subscribe(
      (data) => {
        const id = data?.data?.createStoreV2?.output?.id || '';
        const storeReceiptSettings = this.getStoreReceiptSettings(imageId, id);

        combineLatest([
          this.tablesStorage.updateTablesGroups(this.getStoreTableGroupsInput(id)),
          this.receiptsStorage.updateStoreReceipts(storeReceiptSettings),
        ]).subscribe((_) => this.back(false));
      },
      () => {
        this.validationErrorsService.setErrorsForFormControls(
          this.validationErrorsService.getValidationErrors()[0].errors,
          this.requisitesForm,
        );
        this.enableForm();
        this.refresh();
      },
    );
  }

  initRequisitesForm(): void {
    this.requisitesForm = this.fb.group<RequisiteForm>({
      vatin: this.fb.control(null, [createExactLengthValidator(EXACT_CHARACTERS.VATIN)]),
      kpp: this.fb.control(null, [createExactLengthValidator(EXACT_CHARACTERS.KPP)]),
      ogrn: this.fb.control(null, [createExactLengthValidator(EXACT_CHARACTERS.OGRN)]),
      okpo: this.fb.control(null, [createExactLengthValidator(EXACT_CHARACTERS.OKPO)]),
      legalAddress: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.ADDRESS)]),
      postAddress: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.ADDRESS)]),
      postalCode: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.POSTAL_CODE)]),
      phone: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.PHONE), phoneNumberValidator()]),
      ceo: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.CEO)]),
      registrationDate: this.fb.control(null),
      signerBase: this.fb.nonNullable.control(null),
      signer: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.SIGNER)]),
      bankAccount: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.BANK_ACCOUNT)]),
      bankName: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.BANK_NAME)]),
      bic: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.BIC)]),
      correspondentAccount: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.CORRESPONDENT_ACCOUNT)]),
      postAddressCoincidence: this.fb.control(false),
    });

    if (this.store) {
      const {
        vatin,
        kpp,
        ogrn,
        okpo,
        legalAddress,
        postAddress,
        postalCode,
        phone,
        ceo,
        registrationDate,
        signerBase,
        signer,
        bankAccount,
        bankName,
        bic,
        correspondentAccount,
        postAddressCoincidence,
      } = this.requisitesForm.controls;

      vatin.setValue(this.store.vatin || null);
      kpp.setValue(this.store.kpp || null);
      ogrn.setValue(this.store.ogrn || null);
      okpo.setValue(this.store.okpo || null);
      legalAddress.setValue(this.store.legalAddress || null);
      postAddress.setValue(this.store.postAddress || null);
      postalCode.setValue(this.store.postalCode || null);
      phone.setValue(this.store.phone || null);
      ceo.setValue(this.store.ceo || null);
      registrationDate.setValue(this.store.registrationDate || null);
      signerBase.setValue(this.store.signerBase || null);
      signer.setValue(this.store.signer || null);
      bankAccount.setValue(this.store.bankAccount || null);
      bankName.setValue(this.store.bankName || null);
      bic.setValue(this.store.bic || null);
      correspondentAccount.setValue(this.store.correspondentAccount || null);
      postAddressCoincidence.setValue(this.store.postAddressCoincidence || false);
    }
  }

  searchOrganizationRequisites(search: string): Observable<OrganizationSearchDto[]> {
    return this.searchStorage.searchOrganizationRequisites({ request: search }).pipe(map((res) => (res as OrganizationSearchDto[]) || []));
  }

  searchAddressRequisites(search: string): Observable<AddressSearchDto[]> {
    return this.searchStorage.searchAddressRequisites({ request: search }).pipe(map((res) => (res as AddressSearchDto[]) || []));
  }

  searchBankRequisites(search: string): Observable<BankSearchResponse[]> {
    return this.searchStorage.searchBankRequisites({ request: search }).pipe(map((res) => (res as BankSearchResponse[]) || []));
  }

  getAllPaymentSystems(): Observable<PaymentSystemPage> {
    return this.paySystemsStorage.getPaymentSystemList({ pageRequest: { page: 0, size: 1000 } });
  }

  cancelModal() {
    this.modalRef.close(false);
  }

  okModal() {
    this.modalRef.close(true);
  }

  openPaymentSystemPage(paymentId?: string) {
    if (!this.store?.id) {
      return;
    }

    this.router.navigate([
      this.sessionStorage.getOrgId(),
      RootNavigationRoute.settings,
      SettingsRoute.paymentSystems,
      paymentId || ROUTE_CREATE_NEW,
    ]);
  }

  deletePaymentSystem(id: string): MutationResult<'deletePaymentSystem'> {
    return this.paySystemsStorage.deletePaymentSystem({ id });
  }

  isDuplicateTime(day: WeekDayNew): void {
    if (day.periods.length > 1) {
      day.periods.forEach((period, index) => {
        const isDuplicateTime = day.periods.some((p, i) => {
          if (i === index) {
            return false;
          }

          const maxHour: number = i > index ? Number(period.maxTime.substring(0, 2)) : Number(p.maxTime.substring(0, 2));
          const maxMinute: number = i > index ? Number(period.maxTime.substring(3, 5)) : Number(p.maxTime.substring(3, 5));

          const minHourS: number = i > index ? Number(p.minTime.substring(0, 2)) : Number(period.minTime.substring(0, 2));
          const minMinuteS: number = i > index ? Number(p.minTime.substring(3, 5)) : Number(period.minTime.substring(3, 5));

          return maxHour > minHourS || (maxHour === minHourS && maxMinute > minMinuteS);
        });

        period.duplicateTimeError = isDuplicateTime ? 'Времена пересекаются' : '';
        period.isDuplicateTime = isDuplicateTime;
      });
    } else {
      day.periods[0].duplicateTimeError = '';
      day.periods[0].isDuplicateTime = false;
    }
  }

  isDaysCorrect() {
    if (this.form.controls.isPeriod.value) {
      return !this.days.some((day) => {
        return day.periods.some((period) => period.isDuplicateTime || period.isMaxTimeError || period.isMinTimeError);
      });
    } else return true;
  }

  getStoreReceiptSettings(imageId: string | null, storeId?: string): MutationSaveStoreReceiptSettingsArgs {
    const controls = this.receiptsForm.controls;
    const wishes = controls.wishes.controls
      .filter((control) => control.controls.text.value)
      .map((control) => {
        const { text } = control.controls;
        return { text: text.value };
      });

    return {
      settings: {
        storeId: storeId || this.store?.id || '',
        settings: {
          freeComment: controls.freeComment.value || null,
          printFreeComment: controls.printFreeComment.value,
          printOrderComment: controls.printOrderComment.value,
          printStoreLogo: controls.printStoreLogo.value,
          printStoreName: controls.printStoreName.value,
          printWishes: controls.printWishes.value,
          storeLogoImageId: imageId,
          storeName: controls.storeName.value,
          wishes,
        },
      },
    };
  }

  addWish() {
    this.receiptsForm.controls.wishes.push(this.fb.nonNullable.group({ text: '' }));
  }

  deleteWish(index: number) {
    this.receiptsForm.controls.wishes.removeAt(index);
  }
}
