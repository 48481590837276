import {
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  SkipSelf,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { calculateDistanceFromLeft } from './drag-and-drop.helpers';
import { DragItemId, Drop } from './drag-and-drop.types';
import { DragAndDropHandlerDirective } from './drag-and-drop-handler.directive';
import { DragAndDropItemService } from './drag-and-drop-item.service';
import { DragAndDropListService } from './drag-and-drop-list.service';

@Directive({
  selector: '[nmDragAndDropItem]',
})
export class DragAndDropItemDirective implements AfterViewInit, OnDestroy {
  @HostBinding('draggable') draggable = true;

  @Output() lastDragEnterItem = new EventEmitter<DragAndDropItemDirective>();
  @Output() nmDragEnd = new EventEmitter<Drop<unknown>>();
  @Output() nmDragStart = new EventEmitter<Drop<unknown>>();
  @Output() nmDrop = new EventEmitter<Drop<unknown>>();
  @Output() nmDrag = new EventEmitter<Drop<unknown>>();

  @Input() placeholder: TemplateRef<unknown> | undefined;
  @Input() nmDragAndDropHorizontal = false;
  @Input() dropzoneElement: HTMLElement;
  @Input() set isDraggable(draggable: boolean) {
    this.draggable = draggable;
  }
  @Input() rowId: string;

  get isDraggable(): boolean {
    return this.draggable;
  }

  @Input() data: unknown;
  @Input() ghostTemplate: TemplateRef<unknown> | undefined;

  @ContentChild(DragAndDropHandlerDirective, { static: false }) handlerDirective: DragAndDropHandlerDirective;
  currentDropZone: HTMLElement;
  dragGhost: HTMLElement;
  placeholderOnTop = false;
  #id: DragItemId;
  onDestroy$ = new Subject<void>();

  set id(id: number) {
    this.#id = id;
  }

  get id(): number {
    return this.#id;
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private dndItemService: DragAndDropItemService,
    @SkipSelf() private dnDListService: DragAndDropListService,
  ) {
    this.dnDListService.items$.next([...this.dnDListService.items$.getValue(), this]);
    this.dnDListService.drop.pipe(takeUntil(this.onDestroy$)).subscribe((_) => {
      this.removePlaceholder();
      this.elementRef.nativeElement.classList.remove('row-dragover');
    });
  }

  @HostListener('drag', ['$event'])
  onDrag(event: DragEvent) {
    const targetElement = event.currentTarget as HTMLElement;
    this.nmDrag.emit({
      data: this.data,
      dropZone: targetElement.closest('.dropzone'),
      event,
    });
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    const targetElement = event.currentTarget as HTMLElement;
    this.nmDrop.emit({
      data: this.data,
      dropZone: targetElement.closest('.dropzone'),
      event,
      prevPosition: this.id,
      newPosition: this.id,
    });
  }

  @HostListener('dragstart', ['$event'])
  onDragStart(event: DragEvent) {
    if (!this.isDraggable) {
      return false;
    }
    const targetElement = event.currentTarget as HTMLElement;
    this.nmDragStart.emit({
      data: this.data,
      dropZone: targetElement.closest('.dropzone'),
      event,
      prevPosition: this.id,
      newPosition: this.id,
    });
    if (this.ghostTemplate) {
      const ghostViewRef = this.viewContainerRef.createEmbeddedView(this.ghostTemplate);
      const dragGhost = ghostViewRef.rootNodes[0] as HTMLElement;
      event?.dataTransfer?.setDragImage(dragGhost, 0, 0);
      document?.body?.appendChild(dragGhost);
      setTimeout(() => {
        dragGhost?.parentNode?.removeChild(dragGhost);
      }, 0);
    }

    setTimeout(() => {
      this.elementRef.nativeElement.classList.add('nm-drag-and-drop-hidden-row');
    }, 0);

    return true;
  }

  @HostListener('dragover', ['$event'])
  onDragEnter(event: DragEvent) {
    this.elementRef.nativeElement.classList.add('row-dragover');

    this.lastDragEnterItem.emit(this);

    let aboveElement = false;
    if (this.nmDragAndDropHorizontal) {
      const hoverDistanceFromLeft = calculateDistanceFromLeft(
        event,
        this.dropzoneElement || this.dnDListService?.list$?.getValue()?.dropZone?.nativeElement,
      );
      aboveElement = hoverDistanceFromLeft < this.elementRef.nativeElement.offsetWidth / 2;
    } else {
      const halfHeight = this.elementRef.nativeElement.offsetHeight / 2;
      const halfPosition = this.elementRef.nativeElement.getBoundingClientRect().y + halfHeight;

      const isTopHalf = event.clientY < halfPosition;
      aboveElement = isTopHalf;
    }
    if (this.placeholderOnTop !== aboveElement) {
      this.placeholderOnTop = aboveElement;
    }

    if (this.placeholder && this.dnDListService.list$.getValue()?.isDragged) {
      this.renderTemplate(aboveElement);
    }
  }
  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    const relatedTarget = document.elementFromPoint(event.clientX, event.clientY);
    if (
      !relatedTarget?.classList.contains('pointer') ||
      (relatedTarget?.classList.contains('drop-inside') && !this.isEventFromChild(event))
    ) {
      this.removePlaceholder();
    }
  }

  @HostListener('dragend', ['$event'])
  onDragEnd(event: DragEvent) {
    setTimeout(() => {
      this.elementRef.nativeElement.classList.remove('nm-drag-and-drop-hidden-row');
    }, 0);

    const targetElement = event.currentTarget as HTMLElement;

    this.nmDragEnd.emit({
      data: this.data,
      dropZone: targetElement.closest('.dropzone'),
      event,
      prevPosition: this.id,
      newPosition: this.id,
    });
    if (this.dragGhost?.parentNode) {
      this.dragGhost.parentNode.removeChild(this.dragGhost);
    }
  }

  ngAfterViewInit(): void {
    if (this.handlerDirective && this.isDraggable) {
      this.handlerDirective.hovered$?.subscribe((hovered) => {
        this.draggable = hovered;
      });
    }
  }

  private renderTemplate(showPlaceholderAboveElement = false) {
    if (showPlaceholderAboveElement) {
      this.elementRef.nativeElement.classList.remove('add-to-bottom');
      this.elementRef.nativeElement.classList.add('add-to-top');
    } else {
      this.elementRef.nativeElement.classList.remove('add-to-top');
      this.elementRef.nativeElement.classList.add('add-to-bottom');
    }
  }
  private isEventFromChild(e: DragEvent): boolean {
    const relatedTarget = document.elementFromPoint(e.clientX, e.clientY);

    return (e.currentTarget as HTMLElement).contains(relatedTarget);
  }
  removePlaceholder() {
    this.elementRef.nativeElement.classList.remove('add-to-top');
    this.elementRef.nativeElement.classList.remove('add-to-bottom');
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
