import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { createMask } from '@ngneat/input-mask';

@Component({
  selector: 'nm-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimepickerComponent {
  // TODO: need refactor as ControlValueAccessor
  @Input() customControl: FormControl;
  @Input() label: string = '';
  @Input() hint: string = '';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() error: boolean = false;
  @Input() testId: string;

  timeMask = createMask({
    alias: 'datetime',
    inputFormat: 'HH:MM',
    placeholder: 'ЧЧ:ММ',
    showMaskOnHover: false,
  });
}

export function timeFieldValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isMatch = /[А-яЁё A-Za-z]/.test(control.value);
    return isMatch ? { timeFieldNotMatch: { value: control.value } } : null;
  };
}
