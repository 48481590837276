<div class="dropdown-label" #labelEl (click)="openOverlay($event)">
  <ng-content></ng-content>
</div>

<ng-container #container></ng-container>
<ng-template #overlayContent>
  <div class="dropdown-menu-panel">
    <ng-container [ngTemplateOutlet]="currentView$ | async"></ng-container>
  </div>
</ng-template>
