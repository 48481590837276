import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { INVITATION_TOKEN_KEY } from '@constants';
import { NotifyService } from '@services/shared';
import { CoreSchema, InvitationJWT, MutationResult } from '@typings';
import { jwtDecode } from '@utils';

import { InvitationApiService } from './invitation.api';

@Injectable({
  providedIn: 'root',
})
export class InvitationStorage {
  #invitation = new BehaviorSubject<CoreSchema.Invitation | null>(null);
  invitation = this.#invitation.asObservable();

  #hasError = new BehaviorSubject<boolean>(false);
  hasError = this.#hasError.asObservable();

  constructor(private invitationApi: InvitationApiService, private notifyService: NotifyService) {}

  getInvitationId(): string | undefined {
    const invitation = this.getInvitation();

    if (!invitation) {
      return undefined;
    }

    const { sub } = jwtDecode<InvitationJWT>(invitation);

    return sub;
  }

  getInvitation(): string | null {
    return localStorage.getItem(INVITATION_TOKEN_KEY) || null;
  }

  setInvitation(invitation: string): void {
    localStorage.setItem(INVITATION_TOKEN_KEY, invitation);
  }

  removeInvitation(): void {
    localStorage.removeItem(INVITATION_TOKEN_KEY);

    this.#invitation.next(null);
  }

  acceptInvitation(): MutationResult<'acceptEmployeeInvite'> {
    const invitation = localStorage.getItem(INVITATION_TOKEN_KEY) || '';

    return this.invitationApi.acceptEmployeeInvitation({
      token: invitation,
    });
  }

  getInvitationInfo(id: string) {
    this.invitationApi.getInvitationInfo({ inviteId: id }).subscribe((res) => {
      const { data, networkStatus } = res;

      // TODO: REMOVE AFTER ng-erp#790
      if (!data && networkStatus === 7) {
        this.#hasError.next(true);

        this.notifyService.addNotification({
          type: 'alert',
          title: 'Ошибка получения данных приглашения',
        });

        this.removeInvitation();
      } else if (data) {
        this.#hasError.next(false);
        this.#invitation.next(data.getInvitation);
      }
    });
  }

  createInvitation(id: string, sendTo?: string) {
    return this.invitationApi.createEmployeeInvitation({ employeeId: id, sendTo });
  }
}
