<section class="listing">
  <!-- fix for ERPF-1885. Cant solve this different way -->
  <ng-container [style.display]="isEmpty ? 'none' : 'block'">
    <nm-listing-header>
      <ng-content select="[header]"></ng-content>
    </nm-listing-header>

    <nm-listing-content [attr.data-scroll-wrapper]="scrollWrapperAttr">
      <ng-content select="[main]"></ng-content>
    </nm-listing-content>

    <nm-listing-footer *ngIf="isFooter" [ngClass]="{ 'select-footer': isSelect }">
      <ng-content select="[footer]"></ng-content>
    </nm-listing-footer>
  </ng-container>

  <ng-container *ngIf="isEmpty">
    <ng-content select="[empty]"></ng-content>
  </ng-container>
</section>
