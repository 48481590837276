import { Injectable } from '@angular/core';

import {
  mutationCreateProductV2,
  mutationCreateProductV2WithStockUnit,
  mutationDeleteProductV2,
  mutationDuplicateProduct,
  mutationMoveProduct,
  mutationUpdateProduct,
  queryAllCatalogProductsPageable,
  queryGetWhereUsedPageableV2,
  queryProduct,
  queryProductArrivalsExist,
  queryProducts,
  queryProductShort,
  queryProductsShort,
  queryStockUnits,
} from '@api';
import { ApiService } from '@core/service';
import {
  Mutation,
  MutationBatchResult,
  MutationCreateProductArgs,
  MutationDeleteProductArgs,
  MutationDuplicateProductArgs,
  MutationMoveProductArgs,
  MutationResult,
  MutationUpdateProductV3Args,
  Query,
  QueryAllCatalogProductsPageableArgs,
  QueryGetWhereUsedPageableArgs,
  QueryProductArgs,
  QueryProductsArgs,
  QueryResult,
  QueryStockUnitsArgs,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class ProductApi {
  constructor(private apiService: ApiService) {}

  createProductV2(variables: MutationCreateProductArgs): MutationResult<'createProductV2'> {
    return this.apiService.mutate<Mutation<'createProductV2'>, MutationCreateProductArgs>({
      mutation: mutationCreateProductV2,
      variables,
    });
  }

  createProductV2WithStockUnit(variables: MutationCreateProductArgs): MutationResult<'createProductV2'> {
    return this.apiService.mutate<Mutation<'createProductV2'>, MutationCreateProductArgs>({
      mutation: mutationCreateProductV2WithStockUnit,
      variables,
    });
  }

  updateProduct(variables: MutationUpdateProductV3Args): MutationResult<'updateProductV3'> {
    return this.apiService.mutate<Mutation<'updateProductV3'>, MutationUpdateProductV3Args>({
      mutation: mutationUpdateProduct,
      variables,
    });
  }

  updateProductBatch(variables: MutationUpdateProductV3Args[]): MutationBatchResult<'updateProductV3'> {
    return this.apiService.mutateBatch<Mutation<'updateProductV3'>, Record<string, string>[]>({
      mutation: mutationUpdateProduct,
      variables,
    });
  }

  duplicateProduct(variables: MutationDuplicateProductArgs): MutationResult<'duplicateProduct'> {
    return this.apiService.mutate<Mutation<'duplicateProduct'>, MutationDuplicateProductArgs>({
      mutation: mutationDuplicateProduct,
      variables,
    });
  }

  moveProduct(variables: MutationMoveProductArgs): MutationResult<'moveProduct'> {
    return this.apiService.mutate<Mutation<'moveProduct'>, MutationMoveProductArgs>({
      mutation: mutationMoveProduct,
      variables,
    });
  }

  moveProductBatch(variables: MutationMoveProductArgs[]): MutationBatchResult<'moveProduct'> {
    return this.apiService.mutateBatch<Mutation<'moveProduct'>, { targetProductId: string; newSectionId: string }[]>({
      mutation: mutationMoveProduct,
      variables,
    });
  }

  deleteProduct(variables: MutationDeleteProductArgs): MutationResult<'deleteProduct'> {
    return this.apiService.mutate<Mutation<'deleteProduct'>, MutationDeleteProductArgs>({
      mutation: mutationDeleteProductV2,
      variables,
    });
  }

  getProductArrivals(variables: QueryProductArgs): QueryResult<'productArrivalsExist'> {
    return this.apiService.query<Query<'productArrivalsExist'>, {}>({
      query: queryProductArrivalsExist,
      variables,
    });
  }

  getProduct(variables: QueryProductArgs): QueryResult<'product'> {
    return this.apiService.query<Query<'product'>, {}>({
      query: queryProduct,
      variables,
    });
  }

  getProductShort(variables: QueryProductArgs): QueryResult<'product'> {
    return this.apiService.query<Query<'product'>, {}>({
      query: queryProductShort,
      variables,
    });
  }

  getWhereUsedPageable(variables: QueryGetWhereUsedPageableArgs): QueryResult<'getWhereUsedPageable'> {
    return this.apiService.query<Query<'getWhereUsedPageable'>, QueryGetWhereUsedPageableArgs>({
      query: queryGetWhereUsedPageableV2,
      variables,
    });
  }

  getAllProductsPageable(variables: QueryAllCatalogProductsPageableArgs): QueryResult<'allCatalogProductsPageable'> {
    return this.apiService.query<Query<'allCatalogProductsPageable'>, QueryAllCatalogProductsPageableArgs>({
      query: queryAllCatalogProductsPageable,
      variables,
    });
  }

  products(variables?: QueryProductsArgs): QueryResult<'products'> {
    return this.apiService.query<Query<'products'>, QueryProductsArgs>({
      query: queryProducts,
      variables,
    });
  }

  productsShort(variables?: QueryProductsArgs): QueryResult<'products'> {
    return this.apiService.query<Query<'products'>, QueryProductsArgs>({
      query: queryProductsShort,
      variables,
    });
  }

  deleteProducts(variables: { id: string }[]): MutationBatchResult<'deleteProduct'> {
    return this.apiService.mutateBatch<Mutation<'deleteProduct'>, { id: string }[]>({
      mutation: mutationDeleteProductV2,
      variables,
    });
  }

  stockUnits(variables?: QueryStockUnitsArgs): QueryResult<'stockUnits'> {
    return this.apiService.query<Query<'stockUnits'>, QueryStockUnitsArgs>({
      query: queryStockUnits,
      variables,
    });
  }
}
