import { gql } from 'apollo-angular';

import { fragmentRequestResult } from './mutations';

export const fragmentCatalogTax = gql`
  fragment CatalogTax on Catalog {
    positionType {
      entityId
      entityType
      code
    }
    snoValue {
      entityId
      entityType
      sno
    }
    vatValue {
      entityId
      entityType
      vat
    }
  }
`;

export const fragmentCatalog = gql`
  fragment Catalog on Catalog {
    id
    name
    ...CatalogTax
  }
  ${fragmentCatalogTax}
`;

export const fragmentCatalogResult = gql`
  fragment CatalogResult on CatalogResult {
    ...RequestResult
    output {
      ...Catalog
    }
  }
  ${fragmentRequestResult}
  ${fragmentCatalog}
`;

export const mutationCreateCatalogV2 = gql`
  mutation createCatalogV2($catalog: CatalogCreateInput!) {
    createCatalogV2(catalog: $catalog) {
      ...CatalogResult
    }
  }
  ${fragmentCatalogResult}
`;

export const mutationUpdateCatalogV2 = gql`
  mutation updateCatalogV2($catalog: CatalogUpdateInput!) {
    updateCatalogV2(catalog: $catalog) {
      ...CatalogResult
    }
  }
  ${fragmentCatalogResult}
`;

export const queryCatalogByOrganization = gql`
  query catalogByOrganization {
    catalogByOrganization {
      ...Catalog
    }
  }
  ${fragmentCatalog}
`;
export const queryFullCatalog = gql`
  query fullCatalog($request: FullCatalogRequest!) {
    fullCatalog(request: $request) {
      catalog {
        products {
          name
          parentCategoryId
          skuId
          type
        }
        categories {
          depthLvl
          id
          name
          parentCategoryId
        }
      }
    }
  }
`;

export const queryGetCatalogSectionChildrenIds = gql`
  query catalogSectionChildren($numberOfLevels: Int, $sectionId: UUID!) {
    catalogSectionChildren(numberOfLevels: $numberOfLevels, sectionId: $sectionId) {
      id
    }
  }
`;

export const queryGetCatalogProductsShort = gql`
  query allInCatalogPageable($filter: InCatalogFilterInput!, $pageRequest: PageRequestInput) {
    allInCatalogPageable(filter: $filter, pageRequest: $pageRequest) {
      total
      totalPages
      content {
        ... on Product {
          id
          name
          type
          stockUnits {
            id
            name
            unitTypesMatchList
          }
        }
      }
    }
  }
`;

export const queryCatalogSectionParents = gql`
  query catalogSectionParents($sectionId: UUID!) {
    catalogSectionParents(sectionId: $sectionId) {
      id
      name
      depthLevel
      isParent
      parent {
        id
        name
      }
      productsCount
      colorSection
      positionType {
        entityId
        entityType
        code
      }
      snoValue {
        entityId
        entityType
        sno
      }
      vatValue {
        entityId
        entityType
        vat
      }
    }
  }
`;

export const mutationDeleteCatalogSection = gql`
  mutation deleteCatalogSection($id: UUID!) {
    deleteCatalogSection(id: $id) {
      entityId
      result
      errorMessage
    }
  }
`;
