import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'nm-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGroupComponent {
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() isAction: boolean = false;
  @Input() isOpen: boolean = false;
  @Input() testId: string;

  @Output() setIsOpen = new EventEmitter<boolean>();

  get gridRowGap(): string {
    return this.description ? '24px' : '16px';
  }

  handleToggleOpen() {
    this.isOpen = !this.isOpen;

    this.setIsOpen.emit(this.isOpen);
  }
}
