import { gql } from 'apollo-angular';

export const queryAllSubscriptionsPayments = gql`
  query allSubscriptionsPayments($filter: PaymoPaymentFilterInput!, $pageRequest: PageRequestInput!) {
    allSubscriptionsPayments(filter: $filter, pageRequest: $pageRequest) {
      content {
        id
        paymentDate
        amount {
          amountValue
          currencyUnit
        }
        status
        description
        paymentNumber
        lifeTime
        url
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
`;

export const queryAllUserPaymentCards = gql`
  query allUserPaymentCards {
    allUserPaymentCards {
      id
      pan
      defaultCard
      type
    }
  }
`;

export const queryCountOfSubscritionsPayments = gql`
  query countOfSubscritionsPayments($storeId: UUID!) {
    countOfSubscritionsPayments(storeId: $storeId)
  }
`;

export const mutationAddUserPaymentCard = gql`
  mutation addUserPaymentCard($successRedirectUrl: String!, $failRedirectUrl: String!) {
    addUserPaymentCard(successRedirectUrl: $successRedirectUrl, failRedirectUrl: $failRedirectUrl) {
      id
      paymentDate
      description
      paymentNumber
      lifeTime
      url
      status
      amount {
        amountValue
        currencyUnit
      }
    }
  }
`;

export const mutationDeleteUserPaymentCard = gql`
  mutation deleteUserPaymentCard($cardId: UUID!) {
    deleteUserPaymentCard(cardId: $cardId) {
      entityId
      errorMessage
      result
    }
  }
`;

export const mutationSetUserPaymentCardAsDefault = gql`
  mutation setUserPaymentCardAsDefault($cardId: UUID!) {
    setUserPaymentCardAsDefault(cardId: $cardId) {
      entityId
      errorMessage
      result
    }
  }
`;
