import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

import { IconButtonModule } from '@app/shared/component/icon-button/icon-button.module';
import { MenuModule } from '@app/shared/component/menu/menu.module';

import { ItemActionsComponent } from './item-actions.component';

@NgModule({
  declarations: [ItemActionsComponent],
  imports: [CommonModule, IconButtonModule, MatMenuModule, MenuModule],
  exports: [ItemActionsComponent],
})
export class ItemActionsModule {}
