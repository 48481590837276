import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AutocompleteModule } from '@app/shared/component/autocomplete/autocomplete.module';
import { ModalBaseModule } from '@app/shared/component/dialog/modal-base/modal-base.module';
import { FormModule } from '@app/shared/component/form/form.module';

import { WarehouseArchiveDialogComponent } from './warehouse-archive-dialog.component';

@NgModule({
  declarations: [WarehouseArchiveDialogComponent],
  imports: [CommonModule, ModalBaseModule, FormModule, ReactiveFormsModule, AutocompleteModule],
  exports: [WarehouseArchiveDialogComponent],
})
export class WarehouseArchiveDialogModule {}
