import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';

import { VALIDATION_ERROR_TYPE } from '@constants';
import { filterFormControls } from '@utils';

export function createPhoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    if (!isPossiblePhoneNumber(value, 'RU') || !isValidPhoneNumber(value, 'RU')) {
      return { phone: 'Введен некорректный номер телефона' };
    }

    return null;
  };
}

// 1 => 0.1, 3 => 0.001, 5 => 0.00001 etc.
export function getMinValueByFractionalDigitsNumber(maxFractional: number): number {
  const maxFloor = Math.floor(Number(maxFractional));

  if (isNaN(maxFloor) || maxFloor <= 0) {
    return 0;
  }

  return Math.pow(10, -maxFloor);
}

export function createExactLengthValidator(exactLength: number | readonly number[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const requiredLength = Array.isArray(exactLength) ? exactLength : [exactLength];
    const valueLength = String(value).length;

    if (!requiredLength.includes(valueLength)) {
      return {
        [VALIDATION_ERROR_TYPE.exactLength]: {
          requiredLength: requiredLength,
          actualLength: valueLength,
        },
      };
    }

    return null;
  };
}

export function getControlsWithErrorsOfType(form: FormGroup, errorName: string): AbstractControl[] {
  const filterFn = (control: FormControl) => !!Object.keys(control.errors || {}).find((e) => e === errorName);

  return filterFormControls(form, filterFn);
}
