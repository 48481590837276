import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

import { TooltipModule } from '@app/shared/directive/tooltip/tooltip.module';
import { LetModule } from '@ngrx/component';

import { ButtonModule } from '../button/button.module';
import { ChipModule } from '../chip/chip.module';
import { ControlPanelModule } from '../control-panel/control-panel.module';
import { FilterLabelModule } from '../filter-label/filter-label.module';
import { FilterPanelModule } from '../filter-panel/filter-panel.module';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { InputSearchModule } from '../input-search/input-search.module';
import { MenuModule } from '../menu/menu.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';

import { FilterNameDirective } from './filter-name.directive';
import { FiltersTableComponent } from './filters-table.component';

@NgModule({
  declarations: [FiltersTableComponent, FilterNameDirective],
  exports: [FiltersTableComponent, FilterNameDirective],
  imports: [
    CommonModule,
    ControlPanelModule,
    InputSearchModule,
    ButtonModule,
    IconButtonModule,
    ChipModule,
    MenuModule,
    MatMenuModule,
    LetModule,
    TooltipModule,
    OverlayModule,
    FilterPanelModule,
    SvgIconModule,
    FilterLabelModule,
  ],
})
export class FiltersTableModule {}
