import { gql } from 'apollo-angular';

import { fragmentCatalogSectionTax } from '../catalog-section';
import { fragmentMutationResult, fragmentPage, fragmentRequestResult } from '../mutations';

export const fragmentStockUnitTax = gql`
  fragment StockUnitTax on StockUnit {
    positionType {
      entityId
      entityType
      code
    }
    snoValue {
      entityId
      entityType
      sno
    }
    vatValue {
      entityId
      entityType
      vat
    }
  }
`;

export const fragmentProductResult = gql`
  fragment ProductResult on ProductResult {
    ...RequestResult
    output {
      id
      name
      type
      section {
        id
        name
        colorSection
      }
      stockUnits {
        id
        name
        description
        weighable
        unit
        quantity
        unitTypesMatchList
        positionType {
          entityId
          entityType
          code
        }
        nutritionFact {
          calories
          carbohydrates
          fat
          protein
        }
        primePrice {
          amountValue
          currencyUnit
        }
        weightedAveragePrimePrice {
          amountValue
          currencyUnit
        }
        lastArrivalPrimePrice {
          amountValue
          currencyUnit
        }
        salePrice {
          amountValue
          currencyUnit
        }
        images {
          id
          originalFileName
          extension
          imageSizes {
            url
          }
        }
      }
    }
  }
  ${fragmentRequestResult}
`;

export const mutationCreateProductV2 = gql`
  mutation createProductV2($product: ProductCreateInput!) {
    createProductV2(product: $product) {
      ...ProductResult
    }
  }
  ${fragmentProductResult}
`;

export const mutationCreateProductV2WithStockUnit = gql`
  mutation createProductV2($product: ProductCreateInput!) {
    createProductV2(product: $product) {
      ...ProductResult
      output {
        stockUnits {
          id
          name
          description
          weighable
          unit
          quantity
          unitTypesMatchList
          primePrice {
            amountValue
            currencyUnit
          }
          weightedAveragePrimePrice {
            amountValue
            currencyUnit
          }
          lastArrivalPrimePrice {
            amountValue
            currencyUnit
          }
          salePrice {
            amountValue
            currencyUnit
          }
        }
      }
    }
  }
  ${fragmentProductResult}
`;

export const mutationUpdateProduct = gql`
  mutation updateProductV3($product: ProductPatchUpdateInput!) {
    updateProductV3(product: $product) {
      ...ProductResult
    }
  }
  ${fragmentProductResult}
`;

export const mutationDeleteProductV2 = gql`
  mutation deleteProduct($id: UUID!) {
    deleteProduct(id: $id) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationDuplicateProduct = gql`
  mutation duplicateProduct($productId: UUID!) {
    duplicateProduct(productId: $productId) {
      ...ProductResult
    }
  }
  ${fragmentProductResult}
`;

export const mutationMoveProduct = gql`
  mutation moveProduct($newSectionId: UUID, $targetProductId: UUID!) {
    moveProduct(newSectionId: $newSectionId, targetProductId: $targetProductId) {
      ...ProductResult
    }
  }
  ${fragmentProductResult}
`;

export const queryProductArrivalsExist = gql`
  query productArrivalsExist($id: UUID!) {
    productArrivalsExist(id: $id)
  }
`;

export const queryProduct = gql`
  query product($id: UUID!) {
    product(id: $id) {
      id
      name
      type
      section {
        id
        name
        colorSection
        ...CatalogSectionTax
      }
      workshop {
        id
        name
      }
      weight
      weightUnitType
      stockUnits {
        id
        name
        description
        weighable
        unit
        quantity
        freePrice
        unitTypesMatchList
        ...StockUnitTax
        primePrice {
          amountValue
          currencyUnit
        }
        weightedAveragePrimePrice {
          amountValue
          currencyUnit
        }
        lastArrivalPrimePrice {
          amountValue
          currencyUnit
        }
        salePrice {
          amountValue
          currencyUnit
        }
        nutritionFact {
          calories
          carbohydrates
          fat
          protein
        }
        images {
          id
          originalFileName
          extension
          imageSizes {
            url
          }
        }
        barcodes {
          id
          code
        }
        techCard {
          id
          techCardItems {
            id
            bruttoUnitType
            brutto
            netto
            stockUnit {
              id
              name
              weighable
              unit
              quantity
              primePrice {
                amountValue
                currencyUnit
              }
              weightedAveragePrimePrice {
                amountValue
                currencyUnit
              }
              lastArrivalPrimePrice {
                amountValue
                currencyUnit
              }
            }
          }
          processOfCooking
        }
      }
    }
  }
  ${fragmentCatalogSectionTax}
  ${fragmentStockUnitTax}
`;

export const queryProductShort = gql`
  query product($id: UUID!) {
    product(id: $id) {
      id
      name
      stockUnits {
        id
        name
      }
    }
  }
`;

export const queryGetWhereUsedPageableV2 = gql`
  query getWhereUsedPageable($pageRequest: PageRequestInput!, $stockUnitId: UUID!) {
    getWhereUsedPageable(pageRequest: $pageRequest, stockUnitId: $stockUnitId) {
      content {
        id
        name
        stockUnits {
          id
          salePrice {
            amountValue
            currencyUnit
          }
          images {
            id
            imageSizes {
              url
            }
          }
        }
      }
      ...Page
    }
  }
  ${fragmentPage}
`;

export const queryProducts = gql`
  query products($filter: ProductsFilterInput, $pageRequest: PageRequestInput) {
    products(filter: $filter, pageRequest: $pageRequest) {
      ...Page
      content {
        id
        name
        type
        section {
          id
          name
        }
        stockUnits {
          id
          name
          description
          weighable
          unit
          quantity
          unitTypesMatchList
          primePrice {
            amountValue
            currencyUnit
          }
          weightedAveragePrimePrice {
            amountValue
            currencyUnit
          }
          lastArrivalPrimePrice {
            amountValue
            currencyUnit
          }
          salePrice {
            amountValue
            currencyUnit
          }
        }
      }
    }
  }
  ${fragmentPage}
`;

export const queryProductsShort = gql`
  query products($filter: ProductsFilterInput, $pageRequest: PageRequestInput) {
    products(filter: $filter, pageRequest: $pageRequest) {
      ...Page
      content {
        id
        name
        type
        section {
          name
        }
        stockUnits {
          id
          name
          unit
          description
          unitTypesMatchList
          primePrice {
            amountValue
            currencyUnit
          }
          weightedAveragePrimePrice {
            amountValue
            currencyUnit
          }
          lastArrivalPrimePrice {
            amountValue
            currencyUnit
          }
          salePrice {
            amountValue
            currencyUnit
          }
          quantity
        }
      }
    }
  }
  ${fragmentPage}
`;

export const fragmentStockUnitPage = gql`
  fragment StockUnitPage on StockUnitPage {
    content {
      id
      name
      description
      weighable
      unit
      quantity
      unitTypesMatchList
      primePrice {
        amountValue
        currencyUnit
      }
      salePrice {
        amountValue
        currencyUnit
      }
      weightedAveragePrimePrice {
        amountValue
        currencyUnit
      }
      lastArrivalPrimePrice {
        amountValue
        currencyUnit
      }
    }
    ...Page
  }
  ${fragmentPage}
`;

export const queryStockUnits = gql`
  query stockUnits($filter: StockUnitFilterInput, $pageRequest: PageRequestInput) {
    stockUnits(filter: $filter, pageRequest: $pageRequest) {
      ...StockUnitPage
    }
  }
  ${fragmentStockUnitPage}
`;
