import { EntityWithId, Row } from '../component/table/table.types';
export class TableTreeService<T extends EntityWithId> {
  expandedRows: Row<T>[] = [];

  constructor() {}

  getExpandedRow(rowDataId: string): Row<T> | undefined {
    return this.expandedRows.find((r) => r.rowDataId === rowDataId);
  }

  handleRowExpanded(row: Row<T>): void {
    const index = this.expandedRows.findIndex((r) => r.rowId === row.rowId);
    if (index !== -1) {
      this.expandedRows.splice(index, 1);
    }

    if (row.expanded) {
      this.expandedRows.push(row);
    }
  }

  refreshRowItems(rowDataId: string): void {
    const row = this.getExpandedRow(rowDataId);
    if (row && row.refreshChildren$) {
      row.refreshChildren$.next(true);
    }
  }
}
