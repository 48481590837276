import { Pipe, PipeTransform } from '@angular/core';

import { SelectLib } from '@nomia/nomia-components-lib';

import { CoreSchema } from '@typings';

@Pipe({
  name: 'toStores',
})
export class ToStoresPipe implements PipeTransform {
  transform(stores: CoreSchema.Store[]): SelectLib[] {
    return stores?.map((store) => {
      const { id, name, actualAddress } = store;

      return { id, title: name, caption: actualAddress!, icon: 'Store' };
    });
  }
}
