import { Pipe, PipeTransform } from '@angular/core';

import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { UnitTypePipe } from '@app/shared/pipe/unit-type.pipe';
import { UnitType } from '@typings';

@Pipe({
  name: 'UnitTypeAutocompleteItems',
})
export class UnitTypeAutocompleteItemsPipe implements PipeTransform {
  constructor(private unitTypePipe: UnitTypePipe) {}

  transform(units?: UnitType[]): AutocompleteOption<UnitType>[] {
    return (units || []).map((unit) => ({
      id: unit,
      label: this.unitTypePipe.transform(unit),
      type: 'item',
      data: unit,
    }));
  }
}
