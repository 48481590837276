import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FooterSelectComponent } from '@app/shared/component/footer-select/footer-select.component';

@NgModule({
  declarations: [FooterSelectComponent],
  imports: [CommonModule],
  exports: [FooterSelectComponent],
})
export class FooterSelectModule {}
