<div class="nm-input-file" [attr.data-test-id]="testId">
  <nm-control-label *ngIf="label" [label]="label" [required]="required" [showIcon]="showIcon"></nm-control-label>
  <div
    [ngClass]="inputFileDropzoneClasses"
    (dragenter)="handleDragEnter($event)"
    (dragover)="handleDragOver($event)"
    (dragleave)="handleDragLeave($event)"
    (drop)="handleDrop($event)"
  >
    <div
      class="preview"
      [ngStyle]="{ cursor: fileUrl || disabled ? 'default' : 'pointer' }"
      (click)="handlePreviewClick()"
    >
      <nm-input-image
        #inputImage
        [label]="previewLabel"
        [fileUrl]="fileUrl"
        [fileName]="fileName"
        [disabled]="disabled"
        [loading]="!!fileDownloadSub"
        testId="preview"
        (removeFile)="removeSelectedFile()"
        (previewLoad)="handleImagePreviewLoad()"
        (previewError)="handleImagePreviewError($event)"
      ></nm-input-image>
    </div>
    <div class="right">
      <span *ngIf="!!description">{{ description }}</span>
      <div class="buttons">
        <div class="button">
          <nm-button
            variant="outlined"
            [disabled]="disabled"
            [fullWidth]="true"
            (click)="handleLoadFromLocal()"
            testId="button-load-from-local"
            >{{ selectBtnLabel }}</nm-button
          >
        </div>
        <div class="button" *ngIf="loadByLink">
          <nm-button
            variant="outlined"
            (click)="handleLoadFromURL()"
            [disabled]="disabled"
            [fullWidth]="true"
            testId="button-load-from-url"
            >Загрузить по ссылке</nm-button
          >
        </div>
      </div>
    </div>
    <input #fileInput class="file-input" type="file" [accept]="accept" (change)="handleFileInput()" />
  </div>
  <nm-control-hint *ngIf="hint" [label]="hint" [type]="hintType"></nm-control-hint>
</div>

<ng-template #dialogTemplate>
  <nm-file-url-dialog (urlSelect)="handleUrlSelect($event)" (urlCancel)="handleDialogClose()"></nm-file-url-dialog>
</ng-template>
