import { Injectable } from '@angular/core';

import {
  mutationArchiveStorageRoom,
  mutationCreateStorageRoom,
  mutationCreateWarehouseV2,
  mutationUnarchiveStorageRoom,
  mutationUpdateStorageRoom,
  mutationUpdateWarehouseV2,
  queryAllWarehouseItemsPageable,
  queryAllWarehousesPageable,
  queryAllWarehousesPageableShort,
  queryCountWarehouseItemsWrittenOffReportByWarehouse,
  queryGetWarehouseItemsWrittenOffReportByWarehouse,
  queryStorageRoom,
  queryStorageRooms,
  queryWarehouseFull,
  queryWarehouseItemsWrittenOffReportsExist,
  queryWarehouseStockUnitsIds,
  queryWarehousesTotal,
} from '@api';
import { ApiService } from '@services/core';
import {
  Mutation,
  MutationArchiveStorageRoomArgs,
  MutationCreateStorageRoomArgs,
  MutationCreateWarehouseV2Args,
  MutationResult,
  MutationUnarchiveStorageRoomArgs,
  MutationUpdateStorageRoomArgs,
  MutationUpdateWarehouseV2Args,
  Query,
  QueryAllWarehouseItemsPageableArgs,
  QueryAllWarehousesPageableArgs,
  QueryCountWarehouseItemsWrittenOffReportByWarehouseArgs,
  QueryGetWarehouseItemsWrittenOffReportByWarehouseArgs,
  QueryResult,
  QueryStorageRoomArgs,
  QueryStorageRoomsArgs,
  QueryWarehouseArgs,
  QueryWarehouseStockUnitsIdsArgs,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class WarehouseApi {
  constructor(private apiService: ApiService) {}

  createWarehouseV2(variables: MutationCreateWarehouseV2Args): MutationResult<'createWarehouseV2'> {
    return this.apiService.mutate<Mutation<'createWarehouseV2'>, MutationCreateWarehouseV2Args>({
      mutation: mutationCreateWarehouseV2,
      variables,
    });
  }

  updateWarehouseV2(variables: MutationUpdateWarehouseV2Args): MutationResult<'updateWarehouseV2'> {
    return this.apiService.mutate<Mutation<'updateWarehouseV2'>, MutationUpdateWarehouseV2Args>({
      mutation: mutationUpdateWarehouseV2,
      variables,
    });
  }

  allWarehousesPageable(variables: QueryAllWarehousesPageableArgs): QueryResult<'allWarehousesPageable'> {
    return this.apiService.query<Query<'allWarehousesPageable'>, QueryAllWarehousesPageableArgs>({
      query: queryAllWarehousesPageable,
      variables,
    });
  }

  allWarehousesPageableShort(variables: QueryAllWarehousesPageableArgs): QueryResult<'allWarehousesPageable'> {
    return this.apiService.query<Query<'allWarehousesPageable'>, QueryAllWarehousesPageableArgs>({
      query: queryAllWarehousesPageableShort,
      variables,
    });
  }

  getWarehousesTotalNumber(variables: QueryAllWarehousesPageableArgs): QueryResult<'allWarehousesPageable'> {
    return this.apiService.query<Query<'allWarehousesPageable'>, QueryAllWarehousesPageableArgs>({
      query: queryWarehousesTotal,
      variables,
    });
  }

  warehouse(variables: QueryWarehouseArgs): QueryResult<'warehouse'> {
    return this.apiService.query<Query<'warehouse'>, QueryWarehouseArgs>({
      query: queryWarehouseFull,
      variables,
    });
  }

  allWarehouseItemsPageable(variables: QueryAllWarehouseItemsPageableArgs): QueryResult<'allWarehouseItemsPageable'> {
    return this.apiService.query<Query<'allWarehouseItemsPageable'>, QueryAllWarehouseItemsPageableArgs>({
      query: queryAllWarehouseItemsPageable,
      variables,
    });
  }

  warehouseStockUnitsIds(variables: QueryWarehouseStockUnitsIdsArgs): QueryResult<'warehouseStockUnitsIds'> {
    return this.apiService.query<Query<'warehouseStockUnitsIds'>, QueryWarehouseStockUnitsIdsArgs>({
      query: queryWarehouseStockUnitsIds,
      variables,
    });
  }

  wareHouseItemsWrittenOffReportExist(): QueryResult<'warehouseItemsWrittenOffReportsExist'> {
    return this.apiService.query<Query<'warehouseItemsWrittenOffReportsExist'>, {}>({
      query: queryWarehouseItemsWrittenOffReportsExist,
    });
  }

  getWarehouseItemsWrittenOffReportByWarehouse(
    variables: QueryGetWarehouseItemsWrittenOffReportByWarehouseArgs,
  ): QueryResult<'getWarehouseItemsWrittenOffReportByWarehouse'> {
    return this.apiService.query<
      Query<'getWarehouseItemsWrittenOffReportByWarehouse'>,
      QueryGetWarehouseItemsWrittenOffReportByWarehouseArgs
    >({
      query: queryGetWarehouseItemsWrittenOffReportByWarehouse,
      variables,
    });
  }

  getCountWarehouseItemsWrittenOffReportByWarehouse(
    variables: QueryCountWarehouseItemsWrittenOffReportByWarehouseArgs,
  ): QueryResult<'countWarehouseItemsWrittenOffReportByWarehouse'> {
    return this.apiService.query<
      Query<'countWarehouseItemsWrittenOffReportByWarehouse'>,
      QueryCountWarehouseItemsWrittenOffReportByWarehouseArgs
    >({
      query: queryCountWarehouseItemsWrittenOffReportByWarehouse,
      variables,
    });
  }

  createStorageRoom(variables: MutationCreateStorageRoomArgs): MutationResult<'createStorageRoom'> {
    return this.apiService.mutate<Mutation<'createStorageRoom'>, MutationCreateStorageRoomArgs>({
      mutation: mutationCreateStorageRoom,
      variables,
    });
  }

  updateStorageRoom(variables: MutationUpdateStorageRoomArgs): MutationResult<'updateStorageRoom'> {
    return this.apiService.mutate<Mutation<'updateStorageRoom'>, MutationUpdateStorageRoomArgs>({
      mutation: mutationUpdateStorageRoom,
      variables,
    });
  }

  archiveStorageRoom(variables: MutationArchiveStorageRoomArgs): MutationResult<'archiveStorageRoom'> {
    return this.apiService.mutate<Mutation<'archiveStorageRoom'>, MutationArchiveStorageRoomArgs>({
      mutation: mutationArchiveStorageRoom,
      variables,
    });
  }

  unarchiveStorageRoom(variables: MutationUnarchiveStorageRoomArgs): MutationResult<'unarchiveStorageRoom'> {
    return this.apiService.mutate<Mutation<'unarchiveStorageRoom'>, MutationUnarchiveStorageRoomArgs>({
      mutation: mutationUnarchiveStorageRoom,
      variables,
    });
  }

  storageRooms(variables: QueryStorageRoomsArgs): QueryResult<'storageRooms'> {
    return this.apiService.query<Query<'storageRooms'>, QueryStorageRoomsArgs>({
      query: queryStorageRooms,
      variables,
    });
  }

  storageRoom(variables: QueryStorageRoomArgs): QueryResult<'storageRoom'> {
    return this.apiService.query<Query<'storageRoom'>, QueryStorageRoomArgs>({
      query: queryStorageRoom,
      variables,
    });
  }
}
