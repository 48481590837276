import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';

import { FilterPanelService } from '@app/shared/service/filter-panel.service';
import { SVG_ICONS_TYPE } from '@constants';
import { BreakpointObserverService } from '@services/core';

import { MenuItem } from '../menu-item/menu-item.model';

import { FilterNameDirective } from './filter-name.directive';

type View = 'list' | 'grid';
export type Filter = {
  id: string;
  name: string;
};

export interface FilterTemplate {
  id: string;
  template: TemplateRef<unknown>;
}

@Component({
  selector: 'nm-filters-table',
  templateUrl: './filters-table.component.html',
  styleUrls: ['./filters-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersTableComponent implements AfterViewInit, OnDestroy {
  @ViewChild('menuTemplate') menuTemplate: TemplateRef<unknown>;
  @ViewChild('graphMenuTemplate') graphMenuTemplate: TemplateRef<unknown>;

  @ContentChildren(FilterNameDirective) filterData: QueryList<FilterNameDirective>;

  filterDataArray: FilterNameDirective[];
  onDestroy$ = new Subject<void>();

  @Input() currentView: View = 'list';
  @Input() items: MenuItem[] = [];
  @Input() graphItems: MenuItem[] = [];
  @Input() filters: Filter[] = [];
  @Input() filtersTemplates: FilterTemplate[] = [];
  @Input() initialSearch: string = '';
  @Input() icon: SVG_ICONS_TYPE = 'settings';
  @Input() isContent: boolean = false;
  @Input() isOpenContent: boolean = false;
  @Input() isView: boolean = false;
  @Input() isGraph: boolean = false;
  @Input() showSearch: boolean = true;
  @Input() showGroupedFilters: boolean = true;
  @Input() emptyFilters: boolean = true;
  @Input() testId: string;
  @Input() searchCharsCount: number = 3;
  @Input() positions: ConnectedPosition[] = [
    { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
    { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' },
    { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
    { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
  ];

  @Output() search = new EventEmitter<string>();
  @Output() clickFilters = new EventEmitter<void>();
  @Output() clickView = new EventEmitter<View>();
  @Output() clickSettings = new EventEmitter<void>();
  @Output() resetFilter = new EventEmitter<string>();

  constructor(public bos: BreakpointObserverService, public filterPanelService: FilterPanelService) {}

  ngAfterViewInit(): void {
    this.filterDataArray = this.filterData?.toArray() || [];
    this.filterDataArray.forEach((filter, i) => {
      if (filter?.order === undefined) {
        filter.order = i;
      }
    });
  }

  onMenuItemClick(item: MenuItem, template: TemplateRef<unknown>) {
    if (!!item?.onClick) {
      return;
    }
    const itemTemplate = this.filterDataArray.find((f) => f.name === item.id);
    itemTemplate?.filterOpen.emit();
    this.setTemplate(itemTemplate?.baseFilterService?.panelTemplate || null, template || null);
  }

  setTemplate(template: TemplateRef<unknown> | null, previousTemplate: TemplateRef<unknown> | null = null) {
    this.filterPanelService.setView(template, previousTemplate);
  }

  onSearch(value: string): void {
    this.search.emit(value);
  }

  onClickFilters(): void {
    this.clickFilters.emit();
  }

  onClickView(view: View): void {
    this.currentView = view;
    this.clickView.emit(this.currentView);
  }

  onClickSettings(): void {
    this.clickSettings.emit();
  }

  onResetFilter(id: string): void {
    this.resetFilter.emit(id);
  }

  toggleContent(): void {
    this.isOpenContent = !this.isOpenContent;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
