<nm-modal-base
  (ok)="leavingsService.deleteLeaving(data.leaving, data.callbackFn)"
  (cancel)="leavingsService.hideModal()"
  okTitle="Удалить"
  cancelTitle="Отменить"
  okVariant="filled"
>
  <div modal-base-title>Удалить списание</div>
  <div>Вы уверены, что хотите удалить списание?</div>
</nm-modal-base>
