<nm-modal-base
  cancelTitle="Отменить"
  okTitle="Загрузить"
  (ok)="handleOk()"
  (cancel)="handleCancel()"
  (closeDialog)="handleCancel()"
>
  <span modal-base-title>Загрузка файла по ссылке</span>
  <div class="file-url-dialog">
    <nm-input
      #input
      label="Ссылка"
      placeholder="Вставьте ссылку"
      [value]="url"
      [showLabelIcon]="false"
      testId="input-url"
    ></nm-input>
  </div>
</nm-modal-base>
