import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'nm-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent {
  @Input() value: string | boolean = '';
  @Input() checked: boolean;
  @Input() disabled: boolean = false;
  @Input() testId: string;

  @Output() changeRadio = new EventEmitter<MatRadioChange>();
}
