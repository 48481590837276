import { forwardRef, NgModule } from '@angular/core';
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import {
  cache,
  createApplicationLink,
  createAuthLink,
  createErrorLink,
  createFingerprintLink,
  createOrganizationLink,
  createRefreshLink,
  createRequestLink,
  createTraceIdLink,
} from '@api';
import { ApolloClientOptions, ApolloLink, NormalizedCacheObject } from '@apollo/client/core';
import { ConfigService } from '@app/config.service';
import { ValidationErrorsService } from '@core/service';
import { SessionStorage, SubscriptionClientService } from '@services/api';
import { NotifyService } from '@services/shared';

import { createRetryLink } from './link/retry';

export const apolloClient = (
  httpLink: HttpLink,
  storage: SessionStorage,
  subscriptionClient: SubscriptionClientService,
  notifyService: NotifyService,
  configService: ConfigService,
  validationErrorsService: ValidationErrorsService,
): ApolloClientOptions<NormalizedCacheObject> => {
  return {
    link: ApolloLink.from([
      createTraceIdLink(),
      createApplicationLink({ config: configService }),
      createRefreshLink({ storage }),
      createAuthLink({ storage }),
      createOrganizationLink({ storage }),
      createFingerprintLink({ storage }),
      createErrorLink({ storage, notifyService, validationErrorsService }),
      createRetryLink(),
      createRequestLink({ httpLink, subscriptionClient }),
    ]),
    cache,
  };
};

export const apolloTestClient = (
  httpLink: HttpLink,
  storage: SessionStorage,
  subscriptionClient: SubscriptionClientService,
  notifyService: NotifyService,
  configService: ConfigService,
  validationErrorsService: ValidationErrorsService,
): Record<string, ApolloClientOptions<NormalizedCacheObject>> => {
  return {
    default: {
      link: ApolloLink.from([
        createTraceIdLink(),
        createApplicationLink({ config: configService }),
        createRefreshLink({ storage }),
        createAuthLink({ storage }),
        createOrganizationLink({ storage }),
        createFingerprintLink({ storage }),
        createErrorLink({ storage, notifyService, validationErrorsService }),
        createRetryLink(),
        createRequestLink({ httpLink, subscriptionClient }),
      ]),
      cache,
    },
    test: {
      link: ApolloLink.from([
        createTraceIdLink(),
        createApplicationLink({ config: configService }),
        createRefreshLink({ storage }),
        createAuthLink({ storage }),
        createOrganizationLink({ storage }),
        createFingerprintLink({ storage }),
        createErrorLink({ storage, notifyService, validationErrorsService }),
        createRetryLink(),
        createRequestLink({ httpLink, subscriptionClient }, 'https://test-erp.stage-yc.nomia.tech/graphql'),
      ]),
      cache,
    },
  };
};

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: apolloClient,
      deps: [HttpLink, SessionStorage, SubscriptionClientService, NotifyService, ConfigService, forwardRef(() => ValidationErrorsService)],
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: apolloTestClient,
      deps: [HttpLink, SessionStorage, SubscriptionClientService, NotifyService, ConfigService, forwardRef(() => ValidationErrorsService)],
    },
  ],
})
export class ApolloModule {}
