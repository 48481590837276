import { RetryLink } from '@apollo/client/link/retry';
import { ERROR_NETWORK_STATUS } from '@constants';

export const createRetryLink = () => {
  return new RetryLink({
    attempts: {
      max: 3,
      retryIf: (error) => {
        const { status } = error;
        if (status === ERROR_NETWORK_STATUS.UNKNOWN_ERROR) return true;
        if ((status >= 500 && status <= 504) || status === 403 || status === 408) return true;
        return false;
      },
    },
  });
};
