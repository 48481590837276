import { Dayjs, default as dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

import 'dayjs/locale/ru';

export * from './api';
export * from './catalog-section';
export * from './chart-date.util';
export * from './colors.util';
export * from './compound.util';
export * from './convert-timer.util';
export * from './date.util';
export * from './file.util';
export * from './file-saver.util';
export * from './form.util';
export * from './format.util';
export * from './invitation.util';
export * from './lang.util';
export * from './max-number.util';
export * from './navigator.util';
export * from './number-mask.util';
export * from './promise.util';
export * from './range.util';
export * from './sort.util';
export * from './table.util';
export * from './tech-support.util';
export * from './type.util';
export * from './validation.util';
export { default as deepmerge } from 'deepmerge';
export { default as jwtDecode } from 'jwt-decode';
export { parsePhoneNumber } from 'libphonenumber-js';
export { v4 as uuidv4, validate as uuidValidate } from 'uuid';

// set RU locale globally
// TODO: think about global hook with i18next
dayjs.locale('ru');
dayjs.extend(isToday);

export { dayjs };
export type { Dayjs };
