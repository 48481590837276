import { Injectable } from '@angular/core';

import {
  queryGetAnalyticsWarehouseItemMoveHistoryDetailsByStockUnit,
  queryLeavingByOrderDocument,
  queryOrderByIdShort,
  queryOrderWriteOffDoc,
  queryOrderWriteOffProducts,
  queryOrderWriteOffProductsByDocId,
  queryStockUnitMovements,
  queryStockUnitMovementsByProduct,
} from '@api';
import { ApiService } from '@core/service';
import {
  Query,
  QueryGetAnalyticsWarehouseItemMoveHistoryDetailsByStockUnitArgs,
  QueryLeavingByOrderDocumentArgs,
  QueryOrderByIdArgs,
  QueryOrderWriteOffDocByIdArgs,
  QueryOrderWriteOffProductsArgs,
  QueryOrderWriteOffProductsByDocIdArgs,
  QueryResult,
  QueryStockUnitMovementsArgs,
  QueryStockUnitMovementsByProductArgs,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class MovementsApi {
  constructor(private apiService: ApiService) {}

  movementDetailsPageable(
    variables: QueryGetAnalyticsWarehouseItemMoveHistoryDetailsByStockUnitArgs,
  ): QueryResult<'getAnalyticsWarehouseItemMoveHistoryDetailsByStockUnit'> {
    return this.apiService.query<
      Query<'getAnalyticsWarehouseItemMoveHistoryDetailsByStockUnit'>,
      QueryGetAnalyticsWarehouseItemMoveHistoryDetailsByStockUnitArgs
    >({
      query: queryGetAnalyticsWarehouseItemMoveHistoryDetailsByStockUnit,
      variables,
    });
  }

  leavingByOrderDocument(variables: QueryLeavingByOrderDocumentArgs): QueryResult<'leavingByOrderDocument'> {
    return this.apiService.query<Query<'leavingByOrderDocument'>, QueryLeavingByOrderDocumentArgs>({
      query: queryLeavingByOrderDocument,
      variables,
    });
  }

  orderById(variables: QueryOrderByIdArgs): QueryResult<'orderById'> {
    return this.apiService.query<Query<'orderById'>, QueryOrderByIdArgs>({
      query: queryOrderByIdShort,
      variables,
    });
  }

  stockUnitMovements(variables: QueryStockUnitMovementsArgs): QueryResult<'stockUnitMovements'> {
    return this.apiService.query<Query<'stockUnitMovements'>, QueryStockUnitMovementsArgs>({
      query: queryStockUnitMovements,
      variables,
    });
  }

  stockUnitMovementsByProduct(variables: QueryStockUnitMovementsByProductArgs): QueryResult<'stockUnitMovementsByProduct'> {
    return this.apiService.query<Query<'stockUnitMovementsByProduct'>, QueryStockUnitMovementsByProductArgs>({
      query: queryStockUnitMovementsByProduct,
      variables,
    });
  }

  orderWriteOffProducts(variables: QueryOrderWriteOffProductsArgs): QueryResult<'orderWriteOffProducts'> {
    return this.apiService.query<Query<'orderWriteOffProducts'>, QueryOrderWriteOffProductsArgs>({
      query: queryOrderWriteOffProducts,
      variables,
    });
  }

  orderWriteOffProductsByDocId(variables: QueryOrderWriteOffProductsByDocIdArgs): QueryResult<'orderWriteOffProductsByDocId'> {
    return this.apiService.query<Query<'orderWriteOffProductsByDocId'>, QueryOrderWriteOffProductsByDocIdArgs>({
      query: queryOrderWriteOffProductsByDocId,
      variables,
    });
  }

  orderWriteOffDoc(variables: QueryOrderWriteOffDocByIdArgs): QueryResult<'orderWriteOffDocById'> {
    return this.apiService.query<Query<'orderWriteOffDocById'>, QueryOrderWriteOffDocByIdArgs>({
      query: queryOrderWriteOffDoc,
      variables,
    });
  }
}
