import { Injectable, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { RevertDialogComponent } from '@app/modules/warehouse/components/revert-dialog/revert-dialog.component';
import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { CreateProductDialogComponent } from '@app/shared/component/create-product-dialog/create-product-dialog.component';
import { DatepickerRange } from '@app/shared/component/datepicker/datepicker.component';
import { ModalRef, ModalService } from '@app/shared/component/dialog/abstract';
import { ModalBaseComponent } from '@app/shared/component/dialog/modal-base/modal-base.component';
import { timeFieldValidator } from '@app/shared/component/timepicker/timepicker.component';
import { DateService } from '@app/shared/service/date.service';
import { ExportReportService } from '@app/shared/service/export-report.service';
import {
  DATE_FORMAT,
  DOC_STATUS,
  exportUrlByReportType,
  GET_DOC_STATUS,
  MAX_CHARACTERS,
  MAX_FRACTIONAL,
  RootNavigationRoute,
  ROUTE_CREATE_NEW,
  STATUS_DOCUMENT,
  UNIT_TYPE,
  WarehouseRoute,
} from '@constants';
import { SessionStorage } from '@services/api';
import { CatalogStorage, ProductStorage } from '@services/catalog';
import { FormConfirmSaveService, ValidationErrorsService } from '@services/core';
import { NotifyService, SidenavService } from '@services/shared';
import {
  CreateWriteOffDocInput,
  DateRangeInput,
  DocStatus,
  DocumentNavExtras,
  FunctionTypeVoid,
  LeavingFormGroup,
  LeavingItemForm,
  MutationCreateWriteOffDocArgs,
  MutationUpdateWriteOffDocArgs,
  PageRequestInput,
  ProductCreateInput,
  ProductDialogForm,
  QueryResult,
  QueryWriteOffDocsArgs,
  QueryWriteOffProductsArgs,
  StockUnit,
  StockUnitInput,
  UpdateWriteOffDocInput,
  WriteOffDoc,
  WriteOffDocFilter,
  WriteOffDocPage,
  WriteOffProduct,
  WriteOffProductInput,
  WriteOffProductPage,
} from '@typings';
import { dayjs, getMinValueByFractionalDigitsNumber, notEmpty } from '@utils';

import { DeleteLeavingDialogComponent } from '../../components/delete-leaving-dialog/delete-leaving-dialog.component';

import { LeavingsStorage } from './leavings.storage';

@Injectable({
  providedIn: 'root',
})
export class LeavingsService {
  maxFractional = MAX_FRACTIONAL;
  exportReportType = exportUrlByReportType;
  form: FormGroup<LeavingFormGroup>;
  modalRef: ModalRef<ModalBaseComponent | DeleteLeavingDialogComponent | RevertDialogComponent>;
  leaving: WriteOffDoc;
  leavingProducts: WriteOffProduct[];

  productIndex: number | undefined;
  #productDialogRef: ModalRef<unknown>;
  productForm: FormGroup<ProductDialogForm>;

  isEditing$: BehaviorSubject<boolean>;
  isConfirmed$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isSubmitDisabled$ = new BehaviorSubject(false);
  isLoading$ = new BehaviorSubject<boolean>(false);
  isLoadingConfirmed$ = new BehaviorSubject<boolean>(false);

  constructor(
    private leavingsStorage: LeavingsStorage,
    private exportReportService: ExportReportService,
    private formConfirmSaveService: FormConfirmSaveService,
    private sessionStorage: SessionStorage,
    private modalService: ModalService,
    private fb: FormBuilder,
    private dateService: DateService,
    private router: Router,
    private notifyService: NotifyService,
    private validationErrorsService: ValidationErrorsService,
    private sidenavService: SidenavService,
    private productStorage: ProductStorage,
    private catalogStorage: CatalogStorage,
  ) {}

  allLeavingsPageable(variables: QueryWriteOffDocsArgs): Observable<WriteOffDocPage> {
    return this.leavingsStorage.writeOffDocs(variables).pipe(map((res) => res?.data.writeOffDocs));
  }

  createFilterInputV2(options: {
    search: string;
    warehouses: string[];
    statuses: string[];
    period: DatepickerRange | null;
  }): WriteOffDocFilter {
    const { search, warehouses, statuses, period } = options;
    let filter: WriteOffDocFilter = {};

    if (!!search) {
      filter.search = search;
    }

    if (!!warehouses.length) {
      filter.storageRoomIds = Array.from(warehouses);
    }

    if (!!statuses.length) {
      if (statuses.find((s) => s === STATUS_DOCUMENT.PENDING)) {
        filter.statuses = [DOC_STATUS.OPEN, DOC_STATUS.FAILURE, DOC_STATUS.PENDING_CONFIRMATION];
      } else {
        filter.statuses = Array.from(statuses).map((status) => GET_DOC_STATUS(status));
      }
    }

    let range: DatepickerRange;

    if (period && period.start && period.end) {
      range = period;
    } else {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      range = new DateRange(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), currentDate);
    }

    const dateRange: DateRangeInput = {
      dateFrom: dayjs(range.start).format(DATE_FORMAT),
      dateTo: dayjs(range.end).format(DATE_FORMAT),
    };

    if (dateRange) {
      filter = Object.assign(filter, { dateRange });
    }

    return filter;
  }

  initForm(leaving: WriteOffDoc, products: WriteOffProduct[], extras?: DocumentNavExtras, shouldRegister: boolean = true): void {
    this.leaving = leaving;
    this.leavingProducts = products;

    this.form = this.fb.group<LeavingFormGroup>({
      date: this.fb.nonNullable.control(null, [Validators.required]),
      fromWarehouseId: this.fb.nonNullable.control(null, [Validators.required]),
      description: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.DESCRIPTION)]),
      time: this.fb.nonNullable.control(null, [Validators.required, timeFieldValidator()]),
      documentItems: this.fb.array(new Array<FormGroup<LeavingItemForm>>()),
      docNumber: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.DOC_NUMBER)]),
    });

    const { controls } = this.form;

    if (this.leaving) {
      const { date, number, storageRoom, description } = this.leaving;

      controls.date.setValue(date || null);
      controls.time.setValue(date ? this.dateService.getTime(new Date(date)) : null);
      controls.fromWarehouseId.setValue(storageRoom?.id && !storageRoom.archived ? storageRoom?.id : null);
      controls.description.setValue(description || null);
      controls.docNumber.setValue(number || number == '0' ? number : null);

      if (products) {
        products.forEach((docItem) => {
          const stockUnit = docItem.product || null;
          const quantity = Number(docItem.quantity) || null;

          const documentItemForm = this.createLeavingItemFormGroup(stockUnit, quantity, false, docItem.sum || '0');

          controls.documentItems.push(documentItemForm);
        });

        if (this.leaving.status !== 'CONFIRMED') {
          const nullStockUnit = this.createLeavingItemFormGroup();
          controls.documentItems.push(nullStockUnit);
        }
      }

      if (this.leaving.status === 'CONFIRMED') {
        this.form.disable();
      }
    } else {
      const now = new Date();
      controls.date.setValue(now.toISOString());
      controls.time.setValue(this.dateService.getTime(now));
    }

    (extras?.items || []).forEach((product) => {
      if (!product) {
        return;
      }

      const stockUnit = {
        id: product.id,
        name: product.name,
        unit: product.unit,
        quantity: product.quantity,
        primePrice: { amountValue: product.primePrice },
      } as StockUnit;
      const quantity = Number(stockUnit.quantity);

      const documentItemForm = this.createLeavingItemFormGroup(stockUnit, quantity, false);

      controls.documentItems.push(documentItemForm);
    });

    if (extras?.items && !!extras?.items.length) {
      const nullStockUnit = this.createLeavingItemFormGroup();
      controls.documentItems.push(nullStockUnit);
    }

    this.isEditing$ = new BehaviorSubject(!!this.leaving);
    this.isLoadingConfirmed$.next(false);
    this.isConfirmed$.next(this.leaving?.status === 'CONFIRMED');
    this.isSubmitDisabled$.next(this.leaving?.status === 'CONFIRMED');

    if (shouldRegister) {
      this.formConfirmSaveService.setForm(this.form);
    }
  }

  createLeavingItemFormGroup(
    stockUnit: StockUnit | null = null,
    quantity: number | null = null,
    disabled: boolean = true,
    sum?: string,
  ): FormGroup<LeavingItemForm> {
    const stockUnitAutocompleteValue: AutocompleteOption<StockUnit> | null = stockUnit
      ? {
          id: stockUnit.id,
          label: stockUnit.name,
          type: 'item',
          data: stockUnit,
        }
      : null;

    const price = stockUnit?.weightedAveragePrimePrice?.amountValue || stockUnit?.primePrice?.amountValue || '0';
    const amount = this.countAmount(quantity || 0, price, stockUnit?.quantity || '') || '0';
    const amountValue = this.isConfirmed() ? sum || amount || '0' : amount || '0';

    return this.fb.group<LeavingItemForm>({
      stockUnit: this.fb.control(stockUnitAutocompleteValue),
      factQuantity: this.fb.control({ value: quantity || 0, disabled }, [
        Validators.required,
        Validators.min(getMinValueByFractionalDigitsNumber(this.maxFractional.QUANTITY)),
      ]),
      amount: this.fb.nonNullable.control(amountValue),
    });
  }

  openLeavingPage(leavingId?: string, extras?: NavigationExtras): Promise<boolean> {
    this.formConfirmSaveService.closeForm(false);

    return this.router.navigate(
      [this.sessionStorage.getOrgId(), RootNavigationRoute.warehouse, WarehouseRoute.leavings, leavingId || ROUTE_CREATE_NEW],
      extras,
    );
  }

  exportLeavingsReport(leavingId?: string | null, warehouseId?: string | null): void {
    if (!leavingId || !warehouseId) {
      return;
    }

    this.exportReportService.exportReportWithHandler(
      this.exportReportType.LEAVING.url,
      {
        warehouseId,
        documentId: leavingId,
        zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      this.exportReportType.LEAVING.fileName,
    );
  }

  exportLeavingsAll(search?: string, warehouseIds?: string[], statuses?: string[], dateRange?: DateRangeInput): void {
    const params: Record<string, string[] | string> = {
      zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (search) {
      params.search = search;
    }
    if (warehouseIds && warehouseIds.length) {
      params.warehouseIds = warehouseIds;
    }
    if (statuses && statuses.length) {
      params.statuses = statuses;
    }
    if (dateRange) {
      params.dateFrom = dateRange.dateFrom;
      params.dateTo = dateRange.dateTo;
    }
    this.exportReportService.exportReportWithHandler(
      this.exportReportType.ALL_LEAVING_V2.url,
      params,
      this.exportReportType.ALL_LEAVING_V2.fileName,
    );
  }

  async submitForm(): Promise<void> {
    if (!this.haveLeavingItemsInput(this.form.controls.documentItems)) {
      this.notifyService.addNotification({
        type: 'alert',
        title: 'В списании должны быть указаны позиции',
      });

      return;
    }

    if (this.form.invalid) {
      this.validationErrorsService.markFormControls(this.form);
      this.notifyService.addNotification({
        type: 'alert',
        title: 'Необходимо заполнить обязательные поля',
      });
      return;
    }

    this.disableForm();
    if (this.leaving) {
      return this.updateLeavingDocument({ input: this.getLeavingDocumentUpdateInput() });
    } else {
      return this.createLeavingDocument({ input: this.getLeavingDocumentCreateInput() });
    }
  }

  haveLeavingItemsInput(documentItems: FormArray<FormGroup<LeavingItemForm>>) {
    return documentItems.length > 1;
  }

  disableForm(): void {
    this.form.disable({ emitEvent: false });
    this.isSubmitDisabled$.next(true);
  }

  enableForm(): void {
    this.form.enable({ emitEvent: false });

    this.form.controls.documentItems.controls.forEach((d) => {
      if (!d.controls.stockUnit.value) {
        d.controls.factQuantity.disable();
      }
    });
    this.isSubmitDisabled$.next(false);
  }

  getLeavingDocumentCreateInput(): CreateWriteOffDocInput {
    return {
      storageRoomId: String(this.form.controls.fromWarehouseId.value),
      date: this.dateService.getDateTime(this.form.controls.date.value, this.form.controls.time.value) || '',
      description: this.form.controls.description.value,
      products: this.getLeavingItemsInput(this.form.controls.documentItems),
      number: this.form.controls.docNumber.value || null,
    };
  }

  getLeavingDocumentUpdateInput(): UpdateWriteOffDocInput {
    const addWriteOffProducts: WriteOffProductInput[] = [];
    const removeWriteOffProducts: string[] = [];
    const documentProducts = this.getLeavingItemsInput(this.form.controls.documentItems);

    documentProducts.forEach((d) => {
      if (!this.leavingProducts.some((prod) => prod.product?.id === d.productId)) {
        addWriteOffProducts.push(d);
      }
    });

    this.leavingProducts.forEach((prod) => {
      if (!documentProducts.some((d) => d.productId === prod.product?.id)) {
        removeWriteOffProducts.push(prod.id);
      }
    });

    this.leavingProducts.forEach((prod) => {
      const docItem = documentProducts.find((d) => d.productId === prod.product?.id)!;
      if (docItem && docItem.quantity !== String(prod.quantity)) {
        removeWriteOffProducts.push(prod.id);
        addWriteOffProducts.push(docItem);
      }
    });

    return {
      id: String(this.leaving?.id!),
      storageRoomId: String(this.form.controls.fromWarehouseId.value),
      date: this.dateService.getDateTime(this.form.controls.date.value, this.form.controls.time.value) || '',
      description: this.form.controls.description.value,
      addWriteOffProducts,
      removeWriteOffProducts,
      number: this.form.controls.docNumber.value || null,
    };
  }

  getLeavingItemsInput(documentItems: FormArray<FormGroup<LeavingItemForm>>): WriteOffProductInput[] {
    return documentItems.controls
      .filter((item) => item.controls.stockUnit.value)
      .map((item) => {
        const { stockUnit, factQuantity } = item.controls;

        if (!stockUnit.value) {
          return null;
        }

        const docItem: WriteOffProductInput = {
          quantity: String(factQuantity.value) || '0',
          unit: stockUnit.value?.data?.unit || UNIT_TYPE.NONE,
          productId: stockUnit.value?.data!.id,
        };

        return docItem;
      })
      .filter(notEmpty);
  }

  updateLeavingForm(id: string): Promise<void> {
    return new Promise<void>((resolve) => {
      const output = this.getWriteOffWithProducts(id);
      output.then((res) => {
        combineLatest([res[0], res[1]])
          .pipe(take(1))
          .subscribe(([leaving, leavingProducts]) => {
            this.sidenavService.setTopBarTitle(`Списание №${(leaving as WriteOffDoc).number}`);
            this.formConfirmSaveService.closeForm(false);
            this.initForm(leaving as WriteOffDoc, leavingProducts as WriteOffProduct[]);
            resolve();
          });
      });
    });
  }

  createLeavingDocument(variables: MutationCreateWriteOffDocArgs): void {
    this.leavingsStorage.createWriteOffDoc(variables).subscribe(
      (res) => this.openLeavingPage(res?.data?.createWriteOffDoc.output?.id || ''),
      () => this.enableForm(),
    );
  }

  updateLeavingDocument(variables: MutationUpdateWriteOffDocArgs): Promise<void> {
    return new Promise<void>((resolve) => {
      this.leavingsStorage.updateWriteOffDoc(variables).subscribe((res) => {
        return this.updateLeavingForm(res?.data?.updateWriteOffDoc.output?.id || '').then(() => {
          resolve();
          this.enableForm();
        });
      });
    });
  }

  getLeavingDocument(id: string): Observable<WriteOffDoc> {
    return this.leavingsStorage.writeOffDoc({ id });
  }

  confirmDocument(leavingDocumentId: string) {
    return this.leavingsStorage.confirmWriteOffDoc({ id: leavingDocumentId }).pipe(
      map((res) => (res.data?.confirmWriteOffDoc.result === 'SUCCESS' ? leavingDocumentId : '')),
      tap((id) => {
        if (id) {
          this.isConfirmed$.next(true);
          this.leaving.status = 'CONFIRMED';
          this.disableForm();
          this.formConfirmSaveService.closeForm(false);
        }
      }),
    );
  }

  duplicateDocument(documentId: string) {
    return this.leavingsStorage.duplicateLeavingDocument(documentId);
  }

  showModal(modalTemplate: TemplateRef<ModalBaseComponent>): void {
    this.modalRef = this.modalService.openDialog(modalTemplate);
  }

  back(shouldConfirm: boolean = true) {
    this.sidenavService.back(shouldConfirm);
  }

  showDeleteModal(leaving: WriteOffDoc, callbackFn: FunctionTypeVoid): void {
    this.modalRef = this.modalService.openDialog(DeleteLeavingDialogComponent, { data: { leaving, callbackFn } });
  }

  showRevertModal(leaving: WriteOffDoc, callbackFn: () => void): void {
    this.modalRef = this.modalService.openDialog(RevertDialogComponent, {
      data: {
        title: ` списание №${leaving.number}`,
        callbackFn,
        hideFn: () => {
          this.modalRef.close();
        },
      },
    });
  }

  revertLeaving(leaving: WriteOffDoc): Observable<string | undefined> {
    return this.leavingsStorage
      .revertWriteOffDoc({
        id: leaving.id,
      })
      .pipe(
        tap(() => {
          this.formConfirmSaveService.closeForm(false);
        }),
        map((_) => leaving.id),
      );
  }

  deleteLeaving(leaving: WriteOffDoc, callbackFn?: FunctionTypeVoid): void {
    if (!leaving || !leaving.id) {
      return;
    }

    this.modalRef.close();
    this.leavingsStorage.deleteWriteOffDoc({ id: leaving.id }).subscribe(() => {
      if (callbackFn) {
        callbackFn();
      }
    });
  }

  hideModal(): void {
    if (!this.modalRef) {
      return;
    }

    this.modalRef.close();
  }

  getProducts(pageRequest: PageRequestInput, searchText: string, excludeStockUnitIds: string[]): QueryResult<'products'> {
    return this.productStorage.getProducts({
      pageRequest,
      filter: {
        types: ['MODIFIER', 'PRODUCT', 'SEMIPRODUCT'],
        search: searchText,
        excludeStockUnitIds,
        hasTechCard: false,
      },
    });
  }

  initProductForm(name: string) {
    this.productForm = this.fb.group<ProductDialogForm>({
      name: this.fb.nonNullable.control(name, [Validators.required, Validators.maxLength(MAX_CHARACTERS.PRODUCT_NAME)]),
      section: this.fb.nonNullable.control(null, [Validators.required]),
      type: this.fb.control(null, [Validators.required]),
      weighable: this.fb.nonNullable.control(false),
      unit: this.fb.nonNullable.control(UNIT_TYPE.PIECE),
      quantity: this.fb.nonNullable.control('1', [Validators.required, Validators.min(0)]),
      primePrice: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
      salePrice: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
      surcharge: this.fb.nonNullable.control('0', [Validators.required, Validators.min(0)]),
    });
  }

  openProductDialog(name: string, index: number): void {
    this.initProductForm(name);
    this.productIndex = index;
    this.#productDialogRef = this.modalService.openDialog(CreateProductDialogComponent, {
      data: {
        form: this.productForm,
        title: 'Новая позиция',
        name,
        close: (confirm: boolean) => this.closeCreateProductDialog(confirm),
        filterType: true,
      },
      disableClose: true,
    });
  }

  closeCreateProductDialog(confirm: boolean): void {
    if (confirm) {
      if (this.productForm.valid) {
        this.createProduct();
        this.hideModalCreateProduct();
      } else {
        this.validationErrorsService.markFormControls(this.productForm);
      }
    } else {
      this.hideModalCreateProduct();
    }
  }

  hideModalCreateProduct(): void {
    if (!this.#productDialogRef) {
      return;
    }
    this.#productDialogRef.close();
  }

  private createProduct(): void {
    const { name, type, weighable, unit, quantity, section, primePrice, salePrice } = this.productForm.controls;

    const stockUnitInput: StockUnitInput = {
      name: name.value,
      weighable: !weighable.value,
      unit: unit.value || 'NONE',
      quantity: quantity.value,
      primePrice: { amountValue: primePrice.value, currencyUnit: this.sessionStorage.getCurrencyUnit() },
      salePrice: { amountValue: salePrice.value, currencyUnit: this.sessionStorage.getCurrencyUnit() },
    };

    const productCreateInput: ProductCreateInput = {
      catalogId: this.catalogStorage.getCatalog().id,
      name: name.value,
      type: type.value,
      sectionId: section.value?.id,
      stockUnits: [stockUnitInput],
    };

    this.productStorage.createProduct({ product: productCreateInput }).subscribe((res) => {
      const stockUnits = res.data?.createProductV2?.output?.stockUnits;

      if (this.productIndex !== undefined && stockUnits) {
        const stockUnit = stockUnits[0];
        this.form.controls.documentItems
          .at(this.productIndex)
          .controls.stockUnit.patchValue({ type: 'item', label: stockUnit.name, id: stockUnit.id, data: stockUnit });
      }
    });
  }

  getWriteOffProductsPromise(input: QueryWriteOffProductsArgs): Promise<WriteOffProductPage> {
    return new Promise<WriteOffProductPage>((resolve) => {
      this.getWriteOffProducts(input)
        .pipe(take(1))
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  getWriteOffProducts(input: QueryWriteOffProductsArgs): Observable<WriteOffProductPage> {
    return this.leavingsStorage.writeOffProducts(input).pipe(map((res) => res.data.writeOffProducts!));
  }

  async getWriteOffWithProducts(id: string): Promise<Observable<WriteOffDoc | WriteOffProduct[]>[]> {
    let allData: WriteOffProduct[] = [];
    let page = 1;
    let totalPages = 1;
    const filter = {
      docId: id,
    };

    while (page <= totalPages) {
      try {
        let data = await this.getWriteOffProductsPromise({ filter, pageRequest: { page: page - 1, size: 100 } });
        allData = [...allData, ...data.content];
        if (page === 1) totalPages = data.totalPages;
        page++;
      } catch {
        break;
      }
    }

    return [this.leavingsStorage.writeOffDoc({ id }).pipe(map((res) => res!)), of(allData)];
  }

  isOpenLeaving(status: DocStatus) {
    return status === DOC_STATUS.OPEN || status === DOC_STATUS.FAILURE || status === DOC_STATUS.PENDING_CONFIRMATION;
  }

  countAmount(factQuantity: number | null, amountForOne: string | null, quantity: string): string {
    return this.formatNumber(((factQuantity || 0) * parseFloat(amountForOne || '0')) / (Number(quantity) || 1), false);
  }

  formatNumber(num: number, isFixed: boolean = true): string {
    if (isFixed) {
      return num.toFixed(2).replace(/\.0+$/, '');
    } else {
      return String(Math.floor(num * 100) / 100).replace(/\.0+$/, '');
    }
  }

  isConfirmed(): boolean {
    return this.leaving?.status === 'CONFIRMED';
  }
}
