import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormGroupModule } from '@app/shared/component/form-group/form-group.module';

import { FormComponent } from './form.component';

@NgModule({
  declarations: [FormComponent],
  imports: [CommonModule, ReactiveFormsModule, FormGroupModule],
  exports: [FormComponent],
})
export class FormModule {}
