import { Component, Input } from '@angular/core';

import { TableService } from '@app/shared/service/table.service';
import { BreakpointObserverService } from '@services/core';

import { TableComponent, TemplateColData } from '../table.component';
import { Column, Row, SELECT_COLUMN_WIDTH, TableConfig } from '../table.types';

@Component({
  selector: 'nm-total-rows',
  templateUrl: './table-total-rows.component.html',
  styleUrls: ['./table-total-rows.component.scss'],
  providers: [TableService],
})
export class TableTotalRowsComponent {
  @Input() config: TableConfig;
  @Input() row: Row;
  @Input() columns: Column[];
  @Input() rowPadding = 12;
  @Input() isChild = false;
  @Input() columnsDataTemplates: TemplateColData[] = [];
  @Input() gridTemplateColumns: string = 'auto';

  tableWidth = 0;
  selectColWidth = SELECT_COLUMN_WIDTH;
  expandColumn = 0;

  constructor(public table: TableComponent, public breakpointObserverService: BreakpointObserverService) {}

  contentIsDefined(column: Column): boolean {
    return typeof column.content !== 'undefined';
  }
}
