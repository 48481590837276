<div class="item-actions">
  <nm-icon-button
    variant="text"
    [icon]="menuIcon"
    [matMenuTriggerFor]="menu.childMenu"
    [testId]="testId"
  ></nm-icon-button>

  <nm-menu #menu [items]="items" [selectOnItemClick]="false"></nm-menu>
</div>
