import { Injectable } from '@angular/core';

import { mutationSaveStoreTableGroups, queryStoreTableGroup, queryStoreTableGroups } from '@api';
import { ApiService } from '@services/core';
import { CoreSchema, Mutation, MutationResult, Query, QueryResult } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class TablesApi {
  constructor(private apiService: ApiService) {}

  updateTablesGroups(variables: CoreSchema.MutationSaveStoreTableGroupsArgs): MutationResult<'saveStoreTableGroups'> {
    return this.apiService.mutate<Mutation<'saveStoreTableGroups'>, CoreSchema.MutationSaveStoreTableGroupsArgs>({
      mutation: mutationSaveStoreTableGroups,
      variables,
    });
  }

  tableGroups(variables: CoreSchema.QueryTableGroupsArgs): QueryResult<'tableGroups'> {
    return this.apiService.query<Query<'tableGroups'>, CoreSchema.QueryTableGroupsArgs>({
      query: queryStoreTableGroups,
      variables,
    });
  }

  tableGroup(variables: CoreSchema.QueryTableGroupArgs): QueryResult<'tableGroup'> {
    return this.apiService.query<Query<'tableGroup'>, CoreSchema.QueryAccountArgs>({
      query: queryStoreTableGroup,
      variables,
    });
  }
}
