// @ts-strict-ignore
// TODO: remove after Marketing release
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { DATE_FORMAT } from '@constants';
import { RouteParamsStorage } from '@services/core';
import { QueryGetChartDatesArgs, ReportInvervalMode } from '@typings';
import { dayjs, sortObjectByKeys } from '@utils';

@Injectable()
export class IntervalService {
  #interval = new BehaviorSubject<[Date, Date]>([null, null]);
  interval = this.#interval.asObservable();

  #intervalPrevious = new BehaviorSubject<[Date, Date] | null>(null);
  intervalPrevious = this.#intervalPrevious.asObservable();

  #intervals = new BehaviorSubject<[Date, Date, Date, Date] | null>(null);
  intervals = this.#intervals.asObservable();

  #duration = new BehaviorSubject<number | null>(null);
  duration = this.#duration.asObservable();

  #dates = new BehaviorSubject<QueryGetChartDatesArgs | null>(null);
  dates = this.#dates.asObservable();

  #mode = new BehaviorSubject<ReportInvervalMode | null>(null);
  mode = this.#mode.asObservable();

  #cachedQuery: QueryGetChartDatesArgs;

  updateMode(mode: ReportInvervalMode) {
    this.#mode.next(mode);
  }

  setInterval(value: [Date, Date]) {
    this.#interval.next(value);
  }

  setDuration(value: number) {
    this.#duration.next(value);
  }

  getInterval() {
    return this.#interval.getValue();
  }

  getMode(): ReportInvervalMode {
    return this.#mode.getValue();
  }

  getDuration(): number | null {
    return this.#duration.getValue();
  }

  getDates(): QueryGetChartDatesArgs {
    return this.#dates.getValue();
  }

  calculateDuration(start: Date, end: Date): number {
    const duration = this.getIntervalDuration(start, end);
    return duration;
  }

  setDates(start: Date, end: Date) {
    const [startPrev, endPrev] = this.getPrevDates(start, end);
    this.#dates.next(this.#getFormattedDates(start, end, startPrev, endPrev));
  }

  setIntervalPrevious(start: Date, end: Date) {
    const [startPrev, endPrev] = this.getPrevDates(start, end);
    this.#intervalPrevious.next([startPrev, endPrev]);
  }

  setIntervals(start: Date, end: Date) {
    const [startPrev, endPrev] = this.getPrevDates(start, end);
    this.#intervals.next([start, end, startPrev, endPrev]);
  }

  constructor(private router: Router, private activedRoute: ActivatedRoute, private routeParamsStorage: RouteParamsStorage) {
    this.routeParamsStorage.routeParams.pipe(take(1)).subscribe((queryParams) => {
      this.readUrlQueryString(queryParams);
    });
  }

  #getFormattedDates(start: Date, end: Date, startPrev: Date, endPrev: Date): QueryGetChartDatesArgs {
    const dateFrom = this.getFormattedDate(start);
    const dateTo = this.getFormattedDate(end);
    const dateFromPrev = this.getFormattedDate(startPrev);
    const dateToPrev = this.getFormattedDate(endPrev);

    return { dateFrom, dateTo, dateFromPrev, dateToPrev };
  }

  getFormattedDate(date: Date): string {
    return dayjs(date).format(DATE_FORMAT);
  }

  getPrevDates(start: Date, end: Date): [Date, Date] {
    const diff = this.getIntervalDuration(start, end) + 1;
    const startPrev: Date = dayjs(start).subtract(diff, 'days').toDate();
    const endPrev: Date = dayjs(end).subtract(diff, 'days').toDate();
    return [startPrev, endPrev];
  }

  getIntervalDuration(start: Date, end: Date): number {
    return dayjs(end).diff(start, 'days');
  }

  setUrlQueryParams(start: Date, end: Date) {
    const [startPrev, endPrev] = this.getPrevDates(start, end);
    const queryParams = this.#getFormattedDates(start, end, startPrev, endPrev);

    if (JSON.stringify(sortObjectByKeys(this.#cachedQuery)) === JSON.stringify(sortObjectByKeys(queryParams))) {
      return;
    }

    this.#cachedQuery = queryParams;

    this.router.navigate([], {
      relativeTo: this.activedRoute,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  readUrlQueryString(queryParams) {
    if (queryParams.dateFrom) {
      const dateFrom = queryParams.dateFrom;
      const dateTo = queryParams.dateTo || queryParams.dateFrom;

      const start = new Date(dateFrom);
      const end = new Date(dateTo);

      this.#interval.next([start, end]);
    }
  }

  getCurrIntervalValue(): [Date, Date] | null {
    return this.#interval.getValue();
  }
}
