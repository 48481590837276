import { CoreSchema, SalesWidgetGraphMetric, StrictDictionary } from '@typings';

export type ConsolidatedReport = CoreSchema.ConsolidatedReport;
export type ConsolidatedReportWithId = CoreSchema.ConsolidatedReport & { id: string };
export type ConsolidatedReportTotal = CoreSchema.ConsolidatedReportTotal;
export type ConsolidatedReportTabMetric = keyof Omit<ConsolidatedReportTotal, 'discount' | 'timestamp'>;
export type ConsolidatedReportTotalWithId = CoreSchema.ConsolidatedReportTotal & { id: string };
export type ConsolidatedReportInput = CoreSchema.ConsolidatedReportInput;
export type ConsolidatedReportOutput = CoreSchema.ConsolidatedReportOutput;
export type ConsolidatedReportTimeSeries = CoreSchema.ConsolidatedReportTimeSeries;

export type ConsolidatedReportMetric = keyof Omit<ConsolidatedReportTimeSeries, 'averageCheck' | 'discount' | 'timestamp'>;
export type ConsolidatedReportMetricNames = Record<ConsolidatedReportMetric, string>;
export type ConsolidatedReportMetricInclusion = Record<ConsolidatedReportMetric, boolean>;

export type ConsolidatedReportSort = CoreSchema.ConsolidatedReportSort;
export type ConsolidatedReportSortFields = CoreSchema.ConsolidatedReportSortFields;
export type ConsolidatedReportSortFieldsStrict = StrictDictionary<ConsolidatedReportSortFields, ConsolidatedReportSortFields>;

export type QueryConsolidatedReportArgs = CoreSchema.QueryConsolidatedReportArgs;
export type QueryConsolidatedReportTimeSeriesArgs = CoreSchema.QueryConsolidatedReportTimeSeriesArgs;
export type QueryConsolidatedReportXlsArgs = CoreSchema.QueryConsolidatedReportXlsArgs;

export type ConsolidatedReportLoaderFilters = ConsolidatedReportInput & { sort?: ConsolidatedReportSort[] };
export type ConsolidatedReportInputWithComparison = {
  input: ConsolidatedReportInput[];
  compareType: 'periods' | 'stores';
};
export type ConsolidatedReportLoaderFiltersWithCompare = ConsolidatedReportInputWithComparison & { sort?: ConsolidatedReportSort[] };

export const CONSOLIDATED_TABS_METRICS_NUMBER: StrictDictionary<number, ConsolidatedReportTabMetric> = {
  revenue: 0,
  profit: 1,
  ordersCount: 2,
  guestCount: 3,
  averageCheck: 4,
};

export const CONSOLIDATED_WIDGET_METRICS_UNITS: StrictDictionary<boolean, ConsolidatedReportTabMetric> = {
  revenue: true,
  profit: true,
  ordersCount: false,
  guestCount: false,
  averageCheck: true,
};

export const CONSOLIDATED_WIDGET_TABS_METRICS: StrictDictionary<string, ConsolidatedReportTabMetric> = {
  revenue: 'Выручка',
  profit: 'Прибыль',
  ordersCount: 'Заказы',
  guestCount: 'Гости',
  averageCheck: 'Средний чек',
};

export const CONSOLIDATED_WIDGET_GRAPH_METRICS: StrictDictionary<SalesWidgetGraphMetric, ConsolidatedReportTabMetric> = {
  revenue: 'revenue',
  profit: 'profit',
  ordersCount: 'ordersCount',
  guestCount: 'guestsCount',
  averageCheck: 'averageCheck',
};
