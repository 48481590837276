//TODO: remove after Marketing release
import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { CategoryScale, Chart, LinearScale, registerables } from 'chart.js';
import { Subject } from 'rxjs';

import { ChartData, ChartOptions, ChartType, DefaultDataPoint } from '@typings';
import { deepmerge } from '@utils';

@Component({
  selector: 'nm-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnDestroy, OnChanges, AfterContentInit {
  @ViewChild('chartCanvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  @Input() classCanvas = '';
  @Input() labels: string[] = [];
  @Input() data: ChartData<ChartType, DefaultDataPoint<ChartType>, string>;
  @Input() type: ChartType = 'line';
  @Input() options: ChartOptions = {};
  @Input() width: number | string = '100%';
  @Input() height: number | string = '200';

  @Output() refCanvas = new EventEmitter<ElementRef<HTMLCanvasElement>>();

  #options: ChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        enabled: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          drawBorder: true,
          display: true,
        },
        beginAtZero: true,
        ticks: {
          display: true,
        },
      },
      y: {
        grid: {
          drawBorder: false,
          display: false,
        },
        beginAtZero: true,
        ticks: {
          display: true,
        },
      },
    },
    transitions: {
      show: {
        animations: {
          x: {
            from: 0,
          },
          y: {
            from: 0,
          },
        },
      },
      hide: {
        animations: {
          x: {
            to: 0,
          },
          y: {
            to: 0,
          },
        },
      },
    },
  };

  chart: Chart | null = null;

  onDestroy = new Subject<void>();

  constructor() {
    Chart.register(CategoryScale, LinearScale, ...registerables);
  }

  ngOnDestroy(): void {
    this.chart?.destroy();

    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterContentInit(): void {
    this.refCanvas.emit(this.canvas);
    this.init();
  }

  init(): void {
    if (this.chart) {
      this.chart.destroy();
    }

    this.chart = new Chart(this.canvas.nativeElement, {
      type: this.type,
      data: this.data,
      options: deepmerge<ChartOptions>(this.#options, this.options || {}),
    });

    this.chart.resize(this.chart.width, Number(this.height));
  }

  ngOnChanges() {
    this.init();
  }
}
