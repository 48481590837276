import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SUPER_USER_KEY } from '@constants';
import { SessionStorage } from '@services/api';
import { AccountStorage } from '@services/core';
import { RedirectService } from '@services/shared';

@Injectable()
export class AccountGuard implements CanActivate {
  constructor(private accountStorage: AccountStorage, private sessionStorage: SessionStorage, private redirectService: RedirectService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const {
      params: { id },
    } = route;

    return new Observable((observable) => {
      const currentId = this.sessionStorage.getOrgId() && id === this.sessionStorage.getOrgId() ? this.sessionStorage.getOrgId() : id;

      if (currentId) {
        this.sessionStorage.setOrgId(currentId);
        this.accountStorage.setTheme();
        this.accountStorage.getOrganizationsList();
        this.accountStorage.getOrganizationById(currentId);
        if (!this.accountStorage.getAccount()?.id) {
          if (localStorage.getItem(SUPER_USER_KEY) === 'false') {
            this.accountStorage.getUser(currentId);
          } else {
            this.accountStorage.setSuperUserAccount();
            this.accountStorage.updateRoles(['owner']);
          }
        }
      }

      return this.accountStorage.account.pipe(filter((result) => !!result)).subscribe((_) => {
        this.sessionStorage.setFilters();

        return observable.next(true);
      });
    });
  }
}
