import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { NotifyService } from '@services/shared';

@Injectable({
  providedIn: 'root',
})
export class ConnectCheckService {
  isOnline$ = new Subject<boolean>();
  isOnlineText = 'Подключение к сети восстановлено';
  isOfflineText = 'Отсутствует подключение к сети!';

  constructor(private notifyService: NotifyService) {
    window.addEventListener('online', (_) => {
      this.isOnline$.next(true);
    });

    window.addEventListener('offline', (_) => {
      this.isOnline$.next(false);
    });

    this.isOnline$.subscribe((isOnline) => {
      this.notifyService.addNotification({
        type: isOnline ? 'success' : 'alert',
        title: isOnline ? this.isOnlineText : this.isOfflineText,
      });
    });
  }
}
