import { Injectable } from '@angular/core';

import {
  mutationAddUserRoles,
  mutationChangeUserPin,
  mutationDeleteUser,
  mutationInviteUser,
  mutationRemoveUserRoles,
  mutationUpdateUser,
  queryIamUser,
  queryUser,
  queryUsers,
} from '@api';
import {
  MutationAddUserRolesArgs,
  MutationDeleteUserArgs,
  MutationInviteUserArgs,
  MutationRefreshUserPinArgs,
  MutationRemoveUserRolesArgs,
  MutationUpdateUserArgs,
  QueryIamUserArgs,
  QueryUserArgs,
  QueryUsersArgs,
} from '@app/typings/user';
import { ApiService } from '@core/service/api.service';
import { Mutation, MutationResult, Query, QueryResult } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class UserApi {
  constructor(private api: ApiService) {}

  users(variables: QueryUsersArgs): QueryResult<'users'> {
    return this.api.query<Query<'users'>, QueryUsersArgs>({
      query: queryUsers,
      variables,
    });
  }

  user(variables: QueryUserArgs): QueryResult<'user'> {
    return this.api.query<Query<'user'>, QueryUserArgs>({
      query: queryUser,
      variables,
    });
  }

  iamUser(variables: QueryIamUserArgs): QueryResult<'iamUser'> {
    return this.api.query<Query<'iamUser'>, QueryIamUserArgs>({
      query: queryIamUser,
      variables,
    });
  }

  inviteUser(variables: MutationInviteUserArgs): MutationResult<'inviteUser'> {
    return this.api.mutate<Mutation<'inviteUser'>, MutationInviteUserArgs>({
      mutation: mutationInviteUser,
      variables,
    });
  }

  updateUser(variables: MutationUpdateUserArgs): MutationResult<'updateUser'> {
    return this.api.mutate<Mutation<'updateUser'>, MutationUpdateUserArgs>({
      mutation: mutationUpdateUser,
      variables,
    });
  }

  deleteUser(variables: MutationDeleteUserArgs): MutationResult<'deleteUser'> {
    return this.api.mutate<Mutation<'deleteUser'>, MutationDeleteUserArgs>({
      mutation: mutationDeleteUser,
      variables,
    });
  }

  addUserRoles(variables: MutationAddUserRolesArgs): MutationResult<'addUserRoles'> {
    return this.api.mutate<Mutation<'addUserRoles'>, MutationAddUserRolesArgs>({
      mutation: mutationAddUserRoles,
      variables,
    });
  }

  removeUserRoles(variables: MutationRemoveUserRolesArgs): MutationResult<'removeUserRoles'> {
    return this.api.mutate<Mutation<'removeUserRoles'>, MutationRemoveUserRolesArgs>({
      mutation: mutationRemoveUserRoles,
      variables,
    });
  }

  changeUserPin(variables: MutationRefreshUserPinArgs): MutationResult<'refreshUserPin'> {
    return this.api.mutate<Mutation<'refreshUserPin'>, MutationRefreshUserPinArgs>({
      mutation: mutationChangeUserPin,
      variables,
    });
  }
}
