import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SettingsRouteParam } from '@constants';
import { SubscriptionsService } from '@services/settings';
import { Bill } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class InvoiceResolver implements Resolve<Bill> {
  constructor(private router: Router, private subscriptionsService: SubscriptionsService) {
    // TODO: show preloader
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Bill> {
    const id = route.params[SettingsRouteParam.invoiceId];

    return this.subscriptionsService.getBill(id).pipe(
      // TODO: hide preloader
      catchError((err) => {
        this.router.navigateByUrl(state.url.replace(`/${id}`, ''));

        return throwError(() => err);
      }),
    );
  }
}
