import { gql } from 'apollo-angular';

export const mutationConfirmSupportControlClaim = gql`
  mutation confirmSupportControlClaim($confirmClaim: ConfirmSupportControlClaimInput!) {
    confirmSupportControlClaim(confirmClaim: $confirmClaim) {
      id
    }
  }
`;

export const mutationIssueSupportControlClaim = gql`
  mutation issueSupportControlClaim($issueClaim: IssueSupportControlClaimInput!) {
    issueSupportControlClaim(issueClaim: $issueClaim) {
      supportControlClaimIds
    }
  }
`;

export const mutationRevokeSupportControlClaim = gql`
  mutation revokeSupportControlClaim($claimId: UUID!) {
    revokeSupportControlClaim(claimId: $claimId) {
      id
    }
  }
`;

export const mutationDeclineSupportControlClaim = gql`
  mutation declineSupportControlClaim($claimId: UUID!) {
    declineSupportControlClaim(claimId: $claimId) {
      id
    }
  }
`;

export const mutationExtendSupportControlClaim = gql`
  mutation extendSupportControlClaim($claimId: UUID!, $extendDuration: Int) {
    extendSupportControlClaim(claimId: $claimId, extendDuration: $extendDuration) {
      id
    }
  }
`;

export const subscriptionSupportControlClaimChanges = gql`
  subscription supportControlClaimChanges {
    supportControlClaimChanges {
      id
      organizationId
      clientAccountId
      supportAccountId
      revoked
      confirmed
      declined
      accessCode
      expiresAt
      serverTime
      confirmationCount
    }
  }
`;

export const subscriptionSupportControlClaimChangesList = gql`
  subscription supportControlClaimChangesList {
    supportControlClaimChangesList {
      id
      organizationId
      clientAccountId
      supportAccountId
      revoked
      confirmed
      declined
      accessCode
      expiresAt
      serverTime
      confirmationCount
    }
  }
`;
