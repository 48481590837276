import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, map, Observable, Subject, take } from 'rxjs';
import { first } from 'rxjs/operators';

import { RoleStorage } from '@app/modules/employees/services/roles/role.storage';
import { DeleteRoleDialogComponent } from '@app/modules/settings/pages/components/delete-role-dialog/delete-role-dialog.component';
import { ModalRef, ModalService } from '@app/shared/component/dialog/abstract';
import { TableSidebarLayoutService } from '@app/shared/component/table-sidenav-layout/service/table-sidebar-layout.service';
import { CreateRoleInput, Role, RoleFilterInput, RolePage, UpdateRoleInput } from '@app/typings/role';
import { MAX_CHARACTERS } from '@constants';
import { FormConfirmSaveService, ValidationErrorsService } from '@core/service';
import { NotifyService, SidenavService } from '@services/shared';
import { PageRequestInput } from '@typings';

export type RoleForm = {
  name: FormControl<string>;
  pos: FormControl<boolean>;
  analytics: FormControl<'read' | boolean>;
  sales: FormControl<'read' | boolean>;
  catalog: FormControl<'read' | boolean>;
  marketing: FormControl<'read' | boolean>;
  warehouse: FormControl<'read' | boolean>;
  employees: FormControl<'read' | boolean>;
  settings: FormControl<'read' | boolean>;
};

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  modalRef: ModalRef<unknown>;
  form: FormGroup<RoleForm>;
  role: Role | undefined;

  sidenavMode = false;
  updateRoleInTable = new Subject<Role>();
  removeRoleFromTable = new Subject<string>();

  isSubmitDisabled$ = new BehaviorSubject(false);
  isEditing$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private roleStorage: RoleStorage,
    private modalService: ModalService,
    private formConfirmSaveService: FormConfirmSaveService,
    private fb: FormBuilder,
    private sidenavService: SidenavService,
    private validationErrorsService: ValidationErrorsService,
    private notifyService: NotifyService,
    private tableSidenavService: TableSidebarLayoutService,
  ) {}

  initForm(role?: Role): void {
    this.role = role;

    this.form = this.fb.group<RoleForm>({
      name: this.fb.nonNullable.control('', [Validators.required, Validators.maxLength(MAX_CHARACTERS.NAME)]),
      pos: this.fb.nonNullable.control(true),
      analytics: this.fb.nonNullable.control(true),
      sales: this.fb.nonNullable.control(true),
      catalog: this.fb.nonNullable.control(true),
      marketing: this.fb.nonNullable.control(true),
      warehouse: this.fb.nonNullable.control(true),
      employees: this.fb.nonNullable.control(true),
      settings: this.fb.nonNullable.control(true),
    });

    if (this.role) {
      const { controls } = this.form;
      const { name, scopes } = this.role;

      controls.name.setValue(name);
      controls.pos.setValue(scopes.includes('pos'));

      controls.analytics.setValue(scopes.includes('analytics'));
      controls.sales.setValue(scopes.includes('sales'));
      controls.catalog.setValue(scopes.includes('catalog.read') ? 'read' : scopes.includes('catalog'));
      controls.marketing.setValue(scopes.includes('marketing.read') ? 'read' : scopes.includes('marketing'));
      controls.warehouse.setValue(scopes.includes('warehouse.read') ? 'read' : scopes.includes('warehouse'));
      controls.employees.setValue(scopes.includes('employees.read') ? 'read' : scopes.includes('employees'));
      controls.settings.setValue(scopes.includes('settings.read') ? 'read' : scopes.includes('settings'));
    }

    this.isEditing$.next(!!this.role);
    this.isSubmitDisabled$.next(false);

    this.formConfirmSaveService.setForm(this.form);
  }

  getRolesList(pageRequestInput: PageRequestInput, filter?: RoleFilterInput): Observable<RolePage> {
    return this.roleStorage.roles({ filter, pageRequestInput }).pipe(map((res) => res.data.roles));
  }

  getRole(id: string): Observable<Role> {
    return this.roleStorage.role({ id }).pipe(map((res) => res.data.role!));
  }

  deleteRole(role: Role, callbackFn: () => void): void {
    if (!role || !role.id) {
      return;
    }

    this.modalRef.close();
    this.roleStorage.deleteRole({ id: role.id }).pipe(first()).subscribe(callbackFn);
  }

  showDeleteModal(role: Role, callbackFn: () => void): void {
    this.modalRef = this.modalService.openDialog(DeleteRoleDialogComponent, { data: { role, callbackFn } });
  }

  hideModal(): void {
    if (!this.modalRef) {
      return;
    }

    this.modalRef.close();
  }

  back(shouldConfirm: boolean = true): void {
    this.sidenavService.back(shouldConfirm);
  }

  submitForm() {
    if (this.form.invalid) {
      this.validationErrorsService.markFormControls(this.form);
      this.notifyService.addNotification({
        type: 'alert',
        title: 'Необходимо заполнить обязательные поля',
      });

      return;
    }

    this.disableForm();

    this.role ? this.updateRole(this.getRoleUpdateInput()) : this.createRole(this.getRoleCreateInput());
  }

  getRoleCreateInput(): CreateRoleInput {
    const { controls } = this.form;
    const { name, analytics, sales, catalog, marketing, warehouse, employees, settings, pos } = controls;

    const scopes = ['profile'];

    if (pos.value) {
      scopes.push('pos');
    }

    if (analytics.value) {
      scopes.push('analytics');
    }

    if (!!sales.value) {
      scopes.push('sales');
    }

    if (catalog.value) {
      scopes.push(catalog.value === 'read' ? 'catalog.read' : 'catalog');
    }

    if (marketing.value) {
      scopes.push(marketing.value === 'read' ? 'marketing.read' : 'marketing');
    }

    if (warehouse.value) {
      scopes.push(warehouse.value === 'read' ? 'warehouse.read' : 'warehouse');
    }

    if (employees.value) {
      scopes.push(employees.value === 'read' ? 'employees.read' : 'employees');
    }

    if (settings.value) {
      scopes.push(settings.value === 'read' ? 'settings.read' : 'settings');
    }

    return {
      name: name.value,
      scopes,
    };
  }

  getRoleUpdateInput(): UpdateRoleInput {
    return {
      id: this.role?.id || '',
      ...this.getRoleCreateInput(),
    };
  }

  disableForm(): void {
    this.form.disable();
    this.isSubmitDisabled$.next(true);
  }

  enableForm(): void {
    this.form.enable();
    this.isSubmitDisabled$.next(false);
  }

  createRole(input: CreateRoleInput) {
    this.roleStorage.createRole({ input }).subscribe(
      () => this.back(false),
      () => this.enableForm(),
    );
  }

  updateRole(input: UpdateRoleInput) {
    this.roleStorage.updateRole({ input }).subscribe(
      (res) => {
        if (this.sidenavMode) {
          this.tableSidenavService.closeTableSidenav();
          this.updateRoleInTable.next(res?.data?.updateRole.updatedRole as Role);
          this.formConfirmSaveService.closeForm(false);
        } else {
          this.back(false);
        }
      },
      () => this.enableForm(),
    );
  }

  showExitModal() {
    this.formConfirmSaveService
      .closeForms(true)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.tableSidenavService.closeTableSidenav();
          this.formConfirmSaveService.forms = [];
        }
      });
  }
}
