<div class="root">
  <ng-container *ngIf="infiniteLoaderService.visibleItems$ | async as organizations">
    <ng-container *ngIf="(organizationsLoading$ | async) === false">
      <ng-container *ngFor="let organization of organizations">
        <nm-organization-item
          [organization]="organization"
          [testId]="'organizations::item_' + organization.shortId"
          (click)="switchOrganization(organization)"
        ></nm-organization-item>
        <nm-divider></nm-divider>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="organizationsLoading$ | async">
      <div class="loading">
        <nm-progress-spinner
          mode="indeterminate"
          [diameter]="24"
          class="organizations-loading"
        ></nm-progress-spinner></div
    ></ng-container>
    <ng-container *ngIf="infiniteLoaderService.state$ | async as state">
      <div
        *ngIf="!state.reloading && state.canLoad"
        nmScrollVisibility
        [threshold]="0.5"
        (visible)="onLoadMore()"
        class="add-loading"
      >
        <nm-progress-spinner mode="indeterminate" [diameter]="24"></nm-progress-spinner>
      </div>
    </ng-container>
  </ng-container>
</div>

<nm-invitation></nm-invitation>
