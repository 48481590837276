import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouteParamsStorage {
  #routeParams = new BehaviorSubject<Record<string, string>>({});
  routeParams = this.#routeParams.asObservable();

  constructor() {}

  setRouteParams(params: Record<string, string>): void {
    this.#routeParams.next(params);
  }
}
