<div class="preloader">
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.8891 2.39799L41.6995 9.33885C42.9405 10.0682 43.8555 10.6062 44.5463 11.1103C45.2326 11.6109 45.6816 12.0669 45.9974 12.6237C46.3133 13.1807 46.477 13.8052 46.5599 14.6614C46.6433 15.523 46.6436 16.5991 46.6436 18.0577V31.9394C46.6436 33.3979 46.6433 34.474 46.5599 35.3356C46.477 36.1919 46.3133 36.8164 45.9974 37.3734C45.6816 37.9302 45.2326 38.3862 44.5463 38.8868C43.8555 39.3908 42.9405 39.9289 41.6995 40.6582L29.8891 47.5991C28.6481 48.3284 27.7329 48.8659 26.9587 49.2229C26.1896 49.5776 25.5782 49.7449 24.9484 49.7449C24.3186 49.7449 23.7071 49.5776 22.938 49.2229C22.1639 48.8659 21.2487 48.3284 20.0076 47.5991L8.19728 40.6582C6.95622 39.9289 6.04129 39.3908 5.35043 38.8868C4.66419 38.3862 4.21517 37.9302 3.89937 37.3734C3.58344 36.8164 3.41977 36.1919 3.33685 35.3356C3.25342 34.474 3.25314 33.3979 3.25314 31.9394V18.0577C3.25314 16.5991 3.25342 15.523 3.33685 14.6614C3.41977 13.8052 3.58344 13.1807 3.89937 12.6237C4.21517 12.0669 4.66419 11.6109 5.35043 11.1103C6.04129 10.6062 6.95622 10.0682 8.19728 9.33885L20.0076 2.39799C21.2487 1.66861 22.1639 1.13111 22.938 0.774142C23.7071 0.419506 24.3186 0.252167 24.9484 0.252167C25.5782 0.252167 26.1896 0.419506 26.9587 0.774142C27.7329 1.13111 28.6481 1.66861 29.8891 2.39799Z"
      fill="#171A23"
      stroke="url(#paint0_linear)"
      stroke-width="0.504333"
    />
    <mask id="mask0" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="3" y="0" width="44" height="50">
      <path
        d="M19.8789 2.18059C22.3525 0.726864 23.5893 0 24.9474 0C26.3055 0 27.5423 0.726864 30.0159 2.18059L41.8263 9.12144C44.2999 10.5752 45.5367 11.302 46.2157 12.4993C46.8948 13.6965 46.8948 15.1502 46.8948 18.0577V31.9394C46.8948 34.8468 46.8948 36.3006 46.2157 37.4978C45.5367 38.695 44.2999 39.4219 41.8263 40.8756L30.0159 47.8165C27.5423 49.2702 26.3055 49.9971 24.9474 49.9971C23.5893 49.9971 22.3525 49.2702 19.8789 47.8165L8.06853 40.8756C5.59492 39.4219 4.35811 38.695 3.67905 37.4978C3 36.3006 3 34.8468 3 31.9394V18.0577C3 15.1502 3 13.6965 3.67905 12.4993C4.35811 11.302 5.59492 10.5752 8.06853 9.12144L19.8789 2.18059Z"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0)">
      <g style="mix-blend-mode: multiply" filter="url(#filter0_f)">
        <path
          d="M29.1982 3.57197L41.0086 10.5128C42.2726 11.2557 43.119 11.7552 43.7428 12.2103C44.337 12.6438 44.6243 12.9646 44.812 13.2955C45.0005 13.6279 45.1312 14.0459 45.2036 14.7927C45.2792 15.5736 45.2809 16.5731 45.2809 18.0577V31.9394C45.2809 33.424 45.2792 34.4234 45.2036 35.2044C45.1312 35.9511 45.0005 36.3692 44.812 36.7016C44.6243 37.0325 44.337 37.3532 43.7428 37.7867C43.119 38.2418 42.2726 38.7414 41.0086 39.4842L29.1982 46.4251C27.934 47.1681 27.086 47.6643 26.3876 47.9863C25.7215 48.2935 25.3111 48.3832 24.9474 48.3832C24.5837 48.3832 24.1733 48.2935 23.5072 47.9863C22.8088 47.6643 21.9608 47.1681 20.6966 46.4251L8.88624 39.4842C7.62225 38.7414 6.77577 38.2418 6.15201 37.7867C5.55778 37.3532 5.27052 37.0325 5.08284 36.7016C4.89431 36.3692 4.76355 35.9511 4.69123 35.2044C4.61561 34.4234 4.61387 33.424 4.61387 31.9394V18.0577C4.61387 16.5731 4.61561 15.5736 4.69123 14.7927C4.76355 14.0459 4.89431 13.6279 5.08284 13.2955C5.27052 12.9646 5.55778 12.6438 6.15201 12.2103C6.77577 11.7552 7.62224 11.2557 8.88624 10.5128L20.6966 3.57197C21.9608 2.829 22.8088 2.33278 23.5072 2.01071C24.1733 1.70357 24.5837 1.61387 24.9474 1.61387C25.3111 1.61387 25.7215 1.70357 26.3876 2.01071C27.086 2.33278 27.934 2.829 29.1982 3.57197Z"
          stroke="#22242B"
          stroke-opacity="0.25"
          stroke-width="3.22773"
        />
      </g>
    </g>
    <mask id="mask1" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="3" y="0" width="44" height="50">
      <path
        d="M19.9843 2.18059C22.4579 0.726864 23.6948 0 25.0529 0C26.411 0 27.6478 0.726864 30.1214 2.18059L41.9317 9.12144C44.4053 10.5752 45.6422 11.302 46.3212 12.4993C47.0003 13.6965 47.0003 15.1502 47.0003 18.0577V31.9394C47.0003 34.8468 47.0003 36.3006 46.3212 37.4978C45.6422 38.695 44.4053 39.4219 41.9317 40.8756L30.1214 47.8165C27.6478 49.2702 26.411 49.9971 25.0529 49.9971C23.6948 49.9971 22.4579 49.2702 19.9843 47.8165L8.174 40.8756C5.70039 39.4219 4.46358 38.695 3.78452 37.4978C3.10547 36.3006 3.10547 34.8468 3.10547 31.9394V18.0577C3.10547 15.1502 3.10547 13.6965 3.78452 12.4993C4.46358 11.302 5.70039 10.5752 8.174 9.12144L19.9843 2.18059Z"
        fill="#22242B"
      />
    </mask>
    <g mask="url(#mask1)">
      <path
        d="M0.716005 40.6159C4.90531 47.9965 14.1836 50.5252 21.4397 46.2641C28.6958 42.0029 31.1819 32.5655 26.9926 25.1849C22.8033 17.8044 13.525 15.2756 6.26891 19.5368C-0.987184 23.7979 -3.4733 33.2354 0.716005 40.6159Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M0.716005 40.6159C4.90531 47.9965 14.1836 50.5252 21.4397 46.2641C28.6958 42.0029 31.1819 32.5655 26.9926 25.1849C22.8033 17.8044 13.525 15.2756 6.26891 19.5368C-0.987184 23.7979 -3.4733 33.2354 0.716005 40.6159Z"
        fill="url(#paint2_linear)"
      />
    </g>
    <path
      d="M25.0961 31.9972C28.9062 31.9972 31.9949 28.8555 31.9949 24.98C31.9949 21.1046 28.9062 17.9629 25.0961 17.9629C21.286 17.9629 18.1973 21.1046 18.1973 24.98C18.1973 28.8555 21.286 31.9972 25.0961 31.9972Z"
      fill="#F3F5FF"
    />
    <path
      d="M25.0961 31.9972C28.9062 31.9972 31.9949 28.8555 31.9949 24.98C31.9949 21.1046 28.9062 17.9629 25.0961 17.9629C21.286 17.9629 18.1973 21.1046 18.1973 24.98C18.1973 28.8555 21.286 31.9972 25.0961 31.9972Z"
      fill="url(#paint3_linear)"
      fill-opacity="0.2"
    />
    <path
      d="M29.8891 2.40092L41.6995 9.34178C42.9405 10.0711 43.8555 10.6092 44.5463 11.1132C45.2326 11.6138 45.6816 12.0698 45.9974 12.6266C46.3133 13.1836 46.477 13.8081 46.5599 14.6644C46.6433 15.526 46.6436 16.6021 46.6436 18.0606V31.9423C46.6436 33.4009 46.6433 34.477 46.5599 35.3385C46.477 36.1948 46.3133 36.8193 45.9974 37.3763C45.6816 37.9331 45.2326 38.3891 44.5463 38.8897C43.8555 39.3938 42.9405 39.9318 41.6995 40.6611L29.8891 47.602C28.6481 48.3314 27.7329 48.8689 26.9587 49.2258C26.1896 49.5805 25.5782 49.7478 24.9484 49.7478C24.3186 49.7478 23.7071 49.5805 22.938 49.2258C22.1639 48.8689 21.2487 48.3314 20.0076 47.602L8.19728 40.6611C6.95622 39.9318 6.04129 39.3938 5.35043 38.8897C4.66419 38.3891 4.21517 37.9331 3.89937 37.3763C3.58344 36.8193 3.41977 36.1948 3.33685 35.3385C3.25342 34.477 3.25314 33.4009 3.25314 31.9423V18.0606C3.25314 16.6021 3.25342 15.526 3.33685 14.6644C3.41977 13.8081 3.58344 13.1836 3.89937 12.6266C4.21517 12.0698 4.66419 11.6138 5.35043 11.1132C6.04129 10.6092 6.95622 10.0711 8.19728 9.34178L20.0076 2.40092C21.2487 1.67154 22.1639 1.13404 22.938 0.777072C23.7071 0.422435 24.3186 0.255096 24.9484 0.255096C25.5782 0.255096 26.1896 0.422435 26.9587 0.777072C27.7329 1.13404 28.6481 1.67154 29.8891 2.40092Z"
      stroke="url(#paint4_linear)"
      stroke-width="0.504333"
    />
    <defs>
      <filter
        id="filter0_f"
        x="-1.43813"
        y="-4.43813"
        width="52.7708"
        height="58.8733"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2.21907" result="effect1_foregroundBlur" />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="-9.53671"
        y1="58.1736"
        x2="55.7676"
        y2="16.3716"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.250712" stop-color="#22242B" />
        <stop offset="0.881919" stop-color="#383E4E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="4.55022"
        y1="45.3403"
        x2="20.9966"
        y2="14.8723"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1313" stop-color="#3474E4" />
        <stop offset="0.843987" stop-color="#65A0F2" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="5.2487"
        y1="30.5385"
        x2="24.7464"
        y2="30.2973"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#224DCF" stop-opacity="0.65" />
        <stop offset="0.174241" stop-color="#1F67DA" stop-opacity="0.541667" />
        <stop offset="1" stop-color="#1B85E8" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="27.6732"
        y1="35.0763"
        x2="23.6826"
        y2="25.752"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.11383" stop-color="#0039FF" />
        <stop offset="1" stop-color="#0039FF" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="-9.53671"
        y1="58.1766"
        x2="55.7676"
        y2="16.3745"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.250712" stop-color="#22242B" />
        <stop offset="0.881919" stop-color="#383E4E" />
      </linearGradient>
    </defs>
  </svg>
</div>
