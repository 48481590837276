import { Injectable } from '@angular/core';

import {
  mutationAcceptEmployeeInvite,
  mutationAcceptOrganizationInvitation,
  mutationCreateOrganizationInvitation,
  mutationInviteEmployee,
  mutationRemoveOrganizationInvitation,
  mutationUpdateOrganizationInvitation,
  queryAllOrganizationInvitations,
  queryInvitationInfo,
  queryOrganizationInvitation,
} from '@api';
import { ApiService } from '@core/service';
import { CoreSchema, Mutation, MutationResult, Query, QueryResult } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class InvitationApiService {
  constructor(private api: ApiService) {}

  createOrganizationInvitation(
    variables: CoreSchema.MutationCreateOrganizationInvitationArgs,
  ): MutationResult<'createOrganizationInvitation'> {
    return this.api.mutate<Mutation<'createOrganizationInvitation'>, CoreSchema.MutationCreateOrganizationInvitationArgs>({
      mutation: mutationCreateOrganizationInvitation,
      variables,
    });
  }

  updateOrganizationInvitation(variables: CoreSchema.MutationUpdateEmployeeInvitationArgs): MutationResult<'updateOrganizationInvitation'> {
    return this.api.mutate<Mutation<'updateOrganizationInvitation'>, CoreSchema.MutationUpdateEmployeeInvitationArgs>({
      mutation: mutationUpdateOrganizationInvitation,
      variables,
    });
  }

  removeOrganizationInvitation(
    variables: CoreSchema.MutationRemoveOrganizationInvitationArgs,
  ): MutationResult<'removeOrganizationInvitation'> {
    return this.api.mutate<Mutation<'removeOrganizationInvitation'>, CoreSchema.MutationRemoveOrganizationInvitationArgs>({
      mutation: mutationRemoveOrganizationInvitation,
      variables,
    });
  }

  getOrganizationInvitation(variables: CoreSchema.QueryOrganizationInvitationArgs): QueryResult<'organizationInvitation'> {
    return this.api.watch<Query<'organizationInvitation'>, CoreSchema.QueryOrganizationInvitationArgs>({
      query: queryOrganizationInvitation,
      variables,
    }).valueChanges;
  }

  getAllOrganizationInvitations(): QueryResult<'allOrganizationInvitations'> {
    return this.api.watch<Query<'allOrganizationInvitations'>, {}>({
      query: queryAllOrganizationInvitations,
    }).valueChanges;
  }

  acceptOrganizationInvitation(
    variables: CoreSchema.MutationAcceptOrganizationInvitationArgs,
  ): MutationResult<'acceptOrganizationInvitation'> {
    return this.api.mutate<Mutation<'acceptOrganizationInvitation'>, CoreSchema.MutationAcceptOrganizationInvitationArgs>({
      mutation: mutationAcceptOrganizationInvitation,
      variables,
    });
  }

  acceptEmployeeInvitation(variables: CoreSchema.MutationAcceptEmployeeInviteArgs): MutationResult<'acceptEmployeeInvite'> {
    return this.api.mutate<Mutation<'acceptEmployeeInvite'>, CoreSchema.MutationAcceptEmployeeInviteArgs>({
      mutation: mutationAcceptEmployeeInvite,
      variables,
    });
  }

  getInvitationInfo(variables: CoreSchema.QueryGetInvitationArgs): QueryResult<'getInvitation'> {
    return this.api.query<Query<'getInvitation'>, CoreSchema.QueryGetInvitationArgs>({
      query: queryInvitationInfo,
      variables,
    });
  }

  createEmployeeInvitation(variables: CoreSchema.MutationInviteEmployeeArgs): MutationResult<'createEmployeeInvitation'> {
    return this.api.mutate<Mutation<'createEmployeeInvitation'>, CoreSchema.MutationInviteEmployeeArgs>({
      mutation: mutationInviteEmployee,
      variables,
    });
  }
}
