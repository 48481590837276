import { Injectable } from '@angular/core';

import {
  changeMenuSectionPosition,
  getPrimePriceAndImageForMenuItem,
  mutationAddMenuRc,
  mutationAddMenuStructure,
  mutationChangeMenuItemRcPosition,
  mutationChangeSectionRcPosition,
  mutationCreateItemsRcList,
  mutationCreateMenu,
  mutationCreateMenuItem,
  mutationCreateMenuItemsList,
  mutationCreateMenuRc,
  mutationCreateMenuSection,
  mutationCreateMenuSectionRc,
  mutationCreateMenuWithStructure,
  mutationDeleteMenu,
  mutationDeleteMenuItem,
  mutationDeleteMenuItemRc,
  mutationDeleteMenuRc,
  mutationDeleteMenuSection,
  mutationDeleteMenuSectionRc,
  mutationDuplicateMenu,
  mutationDuplicateMenuRc,
  mutationMoveElements,
  mutationOnlineMenuSectionSetImage,
  mutationOnlineMenuSetActiveState,
  mutationUpdateMenu,
  mutationUpdateMenuItem,
  mutationUpdateMenuItemRc,
  mutationUpdateMenuItemsList,
  mutationUpdateMenuRc,
  mutationUpdateMenuSection,
  mutationUpdateMenuSectionRc,
  mutationUpdateMenuSectionsList,
  queryAllInMenu,
  queryAllInMenuPageable,
  queryAllMenuItemsBySection,
  queryAllMenus,
  queryAllMenusByStoreIdsPageable,
  queryChildrenShort,
  queryMenu,
  queryMenuElement,
  queryMenuElementShort,
  queryMenuElementsShort,
  queryMenuItem,
  queryMenuRc,
  queryMenuRcImmediateDescendants,
  queryMenuRcRootElements,
  queryMenuSection,
  queryMenuSectionsBySection,
  updateMenuSectionV2Patch,
} from '@api';
import { ApiService } from '@services/core';
import {
  Mutation,
  MutationAddMenuRcArgs,
  MutationAddMenuStructureArgs,
  MutationChangeItemRcPositionArgs,
  MutationChangeMenuSectionPositionArgs,
  MutationChangeSectionRcPositionArgs,
  MutationCreateItemsRcListArgs,
  MutationCreateMenuArgs,
  MutationCreateMenuItemArgs,
  MutationCreateMenuItemsListArgs,
  MutationCreateMenuRcArgs,
  MutationCreateMenuSectionArgs,
  MutationCreateMenuStructureArgs,
  MutationCreateSectionRcArgs,
  MutationDeleteItemRcArgs,
  MutationDeleteMenuArgs,
  MutationDeleteMenuItemArgs,
  MutationDeleteMenuRcArgs,
  MutationDeleteMenuSectionArgs,
  MutationDeleteSectionRcArgs,
  MutationDuplicateMenuArgs,
  MutationDuplicateMenuRcArgs,
  MutationMoveElementsArgs,
  MutationOnlineMenuSectionSetImageArgs,
  MutationOnlineMenuSetActiveStateArgs,
  MutationResult,
  MutationUpdateItemRcArgs,
  MutationUpdateMenuArgs,
  MutationUpdateMenuItemArgs,
  MutationUpdateMenuItemsListArgs,
  MutationUpdateMenuRcArgs,
  MutationUpdateMenuSectionArgs,
  MutationUpdateMenuSectionsListArgs,
  MutationUpdateMenuSectionV2PatchArgs,
  MutationUpdateSectionRcArgs,
  Query,
  QueryAllMenuItemsBySectionArgs,
  QueryAllMenusArgs,
  QueryAllMenusByStoreIdsPageableArgs,
  QueryAllRcMenusByStoreIdsPageableArgs,
  QueryFindMenuElementsArgs,
  QueryMenuArgs,
  QueryMenuItemArgs,
  QueryMenuRcArgs,
  QueryMenuRcElementArgs,
  QueryMenuRcElementsArgs,
  QueryMenuRcImmediateDescendantsArgs,
  QueryMenuRcRootElementsArgs,
  QueryMenuSectionArgs,
  QueryMenuSectionsBySectionArgs,
  QueryResult,
  QueryStockUnitsArgs,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class MenuApi {
  constructor(private apiService: ApiService) {}
  //Rc menu
  getAllMenusRc(variables: QueryAllRcMenusByStoreIdsPageableArgs): QueryResult<'menusRc'> {
    return this.apiService.query<Query<'menusRc'>, QueryAllRcMenusByStoreIdsPageableArgs>({
      query: queryAllMenusByStoreIdsPageable,
      variables,
    });
  }

  getMenuRc(variables: QueryMenuRcArgs): QueryResult<'menuRc'> {
    return this.apiService.query<Query<'menuRc'>, QueryMenuRcArgs>({
      query: queryMenuRc,
      variables,
    });
  }

  getAllInMenuPageable(variables: QueryMenuRcElementsArgs): QueryResult<'menuRcElements'> {
    return this.apiService.query<Query<'menuRcElements'>, QueryMenuRcElementsArgs>({
      query: queryAllInMenuPageable,
      variables,
    });
  }

  getMenuSectionsShort(variables: QueryMenuRcElementsArgs): QueryResult<'menuRcElements'> {
    return this.apiService.query<Query<'menuRcElements'>, QueryMenuRcElementsArgs>({
      query: queryMenuElementsShort,
      variables,
    });
  }

  createRcMenu(variables: MutationCreateMenuRcArgs): MutationResult<'createMenuRc'> {
    return this.apiService.mutate<Mutation<'createMenuRc'>, MutationCreateMenuRcArgs>({
      mutation: mutationCreateMenuRc,
      variables,
    });
  }

  getAllMenus(variables: QueryAllMenusByStoreIdsPageableArgs): QueryResult<'allMenusByStoreIdsPageable'> {
    return this.apiService.query<Query<'allMenusByStoreIdsPageable'>, QueryAllMenusByStoreIdsPageableArgs>({
      query: queryAllMenusByStoreIdsPageable,
      variables,
    });
  }

  getAllMenusByStore(variables: QueryAllMenusArgs): QueryResult<'allMenus'> {
    return this.apiService.query<Query<'allMenus'>, QueryAllMenusArgs>({
      query: queryAllMenus,
      variables,
    });
  }

  addMenuRcStructure(variables: MutationAddMenuRcArgs): MutationResult<'addMenuRc'> {
    return this.apiService.mutate<Mutation<'addMenuRc'>, MutationAddMenuRcArgs>({
      mutation: mutationAddMenuRc,
      variables,
    });
  }
  deleteMenuRc(variables: MutationDeleteMenuRcArgs): MutationResult<'deleteMenuRc'> {
    return this.apiService.mutate<Mutation<'deleteMenuRc'>, MutationDeleteMenuRcArgs>({
      mutation: mutationDeleteMenuRc,
      variables,
    });
  }
  updateMenuRc(variables: MutationUpdateMenuRcArgs): MutationResult<'updateMenuRc'> {
    return this.apiService.mutate<Mutation<'updateMenuRc'>, MutationUpdateMenuRcArgs>({
      mutation: mutationUpdateMenuRc,
      variables,
    });
  }
  duplicateMenuRc(variables: MutationDuplicateMenuRcArgs): MutationResult<'duplicateMenuRc'> {
    return this.apiService.mutate<Mutation<'duplicateMenuRc'>, MutationDuplicateMenuRcArgs>({
      mutation: mutationDuplicateMenuRc,
      variables,
    });
  }
  createMenuSectionRc(variables: MutationCreateSectionRcArgs): MutationResult<'createSectionRc'> {
    return this.apiService.mutate<Mutation<'createSectionRc'>, MutationCreateSectionRcArgs>({
      mutation: mutationCreateMenuSectionRc,
      variables,
    });
  }
  updateMenuSectionRc(variables: MutationUpdateSectionRcArgs): MutationResult<'updateSectionRc'> {
    return this.apiService.mutate<Mutation<'updateSectionRc'>, MutationUpdateSectionRcArgs>({
      mutation: mutationUpdateMenuSectionRc,
      variables,
    });
  }
  deleteMenuSectionRc(variables: MutationDeleteSectionRcArgs): MutationResult<'deleteSectionRc'> {
    return this.apiService.mutate<Mutation<'deleteSectionRc'>, MutationDeleteSectionRcArgs>({
      mutation: mutationDeleteMenuSectionRc,
      variables,
    });
  }

  moveMenuElements(variables: MutationMoveElementsArgs): MutationResult<'moveElements'> {
    return this.apiService.mutate<Mutation<'moveElements'>, MutationMoveElementsArgs>({
      mutation: mutationMoveElements,
      variables,
    });
  }
  changeMenuSectionRcPosition(variables: MutationChangeSectionRcPositionArgs): MutationResult<'changeSectionRcPosition'> {
    return this.apiService.mutate<Mutation<'changeSectionRcPosition'>, MutationChangeSectionRcPositionArgs>({
      mutation: mutationChangeSectionRcPosition,
      variables,
    });
  }
  createMenuItemsRcList(variables: MutationCreateItemsRcListArgs): MutationResult<'createItemsRcList'> {
    return this.apiService.mutate<Mutation<'createItemsRcList'>, MutationCreateItemsRcListArgs>({
      mutation: mutationCreateItemsRcList,
      variables,
    });
  }
  updateMenuItemRc(variables: MutationUpdateItemRcArgs): MutationResult<'updateItemRc'> {
    return this.apiService.mutate<Mutation<'updateItemRc'>, MutationUpdateItemRcArgs>({
      mutation: mutationUpdateMenuItemRc,
      variables,
    });
  }
  deleteMenuItemRc(variables: MutationDeleteItemRcArgs): MutationResult<'deleteItemRc'> {
    return this.apiService.mutate<Mutation<'deleteItemRc'>, MutationDeleteItemRcArgs>({
      mutation: mutationDeleteMenuItemRc,
      variables,
    });
  }
  changeMenuItemRcPosition(variables: MutationChangeItemRcPositionArgs): MutationResult<'changeItemRcPosition'> {
    return this.apiService.mutate<Mutation<'changeItemRcPosition'>, MutationChangeItemRcPositionArgs>({
      mutation: mutationChangeMenuItemRcPosition,
      variables,
    });
  }

  getMenuElementShort(variables: QueryMenuRcElementArgs): QueryResult<'menuRcElement'> {
    return this.apiService.query<Query<'menuRcElement'>, QueryMenuRcElementArgs>({
      query: queryMenuElementShort,
      variables,
    });
  }
  getMenuElement(variables: QueryMenuRcElementArgs): QueryResult<'menuRcElement'> {
    return this.apiService.query<Query<'menuRcElement'>, QueryMenuRcElementArgs>({
      query: queryMenuElement,
      variables,
    });
  }
  getMenuRcRootElements(variables: QueryMenuRcRootElementsArgs): QueryResult<'menuRcRootElements'> {
    return this.apiService.query<Query<'menuRcRootElements'>, QueryMenuRcRootElementsArgs>({
      query: queryMenuRcRootElements,
      variables,
    });
  }

  getMenuRcImmediateDescendants(variables: QueryMenuRcImmediateDescendantsArgs): QueryResult<'menuRcImmediateDescendants'> {
    return this.apiService.query<Query<'menuRcImmediateDescendants'>, QueryMenuRcImmediateDescendantsArgs>({
      query: queryMenuRcImmediateDescendants,
      variables,
    });
  }

  getChildrenShort(variables: QueryMenuRcImmediateDescendantsArgs): QueryResult<'menuRcImmediateDescendants'> {
    return this.apiService.query<Query<'menuRcImmediateDescendants'>, QueryMenuRcImmediateDescendantsArgs>({
      query: queryChildrenShort,
      variables,
    });
  }

  getPrimePriceAndImageForMenuItem(variables?: QueryStockUnitsArgs): QueryResult<'stockUnits'> {
    return this.apiService.query<Query<'stockUnits'>, QueryStockUnitsArgs>({
      query: getPrimePriceAndImageForMenuItem,
      variables,
    });
  }
  // old menu module

  getMenu(variables: QueryMenuArgs): QueryResult<'menu'> {
    return this.apiService.query<Query<'menu'>, QueryMenuArgs>({
      query: queryMenu,
      variables,
    });
  }

  getAllInMenu(variables: QueryFindMenuElementsArgs): QueryResult<'findMenuElements'> {
    return this.apiService.query<Query<'findMenuElements'>, QueryFindMenuElementsArgs>({ query: queryAllInMenu, variables });
  }

  createMenu(variables: MutationCreateMenuArgs): MutationResult<'createMenu'> {
    return this.apiService.mutate<Mutation<'createMenu'>, MutationCreateMenuArgs>({
      mutation: mutationCreateMenu,
      variables,
    });
  }
  createMenuWithStructure(variables: MutationCreateMenuStructureArgs): MutationResult<'createMenuStructure'> {
    return this.apiService.mutate<Mutation<'createMenuStructure'>, MutationCreateMenuStructureArgs>({
      mutation: mutationCreateMenuWithStructure,
      variables,
    });
  }

  addMenuStructure(variables: MutationAddMenuStructureArgs): MutationResult<'addMenuStructure'> {
    return this.apiService.mutate<Mutation<'addMenuStructure'>, MutationAddMenuStructureArgs>({
      mutation: mutationAddMenuStructure,
      variables,
    });
  }

  deleteMenu(variables: MutationDeleteMenuArgs): MutationResult<'deleteMenu'> {
    return this.apiService.mutate<Mutation<'deleteMenu'>, MutationDeleteMenuArgs>({
      mutation: mutationDeleteMenu,
      variables,
    });
  }

  updateMenu(variables: MutationUpdateMenuArgs): MutationResult<'updateMenu'> {
    return this.apiService.mutate<Mutation<'updateMenu'>, MutationUpdateMenuArgs>({
      mutation: mutationUpdateMenu,
      variables,
    });
  }

  duplicateMenu(variables: MutationDuplicateMenuArgs): MutationResult<'duplicateMenu'> {
    return this.apiService.mutate<Mutation<'duplicateMenu'>, MutationDuplicateMenuArgs>({
      mutation: mutationDuplicateMenu,
      variables,
    });
  }

  createMenuSection(variables: MutationCreateMenuSectionArgs): MutationResult<'createMenuSection'> {
    return this.apiService.mutate<Mutation<'createMenuSection'>, MutationCreateMenuSectionArgs>({
      mutation: mutationCreateMenuSection,
      variables,
    });
  }

  createMenuItem(variables: MutationCreateMenuItemArgs): MutationResult<'createMenuItem'> {
    return this.apiService.mutate<Mutation<'createMenuItem'>, MutationCreateMenuItemArgs>({
      mutation: mutationCreateMenuItem,
      variables,
    });
  }

  createMenuItemsList(variables: MutationCreateMenuItemsListArgs): MutationResult<'createMenuItemsList'> {
    return this.apiService.mutate<Mutation<'createMenuItemsList'>, MutationCreateMenuItemsListArgs>({
      mutation: mutationCreateMenuItemsList,
      variables,
    });
  }

  deleteMenuItem(variables: MutationDeleteMenuItemArgs): MutationResult<'deleteMenuItem'> {
    return this.apiService.mutate<Mutation<'deleteMenuItem'>, MutationDeleteMenuItemArgs>({
      mutation: mutationDeleteMenuItem,
      variables,
    });
  }

  deleteMenuSection(variables: MutationDeleteMenuSectionArgs): MutationResult<'deleteMenuSection'> {
    return this.apiService.mutate<Mutation<'deleteMenuSection'>, MutationDeleteMenuSectionArgs>({
      mutation: mutationDeleteMenuSection,
      variables,
    });
  }

  updateMenuItem(variables: MutationUpdateMenuItemArgs): MutationResult<'updateMenuItem'> {
    return this.apiService.mutate<Mutation<'updateMenuItem'>, MutationUpdateMenuItemArgs>({
      mutation: mutationUpdateMenuItem,
      variables,
    });
  }

  updateMenuSection(variables: MutationUpdateMenuSectionArgs): MutationResult<'updateMenuSection'> {
    return this.apiService.mutate<Mutation<'updateMenuSection'>, MutationUpdateMenuSectionArgs>({
      mutation: mutationUpdateMenuSection,
      variables,
    });
  }

  patchMenuSection(variables: MutationUpdateMenuSectionV2PatchArgs): MutationResult<'updateMenuSectionV2Patch'> {
    return this.apiService.mutate<Mutation<'updateMenuSectionV2Patch'>, MutationUpdateMenuSectionV2PatchArgs>({
      mutation: updateMenuSectionV2Patch,
      variables,
    });
  }

  changeMenuSectionPosition(variables: MutationChangeMenuSectionPositionArgs): MutationResult<'changeMenuSectionPosition'> {
    return this.apiService.mutate<Mutation<'changeMenuSectionPosition'>, MutationChangeMenuSectionPositionArgs>({
      mutation: changeMenuSectionPosition,
      variables,
    });
  }

  getMenuSection(variables: QueryMenuSectionArgs): QueryResult<'menuSection'> {
    return this.apiService.query<Query<'menuSection'>, QueryMenuSectionArgs>({
      query: queryMenuSection,
      variables,
    });
  }

  getMenuItem(variables: QueryMenuItemArgs): QueryResult<'menuItem'> {
    return this.apiService.query<Query<'menuItem'>, QueryMenuItemArgs>({
      query: queryMenuItem,
      variables,
    });
  }

  getMenuItemsBySection(variables: QueryAllMenuItemsBySectionArgs): QueryResult<'allMenuItemsBySection'> {
    return this.apiService.query<Query<'allMenuItemsBySection'>, QueryAllMenuItemsBySectionArgs>({
      query: queryAllMenuItemsBySection,
      variables,
    });
  }

  getMenuSectionsBySection(variables: QueryMenuSectionsBySectionArgs): QueryResult<'menuSectionsBySection'> {
    return this.apiService.query<Query<'menuSectionsBySection'>, QueryMenuSectionsBySectionArgs>({
      query: queryMenuSectionsBySection,
      variables,
    });
  }

  updateMenuItemsList(variables: MutationUpdateMenuItemsListArgs): MutationResult<'updateMenuItemsList'> {
    return this.apiService.mutate<Mutation<'updateMenuItemsList'>, MutationUpdateMenuItemsListArgs>({
      mutation: mutationUpdateMenuItemsList,
      variables,
    });
  }

  updateMenuSectionsList(variables: MutationUpdateMenuSectionsListArgs): MutationResult<'updateMenuSectionsList'> {
    return this.apiService.mutate<Mutation<'updateMenuSectionsList'>, MutationUpdateMenuSectionsListArgs>({
      mutation: mutationUpdateMenuSectionsList,
      variables,
    });
  }

  onlineMenuSetActiveState(variables: MutationOnlineMenuSetActiveStateArgs): MutationResult<'onlineMenuSetActiveState'> {
    return this.apiService.mutate<Mutation<'onlineMenuSetActiveState'>, MutationOnlineMenuSetActiveStateArgs>({
      mutation: mutationOnlineMenuSetActiveState,
      variables,
    });
  }

  onlineMenuSectionSetImage(variables: MutationOnlineMenuSectionSetImageArgs): MutationResult<'onlineMenuSectionSetImage'> {
    return this.apiService.mutate<Mutation<'onlineMenuSectionSetImage'>, MutationOnlineMenuSectionSetImageArgs>({
      mutation: mutationOnlineMenuSectionSetImage,
      variables,
    });
  }
}
