import { BehaviorSubject, map, Observable, Subject, take, throwError } from 'rxjs';

import { CatalogSection } from '@typings';

import { AutocompleteOption, UpdateSelected } from '../component/autocomplete/autocomplete.model';

const ROOT_NODE_ID = Symbol('rootNodeId');

type NodeId = string | typeof ROOT_NODE_ID;

interface RootNode {
  id: typeof ROOT_NODE_ID;
  depthLevel: -1;
  isParent: true;
  parent: undefined;
  isExpanded: true;
  isSelected: boolean;
  isIndeterminate: boolean;
  isLoading: boolean;
}

interface Node<T> {
  id: string;
  depthLevel: number;
  isParent: boolean;
  parent: NodeId;
  isExpanded: boolean;
  isSelected: boolean;
  isIndeterminate: boolean;
  isLoading: boolean;
  data: T;
}

type InternalNode<T> = Node<T> | RootNode;

export type TreeNode<T> = Omit<Node<T>, 'parent'> & { parent?: string };

type FetchOmitKeys = 'depthLevel' | 'parent' | 'isExpanded' | 'isSelected' | 'isIndeterminate' | 'isLoading';
type CreateNodeOmitKeys = 'isExpanded' | 'isLoading' | 'depthLevel';

export type TreeNodeFetchResult<T> = Omit<TreeNode<T>, FetchOmitKeys>;
export type TreeNodeCreateData<T> = Omit<TreeNode<T>, CreateNodeOmitKeys>;

class NodeStorage<T> {
  nodesMap = new Map<NodeId, InternalNode<T>>();
  childrenMap = new Map<NodeId, string[]>();
}

export class TreeDataService<T = unknown> {
  #storage = new NodeStorage<T>();
  #filterStorage = new NodeStorage<T | {}>();

  #shownNodesList = new BehaviorSubject<TreeNode<T>[]>([]);
  shownNodesList$ = this.#shownNodesList.asObservable();

  #isRootLoaded = new BehaviorSubject<boolean>(false);
  isRootLoaded$ = this.#isRootLoaded.asObservable();

  updateSelected = new Subject<UpdateSelected>();
  categoriesTree = false;
  fromLoadedNodes = false;

  #selectedNodesIds = new BehaviorSubject<Set<string>>(new Set<string>());
  selectedNodesIds$ = this.#selectedNodesIds.asObservable();
  selectedNodes$: Observable<TreeNode<T>[]> = this.selectedNodesIds$.pipe(
    map((nodeIds) => {
      return Array.from(nodeIds)
        .map((id) => this.#getNodeById(id))
        .filter((node): node is Node<T> => !this.#isRootNode(node))
        .map((node) => this.#toTreeNode(node));
    }),
  );

  #filterText: string = '';

  constructor() {
    this.#initRootNode();
  }

  get #activeStorage(): NodeStorage<T> {
    if (this.#filterText) {
      return this.#filterStorage as NodeStorage<T>;
    } else {
      return this.#storage;
    }
  }

  get #nodesMap(): Map<NodeId, InternalNode<T>> {
    return this.#activeStorage.nodesMap;
  }

  get #childrenMap(): Map<NodeId, string[]> {
    return this.#activeStorage.childrenMap;
  }

  getSelectedNodesIds(): Set<string> {
    return this.#selectedNodesIds.getValue();
  }

  setSelectedNodeIds(selectedIds: string[]): void {
    if (selectedIds) {
      const selectedInTree = this.getSelectedNodesIds();

      const toSelect = selectedIds.filter((id) => !selectedInTree.has(id));
      const toDeselect = Array.from(selectedInTree).filter((id) => !selectedIds.includes(id));

      toSelect.forEach((id) => this.select(id));
      toDeselect.forEach((id) => this.deselect(id));
    }
  }

  fetchChildren: (parentId: string | undefined, maxDepth: number) => Observable<TreeNodeFetchResult<T>[]> = () => {
    return throwError(() => new Error('fetchChildren func is undefined'));
  };

  filterNode: (searchText: string) => Observable<TreeNodeFetchResult<T>[]> = () => {
    throw new Error('filterNode func is undefined');
  };

  filterNodeFromStorage: (node: TreeNode<T>, searchText: string) => boolean = () => {
    throw new Error('filterNode func is undefined');
  };

  loadRootNodes(): Promise<void> {
    return this.#loadRootNodes();
  }

  loadAllNodes(): Promise<void> {
    return this.#loadRootNodes(true);
  }

  reloadAllNodes(): Promise<void> {
    this.reset();
    return this.#loadRootNodes(true, true);
  }

  #loadRootNodes(recursive: boolean = false, reload: boolean = false): Promise<void> {
    if (this.#isRootLoaded.getValue() && !reload) {
      return new Promise((resolve) => resolve());
    }

    return this.#loadChildrenAndUpdateSelected(ROOT_NODE_ID, recursive).then(() => {
      this.#updateShownNodesList();
      this.#isRootLoaded.next(true);
    });
  }

  expand(nodeId: string, expandChildren: boolean = false): Promise<void> {
    return this.#expandNode(nodeId, expandChildren);
  }

  expandAll(): Promise<void> {
    return this.#expandNode(ROOT_NODE_ID, true);
  }

  #expandNode(nodeId: NodeId, expandChildren: boolean = false): Promise<void> {
    const node = this.#getNodeById(nodeId);

    if (!node.isParent || (node.isExpanded && !expandChildren) || node.isLoading) {
      return new Promise((resolve) => resolve());
    }

    if (node.isExpanded && expandChildren) {
      const children = this.#childrenMap.get(nodeId) || [];

      return new Promise((resolve, reject) => {
        Promise.all(children.map((chId) => this.#expandNode(chId, true))).then(
          () => resolve(),
          (error) => reject(error),
        );
      });
    }

    this.#nodesMap.set(nodeId, {
      ...node,
      isLoading: true,
    });

    this.#updateShownNodesList();
    const childrenIdsPromise =
      this.#childrenMap.has(nodeId) && this.#childrenMap.get(nodeId)!.length! > 0
        ? new Promise<string[]>((resolve) => resolve(this.#childrenMap.get(nodeId)!))
        : this.#loadChildrenAndUpdateSelected(nodeId).then((chs) =>
            chs.map((ch) => {
              return ch.id;
            }),
          );

    return childrenIdsPromise.then((chIds) => {
      this.#nodesMap.set(nodeId, {
        ...node,
        isExpanded: true,
        isLoading: false,
      });
      this.#storage.nodesMap.set(nodeId, { ...node, isExpanded: true, isLoading: false });
      this.#filterStorage.nodesMap.set(nodeId, { ...node, isExpanded: true, isLoading: false });

      this.#updateShownNodesList();

      return new Promise((resolve, reject) => {
        if (expandChildren) {
          Promise.all(chIds.map((chId) => this.#expandNode(chId, true))).then(
            () => resolve(),
            (error) => reject(error),
          );
        } else {
          resolve();
        }
      });
    });
  }

  collapseAll(): void {
    this.#collapseNode(ROOT_NODE_ID, true);
  }

  collapse(nodeId: string, collapseChildren: boolean = false): void {
    this.#collapseNode(nodeId, collapseChildren);
  }

  #collapseNode(nodeId: NodeId, collapseChildren: boolean = false): void {
    const node = this.#getNodeById(nodeId);

    if (collapseChildren) {
      const children = this.#childrenMap.get(nodeId) || [];

      children.forEach((chId) => this.#collapseNode(chId, true));
    }

    if (!node.isParent || !node.isExpanded || node.isLoading || this.#isRootNode(node)) {
      return;
    }

    this.#nodesMap.set(nodeId, {
      ...node,
      isExpanded: false,
    });

    this.#updateShownNodesList();
  }

  select(nodeId: string): void {
    this.#changeSelectedState(nodeId, true);

    const node = this.#getNodeFromStorage(nodeId, this.#storage);
    if (node.parent) {
      this.#updateCheckedState(node.parent);
    }

    if (this.#filterText) {
      this.#updateCheckedStateInFilteredTree();
    }

    this.#updateShownNodesList();
    this.#updateSelectedNodesIds();
  }

  deselect(nodeId: string) {
    this.#changeSelectedState(nodeId, false);

    const node = this.#getNodeFromStorage(nodeId, this.#storage);
    if (node.parent) {
      this.#updateCheckedState(node.parent);
    }

    if (this.#filterText) {
      this.#updateCheckedStateInFilteredTree();
    }

    this.#updateShownNodesList();
    this.#updateSelectedNodesIds();
  }

  deselectAll(): void {
    Array.from(this.#storage.nodesMap.values()).forEach((element) => {
      if (element.id !== ROOT_NODE_ID) {
        this.#storage.nodesMap.set(element.id, { ...element, isSelected: false });
      }
    });
  }

  getNode(nodeId: string): TreeNode<T> {
    const node = this.#getNodeById(nodeId);

    if (this.#isRootNode(node)) {
      throw new Error(`Unable to find node with id ${nodeId}`);
    }

    return this.#toTreeNode(node);
  }

  createNode(node: TreeNodeCreateData<T>, insertIndex: number = 0): void {
    if (this.#nodesMap.has(node.id)) {
      throw new Error(`Node with id ${node.id} is already exists`);
    }

    const parentId: NodeId = node.parent ?? ROOT_NODE_ID;

    const nodeToCreate: Node<T> = {
      ...node,
      isExpanded: false,
      isLoading: false,
      parent: '',
      depthLevel: 0,
    };

    this.#addChildToParent(parentId, nodeToCreate, insertIndex);

    this.#updateShownNodesList();
    this.#updateSelectedNodesIds();
  }

  deleteNode(nodeId: string): void {
    this.#deleteNode(nodeId);
    this.#updateShownNodesList();
    this.#updateSelectedNodesIds();
  }

  #deleteNode(nodeId: string): void {
    const node = this.#getNodeById(nodeId);

    if (this.#isRootNode(node)) {
      return;
    }

    this.#deleteChildren(nodeId);
    this.#nodesMap.delete(nodeId);
    this.#deleteNodeFromParent(nodeId, node.parent);
  }

  getTreeNodeById(nodeId: string): TreeNode<T> | null {
    const node = this.#storage.nodesMap.get(nodeId);

    if (!node || this.#isRootNode(node)) {
      return null;
    }

    return this.#toTreeNode(node);
  }

  #deleteNodeFromParent(nodeId: string, parentId: NodeId): void {
    const parent = this.#getNodeById(parentId);
    const parentChildren = this.#childrenMap.get(parentId) ?? [];
    const newParentChildren = parentChildren.filter((id) => id !== nodeId);

    if (newParentChildren.length || this.#isRootNode(parent)) {
      this.#childrenMap.set(parentId, newParentChildren);
    } else {
      this.#nodesMap.set(parentId, {
        ...parent,
        isParent: false,
        isExpanded: false,
      });
      this.#childrenMap.delete(parentId);
    }
  }

  deleteChildren(nodeId: string): void {
    this.#deleteChildren(nodeId);
    this.#updateShownNodesList();
    this.#updateSelectedNodesIds();
  }

  updateNodeData(nodeId: string, data: T): void {
    const node = this.#getNodeById(nodeId);

    if (this.#isRootNode(node)) {
      return;
    }

    this.#nodesMap.set(nodeId, {
      ...node,
      data,
    });

    this.#updateShownNodesList();
  }

  moveNode(nodeId: string, parentId: string | undefined, insertIndex: number = 0): void {
    const node = this.#getNodeById(nodeId);

    if (this.#isRootNode(node)) {
      return;
    }

    if (node.isLoading) {
      throw new Error('It is now allowed to move loading nodes');
    }

    const newParentId: NodeId = parentId ?? ROOT_NODE_ID;

    if (newParentId !== node.parent) {
      this.#deleteNodeFromParent(nodeId, node.parent);
      this.#addChildToParent(newParentId, node, insertIndex);
    } else {
      const parentChildren = this.#childrenMap.get(node.parent) || [];
      const movingNodeIndex = parentChildren.findIndex((chId) => chId === nodeId);

      if (movingNodeIndex === -1) {
        throw new Error(`Unable to find node with id ${nodeId} in children list of node ${String(node.parent)}`);
      }

      if (movingNodeIndex === insertIndex) {
        return;
      }

      const otherChildren = parentChildren.filter((chId) => chId !== nodeId);

      this.#childrenMap.set(node.parent, [...otherChildren.slice(0, insertIndex), nodeId, ...otherChildren.slice(insertIndex)]);
    }

    this.#updateShownNodesList();
    this.#updateSelectedNodesIds();
  }

  filter(text: string): void {
    if (text) {
      this.#initFilteredStorage(text);
    }
    this.#filterText = text;
    this.#updateShownNodesList();
  }

  reset(): void {
    this.#filterText = '';
    this.#isRootLoaded.next(false);
    this.#selectedNodesIds.next(new Set<string>());
    this.#shownNodesList.next([]);
    this.#storage = new NodeStorage<T>();
    this.#initRootNode();
    this.#filterStorage = new NodeStorage<T>();
  }

  #initRootNode(): void {
    this.#storage.nodesMap.set(ROOT_NODE_ID, {
      id: ROOT_NODE_ID,
      depthLevel: -1,
      isParent: true,
      parent: undefined,
      isExpanded: true,
      isSelected: false,
      isIndeterminate: false,
      isLoading: false,
    });
  }

  #initFilteredStorage(text: string): void {
    this.#setLoadingStateForFilterStorage();
    const newStorage = new NodeStorage<T>();

    if (this.fromLoadedNodes) {
      const idsWithDuplicates = [...this.#storage.nodesMap.values()]
        .filter((node): node is Node<T> => !this.#isRootNode(node) && this.filterNodeFromStorage(this.#toTreeNode(node), text))
        .flatMap((node) => (!node.parent || node.parent === ROOT_NODE_ID ? [node.id] : [node.id, node.parent]));

      const filteredNodeIds = new Set(idsWithDuplicates);

      const nodeIdToParentIdList: [string, NodeId][] = [...filteredNodeIds.values()].map((nodeId) => [
        nodeId,
        this.#getFilteredParent(nodeId, filteredNodeIds),
      ]);
      const orderMap = this.#getNodesOrderMap(this.#storage);

      const getChildren = (nodeId: NodeId) => this.#getSortedChildren(nodeId, nodeIdToParentIdList, orderMap);

      const rootChildren = getChildren(ROOT_NODE_ID);

      newStorage.nodesMap.set(ROOT_NODE_ID, {
        id: ROOT_NODE_ID,
        depthLevel: -1,
        isParent: true,
        parent: undefined,
        isExpanded: true,
        isSelected: false,
        isIndeterminate: false,
        isLoading: false,
      });

      newStorage.childrenMap.set(ROOT_NODE_ID, rootChildren);
      rootChildren.forEach((chId) => this.#initNode(chId, ROOT_NODE_ID, newStorage, getChildren));
      this.#filterStorage = newStorage;
      this.#updateShownNodesList();
    } else {
      newStorage.nodesMap.set(ROOT_NODE_ID, {
        id: ROOT_NODE_ID,
        depthLevel: -1,
        isParent: true,
        parent: undefined,
        isExpanded: true,
        isSelected: false,
        isIndeterminate: false,
        isLoading: false,
      });
      this.filterNode(text)
        .pipe(take(1))
        .subscribe((res) => {
          res.map((node) => {
            newStorage.nodesMap.set(node.id, {
              id: node.id,
              depthLevel: 0,
              isParent:
                node.isParent ||
                ('productsCount' in (node as TreeNodeFetchResult<object>).data && +(node.data as CatalogSection)['productsCount'] > 0),
              parent: undefined,
              isExpanded: false,
              isSelected: false,
              isIndeterminate: false,
              isLoading: false,
              data: node.data,
            } as unknown as Node<T>);
          });

          const idsWithDuplicates: string[] = [...newStorage.nodesMap.values()]
            .filter((node): node is Node<T> => !this.#isRootNode(node) && this.compareFn(this.#toTreeNode(node), text))
            .flatMap((node) => (!node.parent || node.parent === ROOT_NODE_ID ? [node.id] : [node.id, node.parent]));
          const filteredNodeIds = new Set(idsWithDuplicates);

          const nodeIdToParentIdList: [string, NodeId][] = [...filteredNodeIds.values()].map((nodeId) => {
            return [nodeId, this.#getFilteredParent(nodeId, filteredNodeIds)];
          });

          const orderMap = this.#getNodesOrderMap(newStorage);

          const getChildren = (nodeId: NodeId) => this.#getSortedChildren(nodeId, nodeIdToParentIdList, orderMap);

          newStorage.childrenMap.set(ROOT_NODE_ID, Array.from(filteredNodeIds));

          this.#filterStorage = newStorage;
          filteredNodeIds.forEach((chId) => this.#initNode(chId, ROOT_NODE_ID, this.#filterStorage as NodeStorage<T>, getChildren));
          this.#updateShownNodesList();
        });
    }
  }

  #getSortedChildren(nodeId: NodeId, nodeIdToParentIdList: [string, NodeId][], ordersMap: Map<NodeId, number>): string[] {
    return nodeIdToParentIdList
      .filter(([, parent]) => parent === nodeId)
      .map(([id]) => id)
      .sort((a, b) => (ordersMap.get(a) || 0) - (ordersMap.get(b) || 0));
  }

  #getNodesOrderMap(storage: NodeStorage<T>): Map<NodeId, number> {
    const orderMap = new Map<NodeId, number>();
    const orderedNodes = this.#getSubtreeIdsOrderedList(ROOT_NODE_ID, storage);

    orderedNodes.forEach((id, idx) => orderMap.set(id, idx));

    return orderMap;
  }

  #getFilteredParent(nodeId: string, filteredNodes: Set<string>): NodeId {
    if (nodeId) {
      let node = this.#getNodeFromStorage(nodeId, this.#storage);

      if (this.#isRootNode(node)) {
        throw new Error('Unable to get parent of root node');
      }

      if (node.parent === ROOT_NODE_ID || filteredNodes.has(node.parent)) {
        return node.parent;
      }

      return this.#getFilteredParent(node.parent, filteredNodes);
    } else {
      return '';
    }
  }

  #initNode(nodeId: string, parentId: NodeId, storage: NodeStorage<T>, getChildren: (id: NodeId) => string[]): void {
    let originalNode = this.#getNodeFromStorage(nodeId, this.#storage);
    if (this.#isRootNode(originalNode)) {
      return;
    }

    let nodeChildren = getChildren(nodeId);
    let parentNode = this.#getNodeFromStorage(parentId, this.#storage);
    let isParent = false;
    if ('data' in originalNode && 'data' in (originalNode as Node<AutocompleteOption>).data && !this.fromLoadedNodes) {
      const section = (originalNode as Node<AutocompleteOption>).data?.data as CatalogSection;
      if (this.categoriesTree) {
        isParent = section.isParent;
      } else {
        isParent = section.isParent || +section.productsCount > 0;
      }
    } else if (this.fromLoadedNodes) {
      isParent = !!nodeChildren.length;
    }

    storage.nodesMap.set(nodeId, {
      id: nodeId,
      depthLevel: parentNode.depthLevel + 1,
      isParent,
      parent: parentId,
      isExpanded: this.fromLoadedNodes ? isParent : originalNode.isExpanded,
      isSelected: originalNode.isSelected,
      isIndeterminate: originalNode.isIndeterminate,
      isLoading: false,
      data: originalNode.data,
    });

    if (isParent) {
      storage.childrenMap.set(nodeId, nodeChildren);
      nodeChildren.forEach((chId) => this.#initNode(chId, nodeId, storage, getChildren));
    }
  }

  #deleteChildren(nodeId: NodeId): void {
    const idsToDelete = this.#getSubtreeIdsOrderedList(nodeId, this.#activeStorage).slice(1);

    idsToDelete.forEach((id) => {
      this.#nodesMap.delete(id);
      this.#childrenMap.delete(id);
    });

    const node = this.#getNodeById(nodeId);

    if (this.#isRootNode(node)) {
      this.#childrenMap.set(nodeId, []);
    } else {
      this.#childrenMap.delete(nodeId);
      this.#nodesMap.set(nodeId, {
        ...node,
        isParent: false,
      });
    }
  }

  #addChildToParent(parentId: NodeId, childNode: Node<T>, insertIndex: number): void {
    if (!this.#nodesMap.has(parentId)) {
      throw new Error(`Unable to find parent node with id ${String(parentId)}`);
    }

    const parent = this.#getNodeById(parentId);

    if (parent.isParent || this.#isRootNode(parent)) {
      const parentChildren = this.#childrenMap.get(parentId) ?? [];

      this.#childrenMap.set(parentId, [...parentChildren.slice(0, insertIndex), childNode.id, ...parentChildren.slice(insertIndex)]);
    } else {
      this.#nodesMap.set(parentId, {
        ...parent,
        isParent: true,
        isExpanded: false,
      });

      this.#childrenMap.set(parentId, [childNode.id]);
    }

    const newDepthLevel = parent.depthLevel + 1;

    this.#nodesMap.set(childNode.id, {
      ...childNode,
      parent: parentId,
      depthLevel: newDepthLevel,
    });
    this.#updateChildrenDepthLevel(childNode.id, newDepthLevel);

    this.#updateCheckedState(parentId);

    if (this.#filterText) {
      this.#updateCheckedStateInFilteredTree();
    }
  }

  #updateChildrenDepthLevel(nodeId: string, depthLevel: number): void {
    const childrenIds = this.#childrenMap.get(nodeId) || [];

    childrenIds.forEach((chId) => {
      const childNode = this.#getNodeById(chId);

      if (this.#isRootNode(childNode)) {
        return;
      }

      this.#nodesMap.set(chId, {
        ...childNode,
        depthLevel: depthLevel + 1,
      });

      this.#updateChildrenDepthLevel(chId, depthLevel + 1);
    });
  }

  #getSubtreeIdsOrderedList(nodeId: NodeId, storage: NodeStorage<T>): NodeId[] {
    const node = this.#getNodeFromStorage(nodeId, storage);

    if (!node.isParent) {
      return [nodeId];
    }

    const children = storage.childrenMap.get(nodeId) ?? [];

    return [nodeId, ...children.flatMap((chId) => this.#getSubtreeIdsOrderedList(chId, storage))];
  }

  #changeSelectedState(nodeId: NodeId, selected: boolean): void {
    const node = this.#getNodeFromStorage(nodeId, this.#storage);

    if (node) {
      let set = new Set<string>();
      if (node.isParent && !this.#storage.childrenMap.get(nodeId)) {
        const childrenIds = this.#loadChildrenAndUpdateSelected(nodeId).then((chs) => {
          return chs.map((ch) => {
            this.#nodesMap.set(ch.id, {
              ...ch,
            });
            this.#storage.nodesMap.set(ch.id, { ...ch });
            return ch.id;
          });
        });

        childrenIds.then((chId) => {
          chId.map((id) => {
            set.add(id);
            this.#changeSelectedState(id, selected);
          });
          set.add(node.id as string);
          this.updateSelected.next({ itemsSet: set, selected });
        });
      } else if (node.isParent) {
        (this.#storage.childrenMap.get(nodeId) ?? []).forEach((childId) => {
          set.add(childId);
          this.#changeSelectedState(childId, selected);
        });

        set.add(node.id as string);
        setTimeout(() => {
          this.updateSelected.next({ itemsSet: set, selected });
        });
      }

      this.#storage.nodesMap.set(nodeId, {
        ...node,
        isSelected: selected,
        isIndeterminate: false,
      });

      this.#updateSelectedNodesIds();
    }
  }
  selectAll() {
    let selectedItems = new Set<string>();
    Array.from(this.#storage.nodesMap.values()).forEach((element) => {
      if (element.id !== ROOT_NODE_ID) {
        this.#storage.nodesMap.set(element.id, { ...element, isSelected: true });
        selectedItems.add(element.id);
      }
    });
    this.updateSelected.next({ itemsSet: selectedItems, selected: true });
  }

  #updateCheckedState(nodeId: NodeId): void {
    const node = this.#getNodeFromStorage(nodeId, this.#storage);
    if (this.#isRootNode(node)) {
      return;
    }
    const children = this.#storage.childrenMap.get(nodeId) ?? [];

    const hasSelectedChild = children.some((chId) => {
      const node = this.#getNodeFromStorage(chId, this.#storage);
      return node.isSelected || node.isIndeterminate;
    });

    const hasDeselectedChild = children.some((chId) => {
      const node = this.#getNodeFromStorage(chId, this.#storage);
      return !node.isSelected || node.isIndeterminate;
    });
    if (hasSelectedChild && hasDeselectedChild) {
      this.#storage.nodesMap.set(nodeId, {
        ...node,
        isSelected: false,
        isIndeterminate: true,
      });
    }
    if (hasSelectedChild && !hasDeselectedChild) {
      this.#storage.nodesMap.set(nodeId, {
        ...node,
        isSelected: true,
        isIndeterminate: false,
      });
      setTimeout(() => {
        this.updateSelected.next({ itemsSet: new Set<string>().add(node.id as string), selected: true });
      });
    }
    if (!hasSelectedChild && hasDeselectedChild) {
      this.#storage.nodesMap.set(nodeId, {
        ...node,
        isSelected: false,
        isIndeterminate: false,
      });
      setTimeout(() => {
        this.updateSelected.next({ itemsSet: new Set<string>().add(node.id as string), selected: false });
      });
    }
    if (!hasSelectedChild && !hasDeselectedChild) {
      this.#storage.nodesMap.set(nodeId, {
        ...node,
        isSelected: node.isSelected,
        isIndeterminate: false,
      });
    }

    if (node.parent) {
      this.#updateCheckedState(node.parent);
    }
  }

  #updateCheckedStateInFilteredTree(): void {
    [...this.#filterStorage.nodesMap.entries()].forEach(([nodeId, node]) => {
      const nodeFromMainStorage = this.#storage.nodesMap.get(nodeId);
      if (!nodeFromMainStorage) {
        throw new Error(`Unable to find node with id ${String(nodeId)} in main storage`);
      }

      this.#filterStorage.nodesMap.set(nodeId, {
        ...node,
        isSelected: nodeFromMainStorage.isSelected,
        isIndeterminate: nodeFromMainStorage.isIndeterminate,
      });
    });
  }

  #updateShownNodesList() {
    this.#shownNodesList.next(
      this.#getExpandedSubtreeList(ROOT_NODE_ID)
        .filter((node): node is Node<T> => !this.#isRootNode(node))
        .map(this.#toTreeNode),
    );
  }

  #updateSelectedNodesIds() {
    this.#selectedNodesIds.next(
      new Set(this.#getSelectedNodesIdsInSubtree(ROOT_NODE_ID).filter((nodeId): nodeId is string => !this.#isRootNodeId(nodeId))),
    );
  }

  #getSelectedNodesIdsInSubtree(nodeId: NodeId): NodeId[] {
    const node = this.#getNodeFromStorage(nodeId, this.#storage);
    const childrenIds = this.#storage.childrenMap.get(nodeId) || [];
    const selectedChildrenIds = childrenIds.flatMap((chId) => this.#getSelectedNodesIdsInSubtree(chId));

    if (node.isSelected) {
      return [nodeId, ...selectedChildrenIds];
    }

    return selectedChildrenIds;
  }

  #getExpandedSubtreeList(nodeId: NodeId): InternalNode<T>[] {
    const node = this.#getNodeById(nodeId);

    if (!node.isParent || !node.isExpanded) {
      return [node];
    }

    const childrenIds = this.#childrenMap.get(node.id) ?? [];

    return [node, ...childrenIds.flatMap((childId) => this.#getExpandedSubtreeList(childId))];
  }

  #loadChildrenAndUpdateSelected(nodeId: NodeId, recursive: boolean = false): Promise<Node<T>[]> {
    const targetNode = this.#getNodeById(nodeId);
    return this.#loadChildren(nodeId, recursive).then((nodes) => {
      if (targetNode.isSelected && nodes.length) {
        this.#updateSelectedNodesIds();
      }
      return nodes.filter((node) => !!node);
    });
  }

  #loadChildren(nodeId: NodeId, recursive: boolean = false): Promise<Node<T>[]> {
    const targetNode = this.#getNodeById(nodeId);

    const childrenDepth = targetNode.depthLevel + 1;

    const isRoot = nodeId === ROOT_NODE_ID;

    const childrenPromise = new Promise<Node<T>[]>((resolve, reject) =>
      this.fetchChildren(isRoot ? undefined : nodeId, childrenDepth).subscribe(
        (res) => {
          const nodes: Node<T>[] = res.map((node) => {
            return {
              id: node.id,
              isParent: node.isParent,
              parent: nodeId,
              depthLevel: childrenDepth,
              isExpanded: false,
              isLoading: false,
              isSelected: targetNode.isSelected,
              isIndeterminate: false,
              data: node.data,
            };
          });

          nodes.forEach((node) => {
            this.#nodesMap.set(node.id, node);
            this.#storage.nodesMap.set(node.id, node);
            this.#filterStorage.nodesMap.set(node.id, node);
          });

          this.#childrenMap.set(
            nodeId,
            nodes.map((node) => node.id),
          );
          this.#storage.childrenMap.set(
            nodeId,
            nodes.map((node) => node.id),
          );

          resolve(nodes);
        },
        (error) => reject(error),
      ),
    );

    if (!recursive) {
      return childrenPromise;
    }

    return childrenPromise.then((chs) => {
      if (!chs.length) {
        return Promise.all(chs);
      }
      this.#updateShownNodesList();
      return Promise.all(chs.map((ch) => this.#loadChildren(ch.id, true))).then((chsOfChs) => [...chs, ...chsOfChs.flat()]);
    });
  }

  #getNodeById(nodeId: NodeId): InternalNode<T> {
    const node = this.#nodesMap.get(nodeId);

    if (!node) {
      throw new Error(`Unable to find node with id ${String(nodeId)}`);
    }

    return node;
  }

  #getNodeFromStorage(nodeId: NodeId, storage: NodeStorage<T>): InternalNode<T> {
    const node = storage.nodesMap.get(nodeId);

    if (!node) {
      throw new Error(`Unable to find node with id ${String(nodeId)}`);
    }

    return node;
  }

  #isRootNode(node: InternalNode<T>): node is RootNode {
    return node.id === ROOT_NODE_ID;
  }

  #isRootNodeId(nodeId: NodeId): nodeId is typeof ROOT_NODE_ID {
    return nodeId === ROOT_NODE_ID;
  }

  #toTreeNode(node: Node<T>): TreeNode<T> {
    return {
      ...node,
      parent: node.parent === ROOT_NODE_ID ? undefined : node.parent,
    };
  }
  #setLoadingStateForFilterStorage() {
    this.#filterStorage = new NodeStorage<T>();
    this.#filterStorage.nodesMap.set(ROOT_NODE_ID, {
      id: ROOT_NODE_ID,
      depthLevel: -1,
      isParent: true,
      parent: undefined,
      isExpanded: true,
      isSelected: false,
      isIndeterminate: false,
      isLoading: false,
    });
    const loadingNode: Node<{}> = {
      id: 'loading',
      depthLevel: 0,
      isParent: false,
      parent: ROOT_NODE_ID,
      isExpanded: false,
      isSelected: false,
      isIndeterminate: false,
      isLoading: true,
      data: {},
    };
    this.#filterStorage.nodesMap.set(loadingNode.id, {
      ...loadingNode,
    });
    this.#filterStorage.childrenMap.set(ROOT_NODE_ID, [loadingNode.id]);
    this.#updateShownNodesList();
  }
  compareFn(node: TreeNode<T>, searchText: string) {
    const nodeNameLowerCase = (node as Node<AutocompleteOption>).data.label.toLowerCase();
    const searchTextLowerCase = searchText.toLowerCase();

    return nodeNameLowerCase.includes(searchTextLowerCase);
  }
}
