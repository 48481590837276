<div class="auth-base">
  <div class="header" *ngIf="showHeader">
    <nm-icon-button
      variant="text"
      *ngIf="layoutService.leftIcon"
      [icon]="layoutService.leftIcon"
      (clickButton)="layoutService.iconClick$.next(true)"
    ></nm-icon-button>
    <div class="top-bar">
      <div class="top-bar-title">{{ title }}</div>
    </div>
  </div>
  <div class="body">
    <div class="page-content">
      <div
        *ngIf="showLogo"
        class="logo"
        [style.background-image]="
          getTheme() !== 'dark' ? 'url(/assets/images/logo-apos.svg)' : 'url(/assets/images/logo-dark.svg)'
        "
      ></div>
      <router-outlet></router-outlet>
    </div>
    <div class="footer">
      <div class="content">
        <div *ngIf="showFooterInfo">
          <div class="support-label">Техническая поддержка</div>
          <div class="support-phone">8 (800) 777-89-67 (доб. 2)</div>
        </div>
      </div>
    </div>
  </div>
</div>
