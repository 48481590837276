import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ButtonModule } from '@app/shared/component/button/button.module';
import { IconButtonModule } from '@app/shared/component/icon-button/icon-button.module';

import { BaseLayoutFooterButtonsComponent } from './base-layout-footer-buttons/base-layout-footer-buttons.component';
import { BaseLayoutComponent } from './base-layout.component';

@NgModule({
  declarations: [BaseLayoutComponent, BaseLayoutFooterButtonsComponent],
  exports: [BaseLayoutComponent, BaseLayoutFooterButtonsComponent],
  imports: [CommonModule, ButtonModule, IconButtonModule, RouterModule, IconButtonModule],
})
export class BaseLayoutModule {}
