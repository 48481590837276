import { Injectable } from '@angular/core';

import { ConfigService } from '@app/config.service';
import { Features } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class FeatureEnableService implements Features {
  movements: boolean = false;
  analytics: boolean = false;
  settings: boolean = false;
  profile: boolean = false;
  monetization: boolean = false;
  applications: boolean = false;
  docs: boolean = false;
  enabledTables: boolean = false;
  consolidatedReport = false;
  dashboardReport = false;
  booking = false;

  constructor(public configService: ConfigService) {
    const config = this.configService.config;

    if (config?.features) {
      this.applyFeatureSet(config?.features);
    }
  }

  applyFeatureSet(features: Features) {
    Object.keys(features).forEach((feature) => {
      this[feature] = features[feature] === true;
    });
  }
}
