import { Injectable } from '@angular/core';

import {
  mutationChangeAccountPassword,
  mutationClaimIdentity,
  mutationClaimSendConfirmation,
  mutationIdentityClaimConfirm,
  mutationIdentityRemove,
  mutationUpdateAccount,
  queryAccount,
  queryImageById,
  queryRole,
} from '@api';
import { ApiService } from '@services/core';
import { CoreSchema, Mutation, MutationResult, Query, QueryResult } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class AccountApiService {
  constructor(private api: ApiService) {}

  imageById(variables: CoreSchema.QueryImageByIdArgs): QueryResult<'imageById'> {
    return this.api.query<Query<'imageById'>, CoreSchema.QueryImageByIdArgs>({
      query: queryImageById,
      variables,
    });
  }

  getAccount(variables: CoreSchema.QueryAccountArgs): QueryResult<'account'> {
    return this.api.watch<Query<'account'>, CoreSchema.QueryAccountArgs>({
      query: queryAccount,
      variables,
    }).valueChanges;
  }

  // Warning! Header with OrganizationId is reqired by BE achitecture
  getRole(variables: CoreSchema.QueryAccountArgs): QueryResult<'account'> {
    return this.api.watch<Query<'account'>, CoreSchema.QueryAccountArgs>({
      query: queryRole,
      variables,
    }).valueChanges;
  }

  updateAccount(variables: CoreSchema.MutationUpdateAccountArgs): MutationResult<'updateAccount'> {
    return this.api.mutate<Mutation<'updateAccount'>, CoreSchema.MutationUpdateAccountArgs>({
      mutation: mutationUpdateAccount,
      variables,
      context: { useMultipart: true },
    });
  }

  changePassword(variables: CoreSchema.MutationChangeAccountPasswordArgs): MutationResult<'changeAccountPassword'> {
    return this.api.mutate<Mutation<'changeAccountPassword'>, CoreSchema.MutationChangeAccountPasswordArgs>({
      mutation: mutationChangeAccountPassword,
      variables,
    });
  }

  claimIdentity(variables: CoreSchema.MutationClaimIdentityArgs): MutationResult<'claimIdentity'> {
    return this.api.mutate<Mutation<'claimIdentity'>, CoreSchema.MutationClaimIdentityArgs>({
      mutation: mutationClaimIdentity,
      variables,
    });
  }

  identityClaimSendConfirmation(
    variables: CoreSchema.MutationIdentityClaimSendConfirmationArgs,
  ): MutationResult<'identityClaimSendConfirmation'> {
    return this.api.mutate<Mutation<'identityClaimSendConfirmation'>, CoreSchema.MutationIdentityClaimSendConfirmationArgs>({
      mutation: mutationClaimSendConfirmation,
      variables,
    });
  }

  identityClaimConfirm(variables: CoreSchema.MutationIdentityClaimConfirmArgs): MutationResult<'identityClaimConfirm'> {
    return this.api.mutate<Mutation<'identityClaimConfirm'>, CoreSchema.MutationIdentityClaimConfirmArgs>({
      mutation: mutationIdentityClaimConfirm,
      variables,
    });
  }

  identityRemove(variables: CoreSchema.MutationIdentityClaimRemoveArgs): MutationResult<'identityClaimRemove'> {
    return this.api.mutate<Mutation<'identityClaimRemove'>, CoreSchema.MutationIdentityClaimRemoveArgs>({
      mutation: mutationIdentityRemove,
      variables,
    });
  }
}
