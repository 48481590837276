import { Injectable } from '@angular/core';

import { NotifyService } from '@services/shared';
import {
  MutationAddInventoryProductsArgs,
  MutationConfirmInventoryDocArgs,
  MutationConfirmInventoryDocumentArgs,
  MutationCreateInventoryDocArgs,
  MutationCreateInventoryDocumentArgs,
  MutationDeleteInventoryDocArgs,
  MutationDeleteInventoryDocumentArgs,
  MutationDuplicateInventoryDocumentArgs,
  MutationRemoveInventoryProductsArgs,
  MutationResult,
  MutationRevertInventoryDocArgs,
  MutationUpdateInventoryDocArgs,
  MutationUpdateInventoryDocumentArgs,
  MutationUpdateInventoryProductsArgs,
  QueryAllInventoryDocumentsPageableV2Args,
  QueryGetPossibleDocumentCreationTypesArgs,
  QueryInventoryDocArgs,
  QueryInventoryDocsArgs,
  QueryInventoryDocumentArgs,
  QueryInventoryProductsArgs,
  QueryProductsInventoryDataArgs,
  QueryResult,
  QueryStockUnitInventoryDataArgs,
} from '@typings';

import { InventoriesApi } from './inventories.api';

@Injectable({
  providedIn: 'root',
})
export class InventoriesStorage {
  constructor(private notifyService: NotifyService, private inventoriesApi: InventoriesApi) {}

  createInventory(variables: MutationCreateInventoryDocumentArgs): MutationResult<'createInventoryDocument'> {
    return this.inventoriesApi.createInventoryDocument(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании инвентаризации',
      }),
    );
  }

  updateInventory(variables: MutationUpdateInventoryDocumentArgs): MutationResult<'updateInventoryDocument'> {
    return this.inventoriesApi.updateInventoryDocument(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении инвентаризации',
      }),
    );
  }

  deleteInventory(variables: MutationDeleteInventoryDocumentArgs): MutationResult<'deleteInventoryDocument'> {
    return this.inventoriesApi.deleteInventoryDocument(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении инвентаризации',
        success: 'Инвентаризация успешно удалена',
      }),
    );
  }

  confirmInventory(variables: MutationConfirmInventoryDocumentArgs): MutationResult<'confirmInventoryDocument'> {
    return this.inventoriesApi.confirmInventoryDocument(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при проведении инвентаризации',
      }),
    );
  }

  duplicateInventory(variables: MutationDuplicateInventoryDocumentArgs): MutationResult<'duplicateInventoryDocument'> {
    return this.inventoriesApi.duplicateInventoryDocument(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при дублировании инвентаризации',
      }),
    );
  }

  getInventoriesList(variables?: QueryAllInventoryDocumentsPageableV2Args): QueryResult<'allInventoryDocumentsPageableV2'> {
    return this.inventoriesApi.allInventoryDocumentsPageableV2(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка инвентаризаций',
      }),
    );
  }

  getInventory(variables: QueryInventoryDocumentArgs): QueryResult<'inventoryDocument'> {
    return this.inventoriesApi.inventoryDocument(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении инвентаризации',
      }),
    );
  }

  getStockUnitInventoryData(variables: QueryStockUnitInventoryDataArgs): QueryResult<'stockUnitInventoryData'> {
    return this.inventoriesApi.stockUnitInventoryData(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка',
      }),
    );
  }

  getPossibleDocumentCreationTypes(variables: QueryGetPossibleDocumentCreationTypesArgs): QueryResult<'getPossibleDocumentCreationTypes'> {
    return this.inventoriesApi.getPossibleDocumentCreationTypes(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка',
      }),
    );
  }

  inventoryDocs(variables: QueryInventoryDocsArgs): QueryResult<'inventoryDocs'> {
    return this.inventoriesApi.inventoryDocs(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка инвентаризаций',
      }),
    );
  }

  inventoryProducts(variables: QueryInventoryProductsArgs): QueryResult<'inventoryProducts'> {
    return this.inventoriesApi.inventoryProducts(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка продуктов инвентаризации',
      }),
    );
  }

  productsInventoryData(variables: QueryProductsInventoryDataArgs): QueryResult<'productsInventoryData'> {
    return this.inventoriesApi.productsInventoryData(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка информации о продуктов инвентаризации',
      }),
    );
  }

  inventoryDoc(variables: QueryInventoryDocArgs): QueryResult<'inventoryDoc'> {
    return this.inventoriesApi.inventoryDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении инвентаризации',
      }),
    );
  }

  createInventoryDoc(variables: MutationCreateInventoryDocArgs): MutationResult<'createInventoryDoc'> {
    return this.inventoriesApi.createInventoryDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании инвентаризации',
      }),
    );
  }

  updateInventoryDoc(variables: MutationUpdateInventoryDocArgs): MutationResult<'updateInventoryDoc'> {
    return this.inventoriesApi.updateInventoryDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при изменении инвентаризации',
      }),
    );
  }

  confirmInventoryDoc(variables: MutationConfirmInventoryDocArgs) {
    return this.inventoriesApi.confirmInventoryDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при проведении документа перемещения',
      }),
    );
  }

  revertInventoryDoc(variables: MutationRevertInventoryDocArgs) {
    return this.inventoriesApi.revertInventoryDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при отмене инвентаризации',
      }),
    );
  }

  deleteInventoryDoc(variables: MutationDeleteInventoryDocArgs) {
    return this.inventoriesApi.deleteInventoryDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении инвентаризации',
        success: 'Списание успешно удалено',
      }),
    );
  }

  addInventoryProducts(variables: MutationAddInventoryProductsArgs): MutationResult<'addInventoryProducts'> {
    return this.inventoriesApi.addInventoryProducts(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при добавлении продуктов в инвентаризацию',
      }),
    );
  }

  updateInventoryProducts(variables: MutationUpdateInventoryProductsArgs): MutationResult<'updateInventoryProducts'> {
    return this.inventoriesApi.updateInventoryProducts(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при изменении продуктов в инвентаризацию',
      }),
    );
  }

  removeInventoryProducts(variables: MutationRemoveInventoryProductsArgs): MutationResult<'removeInventoryProducts'> {
    return this.inventoriesApi.removeInventoryProducts(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении продуктов в инвентаризацию',
      }),
    );
  }
}
