import { gql } from 'apollo-angular';

import { fragmentPage, fragmentRequestResult } from './mutations';
export const fragmentApplyRule = gql`
  fragment ApplyRule on ApplyRule {
    dailyPeriods {
      dayOfWeek
      maxTime
      minTime
    }
    maxOrderAmount
    minItemQuantity
    minOrderAmount
    orderAddedCategoryIds {
      all
      selected
      excluded
    }
    orderAddedStockUnitIds {
      all
      selected
      excluded
    }
  }
`;

export const fragmentPromotionProgram = gql`
  fragment PromotionProgram on PromotionProgram {
    id
    imageId
    promotionType
    applyRule {
      ...ApplyRule
    }
    storeIds {
      all
      selected
      excluded
    }
    name
    description
    categoryIds {
      all
      selected
      excluded
    }
    stockUnitIds {
      all
      selected
      excluded
    }
    active
    customerGroupIds {
      all
      selected
      excluded
    }
    beginsAt
    endsAt
  }
  ${fragmentApplyRule}
`;

export const fragmentBonusProgram = gql`
  fragment BonusProgram on BonusProgram {
    id
    bonusProgramType
    bonusFormula
    bonusLimit
    percent
    pointPrice {
      monetary
      monetaryDecimal
      points
    }
    promotionProgram {
      ...PromotionProgram
    }
  }
  ${fragmentPromotionProgram}
`;
export const fragmentDiscountProgram = gql`
  fragment DiscountProgram on DiscountProgram {
    id
    percent
    basis
    automatic
    combined
    discountFormula
    discountLimit
    absolute
    promotionProgram {
      ...PromotionProgram
    }
  }
  ${fragmentPromotionProgram}
`;
export const fragmentGiftProgram = gql`
  fragment GiftProgram on GiftProgram {
    id
    automatic
    promotionProgram {
      ...PromotionProgram
    }
  }
  ${fragmentPromotionProgram}
`;
export const mutationCreateBonusProgram = gql`
  mutation createBonusProgram($program: BonusProgramCreateInput!) {
    createBonusProgram(program: $program) {
      ...RequestResult
      output {
        id
        promotionProgram {
          id
        }
      }
    }
  }
  ${fragmentRequestResult}
`;

export const mutationCreateDiscountProgram = gql`
  mutation createDiscountProgram($program: DiscountProgramCreateInput!) {
    createDiscountProgram(program: $program) {
      ...RequestResult
      output {
        id
        promotionProgram {
          id
        }
      }
    }
  }
  ${fragmentRequestResult}
`;

export const mutationCreateGiftProgram = gql`
  mutation createGiftProgram($program: GiftProgramCreateInput!) {
    createGiftProgram(program: $program) {
      ...RequestResult
      output {
        id
        promotionProgram {
          id
        }
      }
    }
  }
  ${fragmentRequestResult}
`;

export const mutationUpdateBonusProgram = gql`
  mutation updateBonusProgram($program: BonusProgramUpdateInput!) {
    updateBonusProgram(program: $program) {
      ...RequestResult
      output {
        ...BonusProgram
      }
    }
  }
  ${fragmentBonusProgram}
  ${fragmentRequestResult}
`;

export const mutationUpdateDiscountProgram = gql`
  mutation updateDiscountProgram($program: DiscountProgramPatchUpdateRequest!) {
    updateDiscountProgram(program: $program) {
      ...RequestResult
      output {
        ...DiscountProgram
      }
    }
  }
  ${fragmentDiscountProgram}
  ${fragmentRequestResult}
`;

export const mutationUpdateGiftProgram = gql`
  mutation updateGiftProgram($program: GiftProgramUpdateInput!) {
    updateGiftProgram(program: $program) {
      ...RequestResult
      output {
        ...GiftProgram
      }
    }
  }
  ${fragmentGiftProgram}
  ${fragmentRequestResult}
`;

export const mutationUpdateApplyRule = gql`
  mutation updateApplyRule($input: ApplyRulePatchUpdateInput) {
    updateApplyRule(input: $input) {
      ...RequestResult
      output {
        ...ApplyRule
      }
    }
  }
  ${fragmentRequestResult}
  ${fragmentApplyRule}
`;

export const mutationDeletePromotionProgram = gql`
  mutation deletePromotionProgram($id: UUID) {
    deletePromotionProgram(id: $id) {
      ...RequestResult
      output {
        id
      }
    }
  }
  ${fragmentRequestResult}
`;

export const queryPromotionPrograms = gql`
  query promotionPrograms($filter: PromotionProgramFilterInput, $pageRequest: PageRequestInput!, $sort: [PromotionProgramSort!]) {
    promotionPrograms(filter: $filter, pageRequest: $pageRequest, sort: $sort) {
      ...Page
      content {
        ... on BonusProgram {
          ...BonusProgram
        }
        ... on DiscountProgram {
          ...DiscountProgram
        }
        ... on GiftProgram {
          ...GiftProgram
        }
      }
    }
  }
  ${fragmentPage}
  ${fragmentBonusProgram}
  ${fragmentDiscountProgram}
  ${fragmentGiftProgram}
`;
