import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { UnitType, UnitTypeStrict, UnitTypeString } from '@typings';

export const UNIT_TYPE: UnitTypeStrict = {
  PIECE: 'PIECE',
  LITER: 'LITER',
  GRAM: 'GRAM',
  KILOGRAM: 'KILOGRAM',
  MILLILITER: 'MILLILITER',
  NONE: 'NONE',
};

export const { LITER, MILLILITER, KILOGRAM, GRAM, PIECE } = UNIT_TYPE;
export const LITER_OR_KILOGRAM = (unitType: UnitType): boolean => unitType === LITER || unitType === KILOGRAM;
export const MILLILITER_OR_GRAM = (unitType: UnitType): boolean => unitType === MILLILITER || unitType === GRAM;

export const UNIT_TYPE_STRING: UnitTypeString = {
  PIECE: 'Штука',
  LITER: 'Литр',
  GRAM: 'Грамм',
  KILOGRAM: 'Килограмм',
  MILLILITER: 'Миллилитр',
  NONE: '',
};

export const UNIT_TYPE_STRING_SHORT: UnitTypeString = {
  PIECE: 'шт',
  LITER: 'л',
  GRAM: 'г',
  KILOGRAM: 'кг',
  MILLILITER: 'мл',
  NONE: '',
};

const UNIT_TYPE_KEYS = Object.keys(UNIT_TYPE).filter((type) => type !== UNIT_TYPE.NONE);

export const UNIT_TYPE_LIST: AutocompleteOption<UnitType>[] = UNIT_TYPE_KEYS.map((type) => ({
  id: type,
  label: UNIT_TYPE_STRING[type],
  type: 'item',
  data: type,
}));
