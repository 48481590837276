import { gql } from 'apollo-angular';

import { fragmentMutationResult, fragmentPage } from './mutations';

export const fragmentTextModifier = gql`
  fragment TextModifier on TextModifier {
    id
    name
    pinned
    sort
  }
`;

export const fragmentStockUnitModifier = gql`
  fragment StockUnitModifier on StockUnitModifier {
    id
    name
    pinned
    sort
    stockUnit {
      id
      name
      quantity
      unit
      images {
        imageSizes {
          width
          height
          url
        }
      }
      salePrice {
        amountValue
        currencyUnit
      }
    }
  }
`;

export const fragmentModifierGroupOutputShort = gql`
  fragment ModifierGroupOutputShort on ModifierGroupOutput {
    id
    name
  }
`;

export const fragmentModifierGroupOutput = gql`
  fragment ModifierGroupOutput on ModifierGroupOutput {
    id
    modifiers {
      content {
        ... on TextModifier {
          ...TextModifier
        }
        ... on StockUnitModifier {
          ...StockUnitModifier
        }
      }
    }
    products {
      content {
        id
        name
      }
    }
    type
    name
    sort
    active
    required
    minValue
    maxValue
  }
  ${fragmentTextModifier}
  ${fragmentStockUnitModifier}
`;

export const fragmentModifierGroupOutputWithModifiers = gql`
  fragment ModifierGroupOutputWthModifiers on ModifierGroupOutput {
    id
    modifiers(pageRequest: $modifiersPageRequest) {
      content {
        ... on TextModifier {
          ...TextModifier
        }
        ... on StockUnitModifier {
          ...StockUnitModifier
        }
      }
    }
    products {
      content {
        id
        name
      }
    }
    type
    name
    sort
    active
    required
    minValue
    maxValue
  }
  ${fragmentTextModifier}
  ${fragmentStockUnitModifier}
`;

export const queryModifierGroupById = gql`
  query modifierGroupById($id: UUID!) {
    modifierGroupById(id: $id) {
      ...ModifierGroupOutput
    }
  }
  ${fragmentModifierGroupOutput}
`;

export const queryModifierGroups = gql`
  query modifierGroups($pageRequest: PageRequestInput, $filterInput: ModifierGroupFilterInput, $modifiersPageRequest: PageRequestInput) {
    modifierGroups(pageRequest: $pageRequest, filterInput: $filterInput) {
      content {
        ...ModifierGroupOutputWthModifiers
      }
      ...Page
    }
  }
  ${fragmentModifierGroupOutputWithModifiers}
  ${fragmentPage}
`;

export const queryModifierGroupsShort = gql`
  query modifierGroups($pageRequest: PageRequestInput, $filterInput: ModifierGroupFilterInput) {
    modifierGroups(pageRequest: $pageRequest, filterInput: $filterInput) {
      content {
        ...ModifierGroupOutputShort
      }
      ...Page
    }
  }
  ${fragmentModifierGroupOutputShort}
  ${fragmentPage}
`;

export const queryModifierGroupWhereUsedAsModifier = gql`
  query modifierGroupWhereUsedAsModifier($pageRequest: PageRequestInput, $stockUnitId: UUID!) {
    modifierGroupWhereUsedAsModifier(pageRequest: $pageRequest, stockUnitId: $stockUnitId) {
      content {
        ...ModifierGroupOutputShort
      }
    }
  }
  ${fragmentModifierGroupOutputShort}
`;

export const mutationModifierGroupCreate = gql`
  mutation modifierGroupCreate($modifierGroup: CreateModifierGroupInput!) {
    modifierGroupCreate(modifierGroup: $modifierGroup) {
      errorMessage
      output {
        ...ModifierGroupOutput
      }
      result
    }
  }
  ${fragmentModifierGroupOutput}
`;

export const mutationModifierGroupUpdate = gql`
  mutation modifierGroupUpdate($modifierGroup: UpdateModifierGroupInput!) {
    modifierGroupUpdate(modifierGroup: $modifierGroup) {
      errorMessage
      output {
        ...ModifierGroupOutput
      }
      result
    }
  }
  ${fragmentModifierGroupOutput}
`;

export const mutationDeleteModifierGroup = gql`
  mutation deleteModifierGroup($modifierGroupId: UUID!) {
    deleteModifierGroup(modifierGroupId: $modifierGroupId) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationModifierGroupAttachProducts = gql`
  mutation modifierGroupAttachProducts($modifierGroupId: UUID!, $productIds: [UUID!]!) {
    modifierGroupAttachProducts(modifierGroupId: $modifierGroupId, productIds: $productIds) {
      errorMessage
      output {
        ...ModifierGroupOutput
      }
      result
    }
  }
  ${fragmentModifierGroupOutput}
`;

export const mutationModifierGroupDetachProducts = gql`
  mutation modifierGroupDetachProducts($modifierGroupId: UUID!, $productIds: [UUID!]!) {
    modifierGroupDetachProducts(modifierGroupId: $modifierGroupId, productIds: $productIds) {
      errorMessage
      output {
        ...ModifierGroupOutput
      }
      result
    }
  }
  ${fragmentModifierGroupOutput}
`;

export const mutationAddModifiers = gql`
  mutation addModifiers($modifierGroupId: UUID!, $modifiers: [CreateModifierInput!]!) {
    addModifiers(modifierGroupId: $modifierGroupId, modifiers: $modifiers) {
      errorMessage
      output {
        ...ModifierGroupOutput
      }
      result
    }
  }
  ${fragmentModifierGroupOutput}
`;

export const mutationUpdateModifier = gql`
  mutation updateModifier($modifierGroupId: UUID!, $modifierId: UUID!, $name: String, $pinned: Boolean, $sort: Int) {
    updateModifier(modifierGroupId: $modifierGroupId, modifierId: $modifierId, name: $name, pinned: $pinned, sort: $sort) {
      errorMessage
      output {
        ...ModifierGroupOutput
      }
      result
    }
  }
  ${fragmentModifierGroupOutput}
`;

export const mutationRemoveModifiers = gql`
  mutation removeModifiers($modifierGroupId: UUID!, $modifierIds: [UUID!]!) {
    removeModifiers(modifierGroupId: $modifierGroupId, modifierIds: $modifierIds) {
      errorMessage
      output {
        ...ModifierGroupOutput
      }
      result
    }
  }
  ${fragmentModifierGroupOutput}
`;
