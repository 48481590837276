import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { CatalogSection, CategoryLine } from '@typings';

import { getHexCodeByColor } from './colors.util';

export const catalogSectionToAutocompleteOption = (
  section: CatalogSection | CategoryLine,
): AutocompleteOption<CatalogSection | CategoryLine> => {
  const { id, name } = section;
  const { colorSection } = section as CatalogSection;

  const option: AutocompleteOption<CatalogSection | CategoryLine> = {
    id,
    label: name,
    type: 'item',
    imageColor: colorSection ? getHexCodeByColor(colorSection) : undefined,
    catalogType: 'CATEGORY',
    data: section,
  };

  return option;
};
