import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ListingFooterComponent } from './listing-footer.component';

@NgModule({
  declarations: [ListingFooterComponent],
  imports: [CommonModule],
  exports: [ListingFooterComponent],
})
export class ListingFooterModule {}
