import { Injectable } from '@angular/core';

import {
  mutationCreatePaymentSystem,
  mutationDeletePaymentSystem,
  mutationUpdatePaymentSystem,
  queryPaymentSystem,
  queryPaymentSystems,
} from '@api';
import { ApiService } from '@services/core';
import {
  Mutation,
  MutationCreatePaymentSystemArgs,
  MutationDeletePaymentSystemArgs,
  MutationResult,
  MutationUpdatePaymentSystemArgs,
  Query,
  QueryPaymentSystemArgs,
  QueryPaymentSystemsArgs,
  QueryResult,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class PaySystemsApi {
  constructor(private apiService: ApiService) {}

  createPaymentSystem(variables: MutationCreatePaymentSystemArgs): MutationResult<'createPaymentSystem'> {
    return this.apiService.mutate<Mutation<'createPaymentSystem'>, MutationCreatePaymentSystemArgs>({
      mutation: mutationCreatePaymentSystem,
      variables,
    });
  }

  updatePaymentSystem(variables: MutationUpdatePaymentSystemArgs): MutationResult<'updatePaymentSystem'> {
    return this.apiService.mutate<Mutation<'updatePaymentSystem'>, MutationUpdatePaymentSystemArgs>({
      mutation: mutationUpdatePaymentSystem,
      variables,
    });
  }

  deletePaymentSystem(variables: MutationDeletePaymentSystemArgs): MutationResult<'deletePaymentSystem'> {
    return this.apiService.mutate<Mutation<'deletePaymentSystem'>, MutationDeletePaymentSystemArgs>({
      mutation: mutationDeletePaymentSystem,
      variables,
    });
  }

  paymentSystems(variables: QueryPaymentSystemsArgs): QueryResult<'paymentSystems'> {
    return this.apiService.query<Query<'paymentSystems'>, QueryPaymentSystemsArgs>({
      query: queryPaymentSystems,
      variables,
    });
  }

  paymentSystem(variables: QueryPaymentSystemArgs): QueryResult<'paymentSystem'> {
    return this.apiService.query<Query<'paymentSystem'>, QueryPaymentSystemArgs>({
      query: queryPaymentSystem,
      variables,
    });
  }
}
