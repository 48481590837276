import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';
import {
  PaySystemIconName,
  PaySystemIconStrict,
  PaySystemIconString,
  PaySystemTypeIcon,
  PaySystemTypeStrict,
  PaySystemTypeString,
} from '@typings';

export const PAY_SYSTEM_TYPE: PaySystemTypeStrict = {
  CASH: 'CASH',
  CARD: 'CARD',
  VIRTUAL: 'VIRTUAL',
  YANDEX_EDA: 'YANDEX_EDA',
};

export const PAY_SYSTEM_TYPE_STRING: PaySystemTypeString = {
  CASH: 'Наличные',
  CARD: 'Карта',
  VIRTUAL: 'Нефискальный',
  YANDEX_EDA: 'YANDEX_EDA',
};

export const PAY_SYSTEM_TYPE_MENU_ITEMS: MenuItem[] = Object.keys(PAY_SYSTEM_TYPE).map((paySystemType) => ({
  id: paySystemType,
  type: 'item',
  label: PAY_SYSTEM_TYPE_STRING[paySystemType],
}));

export const PAY_SYSTEM_TYPE_ICON: PaySystemTypeIcon = {
  CASH: 'cash',
  CARD: 'card',
  VIRTUAL: 'store',
  YANDEX_EDA: 'yandex',
};

export const PAY_SYSTEM_LIST: AutocompleteOption[] = Object.keys(PAY_SYSTEM_TYPE).map((type) => ({
  id: type,
  label: PAY_SYSTEM_TYPE_STRING[type],
  type: 'item',
}));

export const PAY_SYSTEM_ICON: PaySystemIconStrict = {
  CASH: 'CASH',
  CARD: 'CARD',
  RUBLE: 'RUBLE',
  GUEST: 'GUEST',
  DELIVERY_CLUB: 'DELIVERY_CLUB',
  YANDEX_EATS: 'YANDEX_EATS',
};

export const PAY_SYSTEM_ICON_STRING: PaySystemIconString = {
  CASH: 'Наличные',
  CARD: 'Карта',
  RUBLE: 'Стандартная',
  GUEST: 'Cотрудники',
  DELIVERY_CLUB: 'Деливери',
  YANDEX_EATS: 'Яндекс',
};

export const PAY_SYSTEM_ICON_NAME: PaySystemIconName = {
  CASH: 'cash',
  CARD: 'card',
  RUBLE: 'store',
  GUEST: 'staff',
  DELIVERY_CLUB: 'delivery',
  YANDEX_EATS: 'yandex',
};

const ICONS_LIST = Object.keys(PAY_SYSTEM_ICON).filter(
  (icon) => icon !== PAY_SYSTEM_ICON.DELIVERY_CLUB && icon !== PAY_SYSTEM_ICON.YANDEX_EATS,
);

export const PAY_SYSTEM_ICON_LIST: AutocompleteOption[] = ICONS_LIST.map((type) => ({
  id: type,
  type: 'item',
  label: PAY_SYSTEM_ICON_STRING[type],
  iconLeft: PAY_SYSTEM_ICON_NAME[type],
}));
