import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { NotifyService } from '@services/shared';
import {
  MutationCreatePaymentSystemArgs,
  MutationDeletePaymentSystemArgs,
  MutationResult,
  MutationUpdatePaymentSystemArgs,
  PaymentSystem,
  PaymentSystemPage,
  QueryPaymentSystemArgs,
  QueryPaymentSystemsArgs,
} from '@typings';

import { PaySystemsApi } from './pay-systems.api';

@Injectable({
  providedIn: 'root',
})
export class PaySystemsStorage {
  constructor(private paySystemsApi: PaySystemsApi, private notifyService: NotifyService) {}

  createPaymentSystem(variables: MutationCreatePaymentSystemArgs): MutationResult<'createPaymentSystem'> {
    return this.paySystemsApi.createPaymentSystem(variables).pipe(
      this.notifyService.$notify({
        error: `Ошибка при создании способа оплаты`,
      }),
    );
  }

  updatePaymentSystem(variables: MutationUpdatePaymentSystemArgs): MutationResult<'updatePaymentSystem'> {
    return this.paySystemsApi.updatePaymentSystem(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при изменении способа оплаты',
      }),
    );
  }

  deletePaymentSystem(variables: MutationDeletePaymentSystemArgs): MutationResult<'deletePaymentSystem'> {
    return this.paySystemsApi.deletePaymentSystem(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении способа оплаты',
        success: 'Способ оплаты успешно удален',
      }),
    );
  }

  getPaymentSystemList(variables: QueryPaymentSystemsArgs): Observable<PaymentSystemPage> {
    return this.paySystemsApi.paymentSystems(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении способов оплаты',
      }),
      map((res) => res.data.paymentSystems),
    );
  }

  getPaymentSystem(variables: QueryPaymentSystemArgs): Observable<PaymentSystem> {
    return this.paySystemsApi.paymentSystem(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении способа оплаты',
      }),
      map((res) => res.data.paymentSystem),
    );
  }
}
