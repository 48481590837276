import { Injectable } from '@angular/core';

import {
  querySearchAddress,
  querySearchAddressRequisites,
  querySearchBankByRequestRequisites,
  querySearchOrganizationByRequest,
  querySearchOrganizationByRequestRequisites,
  querySearchOrganizationLegalSupplier,
} from '@api';
import { ApiService } from '@core/service';
import {
  Query,
  QueryResult,
  QuerySearchAddressByRequestArgs,
  QuerySearchBankByRequestArgs,
  QuerySearchOrganizationByRequestArgs,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class SearchApi {
  constructor(private apiService: ApiService) {}

  searchAddressByRequest(variables: QuerySearchAddressByRequestArgs): QueryResult<'searchAddressByRequest'> {
    return this.apiService.query<Query<'searchAddressByRequest'>, QuerySearchAddressByRequestArgs>({
      query: querySearchAddress,
      variables,
    });
  }

  searchOrganizationLegalSupplier(variables: QuerySearchOrganizationByRequestArgs): QueryResult<'searchOrganizationByRequest'> {
    return this.apiService.query<Query<'searchOrganizationByRequest'>, QuerySearchOrganizationByRequestArgs>({
      query: querySearchOrganizationLegalSupplier,
      variables,
    });
  }

  searchOrganization(variables: QuerySearchOrganizationByRequestArgs): QueryResult<'searchOrganizationByRequest'> {
    return this.apiService.query<Query<'searchOrganizationByRequest'>, QuerySearchOrganizationByRequestArgs>({
      query: querySearchOrganizationByRequest,
      variables,
    });
  }

  searchOrganizationRequisites(variables: QuerySearchOrganizationByRequestArgs): QueryResult<'searchOrganizationByRequest'> {
    return this.apiService.query<Query<'searchOrganizationByRequest'>, QuerySearchOrganizationByRequestArgs>({
      query: querySearchOrganizationByRequestRequisites,
      variables,
    });
  }

  searchAddressByRequestRequisites(variables: QuerySearchAddressByRequestArgs): QueryResult<'searchAddressByRequest'> {
    return this.apiService.query<Query<'searchAddressByRequest'>, QuerySearchAddressByRequestArgs>({
      query: querySearchAddressRequisites,
      variables,
    });
  }

  searchBankByRequest(variables: QuerySearchBankByRequestArgs): QueryResult<'searchBankByRequest'> {
    return this.apiService.query<Query<'searchBankByRequest'>, QuerySearchBankByRequestArgs>({
      query: querySearchBankByRequestRequisites,
      variables,
    });
  }
}
