import { Injectable } from '@angular/core';

import { queryLeftovers, queryWarehouseItemLeftovers } from '@api';
import { ExportReportService } from '@app/shared/service/export-report.service';
import { exportUrlByReportType } from '@constants';
import { ApiService } from '@services/core';
import { CoreSchema, Query, QueryLeftoversArgs, QueryResult } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class LeftoversApi {
  constructor(private apiService: ApiService, private exportReportService: ExportReportService) {}

  exportReportType = exportUrlByReportType;

  warehouseItemLeftovers(variables: CoreSchema.QueryWarehouseItemLeftoversArgs): QueryResult<'warehouseItemLeftovers'> {
    return this.apiService.query<Query<'warehouseItemLeftovers'>, CoreSchema.QueryWarehouseItemLeftoversArgs>({
      query: queryWarehouseItemLeftovers,
      variables,
    });
  }

  leftovers(variables: QueryLeftoversArgs): QueryResult<'leftovers'> {
    return this.apiService.query<Query<'leftovers'>, QueryLeftoversArgs>({
      query: queryLeftovers,
      variables,
    });
  }

  exportReport(leftoversId: string, warehouseId: string): void {
    this.exportReportService.exportReportWithHandler(
      this.exportReportType.LEAVING.url,
      {
        warehouseId,
        documentId: leftoversId,
        zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      this.exportReportType.LEAVING.fileName,
    );
  }
}
