import { ElementRef, Injectable, TemplateRef } from '@angular/core';

@Injectable()
export class BaseFilterService {
  private _labelElement: ElementRef;

  filterType: BaseType;
  panelTemplate: TemplateRef<unknown> | null;
  initialLabelWidth: number;

  set labelElement(el: ElementRef) {
    this._labelElement = el;
    this.initialLabelWidth = el.nativeElement.offsetWidth;
  }

  get labelElement() {
    return this._labelElement;
  }
}

export enum BaseType {
  'checkbox' = 0,
  'period' = 1,
  'icon' = 2,
}
