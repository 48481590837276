<nm-modal-base
  (ok)="employeesService.deleteEmployee(data.employee, data.callbackFn)"
  (cancel)="employeesService.hideModal()"
  okTitle="Удалить"
  cancelTitle="Отменить"
  okVariant="filled"
>
  <div modal-base-title>Удалить сотрудника</div>
  <div>Вы уверены, что хотите удалить сотрудника?</div>
</nm-modal-base>
