import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MovementsStorage } from '@app/modules/warehouse/services';
import { RootNavigationRoute, WAREHOUSE_ROUTE_BY_MOVEMENT_REASON, WarehouseRoute } from '@constants';
import { SessionStorage } from '@services/api';
import {
  AnalyticsWarehouseItemMoveHistoryDetailsFilterInput,
  AnalyticsWarehouseItemMoveHistoryDetailsPage,
  PageRequestInput,
  StockUnitMovementsByProduct,
  StockUnitMovementsByProductFilterInput,
  StockUnitMovementsByProductPage,
  StockUnitMovementsFilterInput,
  StockUnitMovementsPage,
  StockUnitMovementsSort,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class MovementsService {
  constructor(private movementsStorage: MovementsStorage, private router: Router, private sessionStorage: SessionStorage) {}

  getMovements(
    filter?: StockUnitMovementsFilterInput,
    sortList?: StockUnitMovementsSort[],
    pageRequest?: PageRequestInput,
  ): Observable<StockUnitMovementsPage> {
    return this.movementsStorage
      .stockUnitMovements({
        pageRequest,
        filter,
        sortList,
      })
      .pipe(map((res) => res.data.stockUnitMovements));
  }

  getMovementDetails(
    filter?: AnalyticsWarehouseItemMoveHistoryDetailsFilterInput,
    pageRequest?: PageRequestInput,
  ): Observable<AnalyticsWarehouseItemMoveHistoryDetailsPage> {
    return this.movementsStorage
      .movementDetailsPageable({
        pageRequest,
        filter: filter || {},
      })
      .pipe(map((res) => res.data.getAnalyticsWarehouseItemMoveHistoryDetailsByStockUnit));
  }

  getMovementDetailsV2(
    filter: StockUnitMovementsByProductFilterInput,
    pageRequest?: PageRequestInput,
  ): Observable<StockUnitMovementsByProductPage> {
    return this.movementsStorage
      .stockUnitMovementsByProduct({
        pageRequest,
        filter,
      })
      .pipe(map((res) => res.data.stockUnitMovementsByProduct));
  }

  openMovementPage(stockUnitId: string) {
    return this.router.navigate([this.sessionStorage.getOrgId(), RootNavigationRoute.warehouse, WarehouseRoute.movements, stockUnitId]);
  }

  openDocumentPage(stockUnitId: string, movement: StockUnitMovementsByProduct) {
    const { docId } = movement;

    if (!stockUnitId || !movement) {
      return;
    }

    const warehouseRoute = WAREHOUSE_ROUTE_BY_MOVEMENT_REASON[movement.reason];

    if (!warehouseRoute) {
      return;
    }

    this.router.navigate([
      this.sessionStorage.getOrgId(),
      RootNavigationRoute.warehouse,
      WarehouseRoute.movements,
      stockUnitId,
      warehouseRoute,
      docId,
    ]);
  }
}
