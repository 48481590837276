import { Injectable } from '@angular/core';

import {
  mutationAddInventoryProducts,
  mutationConfirmInventoryDoc,
  mutationConfirmInventoryDocument,
  mutationCreateInventoryDoc,
  mutationCreateInventoryDocument,
  mutationDeleteInventoryDoc,
  mutationDeleteInventoryDocument,
  mutationDuplicateInventoryDocument,
  mutationRejectInventoryDocument,
  mutationRemoveInventoryProducts,
  mutationRevertInventoryDoc,
  mutationUpdateInventoryDoc,
  mutationUpdateInventoryDocument,
  mutationUpdateInventoryProducts,
  queryAllInventoryDocumentsPageableV2,
  queryGetPossibleDocumentCreationTypes,
  queryInventoryDoc,
  queryInventoryDocs,
  queryInventoryDocument,
  queryInventoryProducts,
  queryProductsInventoryData,
  queryStockUnitInventoryData,
} from '@api';
import { ApiService } from '@core/service';
import {
  Mutation,
  MutationAddInventoryProductsArgs,
  MutationConfirmInventoryDocArgs,
  MutationConfirmInventoryDocumentArgs,
  MutationCreateInventoryDocArgs,
  MutationCreateInventoryDocumentArgs,
  MutationDeleteInventoryDocArgs,
  MutationDeleteInventoryDocumentArgs,
  MutationDuplicateInventoryDocumentArgs,
  MutationRejectInventoryDocumentArgs,
  MutationRemoveInventoryProductsArgs,
  MutationResult,
  MutationRevertInventoryDocArgs,
  MutationUpdateInventoryDocArgs,
  MutationUpdateInventoryDocumentArgs,
  MutationUpdateInventoryProductsArgs,
  Query,
  QueryAllInventoryDocumentsPageableV2Args,
  QueryGetPossibleDocumentCreationTypesArgs,
  QueryInventoryDocArgs,
  QueryInventoryDocsArgs,
  QueryInventoryDocumentArgs,
  QueryInventoryProductsArgs,
  QueryProductsInventoryDataArgs,
  QueryResult,
  QueryStockUnitInventoryDataArgs,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class InventoriesApi {
  constructor(private apiService: ApiService) {}

  createInventoryDocument(variables: MutationCreateInventoryDocumentArgs): MutationResult<'createInventoryDocument'> {
    return this.apiService.mutate<Mutation<'createInventoryDocument'>, MutationCreateInventoryDocumentArgs>({
      mutation: mutationCreateInventoryDocument,
      variables,
    });
  }

  updateInventoryDocument(variables: MutationUpdateInventoryDocumentArgs): MutationResult<'updateInventoryDocument'> {
    return this.apiService.mutate<Mutation<'updateInventoryDocument'>, MutationUpdateInventoryDocumentArgs>({
      mutation: mutationUpdateInventoryDocument,
      variables,
    });
  }

  deleteInventoryDocument(variables: MutationDeleteInventoryDocumentArgs): MutationResult<'deleteInventoryDocument'> {
    return this.apiService.mutate<Mutation<'deleteInventoryDocument'>, MutationDeleteInventoryDocumentArgs>({
      mutation: mutationDeleteInventoryDocument,
      variables,
    });
  }

  confirmInventoryDocument(variables: MutationConfirmInventoryDocumentArgs): MutationResult<'confirmInventoryDocument'> {
    return this.apiService.mutate<Mutation<'confirmInventoryDocument'>, MutationConfirmInventoryDocumentArgs>({
      mutation: mutationConfirmInventoryDocument,
      variables,
    });
  }

  rejectInventoryDocument(variables: MutationRejectInventoryDocumentArgs): MutationResult<'rejectInventoryDocument'> {
    return this.apiService.mutate<Mutation<'rejectInventoryDocument'>, MutationRejectInventoryDocumentArgs>({
      mutation: mutationRejectInventoryDocument,
      variables,
    });
  }

  duplicateInventoryDocument(variables: MutationDuplicateInventoryDocumentArgs): MutationResult<'duplicateInventoryDocument'> {
    return this.apiService.mutate<Mutation<'duplicateInventoryDocument'>, MutationDuplicateInventoryDocumentArgs>({
      mutation: mutationDuplicateInventoryDocument,
      variables,
    });
  }

  allInventoryDocumentsPageableV2(variables?: QueryAllInventoryDocumentsPageableV2Args): QueryResult<'allInventoryDocumentsPageableV2'> {
    return this.apiService.query<Query<'allInventoryDocumentsPageableV2'>, QueryAllInventoryDocumentsPageableV2Args>({
      query: queryAllInventoryDocumentsPageableV2,
      variables,
    });
  }

  inventoryDocument(variables: QueryInventoryDocumentArgs): QueryResult<'inventoryDocument'> {
    return this.apiService.query<Query<'inventoryDocument'>, QueryInventoryDocumentArgs>({
      query: queryInventoryDocument,
      variables,
    });
  }

  stockUnitInventoryData(variables: QueryStockUnitInventoryDataArgs): QueryResult<'stockUnitInventoryData'> {
    return this.apiService.query<Query<'stockUnitInventoryData'>, QueryStockUnitInventoryDataArgs>({
      query: queryStockUnitInventoryData,
      variables,
    });
  }

  getPossibleDocumentCreationTypes(variables: QueryGetPossibleDocumentCreationTypesArgs): QueryResult<'getPossibleDocumentCreationTypes'> {
    return this.apiService.query<Query<'getPossibleDocumentCreationTypes'>, QueryGetPossibleDocumentCreationTypesArgs>({
      query: queryGetPossibleDocumentCreationTypes,
      variables,
    });
  }

  inventoryProducts(variables: QueryInventoryProductsArgs): QueryResult<'inventoryProducts'> {
    return this.apiService.query<Query<'inventoryProducts'>, QueryInventoryProductsArgs>({
      query: queryInventoryProducts,
      variables,
    });
  }

  productsInventoryData(variables: QueryProductsInventoryDataArgs): QueryResult<'productsInventoryData'> {
    return this.apiService.query<Query<'productsInventoryData'>, QueryProductsInventoryDataArgs>({
      query: queryProductsInventoryData,
      variables,
    });
  }

  inventoryDocs(variables: QueryInventoryDocsArgs): QueryResult<'inventoryDocs'> {
    return this.apiService.query<Query<'inventoryDocs'>, QueryInventoryDocsArgs>({
      query: queryInventoryDocs,
      variables,
    });
  }

  inventoryDoc(variables: QueryInventoryDocArgs): QueryResult<'inventoryDoc'> {
    return this.apiService.query<Query<'inventoryDoc'>, QueryInventoryDocArgs>({
      query: queryInventoryDoc,
      variables,
    });
  }

  createInventoryDoc(variables: MutationCreateInventoryDocArgs): MutationResult<'createInventoryDoc'> {
    return this.apiService.mutate<Mutation<'createInventoryDoc'>, MutationCreateInventoryDocArgs>({
      mutation: mutationCreateInventoryDoc,
      variables,
    });
  }

  updateInventoryDoc(variables: MutationUpdateInventoryDocArgs): MutationResult<'updateInventoryDoc'> {
    return this.apiService.mutate<Mutation<'updateInventoryDoc'>, MutationUpdateInventoryDocArgs>({
      mutation: mutationUpdateInventoryDoc,
      variables,
    });
  }

  confirmInventoryDoc(variables: MutationConfirmInventoryDocArgs): MutationResult<'confirmInventoryDoc'> {
    return this.apiService.mutate<Mutation<'confirmInventoryDoc'>, MutationConfirmInventoryDocArgs>({
      mutation: mutationConfirmInventoryDoc,
      variables,
    });
  }

  revertInventoryDoc(variables: MutationRevertInventoryDocArgs): MutationResult<'revertInventoryDoc'> {
    return this.apiService.mutate<Mutation<'revertInventoryDoc'>, MutationRevertInventoryDocArgs>({
      mutation: mutationRevertInventoryDoc,
      variables,
    });
  }

  deleteInventoryDoc(variables: MutationDeleteInventoryDocArgs): MutationResult<'deleteInventoryDoc'> {
    return this.apiService.mutate<Mutation<'deleteInventoryDoc'>, MutationDeleteInventoryDocArgs>({
      mutation: mutationDeleteInventoryDoc,
      variables,
    });
  }

  addInventoryProducts(variables: MutationAddInventoryProductsArgs): MutationResult<'addInventoryProducts'> {
    return this.apiService.mutate<Mutation<'addInventoryProducts'>, MutationAddInventoryProductsArgs>({
      mutation: mutationAddInventoryProducts,
      variables,
    });
  }

  updateInventoryProducts(variables: MutationUpdateInventoryProductsArgs): MutationResult<'updateInventoryProducts'> {
    return this.apiService.mutate<Mutation<'updateInventoryProducts'>, MutationUpdateInventoryProductsArgs>({
      mutation: mutationUpdateInventoryProducts,
      variables,
    });
  }

  removeInventoryProducts(variables: MutationRemoveInventoryProductsArgs): MutationResult<'removeInventoryProducts'> {
    return this.apiService.mutate<Mutation<'removeInventoryProducts'>, MutationRemoveInventoryProductsArgs>({
      mutation: mutationRemoveInventoryProducts,
      variables,
    });
  }
}
