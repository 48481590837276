import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

import { SVG_ICONS_TYPE } from '@constants';

const COMPONENT_CLASS = 'nm-chip';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nm-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent {
  @Input() testId: string;
  @Input() @HostBinding('class.no-shadow') disabled: boolean = false;
  @Input() @HostBinding('class.dragged') dragged: boolean = false;
  @Input() @HostBinding('class.selected') selected: boolean = false;
  @Input() @HostBinding('class.elevated') elevated: boolean = false;
  @Input() iconLeft: SVG_ICONS_TYPE;
  @Input() iconRight: SVG_ICONS_TYPE;
  @Input() image: string;
  @Input() showAvatar: boolean = false;
  @Input() avatarTitle: string = '';
  @Input() avatarImageUrl: string | SafeUrl = '';

  @Output() rightIconClick = new EventEmitter<Event>();
  @Output() clickAvatar = new EventEmitter<void>();
  @Output() avatarImageLoad = new EventEmitter<void>();
  @Output() avatarImageError = new EventEmitter<void>();

  get chipClasses(): string[] {
    const classes = [COMPONENT_CLASS];
    if (this.dragged) {
      classes.push(`${COMPONENT_CLASS}-dragged`);
    }
    if (this.elevated) {
      classes.push(`${COMPONENT_CLASS}-elevated`);
    }
    if (this.iconLeft) {
      classes.push(`${COMPONENT_CLASS}-icon-left`);
    }
    if (this.iconRight) {
      classes.push(`${COMPONENT_CLASS}-icon-right`);
    }

    return classes;
  }

  constructor() {}

  onRightIconClick(event: Event): void {
    event.stopPropagation();
    if (this.disabled) {
      return;
    }
    this.rightIconClick.emit(event);
  }

  onAvatarClick(): void {
    this.clickAvatar.emit();
  }

  onAvatarImageLoad(): void {
    this.avatarImageLoad.emit();
  }

  onAvatarImageError(): void {
    this.avatarImageError.emit();
  }

  blur(matChip: HTMLElement): void {
    const activeElement = matChip.ownerDocument.activeElement;

    if (activeElement === matChip) {
      matChip.blur();
    }
  }
}
