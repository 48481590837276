import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { CurrencyPipe } from '@app/shared/pipe/currency.pipe';

import { NumberSeparatePipe } from './number-separate/number-separate.pipe';
import { AnalyticsChartBarDataPipe } from './chart-bar-data.pipe';
import { AnalyticsChartDonutDataPipe } from './chart-donut-data.pipe';
import { ChartLineDataPipe } from './chart-line-data.pipe';
import { AnalyticsChartPopularDataPipe } from './chart-popular-data.pipe';
import { ChartStackedBarDataPipe } from './chart-stacked-bar-data.pipe';
import { CheckDatePipe } from './check-date.pipe';
import { CustomCurrencyPipe } from './custom-currency.pipe';
import { DeclensionPipe } from './declension.pipe';
import { DocumentItemsCurrencyPipe } from './document-items-currency.pipe';
import { FilterPipe } from './filter.pipe';
import { FilterMenuItemsPipe } from './filter-menu-items.pipe';
import { IdentityTypePipe } from './identity-type.pipe';
import { ISO8601DurationToStringPipe } from './ISO8601-duration-to-string.pipe';
import { LimitArrayLengthPipe } from './limit-array-length.pipe';
import { MenuTypePipe } from './menu-type.pipe';
import { MyDatePipe } from './my-date.pipe';
import { NextPaymentDatePipe } from './next-payment-date.pipe';
import { NoOffsetDatePipe } from './no-offset-date.pipe';
import { OrderStatusPipe } from './order-status.pipe';
import { PaySystemTypePipe } from './paysystem-type.pipe';
import { PluralizePipe } from './pluralize.pipe';
import { SafePipe } from './safe.pipe';
import { SumDocumentItemsAmountPipe } from './sum-document-items-amount.pipe';
import { SumInventoryAmountPipe } from './sum-inventory-amount.pipe';
import { toBusinessMenuSectionLib } from './to-business-menu-section-lib.pipe';
import { ToCustomerGroupsPipe } from './to-customer-groups.pipe';
import { ToMenuItemLibPipe } from './to-menu-item-lib.pipe';
import { ToStoresPipe } from './to-stores.pipe';
import { TruncateFractionalPipe } from './truncate-fractional.pipe';
import { UnitTypePipe } from './unit-type.pipe';
import { UnitTypeAutocompleteItemsPipe } from './unit-type-autocomplete-items.pipe';

const modulePipes = [
  ChartLineDataPipe,
  CheckDatePipe,
  ISO8601DurationToStringPipe,
  NextPaymentDatePipe,
  TruncateFractionalPipe,
  UnitTypePipe,
  toBusinessMenuSectionLib,
  ToStoresPipe,
  ToMenuItemLibPipe,
  SumDocumentItemsAmountPipe,
  ToCustomerGroupsPipe,
  AnalyticsChartBarDataPipe,
  AnalyticsChartDonutDataPipe,
  AnalyticsChartPopularDataPipe,
  UnitTypePipe,
  DeclensionPipe,
  MyDatePipe,
  MenuTypePipe,
  NumberSeparatePipe,
  CurrencyPipe,
  CustomCurrencyPipe,
  PaySystemTypePipe,
  PluralizePipe,
  ChartStackedBarDataPipe,
  OrderStatusPipe,
  SafePipe,
  FilterPipe,
  IdentityTypePipe,
  DocumentItemsCurrencyPipe,
  SumInventoryAmountPipe,
  FilterMenuItemsPipe,
  UnitTypeAutocompleteItemsPipe,
  NoOffsetDatePipe,
  LimitArrayLengthPipe,
];

@NgModule({
  declarations: [...modulePipes],
  exports: [...modulePipes],
  providers: [...modulePipes],
  imports: [CommonModule, DatePipe],
})
export class SharedPipesModule {}
