import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ControlPanelComponent } from './control-panel.component';

@NgModule({
  declarations: [ControlPanelComponent],
  imports: [CommonModule],
  exports: [ControlPanelComponent],
})
export class ControlPanelModule {}
