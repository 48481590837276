import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { SharedPipesModule } from '@app/shared/pipe/pipes.module';
import { SharedModule } from '@app/shared/shared.module';

import { BaseLayoutModule } from './base-layout/base-layout.module';
import { SidenavLayoutModule } from './sidenav-layout/sidenav-layout.module';

@NgModule({
  imports: [
    CommonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatButtonModule,
    MatInputModule,
    BaseLayoutModule,
    RouterModule,
    SharedModule,
    SharedPipesModule,
    SidenavLayoutModule,
  ],
})
export class LayoutModule {}
