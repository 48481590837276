import { RootNavigationRoute, RootNavigationRouteName, ROUTE_CREATE_NEW } from '@constants';

export enum SettingsRoute {
  organization = 'organization',
  stores = 'stores',
  tablesAndBooking = 'stores-table',
  paymentSystems = 'payment-systems',
  receipts = 'stores-receipt',
  subscriptions = 'subscriptions',
  devices = 'devices',
  subscriptionChange = 'subscription-change',
  order = 'order',
}

export enum SettingsStoreRoute {
  payments = 'payments',
  info = 'info',
  requisites = 'requisites',
  tables = 'tables',
  receipts = 'receipts',
}

export enum SettingsRouteName {
  organization = 'Общие',
  stores = 'Заведения',
  tablesAndBooking = 'Столы и бронирование',
  paymentSystems = 'Способы оплаты',
  receipts = 'Чеки',
  subscriptions = 'Подписки',
  devices = 'Устройства',
}

export enum SettingsRouteParam {
  storeId = 'idStore',
  paymentSystemId = 'idPaymentSystem',
  invoiceId = 'idInvoice',
  servicePlanId = 'servicePlanId',
  orderId = 'orderId',
  success = 'success',
  applicationId = 'applicationId',
  confId = 'confId',
  yandex = 'yandex',
  docsInBox = 'docsInBox',
  clientBot = 'clientBot',
  businessBot = 'businessBot',
}

export enum SettingsSubscriptionsRoute {
  info = 'info',
  invoices = 'invoices',
}

export enum SubscriptionsPaymentResultRoute {
  success = 'success',
  error = 'error',
}

export const SETTINGS_ITEM_ORGANIZATION = {
  title: SettingsRouteName.organization,
  route: SettingsRoute.organization,
};

export const SETTINGS_ITEM_RECEIPTS = {
  title: SettingsRouteName.receipts,
  route: SettingsRoute.receipts,
  routes: [
    {
      title: 'Новое заведение',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Заведение',
      route: SettingsRouteParam.storeId,
    },
  ],
};

export const SETTINGS_ITEM_TABLES = {
  title: SettingsRouteName.tablesAndBooking,
  route: SettingsRoute.tablesAndBooking,
  routes: [
    {
      title: 'Новое заведение',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Заведение',
      route: SettingsRouteParam.storeId,
    },
  ],
};

export const SETTINGS_ITEM_STORES = {
  title: SettingsRouteName.stores,
  route: SettingsRoute.stores,
  routes: [
    {
      title: 'Новое заведение',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Заведение',
      route: SettingsRouteParam.storeId,
    },
  ],
};

export const SETTINGS_ITEM_PAYMENT_SYSTEMS = {
  title: SettingsRouteName.paymentSystems,
  route: SettingsRoute.paymentSystems,
  routes: [
    {
      title: 'Новый способ оплаты',
      route: ROUTE_CREATE_NEW,
    },
    {
      title: 'Способ оплаты',
      route: SettingsRouteParam.paymentSystemId,
    },
  ],
};

export const SETTINGS_ITEM_SUBSCRIPTIONS = {
  title: SettingsRouteName.subscriptions,
  route: SettingsRoute.subscriptions,
  routes: [
    {
      title: SettingsRouteName.subscriptions,
      route: SettingsSubscriptionsRoute.info,
    },
    {
      title: SettingsRouteName.subscriptions,
      route: SettingsSubscriptionsRoute.invoices,
    },
  ],
};

export const SETTING_ITEM_DEVICES = {
  title: SettingsRouteName.devices,
  route: SettingsRoute.devices,
};

export const SETTING_ITEM_APPLICATION_SHOP = {
  title: RootNavigationRouteName.application,
  route: RootNavigationRoute.application,
  routes: [
    {
      title: 'Яндекс',
      route: `${SettingsRouteParam.applicationId}/${SettingsRouteParam.yandex}`,
      routes: [
        {
          title: 'Яндекс',
          skipParent: true,
          route: 'info',
        },
        {
          title: 'Яндекс',
          skipParent: true,
          route: 'integrations',
        },
        {
          title: 'Подключение Яндекс.Еда',
          route: ROUTE_CREATE_NEW,
        },
        {
          title: 'Подключение Яндекс.Еда',
          route: SettingsRouteParam.confId,
        },
      ],
    },
    {
      title: 'DocsInBox',
      route: `${SettingsRouteParam.applicationId}/${SettingsRouteParam.docsInBox}`,
      routes: [
        {
          title: 'DocsInBox',
          skipParent: true,
          route: 'info',
        },
        {
          title: 'DocsInBox',
          skipParent: true,
          route: 'integrations',
        },
        {
          title: 'Подключение DocsInBox',
          route: ROUTE_CREATE_NEW,
        },
        {
          title: 'Подключение DocsInBox',
          route: SettingsRouteParam.confId,
        },
      ],
    },
    {
      title: 'Телеграм консьерж-бот',
      route: `${SettingsRouteParam.applicationId}/${SettingsRouteParam.clientBot}`,
      routes: [
        {
          title: 'Телеграм консьерж-бот',
          skipParent: true,
          route: 'info',
        },
        {
          title: 'Телеграм консьерж-бот',
          skipParent: true,
          route: 'integrations',
        },
      ],
    },
    {
      title: 'Telegram бот для бизнеса',
      route: `${SettingsRouteParam.applicationId}/${SettingsRouteParam.businessBot}`,
      routes: [
        {
          title: 'Telegram бот для бизнеса',
          skipParent: true,
          route: 'info',
        },
        {
          title: 'Telegram бот для бизнеса',
          skipParent: true,
          route: 'integrations',
        },
        {
          title: 'Подключение Telegram бота для бизнеса',
          route: ROUTE_CREATE_NEW,
        },
        {
          title: 'Подключение Telegram бота для бизнеса',
          route: SettingsRouteParam.confId,
        },
      ],
    },
  ],
};

export const SETTINGS_ITEM_SUBSCRIPTION_CHANGE = {
  title: '',
  route: SettingsRoute.subscriptionChange,
  hideInDrawer: true,
  isStacking: true,
  substituteItem: SETTINGS_ITEM_SUBSCRIPTIONS,
};

export const SETTINGS_CHILD_ITEMS = [
  SETTINGS_ITEM_ORGANIZATION,
  SETTINGS_ITEM_STORES,
  SETTINGS_ITEM_PAYMENT_SYSTEMS,
  SETTINGS_ITEM_TABLES,
  SETTINGS_ITEM_RECEIPTS,
  SETTINGS_ITEM_SUBSCRIPTIONS,
  SETTING_ITEM_DEVICES,
  SETTINGS_ITEM_SUBSCRIPTION_CHANGE,
];

export const SETTINGS_CHILD_ITEMS_WITH_APPLICATION = [
  SETTINGS_ITEM_ORGANIZATION,
  SETTINGS_ITEM_STORES,
  SETTINGS_ITEM_PAYMENT_SYSTEMS,
  SETTINGS_ITEM_TABLES,
  SETTINGS_ITEM_RECEIPTS,
  SETTINGS_ITEM_SUBSCRIPTIONS,
  SETTING_ITEM_DEVICES,
  SETTINGS_ITEM_SUBSCRIPTION_CHANGE,
];
