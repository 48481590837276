import { MovementReason, MovementReasonStrict, MovementReasonString, ValueType } from '@typings';

export const WAREHOUSE_ITEM_REASON_EVENT: MovementReasonStrict = {
  ARRIVAL_DOCUMENT: 'ARRIVAL_DOCUMENT',
  INVENTORY_DOCUMENT: 'INVENTORY_DOCUMENT',
  WRITE_OFF_DOCUMENT: 'WRITE_OFF_DOCUMENT',
  MOVE_DOCUMENT: 'MOVE_DOCUMENT',
  PACK: 'PACK',
  SOLD: 'SOLD',
  UNPACK: 'UNPACK',
};

export const WAREHOUSE_ITEM_REASON_STRING: MovementReasonString = {
  ARRIVAL_DOCUMENT: 'Поставка',
  INVENTORY_DOCUMENT: 'Инвентаризация',
  WRITE_OFF_DOCUMENT: 'Списание',
  MOVE_DOCUMENT: 'Перемещение',
  PACK: 'PACK',
  SOLD: 'Продажа',
  UNPACK: 'UNPACK',
};

export const WAREHOUSE_ITEM_REASONS_FILTER: MovementReason[] = [
  'ARRIVAL_DOCUMENT',
  'INVENTORY_DOCUMENT',
  'WRITE_OFF_DOCUMENT',
  'MOVE_DOCUMENT',
  'SOLD',
];

export const getWarehouseItemReasonEvent = (reason: string): MovementReason | undefined => {
  return Object.keys(WAREHOUSE_ITEM_REASON_EVENT).find((key) => key === reason);
};

export const getWarehouseItemReasonEventString = (reason: string): string | undefined => {
  const event = getWarehouseItemReasonEvent(reason);

  return event && WAREHOUSE_ITEM_REASON_STRING[event];
};

export const WarehouseItemReasonEventTypes: ValueType[] = [
  {
    name: 'Поставка',
    value: WAREHOUSE_ITEM_REASON_EVENT.ARRIVAL_DOCUMENT,
    type: 'WarehouseItemReasonEvent',
  },
  {
    name: 'Инвентаризация',
    value: WAREHOUSE_ITEM_REASON_EVENT.INVENTORY_DOCUMENT,
    type: 'WarehouseItemReasonEvent',
  },
  {
    name: 'Списание',
    value: WAREHOUSE_ITEM_REASON_EVENT.WRITE_OFF_DOCUMENT,
    type: 'WarehouseItemReasonEvent',
  },
  {
    name: 'Перемещение',
    value: WAREHOUSE_ITEM_REASON_EVENT.MOVE_DOCUMENT,
    type: 'WarehouseItemReasonEvent',
  },
  {
    name: 'Продажа',
    value: WAREHOUSE_ITEM_REASON_EVENT.SOLD,
    type: 'WarehouseItemReasonEvent',
  },
];

export const WarehouseItemReasonAllEventTypes: ValueType[] = [
  {
    name: 'Поставка',
    value: WAREHOUSE_ITEM_REASON_EVENT.ARRIVAL_DOCUMENT,
    type: 'WarehouseItemReasonEvent',
  },
  {
    name: 'Инвентаризация',
    value: WAREHOUSE_ITEM_REASON_EVENT.INVENTORY_DOCUMENT,
    type: 'WarehouseItemReasonEvent',
  },
  {
    name: 'Списание',
    value: WAREHOUSE_ITEM_REASON_EVENT.WRITE_OFF_DOCUMENT,
    type: 'WarehouseItemReasonEvent',
  },
  {
    name: 'Перемещение',
    value: WAREHOUSE_ITEM_REASON_EVENT.MOVE_DOCUMENT,
    type: 'WarehouseItemReasonEvent',
  },
  {
    name: 'Продажа',
    value: WAREHOUSE_ITEM_REASON_EVENT.SOLD,
    type: 'WarehouseItemReasonEvent',
  },
  {
    name: 'PACK',
    value: WAREHOUSE_ITEM_REASON_EVENT.PACK,
    type: 'WarehouseItemReasonEvent',
  },
  {
    name: 'UNPACK',
    value: WAREHOUSE_ITEM_REASON_EVENT.UNPACK,
    type: 'WarehouseItemReasonEvent',
  },
];
