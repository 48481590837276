import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '@app/shared/component/button/button.module';

import { SvgIconModule } from '../../svg-icon/svg-icon.module';

import { ModalBaseComponent } from './modal-base.component';

@NgModule({
  imports: [CommonModule, ButtonModule, SvgIconModule],
  exports: [ModalBaseComponent],
  declarations: [ModalBaseComponent],
  providers: [],
})
export class ModalBaseModule {}
