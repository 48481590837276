export const environment = {
  production: true,
  useMocks: false,
  baseUrl: '/graphql',
  baseUrlWs: `wss://${location.host}/subscriptions`,
  baseExportReportUrl: '/api/v1',
  baseImportUrl: window.location.origin.includes('stage')
    ? 'https://py-import.stage-yc.nomia.tech/pyimport/api'
    : 'https://erp.nomia.app/pyimport/api',
  authUrl: window.location.origin.includes('stage') ? 'https://sso.stage-yc.nomia.tech' : 'https://sso.nomia.tech',
};
