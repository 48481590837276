import { CoreSchema } from '@typings';

export enum ReportInvervalMode {
  HOURS = 'hours',
  DAYS = 'days',
  MONTHS = 'months',
}

export enum ReportSummaryMode {
  HOURS = 'hours',
  DAYS = 'weekdays',
}

export enum ReportGroupPreset {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export type ReportGroupPresetItem = {
  name: string;
  preset: ReportGroupPreset;
};

export type ReportSummaryItem = {
  name: string;
  mode: ReportSummaryMode;
};

export type ReportInvervalModeRuleType = {
  mode: ReportInvervalMode;
  from: number;
  to: number;
};

export type TimeSeriesInput = CoreSchema.TimeSeriesInput;
export type DateGrouping = CoreSchema.DateGrouping;
