<nm-modal-base
  (ok)="archive()"
  (cancel)="warehouseService.hideModal()"
  okTitle="Переместить"
  cancelTitle="Отменить"
  okVariant="filled"
>
  <div modal-base-title>Переместить в архив</div>
  <div class="warehouse-archive-dialog">
    <span>{{ message }}</span>
    <nm-form *ngIf="hasStores" [form]="form">
      <nm-autocomplete
        ngDefaultControl
        #warehouseAutocomplete
        placeholder="Выберите склад"
        label="Склад"
        [required]="true"
        [search]="true"
        [searchFn]="searchWarehousesService.searchFn"
        [options]="searchWarehousesService.options$"
        [loadingState]="searchWarehousesService.loadingState$"
        (loadMore)="searchWarehousesService.loadItems()"
        [returnObjects]="true"
        [formControl]="newWarehouse"
        [error]="validationErrorsService.isControlDirtyAndInvalid(newWarehouse)"
        [hint]="
          validationErrorsService.validateField(newWarehouse) ||
          newWarehouse.getError('newWarehouse') ||
          'Склад, к которому будут привязаны заведения после архивации'
        "
        (menuOpen)="onOpenWarehouseAutocomplete(warehouseAutocomplete)"
      ></nm-autocomplete>
    </nm-form>
  </div>
</nm-modal-base>
