import { Injectable } from '@angular/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import { map, Observable } from 'rxjs';

import {
  AddressSearchDto,
  BankSearchResponse,
  OrganizationSearchDto,
  QueryResult,
  QuerySearchAddressByRequestArgs,
  QuerySearchBankByRequestArgs,
  QuerySearchOrganizationByRequestArgs,
} from '@typings';

import { SearchApi } from './search.api';

@Injectable({
  providedIn: 'root',
})
export class SearchStorage {
  constructor(private searchApi: SearchApi) {}

  searchAddressByRequest(variables: QuerySearchAddressByRequestArgs): QueryResult<'searchAddressByRequest'> {
    return this.searchApi.searchAddressByRequest(variables);
  }

  searchOrganizationLegalSupplier(variables: QuerySearchOrganizationByRequestArgs): QueryResult<'searchOrganizationByRequest'> {
    return this.searchApi.searchOrganizationLegalSupplier(variables);
  }

  searchOrganization(variables: QuerySearchOrganizationByRequestArgs): QueryResult<'searchOrganizationByRequest'> {
    return this.searchApi.searchOrganization(variables);
  }

  searchOrganizationRequisites(variables: QuerySearchOrganizationByRequestArgs): Observable<Maybe<OrganizationSearchDto>[]> {
    return this.searchApi
      .searchOrganizationRequisites(variables)
      .pipe(map((res) => res.data.searchOrganizationByRequest.organizationSearchDTOList));
  }

  searchAddressRequisites(variables: QuerySearchAddressByRequestArgs): Observable<Maybe<AddressSearchDto>[]> {
    return this.searchApi
      .searchAddressByRequestRequisites(variables)
      .pipe(map((res) => res.data.searchAddressByRequest.addressSearchDTOList));
  }

  searchBankRequisites(variables: QuerySearchBankByRequestArgs): Observable<Maybe<BankSearchResponse>[]> {
    return this.searchApi.searchBankByRequest(variables).pipe(map((res) => res.data.searchBankByRequest));
  }
}
