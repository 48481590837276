import { gql } from 'apollo-angular';

export const queryRoles = gql`
  query roles($filter: RoleFilterInput, $pageRequestInput: PageRequestInput!) {
    roles(filter: $filter, pageRequestInput: $pageRequestInput) {
      total
      pageNumber
      pageSize
      totalPages
      content {
        id
        name
        scopes
      }
    }
  }
`;

export const queryRolesByUser = gql`
  query rolesByUser($userId: UUID!, $filter: RoleFilterInput, $pageRequestInput: PageRequestInput!) {
    rolesByUser(userId: $userId, filter: $filter, pageRequestInput: $pageRequestInput) {
      total
      pageNumber
      pageSize
      totalPages
      content {
        id
        name
        scopes
      }
    }
  }
`;

export const queryRole = gql`
  query role($id: UUID!) {
    role(id: $id) {
      id
      name
      scopes
    }
  }
`;

export const mutationCreateRole = gql`
  mutation createRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      status {
        errorMessage
        result
      }
      createdRole {
        id
        name
        scopes
      }
    }
  }
`;

export const mutationUpdateRole = gql`
  mutation updateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      status {
        errorMessage
        result
      }
      updatedRole {
        id
        name
        scopes
      }
    }
  }
`;

export const mutationDeleteRole = gql`
  mutation deleteRole($id: UUID!) {
    deleteRole(id: $id) {
      status {
        errorMessage
        result
      }
    }
  }
`;
