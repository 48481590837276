import { StoreCardType } from '@typings';

export const DAYS_IN_TRIAL_PERIOD = 14;
export const DAYS_IN_MONTH = 30;
export const DAYS_IN_YEAR = 365;

export const FREE_FEATURES = [
  {
    id: 1,
    name: 'Формирование каталога',
  },
  {
    id: 2,
    name: 'Формирование меню',
  },
  {
    id: 3,
    name: 'Неограниченное количество точек продаж',
  },
  {
    id: 4,
    name: 'Ввод базовых скидок',
  },
  {
    id: 5,
    name: 'Управление сотрудниками',
  },
  {
    id: 6,
    name: 'Тех. поддержка',
  },
  {
    id: 7,
    name: 'Подключение принтеров / ККТ / сканеров',
  },
];

export const ENTITY_CARDS: StoreCardType[] = [
  {
    id: 1,
    title: 'To Go',
    description: 'Онлайн/офлайн точка продаж, подходит для небольшого магазина любого формата',
    storeType: 'KAFE',
  },
  {
    id: 2,
    title: 'Ресторан',
    description: 'Официанты, цеха, несколько точек продаж, работа на доставку, зарплаты сотрудников',
    storeType: 'RESTAURANT',
  },
  {
    id: 3,
    title: 'Магазин',
    description: 'Онлайн/офлайн точка продаж, подходит для небольшого магазина любого формата',
    storeType: 'SHOP',
  },
];
