import { gql } from 'apollo-angular';

import { fragmentMutationResult, fragmentRequestResult } from './mutations';

export const fragmentMoveDocument = gql`
  fragment MoveDocument on MoveDocument {
    id
    organization {
      id
      name
    }
    documentItems {
      name
      factQuantity
      stockUnit {
        id
        name
        unit
        unitTypesMatchList
        primePrice {
          amountValue
          currencyUnit
        }
      }
      amount
    }
    docNumber
    date
    creationDate
    typeDocument
    status
    description
    from {
      id
      name
      addressName
      description
      phone
      businessHours
      shippingCenter
      pickupLocation
      active
    }
    to {
      id
      name
      addressName
      description
      phone
      businessHours
      shippingCenter
      pickupLocation
      active
    }
  }
`;

export const fragmentMoveDoc = gql`
  fragment MoveDoc on MoveDoc {
    id
    date
    description
    number
    status
    sum
    revertedDate
    storageRoomFrom {
      id
      name
      archived
    }
    storageRoomTo {
      id
      name
      archived
    }
  }
`;

export const queryAllMoveDocumentsPageable = gql`
  query allMoveDocumentsPageableV2(
    $filter: InventoryDocumentFilterInputV2
    $pageRequest: PageRequestInput!
    $sort: [AllMoveDocumentsPageableV2Sort!]
  ) {
    allMoveDocumentsPageableV2(filter: $filter, pageRequest: $pageRequest, sort: $sort) {
      content {
        id
        docNumber
        date
        from {
          name
        }
        to {
          name
        }
        documentItems {
          stockUnit {
            primePrice {
              currencyUnit
            }
          }
          amount
        }
        status
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
`;

export const mutationCreateMoveDocument = gql`
  mutation createMoveDocument($moveDocumentCreateInput: MoveDocumentCreateInput!) {
    createMoveDocument(moveDocumentCreateInput: $moveDocumentCreateInput) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationUpdateMoveDocument = gql`
  mutation updateMoveDocument($moveDocumentUpdateInput: MoveDocumentUpdateInput!) {
    updateMoveDocument(moveDocumentUpdateInput: $moveDocumentUpdateInput) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationDeleteMoveDocument = gql`
  mutation deleteMoveDocument($moveDocumentId: UUID!) {
    deleteMoveDocument(moveDocumentId: $moveDocumentId) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const mutationConfirmMoveDocument = gql`
  mutation confirmMoveDocumentV2($moveDocumentId: UUID!) {
    confirmMoveDocumentV2(moveDocumentId: $moveDocumentId) {
      output {
        ...MoveDocument
      }
      ...RequestResult
    }
  }
  ${fragmentRequestResult}
  ${fragmentMoveDocument}
`;

export const mutationRejectMoveDocument = gql`
  mutation rejectMoveDocument($moveDocumentId: UUID!) {
    rejectMoveDocument(moveDocumentId: $moveDocumentId) {
      ...MutationResult
    }
  }
  ${fragmentMutationResult}
`;

export const queryMoveDocument = gql`
  query moveDocument($id: UUID!) {
    moveDocument(id: $id) {
      ...MoveDocument
    }
  }
  ${fragmentMoveDocument}
`;

export const mutationDuplicateMoveDocument = gql`
  mutation duplicateMoveDocument($documentId: UUID!) {
    duplicateMoveDocument(documentId: $documentId) {
      output {
        ...MoveDocument
      }
      ...RequestResult
    }
  }
  ${fragmentRequestResult}
  ${fragmentMoveDocument}
`;

export const queryMoveDoc = gql`
  query moveDoc($id: UUID!) {
    moveDoc(id: $id) {
      id
      date
      description
      number
      status
      sum
      storageRoomFrom {
        id
        name
      }
      storageRoomTo {
        id
        name
      }
    }
  }
`;

export const queryMoveDocs = gql`
  query moveDocs($filter: MoveDocFilter!, $pageRequest: PageableInput!, $sort: [MoveDocSort!]) {
    moveDocs(filter: $filter, pageRequest: $pageRequest, sort: $sort) {
      content {
        ...MoveDoc
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
  ${fragmentMoveDoc}
`;

export const queryMoveProducts = gql`
  query moveProducts($filter: MoveProductsFilterInput!, $pageRequest: PageableInput!) {
    moveProducts(filter: $filter, pageRequest: $pageRequest) {
      content {
        id
        quantity
        unit
        sum
        product {
          id
          name
          unit
          quantity
          primePrice {
            amountValue
          }
          weightedAveragePrimePrice {
            amountValue
          }
        }
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
`;

export const mutationCreateMoveDoc = gql`
  mutation createMoveDoc($input: CreateMoveDocInput!) {
    createMoveDoc(input: $input) {
      result
      errorMessage
      output {
        ...MoveDoc
      }
    }
  }
  ${fragmentMoveDoc}
`;

export const mutationUpdateMoveDoc = gql`
  mutation updateMoveDoc($input: PatchUpdateMoveDocInput!) {
    updateMoveDoc(input: $input) {
      result
      errorMessage
      output {
        ...MoveDoc
      }
    }
  }
  ${fragmentMoveDoc}
`;

export const mutationConfirmMoveDoc = gql`
  mutation confirmMoveDoc($id: UUID!) {
    confirmMoveDoc(id: $id) {
      result
      errorMessage
    }
  }
`;

export const mutationRevertMoveDoc = gql`
  mutation revertMoveDoc($id: UUID!) {
    revertMoveDoc(id: $id) {
      result
      errorMessage
    }
  }
`;

export const mutationDeleteMoveDoc = gql`
  mutation deleteMoveDoc($id: UUID!) {
    deleteMoveDoc(id: $id) {
      result
      errorMessage
    }
  }
`;
