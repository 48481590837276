import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { CoreSchema, Product, ProductFilterInput, SortOrderInput, StockUnit, StrictDictionary } from '@typings';

export type ModifierGroupOutput = CoreSchema.ModifierGroupOutput;
export type Modifier = CoreSchema.Modifier;
export type StockUnitModifier = CoreSchema.StockUnitModifier;
export type ModifierType = CoreSchema.ModifierType;
export type ModifierTypeStrict = StrictDictionary<ModifierType, ModifierType>;
export type ModifierTypeString = StrictDictionary<string, ModifierType>;
export type ModifierEntityType = CoreSchema.ModifierEntityType;
export type ModifierEntityTypeStrict = StrictDictionary<ModifierEntityType, ModifierEntityType>;
export type AppliedModifier = CoreSchema.AppliedModifier;
export type StockUnitAppliedModifier = CoreSchema.StockUnitAppliedModifier;
export type ModifierPage = CoreSchema.ModifierPage;
export type CreateModifierGroupInput = CoreSchema.CreateModifierGroupInput;
export type CreateModifierInput = CoreSchema.CreateModifierInput;
export type UpdateModifierGroupInput = CoreSchema.UpdateModifierGroupInput;
export type ModifierGroupFilterInput = CoreSchema.ModifierGroupFilterInput;
export type ModifierGroupOutputPage = CoreSchema.ModifierGroupOutputPage;

export type MutationDeleteModifierGroupArgs = CoreSchema.MutationDeleteModifierGroupArgs;

export type QueryModifierGroupsArgs = CoreSchema.QueryModifierGroupsArgs;

export type QueryModifierGroupByIdArgs = CoreSchema.QueryModifierGroupByIdArgs;
export type QueryModifierGroupWhereUsedAsModifierArgs = CoreSchema.QueryModifierGroupWhereUsedAsModifierArgs;
export type MutationModifierGroupCreateArgs = CoreSchema.MutationModifierGroupCreateArgs;
export type MutationModifierGroupUpdateArgs = CoreSchema.MutationModifierGroupUpdateArgs;
export type MutationModifierGroupAttachProductsArgs = CoreSchema.MutationModifierGroupAttachProductsArgs;
export type MutationModifierGroupDetachProductsArgs = CoreSchema.MutationModifierGroupDetachProductsArgs;
export type MutationAddModifiersArgs = CoreSchema.MutationAddModifiersArgs;
export type MutationUpdateModifierArgs = CoreSchema.MutationUpdateModifierArgs;
export type MutationRemoveModifiersArgs = CoreSchema.MutationRemoveModifiersArgs;
export type QueryModifierGroupsArgsWithModifiers = CoreSchema.QueryModifierGroupsArgs & {
  modifiersPageRequest?: CoreSchema.InputMaybe<CoreSchema.PageRequestInput>;
};

export type ModifierGroupInfoForm = {
  name: FormControl<string>;
  type: FormControl<ModifierType>;
  modifiers: FormArray<FormGroup<ModifierForm>>;
  products: FormArray<FormControl<AutocompleteOption<Product> | null>>;
  required: FormControl<boolean>;
  minValue: FormControl<AutocompleteOption<number> | null>;
  maxValue: FormControl<AutocompleteOption<number> | null>;
};

export type ModifierForm = {
  id: FormControl<string | null>;
  name: FormControl<string>;
  pinned: FormControl<boolean>;
  stockUnit: FormControl<AutocompleteOption<StockUnit> | null>;
};

export function isStockUnitModifier(modifier: Modifier): modifier is StockUnitModifier {
  return 'stockUnit' in modifier;
}

export function isStockUnitAppliedModifier(modifier: AppliedModifier): modifier is StockUnitAppliedModifier {
  return 'stockUnitId' in modifier;
}

export type ModifierLoaderFilters = ProductFilterInput & { sort?: SortOrderInput[] };
export type ModifierGroupLoaderFilters = ModifierGroupFilterInput & { sort?: SortOrderInput[] };
