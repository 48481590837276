import { Injectable } from '@angular/core';

import { MovingsApi } from '@app/modules/warehouse/services';
import { NotifyService } from '@services/shared';
import {
  MutationConfirmMoveDocArgs,
  MutationConfirmMoveDocumentV2Args,
  MutationCreateMoveDocArgs,
  MutationCreateMoveDocumentArgs,
  MutationDeleteMoveDocArgs,
  MutationDeleteMoveDocumentArgs,
  MutationDuplicateMoveDocumentArgs,
  MutationResult,
  MutationRevertMoveDocArgs,
  MutationUpdateMoveDocArgs,
  MutationUpdateMoveDocumentArgs,
  QueryAllMoveDocumentsPageableV2Args,
  QueryMoveDocArgs,
  QueryMoveDocsArgs,
  QueryMoveDocumentArgs,
  QueryMoveProductsArgs,
  QueryResult,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class MovingsStorage {
  constructor(private notifyService: NotifyService, private movingsApi: MovingsApi) {}

  allMovingsPageable(variables: QueryAllMoveDocumentsPageableV2Args): QueryResult<'allMoveDocumentsPageableV2'> {
    return this.movingsApi.allMovingsPageable(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка перемещений',
      }),
    );
  }

  moving(variables: QueryMoveDocumentArgs): QueryResult<'moveDocument'> {
    return this.movingsApi.moving(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении перемещения',
      }),
    );
  }

  createMoving(variables: MutationCreateMoveDocumentArgs): MutationResult<'createMoveDocument'> {
    return this.movingsApi.createMoving(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании перемещения',
      }),
    );
  }

  updateMoving(variables: MutationUpdateMoveDocumentArgs): MutationResult<'updateMoveDocument'> {
    return this.movingsApi.updateMoving(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении перемещения',
      }),
    );
  }

  deleteMoving(variables: MutationDeleteMoveDocumentArgs): MutationResult<'deleteMoveDocument'> {
    return this.movingsApi.deleteMoving(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении перемещения',
        success: 'Перемещение успешно удалено',
      }),
    );
  }

  confirmMoving(variables: MutationConfirmMoveDocumentV2Args): MutationResult<'confirmMoveDocumentV2'> {
    return this.movingsApi.confirmMoving(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при проведении документа перемещения',
      }),
    );
  }

  duplicateMoving(variables: MutationDuplicateMoveDocumentArgs): MutationResult<'duplicateMoveDocument'> {
    return this.movingsApi.duplicateMoving(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при дублировании перемещения',
      }),
    );
  }

  moveDocs(variables: QueryMoveDocsArgs): QueryResult<'moveDocs'> {
    return this.movingsApi.moveDocs(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка перемещения',
      }),
    );
  }

  moveDocProducts(variables: QueryMoveProductsArgs): QueryResult<'moveProducts'> {
    return this.movingsApi.moveDocProducts(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка продуктов перемещения',
      }),
    );
  }

  moveDoc(variables: QueryMoveDocArgs): QueryResult<'moveDoc'> {
    return this.movingsApi.moveDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении перемещения',
      }),
    );
  }

  createMoveDoc(variables: MutationCreateMoveDocArgs): MutationResult<'createMoveDoc'> {
    return this.movingsApi.createMoveDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании перемещения',
      }),
    );
  }

  updateMoveDoc(variables: MutationUpdateMoveDocArgs): MutationResult<'updateMoveDoc'> {
    return this.movingsApi.updateMoveDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при изменении перемещения',
      }),
    );
  }

  confirmMoveDoc(variables: MutationConfirmMoveDocArgs): MutationResult<'confirmMoveDoc'> {
    return this.movingsApi.confirmMoveDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при проведении документа перемещения',
      }),
    );
  }

  revertMoveDoc(variables: MutationRevertMoveDocArgs) {
    return this.movingsApi.revertMoveDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при отмене перемещения',
      }),
    );
  }

  deleteMoveDoc(variables: MutationDeleteMoveDocArgs) {
    return this.movingsApi.deleteMoveDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении перемещения',
        success: 'Списание успешно удалено',
      }),
    );
  }
}
