import { Pipe, PipeTransform } from '@angular/core';

import { CURRENCY } from '@constants';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'RUB':
        return CURRENCY.RUBLE;

      default:
        return CURRENCY.RUBLE;
    }
  }
}
