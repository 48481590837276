import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

import { DropdownMenuModule } from '../dropdown-menu/dropdown-menu.module';
import { FormFieldModule } from '../form-field/form-field.module';
import { MenuModule } from '../menu/menu.module';

import { FilterPanelComponent } from './filter-panel.component';

@NgModule({
  declarations: [FilterPanelComponent],
  imports: [CommonModule, FormFieldModule, MenuModule, MatMenuModule, OverlayModule, DropdownMenuModule],
  exports: [FilterPanelComponent],
})
export class FilterPanelModule {}
