import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';

import { NotifyService } from '@services/shared';
import {
  MutationConfirmArrivalDocArgs,
  MutationConfirmArrivalDocumentArgs,
  MutationCreateArrivalDocArgs,
  MutationCreateArrivalDocumentArgs,
  MutationDeleteArrivalDocArgs,
  MutationDeleteArrivalDocumentArgs,
  MutationDuplicateArrivalDocumentArgs,
  MutationRejectArrivalDocumentArgs,
  MutationResult,
  MutationRevertArrivalDocArgs,
  MutationSetArrivalDocumentPaymentStatusArgs,
  MutationUpdateArrivalDocArgs,
  MutationUpdateArrivalDocumentArgs,
  QueryAllArrivalDocumentsPageableV2Args,
  QueryArrivalDocArgs,
  QueryArrivalDocsArgs,
  QueryArrivalDocumentArgs,
  QueryArrivalProductsArgs,
  QueryResult,
} from '@typings';

import { ArrivalsApi } from '..';

@Injectable({
  providedIn: 'root',
})
export class ArrivalsStorage {
  constructor(private notifyService: NotifyService, private arrivalsApi: ArrivalsApi) {}

  allArrivalsPageable(variables: QueryAllArrivalDocumentsPageableV2Args): QueryResult<'allArrivalDocumentsPageableV2'> {
    return this.arrivalsApi.allArrivalsPageable(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка поставок',
      }),
    );
  }

  arrival(variables: QueryArrivalDocumentArgs): QueryResult<'arrivalDocument'> {
    return this.arrivalsApi.arrival(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении поставки',
      }),
    );
  }

  createArrival(variables: MutationCreateArrivalDocumentArgs): MutationResult<'createArrivalDocument'> {
    return this.arrivalsApi.createArrival(variables).pipe(
      catchError((err: Error) => {
        const msg =
          err.message === 'Duplicated items not allowed'
            ? 'Невозможно создать документ с дубликатами, удалите одинаковые товары'
            : 'Ошибка при создании поставки';
        this.notifyService.addNotification({
          type: 'alert',
          title: msg,
        });
        return throwError(() => err);
      }),
    );
  }

  updateArrival(variables: MutationUpdateArrivalDocumentArgs): MutationResult<'updateArrivalDocument'> {
    return this.arrivalsApi.updateArrival(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении поставки',
      }),
    );
  }

  deleteArrival(variables: MutationDeleteArrivalDocumentArgs): MutationResult<'deleteArrivalDocument'> {
    return this.arrivalsApi.deleteArrival(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении поставки',
        success: 'Поставка успешно удалена',
      }),
    );
  }

  setArrivalDocumentPaymentStatus(
    variables: MutationSetArrivalDocumentPaymentStatusArgs,
  ): MutationResult<'setArrivalDocumentPaymentStatus'> {
    return this.arrivalsApi.setArrivalDocumentPaymentStatus(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при изменении статуса оплаты поставки',
      }),
    );
  }

  confirmArrival(variables: MutationConfirmArrivalDocumentArgs): MutationResult<'confirmArrivalDocument'> {
    return this.arrivalsApi.confirmArrival(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при проведении документа поставки',
      }),
    );
  }

  duplicateArrival(variables: MutationDuplicateArrivalDocumentArgs): MutationResult<'duplicateArrivalDocument'> {
    return this.arrivalsApi.duplicateArrival(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при дублировании документа поставки',
      }),
    );
  }

  rejectArrival(variables: MutationRejectArrivalDocumentArgs): MutationResult<'rejectArrivalDocument'> {
    return this.arrivalsApi.rejectArrival(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при отмене поставки',
      }),
    );
  }

  arrivalDoc(variables: QueryArrivalDocArgs): QueryResult<'arrivalDoc'> {
    return this.arrivalsApi.arrivalDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении поставки',
      }),
    );
  }

  arrivalDocs(variables: QueryArrivalDocsArgs): QueryResult<'arrivalDocs'> {
    return this.arrivalsApi.arrivalDocs(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка поставок',
      }),
    );
  }

  arrivalProducts(variables: QueryArrivalProductsArgs): QueryResult<'arrivalProducts'> {
    return this.arrivalsApi.arrivalProducts(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка поставок',
      }),
    );
  }

  createArrivalDoc(variables: MutationCreateArrivalDocArgs): MutationResult<'createArrivalDoc'> {
    return this.arrivalsApi.createArrivalDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании поставки',
      }),
    );
  }

  updateArrivalDoc(variables: MutationUpdateArrivalDocArgs): MutationResult<'updateArrivalDoc'> {
    return this.arrivalsApi.updateArrivalDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении поставки',
      }),
    );
  }

  deleteArrivalDoc(variables: MutationDeleteArrivalDocArgs): MutationResult<'deleteArrivalDoc'> {
    return this.arrivalsApi.deleteArrivalDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении поставки',
        success: 'Поставка успешно удалена',
      }),
    );
  }

  confirmArrivalDoc(variables: MutationConfirmArrivalDocArgs): MutationResult<'confirmArrivalDoc'> {
    return this.arrivalsApi.confirmArrivalDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при проведении документа поставки',
      }),
    );
  }

  revertArrivalDoc(variables: MutationRevertArrivalDocArgs): MutationResult<'revertArrivalDoc'> {
    return this.arrivalsApi.revertArrivalDoc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при отмене поставки',
      }),
    );
  }
}
