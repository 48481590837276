import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';
import { OrderStateStrict, OrderStateString } from '@typings';

export const ORDER_STATE: OrderStateStrict = {
  NEW: 'NEW',
  CLOSED: 'CLOSED',
  CANCELED: 'CANCELED',
  REFUNDED: 'REFUNDED',
};

export const ORDER_STATE_STRING: OrderStateString = {
  NEW: 'Открыт',
  CLOSED: 'Закрыт',
  CANCELED: 'Отменен',
  REFUNDED: 'Возвращен',
};

export const ORDER_STATE_MENU_ITEMS: MenuItem[] = Object.keys(ORDER_STATE).map((orderState) => ({
  id: orderState,
  type: 'item',
  label: ORDER_STATE_STRING[orderState],
}));
