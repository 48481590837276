import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Body } from 'apollo-angular/http/types';
import { Observable } from 'rxjs';

import { HTTP_HEADER } from '@constants';
import { SessionStorage } from '@services/api';

@Injectable()
export class OrganizationInterceptor implements HttpInterceptor {
  // запросы на которые не надо вешать header
  #exceptions = ['issueTechSupportClaim', 'confirmTechSupportClaim', 'revokeSupportControlClaim'];

  constructor(private sessionStorage: SessionStorage) {}

  intercept(request: HttpRequest<Body>, next: HttpHandler): Observable<HttpEvent<Body>> {
    const orgId = this.sessionStorage.getOrgId();
    const isException = this.#exceptions.includes(request.body?.operationName || '');
    const isSingOut = request.url.includes('singout');

    if (!!orgId && !isException && isSingOut && request.url.indexOf('weight') === -1) {
      const updatedRequest = request.clone({
        setHeaders: {
          [HTTP_HEADER.ORGANIZATION]: `${orgId}`,
        },
      });

      return next.handle(updatedRequest);
    }

    return next.handle(request);
  }
}
