<div class="nm-menu-header" (click)="handleHeaderClick($event)" [attr.data-test-id]="testId">
  <div class="content-container">
    <div class="left">
      <div class="left-button">
        <nm-icon-button
          *ngIf="iconLeft"
          variant="text"
          [icon]="iconLeft"
          testId="icon-left"
          (clickButton)="handleLeftIconClick($event)"
        ></nm-icon-button>
        <nm-avatar
          *ngIf="imageUrl || imageTitle"
          [title]="imageTitle"
          [imageUrl]="imageUrl"
          [type]="type"
          (imageLoad)="handleImageLoaded()"
          (imageError)="handleImageLoadError()"
        ></nm-avatar>
      </div>

      <div class="text" [class.multiline]="multiline">
        <span *ngIf="label" class="label">{{ label }}</span>
        <span *ngIf="description" class="description">{{ description }}</span>
      </div>
    </div>
    <nm-icon-button
      *ngIf="iconRight"
      variant="text"
      [icon]="iconRight"
      testId="icon-right"
      (clickButton)="handleRightIconClick($event)"
    ></nm-icon-button>
  </div>
</div>
