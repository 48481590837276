<div
  class="group-header"
  id="group-header"
  *ngIf="config?.hasGroupHeader"
  [style.gridTemplateColumns]="gridTemplateColumns"
>
  <div></div>
  <div
    *ngFor="let column of headerConfig.columnsConfig; let i = index"
    [class.group]="column.group"
    [class.color]="column?.columnColor"
    [style.margin-right]="
      headerConfig.columnsConfig[i + 1]?.group || headerConfig.columnsConfig[i + 1]?.hidden ? '-1px' : 'none'
    "
    [style.grid-column]="column.group ? getGridColumn(i, column.group) : '1'"
  >
    <span class="column-title">{{ column.title }}</span>
  </div>
</div>

<div
  class="table-header"
  id="table-header"
  [style.gridTemplateColumns]="gridTemplateColumns"
  [class.sticky-header]="true"
  [style.background-color]="config?.headerBackgroundColor || ''"
  [nmDragAndDropHorizontal]="true"
  [dropListDisabled]="isDropListDisabled"
  (nmDragEnd)="onColumnDragEnd($event)"
  nmDragAndDropList
>
  <div
    *ngIf="config.selectable"
    class="column select-column"
    [class.mobile-column]="bos.getIsMobile()"
    [class.pinned]="true"
  >
    <div class="number" *ngIf="config.enumerable?.enabled">#</div>
    <div class="checkbox-wrapper">
      <nm-checkbox
        [indeterminate]="(headerChecked$ | async) === 'indeterminate'"
        [checked]="(headerChecked$ | async) !== false"
        (checkboxChange)="onSelectHeader($event)"
      ></nm-checkbox>
    </div>
  </div>

  <div class="column number-column" *ngIf="config.enumerable?.enabled && !config.selectable" [class.pinned]="true">
    #
  </div>

  <div
    *ngFor="let column of config.columnsConfig; let i = index"
    nmDragAndDropItem
    [draggable]="config.dragAndDropColumns"
    [data]="column"
    class="column"
    [id]="'column' + i"
    [class.mobile-column]="bos.getIsMobile()"
    [class.hidden-column]="column?.hidden"
    [class.pinned]="config?.columnsConfig[i]?.pinned"
    [class.sortable]="column?.sortable"
    [style.justify-content]="column?.align"
    [style.text-align]="column?.align || 'initial'"
    [style.padding]="config.resizable ? '0' : '8px 12px'"
    [ngStyle]="{ left: config?.columnsConfig[i]?.pinned ? this.getLeftForPinned(config?.columnsConfig, i) : '0' }"
    [class.color]="column?.columnColor"
    [class.border-left]="checkBorder(i)"
    [class.border-right]="checkBorder(i, false)"
    (click)="onHeaderClick(column, config.resizable)"
    (mouseup)="endResize()"
    (mousemove)="onResize($event, i)"
    (mouseover)="onMouseOver(i)"
    (mouseout)="onMouseOut()"
    [ngClass]="{ hover: (tableService.isMouseOver | async) && (tableService.isMouseOverIndex | async) === i }"
  >
    <ng-container *ngIf="column?.template">
      <ng-container *ngTemplateOutlet="column?.template"></ng-container>
    </ng-container>

    <ng-container *ngIf="!column?.template">
      <span class="column-title">{{ column.title }}</span>
      <span *ngIf="column.sortable">
        <nm-svg-icon *ngIf="column.sorting === sortEnum.ASC" name="arrowUp" [size]="18"></nm-svg-icon>
        <nm-svg-icon *ngIf="column.sorting === sortEnum.DESC" name="arrowDown" [size]="18"></nm-svg-icon>
      </span>
      <span *ngIf="column.tooltip"
        ><div class="column-tooltip" nmTooltip [TooltipContent]="column.tooltip">?</div></span
      >
    </ng-container>
    <div class="bar" *ngIf="config.resizable && column.name !== 'tools'" (mousedown)="doResize(i)">
      <div class="bar-back"></div>
    </div>
  </div>
</div>
