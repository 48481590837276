import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'numberSeparate' })
export class NumberSeparatePipe implements PipeTransform {
  transform(value: number | string): string {
    const searchValue = /(\d)(?=(\d\d\d)+([^\d]|$))/g;
    const replaceValue = '$1 ';

    if (typeof value === 'number') {
      return value.toString().replace(searchValue, replaceValue);
    }

    if (typeof value === 'string') {
      return value.replace(searchValue, replaceValue);
    }

    return value;
  }
}
