import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { Sno, SnoStrict, SnoString } from '@typings';

export const SNO: Omit<SnoStrict, 'DEFAULT'> = {
  USN_INCOME: 'USN_INCOME',
  USN_INCOME_OUTCOME: 'USN_INCOME_OUTCOME',
  OSN: 'OSN',
  PATENT: 'PATENT',
  ESN: 'ESN',
};

export const SNO_STRING: Omit<SnoString, 'DEFAULT'> = {
  USN_INCOME: 'УСН (Доход)',
  USN_INCOME_OUTCOME: 'УСН (Доход минус расход)',
  OSN: 'ОСН (Общая система налогообложения)',
  PATENT: 'ПСН (Патентная система налогообложения)',
  ESN: 'ЕСХН (Единый с/х налог)',
};

export const SNO_LIST: AutocompleteOption<Sno>[] = Object.keys(SNO).map((sno) => ({
  id: sno,
  label: SNO_STRING[sno],
  type: 'item',
  data: sno,
}));
