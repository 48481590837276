import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BackButtonModule, ButtonModule, CheckboxModule, InputModule, MyInputModule } from '@nomia/nomia-components-lib';

import { AuthRoutingModule } from '@app/modules/auth/auth-routing.module';
import { DividerModule } from '@app/shared/component/divider/divider.module';
import { ProgressSpinnerModule } from '@app/shared/component/progress-spinner/progress-spinner.module';
import { ScrollVisibilityModule } from '@app/shared/directive/scroll-visibility/scroll-visibility.module';
import { SharedModule } from '@app/shared/shared.module';
import { InvitationComponent, InvitationModalComponent } from '@components/auth';
import { LetModule } from '@ngrx/component';
import { OrganizationsComponent } from '@pages/auth';

import { AuthUserAgreementModule } from './component/auth-user-agreement/auth-user-agreement.module';
import { OrganizationItemModule } from './component/organization-item/organization-item.module';

@NgModule({
  declarations: [InvitationComponent, InvitationModalComponent, OrganizationsComponent],
  imports: [
    AuthUserAgreementModule,
    CommonModule,
    CheckboxModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    BackButtonModule,
    ButtonModule,
    InputModule,
    MyInputModule,
    OrganizationItemModule,
    DividerModule,
    ProgressSpinnerModule,
    ScrollVisibilityModule,
    LetModule,
  ],
})
export class AuthModule {}
