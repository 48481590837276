import { Directive, EventEmitter, Input, Output } from '@angular/core';

import { BaseFilterService } from '@app/shared/service/base-filter.service';

@Directive({
  selector: '[filter]',
})
export class FilterNameDirective {
  private _filterName: string;
  private _filterOrder: number;

  @Input('filterName')
  get name(): string {
    return this._filterName;
  }

  set name(name: string) {
    this._filterName = name;
  }

  @Input('filterOrder')
  get order(): number {
    return this._filterOrder;
  }

  set order(order: number) {
    this._filterOrder = order;
  }

  @Output() filterOpen = new EventEmitter<void>();

  constructor(public baseFilterService: BaseFilterService) {}
}
