import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';

import { SVG_ICONS_TYPE } from '@constants';

export type JustifyContent = 'flex-start' | 'center' | 'flex-end';

@Component({
  selector: 'nm-table-title',
  templateUrl: './table-title.component.html',
  styleUrls: ['./table-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableTitleComponent {
  @Input() name: string | string[] = '';
  @Input() imageUrl: string = '';
  @Input() color: string = 'transparent';
  @Input() iconColor: string = '#5B5E66';
  @Input() justifyContent: JustifyContent = 'flex-start';
  @Input() isClickedName: boolean = false;
  @Input() isAvatar: boolean = false;
  @Input() isPath: boolean = false;
  @Input() isBold: boolean = false;
  @Input() isThin: boolean = false;
  @Input() icon: SVG_ICONS_TYPE;
  @Input() testId: string;
  @Input() description: string = '';

  tooltip$ = new BehaviorSubject<string>('');

  get value$() {
    let value = this.name;

    if (this.isPath && Array.isArray(this.name)) {
      if (this.name.length === 2) {
        value = `${this.name[0]} / ${this.name[1]}`;
      }

      if (this.name.length === 3) {
        value = `${this.name[0]} / ... / ${this.name[this.name.length - 1]}`;
        this.tooltip$.next(this.name.join(' / '));
      }
    }

    return of(value);
  }

  @Output() clickButton = new EventEmitter<void>();

  onClickButton(): void {
    this.clickButton.emit();
  }
}
