import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false,
})
export class FilterPipe<T> implements PipeTransform {
  transform(items: T[], filterCallback: (item: T) => boolean): T[] {
    if (!items || !filterCallback) {
      return items;
    }

    return items.filter(filterCallback);
  }
}
