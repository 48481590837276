export enum SalesRoute {
  ordersReport = 'orders-report',
  paymentSystemsReport = 'payment-systems-report',
  consolidatedReport = 'consolidated-report',
  tablesReport = 'tables-report',
  bookingReport = 'booking-report',
  shifts = 'shifts',
}

export enum SalesRouteName {
  ordersReport = 'Заказы',
  paymentSystemsReport = 'Отчет по способам оплаты',
  consolidatedReport = 'Отчет по продажам',
  tablesReport = 'Столы',
  bookingReport = 'Бронирования',
  shifts = 'Кассовые смены',
}

export enum SalesRouteParam {
  orderId = 'idOrder',
}

export enum ShiftsRouteParam {
  shiftId = 'idShift',
}

export const SALES_ITEM_PAYMENT_SYSTEMS = {
  title: SalesRouteName.paymentSystemsReport,
  route: SalesRoute.paymentSystemsReport,
};

export const SALES_ITEM_ORDERS = {
  title: SalesRouteName.ordersReport,
  route: SalesRoute.ordersReport,
  routes: [
    {
      title: '',
      route: SalesRouteParam.orderId,
      keepUrlParams: true,
    },
  ],
};

export const SALES_ITEM_CONSOLIDATED = {
  title: SalesRouteName.consolidatedReport,
  route: SalesRoute.consolidatedReport,
};

export const SALES_ITEM_BOOKING = {
  title: SalesRouteName.bookingReport,
  route: SalesRoute.bookingReport,
};

export const SALES_ITEM_TABLES = {
  title: SalesRouteName.tablesReport,
  route: SalesRoute.tablesReport,
};

export const SALES_ITEM_SHIFTS = {
  title: SalesRouteName.shifts,
  route: SalesRoute.shifts,
  routes: [
    {
      title: '',
      route: ShiftsRouteParam.shiftId,
      keepUrlParams: true,
    },
  ],
};

export const SALES_CHILD_ITEMS = [
  SALES_ITEM_CONSOLIDATED,
  SALES_ITEM_ORDERS,
  SALES_ITEM_PAYMENT_SYSTEMS,
  SALES_ITEM_TABLES,
  SALES_ITEM_BOOKING,
  SALES_ITEM_SHIFTS,
];
