import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

export function filterFormControls(control: AbstractControl, filterFn: (control: FormControl) => boolean): AbstractControl[] {
  if (!control) {
    return [];
  }

  if (control instanceof FormControl) {
    if (filterFn(control)) {
      return [control];
    }

    return [];
  }

  if (control instanceof FormGroup) {
    return Object.keys(control.controls).flatMap((key) => filterFormControls(control.controls[key], filterFn));
  }

  if (control instanceof FormArray) {
    control.controls.flatMap((c) => filterFormControls(c, filterFn));
  }

  return [];
}

interface FormType {
  [key: string]: AbstractControl;
}

export const getUpdatedControls = (formGroup: FormType, updatedValues: Set<string>): Partial<FormType> => {
  const controlNames: string[] = Object.keys(formGroup).map((key) => key.toString());
  return controlNames
    .filter((name: string) => updatedValues.has(name))
    .reduce((acc: Partial<FormType>, name: string) => {
      acc[name] = formGroup[name];
      return acc;
    }, {});
};
