import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';

// TODO: Its about dynamic nested DnD. Patch for nested DnD lists
// https://youtrack.nomia.dev/issue/ERPF-1113 - link attached in this task.
// Patch described here:
// https://mounted.medium.com/angular-cdk-nested-drag-and-drop-d849365a7ca9

// installPatch();

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
