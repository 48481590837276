<nm-modal-base
  (cancel)="closeSectionForm(false)"
  (ok)="closeSectionForm(true)"
  [okTitle]="btnTitle"
  okVariant="filled"
  [cancelTitle]="'Отменить'"
  testId="menu::menu-section-modal"
>
  <div modal-base-title>{{ title }}</div>
  <div modal-base-content-container>
    <ng-container *ngIf="menuService.sectionForm as form">
      <nm-form class="form" [form]="form">
        <nm-input-file
          previewLabel="Добавить фото"
          description="Формат изображения jpg или png. Размер от 800x600 пикселей, вес до 1.9 мб."
          selectBtnLabel="Выбрать"
          [allowedFileTypes]="['png', 'jpg', 'jpeg']"
          [maxSize]="1900"
          [error]="imagesService.isErrors[0]"
          [hint]="imagesService.hints[0]"
          [file]="imagesService.files[0]"
          testId="file-input"
          (fileSelect)="imagesService.setFileControl($event, image)"
          (fileValidationError)="imagesService.fileValidationError($event)"
        ></nm-input-file>
        <nm-input
          label="Название категории"
          placeholder="Введите название"
          ngDefaultControl
          [required]="true"
          [formControl]="name"
          [maxLength]="MAX_CHARACTERS.PRODUCT_NAME"
          [error]="validationErrorsService.isControlDirtyAndInvalid(name)"
          [hint]="validationErrorsService.validateField(name) || name.getError('name')"
          testId="name"
          value="newSectionName"
        ></nm-input>
        <nm-autocomplete
          ngDefaultControl
          label="Родительская категория"
          placeholder="Выберите родительскую категорию"
          [search]="true"
          [formControl]="controls.parentSection"
          [error]="validationErrorsService.isControlDirtyAndInvalid(controls.parentSection)"
          [hint]="
            validationErrorsService.validateField(controls.parentSection) ||
            controls.parentSection.getError('controls.parentSection')
          "
          [searchFn]="autocompleteTreeService.searchFn"
          [getSelectedOptionFn]="autocompleteTreeService.getSelectedOptionFn"
          [options]="autocompleteTreeService.options$"
          [autocompleteTreeService]="autocompleteTreeService"
          collapseChipsLabel="PRODUCTS_AND_CATEGORIES"
        ></nm-autocomplete>
        <nm-autocomplete
          ngDefaultControl
          [formControl]="colorSection"
          label="Цвет категории"
          placeholder="Выберите цвет"
          [options]="colors"
          [error]="validationErrorsService.isControlDirtyAndInvalid(colorSection)"
          [hint]="validationErrorsService.validateField(colorSection) || name.getError('colorSection')"
          testId="color"
        ></nm-autocomplete>
      </nm-form>
    </ng-container>
  </div>
</nm-modal-base>
