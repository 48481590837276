import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { ButtonModule } from '../button/button.module';
import { ControlHintModule } from '../control-hint/control-hint.module';
import { ControlLabelModule } from '../control-label/control-label.module';
import { FormFieldModule } from '../form-field/form-field.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';

import { DatepickerComponent } from './datepicker.component';

@NgModule({
  declarations: [DatepickerComponent],
  imports: [
    CommonModule,
    ControlLabelModule,
    FormsModule,
    ControlHintModule,
    ReactiveFormsModule,
    FormFieldModule,
    SvgIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ButtonModule,
  ],
  exports: [DatepickerComponent],
})
export class DatepickerModule {}
