import { gql } from 'apollo-angular';

import { fragmentPage } from './mutations';

export const fragmentSupplierShort = gql`
  fragment SupplierShort on Supplier {
    id
    companyName
    contactPoint
    type
    phone
    totalCosts
  }
`;

export const fragmentSupplier = gql`
  fragment Supplier on Supplier {
    ...SupplierShort
    address
    description
    email
    inn
    kpp
  }
  ${fragmentSupplierShort}
`;

export const fragmentSupplierLegal = gql`
  fragment SupplierLegal on SupplierLegal {
    id
    address
    contact
    description
    name
    phone
    email
    psrn
    tin
    totalCosts
  }
`;

export const fragmentSupplierPhysical = gql`
  fragment SupplierPhysical on SupplierPhysical {
    id
    address
    description
    name
    phone
    email
    tin
    totalCosts
  }
`;

export const mutationCreateSupplier = gql`
  mutation createSupplier($supplierCreateInput: SupplierCreateInput!) {
    createSupplier(supplierCreateInput: $supplierCreateInput)
  }
`;

export const mutationUpdateSupplier = gql`
  mutation updateSupplier($supplierUpdateInput: SupplierUpdateInput!) {
    updateSupplier(supplierUpdateInput: $supplierUpdateInput)
  }
`;

export const mutationDeleteSupplierV2 = gql`
  mutation deleteSupplierV2($id: UUID!) {
    deleteSupplierV2(id: $id) {
      status {
        errorMessage
        result
      }
    }
  }
`;

export const mutationDeleteSupplier = gql`
  mutation deleteSupplier($supplierId: UUID!) {
    deleteSupplier(supplierId: $supplierId)
  }
`;

export const queryAllSuppliersPageable = gql`
  query allSuppliersPageable($pageRequest: PageRequestInput!, $filter: SupplierFilterInput, $sort: [AllSuppliersPageableSort!]) {
    allSuppliersPageable(pageRequest: $pageRequest, filter: $filter, sort: $sort) {
      ...Page
      content {
        ...SupplierShort
      }
    }
  }
  ${fragmentPage}
  ${fragmentSupplierShort}
`;

export const querySuppliers = gql`
  query suppliers($filter: SuppliersFilterInput, $pageRequest: PageRequestInput!) {
    suppliers(filter: $filter, pageRequest: $pageRequest) {
      ...Page
      content {
        ... on SupplierLegal {
          ...SupplierLegal
        }
        ... on SupplierPhysical {
          ...SupplierPhysical
        }
      }
    }
  }
  ${fragmentPage}
  ${fragmentSupplierLegal}
  ${fragmentSupplierPhysical}
`;

export const querySuppliersShort = gql`
  query allSuppliersPageable($pageRequest: PageRequestInput!, $filter: SupplierFilterInput, $sort: [AllSuppliersPageableSort!]) {
    allSuppliersPageable(pageRequest: $pageRequest, filter: $filter, sort: $sort) {
      ...Page
      content {
        id
        companyName
        contactPoint
      }
    }
  }
  ${fragmentPage}
`;

export const querySupplier = gql`
  query supplier($id: UUID!) {
    supplier(id: $id) {
      ...Supplier
    }
  }
  ${fragmentSupplier}
`;

export const querySupplierV2 = gql`
  query supplierV2($id: UUID!) {
    supplierV2(id: $id) {
      ... on SupplierLegal {
        ...SupplierLegal
      }
      ... on SupplierPhysical {
        ...SupplierPhysical
      }
    }
  }
  ${fragmentSupplierLegal}
  ${fragmentSupplierPhysical}
`;

export const mutationCreateSupplierV2 = gql`
  mutation createSupplierV2($input: SupplierCreateInput!) {
    createSupplierV2(input: $input) {
      output {
        ...SupplierShort
      }
      result
      errorMessage
    }
  }
  ${fragmentSupplierShort}
`;

export const mutationUpdateSupplierV2 = gql`
  mutation updateSupplierV2($input: SupplierUpdateInputV2!) {
    updateSupplierV2(input: $input) {
      output {
        ...SupplierShort
      }
      result
      errorMessage
    }
  }
  ${fragmentSupplierShort}
`;

export const mutationCreateSupplierV3 = gql`
  mutation createSupplierV3($input: CreateSupplierInput!) {
    createSupplierV3(input: $input) {
      status {
        errorMessage
        result
      }
      supplier {
        ... on SupplierLegal {
          ...SupplierLegal
        }
        ... on SupplierPhysical {
          ...SupplierPhysical
        }
      }
    }
  }
  ${fragmentSupplierLegal}
  ${fragmentSupplierPhysical}
`;

export const mutationUpdateSupplierV3 = gql`
  mutation updateSupplierV3($input: UpdateSupplierInput!) {
    updateSupplierV3(input: $input) {
      status {
        errorMessage
        result
      }
      supplier {
        ... on SupplierLegal {
          ...SupplierLegal
        }
        ... on SupplierPhysical {
          ...SupplierPhysical
        }
      }
    }
  }
  ${fragmentSupplierLegal}
  ${fragmentSupplierPhysical}
`;
