import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DevicesService } from '@services/settings';
import { Device } from '@typings';

@Component({
  selector: 'nm-delete-device-dialog',
  templateUrl: './delete-device-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteDeviceDialogComponent {
  constructor(
    public devicesService: DevicesService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      device: Device;
      callbackFn?: () => void;
    },
  ) {}
}
