import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { ModalBaseModule } from '@app/shared/component/dialog/modal-base/modal-base.module';

import { DeleteArrivalDialogComponent } from './delete-arrival-dialog.component';

@NgModule({
  declarations: [DeleteArrivalDialogComponent],
  imports: [CommonModule, MatDialogModule, ModalBaseModule],
  exports: [DeleteArrivalDialogComponent],
})
export class DeleteArrivalDialogModule {}
