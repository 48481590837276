import { Pipe, PipeTransform } from '@angular/core';

import { ChartData, ChartType, CoreSchema, DefaultDataPoint, ReportIndicatorType, ValueOf } from '@typings';
import { chartBarParams, dayjs } from '@utils';

@Pipe({
  name: 'analyticsChartBarData',
})
export class AnalyticsChartBarDataPipe implements PipeTransform {
  constructor() {}

  transform(
    data: CoreSchema.AnalyticsByHour[] | CoreSchema.AnalyticsByDayOfWeek[],
    indicator: ReportIndicatorType,
  ): ChartData<ChartType, DefaultDataPoint<ChartType>, string> {
    const labels: string[] = [];
    const currentData: DefaultDataPoint<ChartType> = [];
    const inputData = data || [];

    inputData.forEach((item: CoreSchema.AnalyticsByHour | CoreSchema.AnalyticsByDayOfWeek) => {
      if (item && 'dayOfWeek' in item) {
        const { dayOfWeek, averageWeeklyCheck, averageWeeklyProfit, averageWeeklyRevenue, totalWeeklyChecksCount, totalWeeklyGuestsCount } =
          item;

        const prefixMap: Record<ReportIndicatorType, ValueOf<CoreSchema.AnalyticsByDayOfWeek>> = {
          averageCheck: averageWeeklyCheck,
          checksCount: totalWeeklyChecksCount,
          guestsCount: totalWeeklyGuestsCount,
          profit: averageWeeklyProfit,
          revenue: averageWeeklyRevenue,
        };

        labels.push(dayjs().day(dayOfWeek).format('dd'));
        currentData.push(Number(prefixMap[indicator]));
      }

      if (item && 'hour' in item) {
        const { hour, averageHourlyCheck, averageHourlyProfit, averageHourlyRevenue, totalHourlyChecksCount, totalHourlyGuestsCount } =
          item;

        const prefixMap: Record<ReportIndicatorType, ValueOf<CoreSchema.AnalyticsByHour>> = {
          averageCheck: averageHourlyCheck,
          checksCount: totalHourlyChecksCount,
          guestsCount: totalHourlyGuestsCount,
          profit: averageHourlyProfit,
          revenue: averageHourlyRevenue,
        };

        labels.push(`${hour}:00`);
        currentData.push(Number(prefixMap[indicator]));
      }
    });

    const params = chartBarParams();

    const chartData: ChartData<ChartType, DefaultDataPoint<ChartType>, string> = {
      labels,
      datasets: [
        {
          data: currentData,
          ...params,
        },
      ],
    };

    return chartData;
  }
}
