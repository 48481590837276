import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';

import { AvatarModule } from '@app/shared/component/avatar/avatar.module';

import { SvgIconModule } from '../svg-icon/svg-icon.module';

import { FilterLabelComponent } from './filter-label.component';

@NgModule({
  declarations: [FilterLabelComponent],
  imports: [CommonModule, SvgIconModule, MatChipsModule, AvatarModule],
  exports: [FilterLabelComponent],
})
export class FilterLabelModule {}
